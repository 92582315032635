import React, { Component } from "react";
import ReactModal from "react-modal";
import {
  editComplaintData,
  getRpuCourier,
  callCustomer,
  revokeTicket,
  callbackTagSubmit,
  moveToTestingInward,
  regeneratePaymentLink,
  submitScreenName,
  transferTodirectDispatch,
  transferTodirectDispatchFreshdesk,
  moveToTestingOutward,
  moveToDispatchInward,
  moveToRtoCase,
  submitRPU,
  forwardPickupCancel,
  returnPolicyAction,
  fetchPrintLabel,
  handleRequestPaymentSubmit,
  repairPaymentSubmit,
  qcSubmit,
  regenerateCoupons,
  submitFreshDeskClick,
  sendEmail,
  reversePickupCancel,
  GCApproval,
  sendCustomerInvoice,
  refundCaseApproval,
  adminList,
  gcCase,
  unmarkedTicketRefund,
  unmarkedTicketGC,
  resendReund,
  gcApprovalSubmit,
  refundApprovalSubmit,
  moveToReadyToDispatch,
  cosmosAction,
  flagAction,
  oosTestingAction,

} from "../../actions/complaintsDataAction";
import ComponentToPrint from "./ComponentToPrint";
import ComponentToRPUPrint from "./ComponentToRPUPrint";
import ComponentToFPUPrint from "./ComponentToFPUPrint";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import Select from "react-select";
import {
  fetchAllBuckets,
  fetchTicketList,
  submitTransfer,
  fetchBucketStatus,
  submitWayBillNumbers,
  selectedSubmitTransfer,
  submitWayBillNumbersToWarehouse,
  submitDispatchInward,
  fetchBulkConstant,
  clearDIDataResults,
  clearBulkTransferDataResults,
  submitBulkRPU,
  clearBulkRpuDataResults,
  qcFailBuckets,
  qcPassBuckets,
} from "../../actions/bulkmovementAction";
// import ReactToPrint from "react-to-print";
// import history from '../../history';
// import { Redirect } from 'react-router-dom';
const moment = require("moment");


const CustomGCStyle = {
  overlay: {
    background: "rgba(0, 0, 0, 0.5)"
  },
  content: {
    inset: "8% 0 0 auto",
    marginRight: "0",
    transform: "translate(0)",
    background: "#000",
    padding: "2rem",
    border: "none",
    width: window.innerWidth < 600 ?"100%" : "35%",
    position: "absolute"
  }
};

const customStyles = {
  overlay: {
    background: "rgba(0, 0, 0, 0.5)"
  },
  content: {
    inset: "8% 0 0 auto",
    marginRight: "0",
    transform: "translate(0)",
    background: "#000",
    padding: "2rem",
    border: "none",
    width: window.innerWidth < 600 ?"100%" :"25%",
    position: "absolute"
  }
};

const customStylesPrint = {
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
    overflow: "scroll"
  },
  content: {
    position: "absolute",
    inset: "auto",
    background: "rgb(0, 0, 0)",
    // overflow: "auto",
    borderRadius: "4px",
    outline: "none",
    padding: "2rem",
    marginRight: "0px",
    transform: "translate(0px)",
    width: window.innerWidth < 600 ?"100%" : "100%",
    overflow: "scroll"
  }
};

const customStylesRPU = {
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
    overflow: "scroll"
  },
  content: {
    inset: "8% 0 0 auto",
    marginRight: "0",
    transform: "translate(0)",
    background: "#fff",
    padding: "2rem",
    border: "none",
    width:  window.innerWidth < 600 ?"100%" : "100%",
    position: "absolute",
    overflow: "scroll"
  }
};

class TicketReceived extends Component {
  constructor() {
    super();
    this.state = {
      showResendModal: false,
      resendComplaintId: "",
      showCallbackTagModal: false,
      callbackReason: "",
      callbackDate: "",
      showGCCaseModal: false,
      orderId: "",
      resendId: "",
      showScreeningModal: false,
      showRefundModal: false,
      resendStatus: "",
      resendStatusId: "",
      showRefundApproval: false,
      adminStatus: "",
      order_id: "",
      showGCApprovalModal: false,
      isDispatchMOdal: false,
      screenOptionsArray: [
        // { name: "MAC ID Verifed", key: "mac_id_verified", isChecked: false },
        { name: "Strap Received", key: "strap_received", isChecked: false },
        {
          name: "Watch Dial Received",
          key: "watch_dial_recieved",
          isChecked: false
        },
        {
          name: "Charger Cable Received",
          key: "charger_cable_received",
          isChecked: false
        },
        {
          name: "Wrong Product Received",
          key: "wrong_product_received",
          isChecked: false
        },
        {
          name: "Empty box Received",
          key: "empty_box_received",
          isChecked: false
        },
        {
          name: "Damaged Earbuds Received",
          key: "damaged_earbuds_received",
          isChecked: false
        },
        {
          name: "Complete Product Received",
          key: "complete_product_received",
          isChecked: false
        },
        {
          name: "Mismatched SKU Received",
          key: "mismatched_sku_received",
          isChecked: false
        },
        {
          name: "Partial Product Received",
          key: "partial_product_received",
          isChecked: false
        },
        {
          name: "Damage Product Received",
          key: "damage_product_received",
          isChecked: false
        },
        { name: "Missing Charger", key: "missing_charger", isChecked: false },
        {
          name: "One Ear Buds Missing",
          key: "one_ear_buds_missing",
          isChecked: false
        },
        {
          name: "One Ear Buds Damage Recieved",
          key: "one_ear_buds_damage_recieved",
          isChecked: false
        },
        {
          name: "USB Cable Missing",
          key: "usb_cable_missing",
          isChecked: false
        },
        {
          name: "AUX & USB Cable Missing",
          key: "aux_and_usb_cable_missing",
          isChecked: false
        },
        {
          name: "Strap Damage Recieved ",
          key: "strap_damage_recieved",
          isChecked: false
        },
        {
          name: "One Side Damage Neckband Received",
          key: "one_side_damage_neckband_received",
          isChecked: false
        },
        {
          name: "Scratch Watch Display Received",
          key: "scratch_watch_display_received",
          isChecked: false
        },
        {
          name: "Extra Strap & USB Cable & Charging",
          key: "extra_strap_&_usb_cable_&_charging",
          isChecked: false
        },
        {
          name: "Both Earbuds Missing",
          key: "both_earbuds_missing",
          isChecked: false
        },
        {
          name: "Charging Case Missing",
          key: "charging_case_missing",
          isChecked: false
        },
        {
          name: "Watch Charging Pin Rusted",
          key: "watch_charging_rin_rusted",
          isChecked: false
        },
        {
          name: "Both Earbuds Received",
          key: "both_earbuds_received",
          isChecked: false
        },
        {
          name: "Charging Case Received",
          key: "charging_case_received",
          isChecked: false
        },
        {
          name: "Watch Crown Missing",
          key: "watch_crown_missing",
          isChecked: false
        },
        { name: "Strap Missing", key: "strap_missing", isChecked: false },
        {
          name: "One Earbuds Received",
          key: "one_earbuds_received",
          isChecked: false
        },
        {
          name: "Watch Dial Missing",
          key: "watch_dial_missing",
          isChecked: false
        },
        {
          name: "Watch Dial Damage Received",
          key: "watch_dial_damage_received",
          isChecked: false
        },
        {
          name: "Wrong Strap Received",
          key: "wrong_strap_received",
          isChecked: false
        }
      ],
      qcPassReplacementArray: [
        { name: "Earbuds", key: "strap_received", isChecked: false },
        { name: "Charging Case", key: "charging_case", isChecked: false },
        { name: "USB Cable", key: "usb_cable", isChecked: false },
        { name: "Dial", key: "dial", isChecked: false },
        { name: "Device only", key: "device_only", isChecked: false },
        { name: "Other", key: "other", isChecked: false }
      ],
      showRPUModal: false,
      showFPUModal: false,
      courierId: 0,
      showScreeningPrintModal: false,
      showRPUPrintModal: false,
      showRequestPayment: false,
      optionStatus: "",
      costReqPay: "",
      upgradeProductId: 0,
      showProdList: false,
      showRepairPayment: false,
      repairCost: "",
      repairPayment: "",
      showQcPass: false,
      isIssueDetected: "issue_detected",
      selectDetectedValue: "",
      selectDetectedId: "",
      qcRepairValue: "",
      qcRepairCost: 0,
      qcPassUpgradeCost: 0,
      showFPUPrintModal: false,
      selectProduct: false,
      dispatchWith: "direct",
      itemId: "",
      selectProductF: false,
      isLoadingGif: false,
      showGCApproveModal: false,
      refundNote: "",
      errors: [],
      loadingInModal: false,
      showGcModal: false,
      showMACScreening: true,
      macValue: "",
      transferFrom: 1,
      transferTo: 1,
      statusId: 1,
      isEnterTicketType: true,
      ticketNumbers: "",
      ticketList: [],
      offset: 1,
      onlyMovement: false,
      transferValidateDisabled: true,
      isHandover: false,
      wayBillNumbers: "",
      pageLimit: 10,
      current_page: 1,
      startDate: new Date().setDate(new Date().getDate() - 60),
      endDate: new Date().setDate(new Date().getDate()),
      isHandoverToCourier: false,
      isWarehouse: false,
      isDispatchInward: false,
      transferList: true,
      status: "",
      errors: [],
      showProducts: false,
      acc_replacement: "0",
      note: "",
      productList: [],
      productColorId: "0",
      showSummary: false,
      isLoading: false,
      bulkRpu: false,
      pickupType: "reverse_pick_up",
      ticketNos: "",
    };
    this.handleCallbackTagOpenModal = this.handleCallbackTagOpenModal.bind(
      this
    );
    this.handleCallbackTagCloseModal = this.handleCallbackTagCloseModal.bind(
      this
    );
    this.handleScreeningOpenModal = this.handleScreeningOpenModal.bind(this);
    this.handleScreeningCloseModal = this.handleScreeningCloseModal.bind(this);
    this.handleRefundOpenModal = this.handleRefundOpenModal.bind(this);
    this.handleRefundCloseModal = this.handleRefundCloseModal.bind(this);
    this.handleGCCloseModal = this.handleGCCloseModal.bind(this);
    this.handleCallbackReasonChange = this.handleCallbackReasonChange.bind(
      this
    );
    this.handleGCCaseOpenModal = this.handleGCCaseOpenModal.bind(this);
    this.handleGCCaseCloseModal = this.handleGCCaseCloseModal.bind(this);
    this.handleCallbackDate = this.handleCallbackDate.bind(this);
    this.handleCallbackReasonChange = this.handleCallbackReasonChange.bind(
      this
    );
    this.handleGCCaseText = this.handleGCCaseText.bind(this);
    // this.handleRefundText = this.handleRefundText.bind(this)
    this.handleCallbackSubmit = this.handleCallbackSubmit.bind(this);
    this.handleGCCase = this.handleGCCase.bind(this);
    this.handleGCApproval = this.handleGCApproval.bind(this);
    this.handleRefundSubmit = this.handleRefundSubmit.bind(this);
    this.handleRefundApproval = this.handleRefundApproval.bind(this);
    this.handleChangeChk = this.handleChangeChk.bind(this);
    this.handleScreeningSubmit = this.handleScreeningSubmit.bind(this);
    this.handleDispatchInward = this.handleDispatchInward.bind(this);
    this.handleRPUOpenModal = this.handleRPUOpenModal.bind(this);
    this.handleRPUSubmit = this.handleRPUSubmit.bind(this);
    this.handleCourierChange = this.handleCourierChange.bind(this);
    this.handleRPUCloseModal = this.handleRPUCloseModal.bind(this);
    this.handleFPUOpenModal = this.handleFPUOpenModal.bind(this);
    this.handleFPUCloseModal = this.handleFPUCloseModal.bind(this);
    this.handleFPUSubmit = this.handleFPUSubmit.bind(this);
    this.handleReturnPolicy = this.handleReturnPolicy.bind(this);
    this.handlePrintModalClose = this.handlePrintModalClose.bind(this);
    this.handleRPUPrint = this.handleRPUPrint.bind(this);
    this.handleRPUModalClose = this.handleRPUModalClose.bind(this);
    this.handleRequestPaymentOpenModal = this.handleRequestPaymentOpenModal.bind(
      this
    );
    this.handleRequestPaymentCloseModal = this.handleRequestPaymentCloseModal.bind(
      this
    );
    this.handleOptionStatus = this.handleOptionStatus.bind(this);
    this.handleCostReqPayStatus = this.handleCostReqPayStatus.bind(this);
    this.handleRequestPaymentSubmit = this.handleRequestPaymentSubmit.bind(
      this
    );
    this.handleProductList = this.handleProductList.bind(this);
    this.handleRepairPaymentOpenModal = this.handleRepairPaymentOpenModal.bind(
      this
    );
    this.handleRepairPaymentCloseModal = this.handleRepairPaymentCloseModal.bind(
      this
    );
    this.handleSelectProductClose = this.handleSelectProductClose.bind(this);
    this.handleSelectProductFreshdeskClose = this.handleSelectProductFreshdeskClose.bind(
      this
    );
    this.handleRepairCostChange = this.handleRepairCostChange.bind(this);
    this.handleRepairPaymentChange = this.handleRepairPaymentChange.bind(this);
    this.handleProductSelectionChange = this.handleProductSelectionChange.bind(
      this
    );
    this.handleRepairPaymentSubmit = this.handleRepairPaymentSubmit.bind(this);
    this.handleQcFail = this.handleQcFail.bind(this);
    this.handleQcPassOpenModal = this.handleQcPassOpenModal.bind(this);
    this.handleQcPassCloseModal = this.handleQcPassCloseModal.bind(this);
    this.handleIsIssueDetectedChange = this.handleIsIssueDetectedChange.bind(
      this
    );
    this.handleIssueDetectedChange = this.handleIssueDetectedChange.bind(this);
    this.handleQcRepairChange = this.handleQcRepairChange.bind(this);
    this.handleQcRepairCost = this.handleQcRepairCost.bind(this);
    this.handleQcSubmit = this.handleQcSubmit.bind(this);
    this.handleQcPassUpgradeCost = this.handleQcPassUpgradeCost.bind(this);
    this.handleChangeQcPassChk = this.handleChangeQcPassChk.bind(this);
    this.handleRejected = this.handleRejected.bind(this);
    this.handleRegenrateCoupon = this.handleRegenrateCoupon.bind(this);
    this.handleFPUPrint = this.handleFPUPrint.bind(this);
    this.handleSendEmail = this.handleSendEmail.bind(this);
    this.resendCustomerInvoice = this.resendCustomerInvoice.bind(this);
    this.handleGCModal = this.handleGCModal.bind(this);
    this.handleGCApproveText = this.handleGCApproveText.bind(this);
    this.handleGCAmmount = this.handleGCAmmount.bind(this);
    this.onClickGCSubmit = this.onClickGCSubmit.bind(this);
    this.handleRefundNotes = this.handleRefundNotes.bind(this);
    this.handleResendCloseModl = this.handleResendCloseModl.bind(this);
    this.handleResendChange = this.handleResendChange.bind(this);
    this.handleRefundCase = this.handleRefundCase.bind(this);
    this.handleRefundApprovalCloseModal = this.handleRefundApprovalCloseModal.bind(
      this
    );
    this.handleAdminStatus = this.handleAdminStatus.bind(this);
    this.GccaseValidation = this.GccaseValidation.bind(this);
    this.handleGCCaseSubmit = this.handleGCCaseSubmit.bind(this);
    this.handleGCApprovalCloseModal = this.handleGCApprovalCloseModal.bind(
      this
    );
    this.handleGCApprovalCase = this.handleGCApprovalCase.bind(this);
    this.onClickGCApprovalSubmit = this.onClickGCApprovalSubmit.bind(this);
    this.onClickRefundCaseSubmit = this.onClickRefundCaseSubmit.bind(this);
    this.handleCosmos = this.handleCosmos.bind(this);
  }

  async resendCustomerInvoice(e, data) {
    await this.props.sendCustomerInvoice({ complaint_id: data });
    alert(this.props.resendMail);
    this.props.handleRefreshList();
  }

  async handleSendEmail() {
    let data = {
      complaint_id: this.props.complaintDetail.id
    };
    await this.props.sendEmail(data);
    this.props.handleRefreshList();
  }

  async handleRegenrateCoupon() {
    let data = {
      complaint_id: this.props.complaintDetail.id
    };
    await this.props.regenerateCoupons(data);
    this.props.handleRefreshList();
  }

  handleRejected() {
    let data = {
      complaint_id: this.props.complaintDetail.id,
      update_comment: "Buyback reject",
      flag: "buyback_reject",
      complaint_status_id: 27,
      sla_breached: "0"
    };
    this.callEditApi(data);
  }

  handleChangeQcPassChk(e) {
    const { name, checked } = e.target;
    let tempArray = this.state.qcPassReplacementArray.map(so =>
      so.name === name ? { ...so, isChecked: checked } : so
    );
    this.setState({
      qcPassReplacementArray: tempArray
    });
  }

  handleQcPassUpgradeCost(e) {
    this.setState({
      qcPassUpgradeCost: e.target.value
    });
  }

  async handleQcSubmit() {
    let data = {
      complaint_id: this.props.complaintDetail.id
    };
    if (this.state.selectDetectedValue.length === 0) {
      alert("Please select issue detected option");
      return;
    } else {
      if (this.state.selectDetectedValue === "1") {
        if (this.state.qcRepairValue.length === 0) {
          alert("Please Select Repair option");
          return;
        } else {
          data.repair_option = this.state.qcRepairValue;
          data.issue_detected = this.state.selectDetectedValue;
          data.issue_status = this.state.isIssueDetected;
          if (this.state.qcRepairValue === "hardware") {
            data.hardware_cost_pay = this.state.qcRepairCost;
            if (Number(this.state.qcRepairCost) === 0) {
              alert("Please Enter QC Repair Cost");
              return;
            }
          }
        }
      }
      if (this.state.selectDetectedValue === "2") {
        data.issue_detected = this.state.selectDetectedValue;
        data.issue_status = this.state.isIssueDetected;
        let replaceProds = [];
        for (let i = 0; i < this.state.qcPassReplacementArray.length; i++) {
          if (this.state.qcPassReplacementArray[i].isChecked) {
            replaceProds.push(this.state.qcPassReplacementArray[i].name);
          }
        }
        if (replaceProds.length === 0) {
          alert("Emply Item Selected");
          return;
        }
        data.replaceProds = replaceProds;
      }
      if (this.state.selectDetectedValue === "3") {
        data.issue_detected = this.state.selectDetectedValue;
        if (Number(this.state.upgradeProductId) === 0) {
          alert("Please select product");
          return;
        }
        if (Number(this.state.qcPassUpgradeCost) === 0) {
          alert("Please Enter Cost");
          return;
        }
        data.issue_status = this.state.isIssueDetected;
        data.upgrade_product_id = this.state.upgradeProductId;
        data.upgrade_cost = this.state.qcPassUpgradeCost;
      }
      if (
        this.state.selectDetectedValue === "4" ||
        this.state.selectDetectedValue === "5" ||
        this.state.selectDetectedValue === "6" ||
        this.state.selectDetectedValue === "7" ||
        this.state.selectDetectedValue === "8"
      ) {
        data.issue_status = this.state.isIssueDetected;
        data.no_issue_detected = this.state.selectDetectedValue;
      }
      await this.props.qcSubmit(data);
      this.props.handleRefreshList();
    }
  }

  handleQcRepairCost(e) {
    this.setState({
      qcRepairCost: e.target.value
    });
  }

  handleQcRepairChange(e) {
    this.setState({
      qcRepairValue: e.target.value
    });
  }

  handleIssueDetectedChange(e) {
    this.setState({
      selectDetectedValue: e.target.value
    });
  }

  handleIsIssueDetectedChange(e) {
    this.setState({
      isIssueDetected: e.target.value
    });
  }

  handleQcPassCloseModal() {
    this.setState({
      showQcPass: false
    });
  }

  handleQcPassOpenModal = async () => {
    let data = {
      "ticketNumbers": [`${this.props.complaintDetail.ticket_id}`],
    };
    this.props.handleLoader(true)
    let response = await this.props.qcPassBuckets(data)
    this.props.handleLoader(false)
    this.props.handleRefreshList();
    if (response.success) {
      alert("Complaint Moved Succesfully")
    }

  }

  handleQcFail = async () => {
    let data = {
      "ticketNumbers": [`${this.props.complaintDetail.ticket_id}`],
    };
    this.props.handleLoader(true)
    let response = await this.props.qcFailBuckets(data)
    this.props.handleLoader(false)
    this.props.handleRefreshList();
    if (response.success) {
      alert("Complaint Moved Succesfully")
    }

  }

  async handleRepairPaymentSubmit(e) {
    e.preventDefault();
    let data = {
      complaint_id: this.props.complaintDetail.id,
      repair_option: this.state.repairPayment
    };

    if (this.state.repairPayment == 0) {
      alert("Please Select Repair Option");
      return;
    }
    if (this.state.repairPayment === "hardware") {
      data.hardware_cost_pay = this.state.repairCost;
      if (data.hardware_cost_pay.length === 0) {
        alert("Please enter cost");
        return;
      } else {
        await this.props.repairPaymentSubmit(data);
        this.props.handleRefreshList();
      }
    } else {
      await this.props.repairPaymentSubmit(data);
      this.props.handleRefreshList();
    }
  }

  async handleRepairPaymentChange(e) {
    this.setState({
      repairPayment: e.target.value
    });
  }

  handleProductSelectionChange(e) {
    this.setState({
      dispatchWith: e.target.value
    });
  }

  async handleRepairCostChange(e) {
    this.setState({
      repairCost: e.target.value
    });
  }

  async handleProductList(e) {
    this.setState({
      upgradeProductId: e.target.value
    });
  }

  async handleRequestPaymentSubmit() {
    let data = {};
    if (this.state.optionStatus.length === 0) {
      alert("Please Select Option");
      return;
    }
    if (this.state.costReqPay === "") {
      alert("Please enter amount");
      return;
    }
    data = {
      complaint_id: this.props.complaintDetail.id,
      option_status: this.state.optionStatus, //repaired
      cost_payment: this.state.costReqPay
    };
    if (this.state.optionStatus === "upgrade") {
      data.upgrade_product_id = this.state.upgradeProductId;
      if (Number(data.upgrade_product_id) > 0) {
        await this.props.handleRequestPaymentSubmit(data);
        this.props.handleRefreshList();
      } else {
        alert("Please select upgrade product");
      }
    } else {
      if (data.cost_payment.length > 0) {
        await this.props.handleRequestPaymentSubmit(data);
        this.props.handleRefreshList();
      } else {
        alert("Please enter cost");
        return;
      }
    }
  }

  handleCostReqPayStatus(e) {
    this.setState({
      costReqPay: e.target.value
    });
  }

  handleOptionStatus(e) {
    if (e.target.value === "upgrade") {
      this.setState({
        showProdList: true
      });
    } else {
      this.setState({
        showProdList: false
      });
    }
    this.setState({
      optionStatus: e.target.value
    });
  }

  handleAdminList(e) {
    this.setState({ givenadmin: e.target.value });
  }

  handleRequestPaymentCloseModal() {
    this.setState({
      showRequestPayment: false
    });
  }

  handleRequestPaymentOpenModal() {
    this.setState({
      showRequestPayment: true
    });
  }

  handleRepairPaymentCloseModal() {
    this.setState({
      showRepairPayment: false
    });
  }

  handleSelectProductClose() {
    this.setState({
      selectProduct: false
    });
  }

  handleSelectProductFreshdeskClose() {
    this.setState({
      selectProductF: false
    });
  }

  handleRepairPaymentOpenModal() {
    this.setState({
      showRepairPayment: true
    });
  }

  handleRPUModalClose() {
    this.setState(
      {
        showRPUPrintModal: false
      },
      () => {
        this.props.handleRefreshList();
      }
    );
  }

  async handleRPUPrint() {
    let data = {
      complaint_id: this.props.complaintDetail.id,
      type: "reverse_pick_up"
    };
    await this.props.fetchPrintLabel(data);
    if (this.props.printLabel) {
      // const win = window.open("/rpu-print", "_blank");
      // win.focus();
      // history.push('/rpu-print')
      this.setState({ showRPUPrintModal: true });
    } else {
      alert("no data found");
    }
  }

  async handleFPUPrint() {
    let data = {
      complaint_id: this.props.complaintDetail.id,
      type: "forward_pick_up"
    };
    await this.props.fetchPrintLabel(data);
    if (this.props.printLabel) {
      //history.push('/fpu-print')
      this.setState({ showFPUPrintModal: true });
    } else {
      alert("no courier data found");
    }
  }

  handlePrintModalClose() {
    this.setState(
      {
        showScreeningPrintModal: false
      },
      () => {
        this.props.handleRefreshList();
      }
    );
  }

  async handleReturnPolicy() {
    let data = {
      complaint_id: this.props.complaintDetail.id
    };
    await this.props.returnPolicyAction(data);
    this.props.handleRefreshList();
  }

  async handleDispatchInward(e, item) {
    let data = {
      complaint_id: item
    };
    await this.props.moveToDispatchInward(data);
    this.props.handleRefreshList();
  }

  async handleScreeningSubmit() {
    let sName = [];
    for (let i = 0; i < this.state.screenOptionsArray.length; i++) {
      if (this.state.screenOptionsArray[i].isChecked) {
        sName.push(this.state.screenOptionsArray[i].key);
      }
    }
    // if (this.props.complaintDetail?.product?.product_group_id == 2 && !(this.props.complaintDetail?.old_mac_id == "DOA" || this.props.complaintDetail?.old_mac_id == "NON-NFA")) {
    //   sName.push(this.state.macValue)
    // }

    if (sName.length === 0) {
      alert("Please select ");
    }

    // else if ((this.props.complaintDetail?.product?.product_group_id == 2 && !this.state.macValue) && !(this.props.complaintDetail?.old_mac_id == "DOA" || this.props.complaintDetail?.old_mac_id == "NON-NFA")) {
    //   alert("Please Verify MAC ID ");
    // }
    else {
      let data = {
        complaint_id: this.props.complaintDetail.id,
        screening: sName,
        screening_status: "",
        // "is_verified": this.state.macValue ? true : false
      };

      // await this.props.selectedSubmitTransfer(body)
      await this.props.submitScreenName(data);
      if (this.props.screenRes && this.props.screenRes.printJob) {
        this.setState({
          showScreeningPrintModal: true
        });
      } else if (this.props.screenRes && !this.props.screenRes.printJob) {
        this.props.handleRefreshList();
      }
    }
  }

  async handleRefundSubmit(e, item) {
    this.setState({
      showRefundModal: true
    });
  }

  async handleRefundApproval(e, item) {
    this.setState({
      showRefundApproval: true
    });
  }

  handleCosmos = async item => {
    let data = {
      complaint_id: this.props.complaintDetail.id
    };
    await this.props.cosmosAction(data);
    if (this.props.cosmosRes.success) {
      this.props.handleRefreshList();
    }
  };

  handleGCCaseText(e) {
    this.setState({
      orderId: e.target.value
    });
  }

  handleGCAmmount(e) {
    this.setState({
      GC_amount: e.target.value
    });
  }

  handleRefundNotes(e) {
    this.setState({
      refundNote: e.target.value
    });
  }

  handleGCApproveText(e) {
    this.setState({
      orderId: e.target.value
    });
  }

  async handleCallbackSubmit(e) {
    e.preventDefault();
    let data = {
      complaint_id: this.props.complaintDetail.id,
      callback_date: moment(this.state.callbackDate).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      tag_comment: this.state.callbackReason
    };
    await this.props.callbackTagSubmit(data);
    this.props.handleRefreshList();
  }

  // async handleGCCase(e) {
  //   e.preventDefault()
  //   let data = {
  //     complaint_id: this.props.complaintDetail.id,
  //   }
  //   this.props.gcCase(data);
  //   this.props.handleRefreshList()
  // }

  handleGCCase = () => {
    this.setState({
      showGcModal: true
    });
  };

  async handleGCApproval(e) {
    e.preventDefault();
    this.setState({
      complaint_id: this.props.complaintDetail.id,
      showGCApprovalModal: true
    });

    // let data = {
    //   complaint_id: this.props.complaintDetail.id,
    // }
    // this.props.gcApprovalSubmit(data)
    // this.props.handleRefreshList()
  }

  handleCallbackDate(e) {
    this.setState({
      callbackDate: e.target.value
    });
  }

  handleRefundCase(e) {
    this.setState({
      order_id: e.target.value
    });
  }

  handleGCApprovalCase(e) {
    this.setState({
      order_id: e.target.value
    });
  }

  handleCallbackReasonChange(e) {
    this.setState({
      callbackReason: e.target.value
    });
  }

  handleCallbackTagCloseModal() {
    this.setState({ showCallbackTagModal: false });
  }

  handleCallbackTagOpenModal() {
    this.setState({ showCallbackTagModal: true });
  }

  handleScreeningCloseModal() {
    this.setState({ showScreeningModal: false });
  }

  handleScreeningOpenModal() {
    this.setState({ showScreeningModal: true });
  }

  handleRPUCloseModal() {
    this.setState({ showRPUModal: false });
  }

  async handleFPUSubmit() {
    let cId = this.state.courierId;
    let selectedC = this.props.courierData.filter(
      cd => Number(cd.courier_id) === Number(cId)
    );
    if (selectedC.length > 0) {
      // this.setState({ isLoadingGif: true });
      let data = {
        complaint_id: this.props.complaintDetail.id,
        type: "forward_pick_up",
        courier_id: cId,
        reversePrice: 0,
        forwardPrice: 0
      };
      await this.props.submitRPU(data);
      //  this.setState({ isLoadingGif: false })
      await this.handleFPUPrint();
      // this.props.handleRefreshList();
    } else {
      alert("Please select courier");
      return;
    }
  }

  async handleRPUSubmit() {
    let cId = this.state.courierId;
    let selectedC = this.props.courierData.filter(
      cd => Number(cd.courier_id) === Number(cId)
    );
    if (selectedC.length > 0) {
      // this.setState({ isLoadingGif: true })
      let data = {
        complaint_id: this.props.complaintDetail.id,
        type: "reverse_pick_up",
        courier_id: cId,
        reversePrice: 0
      };
      await this.props.submitRPU(data);
      // this.setState({ isLoadingGif: false })
      this.props.handleRefreshList();
    } else {
      alert("Please select courier");
    }
  }

  async handleCourierChange(e) {
    this.setState({
      courierId: e.target.value
    });
  }

  async handleResendChange(e) {
    if (e.target.value === 1) {
      this.setState({
        resendStatusId: e.target.value,
        resendStatus: "GC Not Found"
      });
    }
    if (e.target.value === 2) {
      this.setState({
        resendStatusId: e.target.value,
        resendStatus: "Mail missed"
      });
    }
    if (e.target.value === 3) {
      this.setState({
        resendStatusId: e.target.value,
        resendStatus: "Mail Deleted"
      });
    }
    // this.setState({
    //   resendStatus: e.target.name,
    //   resendStatusId:e.target.value
    // })
  }

  async handleFPUOpenModal() {
    let data = {
      complaint_id: this.props.complaintDetail.id,
      type: "forward_pick_up"
    };
    // this.setState({
    //   isLoadingGif: true
    // })
    await this.props.getRpuCourier(data);
    this.setState({ showFPUModal: true });
  }

  handleFPUCloseModal() {
    this.setState({ showFPUModal: false });
  }

  async handleRPUOpenModal() {
    let data = {
      // "complaint_id": item.id,
      complaint_id: this.props.complaintDetail.id
      // "type" : "reverse_pick_up"
    };
    // TODO first call list api then click list item and call new save api
    this.setState({
      isLoading: true
    });
    await this.props.getRpuCourier(data);
    this.setState({ isLoading: false, showRPUModal: true });
  }

  handleGCCaseCloseModal() {
    this.setState({ showGcModal: false });
  }

  handleGCCaseOpenModal() {
    this.setState({ showGCCaseModal: true });
  }

  handleRefundCloseModal() {
    this.setState({ showRefundModal: false });
  }

  handleRefundApprovalCloseModal() {
    this.setState({ showRefundApproval: false });
  }

  handleGCApprovalCloseModal() {
    this.setState({ showGCApprovalModal: false });
  }

  handleRefundOpenModal() {
    this.setState({ showRefundModal: true });
  }

  handleGCModal() {
    this.setState({ showGCApproveModal: true });
  }

  handleGCCloseModal() {
    this.setState({ showGCApproveModal: false, errors: "" });
  }

  handleResendCloseModl() {
    this.setState({ showResendModal: false, errors: "" });
  }

  handleRefundCaseValidation() {
    let flag = true;
    let errors = {};
    if (this.state.order_id === "") {
      errors["order_id"] = "Order ID is mandatory";
      flag = false;
    }
    if (this.state.adminStatus === 0) {
      errors["adminStatus"] = "Please Select User Name ";
      flag = false;
    }
    this.setState({ errors: errors });
    return flag;
  }

  GccaseValidation() {
    let flag = true;
    let errors = {};
    if (this.state.order_id === "") {
      errors["order_id"] = "Order ID is mandatory";
      flag = false;
    }
    if (this.state.adminStatus === 0) {
      errors["adminStatus"] = "Please Select User Name ";
      flag = false;
    }
    this.setState({ errors: errors });
    return flag;
  }

  handleGCCaseSubmit = async () => {
    this.setState({ loadingInModal: true });
    if (this.GccaseValidation() == true) {
      let data = {
        complaint_id: this.props.complaintDetail.id,
        order_id: this.state.order_id,
        gc_approve: this.state.adminStatus
      };
      await this.props.gcCase(data);
      await this.props.adminList();
      this.setState({ showGcModal: false });
      this.props.handleRefreshList();
    }
  };

  // onClickRefundApprovalSubmit = async () => {
  //   this.setState({ loadingInModal: true })
  //   if (this.handleRefundCaseValidation() == true) {
  //     let data = {
  //       "complaint_id": this.props.complaintDetail.id,
  //       "order_id": this.state.order_id,
  //       "refund_approve": this.state.adminStatus,
  //     }
  //     await this.props.refundApprovalSubmit(data)

  //     this.setState({ showGcModal: false });
  //     this.props.handleRefreshList()
  //   }
  // }

  onClickGCApprovalSubmit = async () => {
    this.setState({ loadingInModal: true });
    if (this.handleRefundCaseValidation() === true) {
      let data = {
        complaint_id: this.props.complaintDetail.id,
        order_id: this.state.order_id,
        gc_approve: this.state.adminStatus
      };
      await this.props.gcApprovalSubmit(data);
      this.setState({ showGCApprovalModal: false });
      this.props.handleRefreshList();
    }
  };

  onClickRefundApprovalSubmit = async () => {
    this.setState({ loadingInModal: true });
    if (this.handleRefundCaseValidation() == true) {
      let data = {
        complaint_id: this.props.complaintDetail.id,
        order_id: this.state.order_id,
        refund_approve: this.state.adminStatus
      };
      await this.props.refundApprovalSubmit(data);

      this.setState({ showRefundApproval: false });
      this.props.handleRefreshList();
    }
  };

  handleRefundValidation() {
    let flag = true;
    let errors = {};
    if (this.state.GC_amount == undefined) {
      errors["GC_amount"] = "Gift card Amount is mandatory";
      flag = false;
    }
    if (this.state.GC_amount != undefined) {
      let gcNumber = Number(this.state.GC_amount);
      var pattern = new RegExp(/^\d+(\.\d{1,2})?$/);
      if (!pattern.test(this.state.GC_amount)) {
        flag = false;
        errors["GC_amount"] = "Enter valid Amount...";
      }
    }
    if (this.state.refundNote == "") {
      errors["refundNote"] = "Note is mandatory";
      flag = false;
    }
    this.setState({ errors: errors, loadingInModal: false });
    return flag;
  }

  handleResendValidation() {
    let flag = true;
    let errors = {};
    if (this.state.resendStatusId == "") {
      errors["resendId"] = "Please Choose a Status ";
      flag = false;
    }
    this.setState({ errors: errors, loadingInModal: false });
    return flag;
  }

  onClickGCSubmit = async () => {
    this.setState({ loadingInModal: true });
    if (this.handleRefundValidation() == true) {
      let data = {
        complaint_id: this.props.complaintDetail.id,

        refund_amount: this.state.GC_amount,
        notes: this.state.refundNote
      };
      await this.props.GCApproval(data);
      this.setState({ showGCApproveModal: false, refundNote: "" });
      this.props.handleRefreshList();
    }
  };

  onClickRefundCaseSubmit = async () => {
    this.setState({ loadingInModal: true });
    if (this.handleRefundCaseValidation() == true) {
      let data = {
        complaint_id: this.props.complaintDetail.id,
        order_id: this.state.order_id,
        refund_approve: this.state.adminStatus
      };
      await this.props.refundCaseApproval(data);
      await this.props.adminList();
      this.setState({ showRefundModal: false });
      this.props.handleRefreshList();
    }
  };

  onResendSubmit = async () => {
    this.setState({ loadingInModal: true });
    if (this.handleResendValidation() == true) {
      let data = {
        complaint_id: this.state.resendComplaintId,
        reason: this.state.resendStatus
      };
      await this.props.resendReund(data);
      this.setState({ showResendModal: false });
      this.props.handleRefreshList();
    }
  };

  readyToDispatch = async (e, item) => {
    let data = {
      complaint_id: item
    };
    await this.props.moveToReadyToDispatch(data);
  };

  onSaveClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      //"complaint_status_id": 25 // approved
      complaint_status_id: 4, //complaint_registered
      product_registration: "approved",
      status_enum: "approved",
      sla_breached: "0",
      update_comment: "Complaint Approved",
      flag: "approve_complaint"
    };
    this.callEditApi(data);
  };

  handleCustomerNotPaying = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      //"complaint_status_id": 25 // approved
      complaint_status_id: 11, //reverse_pickup_physical_damage
      resolution_status: "4",
      testing_status: "2",
      sla_breached: "0",
      update_comment: "Customer Not Paying",
      flag: "customer_not_pay"
    };
    this.callEditApi(data);
  };

  onInvalidInvoiceClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 2, // not_approved_invalid_invoice
      complaint_close_reason: "invoice_invalid",
      sla_breached: "0",
      update_comment: "Complaint Invalid Invoice",
      flag: "invalid_invoice"
    };
    this.callEditApi(data);
  };

  onWarrantyExpiredClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 3, // not_approved_warranty_expired
      sla_breached: "0",
      update_comment: "Complaint Warranty Expired",
      flag: "warranty_expired",
      is_warranty: "0"
    };
    this.callEditApi(data);
  };


  onProductMismatch = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 118, // not_approved_warranty_expired
      sla_breached: "0",
      update_comment: "Product Mismatch",
      flag: "product_validation_mismatch",
      is_warranty: "0"
    };
    this.callEditApi(data);
  };


  onFreshdeskClick = async (e, item) => {
    let data = {
      complaint_id: item,
      freshdesk_ticket_type: "priority_cases"
    };
    await this.props.submitFreshDeskClick(data);
    this.props.handleRefreshList();
    // e.preventDefault()
    // let data = {
    //   "complaint_id": item,
    //   "complaint_status_id": 52 // freshdesk
    // }
    // this.callEditApi(data)
  };

  directDispatch = async (e, item) => {
    this.setState({ selectProduct: true, itemId: item });
  };

  handleDirectDispatch = async () => {
    let body = {
      complaint_id: this.state.itemId,
      dispatch_with: this.state.dispatchWith
    };
    await this.props.transferTodirectDispatch(body);
    this.props.handleRefreshList();
  };

  directDispatchFreshdesk = async (e, item) => {
    let data = {
      complaint_id: this.props.complaintDetail.id,
      type: "forward_pick_up"
    };
    // TODO first call list api then click list item and call new save api
    this.setState({
      isLoading: true
    });
    await this.props.getRpuCourier(data);
    if (this.props.courierData) {
      this.setState({
        isLoading: false,
        selectProductF: true,
        itemId: item
      });
    }
  };

  handleDirectDispatchFreshdesk = async () => {
    let cId = this.state.courierId;
    let selectedC = this.props.courierData.filter(
      cd => Number(cd.courier_id) === Number(cId)
    );
    if (selectedC.length > 0) {
      let data = {
        complaint_id: this.props.complaintDetail.id,
        type: "forward_pick_up",
        courier_id: cId,
        reversePrice: selectedC[0].reverse_cost,
        forwardPrice: selectedC[0].forward_cost,
        status: this.state.dispatchWith
      };
      await this.props.submitRPU(data);
      await this.handleFPUPrint();
      this.props.handleRefreshList();
    } else {
      alert("Please select courier");
      return;
    }
  };

  onMoveToComplaintClosedClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 13, // complaint_closed
      sla_breached: "0",
      update_comment: "Complaint close normally",
      flag: "normal_close"
    };
    this.callEditApi(data);
  };

  onMoveToDuplicateComplaintClosedClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 13, // complaint_closed
      sla_breached: "0",
      update_comment: "Duplicate Complaint close",
      flag: "duplicate_close"
    };
    this.callEditApi(data);
  };

  onTsSuccess = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 13, // complaint_closed
      sla_breached: "0",
      update_comment: "Complaint closed, TS successful  by Agent",
      flag: "ts_success_agent",
      ts_successful_agent: "1"
    };
    this.callEditApi(data);
  };

  onMoveToSelfShipClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      product_registration: "approved",
      complaint_status_id: 54, // self_ship_requested
      sla_breached: "0",
      update_comment: "Move to Self ship",
      flag: "move_self_ship"
    };
    this.callEditApi(data);
  };

  onRefundClick = () => {
    let data = {
      complaint_id: this.props.complaintDetail.id,
      complaint_status_id: 52 // need to diss than will do
    };
    this.callEditApi(data);
  };

  // onPhoneClick = async (e, item) => {
  //   e.preventDefault()
  //   await this.props.callCustomer(item.customer_id, item.id)
  // }

  onMoveToFreshProductClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 94, // fresh_product
      sla_breached: "0",
      update_comment: "Move to Fresh product",
      flag: "fresh_product"
    };
    this.callEditApi(data);
  };

  callEditApi = async param => {
    await this.props.editComplaintData(param);
    let editRes = this.props.editData;
    alert(editRes.message);
    this.props.handleRefreshList();
  };

  onPhoneClick = async (e, item) => {
    e.preventDefault();
    await this.props.callCustomer(item.customer_id, item.id);
    let callRes = this.props.callRes;
    //alert(callRes.success);
    // if (callRes.success === true) {
    //   let data = {
    //     "complaint_id": item.id,
    //     "complaint_status_id": 41,
    //     "status_enum": 'approved',
    //     "sla_breached": '0',
    //     "update_comment": 'Complaint Callback',
    //     "flag": 'complaint_callback'
    //   }
    //   this.callEditApi(data)
    // }
    alert(callRes.message);
  };

  onMoveToWalkingReceivedClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 30, // walking_received
      sla_breached: "0",
      update_comment: "Walking Received",
      flag: "walking_received"
    };
    this.callEditApi(data);
  };

  onMoveToUrgentClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      urgent: "1",
      "timesta​m​ps": "0",
      update_comment: "Urgent",
      flag: "urgent"
    };
    this.callEditApi(data);
  };

  onMoveToCategoryTeamClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 75, //   complaint_status_id = 75
      sla_breached: "0",
      update_comment: "Move to Category Bin",
      flag: "category_bin"
    };
    this.callEditApi(data);
  };

  onMoveToCallDoneClick = (e, item) => {
    e.preventDefault();
    let count = item.no_of_manual_callsdone + 1;
    let data = {
      complaint_id: item,
      no_of_manual_callsdone: count, // no_of_manual_callsdone + 1 and update no_of_manual_callsdone
      timestamps: "0",
      update_comment: "Call done",
      flag: "call_count"
    };
    this.callEditApi(data);
  };

  onMoveToTsSuccessByAgentClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 13, // complaint_closed
      sla_breached: "0",
      update_comment: "TS successfull by agent",
      flag: "ts_success_agent"
    };
    this.callEditApi(data);
  };

  onMoveToCategoryBinClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 75, // category_pending
      sla_breached: "0",
      update_comment: "Move to Category Bin",
      flag: "category_bin"
    };
    this.callEditApi(data);
  };

  onMoveToReturnReceivedClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 7, // reverse_pickup_received
      sla_breached: "0",
      package_received_date: moment().format("YYYY-MM-DD"), // this.date
      update_comment: "Return received",
      flag: "return_received"
    };
    this.callEditApi(data);
  };

  onMoveToPickedUpClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 53, // pickedup
      sla_breached: "0",
      update_comment: "Picked up",
      flag: "picked_up"
    };
    this.callEditApi(data);
  };

  onReverseCancelledClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 28, // reverse_pickup_cancelled
      sla_breached: "0",
      update_comment: "Reverse Cancelled",
      flag: "reverse_cancelled"
    };
    this.callEditApi(data);
  };

  onForwardCancelledClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 29, // forward_pickup_cancelled
      sla_breached: "0",
      update_comment: "Forward Cancelled",
      flag: "reverse_cancelled"
    };
    this.callEditApi(data);
  };

  onMoveToWhUrgentClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      wh_urgent: "1", // move to ticket WH urgent
      timestamps: 0,
      update_comment: "WH urgent",
      flag: "wh_urgent"
    };
    this.callEditApi(data);
  };

  onMoveToIQCClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 81, // screening_inbound
      sla_breached: "0",
      update_comment: "Move to ICQ",
      flag: "move_icq"
    };
    this.callEditApi(data);
  };

  onMoveToOutOfStockClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 95, // out_of_stock
      sla_breached: "0",
      update_comment: "Out of Stock",
      flag: "stock_out"
    };
    this.callEditApi(data);
  };

  onMoveToUnserviceableClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 88, // unserviceable
      sla_breached: "0",
      update_comment: "Unserviceable",
      flag: "unserviceable"
    };
    this.callEditApi(data);
  };

  onMoveToRtoOutClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 72, // rto_out
      sla_breached: "0",
      update_comment: "RTO Out",
      flag: "rto_out"
    };
    this.callEditApi(data);
  };

  onMoveToRtoInClick = async (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item
    };
    await this.props.moveToRtoCase(data);
  };

  onMoveToFreshdeskApprovedClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 1, // complaint_registered
      sla_breached: "0",
      update_comment: "Freshdesk approved",
      flag: "freshdesk_approve"
    };
    this.callEditApi(data);
  };

  onMoveToFreshdeskTblstCallClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 41, // callback
      sla_breached: "0",
      update_comment: "Freshdesk troubleshoot call",
      flag: "freshdesk_troubleshoot"
    };
    this.callEditApi(data);
  };

  onMoveToFreshdeskRPUClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 7, // reverse_pickup_received
      sla_breached: "0",
      update_comment: "Freshdesk RPU received",
      flag: "freshdesk_rpu"
    };
    this.callEditApi(data);
  };

  onMoveToFreshdeskNormalCloseClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 13, // complaint_closed
      sla_breached: "0",
      update_comment: "Freshdesk normal close",
      flag: "freshdesk_close"
    };
    this.callEditApi(data);
  };

  onMoveToFreshdeskEmptyBoxClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 79, // empty_box_received
      sla_breached: "0",
      update_comment: "Freshdesk empty box received",
      flag: "freshdesk_empty_rcv"
    };
    this.callEditApi(data);
  };

  onMoveToPaymentDoneClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 57, // payment_done
      sla_breached: "0",
      update_comment: "Payment Done",
      flag: "payment_done"
    };
    this.callEditApi(data);
  };

  onMoveToFreshdeskWrongProductClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 78, // wrong_product_received
      sla_breached: "0",
      update_comment: "Freshdesk wrong product received",
      flag: "freshdesk_wrong_rcv"
    };
    this.callEditApi(data);
  };

  onMoveToFreshdeskMovePolicyClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 80, // fd_policy
      sla_breached: "0",
      update_comment: "Freshdesk move to policy",
      flag: "freshdesk_move_policy"
    };
    this.callEditApi(data);
  };

  onFreshdeskSelfShipClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      //"registration": 'approved',  need to check and update
      complaint_status_id: 54, // self_ship_requested
      sla_breached: "0",
      update_comment: "Freshdesk move to self ship",
      flag: "freshdesk_self_ship"
    };
    this.callEditApi(data);
  };

  onMoveToFreshdeskClick = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 52, // freshdesk
      sla_breached: "0",
      update_comment: "Move to freshdesk",
      flag: "freshdesk_move"
    };
    this.callEditApi(data);
  };

  onMoveToTestingInward = async (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item
    };
    await this.props.moveToTestingInward(data);
    this.props.handleRefreshList();
  };

  onMoveToTestingOutward = async (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item
    };
    await this.props.moveToTestingOutward(data);
    this.props.handleRefreshList();
  };

  onReversePickupCancelled = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 28,
      update_comment: "Reverse Cancelled",
      flag: "reverse_cancelled"
    };
    this.callEditApi(data);
  };

  onMoveToPaymentPending = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 56,
      sla_breached: "0",
      update_comment: "Reverse Cancelled",
      flag: "payment_pending"
    };
    this.callEditApi(data);
  };

  onMoveToSelfShipDone = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 53,
      sla_breached: "0",
      update_comment: "Self ship done",
      flag: "self_ship_done"
    };
    this.callEditApi(data);
  };

  onMoveToProductReceivedAtWarehouse = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 7,
      sla_breached: "0",
      update_comment: "Return received",
      flag: "return_received"
    };
    this.callEditApi(data);
  };

  onProductPickedUp = (e, item) => {
    e.preventDefault();
    let data = {
      complaint_id: item,
      complaint_status_id: 53,
      sla_breached: "0",
      update_comment: "Picked up",
      flag: "picked_up"
    };
    this.callEditApi(data);
  };

  onRevokeTicket = async (e, item) => {
    await this.props.revokeTicket(item);
    this.props.handleRefreshList();
  };

  onUnmarkedTicketRefund = async (e, item) => {
    let data = {
      complaint_id: item
    };
    await this.props.unmarkedTicketRefund(data);
    this.props.handleRefreshList();
  };

  onUnmarkedTicketGC = async (e, item) => {
    let data = {
      complaint_id: item
    };
    await this.props.unmarkedTicketGC(data);
    this.props.handleRefreshList();
  };

  onMoveRegeneratePaymentLink = async (e, item) => {
    let data = {
      complaint_id: item
    };
    await this.props.regeneratePaymentLink(data);
    this.props.handleRefreshList();
  };

  handleChangeChk(e) {
    const { name, checked } = e.target;
    let tempArray = this.state.screenOptionsArray.map(so =>
      so.key === name ? { ...so, isChecked: checked } : so
    );
    this.setState({
      screenOptionsArray: tempArray
    });
  }

  handleMACID = (e) => {
    if (e.target.checked) {
      this.setState({ macValue: e.target.value })
    } else {
      this.setState({ macValue: "" })
    }

  }



  async handleForwardPickupCancel(e, courierId, complaintId, tracking) {
    let body = {
      complaint_id: complaintId,
      type: "forward_pick_up", //  2 values ( reverse_pick_up || forward_pick_up )
      courier_id: courierId, //  4 values (1, 3, 4, 5)
      tracking: tracking
    };
    await this.props.forwardPickupCancel(body);
    this.props.handleRefreshList();
  }

  async handleReversePickupCancel(e, courierId, complaintId, tracking) {
    let body = {
      complaint_id: complaintId,
      type: "reverse_pick_up", //  2 values ( reverse_pick_up || forward_pick_up )
      courier_id: courierId, //  4 values (1, 3, 4, 5)
      tracking: tracking
    };
    await this.props.reversePickupCancel(body);
    this.props.handleRefreshList();
  }

  onResendGc = (e, item) => {
    this.setState({
      showResendModal: true,
      resendComplaintId: item
    });
  };

  handleAdminStatus(e) {
    this.setState({
      adminStatus: e.target.value
    });
  }

  handleflag = async () => {
    let data = {
      complaint_id: this.props.complaintDetail.id
    };
    await this.props.flagAction(data);
    this.props.handleRefreshList();
  };


  handleChangeStatusDI = (e) => {
    let id = e.target.value;
    if (e.target.value === "replace") {
      this.setState({ showProducts: true, showUpgrade: false });
    } else if (e.target.value === "upgrade") {
      this.setState({ showUpgrade: true, showProducts: false });
      this.setState({
        productsList: this.props.productData.data.products
      });
    } else {
      this.setState({ showProducts: false, showUpgrade: false });
    }
    this.setState({
      status: id,
      showSummary: false
    });
  }
  onProductChange = (a) => {
    this.setState({ productColorId: a.value, showSummary: false });
  }

  handleNote = (e) => {
    this.setState({
      note: e.target.value
    });
  }

  handleChangeOnlyMovement = (e) => {
    const { checked } = e.target;
    this.setState({
      onlyMovement: checked
    });
  }

  handleValidation() {
    let errors = {};
    let validity = true;
    if (this.state.status === "") {
      errors["status"] = "Please select appropriate status";
      validity = false;
    }
    if (this.state.showProducts) {
      if (this.state.acc_replacement === "0") {
        errors["accessory"] = "Please select replacement accessory";
        validity = false;
      }
      if (
        this.state.acc_replacement === "1" ||
        this.state.acc_replacement === "6" ||
        this.state.acc_replacement === "13" ||
        this.state.acc_replacement === "14"
      ) {
        if (this.state.note === "") {
          errors["note"] = "Please enter note";
          validity = false;
        }
      }
    }
    if (this.state.showUpgrade) {
      if (this.state.productColorId === "0") {
        errors["color"] = "Please select product";
        validity = false;
      }
    }
    this.setState({ errors: errors });
    return validity;
  }

  handleTransferDispatchInward = async () => {
    let data;
    if (this.handleValidation() !== false) {
      this.setState({ isLoading: true });
      if (this.state.status === "replace") {
        if (this.state.acc_replacement === "Acc") {
          let notes = this.state.note;
          data = {
            only_movement: this.state.onlyMovement,
            qc_status: this.state.status,
            replace_accessory: "Acc " + notes
            // ticketNumbers: waybillnos
          };
        } else if (this.state.acc_replacement === "Repair") {
          let notes = this.state.note;
          data = {
            only_movement: this.state.onlyMovement,
            qc_status: this.state.status,
            replace_accessory: "Repair " + notes
            // ticketNumbers: waybillnos
          };
        } else if (this.state.acc_replacement === "Unit Alternate Repack") {
          let notes = this.state.note;
          data = {
            only_movement: this.state.onlyMovement,
            qc_status: this.state.status,
            replace_accessory: "Unit Alternate Repack " + notes
            // ticketNumbers: waybillnos
          };
        } else if (this.state.acc_replacement === "Unit Alternate New") {
          let notes = this.state.note;
          data = {
            only_movement: this.state.onlyMovement,
            qc_status: this.state.status,
            replace_accessory: "Unit Alternate New " + notes
            // ticketNumbers: waybillnos
          };
        } else {
          // let notes = this.state.note;
          data = {
            only_movement: this.state.onlyMovement,
            qc_status: this.state.status,
            replace_accessory: this.state.acc_replacement
            // ticketNumbers: waybillnos
          };
        }
      } else if (this.state.status === "upgrade") {
        data = {
          only_movement: this.state.onlyMovement,
          qc_status: this.state.status,
          upgrade_product_id: this.state.productColorId
          // ticketNumbers: waybillnos
        };
      } else {
        data = {
          only_movement: this.state.onlyMovement,
          qc_status: this.state.status
          // ticketNumbers: waybillnos
        };
      }
      // Clear previous cache
      await this.props.clearDIDataResults();

      // Batch wise processing
      let size = this.props.bulkConstant[0]
        ? Number(this.props.bulkConstant[0].value)
        : 15;
      // let batchProcessing =  false;
      data.ticketNumbers = [`${this.props.complaintDetail?.ticket_id}`];
      let response = await this.props.submitDispatchInward(data);
      if (response.data?.success.length > 0) {
        alert(`${response.data?.success[0]} ${response.message}`)
        this.props.handleRefreshList();
        this.setState({
          showSummary: true,
          isLoading: false,
          isDispatchInward: true,
          transferList: false,
          bulkRpu: false,
          isHandover: false,
          bulkRpuShiprocket: false
        });
      } else if (response.data?.failure.length > 0) {
        alert(`${response.data?.failure[0]} not moved`)
      } else if (response.data?.macFailure.length > 0) {
        alert(`${response.data?.macFailure[0]}`)
      }

    }
  }

  handleChangeAccessory = (e) => {
    this.setState({
      acc_replacement: e.target.value,
      showSummary: false
    });
  }

  oosTestingInward = async (value) => {
    let data = {
      "complaint_id": value,
    }
    await this.props.oosTestingAction(data)
    this.props.handleRefreshList()
  }




  render() {
    let optionBucketDrop = [
      {
        value: 0,
        label: "Select Product"
      }
    ];
    {
      this.props.productData.data.products.map(obj =>
        optionBucketDrop.push({
          // label: obj.name,
          value: obj.product_color_id,
          label: obj.id == 239 ? obj.product_name + "(" + obj.size + "|" + obj.color_name + ")" : obj.product_name + "(" + obj.color_name + ")"
          // id: obj.id
        })
      );
    }
    let userRoleId = localStorage.getItem("userRoleId");
    if (this.props.adminUserlist.data) {
    } else {
    }
    return (
      <div>
        {this.props.statusId === 1 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e => this.onSaveClick(e, this.props.complaintDetail.id)}
            >
              <img src="../../img/Save.svg" alt="" />
              Save Ticket
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshdeskTblstCallClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Troubleshoot.svg" alt="" />
              Troubleshoot
            </a>
            {this.props.complaintDetail?.invoice_url &&
              <a
                className="dropdown-item"
                
                onClick={e =>
                  this.onInvalidInvoiceClick(e, this.props.complaintDetail.id)
                }
              >
                <img src="../../img/Invalid-Invoice.svg" alt="" />
                Invalid Invoice
              </a>}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onWarrantyExpiredClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Warranty-Expired.svg" alt="" />
              Warranty Expired
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onProductMismatch(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Invalid-Invoice.svg" alt="" />
              On Product Mismatch
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onFreshdeskClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Freshdesk.svg" alt="" />
              FreshDesk
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.directDispatch(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Direct-Dispatch-Accessory.svg" alt="" />
              Direct Dispatch
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToSelfShipClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Self-Ship.svg" alt="" />
              Move to selfship
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a className="dropdown-item" onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.handleRefundSubmit(e, this.props.complaintDetail.order_id)
              }
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.handleReversePickupCancel(
                  e,
                  this.props.complaintDetail.reverse_courier_id
                    ? this.props.complaintDetail.reverse_courier_id
                    : "",
                  this.props.complaintDetail.id,
                  this.props.complaintDetail.reverse_tracking
                )
              }
            >
              <img src="../../img/Reverse-Pickup-Cancelled.svg" alt="" />
              Reverse pickup cancelled
            </a>
            {this.props.complaintDetail.is_flag == 0 && (
              <a className="dropdown-item"  onClick={this.handleflag}>
                <img src="../../img/whiteflag1.svg" />
                Flag
              </a>
            )}
          </div>
        ) : null}
        {this.props.statusId === 2 ? (
          <div>
            {/* <a className="dropdown-item"  ><img src="../../img/Troubleshoot.svg"  />Troubleshoot</a> */}
            {/* <a className="dropdown-item"  ><img src="../../img/Edit.svg" />Edit</a> */}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshdeskTblstCallClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Troubleshoot.svg" alt="" />
              Troubleshoot
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToWalkingReceivedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Walk_in_Customer.svg" alt="" />
              Walk-in Customer
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.directDispatch(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Direct-Dispatch-Accessory.svg" alt="" />
              Direct Dispatch
            </a>
            <a
              className="dropdown-item"
              onClick={this.handleRPUOpenModal}
            >
              <img src="../../img/RPU.svg" alt="" />
              RPU
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToSelfShipClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Self-Ship.svg" alt="" />
              Move to selfship
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onFreshdeskClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Freshdesk.svg" alt="" />
              Freshdesk
            </a>
            <a
              className="dropdown-item"
              onClick={e =>
                this.onRevokeTicket(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Revoke-Ticket.svg" alt="" />
              Revoke Ticket
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 3 ? (
          <div>
            {/* <a className="dropdown-item"  ><img src="../../img/Edit.svg" />Edit</a> */}
            <a
              className="dropdown-item"
              onClick={e =>
                this.directDispatch(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Direct-Dispatch-Accessory.svg" alt="" />
              Direct Dispatch
            </a>
            <a
              className="dropdown-item"
              onClick={e =>
                this.onMoveToCategoryTeamClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Walk_in_Customer.svg" alt="" />
              Transfer to category team
            </a>
            <a
              className="dropdown-item"
              onClick={e =>
                this.onMoveToSelfShipClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Self-Ship.svg" alt="" />
              Move to selfship
            </a>
            <a
              className="dropdown-item"
              onClick={e =>
                this.onFreshdeskClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Freshdesk.svg" alt="" />
              Freshdesk
            </a>
            <a
              className="dropdown-item"
              onClick={e =>
                this.onRevokeTicket(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Revoke-Ticket.svg" alt="" />
              Revoke Ticket
            </a>
            <a className="dropdown-item" onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e => this.onTsSuccess(e, this.props.complaintDetail.id)}
            >
              <i className="fa fa-check"></i>TS successfull by Agent
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.handleReversePickupCancel(
                  e,
                  this.props.complaintDetail.reverse_courier_id
                    ? this.props.complaintDetail.reverse_courier_id
                    : "",
                  this.props.complaintDetail.id,
                  this.props.complaintDetail.reverse_tracking
                )
              }
            >
              <img src="../../img/Reverse-Pickup-Cancelled.svg" alt="" />
              Reverse pickup cancelled
            </a>
          </div>
        ) : null}
        {this.props.statusId === 4 ? (
          <div>
            {/* <a className="dropdown-item"  ><img src="../../img/Edit.svg" />Edit</a> */}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.directDispatch(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Direct-Dispatch-Accessory.svg" alt="" />
              Direct Dispatch
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToCategoryTeamClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Walk_in_Customer.svg" alt="" />
              Transfer to category team
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToSelfShipClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Self-Ship.svg" alt="" />
              Move to selfship
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onFreshdeskClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Freshdesk.svg" alt="" />
              Freshdesk
            </a>
            <a
              className="dropdown-item"
              onClick={e =>
                this.onRevokeTicket(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Revoke-Ticket.svg" alt="" />
              Revoke Ticket
            </a>
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e => this.onTsSuccess(e, this.props.complaintDetail.id)}
            >
              <i className="fa fa-check"></i>TS successfull by Agent
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.handleReversePickupCancel(
                  e,
                  this.props.complaintDetail.reverse_courier_id
                    ? this.props.complaintDetail.reverse_courier_id
                    : "",
                  this.props.complaintDetail.id,
                  this.props.complaintDetail.reverse_tracking
                )
              }
            >
              <img src="../../img/Reverse-Pickup-Cancelled.svg" alt="" />
              Reverse pickup cancelled
            </a>
          </div>
        ) : null}
        {this.props.statusId === 5 ? (
          <div>
            {/* <a className="dropdown-item"  ><img src="../../img/Edit.svg" />Edit</a> */}
            <a
              className="dropdown-item"
              
              onClick={this.handleRPUOpenModal}
            >
              <img src="../../img/RPU.svg" alt="" />
              Re-arrange Reverse Pickup
            </a>
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={e => this.onPhoneClick(e, this.props.complaintDetail)}
            >
              <img src="../../img/Call.svg" alt="" />
              Call{" "}
              {this.props.complaintDetail.no_of_manual_callsdone > 0 && (
                <sup className="callCount">
                  ({this.props.complaintDetail.no_of_manual_callsdone})
                </sup>
              )}
            </a>}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onRevokeTicket(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Revoke-Ticket.svg" alt="" />
              Revoke Ticket
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToPickedUpClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Product_Picked_Up.svg" alt="" />
              Product picked up
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.handleReversePickupCancel(
                  e,
                  this.props.complaintDetail.reverse_courier_id
                    ? this.props.complaintDetail.reverse_courier_id
                    : "",
                  this.props.complaintDetail.id,
                  this.props.complaintDetail.reverse_tracking
                )
              }
            >
              <img src="../../img/Reverse-Pickup-Cancelled.svg" alt="" />
              Reverse pickup cancelled
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onFreshdeskClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Freshdesk.svg" alt="" />
              Freshdesk
            </a>
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToSelfShipClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Self-Ship.svg" alt="" />
              Move to selfship
            </a>
          </div>
        ) : null}
        {this.props.statusId === 6 ? (
          <div>
            {/* <a className="dropdown-item"  ><img src="../../img/Edit.svg" />Re-arrange Reverse Pickup</a> */}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onRevokeTicket(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Revoke-Ticket.svg" alt="" />
              Revoke Ticket
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRPUOpenModal}
            >
              <img src="../../img/RPU.svg" alt="" />
              Re-arrange Reverse Pickup
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToPickedUpClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Product_Picked_Up.svg" alt="" />
              Product pickup
            </a>
            {/* <a className="dropdown-item"  onClick={(e) => this.onReversePickupCancelled(e, this.props.complaintDetail.id)}><img src="../../img/Reverse-Pickup-Cancelled.svg" alt="" />Reverse Pickup cancelled</a> */}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            {/* <a className="dropdown-item"  onClick={(e) => this.onFreshdeskClick(e, this.props.complaintDetail.id)}><img src="../../img/Edit.svg" />Freshdesk</a> */}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToSelfShipClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Self-Ship.svg" alt="" />
              Move to selfship
            </a>
            {/* <a className="dropdown-item"  onClick={(e) => this.onRevokeTicket(e, this.props.complaintDetail.id)}><img src="../../img/Edit.svg" />Revoke Ticket</a> */}
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={e => this.onPhoneClick(e, this.props.complaintDetail)}
            >
              <img src="../../img/Call.svg" alt="" />
              Call{" "}
              {this.props.complaintDetail.no_of_manual_callsdone > 0 && (
                <sup className="callCount">
                  ({this.props.complaintDetail.no_of_manual_callsdone})
                </sup>
              )}
            </a>}
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
            {/* <a className="dropdown-item"  onClick={this.handleCallbackTagOpenModal}><img src="../../img/Edit.svg" />Callback tag</a> */}
          </div>
        ) : null}
        {this.props.statusId === 7 ? (
          <div>
            {/* <a className="dropdown-item"  ><img src="../../img/Edit.svg" />Edit</a> */}
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={e => this.onPhoneClick(e, this.props.complaintDetail)}
            >
              <img src="../../img/Call.svg" alt="" />
              Call{" "}
              {this.props.complaintDetail.no_of_manual_callsdone > 0 && (
                <sup className="callCount">
                  ({this.props.complaintDetail.no_of_manual_callsdone})
                </sup>
              )}
            </a>}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToReturnReceivedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Reverse_Pickup_Received.svg" alt="" />
              Reverse pickup received
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToSelfShipDone(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Self-Ship.svg" alt="" />
              Selfship Done
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.handleReversePickupCancel(
                  e,
                  this.props.complaintDetail.reverse_courier_id
                    ? this.props.complaintDetail.reverse_courier_id
                    : "",
                  this.props.complaintDetail.id,
                  this.props.complaintDetail.reverse_tracking
                )
              }
            >
              <img src="../../img/Reverse-Pickup-Cancelled.svg" alt="" />
              Reverse Pickup cancelled
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onRevokeTicket(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Revoke-Ticket.svg" alt="" />
              Revoke Ticket
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRPUOpenModal}
            >
              <img src="../../img/RPU.svg" alt="" />
              RPU
            </a>
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            {/* <a className="dropdown-item"  onClick={this.handleCallbackTagOpenModal}><img src="../../img/Edit.svg" />Callback tag</a> */}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 8 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e => this.onSaveClick(e, this.props.complaintDetail.id)}
            >
              <img src="../../img/Approve.svg" alt="" />
              Save
            </a>
            {/* <a className="dropdown-item"  ><img src="../../img/Edit.svg" />Edit</a> */}
            {this.props.complaintDetail?.invoice_url &&
              <a
                className="dropdown-item"
                
                onClick={e =>
                  this.onInvalidInvoiceClick(e, this.props.complaintDetail.id)
                }
              >
                <img src="../../img/Invalid-Invoice.svg" alt="" />
                Invalid Invoice
              </a>}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onWarrantyExpiredClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Warranty-Expired.svg" alt="" />
              Warranty Expired
            </a>
            {/* <a className="dropdown-item"  onClick={(e) => this.onMoveToUrgentClick(e, this.props.complaintDetail.id)}><img src="../../img/WH-Urgent-Ticket.svg" alt="" />Urgent Ticket</a> */}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onFreshdeskClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Freshdesk.svg" alt="" />
              Freshdesk
            </a>
            {/* <a className="dropdown-item"  ><img src="../../img/Edit.svg" />TS Unsuccessful</a> */}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToSelfShipDone(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Self-Ship.svg" alt="" />
              Selfship Done
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onRevokeTicket(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Revoke-Ticket.svg" alt="" />
              Revoke Ticket
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRPUOpenModal}
            >
              <img src="../../img/RPU.svg" alt="" />
              RPU
            </a>
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 9 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveRegeneratePaymentLink(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Regenerate-Payment-Link.svg" alt="" />
              Regenerate payment link
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToPaymentDoneClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Payment_Done.svg" alt="" />
              Payment done
            </a>
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={e => this.onPhoneClick(e, this.props.complaintDetail)}
            >
              <img src="../../img/Call.svg" alt="" />
              Call{" "}
              {this.props.complaintDetail.no_of_manual_callsdone > 0 && (
                <sup className="callCount">
                  ({this.props.complaintDetail.no_of_manual_callsdone})
                </sup>
              )}
            </a>}
            {/* <a className="dropdown-item"  onClick={(e) => this.onMoveToUrgentClick(e, this.props.complaintDetail.id)}><img src="../../img/WH-Urgent-Ticket.svg" alt=""/>Urgent Ticket</a>
            <a className="dropdown-item"  onClick={(e) => this.onMoveToWhUrgentClick(e, this.props.complaintDetail.id)}><img src="../../img/WH-Urgent-Ticket.svg" alt=""/>WH Urgent Ticket</a> */}
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.handleCustomerNotPaying(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Not-Paying.svg" alt="" />
              Customer not paying
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 10 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToPaymentPending(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Pending.svg" alt="" />
              Payment Pending
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToSelfShipClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Self-Ship.svg" alt="" />
              Move to selfship
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToTestingOutward(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Testing-Outward.svg" alt="" />
              Testing Outward
            </a>
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={e => this.onPhoneClick(e, this.props.complaintDetail)}
            >
              <img src="../../img/Call.svg" alt="" />
              Call{" "}
              {this.props.complaintDetail.no_of_manual_callsdone > 0 && (
                <sup className="callCount">
                  ({this.props.complaintDetail.no_of_manual_callsdone})
                </sup>
              )}
            </a>}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.resendCustomerInvoice(e, this.props.complaintDetail.id)
              }
            >
              <i className="fa fa-repeat" aria-hidden="true"></i>Resend Invoice{" "}
            </a>
          </div>
        ) : null}
        {this.props.statusId === 11 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onRevokeTicket(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Revoke-Ticket.svg" alt="" />
              Revoke Ticket
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
            {this.props.complaintDetail.refund_gc === null ? (
              <a
                className="dropdown-item"
                onClick={e => this.onResendGc(e, this.props.complaintDetail.id)}
              >
                <i className="fa fa-repeat" aria-hidden="true"></i>Resend GC
              </a>
            ) : null}
          </div>
        ) : null}
        {this.props.statusId === 12 ? (
          <div>
            {/* <a className="dropdown-item"  ><img src="../../img/Edit.svg" />Edit</a> */}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToProductReceivedAtWarehouse(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Product_Received.svg" />
              Product received
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onFreshdeskClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Freshdesk.svg" alt="" />
              Freshdesk
            </a>
            {/* <a className="dropdown-item"  ><img src="../../img/Edit.svg" />Print option</a> */}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 13 ? (
          <div>
            {/* <a className="dropdown-item"  ><img src="../../img/Edit.svg" />Edit</a> */}
            <a
              className="dropdown-item"
              
              onClick={e => this.onSaveClick(e, this.props.complaintDetail.id)}
            >
              <img src="../../img/Approve.svg" alt="" />
              Save
            </a>
            {/* <a className="dropdown-item"  onClick={(e) => this.onMoveToIQCClick(e, this.props.complaintDetail.id)}><img src="../../img/IQC.svg" alt="" />IQC</a> */}
            <a
              className="dropdown-item"
              onClick={this.handleScreeningOpenModal}
            >
              <img src="../../img/Screening.svg" alt="" />
              Screening
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToTestingInward(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Testing-Inward.svg" alt="" />
              Transfer to testing inward
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onFreshdeskClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Freshdesk.svg" alt="" />
              Freshdesk
            </a>
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            {/* <a className="dropdown-item"  onClick={this.handleCallbackTagOpenModal}><img src="../../img/Edit.svg" />Callback tag</a> */}
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={e => this.onPhoneClick(e, this.props.complaintDetail)}
            >
              <img src="../../img/Call.svg" alt="" />
              Call{" "}
              {this.props.complaintDetail.no_of_manual_callsdone > 0 && (
                <sup className="callCount">
                  ({this.props.complaintDetail.no_of_manual_callsdone})
                </sup>
              )}
            </a>}
            {/* <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToOutOfStockClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Out-of-Stock.svg" alt="" />
              Out of stock
            </a> */}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 14 ? (
          <div>
            {/* <a className="dropdown-item"  ><img src="../../img/Edit.svg" />Edit</a> */}
            <a
              className="dropdown-item"
              
              onClick={e => this.onSaveClick(e, this.props.complaintDetail.id)}
            >
              <img src="../../img/Approve.svg" alt="" />
              Save
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToTestingInward(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Testing-Inward.svg" alt="" />
              Transfer to testing inward
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            {/* <a className="dropdown-item"  ><img src="../../img/Edit.svg" />Screening</a> */}
            {/* <a
              className="dropdown-item"
              
              onClick={this.handleScreeningOpenModal}
            >
              <img src="../../img/Screening.svg" alt="" />
              Screening
            </a> */}
            <a className="dropdown-item"  onClick={this.handleRPUPrint}>
              <img src="../../img/Reverse-Pickup-Print.svg" />
              Reverse Pickup Print
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onFreshdeskClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Freshdesk.svg" alt="" />
              Freshdesk
            </a>
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            {/* <a className="dropdown-item"  onClick={this.handleCallbackTagOpenModal}><img src="../../img/Edit.svg" />Callback tag</a> */}
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={e => this.onPhoneClick(e, this.props.complaintDetail)}
            >
              <img src="../../img/Call.svg" alt="" />
              Call{" "}
              {this.props.complaintDetail.no_of_manual_callsdone > 0 && (
                <sup className="callCount">
                  ({this.props.complaintDetail.no_of_manual_callsdone})
                </sup>
              )}
            </a>}
            {/* <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToOutOfStockClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Out-of-Stock.svg" alt="" />
              Out of stock
            </a> */}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
            <a
              className="dropdown-item"
              onClick={this.handleGCApproval}
            >
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Approval
            </a>
            <a
              className="dropdown-item"
              onClick={e =>
                this.handleRefundApproval(
                  e,
                  this.props.complaintDetail.order_id
                )
              }
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund Approval
            </a>
            {/* <a className="dropdown-item"  ><img src="../../img/Reverse-Pickup-Print.svg" />Reverse Pickup Print</a> */}
          </div>
        ) : null}
        {this.props.statusId === 15 ?
          (
            <div>{
              (this.props.complaintDetail?.status_name == "qc_pass" || this.props.complaintDetail?.status_name == "qc_fail") ?
                <div>
                  <a onClick={(() => this.setState({ isDispatchMOdal: true }))} className="dropdown-item"><img src="../../img/Edit.svg" />Dispatch Inward</a>
                  <a className="dropdown-item"  onClick={e => this.onSaveClick(e, this.props.complaintDetail.id)} > <img src="../../img/Approve.svg" alt="" /> Save </a>
                  <a className="dropdown-item"  onClick={e => this.onMoveToComplaintClosedClick(e, this.props.complaintDetail.id)}><img src="../../img/Complaint-Closed.svg" alt="" />Close Complaint</a>

                </div>
                :
                <div>
                  <a className="dropdown-item"  onClick={this.handleQcFail}> <img src="../../img/QC-Fail-White.svg" alt="" /> QC Fail</a>
                  <a className="dropdown-item"  onClick={this.handleQcPassOpenModal} > <img src="../../img/QC-Pass-White.svg" alt="" />QC Pass </a>
                  <a
                    className="dropdown-item"
                    onClick={this.handleRepairPaymentOpenModal}
                  >
                    <img src="../../img/Repair-Scenario.svg" alt="" />
                    Repair Payment
                  </a>
                  <a className="dropdown-item"  onClick={e => this.onSaveClick(e, this.props.complaintDetail.id)} > <img src="../../img/Approve.svg" alt="" /> Save </a>
                  <a className="dropdown-item"  onClick={e => this.onMoveToComplaintClosedClick(e, this.props.complaintDetail.id)}><img src="../../img/Complaint-Closed.svg" alt="" />Close Complaint</a>
                </div>

            }

            </div>
          ) : null}
        {this.props.statusId === 16 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e => this.onSaveClick(e, this.props.complaintDetail.id)}
            >
              <img src="../../img/Approve.svg" alt="" />
              Save
            </a>
            {/* <a className="dropdown-item"  ><img src="../../img/Edit.svg" />Transfer to dispatch outward</a> */}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a className="dropdown-item"  onClick={this.handleRPUPrint}>
              <img src="../../img/Reverse-Pickup-Print.svg" />
              Reverse Pickup Print
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToUnserviceableClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Unserviceable.svg" alt="" />
              Unserviceable Ticket
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.handleDispatchInward(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Transfer-to-dispatch-inward.svg" alt="" />
              Transfer to dispatch inward
            </a>
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 17 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e => this.onSaveClick(e, this.props.complaintDetail.id)}
            >
              <img src="../../img/Save.svg" alt="" />
              Save
            </a>
            <a
              className="dropdown-item"
              onClick={this.handleFPUOpenModal}
            >
              <img src="../../img/Edit.svg" />
              Transfer to Dispatch Outward
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            {/* <a className="dropdown-item"  onClick={this.handleRPUPrint}><img src="../../img/Reverse-Pickup-Print.svg" />Reverse Pickup Print</a> */}
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 18 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e => this.onSaveClick(e, this.props.complaintDetail.id)}
            >
              <img src="../../img/Approve.svg" alt="" />
              Save
            </a>
            {/* <a className="dropdown-item"  ><img src="../../img/Handover-to-Courier.svg" alt="" />Handover to courier</a> */}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a className="dropdown-item"  onClick={this.handleFPUPrint}>
              <img src="../../img/Forward-pickup-print.svg" />
              Forward Pickup Print
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToUnserviceableClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Unserviceable.svg" alt="" />
              Unserviceable Ticket
            </a>
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.handleForwardPickupCancel(
                  e,
                  this.props.complaintDetail.forward_courier_id
                    ? this.props.complaintDetail.forward_courier_id
                    : "",
                  this.props.complaintDetail.id,
                  this.props.complaintDetail.forward_tracking
                )
              }
            >
              <img src="../../img/Reverse-Pickup-Cancelled.svg" alt="" />
              Forward pickup cancelled
            </a>
          </div>
        ) : null}
        {this.props.statusId === 19 ? (
          <div>
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={e => this.onPhoneClick(e, this.props.complaintDetail)}
            >
              <img src="../../img/Call.svg" alt="" />
              Call{" "}
              {this.props.complaintDetail.no_of_manual_callsdone > 0 && (
                <sup className="callCount">
                  ({this.props.complaintDetail.no_of_manual_callsdone})
                </sup>
              )}
            </a>}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToRtoOutClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/RTO-Out.svg" alt="" />
              RTO Out
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleFPUOpenModal}
            >
              <img src="../../img/Re-Dispatch.svg" />
              Re-dispatch
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToUnserviceableClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Unserviceable.svg" alt="" />
              Unserviceable Ticket
            </a>
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 20 ? (
          <div>
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={e => this.onPhoneClick(e, this.props.complaintDetail)}
            >
              <img src="../../img/Call.svg" alt="" />
              Call{" "}
              {this.props.complaintDetail.no_of_manual_callsdone > 0 && (
                <sup className="callCount">
                  ({this.props.complaintDetail.no_of_manual_callsdone})
                </sup>
              )}
            </a>}
            {/* <a className="dropdown-item"  ><img src="../../img/Edit.svg" />Re-dispatch</a> */}
            <a
              className="dropdown-item"
              
              onClick={this.handleFPUOpenModal}
            >
              <img src="../../img/Re-Dispatch.svg" />
              Re-dispatch
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            {/* <a className="dropdown-item"  onClick={this.handleCallbackTagOpenModal}><img src="../../img/Edit.svg" />Callback tag</a> */}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 21 ? (
          <div>
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={e => this.onPhoneClick(e, this.props.complaintDetail)}
            >
              <img src="../../img/Call.svg" alt="" />
              Call{" "}
              {this.props.complaintDetail.no_of_manual_callsdone > 0 && (
                <sup className="callCount">
                  ({this.props.complaintDetail.no_of_manual_callsdone})
                </sup>
              )}
            </a>}
            <a
              className="dropdown-item"
              
              onClick={this.handleFPUOpenModal}
            >
              <img src="../../img/Re-Dispatch.svg" />
              Re-dispatch
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a className="dropdown-item" onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}

        {(this.props.statusId === 22 || this.props.statusId === 51) ? (
          <div>
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={e => this.onPhoneClick(e, this.props.complaintDetail)}
            >
              <img src="../../img/Call.svg" alt="" />
              Call{" "}
              {this.props.complaintDetail.no_of_manual_callsdone > 0 && (
                <sup className="callCount">
                  ({this.props.complaintDetail.no_of_manual_callsdone})
                </sup>
              )}
            </a>}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToSelfShipClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Self-Ship.svg" alt="" />
              Move to selfship
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshdeskMovePolicyClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Move-to-Policy.svg" alt="" />
              Move to policy
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshdeskEmptyBoxClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Empty-Box.svg" alt="" />
              Empty Box received
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshdeskWrongProductClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Wrong-Product.svg" alt="" />
              Wrong product received
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshdeskRPUClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Reverse-Pickup-Received.svg" alt="" />
              Reverse pickup received
            </a>
            <a className="dropdown-item" onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshdeskTblstCallClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Troubleshoot.svg" alt="" />
              Troubleshoot
            </a>
            <a
              className="dropdown-item"
              onClick={this.handleRequestPaymentOpenModal}
            >
              <img src="../../img/Request-Payment.svg" />
              Request payment
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.directDispatch(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Direct-Dispatch-Accessory.svg" alt="" />
              Direct Dispatch
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleGCApproval}
            >
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Approval
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.handleRefundApproval(
                  e,
                  this.props.complaintDetail.order_id
                )
              }
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund Approval
            </a>
            <a
              className="dropdown-item"
              
              onClick={e => this.handleCosmos()}
            >
              <img src="../../img/Freshdesk.svg" alt="" />
              Cosmos
            </a>
          </div>
        ) : null}
        {this.props.statusId === 23 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveRegeneratePaymentLink(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Regenerate-Payment-Link.svg" alt="" />
              Regenerate payment link
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToPaymentDoneClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Payment_Done.svg" alt="" />
              Payment Done
            </a>
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={e => this.onPhoneClick(e, this.props.complaintDetail)}
            >
              <img src="../../img/Call.svg" alt="" />
              Call{" "}
              {this.props.complaintDetail.no_of_manual_callsdone > 0 && (
                <sup className="callCount">
                  ({this.props.complaintDetail.no_of_manual_callsdone})
                </sup>
              )}
            </a>}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshdeskTblstCallClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Troubleshoot.svg" alt="" />
              Troubleshoot
            </a>
            <a className="dropdown-item" onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.directDispatch(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Direct-Dispatch-Accessory.svg" alt="" />
              Direct Dispatch
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 24 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.directDispatch(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Direct-Dispatch-Accessory.svg" alt="" />
              Direct Dispatch
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.resendCustomerInvoice(e, this.props.complaintDetail.id)
              }
            >
              <i className="fa fa-repeat" aria-hidden="true"></i>Resend Invoice
            </a>
          </div>
        ) : null}
        {this.props.statusId === 25 ? (
          <div>
            {/* <a className="dropdown-item"  ><img src="../../img/Edit.svg" />Reverse pickup</a> */}
            {/* <a className="dropdown-item"  ><img src="../../img/Direct-Dispatch-Accessory.svg" />Direct dispatch</a> */}
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={e => this.onPhoneClick(e, this.props.complaintDetail)}
            >
              <img src="../../img/Call.svg" alt="" />
              Call{" "}
              {this.props.complaintDetail.no_of_manual_callsdone > 0 && (
                <sup className="callCount">
                  ({this.props.complaintDetail.no_of_manual_callsdone})
                </sup>
              )}
            </a>}
            <a className="dropdown-item" onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshdeskTblstCallClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Troubleshoot.svg" alt="" />
              Troubleshoot
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.directDispatch(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Direct-Dispatch-Accessory.svg" alt="" />
              Direct Dispatch
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 26 ? (
          <div>
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={e => this.onPhoneClick(e, this.props.complaintDetail)}
            >
              <img src="../../img/Call.svg" alt="" />
              Call{" "}
              {this.props.complaintDetail.no_of_manual_callsdone > 0 && (
                <sup className="callCount">
                  ({this.props.complaintDetail.no_of_manual_callsdone})
                </sup>
              )}
            </a>}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshdeskClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Freshdesk.svg" alt="" />
              Move to freshdesk
            </a>
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.directDispatch(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Direct-Dispatch-Accessory.svg" alt="" />
              Direct Dispatch
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            {this.props.complaintDetail.buyback ? (
              <a
                className="dropdown-item"
                
                onClick={this.handleRegenrateCoupon}
              >
                <img src="../../img/Refund.svg" alt="" />
                Regenrate Coupons
              </a>
            ) : null}
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 27 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={this.handleReturnPolicy}
            >
              <img src="../../img/Refund.svg" alt="" />
              Return Policy
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToSelfShipClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Self-Ship.svg" alt="" />
              Move to selfship
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshdeskClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Freshdesk.svg" alt="" />
              Move to freshdesk
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.directDispatch(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Direct-Dispatch-Accessory.svg" alt="" />
              Direct Dispatch
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 28 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={this.handleRPUOpenModal}
            >
              <img src="../../img/RPU.svg" alt="" />
              RPU
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToSelfShipClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Self-Ship.svg" alt="" />
              Move to selfship
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 30 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onProductPickedUp(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Product_Picked_Up.svg" alt="" />
              Product picked up
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.handleReversePickupCancel(
                  e,
                  this.props.complaintDetail.reverse_courier_id
                    ? this.props.complaintDetail.reverse_courier_id
                    : "",
                  this.props.complaintDetail.id,
                  this.props.complaintDetail.reverse_tracking
                )
              }
            >
              <img src="../../img/Reverse-Pickup-Cancelled.svg" alt="" />
              Reverse Pickup cancelled
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a className="dropdown-item" onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 33 || this.props.statusId === 24 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onFreshdeskClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Freshdesk.svg" alt="" />
              Freshdesk
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToTestingInward(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Testing-Inward.svg" alt="" />
              Transfer to testing inward
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToTestingOutward(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Testing-Outward.svg" alt="" />
              Testing Outward
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.handleDispatchInward(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Transfer-to-dispatch-inward.svg" alt="" />
              Transfer to dispatch inward
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              onClick={this.handleScreeningOpenModal}
            >
              <img src="../../img/Screening.svg" alt="" />
              Screening
            </a>
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={e => this.onPhoneClick(e, this.props.complaintDetail)}
            >
              <img src="../../img/Call.svg" alt="" />
              Call{" "}
              {this.props.complaintDetail.no_of_manual_callsdone > 0 && (
                <sup className="callCount">
                  ({this.props.complaintDetail.no_of_manual_callsdone})
                </sup>
              )}
            </a>}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToIQCClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/IQC.svg" alt="" />
              IQC
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 34 ? (
          <div>
            {/* <a className="dropdown-item"  ><img src="../../img/Edit.svg" />Edit</a> */}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.directDispatch(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Direct-Dispatch-Accessory.svg" alt="" />
              Direct Dispatch
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToCategoryTeamClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Walk_in_Customer.svg" alt="" />
              Transfer to category team
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToSelfShipClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Self-Ship.svg" alt="" />
              Move to selfship
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onFreshdeskClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Freshdesk.svg" alt="" />
              Freshdesk
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onRevokeTicket(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Revoke-Ticket.svg" alt="" />
              Revoke Ticket
            </a>
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.handleReversePickupCancel(
                  e,
                  this.props.complaintDetail.reverse_courier_id
                    ? this.props.complaintDetail.reverse_courier_id
                    : "",
                  this.props.complaintDetail.id,
                  this.props.complaintDetail.reverse_tracking
                )
              }
            >
              <img src="../../img/Reverse-Pickup-Cancelled.svg" alt="" />
              Reverse pickup cancelled
            </a>
          </div>
        ) : null}
        {this.props.statusId === 35 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onRevokeTicket(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Revoke-Ticket.svg" alt="" />
              Back to stock
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
            <a
              className="dropdown-item"
              onClick={this.handleGCApproval}
            >
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Approval
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.handleRefundApproval(
                  e,
                  this.props.complaintDetail.order_id
                )
              }
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund Approval
            </a>
          </div>
        ) : null}
        {this.props.statusId === 41 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.handleForwardPickupCancel(
                  e,
                  this.props.complaintDetail.forward_courier_id
                    ? this.props.complaintDetail.forward_courier_id
                    : "",
                  this.props.complaintDetail.id,
                  this.props.complaintDetail.forward_tracking
                )
              }
            >
              <img src="../../img/Reverse-Pickup-Cancelled.svg" alt="" />
              Forward pickup cancel{" "}
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToRtoInClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/RTO.svg" alt="" />
              RTO In
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={e => this.onPhoneClick(e, this.props.complaintDetail)}
            >
              <img src="../../img/Call.svg" alt="" />
              Call{" "}
              {this.props.complaintDetail.no_of_manual_callsdone > 0 && (
                <sup className="callCount">
                  ({this.props.complaintDetail.no_of_manual_callsdone})
                </sup>
              )}
            </a>}
            <a className="dropdown-item" onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
            <a className="dropdown-item"  onClick={this.handleFPUPrint}>
              <img src="../../img/Forward-pickup-print.svg" />
              Forward Pickup Print
            </a>
          </div>
        ) : null}
        {this.props.statusId === 42 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={e => this.onPhoneClick(e, this.props.complaintDetail)}
            >
              <img src="../../img/Call.svg" alt="" />
              Call{" "}
              {this.props.complaintDetail.no_of_manual_callsdone > 0 && (
                <sup className="callCount">
                  ({this.props.complaintDetail.no_of_manual_callsdone})
                </sup>
              )}
            </a>}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToRtoInClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/RTO.svg" alt="" />
              RTO In
            </a>
            <a className="dropdown-item"  onClick={this.handleRPUPrint}>
              <img src="../../img/Reverse-Pickup-Print.svg" />
              Reverse Pickup Print
            </a>
            <a className="dropdown-item"  onClick={this.handleFPUPrint}>
              <img src="../../img/Forward-pickup-print.svg" />
              Forward Pickup Print
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.handleForwardPickupCancel(
                  e,
                  this.props.complaintDetail.forward_courier_id
                    ? this.props.complaintDetail.forward_courier_id
                    : "",
                  this.props.complaintDetail.id,
                  this.props.complaintDetail.forward_tracking
                )
              }
            >
              <img src="../../img/Reverse-Pickup-Cancelled.svg" alt="" />
              Forward pickup cancelled
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 43 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshdeskTblstCallClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Troubleshoot.svg" alt="" />
              Troubleshoot
            </a>
            {/* <a className="dropdown-item"  ><img src="../../img/" alt="" />Ticket details </a> */}
            <a className="dropdown-item"  onClick={this.handleRPUPrint}>
              <img src="../../img/Reverse-Pickup-Print.svg" />
              Reverse Pickup Print
            </a>
            <a className="dropdown-item"  onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRPUOpenModal}
            >
              <img src="../../img/RPU.svg" alt="" />
              Re-arrange Reverse Pickup
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToWalkingReceivedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Walk_in_Customer.svg" alt="" />
              Walk-in Customer
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToSelfShipClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Self-Ship.svg" alt="" />
              Move to selfship
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.handleReversePickupCancel(
                  e,
                  this.props.complaintDetail.reverse_courier_id
                    ? this.props.complaintDetail.reverse_courier_id
                    : "",
                  this.props.complaintDetail.id,
                  this.props.complaintDetail.reverse_tracking
                )
              }
            >
              <img src="../../img/Reverse-Pickup-Cancelled.svg" alt="" />
              Reverse pickup cancelled
            </a>
            {/* <a className="dropdown-item"  onClick={(e) => this.onRevokeTicket(e, this.props.complaintDetail.id)}><img src="../../img/Revoke-Ticket.svg" alt="" />Unmarked - Not Gc case</a> */}
            {this.props.complaintDetail.refund_ticket === 1 ? (
              <a
                className="dropdown-item"
                
                onClick={e =>
                  this.onUnmarkedTicketRefund(e, this.props.complaintDetail.id)
                }
              >
                <img src="../../img/Revoke-Ticket.svg" alt="" />
                Unmarked Refund{" "}
              </a>
            ) : null}
            {this.props.complaintDetail.refund_gc === 1 ? (
              <a
                className="dropdown-item"
                
                onClick={e =>
                  this.onUnmarkedTicketGC(e, this.props.complaintDetail.id)
                }
              >
                <img src="../../img/Revoke-Ticket.svg" alt="" />
                Unmarked GC{" "}
              </a>
            ) : null}
            {/* <a className='dropdown-item'  onclick={this.handleGCModal}><img src="../../img/SMS.svg" alt=""/> GC Approve </a> */}
            {/* <a className='dropdown-item'  onclick={this.handleGCModal}><img src="../../img/SMS.svg" alt=""/> GC Approve </a> */}
            {/* <a className="dropdown-item"  onClick={this.handleGCModal}><img src="../../img/Refund.svg" alt="" />GC approve</a> */}
          </div>
        ) : null}
        {this.props.statusId === 44 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToDuplicateComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Duplicate Close Complaint
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onFreshdeskClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Freshdesk.svg" alt="" />
              Freshdesk
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshdeskTblstCallClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Troubleshoot.svg" alt="" />
              Troubleshoot
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToSelfShipClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Self-Ship.svg" alt="" />
              Move to selfship
            </a>
            {this.props.complaintDetail?.invoice_url &&
              <a
                className="dropdown-item"
                
                onClick={e =>
                  this.onInvalidInvoiceClick(e, this.props.complaintDetail.id)
                }
              >
                <img src="../../img/Invalid-Invoice.svg" alt="" />
                Invalid Invoice
              </a>}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onWarrantyExpiredClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Warranty-Expired.svg" alt="" />
              Warranty Expired
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onProductMismatch(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Invalid-Invoice.svg" alt="" />
              On Product Mismatch
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>}
            <a className="dropdown-item" onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
          </div>
        ) : null}
        {this.props.statusId === 45 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            <a className="dropdown-item" onClick={this.handleGCCase}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Case
            </a>
            <a
              className="dropdown-item"
              onClick={this.handleRefundSubmit}
            >
              <img src="../../img/Refund.svg" alt="" />
              Refund
            </a>
            {!(this.props.complaintDetail.product_id == "239") && <a
              className="dropdown-item"
              
              onClick={e => this.onPhoneClick(e, this.props.complaintDetail)}
            >
              <img src="../../img/Call.svg" alt="" />
              Call{" "}
              {this.props.complaintDetail.no_of_manual_callsdone > 0 && (
                <sup className="callCount">
                  ({this.props.complaintDetail.no_of_manual_callsdone})
                </sup>
              )}
            </a>}
            {/* <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToOutOfStockClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Out-of-Stock.svg" alt="" />
              Out of stock
            </a> */}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToRtoInClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/RTO.svg" alt="" />
              RTO In
            </a>
            <a className="dropdown-item" onClick={this.handleRPUPrint}>
              <img src="../../img/Reverse-Pickup-Print.svg" />
              Reverse Pickup Print
            </a>
            <a className="dropdown-item"  onClick={this.handleFPUPrint}>
              <img src="../../img/Forward-pickup-print.svg" />
              Forward Pickup Print
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.handleForwardPickupCancel(
                  e,
                  this.props.complaintDetail.forward_courier_id
                    ? this.props.complaintDetail.forward_courier_id
                    : "",
                  this.props.complaintDetail.id,
                  this.props.complaintDetail.forward_tracking
                )
              }
            >
              <img src="../../img/Reverse-Pickup-Cancelled.svg" alt="" />
              Forward pickup cancelled
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 46 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
            {this.props.complaintDetail?.invoice_url &&
              <a
                className="dropdown-item"
                
                onClick={e =>
                  this.onInvalidInvoiceClick(e, this.props.complaintDetail.id)
                }
              >
                <img src="../../img/Invalid-Invoice.svg" alt="" />
                Invalid Invoice
              </a>}
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onWarrantyExpiredClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Warranty-Expired.svg" alt="" />
              Warranty Expired
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleRPUOpenModal}
            >
              <img src="../../img/RPU.svg" alt="" />
              RPU
            </a>
            <a className="dropdown-item"  onClick={this.handleRejected}>
              <img src="../../img/Reject.svg" alt="" />
              Reject
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToFreshProductClick(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Fresh-Product.svg" alt="" />
              Fresh Product
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleSendEmail}
            >
              <img src="../../img/SMS.svg" />
              Call Reminder
            </a>
          </div>
        ) : null}
        {this.props.statusId === 47 ? (
          <div>
            {/* <a className="dropdown-item"  onClick={(e) => this.onUnmarkedTicketRefund(e, this.props.complaintDetail.id)}><img src="../../img/Revoke-Ticket.svg" alt="" />Unmarked Refund </a> */}
          </div>
        ) : null}
        {this.props.statusId === 48 &&
          (userRoleId === "1" || userRoleId === "2") ? (
          <div>
            <a className="dropdown-item" onClick={this.handleGCModal}>
              <img src="../../img/Gift-Card.svg" alt="" />
              GC Approve
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onUnmarkedTicketGC(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Revoke-Ticket.svg" alt="" />
              Unmarked GC{" "}
            </a>
          </div>
        ) : null}
        {this.props.statusId === 51 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.oosTestingInward(this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Testing-Inward.svg" alt="" />
              Testing inward
            </a>
          </div>
        ) : null}
        {this.props.statusId === 113 ? (
          <div>
            <a
              className="dropdown-item"
              onClick={e =>
                this.readyToDispatch(e, this.props.complaintDetail.id)
              }
            >
              <img src="../../img/Gift-Card.svg" alt="" />
              Ready to dispatch
            </a>
            <a
              className="dropdown-item"
              
              onClick={e =>
                this.onMoveToComplaintClosedClick(
                  e,
                  this.props.complaintDetail.id
                )
              }
            >
              <img src="../../img/Complaint-Closed.svg" alt="" />
              Close Complaint
            </a>
          </div>
        ) : null}

        {this.props.statusId === 114 ? (
          <div>
            <a
              className="dropdown-item"
              
              onClick={this.handleDispatchInward}
            >
              <img src="../../img/Transfer-to-dispatch-inward.svg" alt="" />
              Transfer to dispatch inward
            </a>
            <a
              className="dropdown-item"
              
              onClick={this.handleFPUOpenModal}
            >
              <img src="../../img/Edit.svg" />
              Transfer to Dispatch Outward
            </a>
            {/* <a className="dropdown-item"  onClick={this.handleFPUOpenModal}><img src="../../img/Edit.svg" />FPU</a> */}
            <a className="dropdown-item"  onClick={this.handleFPUPrint}>
              <img src="../../img/Forward-pickup-print.svg" />
              Forward Pickup Print
            </a>
          </div>
        ) : null}

        <ReactModal
          isOpen={this.state.showGCApprovalModal}
          contentLabel="Minimal Modal Example"
          style={customStyles}
        >
          <h2 id="rModal_head" style={{ "margin-bottom": "30px" }}>
            GC Approval
          </h2>
          <button id="close_rModal" onClick={this.handleGCApprovalCloseModal}>
            <i className="fas fa-times"></i>
          </button>
          <div className="gc_wrapper">
            <label>Order Id</label>
            <input
              type="text"
              value={this.state.order_id}
              onChange={this.handleGCApprovalCase}
              placeholder="Enter Order ID"
            ></input>
            <div className="error_add">
              <span className="errorShow">{this.state.errors["order_id"]}</span>
            </div>
          </div>
          <div className="gc_wrapper">
            <label>User</label>
            <select onChange={this.handleAdminStatus} id="select_aaModal">
              <option value={"0"} defaultValue="selected">
                Select User
              </option>
              {this.props.adminUserlist.data &&
                this.props.adminUserlist.data.map((obj, index) => (
                  <option value={obj.user_id} key={index}>
                    {obj.first_name}
                    {obj.last_name}
                  </option>
                ))}
            </select>
            <div className="error_add">
              <span className="errorShow">
                {this.state.errors["adminStatus"]}
              </span>
            </div>
            <div>
              <button
                id="send_raModal"
                type="button"
                onClick={this.onClickGCApprovalSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={this.state.showGcModal}
          contentLabel="Minimal Modal Example"
          style={CustomGCStyle}
        >
          <h2 id="rModal_head">GC Case</h2>
          <button id="close_rModal" onClick={this.handleGCCaseCloseModal}>
            <i className="fas fa-times"></i>
          </button>
          <div className="gc_wrapper">
            <label style={{ color: "aliceblue" }}>Order Id</label>
            <input
              type="text"
              value={this.state.order_id}
              onChange={this.handleRefundCase}
              placeholder="Enter Order ID"
            ></input>
            <div className="error_add">
              <span className="errorShow">{this.state.errors["order_id"]}</span>
            </div>
          </div>
          <div className="gc_wrapper">
            <label style={{ color: "aliceblue" }}>Approved By</label>
            <select onChange={this.handleAdminStatus} id="select_aaModal">
              <option value={"0"} defaultValue="selected">
                Select User
              </option>
              {this.props.adminUserlist.data &&
                this.props.adminUserlist.data.map((obj, index) => (
                  <option value={obj.user_id} key={index}>
                    {obj.first_name}
                    {obj.last_name}
                  </option>
                ))}
            </select>
            <div className="error_add">
              <span className="errorShow">
                {this.state.errors["adminStatus"]}
              </span>
            </div>
            <div>
              <button
                id="send_raModal"
                type="button"
                onClick={this.handleGCCaseSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={this.state.showRefundApproval}
          contentLabel="Minimal Modal Example"
          style={customStyles}
        >
          <h2 id="rModal_head" style={{ "margin-bottom": "30px" }}>
            Refund Approval
          </h2>
          <button
            id="close_rModal"
            onClick={this.handleRefundApprovalCloseModal}
          >
            <i className="fas fa-times"></i>
          </button>
          <div className="gc_wrapper">
            <label>Order Id</label>
            <input
              type="text"
              value={this.state.order_id}
              onChange={this.handleRefundCase}
              placeholder="Enter Order ID"
            ></input>
            <div className="error_add">
              <span className="errorShow">{this.state.errors["order_id"]}</span>
            </div>
          </div>
          <div className="gc_wrapper">
            <label>User</label>
            <select onChange={this.handleAdminStatus} id="select_aaModal">
              <option value={"0"} defaultValue="selected">
                Select User
              </option>
              {this.props.adminUserlist.data &&
                this.props.adminUserlist.data.map((obj, index) => (
                  <option value={obj.user_id} key={index}>
                    {obj.first_name}
                    {obj.last_name}
                  </option>
                ))}
            </select>
            <div className="error_add">
              <span className="errorShow">
                {this.state.errors["adminStatus"]}
              </span>
            </div>
            <div>
              <button
                id="send_raModal"
                type="button"
                onClick={this.onClickRefundApprovalSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={this.state.showRefundModal}
          contentLabel="Minimal Modal Example"
          style={customStyles}
        >
          <h2 id="rModal_head" style={{ "margin-bottom": "30px" }}>
            Refund Case
          </h2>
          <button id="close_rModal" onClick={this.handleRefundCloseModal}>
            <i className="fas fa-times"></i>
          </button>
          <div className="gc_wrapper">
            <label>Order Id</label>
            <input
              type="text"
              value={this.state.order_id}
              onChange={this.handleRefundCase}
              placeholder="Enter OrderID"
            ></input>
            <div className="error_add">
              <span className="errorShow">{this.state.errors["order_id"]}</span>
            </div>
          </div>
          <div className="gc_wrapper">
            <label>User</label>
            <select onChange={this.handleAdminStatus} id="select_aaModal">
              <option value={"0"} defaultValue="selected">
                Select User
              </option>
              {this.props.adminUserlist.data &&
                this.props.adminUserlist.data.map((obj, index) => (
                  <option value={obj.user_id} key={index}>
                    {obj.first_name}
                    {obj.last_name}
                  </option>
                ))}
            </select>
            <div className="error_add">
              <span className="errorShow">
                {this.state.errors["adminStatus"]}
              </span>
            </div>
            <div>
              <button
                id="send_raModal"
                type="button"
                onClick={this.onClickRefundCaseSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </ReactModal>
        {/* <ReactModal
          isOpen={this.state.showCallbackTagModal}
          contentLabel="Minimal Modal Example"
          style={customStyles}
        >
          <h2 id="rModal_head">Callback Reminder</h2>
          <button id="close_rModal" onClick={this.handleCallbackTagCloseModal}><i className="fas fa-times"></i></button>
          <div>
            <label for="callback-tag">Callback Date</label>
            <input type="date" value={this.state.callbackDate} onChange={this.handleCallbackDate} />
          </div>
          <div>
            <label>Reason</label>
            <textarea rows="4" cols="50" name="comment" form="usrform" value={this.state.callbackReason} onChange={this.handleCallbackReasonChange}></textarea>
          </div>
          <button id="send_rModal" type="button" onClick={this.handleCallbackSubmit}>Save</button>
        </ReactModal> */}
        <ReactModal
          isOpen={this.state.showGCApproveModal}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          ariaHideApp={false}
        >
          <h2 id="rModal_head">GC Refund</h2>
          <button id="close_rModal" onClick={this.handleGCCloseModal}>
            <i className="fas fa-times"></i>
          </button>
          {/* <div className="gc_wrapper">
            <label>Order Id</label>
            <input type="text" value={this.state.orderId} onChange={this.handleGCApproveText}></input>
            <div className="error_add">
              <span className="errorShow">{this.state.errors["orderId"]}</span>
            </div>
          </div> */}
          <div className="gc_wrapper">
            <label>Amount</label>
            <input
              type="text"
              value={this.state.GC_amount}
              onChange={this.handleGCAmmount}
              placeholder="Enter amount...."
            ></input>
            <div className="error_add">
              <span className="errorShow">
                {this.state.errors["GC_amount"]}
              </span>
            </div>
          </div>
          <div className="gc_wrapper">
            <label>Notes</label>
            <textarea
              style={{ width: "90%" }}
              type="text"
              value={this.state.refundNote}
              onChange={this.handleRefundNotes}
            ></textarea>
            <div className="error_add">
              <span className="errorShow">
                {this.state.errors["refundNote"]}
              </span>
            </div>
          </div>
          {this.state.loadingInModal ? (
            <button id="send_rModal" type="button">
              <Loader type="Oval" color="Grey" height={25} width={25} />
            </button>
          ) : (
            <button
              id="send_rModal"
              type="button"
              onClick={this.onClickGCSubmit}
            >
              Save
            </button>
          )}
        </ReactModal>
        <ReactModal
          isOpen={this.state.showResendModal}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          ariaHideApp={false}
        >
          <h2 id="rModal_head">Resend case</h2>
          <button id="close_rModal" onClick={this.handleResendCloseModl}>
            <i className="fas fa-times"></i>
          </button>
          <div className="gc_wrapper">
            <select
              style={{ "margin-bottom": "3%" }}
              id="select_rModal"
              className="form-control"
              onChange={this.handleResendChange}
            >
              <option value={"0"} defaultValue="selected">
                Select Reason
              </option>
              <option value={"1"} name="GC Not Found">
                GC Not Found
              </option>
              <option value={"2"} name="Mail missed">
                Mail missed
              </option>
              <option value={"3"} name="Mail Deleted">
                Mail Deleted
              </option>
            </select>
            <div className="error_add">
              <span className="errorShow">{this.state.errors["resendId"]}</span>
            </div>
          </div>
          {this.state.loadingInModal ? (
            <button id="send_rModal" type="button">
              <Loader type="Oval" color="Grey" height={25} width={25} />
            </button>
          ) : (
            <button
              id="send_rModal"
              type="button"
              onClick={this.onResendSubmit}
            >
              Change
            </button>
          )}
        </ReactModal>
        {/* <ReactModal 
        isOpen={this.state.showGCApproveModal}
        contentLabel="minimal Modal Example"
        style={customStyles}
        ariaHideApp={false}>
        </ReactModal> */}
        <ReactModal
          isOpen={this.state.showScreeningModal}
          contentLabel="Minimal Modal Example"
          style={customStyles}
        >
          <div className="ScreeningHeader">
            <h2 id="rModal_head">Screening</h2>
            <button id="close_rModal" onClick={this.handleScreeningCloseModal}>
              <i className="fas fa-times"></i>
            </button>
          </div>

          {/* {(this.props.complaintDetail?.product?.product_group_id == 2 && !(this.props.complaintDetail?.old_mac_id == "DOA" || this.props.complaintDetail?.old_mac_id == "NON-NFA")) &&
            <div className="screening-list" style={{ marginTop: "10%", paddingBottom: "7%" }}>
              <div >
                <input
                  type="checkbox"
                  name={"MAC ID Verify"}
                  value={"is_verified"}
                  onChange={this.handleMACID}
                  style={{ marginTop: "-8px" }}
                />
                <label for="subscribeNews" className="screening-label" style={{ marginTop: "-10px" }}>
                  {"MACID verify"}
                </label>
              </div>
            </div>} */}
          <div className="screening-list" style={(this.props.complaintDetail?.product?.product_group_id == 2) ? { paddingTop: "0%" } : { paddingTop: "15%" }}>
            {this.state.screenOptionsArray.map((so, index) => (
              <div className="ScrenningLablel" key={index}>
                <input
                  type="checkbox"
                  name={so.key}
                  onChange={this.handleChangeChk}
                  checked={so.isChecked || false}
                />
                <label for="subscribeNews" className="screening-label">
                  {so.name}
                </label>
              </div>
            ))}
          </div>
          <button
            id="send_rModal"
            type="button"
            onClick={this.handleScreeningSubmit}
          >
            Submit
          </button>
        </ReactModal>
        <ReactModal
          isOpen={this.state.showRPUModal}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          ariaHideApp={false}
        >
          <h2 id="rModal_head">RPU</h2>
          <button id="close_rModal" onClick={this.handleRPUCloseModal}>
            <i className="fas fa-times"></i>
          </button>
          <select
            id="select_rModal"
            className="form-control"
            onChange={this.handleCourierChange}
          >
            <option value={"0"} defaultValue="selected">
              Select Courier
            </option>
            {this.props.courierData.map(obj => (
              <option value={obj.courier_id}>
                {obj.courier_name} - {obj.pincode} - Rs.{obj.reverse_cost}
              </option>
            ))}
          </select>
          {this.state.isLoadingGif ? (
            <button id="send_rModal" type="button">
              <img
                className="loaderCTA"
                src="/img/loading-gif.gif"
                alt="Loader"
              />
            </button>
          ) : (
            <button
              id="send_rModal"
              type="button"
              onClick={this.handleRPUSubmit}
            >
              Send
            </button>
          )}
        </ReactModal>
        <ReactModal
          isOpen={this.state.showFPUModal}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          ariaHideApp={false}
        >
          <h2 id="rModal_head">Forward Pickup</h2>
          <button id="close_rModal" onClick={this.handleFPUCloseModal}>
            <i className="fas fa-times"></i>
          </button>
          <select
            id="select_rModal"
            className="form-control"
            onChange={this.handleCourierChange}
          >
            <option value={"0"} defaultValue="selected">
              Select Courier
            </option>
            {this.props.courierData.map(obj => (
              <option value={obj.courier_id}>
                {obj.courier_name} - {obj.pincode} - Rs.{obj.reverse_cost}
              </option>
            ))}
          </select>
          {this.state.isLoadingGif ? (
            <button id="send_rModal" type="button">
              <img
                className="loaderCTA"
                src="/img/loading-gif.gif"
                alt="Loader"
              />
            </button>
          ) : (
            <button
              id="send_rModal"
              type="button"
              onClick={this.handleFPUSubmit}
            >
              Send
            </button>
          )}
        </ReactModal>
        {/* Screening print modal */}
        <ReactModal
          isOpen={this.state.showScreeningPrintModal}
          contentLabel="Minimal Modal Example"
          style={customStylesPrint}
          ariaHideApp={false}
        >
          <button
            type="button"
            onClick={this.handlePrintModalClose}
            id="close_rModal"
          >
            <i className="fas fa-times"></i>
          </button>
          <ComponentToPrint ref={el => (this.componentRef = el)} />
        </ReactModal>
        {/* RPU print modal */}
        <ReactModal
          isOpen={this.state.showRPUPrintModal}
          contentLabel="Minimal Modal Example"
          style={customStylesRPU}
          ariaHideApp={false}
          className="rpu_print"
        >
          <button
            type="button"
            onClick={this.handlePrintModalClose}
            id="close_rModal"
          >
            <i className="fas fa-times"></i>
          </button>
          <ComponentToRPUPrint ref={el => (this.componentRef = el)} />
        </ReactModal>
        {/* FPU print modal */}
        <ReactModal
          isOpen={this.state.showFPUPrintModal}
          contentLabel="Minimal Modal Example"
          style={customStylesRPU}
          ariaHideApp={false}
          className="rpu_print"
        >
          <button
            type="button"
            onClick={this.handlePrintModalClose}
            id="close_rModal"
          >
            <i className="fas fa-times"></i>
          </button>
          <ComponentToFPUPrint ref={el => (this.componentRef = el)} />
        </ReactModal>
        <ReactModal
          isOpen={this.state.showRequestPayment}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          ariaHideApp={false}
          className="request_payment"
        >
          <h2 id="rModal_head">Request Payment</h2>
          <button
            onClick={this.handleRequestPaymentCloseModal}
            id="close_rModal"
          >
            <i className="fas fa-times"></i>
          </button>
          <div id="rp_wrapper">
            <div>
              <label style={{ color: "white" }}>Options</label>
              <select onChange={this.handleOptionStatus} id="select_pModal">
                <option value={"0"} defaultValue="selected">
                  Select type
                </option>
                <option value="repaired">Repair</option>
                <option value="upgrade">Upgrade</option>
                <option value="buds_only">Buds only</option>
                <option value="charging_cable">Charging cable</option>
                <option value="charging_strap">Charging Strap</option>
                <option value="complete_product">Complete product</option>
                <option value="Only_charging_case">Only charging cases</option>
                <option value="reverse_shipping_charges">
                  Reverse shipping charges
                </option>
              </select>
            </div>
            <div id="qctext">
              <label style={{ color: "white", marginRight: "5%" }}>Cost</label>
              <input
                className="costInput"
                type="text"
                onChange={this.handleCostReqPayStatus}
                placeholder="Enter amount"
              ></input>
            </div>
            {this.state.showProdList ? (
              <div className="productSelect">
                <label style={{ color: "white" }}>Product</label>
                <select className="selection" onChange={this.handleProductList}>
                  <option value={"0"} defaultValue="selected">
                    Search Product
                  </option>
                  {this.props.productData.data.products.map((p, index) => (
                    <option key={index} value={p.product_color_id}>
                      {p.product_name} ({p.size && ("Size " + p.size + " | ")} {p.color_name})
                    </option>
                  ))}
                </select>
              </div>
            ) : null}
          </div>
          <button
            id="send_rModal"
            type="button"
            onClick={this.handleRequestPaymentSubmit}
          >
            Send
          </button>
        </ReactModal>
        <ReactModal
          isOpen={this.state.selectProduct}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          ariaHideApp={false}
          className="selectProduct"
        >
          <h2 id="rModal_head">Select Type</h2>
          <button id="close_rModal" onClick={this.handleSelectProductClose}>
            <i className="fas fa-times"></i>
          </button>
          <div className="gc_wrapper">
            <label>Options</label>
            <select onChange={this.handleProductSelectionChange}>
              <option value="direct" defaultValue="selected">
                Product
              </option>
              <option value="accessory">Accessory</option>
            </select>
          </div>
          <button
            id="send_rModal"
            type="button"
            onClick={this.handleDirectDispatch}
          >
            Send
          </button>
        </ReactModal>
        <ReactModal
          isOpen={this.state.selectProductF}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          ariaHideApp={false}
          className="selectProduct"
        >
          <h2 id="rModal_head">Select Type</h2>
          <button
            id="close_rModal"
            onClick={this.handleSelectProductFreshdeskClose}
          >
            <i className="fas fa-times"></i>
          </button>
          <div className="gc_wrapper">
            <label>Options</label>
            <select onChange={this.handleProductSelectionChange}>
              <option value="direct" defaultValue="selected">
                Product
              </option>
              <option value="accessory">Accessory</option>
            </select>
          </div>
          {this.props.courierData && (
            <select
              id="select_rModal"
              className="form-control"
              onChange={this.handleCourierChange}
            >
              <option value={"0"} defaultValue="selected">
                Select Courier
              </option>
              {this.props.courierData.map(obj => (
                <option value={obj.courier_id}>
                  {obj.courier_name} - {obj.pincode} - Rs.{obj.reverse_cost}
                </option>
              ))}
            </select>
          )}
          <button
            id="send_rModal"
            type="button"
            onClick={this.handleDirectDispatchFreshdesk}
          >
            Send
          </button>
        </ReactModal>
        <ReactModal
          isOpen={this.state.showRepairPayment}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          ariaHideApp={false}
          className="repair_payment"
        >
          <h2 id="rModal_head">Repair Payment</h2>
          <button
            id="close_rModal"
            onClick={this.handleRepairPaymentCloseModal}
          >
            <i className="fas fa-times"></i>
          </button>
          <div className="gc_wrapper">
            <label>Options</label>
            <select
              onChange={this.handleRepairPaymentChange}
              id="select_aaModal"
            >
              <option value={"0"} defaultValue="selected">
                Select
              </option>
              <option value="hardware">Hardware</option>
              <option value="software_updation">Software Updation</option>
            </select>
          </div>
          {this.state.repairPayment === "hardware" ? (
            <div className="gc_wrapper">
              <label>Repair Cost</label>
              <input type="text" onChange={this.handleRepairCostChange}></input>
            </div>
          ) : null}
          <button
            id="send_rModal"
            type="button"
            onClick={this.handleRepairPaymentSubmit}
          >
            Send
          </button>
        </ReactModal>
        <ReactModal
          isOpen={this.state.showQcPass}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          ariaHideApp={false}
          className="qc_pass"
        >
          <h2 id="rModal_head">QC Pass</h2>
          <button onClick={this.handleQcPassCloseModal} id="close_rModal">
            <i className="fas fa-times"></i>
          </button>
          <div>
            <label style={{ color: "#fff" }}>Issue Detective</label>
            <select
              onChange={this.handleIsIssueDetectedChange}
              id="select_rModal"
            >
              {/* <option value={"0"} defaultValue="selected">Select</option> */}
              <option value="issue_detected">Issue Detected</option>
              <option value="no_issue_detected">No Issue Detected</option>
            </select>
          </div>
          <div>
            {this.state.isIssueDetected === "issue_detected" ? (
              <div>
                <label style={{ color: "#fff" }}>Select Issue Detected</label>
                <select
                  onChange={this.handleIssueDetectedChange}
                  id="select_rModal"
                >
                  <option value="0" defaultValue="selected">
                    Select
                  </option>
                  <option value="1">Repair</option>
                  <option value="2">Replacement</option>
                  <option value="3">Upgrade Product</option>
                </select>
              </div>
            ) : (
              <div>
                <label style={{ color: "#fff" }}>
                  Select No Issue Detected
                </label>
                <select
                  onChange={this.handleIssueDetectedChange}
                  id="select_rModal"
                >
                  <option value="0" defaultValue="selected">
                    Select
                  </option>
                  <option value="4">Physical Damage Return</option>
                  <option value="5">Working Return</option>
                  <option value="6">Wrong Product Return</option>
                  <option value="7">Freshdesk Repaired</option>
                  <option value="8">Freshdesk Upgrade</option>
                </select>
              </div>
            )}
            {this.state.selectDetectedValue === "1" ? (
              <div>
                <div>
                  <label style={{ color: "#fff" }}>Repair Scenario</label>
                  <select
                    onChange={this.handleQcRepairChange}
                    id="select_rModal"
                  >
                    <option value={"0"} defaultValue="selected">
                      Select
                    </option>
                    <option value="hardware">Hardware</option>
                    <option value="software_updation">Software Updation</option>
                  </select>
                </div>
                {this.state.qcRepairValue === "hardware" ? (
                  <div id="qctext">
                    <label style={{ color: "#fff" }}>Repair Cost</label>
                    <input
                      type="text"
                      onChange={this.handleQcRepairCost}
                    ></input>
                  </div>
                ) : null}
              </div>
            ) : null}
            {this.state.selectDetectedValue === "2" ? (
              <div>
                {this.state.qcPassReplacementArray.map((so, index) => (
                  <div key={index} className="NewQC">
                    <input
                      type="checkbox"
                      name={so.name}
                      onChange={this.handleChangeQcPassChk}
                      checked={so.isChecked || false}
                    />
                    <label for="subscribeNews" style={{ color: "#fff" }}>
                      {so.name}
                    </label>
                  </div>
                ))}
              </div>
            ) : null}
            {this.state.selectDetectedValue === "3" ? (
              <div>
                <div>
                  <label style={{ color: "#fff" }}>Product</label>
                  <select onChange={this.handleProductList} id="select_rModal">
                    <option value={"0"} defaultValue="selected">
                      Search Product
                    </option>
                    {this.props.productData.data.products.map((p, index) => (
                      <option key={index} value={p.product_color_id}>
                        {p.product_name} ({p.size && ("Size " + p.size + " | ")} {p.color_name})
                      </option>
                    ))}
                  </select>
                </div>
                <div id="qctext">
                  <label style={{ color: "#fff" }}>Cost</label>
                  <input
                    type="text"
                    onChange={this.handleQcPassUpgradeCost}
                    placeholder="Enter Amount"
                  ></input>
                </div>
              </div>
            ) : null}
          </div>
          <button id="send_rModal" type="button" onClick={this.handleQcSubmit}>
            Send
          </button>
        </ReactModal>
        <ReactModal
          isOpen={this.state.isDispatchMOdal}
          contentLabel="Minimal Modal Example"
          style={customStyles}
        >
          <h2 id="rModal_head" style={{ "margin-bottom": "30px" }}>
            Dispatch Inward
          </h2>
          <button id="close_rModal" onClick={(() => this.setState({ isDispatchMOdal: false }))}>
            <i className="fas fa-times"></i>
          </button>
          <div className="gc_wrapper">
            <label>Status</label>
            <select onChange={this.handleChangeStatusDI} value={this.state.status} id="select_aaModal">
              <option value="">Select Status</option>
              <option value="replace">Replace</option>
              <option value="working">Working</option>
              <option value="repair">Repair</option>
              <option value="upgrade">Upgrade</option>
              <option value="wrong_product">Wrong Product</option>
              <option value="physical_damage">Physical Damage</option>
            </select>
            <div className="error_add">
              <span className="errorShow">
                {this.state.errors["status"]}
              </span>
            </div>
            {this.state.showProducts &&
              <div>
                <label>Select Product</label>
                <select onChange={this.handleChangeAccessory} value={this.state.acc_replacement} id="select_aaModal">
                  <option value="0">Select replacement</option>
                  <option value="Acc">Acc</option>
                  <option value="Charging case- B2B"> Charging case- B2B   </option>
                  <option value="Earbuds-B2B">Earbuds-B2B</option>
                  <option value="Charging case- New"> Charging case- New</option>
                  <option value="Earbuds-New">Earbuds-New</option>
                  <option value="Repair">Repair</option>
                  <option value="RWR">RWR</option>
                  <option value="Same Unit">Same Unit</option>
                  <option value="Unit B2B">Unit B2B</option>
                  <option value="Unit New">Unit New</option>
                  <option value="Dial-New">Dial-New</option>
                  <option value="Dial-B2B">Dial-B2B</option>
                  <option value="Unit Alternate Repack"> Unit Alternate Repack </option>
                  <option value="Unit Alternate New">  Unit Alternate New</option>
                  <option value="Unit Repack">Unit Repack</option>
                  <option value="only_cable">Same unit-only cable replaced</option>
                </select>
                <div className="error_add">
                  <span className="errorShow">
                    {this.state.errors["status"]}
                  </span>
                </div>
              </div>}
            {<div>
              {this.state.showUpgrade && (
                <div className="select-prod-comp">
                  <div className="dropdown-wrap-problem ">
                    <h6>Select Product</h6>
                    <Select
                      onChange={this.onProductChange}
                      options={optionBucketDrop}
                      isSearchable={true}
                      className="select-product-css selectProductDropdown"
                      //values={this.state.productId}
                      maxMenuHeight={200}
                      placeholder="Search Product.."
                      // closeMenuOnSelect={true}
                      styles={{
                        singleValue: provided => ({
                          ...provided,
                          color: "#fff !important"
                        }),
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          border: "1px solid #595959",
                          boxShadow: "0 !important",
                          "&:hover": {
                            border: "1px solid #595959 !important"
                          },
                          color: "#fff !important",
                          borderRadius: "20px !important",
                          backgroundColor: "#000 !important",
                          padding: "0 5px",
                          fontSize: "14px !important",
                          minHeight: "32px !important",
                          height: "32px !important"
                        }),
                        option: (baseStyles, state) => ({
                          ...baseStyles,
                          background: state.isFocused
                            ? "#402500d7 !important"
                            : "#000 !important",
                          color: state.isFocused
                            ? "#FA9F22 !important"
                            : "#fff !important",
                          width: "100%",
                          fontSize: "14px !important"
                        }),
                        Input: (baseStyles, state) => ({
                          ...baseStyles,
                          color: state.isFocused
                            ? "#fff !important"
                            : "#000"
                        }),
                        placeholder: baseStyles => ({
                          ...baseStyles,
                          color: "#fff !important"
                        }),
                        indicatorSeparator: () => null,
                        valueContainer: baseStyles => ({
                          ...baseStyles,
                          minHeight: "auto !important",
                          height: "auto !important",
                          color: "#fff !important",
                          marginTop: "-13px !important"
                        }),
                        DropdownIndicator: baseStyles => ({
                          ...baseStyles,
                          height: "25px !important",
                          marginBottom: "-13px !important"
                        }),
                        menuList: baseStyles => ({
                          ...baseStyles,
                          background: "#000 !important"
                        })
                      }}
                    />
                  </div>
                  <div className="error_add">
                    <span className="errorShow">
                      {this.state.errors["color"]}
                    </span>
                  </div>
                </div>
              )}
            </div>}

            {this.state.showProducts && (
              <div>
                {(this.state.acc_replacement === "Acc" ||
                  this.state.acc_replacement === "Repair" ||
                  this.state.acc_replacement ===
                  "Unit Alternate Repack" ||
                  this.state.acc_replacement ===
                  "Unit Alternate New") && (
                    <div>
                      <label>Note:</label>
                      <textarea
                        placeholder="Please add note"
                        className="text-area-input"
                        value={this.state.note}
                        onChange={this.handleNote}
                      />
                      <div className="error_add">
                        <span className="errorShow">
                          {this.state.errors["note"]}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            )}
            <div className="only_movement_wrapper">
              <span className="input-wrap-bulk-main only-movement">
                <input
                  type="checkbox"
                  id="select_all_movement"
                  name="onlyMove"
                  checked={this.state.onlyMovement}
                  onChange={this.handleChangeOnlyMovement}
                  className="input-wrap-bulk"
                />
                <label
                  className="select-all-label"
                  htmlFor="select_all_movement"
                >
                  Only movement
                </label>
              </span>
            </div>
            <div>
              <button style={{ marginTop: "10%" }}
                type="button"
                onClick={this.handleTransferDispatchInward}
                className="transferButton"
              >
                Submit
              </button>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  editData: state.complaints.editData,
  complaintDetail: state.complaints.complaintsDetail,
  statusId: state.complaints.statusId,
  courierData: state.complaints.courierData,
  callRes: state.complaints.callRes,
  screenRes: state.complaints.screenRes,
  printLabel: state.complaints.printLabel,
  productData: state.complaints.productData,
  trackDDStatus: state.complaints.trackDDStatus,
  resendMail: state.complaints.resendMail,
  adminUserlist: state.complaints.adminUserlist,
  cosmosRes: state.complaints.cosmosRes,

  bulkConstant: state.bulkmovement.bulkConstant,
});

export default connect(mapStateToProps, {
  editComplaintData,
  getRpuCourier,
  callCustomer,
  revokeTicket,
  callbackTagSubmit,
  moveToTestingInward,
  regeneratePaymentLink,
  submitScreenName,
  transferTodirectDispatch,
  transferTodirectDispatchFreshdesk,
  moveToTestingOutward,
  moveToDispatchInward,
  moveToRtoCase,
  submitRPU,
  forwardPickupCancel,
  returnPolicyAction,
  fetchPrintLabel,
  handleRequestPaymentSubmit,
  repairPaymentSubmit,
  qcSubmit,
  regenerateCoupons,
  submitFreshDeskClick,
  sendEmail,
  GCApproval,
  reversePickupCancel,
  sendCustomerInvoice,
  refundCaseApproval,
  adminList,
  gcCase,
  gcApprovalSubmit,
  refundApprovalSubmit,
  unmarkedTicketRefund,
  unmarkedTicketGC,
  resendReund,
  moveToReadyToDispatch,
  cosmosAction,
  flagAction,
  fetchAllBuckets,
  fetchTicketList,
  submitTransfer,
  fetchBucketStatus,
  submitWayBillNumbers,
  selectedSubmitTransfer,
  submitWayBillNumbersToWarehouse,
  submitDispatchInward,
  fetchBulkConstant,
  clearDIDataResults,
  clearBulkTransferDataResults,
  submitBulkRPU,
  clearBulkRpuDataResults,
  qcFailBuckets,
  qcPassBuckets,
  oosTestingAction,
})(TicketReceived);
