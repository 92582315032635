import React, { Component } from 'react';

export default class CutomerDetails extends Component {



  render() {
    let item = this.props.item;
    return (
      <div className="heads">
        <div className="first">
          <div className="firstbox">
            <span>Name</span>
            <p>{item.customer.name || 'N/A'}</p>
          </div>
          <div className="secondbox">
            <span>Address 1</span>
            <p>{item.customer.address || 'N/A'}</p>
          </div>
          <div className="thirdbox">
            <span>City</span>
            <p>{item.customer.city || 'N/A'}</p>
          </div>
        </div>

        <div className="second">
          <div className="firstbox">
            <span>Email</span>
            <p>{item.customer.email || 'N/A'}</p>
          </div>
          <div className="secondbox">
            <span>Address 2</span>
            <p>{item.customer.address_2 || 'N/A'}</p>
          </div>
          <div className="thirdbox">
            <span>Pincode</span>
            <p>{item.customer.pincode || 'N/A'}</p>
          </div>
        </div>

        <div className="third">
          <div className="firstbox">
            <span>Mobile Number</span>
            <p>{item.customer.mobile || 'N/A'}</p>
          </div>
          <div className="secondbox">
            <span>State</span>
            <p>{item.customer.state_name || 'N/A'}</p>
          </div>
          {/* <div className="thirdbox">
            <span>Sub Problem</span>
            <p>{item.product.sub_problem || 'N/A'}</p>
          </div> */}
        </div>
        <div className='problem_des'>
          {/* <span>{item.sub_product_problem_id == '0' ? 'Problem Description' : 'Sub problem issue'}</span>
          <p  data-bs-toggle="tooltip" data-bs-placement="bottom" className="tooltip-white text-start" title={item.sub_product_problem_id == '0' ? item.problem_description : item.product.sub_problem}>{item.sub_product_problem_id == '0' ? item.problem_description : item.product.sub_problem}</p> */}
          <span>{'Problem Description'}</span>
          <p data-bs-toggle="tooltip" data-bs-placement="bottom" className="tooltip-white text-start" title={item.problem_description}>{item.problem_description}</p>
        </div>
      </div>
    );
  }
}