import {
  FETCH_PRODUCT_DATA, FETCH_GROUP_DATA, SET_PRODUCT_DATA, EDIT_PRODUCT_DATA, GET_PRODUCT_DETAIL, FETCH_LEADERBOARD_ALL,
  FETCH_LEADERBOARD_ALL_CHANGE, FETCH_DAILY_REPORT_DATA, FETCH_REPORT_CONFIG, FETCH_ACTION_REPORT_CONFIG,
  FETCH_USER_ACTION_REPORT_DATA, FETCH_OPEN_CLOSE_REPORT_CONFIG, FETCH_OPEN_CLOSE_REPORT_DATA, DOWNLOAD_MANIFEST_REPORT,
  FETCH_ALL_PRODUCTS, FETCH_CHANNELS, FETCH_DATA_FIELDS, DOWNLOAD_CUSTOM_REPORT, FETCH_AUTHORIZATION,
  FETCH_INVOICE_REPORT_CONFIG, FETCH_MANIFEST_REPORT_CONFIG, FETCH_SERVICE_REPORT_CONFIG, FETCH_IVR_REPORT_CONFIG, FETCH_RTL_REPORT_CONFIG, FETCH_NOTIFY_REPORT_CONFIG, FETCH_SC_REPORT_CONFIG,
  FETCH_MOVE_REPORT_CONFIG, FETCH_REFUND_GC_CONFIG, FETCH_LUNA_NOTIFY_REPORT_CONFIG, FETCH_OOS_REPORT, FETCH_OOS_GIFT_REPORT, FETCH_B2B_REPORT_CONFIG,FETCH_REPLACEMENT_REPORT_CONFIG
} from '../actions/types';

const initialState = {
  productData: [],
  groupData: [],
  productResponse: [],
  editData: [],
  detailData: [],
  user: {},
  leaderboardData: {},
  leaderboardDataChange: {},
  dailyReportData: [],
  reportConfig: {},
  fetchActionReportConfig: {},
  fetchOpenCloseReportConfig: {},
  invoiceReportConfig: {},
  replacementOrderConfig:{},
  refundGCConfig: {},
  manifestReportConfig: {},
  serviceReportConfig: {},
  actionReportData: [],
  openCloseReportData: [],
  manifestReportData: [],
  ivrReportConfig: {},
  rtlReportConfig: {},
  scConfig: {},
  fetchAllProducts: [],
  fetchChannelList: [],
  fetchDataFieldsList: [],
  downloadCustomReport: [],
  bulkReportConfig: [],
  isAuthorized: '',
  notifyReportConfig: [],
  lunaReportConfig: [],
  oosReportConfig: [],
  oosGiftReportConfig: [],
  b2bReportConfig: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCT_DATA:
      return {
        ...state,
        productData: action.payload
      };
    case FETCH_GROUP_DATA:
      return {
        ...state,
        groupData: action.payload,
      };
    case SET_PRODUCT_DATA:
      return {
        ...state,
        productResponse: action.payload,
      };
    case EDIT_PRODUCT_DATA:
      return {
        ...state,
        editData: action.payload,
      };
    case GET_PRODUCT_DETAIL:
      return {
        ...state,
        detailData: action.payload,
      };
    case FETCH_LEADERBOARD_ALL:
      return {
        ...state,
        leaderboardData: action.payload,
      };
    case FETCH_LEADERBOARD_ALL_CHANGE:
      return {
        ...state,
        leaderboardDataChange: action.payload,
      };
    case FETCH_DAILY_REPORT_DATA:
      return {
        ...state,
        dailyReportData: action.payload,
      };
    case FETCH_USER_ACTION_REPORT_DATA:
      return {
        ...state,
        actionReportData: action.payload,
      };
    case FETCH_OPEN_CLOSE_REPORT_DATA:
      return {
        ...state,
        openCloseReportData: action.payload,
      };
    case FETCH_REPORT_CONFIG:
      return {
        ...state,
        reportConfig: action.payload,
      };
    case FETCH_INVOICE_REPORT_CONFIG:
      return {
        ...state,
        invoiceReportConfig: action.payload,
      }; 
      case FETCH_REPLACEMENT_REPORT_CONFIG:
      return {
        ...state,
        replacementOrderConfig: action.payload,
      };
    case FETCH_REFUND_GC_CONFIG:
      return {
        ...state,
        refundGCConfig: action.payload,
      };
    case FETCH_IVR_REPORT_CONFIG:
      return {
        ...state,
        ivrReportConfig: action.payload,
      };
    case FETCH_RTL_REPORT_CONFIG:
      return {
        ...state,
        rtlReportConfig: action.payload,
      };
    case FETCH_SC_REPORT_CONFIG:
      return {
        ...state,
        scConfig: action.payload,
      };
    case FETCH_MANIFEST_REPORT_CONFIG:
      return {
        ...state,
        manifestReportConfig: action.payload,
      };
    case FETCH_SERVICE_REPORT_CONFIG:
      return {
        ...state,
        serviceReportConfig: action.payload,
      };
    case FETCH_ACTION_REPORT_CONFIG:
      return {
        ...state,
        fetchActionReportConfig: action.payload,
      };
    case FETCH_OPEN_CLOSE_REPORT_CONFIG:
      return {
        ...state,
        fetchOpenCloseReportConfig: action.payload,
      };
    case DOWNLOAD_MANIFEST_REPORT:
      return {
        ...state,
        manifestReportData: action.payload,
      };
    case FETCH_MOVE_REPORT_CONFIG:
      return {
        ...state,
        bulkReportConfig: action.payload,
      };
    case FETCH_ALL_PRODUCTS:
      return {
        ...state,
        fetchAllProducts: action.payload,
      };
    case FETCH_CHANNELS:
      return {
        ...state,
        fetchChannelList: action.payload,
      };
    case FETCH_DATA_FIELDS:
      return {
        ...state,
        fetchDataFieldsList: action.payload,
      };
    case DOWNLOAD_CUSTOM_REPORT:
      return {
        ...state,
        downloadCustomReport: action.payload,
      };
    case FETCH_AUTHORIZATION:
      return {
        ...state,
        isAuthorized: action.payload,
      };
    case FETCH_NOTIFY_REPORT_CONFIG:
      return {
        ...state,
        notifyReportConfig: action.payload

      };
    case FETCH_LUNA_NOTIFY_REPORT_CONFIG:
      return {
        ...state,
        lunaReportConfig: action.payload

      };
    case FETCH_OOS_REPORT:
      return {
        ...state,
        oosReportConfig: action.payload

      };
    case FETCH_OOS_GIFT_REPORT:
      return {
        ...state,
        oosGiftReportConfig: action.payload

      };
    case FETCH_B2B_REPORT_CONFIG:
      return {
        ...state,
        b2bReportConfig: action.payload
      };
    default:
      return state;
  }
}