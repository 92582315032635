import React, { Component } from 'react'
import './ProductComponent.css';
import { connect } from "react-redux";
import history from '../../history';
// import moment from 'moment'
// import Loader from 'react-loader-spinner'
import { getGroupData, createProductGroup, removeGroupData } from '../../actions/productActions'
import ViewProfile from '../ViewProfile'
import ComplaintRegistration from '../ComplaintRegistration';
import AccessRestricted from '../AccessRestricted'
import Footer from'../FooterComponent/footer';
import SidebarComponent from '../SidebarComponent/SidebarComponent';

class GroupComponent extends Component {
  constructor() {
    super();

    this.state = {
      searchText: '',
      logout: false,
      pageLimit: 10,
      pageNo: 0,
      //searchDate: '',
      groupList: [],
      //prevPage: false,
      //nextPage: false,

      groupId: '',
      product_group_name: '',
      group_name: '',
      errors: '',
      limit: '',
      totalcount: '',
      prevPage: '',
      nextPage: '',
    }

    this.showMenu = this.showMenu.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this)
  }

  handleLogout() {
    localStorage.removeItem('token')
    document.querySelector(".modal-backdrop").style.display = "none";
    history.push('/')
  }

  handleIconDetails() {
    this.setState({
      logout: !this.state.logout
    })
  }

  onClearForm = (e) => {
    this.setState({
      firstName: '',
      lastName: '',
      mobile: '',
      email: '',
      is_reporter: '',
      reporting_to: '',
    })
  }

  componentDidMount() {
    this.getGroupList();
  }

  onPageLimitChange = (e) => {
    this.setState({ pageLimit: e.target.value }, () => {
      this.getGroupList();
    })
  }

  onPrevPress = () => {
    if (this.state.prevPage) {
      this.setState({ pageNo: (this.state.pageNo - 1) }, () => {
        this.getGroupList()
      })
    }
  }


  getGroupList = async () => {
    let data = {
      "limit": Number(this.state.pageLimit),
      "filterText": this.state.searchText,
      "offset": Number(this.state.pageNo)
    }
    this.setState({ isLoading: true, groupList: [] })
    //await this.props.getUserData(data);
    await this.props.getGroupData(data);
    let groupData = this.props.groupData;

    this.setState({
      //   isLoading: false,
      //   prevPage: usersData.data.prev_page,
      //   nextPage: usersData.data.next_page,
      groupList: groupData.data
    })
  }

  handleEdit = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }


  onEditClick = (e, item) => {
    this.setState({
      groupId: item.id,
      group_name: item.group_name
    })
  }

  showMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true });
  }

  handleDate = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.getGroupList();
    })
  }

  //this.setState({ [e.target.name]: e.target.value })

  handleChange = (e) => {
    if (e.target.name === 'searchByusername') {
      this.setState({ searchText: e.target.value }, () => {
        this.getGroupList();
      })
    } else {
      this.setState({ [e.target.name]: e.target.value }, () => {

      })
    }
  }



  handleSubmit(e) {
    e.preventDefault();
    let data = {
      'name': this.state.product_group_name
    }
    if (this.handleValidationadd() !== false) {
      this.props.createProductGroup(data);
      let groupResponce = this.props.groupResponse;
      this.getGroupList();
      if (groupResponce.success) {
        document.getElementById("add_form").reset();
        //var link = document.getElementById('edit_close1');


      }
    }
    this.getGroupList()

  }

  onUpdateClick = (e) => {
    e.preventDefault()
    let data = {
      "category_id": this.state.groupId,
      "name": this.state.group_name
    }
    if (this.handleValidation() !== false) {
      this.callEditApi(data)
    }

  }



  handleValidation() {
    let errors = {};
    let formIsValid = true;
    //Name

    if (!this.state.group_name) {
      formIsValid = false;
      errors["error_group_name"] = "Cannot be empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleValidationadd() {
    let errors = {};
    let formIsValid = true;
    //Name

    if (!this.state.product_group_name) {
      formIsValid = false;
      errors["error_product_group_name"] = "Cannot be empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }




  onDeleteClick = (e, item) => {
    e.preventDefault()
    let data = {
      "group_id": item.id,
    }

    this.deleteGroupApi(data)
  }





  deleteGroupApi = async (param) => {
    await this.props.removeGroupData(param)
    this.getGroupList()
  }


  callEditApi = async (param) => {
    await this.props.createProductGroup(param)
    // let editRes = this.props.editData
    var link = document.getElementById('edit_close1');
    //var link = document.getElementById('product_close_id');
    link.click();

    this.getGroupList()
  }
  render() {
    let userRoleId = localStorage.getItem('userRoleId');
    if(userRoleId === '4' || userRoleId === '8' || userRoleId === '9' || userRoleId === '10'){
      return( <AccessRestricted/>)
    }
    else if (userRoleId === '6'){
      return (<ComplaintRegistration />)
  }
    else{
    return (

      <div className="product-group group-component-main">

        <ViewProfile />
        { window.innerWidth > 600 && <SidebarComponent sideProp={'MP'} />}
          <div className="content">
            <div className='search_header_product'>
            <span>Product</span>
          <div className='search' style={{marginRight:'unset'}}>
              <i className="fa fa-search" ></i> 
              <input type="username"  style={{width:'230px'}} name="searchByusername" placeholder=" Search By Product Group" onChange={this.handleChange} />
              </div>
            </div>
         
          <ul id="menu">
            <li><a href="/manage-product" className="noactive">Product Info</a></li>
            <li className="actice2"><a href="/manage-group" >Product Group</a></li>
            <li><a href="/manage-message" className="noactive">Message</a></li>
            <li><a href="/channels" className="noactive">Channels</a></li>
          </ul>
          <div className="list">
              {/* <i className="fa fa-search"></i>
              <input type="username" name="searchByusername" placeholder=" Search By Product Group" onChange={this.handleChange} /> */}
            <button type="button" data-toggle="modal" data-target="#exampleModal" className="btn-add-grp">
              Add Group
            </button>

            <div className="modal fade" id="exampleModal">
              <div className="modal-dialog" role="document">
                <div className="modc">
                  <form action="" id="add_form" onSubmit={this.handleSubmit}>
                    <div className="modal-header">

                      <div className="list2">
                        <span>Add product</span>
                      </div>
                      <div data-dismiss="modal" aria-label="Close" id="product_close_id" className="product_close">
                        <span aria-hidden="true"><i className="fas fa-times" style={{ color: '#fff' }}></i></span>
                      </div></div>


                    <div className="form-group mg-left2">
                      <label>Product group name</label>
                      <input type="text" className="form-control inputclass" name="product_group_name" onChange={this.handleChange} placeholder="Product Group Name" required />
                      <div className="errorpd">
                        <span className="errorShow">{this.state.errors["error_product_group_name"]}</span>
                      </div>
                    </div>


                    <div className="addbtn">
                      <button onClick={this.handleSubmit}>Add Group</button></div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="viewModal" >
          <div className="modal-dialog" role="document">
            <div className="modc">
              <div className="modal-header">

                <div className="list2">
                  <span >Edit product group</span>
                </div>
                <div data-dismiss="modal" aria-label="Close" id="edit_close1" className="edit_close">
                  <span aria-hidden="true"><i className="fas fa-times"></i></span>
                </div>
              </div>

              <div className="form-group mg-left2">
                <label>Product group name</label>
                <input type="text" className="form-control inputclass" name="group_name" value={this.state.group_name} onChange={this.handleEdit} placeholder="Product Group Name" required />
                <div className="errorpd">
                  <span className="errorShow">{this.state.errors["error_group_name"]}</span>
                </div>
              </div>

              <div className="addbtn">
                <button onClick={(e) => this.onUpdateClick(e)}>Update Changes</button></div>

            </div>

          </div>
        </div>


        <div className="colrow grp">
          <div className="row customrow" style={{marginTop:"0px"}}>
            <div className="col-sm"  style={{color:"#eb951f"}}>Product Group</div>
          </div>

          {this.state.groupList.length > 0 ?
            this.state.groupList.map((item) => {
              return (
                <div key={item.id}className="row customrow">
                  <div className="col">
                    <p>{item.group_name ? item.group_name : 'NA'}</p>

                    <div className="dropdown">
                      {/* <button className="btn btn-link rightclass" type="button" id="dropdownMenuButton" data-toggle="dropdown" ria-expanded="false">
                        <i className="fas fa-ellipsis-h"></i>
                      </button> */}
                      {/* <div className="dropdown-menu grp_dropdown" aria-labelledby="dropdownMenuButton" onClick={(e) => this.onEditClick(e, item)}>
                        <a className="dropdown-item" href="#" data-toggle="modal" data-target="#viewModal"> <i className="fas fa-pencil-alt"></i>Edit</a>
                        <a className="dropdown-item" href="#" onClick={(e) => this.onDeleteClick(e, item)} ><i className="fas fa-exclamation-circle"></i>Remove</a>
                      </div> */}
                    </div>
                  </div>
                </div>
              )
            })
            :
            <div className="no-result-text">
              <p>No result found</p>
            </div>
          }
        </div>
        <Footer onPrevPress={this.onPrevPress} onNextPress={this.onNextPress} pageNo={this.state.pageNo} cc={"jj"} totalcount={this.state.totalcount}/>
        {/* <div className="footer">
          <div className="pages">
           
          </div>
          <div className="pages1">
            <p>{this.state.pageLimit * (this.state.pageNo) > 1 ? this.state.pageLimit * (this.state.pageNo) : '1'} - {this.state.pageLimit * (this.state.pageNo + 1)} of {this.state.totalcount} results
              <i className="fas fa-chevron-left" onClick={this.onPrevPress} />
              <span>{Number.parseInt(this.state.pageNo) + 1}</span>
              <i className="fa fa-chevron-right" onClick={this.onNextPress} />
            </p>
          </div></div> */}
      </div>

    )
              }
  }
}
const mapStateToProps = state => ({
  groupData: state.product.groupData,
  groupResponse: state.product.groupData
})

export default connect(mapStateToProps, { getGroupData, createProductGroup, removeGroupData })(GroupComponent);
