import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {useHistory} from 'react-router-dom'
import './ServiceCenterHeader.css'
import {
   getSearchResults
} from '../../actions/serviceCenterActions';
import moment from 'moment';

function ServiceCenterHeader({searchServiceCenterData, isSearch}) {
  let sc_details = JSON.parse(localStorage.getItem('scAddress'))
  let name = localStorage.getItem('userFirstName') + " " + localStorage.getItem('userLastName');

  const dispatch = useDispatch()
  const history = useHistory()

  const initialValues = {
    prevPage: false,
    nextPage: false,
    current_page: 1,
    search_sc: "",
  }

  const [values, setValues] = useState(initialValues);

  const handleChange = (e) => {
    const {name, value} = e.target;
    setValues({
      ...values,
      [name]: value
    })
  }

  //Clearing the search input field 
  if(values.search_sc === "") {
    isSearch(false)
  }

  //Search Results
  const response = useSelector((state) => state.serviceCenter.searchResults)

   const handleSearchButton = () => {
    let val = values.search_sc;
   
    if(val.length > 0) {
      isSearch(true)
        let data = {
          "limit": 10,
          "offset": 0,
          "tag": "panel",
          "search_by": values.search_sc,
          "page_no": values.current_page,
          "startDate": moment(values.startDate).format('YYYY-MM-DD'),
          "endDate": moment(values.endDate).format('YYYY-MM-DD'),
        }

        dispatch(getSearchResults(data));
        searchServiceCenterData(response);
        
        setValues({
          ...values,
          totalCount: response.total,
          totalPage: response.data ? response.data.no_page : 0,
          prevPage: response.data ? response.data.prev_page : 0,
          nextPage: response.data ? response.data.next_page : 1,
        })
    }
    else{
      isSearch(false)
    }
  }

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearchButton();
    }
  }  

  const handleLogoutDropdown = () => {
    let logoutDiv = document.getElementById('signout_dropdown');
    if(logoutDiv.style.display === "block") {
      logoutDiv.style.display = "none"
    }
    else
      logoutDiv.style.display = "block"
  }

  const handleLogout = () => {
    setTimeout(() => {
      localStorage.removeItem('token')
      history.push('/')
    }, 500);
  }

  return (
    <div className='serviceCenterHeader'>
        <div>
            <img src='/img/NoiseLogo.svg' alt='Noise Logo' />
            <span className='bold'>Noise Connect</span>
        </div>
        <div>
            <span><img src='/img/ServiceCenter.svg'  alt='service center logo'/></span>
            <span className='bold'>Service Center Name - </span>
            <span>{sc_details.name} {sc_details.pincode}</span>
        </div>
        <div className='columnThreeContainer'>
          <div className = "serviceSearch">
            <input id="searchInput" name="search_sc" type="text" value={values.search_sc || ""} className='searchInputService' placeholder="enter ticket id,name or phone" onChange={handleChange} onKeyDown={_handleKeyDown} />
            <button type="button" className="serviceSearchButton" onClick={handleSearchButton}><i className="fa fa-search user_search_icon"></i></button>
          </div>
          {/* <span className='columnThree'><img src='/img/NotificationIcon.svg' alt='notifications icon' /></span> */}
            <span className='columnThree' style={{cursor: 'pointer'}} onClick={handleLogoutDropdown}><i className='fas fa-user-circle fa-2x' aria-hidden="true"></i></span>
            <div id='signout_dropdown'>
              <span><i className="fa fa-user" aria-hidden="true" style={{marginRight: '5px'}}></i>{name}</span>
              <div className='horizontal'></div>
              <button id='logoutBtn' onClick={handleLogout}><i className="fa fa-power-off" aria-hidden="true" style={{marginRight: '5px'}}></i>Logout</button>
            </div>
        </div>
    </div>
  )
}

export default ServiceCenterHeader