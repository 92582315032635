import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getGroupData, getProductNameData } from '../../actions/productActions';
import { getProductProblemList, getSubProblemList, } from '../../actions/complaintsDataAction';
import { setProductDetails, setSubProblemID } from '../../actions/serviceCenterComplaintAction';
import moment from 'moment';

import Select from 'react-select';

function ProductDetails({ nextStep, prevStep }) {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGroupData());
  }, []);

  const initialValues = {
    categoryCode: '',
    categoryName: '',
    productCode: '',
    productName: '',
    complainCategoryCode: '',
    complainCategoryName: '',
    description: '',
    screeningStatusCode: '',
    screeningStatusDescription: [],
    purchaseDate: '',
    subProblemID: '',
    subProblemName: '',
    secondSubName: "",
  };

  const [values, setValues] = useState(initialValues);
  const [descriptionss, setDescription] = useState(false);
  const categories = useSelector((state) => state.product.groupData.data);
  const products = useSelector((state) => state.product.productNameList);
  const productProblems = useSelector((state) => state.complaints.problemData.problems);
  const subproblemData = useSelector((state) => state.complaints.subproblemData);
  const productDetails = useSelector((state) => state.serviceCenterComplaint.productDetails);
  const secondSubProbelmID = useSelector((state) => state.serviceCenterComplaint.secondSubProbelmID);

  useEffect(() => {
    if (productDetails !== undefined && productDetails !== null && productDetails != {})
      setValues(productDetails);
  }, [productDetails]);

  const [errors, setErrors] = useState({});

  const handleValidation = (productDetails) => {
    let errors = {};
    let validity = true;
    if (productDetails.categoryCode === undefined || productDetails.categoryCode === '') {
      errors['category'] = 'Category cannot be empty';
      validity = false;
    }

    if (productDetails.productCode === undefined || productDetails.productCode === '') {
      errors['product'] = 'Product cannot be empty';
      validity = false;
    }

    if (productDetails.complainCategoryCode === undefined || productDetails.complainCategoryCode === '' || productDetails.complainCategoryCode === '0') {
      errors['complain'] = 'Complain Category cannot be empty';
      validity = false;
    }

    if (!descriptionss && productDetails.complainCategoryCode != '0') {
      if (productDetails.sub_product_problem_id === undefined || productDetails.sub_product_problem_id === '0') {
        errors['subProblem'] = 'Sub problem  Category cannot be empty';
        validity = false;
      }
    }


    if (productDetails.description === undefined || productDetails.description === '') {
      errors['description'] = 'Description cannot be empty';
      validity = false;
    } else if (productDetails.description.trim().length === 0) {
      errors['description'] = 'Please provide a valid description';
      validity = false;
    }

    if (productDetails.screeningStatusCode === undefined || productDetails.screeningStatusCode === '') {
      errors['screening'] = 'Screening Status cannot be empty';
      validity = false;
    }

    if (productDetails.purchaseDate === undefined || productDetails.purchaseDate === '') {
      errors['purchaseDate'] = 'Date of purchase cannot be empty';
      validity = false;
    }
    else if (productDetails.purchaseDate > new Date().toISOString().split("T")[0]) {
      errors['purchaseDate'] = 'Date of purchase must be earlier than present date';
      validity = false;
    }
    else if (productDetails.purchaseDate < ("2000-01-01")) {
      errors['purchaseDate'] = 'Please provide a valid date of purchase';
      validity = false;
    }

    setErrors(errors);
    return validity;
  };

  const handleContinueBtn = () => {
    let productDetails = {
      categoryCode: values.categoryCode,
      categoryName: values.categoryName,
      productCode: values.productCode,
      productName: values.productName,
      complainCategoryCode: values.complainCategoryCode,
      complainCategoryName: values.complainCategoryName,
      description: values.description,
      screeningStatusCode: values.screeningStatusCode,
      screeningStatusDescription: [values.screeningStatusDescription],
      purchaseDate: values.purchaseDate,
      sub_product_problem_id: values.subProblemID || secondSubProbelmID,
      subProblemName: values.subProblemName
    };
    let validity = handleValidation(productDetails);
    if (validity === true) {
      dispatch(setProductDetails(productDetails));
      nextStep(2);
    }
  };

  const handleBackBtn = () => {
    prevStep(2);
  };

  const getProductsByCategory = (e) => {
    const select = e.target;
    const desc = select.selectedOptions[0].text;
    setValues({
      ...values,
      categoryCode: e.target.value,
      categoryName: desc,
      productCode: '',
      productName: '',
      complainCategoryCode: '',
      complainCategoryName: ''
    });
    let data = {
      'product_group_id': e.target.value
    };
    dispatch(getProductNameData(data));
    let categoryData = {
      "category_id": e.target.value
    };
    dispatch(getProductProblemList(categoryData));
  };

  const setSUbProblem = (e) => {
    const select = e.target;
    const desc = select.selectedOptions[0].text;
    dispatch(setSubProblemID(select.value));
    setValues({ ...values, subProblemID: select.value, subProblemName: desc, secondSubName: select.value });
  };

  const handleComplainCategory = async (e) => {
    const select = e.target;
    const desc = select.selectedOptions[0].text;
    if (select.value == "0") {
      setValues({ ...values, subProblemID: "0", subProblemName: "Sub Problem", secondSubName: "0" });
    }
    if (select.selectedOptions[0].text == "Issue not listed") {
      setDescription(true);
    } else {
      let data = {
        "problem_id": [e.target.value]
      };
      dispatch(getSubProblemList(data));
      setDescription(false);
    }
    setValues({
      ...values,
      complainCategoryCode: e.target.value,
      complainCategoryName: desc,
      subProblemID: "0",
      secondSubName: "0",
      subProblemName: "Sub Problem"
    });
  };



  let productOptions = [];

  products && products.data.products.map((product, index) => {
    productOptions.push({
      value: product.product_color_id,
      label: product.id == 239 ? product.product_name + "(" + "Size " + product.size + " | " + product.color_name + ")" : product.product_name + "(" + product.color_name + ")"
    });
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleProductChange = (e) => {
    const prodCode = e.value;
    const desc = e.label;
    setValues({
      ...values,
      productCode: prodCode,
      productName: desc
    });
  };



  const handleScreeningStatus = (e) => {
    const select = e.target;
    const desc = select.selectedOptions[0].text;
    setValues({
      ...values,
      screeningStatusCode: e.target.value,
      screeningStatusDescription: desc
    });
  };

  return (
    <div>
      <h1 className='form-heading'>Product details</h1>
      <div className='horizontal'></div>
      <form>
        <div className='row_4'>
          <div>
            <label htmlFor='category'>Category</label>
            <select value={values.categoryCode || ''} onChange={getProductsByCategory}>
              <option value=''>Select Category</option>
              {
                categories && categories.map((category, index) => {
                  return <option key={category.id} value={category.id}>{category.group_name}</option>;
                })
              }
            </select>
            <div className='error_add'>
              <span className='errorShow'>{errors.category}</span>
            </div>
          </div>
          <div>
            <label htmlFor='product name'>Product name</label>
            <Select placeholder='Search Products...' options={productOptions} isSearchable={true} onChange={handleProductChange}
              isDisabled={values.categoryCode === undefined || values.categoryCode === ''}
              value={values.productCode === undefined || values.productCode === '' ? { label: 'Search Products...', value: '' } : { label: values.productName, value: values.productName }}
              styles={{
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  color: values.categoryCode === undefined || values.categoryCode === '' ? 'rgb(235,235,228)' : '#000 !important'
                }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  border: state.isFocused ? '1px solid #307FE2' : 'border: 0.1px solid rgba(41, 41, 41, 0.352)',
                  backgroundColor: '#f7f7f7',
                }),
                indicatorSeparator: (styles) => ({
                  ...styles,
                  display: 'none'
                }),
                dropdownIndicator: (baseStyles) => ({
                  ...baseStyles,
                  color: '#26252C66'
                }),
                Input: (baseStyles) => ({
                  ...baseStyles,

                  color: '#fff !important'
                }),
              }}
            />
            <div className='error_add'>
              <span className='errorShow'>{errors.product}</span>
            </div>
          </div>

        </div>
        <div className='row_8' style={{ marginTop: "25px" }}>
          <div>
            <label htmlFor='problem category'>Problem</label>
            <select onChange={handleComplainCategory} disabled={values.categoryCode === undefined || values.categoryCode === ''}>
              <option value="0">{values.complainCategoryName || "Select Problem"}</option>
              {
                productProblems && productProblems.map((problem, index) => {
                  return <option key={problem.id} value={problem.id}>{problem.problem}</option>;
                })
              }
            </select>
            <div className='error_add'>
              <span className='errorShow'>{errors.complain}</span>
            </div>
          </div>

          < div >
            <label htmlFor='category'>Select Sub Problem</label>
            <select onChange={setSUbProblem} value={values.subProblemName || "Sub Problem"} placeholder="Sub Problem">
              <option value='0'>{values.subProblemName || "Sub Problem"}</option>
              {
                subproblemData && subproblemData.sub_problems && subproblemData.sub_problems.length > 0 ?
                  subproblemData.sub_problems.map((obj) => (<option value={obj.id}>{obj.sub_problem}</option>))
                  : null
              }
            </select>
            <div className='error_add'>
              <span className='errorShow'>{errors.subProblem}</span>
            </div>
          </div>

          <div>
            <label htmlFor='description'>Description</label>
            <input type='text' name='description' value={values.description || ''} onChange={handleInputChange} />
            <div className='error_add'>
              <span className='errorShow'>{errors.description}</span>
            </div>
          </div>

        </div>

        <div style={{ margin: "30px 0" }} className='row_4 flexRowNew'>
          <div>
            <label htmlFor='screening status'>Screening status</label>
            <select value={values.screeningStatusCode || ''} onChange={handleScreeningStatus}>
              <option value=''>Select Screening Status</option>
              <option value='1'>Complete Product Received with strap & charging cable</option>
              <option value='2'>Product Received with strap & without charging cable</option>
              <option value='3'>Product Received with charging cable & without strap</option>
              <option value='4'>Complete Product received Cable</option>
              <option value='5'>Product recieved without cable</option>
            </select>
            <div className='error_add'>
              <span className='errorShow'>{errors.screening}</span>
            </div>
          </div>
          <div className="date_wrapper">
            <label htmlFor='purchase date'>Purchase Date</label>
            <input style={{ padding: '5px' }} type='date' name='purchaseDate' min={'2000-01-01'} max={new Date().toISOString().split("T")[0]} value={values.purchaseDate || ''} onChange={handleInputChange} />
            <div className='error_add'>
              <span className='errorShow'>{errors.purchaseDate}</span>
            </div>
          </div>
        </div>
      </form >
      <div className='horizontal'></div>
      <div className='buttons'>
        <button className='navBtn' id='prevBtn' onClick={handleBackBtn}>Back</button>
        <button className='navBtn' onClick={handleContinueBtn}>Continue</button>
      </div>
    </div >
  );
}

export default ProductDetails;