import {
  FETCH_BUCKETS,
  FETCH_TICKET_LIST,
  FETCH_STATUS_LIST,
  SUCCESS_LIST,
  SUCCESS_LIST_DI,
  FAILURE_LIST,
  FAILURE_WAY_BILL_LIST,
  SUCCESS_WAY_BILL_LIST,
  NEXT_BUTTON_DISABLE,
  FETCH_TICKET_COUNT,
  FAILURE_LIST_DI,
  MAC_FAILURE_LIST,
  FETCH_BULK_CONSTANT,
  SUCCESS_LIST_BRPU,
  FAILURE_LIST_BRPU,
  CLEAR_SUCCESS_LIST_BRPU,
  CLEAR_FAILURE_LIST_BRPU,
  CLEAR_SUCCESS_LIST,
  CLEAR_SUCCESS_LIST_DI,
  CLEAR_FAILURE_LIST,
  CLEAR_FAILURE_LIST_DI,
  SUCCESS_TESTING_INWARD_LIST,
  FAILURE_TESTING_INWARD_LIST,
  FAILURE_MAC_FAILURE_LIST,
  SUCCESS_TESTING_INWARD_LIST_CLEAR,
  FAILURE_MAC_FAILURE_LIST_CLEAR,
  FAILURE_TESTING_INWARD_LIST_CLEAR

} from '../actions/types';

const initialState = {
  bucketData: [],
  ticketData: [],
  statusData: [],
  successList: [],
  failureList: [],
  macFailureList: [],
  successListDI: [],
  failureListDI: [],
  successWayBillList: [],
  failureWayBillList: [],
  nextButtonDisable: true,
  paginationCount: [],
  bulkConstant: [],
  FailureListBRPU: [],
  SucccessListBRPU: [],
  testingFailure: [],
  testingSuccess: [],
  testingMAcFilure: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_BUCKETS:
      return {
        ...state,
        bucketData: action.payload,
      };
    case FETCH_TICKET_LIST:
      return {
        ...state,
        ticketData: action.payload,
      };
    case FETCH_STATUS_LIST:
      return {
        ...state,
        statusData: action.payload,
      };
    case SUCCESS_LIST:
      // let successListResult = []  
      // if(action.payload.length > 0){
      //   successListResult = [...state.successList, ...action.payload]
      // }
      return {
        ...state,
        successList: [...state.successList, ...action.payload],
      };
    case CLEAR_SUCCESS_LIST:
      return {
        ...state,
        successList: []
      }
    case SUCCESS_LIST_DI:
      // let successResult = []  
      //   if(action.payload.length > 0){
      //     successResult = [...state.successListDI, ...action.payload]
      //   }
      return {
        ...state,
        successListDI: [...state.successListDI, ...action.payload]
      };
    case CLEAR_SUCCESS_LIST_DI:
      return {
        ...state,
        successListDI: []
      }
    case FAILURE_LIST:
      // let failureListResult = []  
      // if(action.payload.length > 0){
      //   failureListResult = [...state.failureList, ...action.payload]
      // }
      return {
        ...state,
        failureList: [...state.failureList, ...action.payload],
      };
    case CLEAR_FAILURE_LIST:
      return {
        ...state,
        failureList: []
      }
    case FAILURE_LIST_DI:
      // let failureResult = []  
      // if(action.payload.length > 0){
      //   failureResult = [...state.failureListDI, ...action.payload]
      // }
      return {
        ...state,
        failureListDI: [...state.failureListDI, ...action.payload],
      };
    case MAC_FAILURE_LIST:
      // let failureResult = []  
      // if(action.payload.length > 0){
      //   failureResult = [...state.failureListDI, ...action.payload]
      // }
      return {
        ...state,
        macFailureList: [...state.macFailureList, ...action.payload],
      };
    case CLEAR_FAILURE_LIST_DI:
      return {
        ...state,
        failureListDI: []
      }
    case FAILURE_WAY_BILL_LIST:
      return {
        ...state,
        failureWayBillList: action.payload,
      };
    case SUCCESS_WAY_BILL_LIST:
      return {
        ...state,
        successWayBillList: action.payload,
      };
    case NEXT_BUTTON_DISABLE:
      return {
        ...state,
        nextButtonDisable: action.payload,
      };
    case FETCH_TICKET_COUNT:
      return {
        ...state,
        paginationCount: action.payload,
      };
    case FETCH_BULK_CONSTANT:
      return {
        ...state,
        bulkConstant: action.payload,
      };
    case SUCCESS_LIST_BRPU:
      // let successResultBRPU = []  
      // if(action.payload.length > 0){
      //   successResultBRPU = [...state.SucccessListBRPU, ...action.payload]
      // }

      return {
        ...state,
        SucccessListBRPU: [...state.SucccessListBRPU, ...action.payload]
      };
    case FAILURE_LIST_BRPU:
      // let failureResultBRPU = []  
      // if(action.payload.length > 0){
      //   failureResultBRPU = [...state.FailureListBRPU, ...action.payload]
      // }
      return {
        ...state,
        FailureListBRPU: [...state.FailureListBRPU, ...action.payload]
      };
    case CLEAR_SUCCESS_LIST_BRPU:
      return {
        ...state,
        SucccessListBRPU: []
      }
    case CLEAR_FAILURE_LIST_BRPU:
      return {
        ...state,
        FailureListBRPU: []
      }
    case SUCCESS_TESTING_INWARD_LIST:
      return {
        ...state,
        testingSuccess: [...state.testingSuccess, ...action.payload]
      }
    case FAILURE_TESTING_INWARD_LIST:
      return {
        ...state,
        testingFailure: [...state.testingFailure, ...action.payload]
      }
    case FAILURE_MAC_FAILURE_LIST:
      return {
        ...state,
        testingMAcFilure: [...state.testingMAcFilure, ...action.payload]
      }
    case SUCCESS_TESTING_INWARD_LIST_CLEAR:
      return {
        ...state,
        testingSuccess: []
      }
    case FAILURE_TESTING_INWARD_LIST_CLEAR:
      return {
        ...state,
        testingFailure: []
      }
    case FAILURE_MAC_FAILURE_LIST_CLEAR:
      return {
        ...state,
        testingMAcFilure: []
      }
    default:
      return state;
  }
}
