import React, { Component } from "react";
// import HeaderComponent from '../HeaderComponent/HeaderComponent';
import { connect } from "react-redux";
import SidebarComponent from "../SidebarComponent/SidebarComponent";
import "./BulkmovementComponent.css";
import DateRangePicker from "rsuite/DateRangePicker";
import moment from "moment";
import {
  flagAction,
  getProductList,
  refreshComplaintList,
} from "../../actions/complaintsDataAction";
import {
  fetchAllBuckets,
  fetchTicketList,
  submitTransfer,
  fetchBucketStatus,
  submitWayBillNumbers,
  selectedSubmitTransfer,
  submitWayBillNumbersToWarehouse,
  submitDispatchInward,
  fetchBulkConstant,
  clearDIDataResults,
  clearBulkTransferDataResults,
  submitBulkRPU,
  clearBulkRpuDataResults,
  qcPassBuckets,
  qcFailBuckets,
  clearTEstingBulk,
  complaintResend,
  fetchoosIQC,
  iqxToTestingAction
} from "../../actions/bulkmovementAction";
import Select from "react-select";
import Loader from "react-loader-spinner";
import AccessRestricted from "../AccessRestricted";
import ComplaintRegistration from "../ComplaintRegistration";
import ViewProfile from "../ViewProfile";
import Footer from "../FooterComponent/footer";
// import {toast} from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// toast.configure()

const {
  // allowedMaxDays,
  // allowedDays,
  allowedRange,
  // beforeToday,
  // afterToday,
  // combine
} = DateRangePicker;

const hideBuckets = [5, 6, 17, 18, 45];

// const notify = ()=>{
//     toast('Hello Geeks 4',
//        {position: toast.POSITION.BOTTOM_LEFT})
//     toast('Hello Geeks 6',
//        {position: toast.POSITION.BOTTOM_RIGHT})
//     toast('Hello Geeks 5',
//        {position: toast.POSITION.BOTTOM_CENTER})
//     toast('Hello Geeks 1',
//        {position: toast.POSITION.TOP_LEFT})
//     toast('Hello Geeks 3',
//        {position: toast.POSITION.TOP_RIGHT})
//     toast('Hello Geeks 2',
//        {position: toast.POSITION.TOP_CENTER})
// }

// const {
//     // allowedMaxDays,
//     // allowedDays,
//     // allowedRange,
//     // beforeToday,
//     // afterToday,
//     // combine
//   } = DateRangePicker;

class BulkmovementComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      transferFrom: 1,
      transferTo: 1,
      statusId: 1,
      isEnterTicketType: true,
      ticketNumbers: "",
      ticketList: [],
      offset: 1,
      onlyMovement: false,
      transferValidateDisabled: true,
      isHandover: false,
      wayBillNumbers: "",
      pageLimit: 10,
      current_page: 1,
      startDate: new Date().setDate(new Date().getDate() - 60),
      endDate: new Date().setDate(new Date().getDate()),
      isHandoverToCourier: false,
      isWarehouse: false,
      isDispatchInward: false,
      transferList: true,
      status: "",
      errors: [],
      showProducts: false,
      acc_replacement: "0",
      note: "",
      productList: [],
      productColorId: "0",
      showSummary: false,
      isLoading: false,
      bulkRpu: false,
      pickupType: "reverse_pick_up",
      ticketNos: "",
      testInward: "",
      testInwardStatus: "",
      testingInwardSucFailList: [],
      testingMacFailure: [],
      testingFailureState: [],
      testingSuccessState: [],
      ticketNumbersRPU: "",
      complaintsLink: false,
      mobileNumber: "",
      mobileError: "",
      oosToIqc: false,
      oosIqcSuccess: [],
      oosIqcFailure: [],
      qcfailTesting:false,
      iqctestingSuccess: [],
      iqctestingfailure: [],
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeChooseType = this.handleChangeChooseType.bind(this);
    this.handleChangeChk = this.handleChangeChk.bind(this);
    this.onPrevPress = this.onPrevPress.bind(this);
    this.onNextPress = this.onNextPress.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleChangeStatusDI = this.handleChangeStatusDI.bind(this);
    this.handleChangeOnlyMovement = this.handleChangeOnlyMovement.bind(this);
    this.handleClickSubHeader = this.handleClickSubHeader.bind(this);
    this.handleWayBillSubmit = this.handleWayBillSubmit.bind(this);
    this.handleChangeWayInput = this.handleChangeWayInput.bind(this);
    this.handleChangeHandoverScanning =
      this.handleChangeHandoverScanning.bind(this);
    this.handleChangeWarehouseScanning =
      this.handleChangeWarehouseScanning.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleChangeAccessory = this.handleChangeAccessory.bind(this);
    this.onPickupChange = this.onPickupChange.bind(this);
    this.handleNote = this.handleNote.bind(this);
    this.handleTransferDispatchInward =
      this.handleTransferDispatchInward.bind(this);
    this.onProductChange = this.onProductChange.bind(this);
    this.handleBulkRPU = this.handleBulkRPU.bind(this);
    this.inputTicketNumbers = this.inputTicketNumbers.bind(this);
  }

  onProductChange(a) {
    this.setState({ productColorId: a.value, showSummary: false });
  }

  handleChangeWarehouseScanning(e) {
    this.setState(
      {
        isWarehouse: !this.state.isWarehouse,
      },
      () => {
        this.setState({
          isHandoverToCourier: false,
        });
      }
    );
  }

  handleChangeHandoverScanning(e) {
    this.setState(
      {
        isHandoverToCourier: !this.state.isHandoverToCourier,
      },
      () => {
        this.setState({
          isWarehouse: false,
        });
      }
    );
  }

  handleClickSubHeader(e) {
    if (e.currentTarget.value === 1) {
      this.setState({
        bulkRpu: false,
        isHandover: true,
        isDispatchInward: false,
        transferList: false,
        wayBillNumbers: "",
        showSummary: false,
        onlyMovement: false,
        bulkRpuShiprocket: false,
        testInward: false,
        complaintsLink: false,
        oosToIqc: false,
        qcfailTesting:false,
      });
    } else if (e.currentTarget.value === 2) {
      this.setState({
        bulkRpu: false,
        isHandover: false,
        isDispatchInward: true,
        transferList: false,
        wayBillNumbers: "",
        showSummary: false,
        showTicketSummary: false,
        onlyMovement: false,
        bulkRpuShiprocket: false,
        testInward: false,
        complaintsLink: false,
        oosToIqc: false,
        qcfailTesting:false,
      });
    } else if (e.currentTarget.value === 3) {
      this.setState({
        bulkRpu: true,
        isHandover: false,
        isDispatchInward: false,
        transferList: false,
        wayBillNumbers: "",
        showSummary: false,
        showTicketSummary: false,
        onlyMovement: false,
        bulkRpuShiprocket: false,
        testInward: false,
        complaintsLink: false,
        oosToIqc: false,
        qcfailTesting:false,
      });
    } else if (e.currentTarget.value === 4) {
      this.setState({
        bulkRpu: false,
        isHandover: false,
        isDispatchInward: false,
        transferList: false,
        wayBillNumbers: "",
        showSummary: false,
        showTicketSummary: false,
        onlyMovement: false,
        testInward: true,
        complaintsLink: false,
        oosToIqc: false,
        qcfailTesting:false,
      });
    } else if (e.currentTarget.value === 5) {
      this.setState({
        bulkRpu: false,
        isHandover: false,
        isDispatchInward: false,
        transferList: false,
        wayBillNumbers: "",
        showSummary: false,
        showTicketSummary: false,
        onlyMovement: false,
        complaintsLink: true,
        testInward: false,
        oosToIqc: false,
        qcfailTesting:false,
      });
    } else if (e.currentTarget.value === 6) {
      this.setState({
        bulkRpu: false,
        isHandover: false,
        isDispatchInward: false,
        transferList: false,
        wayBillNumbers: "",
        showSummary: false,
        showTicketSummary: false,
        onlyMovement: false,
        complaintsLink: false,
        testInward: false,
        oosToIqc: true,
         qcfailTesting:false,
      });
    } else if (e.currentTarget.value === 7) {
      this.setState({
        bulkRpu: false,
        isHandover: false,
        isDispatchInward: false,
        transferList: false,
        wayBillNumbers: "",
        showSummary: false,
        showTicketSummary: false,
        onlyMovement: false,
        complaintsLink: false,
        testInward: false,
        oosToIqc: false,
         qcfailTesting:true,
      });
    }else {
      this.setState({
        bulkRpu: false,
        isHandover: false,
        isDispatchInward: false,
        transferList: true,
        wayBillNumbers: "",
        showSummary: false,
        showTicketSummary: false,
        onlyMovement: false,
        bulkRpuShiprocket: false,
        testInward: false,
        oosToIqc: false,
        qcfailTesting:false,
      });
    }
    this.setState({
      transferFrom: 1,
      transferTo: 1,
      statusId: 1,
      isEnterTicketType: true,
      ticketNumbers: "",
      ticketList: [],
      offset: 1,
      transferValidateDisabled: true,
      pageLimit: 10,
      current_page: 1,
      startDate: new Date().setDate(new Date().getDate() - 60),
      endDate: new Date().setDate(new Date().getDate()),
      isHandoverToCourier: false,
      isWarehouse: false,
      status: "",
      errors: [],
      showProducts: false,
      acc_replacement: "0",
      note: "",
      productList: [],
      productColorId: "0",
      isLoading: false,
      pickupType: "reverse_pick_up",
      ticketNos: "",
      testInwardStatus: "",
      testingInwardSucFailList: [],
      mobileNumber: "",
      mobileError: "",
    });
  }

  handleChangeOnlyMovement(e) {
    const { checked } = e.target;
    this.setState({
      onlyMovement: checked,
    });
  }

  handleChangeAccessory(e) {
    this.setState({
      acc_replacement: e.target.value,
      showSummary: false,
    });
  }

  onPickupChange(e) {
    this.setState({
      pickupType: e.target.value,
    });
  }

  handleNote(e) {
    this.setState({
      note: e.target.value,
    });
  }

  handleChangeStatus(e) {
    let id = e.target.value;
    this.setState({
      statusId: id,
    });
  }

  async handleChangeStatusDI(e) {
    let id = e.target.value;
    if (e.target.value === "replace") {
      this.setState({ showProducts: true, showUpgrade: false });
    } else if (e.target.value === "upgrade") {
      this.setState({ showUpgrade: true, showProducts: false });
      this.setState({ isLoading: true });
      await this.props.getProductList({});
      this.setState({ isLoading: false });
      this.setState({
        productList: this.props.productData.data.products,
      });
    } else {
      this.setState({ showProducts: false, showUpgrade: false });
    }
    this.setState({
      status: id,
      showSummary: false,
    });
  }

  handleChangeTransferTo(e) {
    let id = e.target.value;
    this.setState(
      {
        transferTo: id,
      },
      async () => {
        this.setState({ isLoading: true });
        await this.props.fetchBucketStatus(this.state.transferTo);
        this.setState({
          statusId: this.props.statusData[0] ? this.props.statusData[0].id : 1,
          showTicketSummary: false,
          isLoading: false,
        });
      }
    );
  }

  onPrevPress = async () => {
    if (this.props.paginationCount.prev_page) {
      // let new_page = (this.state.new_page1 - 1);
      // this.setState({new_page1: new_page});
      this.setState({ current_page: this.state.current_page - 1 }, async () => {
        let transferBody = {
          page_no: this.state.current_page,
          bucket_id: this.state.transferFrom,
          startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
          endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
        };
        this.setState({ isLoading: true });
        await this.props.fetchTicketList(transferBody);
        this.setState({ isLoading: false });
        this.setState({
          ticketList: this.props.ticketData,
        });
      });
    }
  };

  onNextPress = async () => {
    if (this.props.paginationCount.next_page) {
      this.setState({ current_page: this.state.current_page + 1 }, async () => {
        let transferBody = {
          page_no: this.state.current_page,
          bucket_id: this.state.transferFrom,
          startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
          endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
        };
        this.setState({ isLoading: true });
        await this.props.fetchTicketList(transferBody);
        this.setState({ isLoading: false });
        this.setState({
          ticketList: this.props.ticketData,
        });
      });
    }
  };

  async handleSubmit() {
    let body = {};
    if (this.state.isEnterTicketType) {
      if (this.state.ticketNumbers.length === 0) {
        alert("Please enter ticket Numbers");
      } else {
        let tNums = this.state.ticketNumbers.split("\n");
        tNums = tNums.filter((t) => t !== "");
        this.setState({ isLoading: true });
        // Batch wise processing
        let size = this.props.bulkConstant[0]
          ? Number(this.props.bulkConstant[0].value)
          : 15;
        // let batchProcessing =  false;

        await this.props.clearBulkTransferDataResults();
        let arrays = [];
        while (tNums.length > 0) {
          arrays.push(tNums.splice(0, size));
        }
        for (let z = 0; z < arrays.length; z++) {
          body["entryTicketFlag"] = true;
          body["ticketNumbers"] = arrays[z];
          body["to_bucket_id"] = this.state.transferTo;
          body["only_movement"] = this.state.onlyMovement;
          await this.props.submitTransfer(body);
        }
        this.setState({
          ticketNumbers: "",
          showTicketSummary: true,
          isLoading: false,
        });
      }
    } else {
      if (this.state.transferTo === this.state.transferFrom) {
        alert("ERROR - Same Buckets");
      } else {
        body["status_id"] = this.state.statusId;
        body["to_bucket_id"] = this.state.transferTo;
        body["from_bucket_id"] = this.state.transferFrom;
        body["only_movement"] = this.state.onlyMovement;
        this.setState({ isLoading: true });
        let compIds = [];
        for (let i = 0; i < this.state.ticketList.length; i++) {
          if (this.state.ticketList[i].isChecked) {
            compIds.push(this.state.ticketList[i].id);
          }
        }
        if (compIds.length === 0) {
          alert("Please select ticket List");
        } else {
          await this.props.clearBulkTransferDataResults();
          let size = this.props.bulkConstant[0]
            ? Number(this.props.bulkConstant[0].value)
            : 15;
          let arrays = [];
          while (compIds.length > 0) {
            arrays.push(compIds.splice(0, size));
          }
          for (let z = 0; z < arrays.length; z++) {
            body["complaint_ids"] = arrays[z];
            await this.props.selectedSubmitTransfer(body);
          }
          let transferBody = {
            page_no: this.state.current_page,
            bucket_id: this.state.transferFrom,
          };
          await this.props.fetchTicketList(transferBody);
          this.setState({
            ticketList: this.props.ticketData,
            isLoading: false,
            // transferTo: generateRandom(0, this.props.bucketData.length -1,this.state.transferFrom ),
          });
        }
      }
    }
  }

  async handleWayBillSubmit() {
    let body = {};
    if (
      this.state.wayBillNumbers.length === 0 ||
      (!this.state.isWarehouse && !this.state.isHandoverToCourier)
    ) {
      alert("Empty waybill/Empty bucket selection");
      return;
    }
    this.setState({ isLoading: true });
    let tNums = this.state.wayBillNumbers.split("\n");
    body["waybill_no"] = tNums;
    if (this.state.isHandoverToCourier) {
      await this.props.submitWayBillNumbers(body);
      this.setState({
        wayBillNumbers: "",
        isHandoverToCourier: false,
        isLoading: false,
      });
    } else {
      await this.props.submitWayBillNumbersToWarehouse(body);
      this.setState({
        wayBillNumbers: "",
        isWarehouse: false,
        isLoading: false,
      });
    }
  }

  handleValidation() {
    let errors = {};
    let validity = true;
    if (this.state.status === "") {
      errors["status"] = "Please select appropriate status";
      validity = false;
    }
    if (this.state.wayBillNumbers === "") {
      errors["wayBillNumbers"] = "Please enter waybill number(s)";
      validity = false;
    }
    if (this.state.showProducts) {
      if (this.state.acc_replacement === "0") {
        errors["accessory"] = "Please select replacement accessory";
        validity = false;
      }
      if (
        this.state.acc_replacement === "1" ||
        this.state.acc_replacement === "6" ||
        this.state.acc_replacement === "13" ||
        this.state.acc_replacement === "14"
      ) {
        if (this.state.note === "") {
          errors["note"] = "Please enter note";
          validity = false;
        }
      }
    }
    if (this.state.showUpgrade) {
      if (this.state.productColorId === "0") {
        errors["color"] = "Please select product";
        validity = false;
      }
    }
    this.setState({ errors: errors });
    return validity;
  }

  async handleBulkRPU() {
    let errors = {};
    if (
      this.state.ticketNumbersRPU === "" ||
      this.state.ticketNumbersRPU === null ||
      this.state.ticketNumbersRPU === undefined
    ) {
      errors["ticketNos"] = "Please enter ticket number(s)";
    } else {
      this.setState({ isLoading: true });
      errors["ticketNos"] = "";
      let waybillnos = this.state.ticketNumbersRPU.split("\n");
      waybillnos = waybillnos.filter((val, index, self) => {
        return val !== "" && self.indexOf(val) === index;
      });
      let data;
      data = {
        only_movement: this.state.onlyMovement,
        type: this.state.pickupType,
      };
      //create new function clearBulkRpuDataResults()
      await this.props.clearBulkRpuDataResults();
      // Batch wise processing
      // let size = this.props.bulkConstant[0] ? Number(this.props.bulkConstant[0].value) : 15;
      let size = 10;
      // let batchProcessing =  false;
      let arrays = [];
      while (waybillnos.length > 0) {
        arrays.push(waybillnos.splice(0, size));
      }
      for (let z = 0; z < arrays.length; z++) {
        data.ticketNumbers = arrays[z];
        await this.props.submitBulkRPU(data);
      }
      this.setState({
        ticketNumbersRPU: "",
        showSummary: true,
        isLoading: false,
        only_movement: "",
      });
    }
    this.setState({ errors: errors });
  }

  async handleTransferDispatchInward() {
    let waybillnos = this.state.wayBillNumbers.split("\n");
    waybillnos = waybillnos.filter((wb) => wb !== "");
    let data;
    if (this.handleValidation() !== false) {
      this.setState({ isLoading: true });
      if (this.state.status === "replace") {
        if (this.state.acc_replacement === "Acc") {
          let notes = this.state.note;
          data = {
            only_movement: this.state.onlyMovement,
            qc_status: this.state.status,
            replace_accessory: "Acc " + notes,
            // ticketNumbers: waybillnos
          };
        } else if (this.state.acc_replacement === "Repair") {
          let notes = this.state.note;
          data = {
            only_movement: this.state.onlyMovement,
            qc_status: this.state.status,
            replace_accessory: "Repair " + notes,
            // ticketNumbers: waybillnos
          };
        } else if (this.state.acc_replacement === "Unit Alternate Repack") {
          let notes = this.state.note;
          data = {
            only_movement: this.state.onlyMovement,
            qc_status: this.state.status,
            replace_accessory: "Unit Alternate Repack " + notes,
            // ticketNumbers: waybillnos
          };
        } else if (this.state.acc_replacement === "Unit Alternate New") {
          let notes = this.state.note;
          data = {
            only_movement: this.state.onlyMovement,
            qc_status: this.state.status,
            replace_accessory: "Unit Alternate New " + notes,
            // ticketNumbers: waybillnos
          };
        } else {
          // let notes = this.state.note;
          data = {
            only_movement: this.state.onlyMovement,
            qc_status: this.state.status,
            replace_accessory: this.state.acc_replacement,
            // ticketNumbers: waybillnos
          };
        }
      } else if (this.state.status === "upgrade") {
        data = {
          only_movement: this.state.onlyMovement,
          qc_status: this.state.status,
          upgrade_product_id: this.state.productColorId,
          // ticketNumbers: waybillnos
        };
      } else {
        data = {
          only_movement: this.state.onlyMovement,
          qc_status: this.state.status,
          // ticketNumbers: waybillnos
        };
      }
      // Clear previous cache
      await this.props.clearDIDataResults();
      // Batch wise processing
      let size = this.props.bulkConstant[0]
        ? Number(this.props.bulkConstant[0].value)
        : 15;
      // let batchProcessing =  false;
      let arrays = [];
      while (waybillnos.length > 0) {
        arrays.push(waybillnos.splice(0, size));
      }
      for (let z = 0; z < arrays.length; z++) {
        data.ticketNumbers = arrays[z];
        await this.props.submitDispatchInward(data);
      }
      this.setState({
        wayBillNumbers: "",
        showSummary: true,
        isLoading: false,
        isDispatchInward: true,
        transferList: false,
        bulkRpu: false,
        isHandover: false,
        bulkRpuShiprocket: false,
      });
    }
  }

  handleChangeTransferFrom(e) {
    this.setState(
      {
        transferFrom: e.target.value,
        offset: 0,
        ticketList: [],
      },
      async () => {
        let transferBody = {
          page_no: this.state.current_page,
          bucket_id: this.state.transferFrom,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        };
        this.setState({ isLoading: true });
        await this.props.fetchTicketList(transferBody);
        this.setState({
          ticketList: this.props.ticketData,
          showTicketSummary: false,
          isLoading: false,
        });
      }
    );
  }

  handleChangeInput(e) {
    this.setState({
      ticketNumbers: e.target.value,
    });
  }

  inputTicketNumbers(e) {
    this.setState({
      ticketNumbersRPU: e.target.value,
    });
  }

  handleChangeWayInput(e) {
    this.setState({
      wayBillNumbers: e.target.value,
    });
  }

  handleChangeChooseType(e) {
    if (e.target.value === "entryType") {
      this.setState({
        isEnterTicketType: true,
        transferValidateDisabled: true,
        onlyMovement: false,
      });
    } else {
      if (this.props.bucketData && this.props.bucketData.length > 0) {
        this.setState(
          {
            transferFrom:
              this.props.bucketData[0].id || this.state.transferFrom,
            transferTo: this.state.transferTo,
            isEnterTicketType: false,
            transferValidateDisabled: true,
            onlyMovement: false,
          },
          async () => {
            let transferBody = {
              page_no: this.state.current_page,
              bucket_id: this.state.transferFrom,
              startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
              endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
            };
            this.setState({ isLoading: true });
            await this.props.fetchTicketList(transferBody);
            this.setState({
              ticketList: this.props.ticketData,
              // transferTo: generateRandom(0, this.props.bucketData.length -1,this.state.transferFrom ),
            });
            await this.props.fetchBucketStatus(this.state.transferTo);
            this.setState({
              statusId: this.props.statusData[0]
                ? this.props.statusData[0].id
                : 1,
              showTicketSummary: false,
              isLoading: false,
            });
          }
        );
      }
      // this.setState({
      //     isEnterTicketType: false,
      //     transferValidateDisabled : true
      // }, async () => {
      //     let transferBody = {
      //         page_no: this.state.offset,
      //         bucket_id: this.state.transferFrom
      //     }
      //     await this.props.fetchTicketList(transferBody)
      // })
    }
  }

  handleChangeChk(e) {
    const { name, checked } = e.target;

    if (name === "allSelect") {
      let tempTickets = this.state.ticketList.map((td) => {
        return { ...td, isChecked: checked };
      });
      this.setState({
        ticketList: tempTickets,
        transferValidateDisabled: false,
      });
    } else {
      let tempTickets = this.state.ticketList.map((td) =>
        td.id === name ? { ...td, isChecked: checked } : td
      );
      this.setState({
        ticketList: tempTickets,
        transferValidateDisabled: false,
      });
    }
    // disable transfer buton
  }

  async componentDidMount() {
    await this.props.fetchAllBuckets();
    await this.props.fetchBulkConstant();
    // if (this.props.bucketData && this.props.bucketData.length > 0) {
    //     this.setState({
    //         transferFrom: this.props.bucketData[0].id  || this.state.transferFrom,
    //         transferTo: this.props.bucketData[1].id || this.state.transferTo,
    //     }, async () => {
    //         let transferBody = {
    //             page_no: this.state.offset,
    //             bucket_id: this.state.transferFrom
    //         }
    //         await this.props.fetchTicketList(transferBody)
    //         this.setState({
    //             ticketList: this.props.ticketData
    //         })
    //         await this.props.fetchBucketStatus(this.state.transferTo)
    //     })
    // }
  }

  dateSelect = async (value) => {
    this.setState(
      {
        startDate: moment(new Date(value[0])).format("YYYY-MM-DD"),
        endDate: moment(new Date(value[1])).format("YYYY-MM-DD"),
      },
      async () => {
        let transferBody = {
          page_no: this.state.current_page,
          bucket_id: this.state.transferFrom,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        };
        await this.props.fetchTicketList(transferBody);
        this.setState({
          ticketList: this.props.ticketData,
        });
      }
    );
  };

  handleBulkTestingInward = async () => {
    let errors = {};
    let validity = true;
    if (this.state.wayBillNumbers === "") {
      errors["test_waybil"] = "Please enter waybill number(s)";
      validity = false;
    } else if (this.state.testInwardStatus === "") {
      errors["test_status"] = "Please select appropriate status";
      validity = false;
    } else {
      this.props.clearTEstingBulk();
      let waybillnos = this.state.wayBillNumbers.split("\n");
      waybillnos = waybillnos.filter((wb) => wb !== "");
      let arrays = [];
      let size = 5;
      let data = {
        qcStatus: this.state.testInwardStatus == 1 ? "qc_pass" : "qc_fail",
      };
      this.setState({ isLoading: true });
      while (waybillnos.length > 0) {
        arrays.push(waybillnos.splice(0, size));
      }
      for (let z = 0; z < arrays.length; z++) {
        data.ticketNumbers = arrays[z];
        let response =
          this.state.testInwardStatus == 1
            ? await this.props.qcPassBuckets(data)
            : await this.props.qcFailBuckets(data);
        this.setState({ testingInwardSucFailList: response.data });
      }
      this.setState({ isLoading: false });
    }
    this.setState({ errors: errors });
  };

  handleOosToIqc = async () => {
    let waybillnos = this.state.wayBillNumbers.split("\n");
    waybillnos = waybillnos.filter((wb) => wb !== "");
    let arrays = [];
    let size = 10;
    this.setState({ isLoading: true });
    while (waybillnos.length > 0) {
      arrays.push(waybillnos.splice(0, size));
    }
    this.setState({ oosIqcFailure: [], oosIqcSuccess: [] });
    let response = [];
    for (let z = 0; z < arrays.length; z++) {
      let data = {
        ticketNumbers: arrays[z],
      };
      let response = await this.props.fetchoosIQC(data);
      this.setState({
        oosIqcSuccess: [...this.state.oosIqcSuccess, ...response.data.success],
        oosIqcFailure: [...this.state.oosIqcFailure, ...response.data.failure],
      });
    }

    this.setState({ isLoading: false });
  };

  handleiqcTOTesting = async () => {
    let waybillnos = this.state.wayBillNumbers.split("\n");
    waybillnos = waybillnos.filter((wb) => wb !== "");
    let arrays = [];
    let size = 10;
    this.setState({ isLoading: true });
    while (waybillnos.length > 0) {
      arrays.push(waybillnos.splice(0, size));
    }
    this.setState({ iqctestingfailure: [], iqctestingSuccess: [] });
    let response = [];
    for (let z = 0; z < arrays.length; z++) {
      let data = {
        ticketNumbers: arrays[z],
      };
      let response = await this.props.iqxToTestingAction(data);
      this.setState({
        iqctestingSuccess: [...this.state.iqctestingSuccess, ...response.data.success],
        iqctestingfailure: [...this.state.iqctestingfailure, ...response.data.failure],
      });
    }

    this.setState({ isLoading: false });
  };

  hanldeTestingInwardStatus = (e) => {
    this.setState({ testInwardStatus: e.target.value });
    this.props.clearTEstingBulk();
  };

  sendComplaintLink = async () => {
    const regex = /^[6-9]\d{9}$/;
    let valid = regex.test(this.state.mobileNumber);
    let flag = true;
    let message = "";
    if (
      this.state.mobileNumber == "" ||
      this.state.mobileNumber == null ||
      this.state.mobileNumber == "null" ||
      this.state.mobileNumber == undefined
    ) {
      message = "Please enter Mobile number";
      flag = false;
    }
    if (!valid) {
      message = "Please enter valid Mobile number";
      flag = false;
    }
    this.setState({ mobileError: message });
    if (flag) {
      let data = {
        customer_mobile: this.state.mobileNumber,
      };
      let response = await this.props.complaintResend(data);
      alert(response?.message);
      this.setState({
        mobileNumber: "",
        mobileError: "",
      });
    }
  };

  render() {
    console.log(this.state.iqctestingSuccess,'.......')
    let optionBucketDrop = [
      {
        value: 0,
        label: "Select Product",
      },
    ];
    {
      this.state.productList.map((obj) =>
        optionBucketDrop.push({
          // label: obj.name,
          value: obj.product_color_id,
          label:
            obj.id == 239
              ? obj.product_name + "(" + obj.size + "|" + obj.color_name + ")"
              : obj.product_name + "(" + obj.color_name + ")",
          // id: obj.id
        })
      );
    }
    let paginationCount = this.props.paginationCount;
    let new_page =
      paginationCount.no_page -
      (paginationCount.no_page - this.state.current_page) -
      1;
    let userRoleId = localStorage.getItem("userRoleId");
    if (
      userRoleId === "4" ||
      userRoleId === "8" ||
      userRoleId === "9" ||
      userRoleId === "10"
    ) {
      return <AccessRestricted />;
    } else if (userRoleId === "6") {
      return <ComplaintRegistration />;
    } else {
      return (
        <div className="container-new">
        { window.innerWidth > 600 && <SidebarComponent sideProp={"BM"} />}
          {/* <HeaderComponent /> */}
          <div className="nav dashboard">
            <span>
              <i
                className="fas fa-user-circle"
                data-toggle="modal"
                data-target="#exampleModals"></i>{" "}
            </span>
          </div>
          <ViewProfile />
          {this.state.isLoading ? (
            <div className="dispatch-loader">
              <Loader type="Oval" color="Grey" height={200} width={200} />
            </div>
          ) : (
            <div className="main-content">
              <div className="heading-bulk">Bulk Movement</div>
              <div className="sub-header-bulk">
                <li
                  onClick={this.handleClickSubHeader}
                  id="ticket-tab"
                  value="0"
                  className={this.state.transferList ? "activebm" : ""}>
                  Transfer List
                </li>
                <li
                  onClick={this.handleClickSubHeader}
                  id="handover-tab"
                  value="1"
                  className={this.state.isHandover ? "activebm" : ""}>
                  Handover Scanning
                </li>
                <li
                  onClick={this.handleClickSubHeader}
                  id="dispatch-inward"
                  value="2"
                  className={this.state.isDispatchInward ? "activebm" : ""}>
                  Dispatch Inward
                </li>
                <li
                  onClick={this.handleClickSubHeader}
                  id="bulk-rpu"
                  value="3"
                  className={this.state.bulkRpu ? "activebm" : ""}>
                  Bulk RPU
                </li>
                <li
                  onClick={this.handleClickSubHeader}
                  id="test-inward"
                  value="4"
                  className={this.state.testInward ? "activebm" : ""}>
                  Testing Inward
                </li>
                <li
                  onClick={this.handleClickSubHeader}
                  id="complaint-link"
                  value="5"
                  className={this.state.complaintsLink ? "activebm" : ""}>
                  Complaint Link
                </li>
                {(userRoleId == 1 || userRoleId == 2) && (
                  <li
                    onClick={this.handleClickSubHeader}
                    id="complaint-link"
                    value="6"
                    className={this.state.oosToIqc ? "activebm" : ""}>
                    OOS to Testing Inward
                  </li>
                )}
                 {(userRoleId == 1 || userRoleId == 2 ) && (
                  <li
                    onClick={this.handleClickSubHeader}
                    id="complaint-link"
                    value="7"
                    className={this.state.qcfailTesting ? "activebm" : ""}>
                   QC fail to testing inward
                  </li>
                )}
                {/* <li onClick={this.handleClickSubHeader} id="bulk-rpu-shiprocket" value="4" className={this.state.bulkRpuShiprocket ? 'activebm' : ''}>Bulk RPU Shiprocket</li> */}
              </div>
              {this.state.isHandover && (
                <div>
                  <div className="filter-row">
                    <div className="ticketNumberInput">
                      <div className="ticket_head">Way Bill No</div>
                      <textarea
                        placeholder="Enter Way Bill No"
                        className="text-area-input"
                        value={this.state.wayBillNumbers}
                        onChange={this.handleChangeWayInput}
                      />
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        checked={this.state.isHandoverToCourier}
                        onChange={this.handleChangeHandoverScanning}
                        className="scanning"></input>
                      <label
                        className="select-all-label"
                        htmlFor="select_all_movement">
                        Handover To Courier
                      </label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        value="warehouse"
                        checked={this.state.isWarehouse}
                        onChange={this.handleChangeWarehouseScanning}
                        className="warehouse"
                      />
                      <label
                        className="select-all-label"
                        htmlFor="select_all_movement">
                        Package Received at WH
                      </label>
                    </div>
                    {/* <div className="input-wrap-bulk-main only-movement">
                                        <input type="checkbox" value="handoverToCourier" checked={this.state.isHandoverToCourier} onChange={this.handleChangeHandoverScanning} className="input-wrap-bulk" />
                                        <label className="select-all-label" htmlFor="select_all_movement">Hanover To Courier</label>
                                    </div>
                                    <div className="input-wrap-bulk-main only-movement-warehouse">
                                        <input type="checkbox" id="select_all_movement" value="warehouse" checked={this.state.isWarehouse} onChange={this.handleChangeWarehouseScanning} className="input-wrap-bulk" />
                                        <label className="select-all-label" htmlFor="select_all_movement">Package Received at WH</label>
                                    </div> */}
                    <div className="transfer">
                      <button
                        type="button"
                        onClick={this.handleWayBillSubmit}
                        className="transferButton">
                        Submit
                      </button>
                    </div>
                  </div>
                  <div className="ticket-summary">
                    {this.props.successWayBillList.length > 0 ? (
                      <div style={{ color: "green" }}>
                        <h4 style={{ fontSize: "15px" }}>
                          Last Success Way Bill Numbers
                        </h4>
                        {this.props.successWayBillList.map((sd, index) => (
                          <div style={{ color: "white", fontSize: "15px" }}>
                            {sd}
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {this.props.failureWayBillList.length > 0 ? (
                      <div style={{ color: "red" }}>
                        <h4 style={{ fontSize: "15px" }}>
                          Last Failure Way Bill Numbers
                        </h4>
                        {this.props.failureWayBillList.map((sd, index) => (
                          <div style={{ color: "white", fontSize: "15px" }}>
                            {sd}
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
              {this.state.transferList && (
                <div>
                  <div className="filter-row">
                    <div>
                      <div className="choose_type">Choose Type</div>
                      <select
                        onChange={this.handleChangeChooseType}
                        className="dropdown">
                        <option value="entryType">Enter Ticket</option>
                        <option value="selectType">Select Ticket</option>
                      </select>
                    </div>
                    {!this.state.isEnterTicketType ? (
                      <div className="">
                        <h6 className="date-heading">Date - (DD/MM/YYYY)</h6>
                        <DateRangePicker
                          showTwoCalendar
                          format="dd/MM/yyyy"
                          disabledDate={allowedRange(
                            moment(
                              new Date(
                                new Date().setDate(new Date().getDate() - 120)
                              )
                            ).format("YYYY-MM-DD"),
                            moment(new Date()).format("YYYY-MM-DD")
                          )}
                          onOk={(value) => {
                            this.dateSelect(value);
                          }}
                          value={[
                            new Date(this.state.startDate),
                            new Date(this.state.endDate),
                          ]}
                        />
                      </div>
                    ) : null}
                    {!this.state.isEnterTicketType ? (
                      <div className="transfer_from">
                        <div>Transfer From</div>
                        <select
                          onChange={(e) => this.handleChangeTransferFrom(e)}
                          value={this.state.transferFrom}
                          className="dropdown">
                          {this.props.bucketData.map((bd, index) => (
                            <option key={index} value={bd.id}>
                              {bd.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : null}
                    <div className="transfer_to">
                      <div className="transfer_to">Transfer to</div>
                      <select
                        onChange={(e) => this.handleChangeTransferTo(e)}
                        value={this.state.transferTo}
                        className="dropdown">
                        {
                          this.props.bucketData.map((bd, index) =>
                            !hideBuckets.includes(bd.id) ? (
                              <option
                                disabled={bd.id == "35" || bd.id == "53"}
                                key={index}
                                value={bd.id}>
                                {bd.name}
                              </option>
                            ) : null
                          )
                          // this.props.bucketData.map((bd, index) => !this.state.isEnterTicketType && Number(this.state.transferFrom) !== bd.id ? (
                          //     <option key={index} value={bd.id}>{bd.id}</option>
                          // ) : this.state.isEnterTicketType ? (
                          //     <option key={index} value={bd.id}>{bd.id}</option> ): (null
                          // )
                          // )
                        }
                      </select>
                    </div>
                    {/* <DateRangePicker showTwoCalendar format="yyyy-MM-dd" disabledDate={allowedRange(moment(new Date(new Date().setDate(new Date().getDate() - 90))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.dateSelect(value) }} value={[new Date(this.state.startDate), new Date(this.state.endDate)]} /> */}
                    {!this.state.isEnterTicketType ? (
                      <div className="status">
                        <div className="white-color">Status</div>
                        <select
                          onChange={this.handleChangeStatus}
                          className="dropdown">
                          {this.props.statusData.map((sd, index) => (
                            <option key={index} value={sd.id}>
                              {sd.status_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : null}
                  </div>

                  {this.state.isEnterTicketType ? (
                    <div>
                      <div className="enter-ticket-module">
                        <div className="ticketNumberInput">
                          <div className="ticket_head">Ticket Numbers</div>
                          <textarea
                            placeholder="Enter Ticket Numbers"
                            className="text-area-input"
                            value={this.state.ticketNumbers}
                            onChange={this.handleChangeInput}
                          />
                        </div>
                        {/* <div className="only-movement">
                                        <input type="checkbox" name="onlyMove" checked={this.state.onlyMovement} onChange={this.handleChangeOnlyMovement} />
                                        <label className="select-all-label">Only movement</label>
                                    </div> */}
                        <span className="input-wrap-bulk-main only-movement">
                          <input
                            type="checkbox"
                            id="select_all_movement"
                            name="onlyMove"
                            checked={this.state.onlyMovement}
                            onChange={this.handleChangeOnlyMovement}
                            className="input-wrap-bulk"
                          />
                          <label
                            className="select-all-label"
                            htmlFor="select_all_movement">
                            Only movement
                          </label>
                        </span>
                      </div>
                      <div className="transfer">
                        <button
                          type="button"
                          onClick={this.handleSubmit}
                          className="transferButton">
                          Transfer
                        </button>
                      </div>
                      {this.state.showTicketSummary && (
                        <div className="ticket-summary">
                          {this.props.successList.length > 0 ? (
                            <div style={{ color: "green" }}>
                              <h4>Last Success Tickets</h4>
                              {this.props.successList.map((sd, index) => (
                                <div style={{ color: "white" }}>
                                  {sd.ticketNumber}
                                </div>
                              ))}
                            </div>
                          ) : null}
                          {this.props.failureList.length > 0 ? (
                            <div style={{ color: "red" }}>
                              <h4>Last Failure Tickets</h4>
                              {this.props.failureList.map((sd, index) => (
                                <div style={{ color: "white" }}>
                                  {sd.ticketNumber}
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="select-all-tab">
                        <span className="input-wrap-bulk-main">
                          <input
                            id="select_all"
                            type="checkbox"
                            name="allSelect"
                            checked={
                              this.state.ticketList &&
                              this.state.ticketList.length > 0
                                ? this.state.ticketList.filter(
                                    (td) => td.isChecked !== true
                                  ).length < 1
                                : false
                            }
                            onChange={this.handleChangeChk}
                            autoComplete="off"
                            className="input-wrap-bulk"
                          />
                          <label
                            className="select-all-label"
                            htmlFor="select_all">
                            Select All
                          </label>
                        </span>
                        <span className="input-wrap-bulk-main only-movement">
                          <input
                            type="checkbox"
                            id="select_all_movement"
                            name="onlyMove"
                            checked={this.state.onlyMovement}
                            onChange={this.handleChangeOnlyMovement}
                            className="input-wrap-bulk"
                          />
                          <label
                            className="select-all-label"
                            htmlFor="select_all_movement">
                            Only movement
                          </label>
                        </span>
                        {!this.state.isEnterTicketType ? (
                          <div className="transfer">
                            <button
                              type="button"
                              onClick={this.handleSubmit}
                              className="transferButton">
                              Transfer
                            </button>
                          </div>
                        ) : null}
                      </div>

                      {this.state.ticketList &&
                      this.state.ticketList.length > 0 ? (
                        <div>
                          <ul className="ticket-list-module">
                            {this.state.ticketList.map((td, index) => (
                              <li className="ticket-list" key={index}>
                                <div>
                                  <input
                                    id={"ticket-list-index-" + index}
                                    className="input-wrap-bulk"
                                    type="checkbox"
                                    name={td.id}
                                    onChange={this.handleChangeChk}
                                    checked={td.isChecked || false}
                                  />
                                  <label
                                    htmlFor={
                                      "ticket-list-index-" + index
                                    }></label>
                                </div>
                                <div className="ticket_info">
                                  <div className="ticketId">{td.ticket_id}</div>
                                  <div className="productName">
                                    {td.product_name}(
                                    {td.size && "Size " + td.size + " | "}
                                    {td.color_name})
                                  </div>
                                </div>
                                <div className="productInformation">
                                  <div className="productProblem">
                                    {td.problem}
                                  </div>
                                  <div className="probleDescription">
                                    {td.problem_description}
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                          <Footer
                            new_page={new_page}
                            onPrevPress={this.onPrevPress}
                            onNextPress={this.onNextPress}
                            current_page={this.state.current_page}
                            cc={"kk"}
                          />
                          {/* <div className="pagination">
                                                <button className="btn" onClick={this.prev} disabled={!this.state.offset}>
                                                    Prev
                                                </button>
                                                <button className="btn" onClick={this.next} disabled={!this.props.nextButtonDisable}>
                                                    Next
                                                </button>
                                            </div> */}
                        </div>
                      ) : (
                        <h3 className="empty-data">No Data Available</h3>
                      )}
                    </div>
                  )}
                </div>
              )}
              {this.state.isDispatchInward && (
                <div>
                  <div className="dispatch_inward">
                    <div className="ticketNumberInput">
                      <div className="ticket_head">Ticket No</div>
                      <textarea
                        placeholder="Enter Ticket No"
                        className="text-area-input"
                        value={this.state.wayBillNumbers}
                        onChange={this.handleChangeWayInput}
                      />
                      <div className="error_add">
                        <span className="errorShow">
                          {this.state.errors["wayBillNumbers"]}
                        </span>
                      </div>
                    </div>
                    <div className="selectStatus">
                      <div className="choose_type">Status</div>
                      <select
                        onChange={this.handleChangeStatusDI}
                        className="dropdown"
                        value={this.state.status}>
                        <option value="">Select Status</option>
                        <option value="replace">Replace</option>
                        <option value="working">Working</option>
                        <option value="repair">Repair</option>
                        <option value="upgrade">Upgrade</option>
                        <option value="wrong_product">Wrong Product</option>
                        <option value="physical_damage">Physical Damage</option>
                      </select>
                      <div className="error_add">
                        <span className="errorShow">
                          {this.state.errors["status"]}
                        </span>
                      </div>
                    </div>
                    {this.state.showProducts && (
                      <div className="showProducts">
                        <div className="select_product">Select product</div>
                        <select
                          onChange={this.handleChangeAccessory}
                          className="dropdown"
                          value={this.state.acc_replacement}>
                          <option value="0">Select replacement</option>
                          <option value="Acc">Acc</option>
                          <option value="Charging case- B2B">
                            Charging case- B2B
                          </option>
                          <option value="Earbuds-B2B">Earbuds-B2B</option>
                          <option value="Charging case- New">
                            Charging case- New
                          </option>
                          <option value="Earbuds-New">Earbuds-New</option>
                          <option value="Repair">Repair</option>
                          <option value="RWR">RWR</option>
                          <option value="Same Unit">Same Unit</option>
                          <option value="Unit B2B">Unit B2B</option>
                          <option value="Unit New">Unit New</option>
                          <option value="Dial-New">Dial-New</option>
                          <option value="Dial-B2B">Dial-B2B</option>
                          <option value="Unit Alternate Repack">
                            Unit Alternate Repack
                          </option>
                          <option value="Unit Alternate New">
                            Unit Alternate New
                          </option>
                          <option value="Unit Repack">Unit Repack</option>
                          <option value="only_cable">
                            Same unit-only cable replaced
                          </option>
                        </select>
                        <div className="error_add">
                          <span className="errorShow">
                            {this.state.errors["accessory"]}
                          </span>
                        </div>
                      </div>
                    )}
                    {this.state.showUpgrade && (
                      <div id="fontgood" className="select-prod-comp">
                        <div className="dropdown-wrap-problem ">
                          <h6>Select Product</h6>
                          <Select
                            onChange={this.onProductChange}
                            options={optionBucketDrop}
                            isSearchable={true}
                            className="select-product-css selectProductDropdown"
                            //values={this.state.productId}
                            defaultValue={this.state.selectedProduct}
                            maxMenuHeight={200}
                            placeholder="Search Product.."
                            // closeMenuOnSelect={true}
                            styles={{
                              singleValue: (provided) => ({
                                ...provided,
                                color: "#fff !important",
                              }),
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                border: "1px solid #595959",
                                boxShadow: "0 !important",
                                "&:hover": {
                                  border: "1px solid #595959 !important",
                                },
                                color: "#fff !important",
                                borderRadius: "20px !important",
                                backgroundColor: "#000 !important",
                                padding: "0 5px",
                                fontSize: "14px !important",
                                minHeight: "32px !important",
                                height: "32px !important",
                              }),
                              option: (baseStyles, state) => ({
                                ...baseStyles,
                                background: state.isFocused
                                  ? "#402500d7 !important"
                                  : "#000 !important",
                                color: state.isFocused
                                  ? "#FA9F22 !important"
                                  : "#fff !important",
                                width: "100%",
                                fontSize: "14px !important",
                              }),
                              Input: (baseStyles, state) => ({
                                ...baseStyles,
                                color: state.isFocused
                                  ? "#fff !important"
                                  : "#000",
                              }),
                              placeholder: (baseStyles) => ({
                                ...baseStyles,
                                color: "#fff !important",
                              }),
                              indicatorSeparator: () => null,
                              valueContainer: (baseStyles) => ({
                                ...baseStyles,
                                minHeight: "auto !important",
                                height: "auto !important",
                                color: "#fff !important",
                                marginTop: "-13px !important",
                              }),
                              DropdownIndicator: (baseStyles) => ({
                                ...baseStyles,
                                height: "25px !important",
                                marginBottom: "-13px !important",
                              }),
                              menuList: (baseStyles) => ({
                                ...baseStyles,
                                background: "#000 !important",
                              }),
                            }}
                          />
                        </div>
                        <div className="error_add">
                          <span className="errorShow">
                            {this.state.errors["color"]}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  {this.state.showProducts && (
                    <div className="row">
                      {(this.state.acc_replacement === "Acc" ||
                        this.state.acc_replacement === "Repair" ||
                        this.state.acc_replacement ===
                          "Unit Alternate Repack" ||
                        this.state.acc_replacement ===
                          "Unit Alternate New") && (
                        <div className="acc_selected">
                          <div>Note:</div>
                          <textarea
                            placeholder="Please add note"
                            className="text-area-input"
                            value={this.state.note}
                            onChange={this.handleNote}
                          />
                          {/* <textarea  placeholder='Please add note' value={this.state.note} onChange={this.handleNote}>
                                                    </textarea> */}
                          <div className="error_add">
                            <span className="errorShow">
                              {this.state.errors["note"]}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="row">
                    <div className="only_movement_wrapper">
                      <span className="input-wrap-bulk-main only-movement">
                        <input
                          type="checkbox"
                          id="select_all_movement"
                          name="onlyMove"
                          checked={this.state.onlyMovement}
                          onChange={this.handleChangeOnlyMovement}
                          className="input-wrap-bulk"
                        />
                        <label
                          className="select-all-label"
                          htmlFor="select_all_movement">
                          Only movement
                        </label>
                      </span>
                    </div>
                    <div className="transfer">
                      <button
                        type="button"
                        onClick={this.handleTransferDispatchInward}
                        className="transferButton">
                        Submit
                      </button>
                    </div>
                    {this.state.showSummary && (
                      <div className="ticket-summary">
                        {this.props.successListDI.length > 0 ? (
                          <div style={{ color: "green" }}>
                            <h4>Last Success Tickets</h4>
                            {this.props.successListDI.map((sd, index) => (
                              <div style={{ color: "white" }}>{sd}</div>
                            ))}
                          </div>
                        ) : null}
                        {this.props.failureListDI.length > 0 ? (
                          <div style={{ color: "red" }}>
                            <h4 className="faliure_head">
                              Last Failure Tickets
                            </h4>
                            {this.props.failureListDI.map((sd, index) => (
                              <div style={{ color: "white" }}>{sd}</div>
                            ))}
                          </div>
                        ) : null}
                        {this.props.macFailureList.length > 0 ? (
                          <div style={{ color: "red" }}>
                            <h4 className="faliure_head">
                              Cannot move: MAC ID is missing
                            </h4>
                            {this.props.macFailureList.map((sd, index) => (
                              <div style={{ color: "white" }}>{sd}</div>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>{" "}
                  :<div></div>
                </div>
              )}
              {this.state.bulkRpu && (
                <div>
                  <div className="bulk-rpu">
                    <div className="ticketNumberInput">
                      <div className="ticket_head">Ticket No</div>
                      <textarea
                        placeholder="Enter Ticket No"
                        className="text-area-input"
                        value={this.state.ticketNumbersRPU}
                        onChange={this.inputTicketNumbers}
                      />
                      <div className="error_add">
                        <span className="errorShow">
                          {this.state.errors["ticketNos"]}
                        </span>
                      </div>
                    </div>
                    <div className="selectStatus">
                      <div className="list">Status</div>
                      <select
                        onChange={this.onPickupChange}
                        className="dropdown"
                        value={this.state.pickupType}>
                        <option value="reverse_pick_up">Reverse Pick Up</option>
                        <option value="forward_pick_up">Forward Pick Up</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="only_movement_wrapper">
                      <span className="bulk_rpu only-movement">
                        <input
                          type="checkbox"
                          id="select_all_movement"
                          name="onlyMove"
                          checked={this.state.onlyMovement}
                          onChange={this.handleChangeOnlyMovement}
                          className="input-wrap-bulk"
                        />
                        <label
                          className="select-all-label"
                          htmlFor="select_all_movement">
                          Only movement
                        </label>
                      </span>
                    </div>
                    <div className="transfer">
                      <button
                        type="button"
                        onClick={this.handleBulkRPU}
                        className="transferButton">
                        Submit
                      </button>
                    </div>
                  </div>
                  {this.state.showSummary && (
                    <div className="bulk_rpu_response">
                      {this.props.SucccessListBRPU.length > 0 ? (
                        <div style={{ color: "green" }}>
                          <h4>Last Success Tickets</h4>
                          {this.props.SucccessListBRPU.map((sd, index) => (
                            <div style={{ color: "white" }}>{sd.ticket_id}</div>
                          ))}
                        </div>
                      ) : null}
                      {this.props.FailureListBRPU.length > 0 ? (
                        <div style={{ color: "red", marginLeft: "4%" }}>
                          <h4 className="faliure_head">Last Failure Tickets</h4>
                          {this.props.FailureListBRPU.map((sd, index) => (
                            <div style={{ color: "white" }}>{sd}</div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              )}
              {this.state.bulkRpuShiprocket && (
                <div>
                  <div className="bulk-rpu">
                    <div className="ticketNumberInput">
                      <div className="ticket_head">Ticket No</div>
                      <textarea
                        placeholder="Enter Ticket No"
                        className="text-area-input"
                        value={this.state.ticketNumbersRPU}
                        onChange={this.inputTicketNumbers}
                      />
                      <div className="error_add">
                        <span className="errorShow">
                          {this.state.errors["ticketNos"]}
                        </span>
                      </div>
                    </div>
                    <div className="selectStatus">
                      <div className="list">Status</div>
                      <select
                        onChange={this.onPickupChange}
                        className="dropdown"
                        value={this.state.pickupType}>
                        <option value="reverse_pick_up">Reverse Pick Up</option>
                        <option value="forward_pick_up">Forward Pick Up</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="only_movement_wrapper">
                      <span className="bulk_rpu only-movement">
                        <input
                          type="checkbox"
                          id="select_all_movement"
                          name="onlyMove"
                          checked={this.state.onlyMovement}
                          onChange={this.handleChangeOnlyMovement}
                          className="input-wrap-bulk"
                        />
                        <label
                          className="select-all-label"
                          htmlFor="select_all_movement">
                          Only movement
                        </label>
                      </span>
                    </div>
                    <div className="transfer">
                      <button
                        type="button"
                        onClick={this.handleBulkRPU}
                        className="transferButton">
                        Submit
                      </button>
                    </div>
                  </div>
                  {this.state.showSummary && (
                    <div className="bulk_rpu_response">
                      {this.props.SucccessListBRPU.length > 0 ? (
                        <div style={{ color: "green" }}>
                          <h4>Last Success Tickets</h4>
                          {this.props.SucccessListBRPU.map((sd, index) => (
                            <div style={{ color: "white" }}>{sd.ticket_id}</div>
                          ))}
                        </div>
                      ) : null}
                      {this.props.FailureListBRPU.length > 0 ? (
                        <div style={{ color: "red", marginLeft: "4%" }}>
                          <h4 className="faliure_head">Last Failure Tickets</h4>
                          {this.props.FailureListBRPU.map((sd, index) => (
                            <div style={{ color: "white" }}>{sd}</div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              )}
              {this.state.testInward && (
                <div>
                  <div className="dispatch_inward">
                    <div className="ticketNumberInput">
                      <div className="ticket_head">Ticket No</div>
                      <textarea
                        placeholder="Enter Ticket No"
                        className="text-area-input"
                        value={this.state.wayBillNumbers}
                        onChange={this.handleChangeWayInput}
                      />
                      <div className="error_add">
                        <span className="errorShow">
                          {this.state.errors["test_waybil"]}
                        </span>
                      </div>
                    </div>
                    <div className="selectStatus">
                      <div className="choose_type">Status</div>
                      <select
                        onChange={(e) => this.hanldeTestingInwardStatus(e)}
                        className="dropdown"
                        value={this.state.testInwardStatus}>
                        <option value="">Select Status</option>
                        <option value="1">QC Pass</option>
                        <option value="2">QC Fail</option>
                      </select>
                      <div className="error_add">
                        <span className="errorShow">
                          {this.state.errors["test_status"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="transfer">
                    <button
                      style={{ marginTop: "3%" }}
                      type="button"
                      onClick={this.handleBulkTestingInward}
                      className="transferButton">
                      Submit
                    </button>
                  </div>
                  <div className="ticket-summary">
                    {this.props.testingSuccess?.length > 0 ? (
                      <div style={{ color: "green" }}>
                        <h4 style={{ fontSize: "15px" }}>Success</h4>
                        {this.props.testingSuccess?.map((sd, index) => (
                          <div style={{ color: "white", fontSize: "15px" }}>
                            {sd}
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {this.props.testingFailure?.length > 0 ? (
                      <div style={{ color: "red" }}>
                        <h4 style={{ fontSize: "15px" }}>Failed</h4>
                        {this.props.testingFailure?.map((sd, index) => (
                          <div style={{ color: "white", fontSize: "15px" }}>
                            {sd}
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {this.props.testingMAcFilure?.length > 0 ? (
                      <div style={{ color: "red" }}>
                        <h4 style={{ fontSize: "15px" }}>MAC Failuer</h4>
                        {this.props.testingMAcFilure.map((sd, index) => (
                          <div style={{ color: "white", fontSize: "15px" }}>
                            {sd}
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
              {this.state.complaintsLink && (
                <div>
                  <div className="filter-row">
                    <div className="ticketNumberInput">
                      <div className="ticket_head">Mobile number</div>
                      <textarea
                        placeholder="Enter mobile number"
                        className="text-area-input"
                        value={this.state.mobileNumber}
                        onChange={(e) =>
                          this.setState({ mobileNumber: e.target.value })
                        }
                        maxLength={10}
                        type="number"
                      />
                      {this.state.mobileError && (
                        <span style={{ color: "red" }}>
                          {this.state.mobileError}
                        </span>
                      )}
                    </div>
                    <div className="transfer">
                      <button
                        type="button"
                        onClick={this.sendComplaintLink}
                        className="transferButton">
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {(userRoleId == 1 || userRoleId == 2) && this.state.oosToIqc && (
                <div>
                  <div className="dispatch_inward">
                    <div className="ticketNumberInput">
                      <div className="ticket_head">Ticket No</div>
                      <textarea
                        placeholder="Enter Ticket No"
                        className="text-area-input"
                        value={this.state.wayBillNumbers}
                        onChange={this.handleChangeWayInput}
                      />
                    </div>
                  </div>
                  <div className="transfer">
                    <button
                      style={{ marginTop: "3%" }}
                      type="button"
                      onClick={this.handleOosToIqc}
                      className="transferButton">
                      Submit
                    </button>
                  </div>
                  <div className="ticket-summary">
                    {this.state.oosIqcSuccess?.length > 0 ? (
                      <div style={{ color: "green" }}>
                        <h4 style={{ fontSize: "15px" }}>Success</h4>
                        {this.state.oosIqcSuccess?.map((sd, index) => (
                          <div style={{ color: "white", fontSize: "15px" }}>
                            {sd?.ticketNumber}
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {this.state.oosIqcFailure?.length > 0 ? (
                      <div style={{ color: "red" }}>
                        <h4 style={{ fontSize: "15px" }}>Failed</h4>
                        {this.state.oosIqcFailure?.map((sd, index) => (
                          <div style={{ color: "white", fontSize: "15px" }}>
                            {sd?.ticketNumber}
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
               {this.state.qcfailTesting && (
                <div>
                  <div className="dispatch_inward">
                    <div className="ticketNumberInput">
                      <div className="ticket_head">Ticket No</div>
                      <textarea
                        placeholder="Enter Ticket No"
                        className="text-area-input"
                        value={this.state.wayBillNumbers}
                        onChange={this.handleChangeWayInput}
                      />
                    </div>
                  </div>
                  <div className="transfer">
                    <button
                      style={{ marginTop: "3%" }}
                      type="button"
                      onClick={this.handleiqcTOTesting}
                      className="transferButton">
                      Submit
                    </button>
                  </div>
                  <div className="ticket-summary">
                    {this.state.iqctestingSuccess?.length > 0 ? (
                      <div style={{ color: "green" }}>
                        <h4 style={{ fontSize: "15px" }}>Success</h4>
                        {this.state.iqctestingSuccess?.map((sd, index) => (
                          <div style={{ color: "white", fontSize: "15px" }}>
                            {sd}
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {this.state.iqctestingfailure?.length > 0 ? (
                      <div style={{ color: "red" }}>
                        <h4 style={{ fontSize: "15px" }}>Failed</h4>
                        {this.state.iqctestingfailure?.map((sd, index) => (
                          <div style={{ color: "white", fontSize: "15px" }}>
                            {sd?.ticketNumber}
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  bucketData: state.bulkmovement.bucketData,
  ticketData: state.bulkmovement.ticketData,
  statusData: state.bulkmovement.statusData,
  successList: state.bulkmovement.successList,
  successListDI: state.bulkmovement.successListDI,
  failureList: state.bulkmovement.failureList,
  failureListDI: state.bulkmovement.failureListDI,
  macFailureList: state.bulkmovement.macFailureList,
  failureWayBillList: state.bulkmovement.failureWayBillList,
  successWayBillList: state.bulkmovement.successWayBillList,
  nextButtonDisable: state.bulkmovement.nextButtonDisable,
  paginationCount: state.bulkmovement.paginationCount,
  paginationCount: state.complaints.paginationCount,
  productData: state.complaints.productData,
  bulkConstant: state.bulkmovement.bulkConstant,
  SucccessListBRPU: state.bulkmovement.SucccessListBRPU,
  FailureListBRPU: state.bulkmovement.FailureListBRPU,
  testingFailure: state.bulkmovement.testingFailure,
  testingSuccess: state.bulkmovement.testingSuccess,
  testingMAcFilure: state.bulkmovement.testingMAcFilure,
});

export default connect(mapStateToProps, {
  fetchAllBuckets,
  fetchTicketList,
  submitTransfer,
  fetchBucketStatus,
  submitWayBillNumbers,
  selectedSubmitTransfer,
  submitWayBillNumbersToWarehouse,
  getProductList,
  submitDispatchInward,
  fetchBulkConstant,
  clearDIDataResults,
  clearBulkTransferDataResults,
  submitBulkRPU,
  clearBulkRpuDataResults,
  qcPassBuckets,
  qcFailBuckets,
  clearTEstingBulk,
  complaintResend,
  fetchoosIQC,
  iqxToTestingAction
})(BulkmovementComponent);
