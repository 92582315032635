import {
  FETCH_DISTRIBUTOR_DATA, GET_COMPLAINT_BUCKET,
  GET_DISTRIBUTOR_COMPLAINTS_DETAIL, ADD_STATUS_ID, GET_COMPLAINTS_DETAIL, SHOW_COMPLAINT_DETAILS,
  DISTRIBUTOR_COMPLAINT_STATUS, ADD_COMPLAINT_DISTRIBUTOR, FETCH_SAVE_TICKET, CALL_SERVICE_CENTER,
  FETCH_COMPLAINTS_COUNT, FETCH_DISTRIBUTOR_CONVERSATIONS, PRINT_LABEL_DATA, FETCH_PAGINATION_INFO,
  DISTRIBUTOR_SCREENING_RESPONSE, FETCH_INDIVIDUAL_PRODUCTS, COMPLAINT_PRODUCT_LIST, EDIT_COMPLAINT, FETCH_ALL_PRODUCTS_CLUBBED
  , FETCH_COMMENT_LOAD, FETCH_COMMENT_DATA, FETCH_CONVERSATIONS_LOAD, FETCH_CONVERSATIONS_DATA
} from '../actions/types';

const initialState = {
  distributorData: [],
  showDistributorComplaintDetails: [],
  complaintsDetail: undefined,
  statusData: [],
  statusId: 1,
  showComplaintDetails: false,
  bucketData: [],
  distributorComplainDetails: [],
  distributorComplaintStatus: [],
  distributorTicketId: [],
  saveticket: [],
  callServiceCenter: [],
  distributorDataCount: [],
  distributorConversationData: [],
  printLabel: null,
  paginationCount: [],
  distributorScreeningRes: [],
  individualProductList: [],
  complaintProductList: [],
  editComplaint: [],
  allProductsClubbed: [],
  commentSCpage: [],
  commentSCDetails: [],
  SCconversationpage: [],
  SCconversationDetails: [],

};

export default function (state = initialState, action) {
  switch (action.type) {

    case DISTRIBUTOR_SCREENING_RESPONSE:
      return {
        ...state,
        distributorScreeningRes: action.payload,
      };
    case FETCH_DISTRIBUTOR_DATA:
      return {
        ...state,
        distributorData: action.payload,
      };
    case GET_COMPLAINTS_DETAIL:
      return {
        ...state,
        complaintsDetail: action.payload,
      };
    case ADD_STATUS_ID:
      return {
        ...state,
        statusId: action.payload
      };
    case SHOW_COMPLAINT_DETAILS:
      return {
        ...state,
        showComplaintDetails: action.payload
      };
    case GET_COMPLAINT_BUCKET:
      return {
        ...state,
        bucketData: action.payload,
      };
    case GET_DISTRIBUTOR_COMPLAINTS_DETAIL:
      return {
        ...state,
        distributorComplainDetails: action.payload,
      };
    case FETCH_SAVE_TICKET:
      return {
        ...state,
        saveticket: action.payload
      };
    case CALL_SERVICE_CENTER:
      return {
        ...state,
        callServiceCenter: action.payload,
      };
    case DISTRIBUTOR_COMPLAINT_STATUS:
      return {
        ...state,
        distributorComplaintStatus: action.payload,
      };
    case ADD_COMPLAINT_DISTRIBUTOR:
      return {
        ...state,
        distributorTicketId: action.payload,
      };
    case FETCH_COMPLAINTS_COUNT:
      return {
        ...state,
        distributorDataCount: action.payload,
      };
    case FETCH_DISTRIBUTOR_CONVERSATIONS:
      return {
        ...state,
        distributorConversationData: action.payload
      };
    case PRINT_LABEL_DATA:
      return {
        ...state,
        printLabel: action.payload,
      };
    case FETCH_PAGINATION_INFO:
      return {
        ...state,
        paginationCount: action.payload,
      };
    case FETCH_INDIVIDUAL_PRODUCTS:
      return {
        ...state,
        individualProductList: action.payload,
      };
    case COMPLAINT_PRODUCT_LIST:
      return {
        ...state,
        complaintProductList: action.payload,
      };
    case FETCH_ALL_PRODUCTS_CLUBBED:
      return {
        ...state,
        allProductsClubbed: action.payload,
      };

    case EDIT_COMPLAINT:
      return {
        ...state,
        editComplaint: action.payload
      };
    case FETCH_COMMENT_DATA:
      return {
        ...state,
        commentSCDetails: action.payload
      };
    case FETCH_COMMENT_LOAD:
      return {
        ...state,
        commentSCpage: action.payload
      };
    case FETCH_CONVERSATIONS_DATA:
      return {
        ...state,
        SCconversationDetails: action.payload
      };
    case FETCH_CONVERSATIONS_LOAD:
      return {
        ...state,
        SCconversationpage: action.payload
      };
    // case ADD_DISTRIBUTOR_STATUS_ID:
    //   return {
    //     ...state,
    //     distributorstatusData: action.payload,
    //   };
    //   case GET_DISTRIBUTOR_COMPLAINTS_DETAIL:
    //     return {
    //       ...state,
    //       distributorcomplaintsDetail: action.payload,
    //     };
    default:
      return state;
  }
}
