import React, { Component } from 'react';
import { connect } from 'react-redux';
import ViewProfile from '../ViewProfile';
import './RTLComplaintComponent.css';
import AccessRestricted from '../AccessRestricted';
import moment from 'moment';
import DateRangePicker from 'rsuite/DateRangePicker';
import RTLComplainComponentnew from "./RTLComplainComponentnew";
import Loader from 'react-loader-spinner';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactModal from 'react-modal';
import SidebarComponent from '../SidebarComponent/SidebarComponent';
import {
  getDistributorData, showComplaintDetailsAction, getDistributorComplaintDetail, addStatusId, getComplaintBuckets, onSaveTicket, callCustomer, getRPUCourier, onSelfShip, onCloseTicket,
  editDistributorComplaint
} from '../../actions/distributorAction';
import { clearDIDataResults, submitDispatchInwardRetailer } from '../../actions/bulkmovementAction';
import { getStateList, getCityByState, getProductProblemList } from '../../actions/complaintsDataAction';
import Footer from '../FooterComponent/footer';

const {
  // allowedMaxDays,
  // allowedDays,
  allowedRange,
  // beforeToday,
  // afterToday,
  // combine
} = DateRangePicker;

const styleEdit = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    inset: '0 0 0 auto',
    marginRight: '0',
    transform: 'translate(0)',
    background: '#121212',
    padding: '0',
    border: 'none',
    height: '100%',
    overflow: 'hidden',
    overflowY: 'scroll',
    position: 'fixed',
    width: '50%',
    float: 'right',
    productName: '',
    isLoadingGif: false,
    distributorComplainDetails: '',
  },
};

// const customStylesRPU = {
//   overlay: {
//     background: 'rgba(0, 0, 0, 0.5)',
//   },
//   content: {
//     inset: '8% 0 0 auto',
//     marginRight: '0',
//     transform: 'translate(0)',
//     background: '#000',
//     padding: '0',
//     border: 'none',
//     height: '600px',
//     overflow: 'hidden',
//     overflowY: 'scroll',
//     position: 'fixed',
//     width: '30%',
//     float: 'right',
//     productName: '',
//     isLoadingGif: false,
//   },
// }

// const customStylesCallReminder = {
//   overlay: {
//     background: 'rgba(0, 0, 0, 0.5)',
//   },
//   content: {
//     inset: '60%  7%0 auto',
//     border: '1px solid #eb951f',
//     marginRight: '0',
//     transform: 'translate(0)',
//     background: '#000',
//     padding: ' 0',
//     borderRadius: '20px',
//     width: '35%',
//     position: 'absolute'
//   },
// };

class RTLComplaintsComponent extends Component {
  constructor() {
    super();
    this.state = {
      showEditTicket: false,
      showRPUModal: false,
      showCallReminder: false,
      isLoading: false,
      searchText: '',
      startDate: new Date().setDate(new Date().getDate() - 90),
      endDate: new Date().setDate(new Date().getDate()),
      current_page: 1,
      teamName: "CS",
      bucket_id: "1",
      sla_breached: null,
      complaint_id: '',
      ticketId: '',
      isWarranty: 1,
      today: '',
      callReminderDate: '',
      callReminderTime: '',
      time: '',
      errors: [],
      complaintId: 0,
      isSearch: false,
      warranty: '',
      distributorComplainList: '',
      productName: "",
      showProducts: false,
      status: '',
      showSummary: false,
      acc_replacement: "0",
      note: '',
      onlyMovement: false,
      assignedStatus: false,
      SLAList: [{ "id": 'true', "value": "Yes" }, { "id": 'false', "value": "No" }],
      SLAId: '',
      pageLimit: 10,
      loader2: false,
      ticketProducts: [],
      retailer: [],
      productIndex: 0,
      productDescription: [],
      subProblem: [],
      purchaseDate: [],
      retailerAddress: '',
      retailerAddress2: '',
      pincode: '',
      country_id: '',
      state_id: '',
      city_id: '',
      stateList: [],
      cityList: '',
      product_problem_id: [],
      problemList: [],
      distributorComplainList: [],
      mobile: '',
      email: '',
      wayBillNumbers: ''
    };
    this.handleRPUCloseModal = this.handleRPUCloseModal.bind(this);
    this.handleCourierChange = this.handleCourierChange.bind(this);
    this.handleChangeProductSelection = this.handleChangeProductSelection.bind(this);
    this.handleChangeButton = this.handleChangeButton.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.handleRefreshList = this.handleRefreshList.bind(this);
  }

  handleChangeProductSelection(a) {
    this.setState({ productName: a.value });
  }

  onItemPress = async (e, item) => {
    e.preventDefault();
    // await this.props.getComplaintDetail(item.id)
    // let detail = this.props.complaintsDetail.data[0]
    // history.push('/complaint-detail', {
    //   item: item
    // })
    await this.props.showComplaintDetailsAction(true);
    this.setState({
      loader2: true
    });
    // Store selected Complaint
    await this.props.addStatusId(item.bucketId);
    await this.props.getDistributorComplaintDetail(item.id);
    this.setState({
      loader2: false
    });
    // const element = document.querySelector(".activeColor");
    // element.scrollIntoView({ block: "center" });
    // element.scrollTop();
  };

  async componentDidMount() {
    let data = {
      team: this.state.teamName,
      ret_enb: true,
    };
    await this.props.getComplaintBuckets(data);
    if (this.props.bucketData.length > 0) {
      await this.props.addStatusId(this.props.bucketData[0].id);
    }
    this.getComplaintList();
  }

  dateSelect = async (value) => {
    this.setState({
      startDate: new Date(value[0]),
      endDate: new Date(value[1])
    }, async () => {
      this.getComplaintList();
      await this.props.showComplaintDetailsAction(false);
    });
  };

  onStatusChange = async (e) => {
    this.setState({ current_page: 1 });
    this.props.showComplaintDetailsAction(false);
    await this.props.addStatusId(e.target.value);
    this.getComplaintList();
  };

  onProducdProblemChange = async (e) => {
    this.setState({
      sla_breached: e.target.value
    });
    let data =
      await this.props.getDistributorData(data);
  };

  onMoveToComplaintClosedClick = async (e, item) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let data = {
      'complaint_id': item.id,
    };
    await this.props.onCloseTicket(data);
    this.setState({ isLoading: false });
    this.handleRefreshList();
  };

  onSaveClick = async (e, item) => {
    e.preventDefault();
    let data = {
      'complaint_id': item.id
    };
    await this.props.onSaveTicket(data);
  };

  handleCloseEditTicket = (() => {
    this.setState({ showEditTicket: false, errors: "" });
  });

  handleEdit = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRPUCloseModal() {
    this.setState({
      showRPUModal: false
    });
  }

  onRPUClick = async (e, item) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let data = {
      "complaint_id": item.id,
      "type": "reverse_pick_up"
    };
    this.setState({
      complaintId: item.id
    });
    await this.props.getRPUCourier(data);
    this.setState({ isLoading: false });
  };

  async handleCourierChange(e) {
    this.setState({
      courierId: e.target.value
    });
  }

  onPhoneClick = async (e, item) => {
    this.setState({ isLoading: false });
    await this.props.callCustomer(item.distributor_id, item.id);
    let callRes = this.props.callRes;
    if (callRes) {
      this.setState({ showCallReminder: false, isLoading: false });
      this.getComplaintList();
    }
    this.handleRefreshList();
  };

  handleChangeEvent = async (e) => {
    this.setState({ searchText: e.target.value, current_page: 1 });
    if (e.target.value.length === 0) {
      this.setState({ isSearch: false });
      await this.props.showComplaintDetailsAction(false);
      this.getComplaintList();
    }
  };

  handleClose = async (e) => {
    this.setState({ searchText: '', current_page: 1 });
    document.getElementById("search_input").value = '';
    this.setState({ isSearch: false });
    await this.props.showComplaintDetailsAction(false);
    this.getComplaintList();
  };

  _handleKeyDown(e) {
    if (e.key === 'Enter') {
      // this.handleChangeButton()
      this.handleChangeButton();
    }
  }

  handleChangeButton = async () => {
    // e.preventDefault();
    // this.handleRefreshList();
    let val = document.getElementById("search_input").value;
    if (val.length > 0) {
      this.setState({ isSearch: true });
    } else {
      this.setState({ isSearch: false });
    }
    await this.props.showComplaintDetailsAction(false);
    this.setState({ searchText: val, current_page: 1 }, () => {
      this.getComplaintList();
    });
  };

  onPrevPress = () => {
    if (this.props.paginationCount.prev_page) {
      // let new_page = (this.state.new_page1 - 1);
      // this.setState({new_page1: new_page});
      this.setState({ current_page: (this.state.current_page - 1) }, async () => {
        await this.props.showComplaintDetailsAction(false);
        this.getComplaintList();
      });
    }
  };

  onNextPress = () => {
    if (this.props.paginationCount.next_page) {
      // let new_page = (this.state.new_page1 - 1);
      // this.setState({new_page1: new_page});
      this.setState({ current_page: (this.state.current_page + 1) }, async () => {
        await this.props.showComplaintDetailsAction(false);
        this.getComplaintList();
      });
    }
    // if (this.state.nextPage) {
    //   this.setState({ pageNo: (this.state.pageNo + 1) }, () => {
    //     this.getComplaintList()
    //   })
    // }
  };

  getComplaintList = async () => {
    let data = {};
    if (this.state.searchText.length > 0) {
      data = {
        "page_no": this.state.current_page,
        "search_by": this.state.searchText
      };
      this.setState({
        showBucketName: true
      });
    }
    // else if (this.state.showPriority) {
    //   data = {
    //     "page_no": this.state.current_page,
    //     "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
    //     "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
    //     "is_priority": "1"
    //   }
    //   if (Number(this.state.productId) > 0)
    //     data["product_id"] = this.state.productId
    //   if (this.state.SLAId && this.state.SLAId !== "0")
    //     data["sla_breached"] = this.state.SLAId
    //   if (this.state.warranty && this.state.warranty !== 'All')
    //     data["is_warranty"] = this.state.warranty
    //   if (this.state.problemId && this.state.problemId !== 0)
    //     data["problem_by"] = this.state.problemId
    // }
    else if (!this.state.assignedStatus) {
      data = {
        "page_no": this.state.current_page,
        "limit": this.state.pageLimit,
        "search_by_date": this.state.searchDate,
        // "search_by": this.state.searchText,
        "status_by": '',
        "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
        "endDate": moment(this.state.endDate).format('YYYY-MM-DD')
      };
      if (Number(this.state.productId) > 0)
        data["product_id"] = this.state.productId;
      if (this.state.SLAId && this.state.SLAId !== "0")
        data["sla_breached"] = this.state.SLAId;
      // if (this.state.warranty && this.state.warranty !== 'All')
      //   data["is_warranty"] = this.state.warranty
      // if (this.state.problemId && this.state.problemId !== 0)
      //   data["problem_by"] = this.state.problemId
      // if (this.state.searchDate)
      //   data["search_by_date"] = this.state.searchDate
      data["bucket_id"] = this.props.statusId;
    }
    else {
      data = {
        "page_no": this.state.current_page,
        "assignedStatus": this.state.assignedStatus,
        "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
        "endDate": moment(this.state.endDate).format('YYYY-MM-DD')
      };
      // if (Number(this.state.productId) > 0)
      //   data["product_id"] = this.state.productId
      if (this.state.SLAId && this.state.SLAId !== "0")
        data["sla_breached"] = this.state.SLAId;
      // if (this.state.warranty && this.state.warranty !== 'All')
      //   data["is_warranty"] = this.state.warranty
      // if (this.state.problemId && this.state.problemId !== 0)
      //   data["problem_by"] = this.state.problemId
    }
    // if(Number(this.state.productId) > 0){
    //   data.bucket_id = 0 
    // }
    this.setState({ isLoading: true, complaintList: [], showBucketName: false });
    await this.props.getDistributorData(data);
    // let complaintsData = this.props.complaintsData
    let distributorDataCount = this.props.distributorDataCount;
    // let paginationCount = this.props.paginationCount;
    this.setState({
      isLoading: false,
      totalCount: distributorDataCount.total,
      totalPage: distributorDataCount.data ? distributorDataCount.data.no_page : 0,
      prevPage: distributorDataCount.data ? distributorDataCount.data.prev_page : 0,
      nextPage: distributorDataCount.data ? distributorDataCount.data.next_page : 1,
      // complaintList: complaintsData.data.complaint_data,
    });
  };

  onCategoryChange = (e) => {
    this.setState({ prodct_id: 1, city_id: 0, state_id: e.target.value }, () => {
      // this.getStateListApi();
      this.getCityListApi();
    });
  };

  onMenuClick = (e, teamName) => {
    this.setState({ teamName: teamName, current_page: 1 }, async () => {
      if (teamName !== 'DI') {
        let data = {
          team: this.state.teamName,
          ret_enb: true,
        };
        await this.props.getComplaintBuckets(data);
        if (this.props.bucketData.length > 0) {
          await this.props.addStatusId(this.props.bucketData[0].id);
        }
        await this.props.showComplaintDetailsAction(false);
        this.getComplaintList();
      }
    });
  };

  handleChangeWayInput = (e) => {
    this.setState({
      wayBillNumbers: e.target.value,
    });
  };

  handleChangeStatusDI = async (e) => {
    let id = e.target.value;
    if (e.target.value === 'replace') {
      this.setState({ showProducts: true });
    }
    // else if (e.target.value === 'upgrade') {
    //     this.setState({ showUpgrade: true, showProducts: false })
    //     await this.props.getProductList({});
    //     this.setState({
    //         productList: this.props.productData.data.products
    //     })
    // }
    else {
      this.setState({ showProducts: false });
    }
    this.setState({
      status: id,
      showSummary: false
    });
  };

  handleChangeAccessory = (e) => {
    this.setState({
      acc_replacement: e.target.value,
      showSummary: false
    });
  };

  handleNote = (e) => {
    this.setState({
      note: e.target.value
    });
  };

  handleChangeOnlyMovement = (e) => {
    // const { checked } = e.target
    this.setState({
      onlyMovement: !this.state.onlyMovement
    });
  };

  handleValidation = () => {
    let errors = {};
    let validity = true;
    if (this.state.status === '') {
      errors["status"] = "Please select appropriate status";
      validity = false;
    }
    if (this.state.wayBillNumbers === '') {
      errors["wayBillNumbers"] = "Please enter waybill number(s)";
      validity = false;
    }
    if (this.state.acc_replacement === '0') {
      errors["accessory"] = "Please select replacement accessory";
      validity = false;
    }
    this.setState({ errors: errors });
    return validity;
  };

  handleRefreshList() {
    this.props.showComplaintDetailsAction(false);
    this.getComplaintList();
  }

  handleTransferDispatchInward = async () => {
    let waybillnos = this.state.wayBillNumbers.split("\n");
    waybillnos = waybillnos.filter((wb) => wb !== "");
    let data;
    if (this.handleValidation() !== false) {
      this.setState({ isLoading: true });
      if (this.state.status === 'replace') {
        // let notes = this.state.note;
        data = {
          only_movement: this.state.onlyMovement,
          qc_status: this.state.status,
          replace_accessory: this.state.acc_replacement,
          note: this.state.note
          // ticketNumbers: waybillnos
        };
      }
      // Clear previous cache
      await this.props.clearDIDataResults();
      // Batch wise processing
      let size = this.props.bulkConstant[0] ? Number(this.props.bulkConstant[0].value) : 15;
      // let batchProcessing =  false;
      let arrays = [];
      while (waybillnos.length > 0) {
        arrays.push(waybillnos.splice(0, size));
      }
      for (let z = 0; z < arrays.length; z++) {
        data.ticketNumbers = arrays[z];
        await this.props.submitDispatchInwardRetailer(data);
      }
      this.setState({
        wayBillNumbers: "",
        showSummary: true,
        isLoading: false,
        isDispatchInward: true,
        status: '',
        acc_replacement: "0",
        note: '',
        onlyMovement: false
      });
    }
  };

  onCopy() {
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    alert("Copied!");
  }

  onSLAChange = (e) => {
    this.setState({ SLAId: e.target.value }, async () => {
      this.getComplaintList();
      await this.props.showComplaintDetailsAction(false);
    });
  };

  onEditClick = async (e, item) => {
    let data = item.id;
    this.getComplaintDetailApi(data);
    this.getStateListApi();
    this.getCityListApi();
    this.setState({ showEditTicket: true, ticketId: item.ticket_id });
    this.handleRefreshList();

  };

  getProblemList = async (distributorComplainList) => {
    let i = this.state.productIndex;
    await this.props.getProductProblemList({ "category_id": distributorComplainList.product[i].product_group_id });
    let problemList = this.props.problemData.problems;
    this.setState({ problemList });
  };

  getComplaintDetailApi = async (param) => {
    await this.props.getDistributorComplaintDetail(param);
    this.setState({ distributorComplainList: [], distributorProductList: [] });
    let distributorProductList = this.props.allProductsClubbed;
    let distributorComplainList = this.props.distributorComplainDetails;
    let productDescription = [];
    let subProblem = [];
    let newDate = [];
    if (distributorProductList.length > 0) {

      distributorProductList.map((item, index) => (
        productDescription.push(
          item.problem_description
        )
      ));


      distributorProductList.map((item, index) => (
        newDate.push(
          item.purchase_date
        )
      ));


      distributorProductList.map((item, index) => (
        subProblem.push(
          item.sub_problem_name
        )
      ));


      this.setState({
        distributorComplainList, distributorProductList,
        complaintId: distributorComplainList.id ? distributorComplainList.id : '',
        ticketProducts: distributorProductList ? distributorProductList : '',
        retailer: distributorComplainList.retailer ? distributorComplainList.retailer : '',
        productDescription, subProblem, purchaseDate: newDate,
        retailerAddress: distributorComplainList.retailer ? distributorComplainList.retailer.address : '',
        retailerAddress2: distributorComplainList.retailer ? distributorComplainList.retailer.address_2 : '',
        pincode: distributorComplainList.retailer ? distributorComplainList.retailer.pincode : '',
        state_id: distributorComplainList.retailer ? distributorComplainList.retailer.state_id : '',
        city_id: distributorComplainList.retailer ? distributorComplainList.retailer.city_id : '',
        mobile: distributorComplainList.retailer ? distributorComplainList.retailer.mobile : '',
        email: distributorComplainList.retailer ? distributorComplainList.retailer.email : '',
      });

      let i = this.state.productIndex;
      await this.props.getProductProblemList({ "category_id": distributorProductList[i].product_group_id });
      let problemList = this.props.problemData.problems;
      let product_problem_id = [];
      if (distributorProductList.length > 0) {
        for (let i = 0; i < distributorProductList.length; i++) {
          product_problem_id.push(distributorProductList[i].product_problem_id);
        }
      }
      this.setState({ problemList, product_problem_id });
    } else {
      alert("No products found!");
    }
    // this.getStateListApi();
    // this.getCityListApi(this.state.state_id);
  };

  onEditProduct(e) {
    let productIndex = document.getElementById("rtl_edit_product").selectedIndex;
    this.setState({ productIndex }, () => {
      this.getProblemList(this.state.distributorComplainList);
    });
    // let productDescription = this.state.productDescription;
    // productDescription[productIndex] = this.state.ticketProducts[productIndex].problem_description
    // this.setState({ productDescription })
  }

  onChangeDescription(i, e) {
    let productDescription = this.state.productDescription;
    productDescription[i] = e.target.value;
    this.setState({ productDescription });
  }

  onChangeDate(i, e) {
    let newDate = this.state.purchaseDate;
    newDate[i] = e.target.value;
    this.setState({ purchaseDate: newDate });
  }

  updateTicketValidation = () => {
    let errors = [];
    let flag = true;
    let minDate = new Date();
    minDate.setDate(minDate.getDate() - 90);
    let currentDate = new Date();
    if (this.state.purchaseDate.length > 0) {
      for (let i = 0; i < this.state.purchaseDate.length; i++) {
        let input = this.state.purchaseDate[i];
        let formatinput = new Date(input);
        let diff = (formatinput - minDate);
        diff = Math.floor(diff / (1000 * 60 * 60 * 24));
        if (diff <= 0) {
          errors["editPurchaseError" + i] = "Date can't be before 3 months from current date";
          flag = false;
        }
        let diff2 = (currentDate - formatinput);
        if (diff2 <= 0) {
          errors["editPurchaseError" + i] = "Purchase date can't be future date";
          flag = false;
        }
      }
    }
    if (this.state.retailerAddress === '') {
      errors["retailerAddress"] = "Address can't be empty.";
      flag = false;
    }
    if (this.state.retailerAddress2 === '') {
      errors["retailerAddress2"] = "Address can't be empty.";
      flag = false;
    }
    if (this.state.pincode === '') {
      errors["pincode"] = "Please enter pincode";
      flag = false;
    }
    if (this.state.pincode !== '') {
      var pattern = new RegExp(/^[1-9]\d{5}$/);
      if (!pattern.test(this.state.pincode)) {
        flag = false;
        errors['pincode'] = "Please Enter a Valid Pincode";
      }
    }

    if (this.state.state_id === 0) {
      errors["rtlState"] = "Please select city";
      flag = false;
    }
    if (this.state.city_id === 0) {
      errors["rtlCity"] = "Please select city";
      flag = false;
    }
    if (this.state.mobile === '') {
      flag = false;
      errors["mobile"] = "Please Enter Mobile";
    }
    if (this.state.mobile !== '') {
      var pattern = new RegExp(/^[6-9]\d{9}$/);
      if (!pattern.test(this.state.mobile)) {
        flag = false;
        errors["mobile"] = "Please Enter Valid Number";
      }
    }
    if (this.state.email === '') {
      flag = false;
      errors["email"] = "Please Enter E-Mail";
    }
    if (this.state.email !== '') {
      var pattern = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
      if (!pattern.test(this.state.email)) {
        flag = false;
        errors["email"] = "Please Enter Valid Email";
      }
    }
    this.setState({ errors });
    return flag;
  };

  onUpdateTicket = async () => {
    if (this.updateTicketValidation() !== false) {
      let data = {
        "complaint_id": this.state.complaintId,
        // product,
        "address": this.state.retailerAddress,
        "address2": this.state.retailerAddress2,
        "pincode": this.state.pincode,
        "state_id": this.state.state_id,
        "city_id": this.state.city_id,
        "mobile": this.state.mobile,
        "email": this.state.email,
      };
      await this.props.editDistributorComplaint(data);
      if (this.props.editComplaint.success) {
        alert(this.props.editComplaint.message);
        this.setState({ showEditTicket: false, productIndex: 0 });
        this.handleRefreshList();
      }
    }
  };

  onHandleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onStateChange = (e) => {
    this.setState({ country_id: 1, city_id: 0, state_id: Number(e.target.value) }, () => {
      // this.getStateListApi();
      this.getCityListApi();
    });
  };

  onIssueChange = (e, i) => {
    let product_problem_id = this.state.product_problem_id;
    product_problem_id[i] = e.target.value;
    this.setState({ product_problem_id });
  };

  getStateListApi = async (param) => {
    await this.props.getStateList(param);
    let stateDataList = this.props.stateDataList;
    this.setState({
      stateList: stateDataList.data,
    });
  };

  onCityChange = (e) => {
    this.setState({ city_id: e.target.value }, () => {
      // this.getStateListApi();
      this.getCityListApi();
    });
  };

  getCityListApi = async (param) => {
    let data = {
      "state_id": this.state.state_id
    };
    await this.props.getCityByState(data);
    this.setState({ cityList: [] });
    let cityDataList = this.props.cityDataList;
    this.setState({
      cityList: cityDataList.data,
    });
  };

  render() {
    let userRoleId = localStorage.getItem('userRoleId');
    if (userRoleId === '8' || userRoleId === '9' || userRoleId === '10') {
      return (<AccessRestricted />);
    }
    else {
      let productIndex = this.state.productIndex;
      let paginationCount = this.props.paginationCount;
      let new_page = ((paginationCount.no_page - (paginationCount.no_page - this.state.current_page)) - 1);
      return (
        <div>
      { window.innerWidth > 600 &&    <SidebarComponent sideProp={'RD'} />}
          <ViewProfile />
          <div className='content rtl-complaint'>
            <div>
              <span className="distributor-head">RTL Complaints</span>
              {
                this.state.teamName !== 'DI' &&
                <div className="search">
                  <input type="username" name="searchText" id="search_input" placeholder="Search by Ticket id, customer number..." onChange={this.handleChangeEvent} onKeyDown={this._handleKeyDown} />
                  {this.state.searchText &&
                    <button type="button" className="close-search" onClick={this.handleClose}><i className="fa fa-times" aria-hidden="true"></i></button>
                  }
                  <button type="button" className="add-user-btn-input" onClick={this.handleChangeButton}><i className="fa fa-search user_search_icon"></i></button>
                </div>
              }
              {
                !this.state.isSearch && !this.props.showComplaintDetails && 
                <div className='menu_wrapper'>
                  <ul id="menu">
                    <li className={this.state.teamName === 'CS' ? "selectedMenu" : null} onClick={(e) => this.onMenuClick(e, "CS")}>CS Team {this.state.teamName === 'CS' && paginationCount.total && !this.state.isLoading ? ' (' + paginationCount.total + ')' : ''} </li>
                    <li className={this.state.teamName === 'WH' ? "selectedMenu" : null} onClick={(e) => this.onMenuClick(e, "WH")}>Warehouse Team {this.state.teamName === 'WH' && paginationCount.total && !this.state.isLoading ? ' (' + paginationCount.total + ')' : ''}</li>
                    <li className={this.state.teamName === 'DI' ? "selectedMenu" : null} onClick={(e) => this.onMenuClick(e, "DI")}>Bulk Dispatch Inward  </li>
                  </ul>
                  {
                    this.state.teamName !== 'DI' &&
                    <div>
                      {
                        !this.state.assignedStatus &&
                        <div id="st-box">
                          <div className="">
                            <h6>Select Bucket</h6>
                            <select id="bucket" className="dropbtn" onChange={(e) => this.onStatusChange(e)}>
                              {this.props.bucketData.map((obj, index) => (
                                <option defaultValue={obj.id === this.props.statusId && 'selected'} value={obj.id} key={index}>{obj.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      }
                      {
                        <div id="rd-box">
                          <div className="">
                            <h6>Date - (DD/MM/YYYY)</h6>
                            <DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(new Date(new Date().setDate(new Date().getDate() - 120))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.dateSelect(value); }} value={[new Date(this.state.startDate), new Date(this.state.endDate)]} />
                          </div>
                        </div>
                      }
                      {
                        <div id="rd-box">
                          <div className="">
                            <h6>SLA Breached</h6>
                            <select className="dropbtn" onChange={(e) => this.onSLAChange(e)}>
                              {/* <i className="fa fa-angle-down" /> */}
                              <option value={0}> Select SLA Breached</option>
                              {this.state.SLAList.map((obj, index) => (
                                <option value={obj.id} key={index}>{obj.value}</option>
                              ))}
                            </select>
                          </div >
                        </div>
                      }
                    </div>
                  }
                </div>
              }
              {
                this.state.teamName === 'DI' ?
                  <div>
                    <div className='dispatch_inward rtl_dispatch_inward'>
                      <div className="ticketNumberInput">
                        <div className="ticket_head">Ticket No</div>
                        <textarea placeholder="Enter Ticket No" className="text-area-input" value={this.state.wayBillNumbers} onChange={this.handleChangeWayInput} />
                        <div className="error_add">
                          <span className="errorShow">{this.state.errors["wayBillNumbers"]}</span>
                        </div>
                      </div>
                      <div className='selectStatus'>
                        <div className="choose_type">Status</div>
                        <select onChange={this.handleChangeStatusDI} className="dropdown" value={this.state.status}>
                          <option value="">Select Status</option>
                          <option value="replace">Replace</option>
                          {/* <option value="working">Working</option>
                                                  <option value="repair">Repair</option>
                                                  <option value="upgrade">Upgrade</option>
                                                  <option value="wrong_product">Wrong Product</option>
                                                  <option value="physical_damage">Physical Damage</option> */}
                        </select>
                        <div className="error_add">
                          <span className="errorShow">{this.state.errors["status"]}</span>
                        </div>
                      </div>
                      {this.state.showProducts &&
                        <div className='showProducts'>
                          <div className="select_product">Select product</div>
                          <select onChange={this.handleChangeAccessory} className="dropdown" value={this.state.acc_replacement}>
                            <option value="0">Select replacement</option>
                            {/* <option value="Acc">Acc</option>
                            <option value="Charging case- B2B">Charging case- B2B</option>
                            <option value="Earbuds-B2B">Earbuds-B2B</option>
                            <option value="Charging case- New">Charging case- New</option>
                            <option value="Earbuds-New">Earbuds-New</option>
                            <option value="Repair">Repair</option>
                            <option value="RWR">RWR</option> */}
                            <option value="Same Unit">Same Unit</option>
                            <option value="Unit B2B">Unit B2B</option>
                            <option value="Unit New">Unit New</option>
                            {/* <option value="Dial-New">Dial-New</option>
                            <option value="Dial-B2B">Dial-B2B</option>
                            <option value="Unit Alternate Repack">Unit Alternate Repack</option>
                            <option value="Unit Alternate New">Unit Alternate New</option>
                            <option value="Unit Repack">Unit Repack</option> */}
                          </select>
                          <div className="error_add">
                            <span className="errorShow">{this.state.errors["accessory"]}</span>
                          </div>
                        </div>
                      }
                    </div>
                    {this.state.showProducts &&
                      <div className="row">
                        <div className='acc_selected'>
                          <div>
                            Note:
                          </div>
                          <textarea placeholder='Please add note' className="text-area-input" value={this.state.note} onChange={this.handleNote} />
                          {/* <textarea placeholder='Please add note' value={this.state.note} onChange={this.handleNote}>
                          </textarea> */}
                          <div className="error_add">
                            <span className="errorShow">{this.state.errors["note"]}</span>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="row">
                      <div className='only_movement_wrapper'>
                        <span className="input-wrap-bulk-main only-movement">
                          <input type="checkbox" id="select_all_movement" name="onlyMove" checked={this.state.onlyMovement} onChange={this.handleChangeOnlyMovement} className="input-wrap-bulk" />
                          <label className="select-all-label" htmlFor="select_all_movement">Only movement</label>
                        </span>
                      </div>
                      <div className="transfer">
                        <button type="button" onClick={this.handleTransferDispatchInward} className="transferButton">Submit</button>
                      </div>
                      {this.state.showSummary &&
                        <div className="ticket-summary" >
                          {
                            this.props.successListDI.length > 0 ? (
                              <div style={{ color: "green" }}>
                                <h4>Last Success Tickets</h4>
                                {this.props.successListDI.map((sd, index) => (
                                  <div style={{ "color": "white" }}>{sd}</div>
                                ))
                                }
                              </div>
                            ) : null
                          }
                          {
                            this.props.failureListDI.length > 0 ?
                              (
                                <div style={{ color: "red" }}>
                                  <h4 className='faliure_head'>Last Failure Tickets</h4>
                                  {this.props.failureListDI.map((sd, index) => (
                                    <div style={{ "color": "white" }}>{sd}</div>
                                  ))
                                  }
                                </div>
                              ) : null
                          }
                        </div>
                      }
                    </div>
                  </div>
                  :
                  <div>
                    {
                      this.state.isLoading ?
                        <div className='loader'>
                          <Loader
                            type="Oval"
                            color="Grey"
                            height={200}
                            width={200}
                          />
                        </div>
                        : <div>
                          {this.state.isSearch &&
                            <div className='search_title'>Search Results</div>
                          }
                          {
                            !this.props.showComplaintDetails ?
                              <div className="list-container">
                                {this.props.distributorData.length > 0 ?
                                  this.props.distributorData.map((item, index) => {
                                    return (
                                      <div className="container6" key={index}>
                                        {/* onClick={(e) => this.onItemPress(e, item)} */}
                                        <div className="box" >
                                          <div className="handclass" >
                                            <div className="handclassWrapper" onClick={(e) => this.onItemPress(e, item)}>
                                              <h6>{item.ticket_id}
                                                <button className="active">Active</button>
                                              </h6>
                                              <span style={{ cursor: 'pointer', class: 'name' }}>Total Products : </span>
                                              <span style={{ cursor: 'pointer', color: "#d2691e" }}>{item.productCount}</span>
                                              <p style={{ cursor: 'pointer' }}>{moment(item.created_at).format('MMMM DD, YYYY || hh:mm:ss A')}</p>
                                            </div>
                                          </div>
                                          <div className="box1" style={{ width: '30%' }} onClick={(e) => this.onItemPress(e, item)}>
                                            <span style={{ cursor: 'pointer' }}>Retailer Name : </span>
                                            <span style={{ cursor: 'pointer', color: "#d2691e" }}>{item.retailer ? item.retailer.first_name : null} {item.retailer ? item.retailer.last_name : null}</span>
                                            <h6>
                                              <span style={{ cursor: 'pointer', class: 'number' }}>Contact Number : </span>
                                              <span style={{ cursor: 'pointer', color: "#d2691e" }}>{item.retailer ? item.retailer.mobile : null}</span>
                                            </h6>
                                            <span style={{ cursor: 'pointer' }}>E-Mail : </span>
                                            <span style={{ cursor: 'pointer', color: "#d2691e" }}>{item.retailer ? item.retailer.email : null}</span >
                                          </div>
                                          <div className="box2" style={{ width: '21%' }} onClick={(e) => this.onItemPress(e, item)}>
                                            <h6 style={{ cursor: 'pointer' }}>{item.last_user_first_name}{item.last_user_last_name}</h6>
                                            <h6 style={{ cursor: 'pointer' }}>{this.state.isSearch || this.state.assignedStatus ? item.bucket_name : item.complaint_status_name}</h6>
                                            <p style={{ cursor: 'pointer' }}>{moment(item.updated_at).format('MMMM DD, YYYY || hh:mm:ss A')}</p>
                                          </div>
                                          <div className="box3" style={{ width: '21%' }}>
                                            {
                                              item.bucketId === 11 ?
                                                <div>
                                                  <div className='closedDuration'>
                                                    <p>Closed time : <span>{item.closeDuration}</span></p>
                                                  </div>
                                                  <div>
                                                    <i className="fas fa-phone" title="Call" onClick={(e) => this.onPhoneClick(e, item)}></i>
                                                  </div>
                                                </div>
                                                :
                                                <div>
                                                  <i className="fas fa-pencil-alt" title="Edit" onClick={(e) => this.onEditClick(e, item)}></i>
                                                  {
                                                    item.bucketId !== 2 ?
                                                      <i className="fas fa-phone" title="Call option" onClick={(e) => this.onPhoneClick(e, item)}></i>
                                                      : null
                                                  }
                                                  {/* {item.bucketId !== 17 ?
                                        <img src="/img/rpu-icon.svg" alt="" className="fas fa-star" title="Reverse Pickup" data-toggle="modal" data-target="#exampleModalabc" onClick={(e) => this.onRPUClick(e, item)} />
                                        :
                                        <i className="fa fa-arrow-right" title="Dispatch Outward" onClick={(e) => this.handleFPUOpenModal(e, item)}></i>
                                      } */}
                                                  {/* {
                                                    item.bucketId === 17 ?
                                                      <i className="fa fa-arrow-right" title="Dispatch Outward" onClick={(e) => this.handleFPUOpenModal(e, item)}></i>
                                                      : null
                                                  } */}
                                                  {
                                                    ![12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 33, 35, 41, 42, 45].includes(item.bucketId) ?

                                                      <img src="/img/Complaint-Closed.svg" alt="" className='fas fa-cross' title='close Complaint' onClick={(e) => this.onMoveToComplaintClosedClick(e, item)} /> : null
                                                    // <a className="dropdown-item" href="#" onClick={(e) => this.onMoveToComplaintClosedClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Complaint-Closed.svg" alt="" />Close Complaint</a>

                                                    // <img src="/img/rpu-icon.svg" alt="" className="fas fa-star" title="Reverse Pickup" data-toggle="modal" data-target="#exampleModalabc" onClick={(e) => this.onRPUClick(e, item)} /> : null
                                                  }
                                                </div>
                                            }
                                            {/* <i className="fas fa-star" title="Save Ticket" onClick={(e) => this.onSaveClick(e, item)}></i>
                                <img src="https://cdn.shopify.com/s/files/1/0997/6284/files/RPU_b70fcd2e-8194-4eb8-bc19-358949885b0c.svg?v=1637931896" alt="" className="fas fa-star" title="Reverse Pickup" data-toggle="modal" data-target="#exampleModalabc" onClick={(e) => this.onRPUClick(e, item)} />
                                <i className="fas fa-phone" title="Call option" onClick={(e) => this.onPhoneClick(e, item)}></i>
                                <i className="fas fa-arrow-circle-left" title="Cred" onClick={(e) => this.onCredClick(e, item)}></i> */}
                                            {Number(item.is_duplicate) === 1 ?
                                              <p>Duplicate</p>
                                              : null
                                            }
                                          </div>
                                          <CopyToClipboard text={item.ticket_id}
                                            onCopy={this.onCopy}>
                                            <div className='copy_icon'><i className="fa fa-copy"></i></div>
                                          </CopyToClipboard>
                                        </div>
                                      </div>
                                    );
                                  })
                                  :
                                  <div className="no-result-text">
                                    <p>No result found</p>
                                  </div>
                                }
                              </div> :
                              <div className='loader' style={{marginTop :  this.state.loader2 ? "7%" : "1%"}}>
                                {this.state.loader2 ?
                                  <Loader
                                    type="Oval"
                                    color="Grey"
                                    height={200}
                                    width={200}
                                  />
                                  :
                                  <RTLComplainComponentnew handleRefreshList={this.handleRefreshList} />
                                }
                              </div>
                          }
                        </div>
                    }
                  </div>
              }
              <ReactModal
                isOpen={this.state.showEditTicket}
                contentLabel="Minimal Modal Example"
                style={styleEdit}
                ariaHideApp={false}
                className="modal-header"
              >
                {this.state.ticketProducts.length > 0 ?
                  <div className='Et edit_rtl'>
                    <div className='ticket_wrapper'>
                      <span>Edit Ticket</span >
                      <span className='s'>{this.state.ticketId}</span>
                      {Number(this.state.isWarranty) === 1 ?
                        <button className="active">Active</button>
                        :
                        <button className="active">Expire</button>
                      }
                      <button id="close_rModal" onClick={this.handleCloseEditTicket} style={{ background: "none" }}><i className="fas fa-times"></i></button>
                    </div>
                    <div id="rtl-edit-box">
                      <div className=''>
                        <p>Product</p>
                        <select id="rtl_edit_product" className=" dropbtn1111" onChange={(e) => this.onEditProduct(e)}>
                          {/* <option value='0'>Smart Watch</option> */}
                          {
                            this.state.ticketProducts.map((obj, index) => (
                              <option value={obj.product_color_id} key={index}>{obj.product_name + " ( " + obj.color_name + " ) "}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                    <div className='rtl-kd-box'>
                      <div className="rtl_selection_wrapper">
                        <p className="cat">Category</p>
                        <input className="rtl-dropbtn" name="rtl_category" disabled value={this.state.ticketProducts[productIndex].group_name} type="text"></input>
                      </div>
                      <div className="rtl_selection_wrapper">
                        <p>Quantity</p>
                        <input className="rtl-dropbtn rtl_quantity" name="rtl_quantity" disabled value={this.state.ticketProducts[productIndex].quantity} type="text"></input>
                      </div>


                      <div className='issue_wrapper'>
                        <div className='rtl_selection_wrapper'>
                          <p>Purchase Date</p>
                          <input className="rtl-dropbtn" name="purchaseDate" type="date" disabled placeholder="Enter Purchase Date" value={this.state.purchaseDate[productIndex]} onChange={(e) => this.onChangeDate(productIndex, e)} />
                          <div className="error_add">
                            <span className="errorShow">{this.state.errors["editPurchaseError" + productIndex]}</span>
                          </div>
                        </div>
                        {<div className="rtl_selection_wrapper">
                          <p>Issues</p>
                          <select className="rtl-dropbtn" onChange={(e) => this.onIssueChange(e, productIndex)} disabled name="issue">
                            {this.state.problemList.map((obj) => (
                              <option value={obj.id} selected={Number(obj.id) === Number(this.state.product_problem_id[productIndex])}>{obj.problem}</option>
                            ))}
                          </select>
                        </div>}

                        {
                          // this.state.ticketProducts[productIndex].problem != "Issue not listed" &&
                          <div className="rtl_selection_wrapper ">
                            <p>Sub Problem</p>
                            <input disabled className='rtl-dropbtn' type='text' value={this.state.subProblem[productIndex]} />
                          </div>}
                      </div>


                      {
                        // this.state.ticketProducts[productIndex].problem == "Issue not listed" &&
                        <div className="rtl_selection_wrapper rtl_desc">
                          <p>Problem Description</p>
                          <input className='rtl-dropbtn' type='text' placeholder='Enter Problem Description' disabled value={this.state.productDescription[productIndex]} onChange={(e) => this.onChangeDescription(productIndex, e)} />
                        </div>}


                    </div>
                    <div className="lbl8  rtl_edit_adddress ">
                      <p> Phone </p>
                      <input type='text' placeholder='Enter Mobile Number ' name="mobile" value={this.state.mobile} onChange={(e) => this.onHandleChange(e)} />
                      <div className="error_add">
                        <span className="errorShow">{this.state.errors["mobile"]}</span>
                      </div>
                    </div>
                    <div className="lbl8">
                      <p> E-mail </p>
                      <input type='text' placeholder='Enter Email' name="email" value={this.state.email} onChange={(e) => this.onHandleChange(e)} />
                      <div className="error_add">
                        <span className="errorShow">{this.state.errors["email"]}</span>
                      </div>
                    </div>
                    <div className="lbl6 ">
                      <p> Address 1</p>
                      <input type='text' name="retailerAddress" placeholder='Enter First Address' value={this.state.retailerAddress} onChange={(e) => this.onHandleChange(e)} />
                      <div className="error_add">
                        <span className="errorShow">{this.state.errors["retailerAddress"]}</span>
                      </div>
                    </div>
                    <div className="lbl7">
                      <p> Address 2</p>
                      <input type='text' name="retailerAddress2" placeholder='Enter Second Address' value={this.state.retailerAddress2} onChange={(e) => this.onHandleChange(e)} />
                      <div className="error_add">
                        <span className="errorShow">{this.state.errors["retailerAddress2"]}</span>
                      </div>
                    </div>
                    <div className="lbl8">
                      <p> ZIP Code</p>
                      <input type='text' maxLength={6} placeholder='Enter ZIP Code' name="pincode" value={this.state.pincode} onChange={(e) => this.onHandleChange(e)} />
                      <div className="error_add">
                        <span className="errorShow">{this.state.errors["pincode"]}</span>
                      </div>
                    </div>
                    <div className="lbl9">
                      <p>Select State</p>
                      <select className="dropbtn1111" onChange={(e) => this.onStateChange(e)} name="state">
                        {this.state.stateList.map((obj) => (
                          <option value={obj.id} selected={Number(obj.id) === Number(this.state.state_id)}>{obj.state_name}</option>
                        ))}
                      </select>
                      <div className="error_add">
                        <span className="errorShow">{this.state.errors["rtlState"]}</span>
                      </div>
                    </div>
                    <div className="lbl9">
                      <p>City</p>
                      <select className="dropbtn1111" id="select_city" onChange={(e) => this.onCityChange(e)}>
                        <option value={'0'}> select city</option>
                        {this.state.cityList?.map((obj) => (
                          <option value={obj.id} selected={Number(obj.id) === Number(this.state.city_id)}>{obj.city}</option>
                        ))}
                      </select>
                      <div className="error_add">
                        <span className="errorShow">{this.state.errors["rtlCity"]}</span>
                      </div>
                    </div>
                    <div className="lbl13 update_ticket">
                      <button onClick={this.onUpdateTicket}>Update Ticket</button>
                    </div>
                  </div>
                  :
                  <Loader
                    type="Oval"
                    color="Grey"
                    height={200}
                    width={200}
                    className="loaderBeforeFetchTicket"
                  />
                }

              </ReactModal>
              {this.state.teamName !== 'DI' &&
                <Footer new_page={new_page} onPrevPress={this.onPrevPress} onNextPress={this.onNextPress} current_page={this.state.current_page} cc={"kk"} />
              }
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  distributorData: state.distributor.distributorData,
  statusId: state.distributor.statusId,
  complaintsDetail: state.distributor.complaintsDetail,
  showComplaintDetails: state.distributor.showComplaintDetails,
  bucketData: state.distributor.bucketData,
  distributorComplainDetails: state.distributor.distributorComplainDetails,
  paginationCount: state.distributor.paginationCount,
  saveticket: state.distributor.saveticket,
  editComplaint: state.distributor.editComplaint,
  callServiceCenter: state.distributor.callServiceCenter,
  distributorDataCount: state.distributor.distributorDataCount,
  successListDI: state.bulkmovement.successListDI,
  failureListDI: state.bulkmovement.failureListDI,
  bulkConstant: state.bulkmovement.bulkConstant,
  stateDataList: state.complaints.stateData,
  cityDataList: state.complaints.cityData,
  problemData: state.complaints.problemData,
  complaintProductList: state.distributor.complaintProductList,
  allProductsClubbed: state.distributor.allProductsClubbed,
  // showDistributorComplaintDetails:state.showDistributorComplaintDetails.showDistributorComplaintDetails,
  // distributorstatusData:state.distributorstatusData.statusData,
  // distributorcomplaintsDetail:state.distributorcomplaintsDetail.distributorcomplaintsDetail
});

export default connect(mapStateToProps, {
  getDistributorData, addStatusId, showComplaintDetailsAction, getComplaintBuckets,
  getDistributorComplaintDetail, onSaveTicket, callCustomer, getRPUCourier, onSelfShip, clearDIDataResults,
  submitDispatchInwardRetailer, onCloseTicket, getStateList, getCityByState, getProductProblemList, editDistributorComplaint
})(RTLComplaintsComponent);