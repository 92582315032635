import * as React from "react";
import { connect } from "react-redux";
import './ComponentToRPUPrint.css';
import moment from 'moment'
import ReactToPrint from "react-to-print";
import AccessRestricted from '../AccessRestricted'

var Barcode = require('react-barcode');

class ComponentToRPUPrint extends React.PureComponent {
    componentRef = null;
    // constructor() {
    //     super();
    // }
    render() {
        let userRoleId = localStorage.getItem('userRoleId');        
        if (userRoleId === '6') {
            return (<AccessRestricted />)
        }
        else {
            return (
                <div>
                    {this.props.printLabel && this.props.printLabel.tickets[0] ? (
                        <div>
                            <div className='rpu-print-cont' ref={el => (this.componentRef = el)}>
                                <style type="text/css" media="print">
                                    {"\.flex-table{padding-left:1rem !important;}.rpu-print-cont td,.rpu-print-cont p{font-size: 2.5rem !important;font-weight: 800 !important;}.description, .from, .deliver_to{flex: 5 !important;}.flex-table svg{transform: scale(1.5) translate(44px, 20px) !important;margin-bottom: 6%!important;}\
                                "
                                    }
                                </style>
                                <table className="flex-table">
                                    <tbody>
                                        <tr className='svg'>
                                            <td data-label="Number">
                                                <Barcode value={
                                                    this.props.printLabel && this.props.printLabel.tickets[0] && this.props.printLabel.tickets[0].courier
                                                        ? this.props.printLabel.tickets[0].courier.reverse_waybill_number : null
                                                }
                                                    displayValue={false}
                                                />
                                            </td>
                                        </tr>
                                        <tr className="tr1">
                                            <td>
                                                <p>{
                                                    this.props.printLabel && this.props.printLabel.tickets[0] && this.props.printLabel.tickets[0].courier
                                                        ? this.props.printLabel.tickets[0].courier.reverse_waybill_number : null
                                                } - {
                                                        this.props.printLabel && this.props.printLabel.tickets[0] && this.props.printLabel.tickets[0].courier
                                                            ? this.props.printLabel.tickets[0].courier.courier_name : null
                                                    }</p>
                                            </td>
                                            <td data-label="Name">
                                                <div>Act wt - {
                                                    this.props.printLabel && this.props.printLabel.tickets[0] && this.props.printLabel.tickets[0].courier ?
                                                        this.props.printLabel.tickets[0].courier.actual_weight : null
                                                }</div>
                                            </td>
                                            <td data-label="Name">
                                                <div>Dim (cm) - </div>
                                            </td>
                                        </tr>
                                        {this.props.printLabel && this.props.printLabel.destinationCode !== 0  ? (
                                            <tr>
                                                <td>
                                                    <div>Destination Code: {this.props.printLabel && this.props.printLabel.tickets[0] ? this.props.printLabel.tickets[0].courier.reverse_dest_code : null}</div>
                                                </td>
                                            </tr>
                                        ) : null}
                                        <tr>
                                            <td className="deliver_to" data-label="Number">
                                                <b>Deliver To:</b>
                                                <br></br>
                                                {
                                                    this.props.printLabel.toAddress
                                                }
                                            </td>
                                            <td data-label="Name"></td>
                                        </tr>
                                        <tr>
                                            <td data-label="Number">Order No: {this.props.printLabel.tickets[0].order_id}</td>
                                            <td data-label="Name"></td>
                                        </tr>
                                        <tr>
                                            <td data-label="Number">Date : {moment(this.props.printLabel.tickets[0].reverse_pickup_date).format("DD/MM/YYYY HH:mm:ss")}</td>
                                            <td data-label="Name"></td>
                                        </tr>
                                        <tr>
                                            <td className="from" data-label="Number">From : {this.props.printLabel.fromAddress}</td>
                                            <td data-label="Name"></td>
                                        </tr>
                                        <tr>
                                            <td className="description" data-label="Number">Description : Product Count ({this.props.printLabel.tickets[0].product.length})</td>
                                            <td data-label="Name"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <ReactToPrint
                                content={() => this.componentRef}
                                removeAfterPrint
                                trigger={() => <div className='rpu_print_cta'><button type="button" id="send_rModal">Print</button></div>}

                            />
                        </div>
                    )
                        :
                        <h2 id="rModal_head" style={{ color: "#fff" }}>No reverse pick up found</h2>
                    }

                </div>
            );
        }
    }
}

const mapStateToProps = state => ({
    printLabel: state.distributor.printLabel,
})

export default connect(mapStateToProps, {})(ComponentToRPUPrint);
