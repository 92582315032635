import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import { cosmosAction, fetchTrackStatus, setACtionTrue, saveMACID, getComplaintDetail, moveToDispatchInward } from '../../../actions/complaintsDataAction';
import ReactModal from 'react-modal';
import ConversationExpand from './ConversationExpand';

//let tBktIds = [5, 6, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 33, 35, 41, 42, 45]

const customStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    inset: '8% 0 0 auto',
    marginRight: '0',
    transform: 'translate(0)',
    background: '#000',
    padding: '2rem',
    border: 'none',
    width: '35%',
    color: "#999999",
    marginTop: "2%"
  },
};

const customStylesTicketStatus = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    inset: '8% 0 0 auto',
    marginRight: '0',
    transform: 'translate(0)',
    background: '#000',
    padding: '2rem',
    border: 'none',
    width: '45%',
    color: "#999999",
    marginTop: "2%"
  },
};

class ProductDetails extends Component {

  constructor() {
    super();
    this.state = {
      showTrackStatusModal: false,
      showLiveStatusModal: false,
      showReverseTrackStatusModal: false,
      showReverseLiveStatusModal: false,
      formattedMacText: "",
      isEdit: false,
      showMacID: false

    };
    this.handleForwardTrackStatusOpenModal = this.handleForwardTrackStatusOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleForwardLiveStatusOpenModal = this.handleForwardLiveStatusOpenModal.bind(this);
    // this.handleForwardLiveStatusCloseModal = this.handleForwardLiveStatusCloseModal.bind(this);
    this.handleReverseTrackStatusOpenModal = this.handleReverseTrackStatusOpenModal.bind(this);
    // this.handleReverseTrackStatusCloseModal = this.handleReverseTrackStatusCloseModal.bind(this);
    this.handleReverseLiveStatusOpenModal = this.handleReverseLiveStatusOpenModal.bind(this);
    // this.handleReverseLiveStatusCloseModal = this.handleReverseLiveStatusCloseModal.bind(this);
  }

  async handleReverseTrackStatusOpenModal() {
    let data = {
      "waybill": this.props.complaintsDetail.reverse_waybill_number,
      // "waybill" : "69630848534",
      "courier_id": this.props.complaintsDetail.reverse_courier_id,
      // "courier_id" : "4",
      "tracking": this.props.complaintsDetail.reverse_tracking,
    };
    await this.props.fetchTrackStatus(data);
    this.setState({
      showReverseTrackStatusModal: true
    });
  }

  async handleReverseLiveStatusOpenModal() {
    let data = {
      "waybill": this.props.complaintsDetail.reverse_waybill_number,
      // "waybill" : "69630848534",
      "courier_id": this.props.complaintsDetail.reverse_courier_id,
      // "courier_id" : "4",
      "tracking": this.props.complaintsDetail.reverse_tracking,
    };
    await this.props.fetchTrackStatus(data);
    this.setState({
      showReverseLiveStatusModal: true
    });
  }

  handleCloseModal() {
    this.setState({
      showLiveStatusModal: false,
      showTrackStatusModal: false,
      showReverseTrackStatusModal: false,
      showReverseLiveStatusModal: false
    });
  }

  componentDidMount() {
    let createdAt = new Date(this.props.complaintsDetail?.created_at)
    let new_date = new Date("2023-10-25")
    let dateCheck = createdAt > new_date

    if (dateCheck) {
      this.setState({ showMacID: true })
    } else {
      this.setState({ showMacID: false })
    }
    this.setState({ formattedMacText: this.props.complaintsDetail?.new_mac_id })
    if (this.props.complaintsDetail?.new_mac_id) {
      this.setState({ isEdit: true })
    }
    if(this.props.complaintsDetail?.current_suffix != "SC"){
      if ((this.props.complaintsDetail?.current_prefix == "OOS" || this.props.complaintsDetail?.current_prefix == "QcFailOrder" || this.props.complaintsDetail?.current_suffix == "SC" ) && this.props.statusId != "51") {
        this.props.setACtionTrue(true)
      } else {
        if (this.props.complaintsDetail?.status_name == "testing_inward" && !(this.props.complaintsDetail?.old_mac_verification == "TRUE") && (this.props.complaintsDetail?.product?.product_group_id == 2 && dateCheck) && !(this.props.complaintsDetail?.old_mac_id == "NON-NFA" || this.props.complaintsDetail?.old_mac_id == "DOA"  )) {
          this.props.setACtionTrue(true)
        } else {
          if ((this.props.complaintsDetail?.old_mac_verification == "TRUE")) {
            this.props.setACtionTrue(false)
          } else {
            if ((this.props.complaintsDetail?.product?.product_group_id == 2 && dateCheck) && (this.props.complaintsDetail?.old_mac_id)) {
              if (this.props.statusId == 15) {
                if (this.props.complaintsDetail?.new_mac_id) {
                  this.props.setACtionTrue(false)
                } else if (!(this.props.complaintsDetail?.old_mac_id == "NON-NFA" || this.props.complaintsDetail?.old_mac_id == "DOA")) {
                  this.props.setACtionTrue(true)
                } else {
                  this.props.setACtionTrue(false)
                }
              } else {
                this.props.setACtionTrue(false)
              }
            }
            else {
              this.props.setACtionTrue(false)
            }
          }
        }
      }
    }else{
      if(this.props.complaintsDetail?.current_prefix == "OOS" || this.props.complaintsDetail?.current_prefix == "QcFailOrder" ){
        this.props.setACtionTrue(true)
      }else{
        this.props.setACtionTrue(false)

      }
    }


  }

  async handleForwardTrackStatusOpenModal() {
    let data = {
      "waybill": this.props.complaintsDetail.forward_waybill_number,
      // "waybill" : "69630848534",
      "courier_id": this.props.complaintsDetail.forward_courier_id,
      // "courier_id" : "4",
      "tracking": this.props.complaintsDetail.forward_tracking,
    };
    await this.props.fetchTrackStatus(data);
    this.setState({
      showTrackStatusModal: true
    });
  }

  async handleForwardLiveStatusOpenModal() {
    let data = {
      "waybill": this.props.complaintsDetail.forward_waybill_number,
      // "waybill" : "69630848534",
      "courier_id": this.props.complaintsDetail.forward_courier_id,
      // "courier_id" : "4",
      "tracking": this.props.complaintsDetail.forward_tracking,
    };
    await this.props.fetchTrackStatus(data);
    this.setState({
      showLiveStatusModal: true
    });
  }

  // handleForwardTrackStatusCloseModal(){
  //   this.setState({
  //     showTrackStatusModal : false
  //   })
  // }

  handleInputChange = async (event) => {
    const input = event.target.value;
    // Remove any non-alphanumeric characters and limit to 12 characters
    const sanitizedInput = input.replace(/[^a-zA-Z0-9]/g, '').slice(0, 12);
    // Format the MAC address with colons
    const formattedMac = sanitizedInput
      .match(/.{1,2}/g)
      ?.join(':')
      .toUpperCase() || '';
    this.setState({ formattedMacText: formattedMac });
  };

  handleMacID = async (value) => {
    if (value == "save") {
      if (this.state.formattedMacText.length < 17) {
        alert("Entered MAC ID is Incorrect")
      } else {
        let data = {
          "complaint_id": this.props.complaintsDetail?.id,
          "new_mac_id": this.state.formattedMacText
        }
        await this.props.saveMACID(data)
        if (this.props.macSaved.success) {
          this.props.handleProdutcRefreshList()
          this.props.setACtionTrue(true)
          this.setState({ isEdit: true })
        } else {
          this.props.setACtionTrue(false)
        }

      }
    } else {
      this.setState({ isEdit: false })
    }
  }

  handleCopyMac = () => {
    navigator.clipboard.writeText(this.props.complaintsDetail?.old_mac_id);
    alert("MAC ID Copy")
  }

  handleVerifyMacID = async (e) => {
    if (e.target.checked) {
      let data = {
        "complaint_id": this.props.complaintsDetail?.id,
        "is_verified": true
      }
      await this.props.saveMACID(data)
      this.props.handleProdutcRefreshList()
    }
  }

  handleDispatchInward = async () => {
    let data = {
      "complaint_id": this.props.complaintsDetail?.id,
    };
    await this.props.moveToDispatchInward(data);
    this.props.handleRefreshList()
  }

  render() {
    let item = this.props.complaintsDetail;
    return (
      <div className="product_details1" style={{ heigth: "330px" }}>
        <div className="first">
          <div className="firstbox">
            <span>Product</span>
            <p>{item.product.product_name} ({item.product.size && "Size " + item.product.size + " | "} {item.product.color_name})</p>
          </div>
          <div className="thirdbox">
            <span>Reverse Pick-Up Cost</span>
            {/* TODO...  Not in api*/}
            <p>{item.reverse_courier_cost || 'N/A'}</p>
          </div>
          <div className="fourthbox">
            <span>Product Cost</span>
            {/* TODO...  check in api*/}
            <p>{item.product.product_price || 'N/A'}</p>
          </div>
          {item.upgradeProduct &&
            <div className="fourthbox">
              <span>Upgrade to</span>
              {/* TODO...  Not in api*/}
              <p>{item.upgradeProduct.product_name} {item.upgradeProduct.color_name}</p>
            </div>
          }
          {
            // tBktIds.includes(this.props.statusId) ?
            item.forward_waybill_number !== "0" && item.forward_waybill_number !== null ?
              <div className="secondbox">
                <span>Forward Waybill Number</span>
                <p>{item.forward_waybill_number || 'N/A'}</p>
                {
                  item.forward_waybill_ynumber ? <div>
                    <a className='ticket-status' onClick={this.handleForwardTrackStatusOpenModal}>Tracking Status</a>
                    <br></br>
                    <a href="javascript:void(0)" className='ticket-status' onClick={this.handleForwardLiveStatusOpenModal}>Live Status</a>
                  </div> : null
                }
              </div> : null
          }

          {(!(item.old_mac_id == "null" || item.old_mac_id == null) && (item.product?.product_group_id == 2 && this.state.showMacID &&  item?.current_suffix != "SC"))
            &&
            <div className="fourthbox">
              <span>Received MAC ID</span>
              {item.old_mac_id == ("DOA" || "NON-NFA")   ?
                <p style={{ color: "#eb951f" }}>{item.old_mac_id != "null" ? item.old_mac_id : 'N/A'}</p>
                :
                <p style={{ color: "#eb951f" }}>{item.old_mac_id != "null" ? item.old_mac_id : 'N/A'}<i className="fa fa-copy" onClick={this.handleCopyMac}></i></p>}
            </div>}
          {
            // tBktIds.includes(this.props.statusId) ?
            item.reverse_waybill_number !== "0" && item.reverse_waybill_number !== null ?
              <div className="secondbox">
                <span>Reverse Waybill Number</span>
                <p>{item.reverse_waybill_number || 'N/A'}</p>
                {
                  item.reverse_waybill_number ? <div>
                    <a className='ticket-status' onClick={this.handleReverseTrackStatusOpenModal}>Tracking Status</a>
                    <br></br>
                    <a  className='ticket-status' onClick={this.handleReverseLiveStatusOpenModal}>Live Status</a>
                  </div>
                    : null
                }
              </div> : null
          }
        </div>
        <div className="second">
          {
            item.newProductName &&
            <div className="firstbox">
              <span>New Product</span>
              <p>{item.newProductName.product_name} ({item.newProductName.color_name})</p>
            </div>
          }
          {
            item.new_product_sku &&
            <div className="firstbox">
              <span>New Product SKU</span>
              <p>{item.new_product_sku}</p>
            </div>
          }
          <div className="secondbox">
            <span>Purchase Date</span>
            <p>{item.purchase_date ? moment(item.purchase_date).format('MMMM DD, YYYY') : 'N/A'}</p>
          </div>
          <div className="thirdbox">
            <span>Forward Pick-Up Cost</span>
            {/* TODO...  Not in api*/}
            <p>{item.forward_courier_cost || 'N/A'}</p>
          </div>
          <div className="fourthbox">
            <span>Order ID</span>
            <p>{item.customer_order_id || 'N/A'}</p>
          </div>
          {(this.state.showMacID && item.product?.product_group_id == 2) && (item.old_mac_verification == "TRUE" || item.old_mac_id == "DOA") &&
            <div className="fourthbox">
              {!((this.props.statusId == 15)) && this.state.formattedMacText &&
                <div>
                  <span>Replacement MAC ID</span>
                  <p style={{ color: "yellow" }}>{this.state.formattedMacText}</p>
                </div>
              }
              {((this.props.statusId == 15) && !(item.old_mac_id == "NON-NFA")) &&
                <div>
                  <span>{this.state.isEdit ? "Replaced MAC ID" : "Enter Replacement MAC ID"}</span>
                  <div className="macID">
                    <input maxLength={17} onChange={(e) => this.handleInputChange(e)} value={this.state.formattedMacText} disabled={this.state.isEdit} placeholder='Enter MAC ID'></input>
                    {!this.state.isEdit ?
                      <button title='Save' onClick={(() => this.handleMacID("save"))}>Save</button>
                      :
                      <button title='Edit' onClick={(() => this.handleMacID("edit"))}><i className="fas fa-pencil-alt" style={{ "margin-left": "0px" }} title="Edit"></i></button>
                    }
                  </div>
                </div>}
            </div>
          }
          {this.state.showMacID && this.props.statusId == 15 && this.props.complaintsDetail?.product?.product_group_id == 2 && this.props.complaintsDetail?.old_mac_verification == "FALSE" &&  !(item.old_mac_id == "NON-NFA" || item.old_mac_id == "DOA") &&
            <div className="fourthbox">
              <span>Verify MAC ID </span>
              <p style={{ display: 'flex', alignItems: "center", gap: "10px", color: "#eb951f" }}><input className='macIDCheckbox' type="checkbox" onChange={((e) => this.handleVerifyMacID(e))}></input> Verify</p>

            </div>
          }

        </div>
        <div className="third">
          <div className="firstbox">
            <span>Operating System</span>
            {/* TODO...  Not in api*/}
            <p>{item.operating_software || 'N/A'}</p>
          </div>
          {
            item.watch_firmware_version == null ? null :
              <div className="secondbox">
                <span>Watch FirmWare Version</span>
                <p>{item.watch_firmware_version}</p>
              </div>
          }

          {
            item.app_version == null ? null :
              <div className="thirdbox">
                <span>App Version </span>
                <p>{item.app_version}</p>
              </div>
          }
          {
            (item.old_mac_id != ("null" || null)) && this.state.showMacID && this.props.statusId == 15 && this.props.complaintsDetail?.product?.product_group_id == 2 && this.props.complaintsDetail?.old_mac_verification == "FALSE" && !(item.old_mac_id == "NON-NFA" || item.old_mac_id == "DOA" ||  item?.current_suffix == "SC") &&
            <div className="fourthbox">
              <span>Unverify MAC ID </span>
              <p style={{ display: 'flex', alignItems: "center", gap: "10px", color: "#eb951f" }}><input className='macIDCheckbox' type="checkbox" onChange={((e) => this.handleDispatchInward(e))}></input>Unverify</p>

            </div>
          }
          {
            item.device == null ? null :
              <div className="fourthbox">
                <span>Device </span>
                <p>{item.device}</p>
              </div>
          }
          {
            this.props.statusId === 15 || this.props.statusId === 16 || this.props.statusId === 17 || this.props.statusId === 18 ?
              <div className="thirdbox">
                <span>Testing Status</span>
                <p>{item.testing_status || 'N/A'}</p>
              </div> : null
          }
          <div className="secondbox">
            <span>SLA Breached</span>
            {/* TODO...  only id in api*/}
            <p>{item.sla_breached ? "Yes" : "No"}</p>
          </div>
          <div className="sixthbox">
            <span>Serial Number</span>

            <p>{item.serial_number}</p>
          </div>
        </div>
        <ReactModal
          isOpen={this.state.showTrackStatusModal}
          contentLabel="Minimal Modal Example"
          style={customStylesTicketStatus}
          ariaHideApp={false}
        >
          <h2 id="rModal_head" className='liveStatusHead'>Forward Waybill Ticket Status</h2>
          <button id="close_rModal" onClick={this.handleCloseModal}><i className="fas fa-times"></i></button>
          {
            this.props.trackStatusData.scans ? this.props.trackStatusData.scans.map((sd, index) => (
              <div key={index} className='liveStatus'>
                {sd.timestamp} - {sd.remark}
              </div>
            )) : <React.Fragment>
              {
                this.props.trackStatusData.shipment_track_activities ? this.props.trackStatusData.shipment_track_activities.map((sd, index) => (
                  <div key={index} className='liveStatus'>
                    {sd.date} - {sd.activity}
                  </div>
                )) : null
              }
            </React.Fragment>
          }
        </ReactModal>
        <ReactModal
          isOpen={this.state.showLiveStatusModal}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          ariaHideApp={false}
        >
          <h2 id="rModal_head" className='liveStatusHead'>Live Status</h2>
          <button id="close_rModal" onClick={this.handleCloseModal}><i className="fas fa-times"></i></button>
          <div className='liveStatus'>AWB Number
            <p>{this.props.complaintsDetail.forward_waybill_number || null}</p></div>
          <div className='liveStatus'>Courier
            <p>{this.props.trackStatusData.additional ? this.props.trackStatusData.additional.courier_partner_name : (this.props.trackStatusData.shipment_track ? this.props.trackStatusData.shipment_track[0].courier_partner_name : null)}</p>
          </div>
          <div className='liveStatus'>Status
            <p>{this.props.trackStatusData.latest_status ? this.props.trackStatusData.latest_status.remark : (this.props.trackStatusData.shipment_track ? this.props.trackStatusData.shipment_track[0].current_status : null)}</p>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={this.state.showReverseTrackStatusModal}
          contentLabel="Minimal Modal Example"
          style={customStylesTicketStatus}
          ariaHideApp={false}
        >
          <h2 id="rModal_head" className='liveStatusHead'>Reverse Waybill Ticket Status</h2>
          <button id="close_rModal" onClick={this.handleCloseModal}><i className="fas fa-times"></i></button>
          {
            this.props.trackStatusData.scans ? this.props.trackStatusData.scans.map((sd, index) => (
              <div key={index} className='liveStatus'>
                {sd.timestamp} - {sd.remark}
              </div>
            )) : <React.Fragment>
              {
                this.props.trackStatusData.shipment_track_activities ? this.props.trackStatusData.shipment_track_activities.map((sd, index) => (
                  <div key={index} className='liveStatus'>
                    {sd.date} - {sd.activity}
                  </div>
                )) : null
              }
            </React.Fragment>
          }
        </ReactModal>
        <ReactModal
          isOpen={this.state.showReverseLiveStatusModal}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          ariaHideApp={false}
        >
          <h2 id="rModal_head" className='liveStatusHead'>Live Status</h2>
          <button id="close_rModal" onClick={this.handleCloseModal}><i className="fas fa-times"></i></button>
          <div className='liveStatus'>AWB Number
            <p>{this.props.complaintsDetail.reverse_waybill_number || null}</p></div>
          <div className='liveStatus'>Courier
            <p>{this.props.trackStatusData.additional ? this.props.trackStatusData.additional.courier_partner_name : (this.props.trackStatusData.shipment_track ? this.props.trackStatusData.shipment_track[0].courier_partner_name : null)}</p>
          </div>
          <div className='liveStatus'>Status
            <p>{this.props.trackStatusData.latest_status ? this.props.trackStatusData.latest_status.remark : (this.props.trackStatusData.shipment_track ? this.props.trackStatusData.shipment_track[0].current_status : null)}</p>
          </div>
        </ReactModal>
      </div >
    );
  }
}
const mapStateToProps = state => ({
  complaintsDetail: state.complaints.complaintsDetail,
  statusId: state.complaints.statusId,
  trackStatusData: state.complaints.trackStatusData,
  macSaved: state.complaints.macSaved

});
export default connect(mapStateToProps, { fetchTrackStatus, setACtionTrue, saveMACID, getComplaintDetail, moveToDispatchInward })(ProductDetails);