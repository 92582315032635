import React, { Component } from "react";
import "./ProductComponent.css";
import { connect } from "react-redux";
// import moment from 'moment'
// import Loader from 'react-loader-spinner'
import { getProductColorData,addNewColor } from "../../actions/productActions";
import ViewProfile from "../ViewProfile";
import ComplaintRegistration from "../ComplaintRegistration";
import AccessRestricted from "../AccessRestricted";
import SidebarComponent from "../SidebarComponent/SidebarComponent";
import ReactModal from "react-modal";
const customStylesDO = {
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    inset: "8% 0 0 auto",
    marginRight: "0",
    transform: "translate(0)",
    background: "#000",
    padding: "2rem",
    border: "none",
    width: "35%",
    position: "absolute",
  },
};

class ColorComponent extends Component {
  constructor() {
    super();

    this.state = {
      searchText: "",
      productColorList: [],
      showcolorModal: false,
      colorName: "",
      colorDesctiption: "",
      errors:[]
    };
  }

  componentDidMount() {
    this.getProductColor();
  }
  getProductColor = async () => {
    let data = {
      offset: 0,
      limit: 1000,
    };
    await this.props.getProductColorData(data);
    let colorData = this.props.clolorList;
    this.setState({
      productColorList: colorData.data ? colorData.data.colors : "",
    });
  };

  //this.setState({ [e.target.name]: e.target.value })

  handleSearch = (e) => {
    let searchTerm = e.target.value;
    let searchedArray = this.props.clolorList?.data?.colors.filter((item) => {
      console.log(item, "..");
      return item.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
    if (e.target.value.length > 0) {
      this.setState({ productColorList: searchedArray });
    } else {
      this.setState({ productColorList: this.props.clolorList?.data?.colors });
    }
  };

  handleValidationaddColor() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.colorName) {
      formIsValid = false;
      errors["error_color"] = "Color can't be empty";
    }

    if (!this.state.colorDesctiption) {
      formIsValid = false;
      errors["error_description"] = " Description Can't be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleColorAdd = async (a) => {
    let validation = this.handleValidationaddColor();
    if (validation) {
      let data = {
        color_name: this.state.colorName,
        description: this.state.colorDesctiption,
      };
      this.setState({ isLoading: true, showcolorModal: false });
      let response = await this.props.addNewColor(data);
      if (response.success) {
        this.getProductColor()
        this.setState({ isLoading: false });
        alert(response.message);
      }
    }
  };

  render() {
    let userRoleId = localStorage.getItem("userRoleId");
    if (
      userRoleId === "4" ||
      userRoleId === "8" ||
      userRoleId === "9" ||
      userRoleId === "10"
    ) {
      return <AccessRestricted />;
    } else if (userRoleId === "6") {
      return <ComplaintRegistration />;
    } else {
      return (
        <div className="product-group group-component-main">
          <ViewProfile />
          {window.innerWidth > 600 && <SidebarComponent sideProp={"MP"} />}
          <div className="content">
            <div className="search_header_product">
              <span>Product</span>
              <div className="search" style={{ marginRight: "unset" }}>
                <i className="fa fa-search"></i>
                <input
                  type="username"
                  style={{ width: "230px" }}
                  name="searchByusername"
                  placeholder=" Search By color name"
                  onChange={this.handleSearch}
                />
              </div>
            </div>

            <ul id="menu">
              <li>
                <a href="/manage-product" className="noactive">
                  Product Info
                </a>
              </li>
              <li>
                <a href="/manage-group" className="noactive">
                  Product Group
                </a>
              </li>
              <li>
                <a href="/manage-message" className="noactive">
                  Message
                </a>
              </li>
              <li className="actice2">
                <a href="/manage-message" className="noactive">
                  Colors
                </a>
              </li>
            </ul>
            <div className="add-color-product">
              <button
                onClick={() =>
                  this.setState({
                    showcolorModal: true,
                    colorName: "",
                    colorDesctiption: "",
                  })
                }
                className="add-message-btn add-color">
                Add Color
              </button>
            </div>
          </div>

          <div className="colrow grp">
            <div className="row customrow" style={{ marginTop: "0px" }}>
              <div className="col-sm" style={{ color: "#eb951f" }}>
                Color Name
              </div>
            </div>

            {this.state.productColorList.length > 0 ? (
              this.state.productColorList.map((item) => {
                return (
                  <div key={item.id} className="row customrow">
                    <div className="col">
                      <p>{item.name ? item.name : "NA"}</p>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="no-result-text">
                <p>No result found</p>
              </div>
            )}
          </div>
          <ReactModal
            isOpen={this.state.showcolorModal}
            contentLabel="Minimal Modal Example"
            style={customStylesDO}
            ariaHideApp={false}>
            <div className="modal-head-product">
              <h2 id="rModal_head">Add Color</h2>
              <i
                className="fa fa-times"
                aria-hidden="true"
                onClick={() => this.setState({ showcolorModal: false })}></i>
            </div>

            <div className="add-product-color">
              <div className="form-group add-color">
                <label>Color Name</label>
                <input
                  type="text"
                  value={this.state.colorName}
                  className="form-control inputclass"
                  name="subject"
                  onChange={(e) => this.setState({ colorName: e.target.value })}
                  placeholder="Please enter color name"
                  required
                />
                <div className="errorpd">
                  <span className="errorShow">
                    {this.state.errors["error_color"]}
                  </span>
                </div>
              </div>
              <div className="form-group">
                <label>Description</label>
                <input
                  type="text"
                  value={this.state.colorDesctiption}
                  className="form-control inputclass"
                  name="body"
                  onChange={(e) =>
                    this.setState({
                      colorDesctiption: e.target.value,
                    })
                  }
                  placeholder="Please enter description"
                  required
                />
                <div className="errorpd">
                  <span className="errorShow">
                    {this.state.errors["error_description"]}
                  </span>
                </div>
              </div>
              <div className="addbtn">
                <button onClick={this.handleColorAdd}>Add Color</button>
              </div>
            </div>
          </ReactModal>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  clolorList: state.product.clolorList,
});

export default connect(mapStateToProps, {
  getProductColorData,
  addNewColor
})(ColorComponent);
