import axios from "axios";
import { baseUrl, oldUrl, webBaseUrl } from "../config";
import history from "../history";
import {
    FETCH_SERVICE_DATA,
    UPDATE_SERVICE_DATA,
    REMOVE_SERVICE_CENTER,
    CALL_SERVICE_CENTER,
    FETCH_SERVICE_COMPLAINT_CONFIG,
    FETCH_SERVICE_REPORT_CONFIG,
    ADD_SERVICE_CENTER,
    FETCH_PAGINATION_INFO,
    GET_EMPLOYEE_DETAILS,
    ADD_NEW_SERVICE_CENTER,
    ADD_SUB_SERVICE_CENTER_EMPLOYEE,
    CLEAR_MAIN_SERVICE_CENTER_DATA,
    EDIT_SERVICE_CENTER,
    EDIT_SUB_SERVICE_CENTER_EMPLOYEE,
    CLEAR_SUB_SERVICE_CENTER_EMP_DATA,
    CLEAR_SEARCH_DATA,
    CLEAR_EDIT_SERVICE_CENTER_DATA,
    CLEAR_EDIT_EMPLOYEE_DATA,
    DEACTIVATE_SERVICE_CENTER,
    FETCH_SEARCH_RESULTS,
    FETCH_COMPLAINTS_DATA,
    GET_COMPLAINTS_DETAIL,
    PRINT_SC_LABEL_DATA,
    FETCH_COMMENTS,
    FETCH_CONVERSATIONS,
    FETCH_COMPLAINTS_COUNT,
    GET_SC_RPU_COURIER,
    GET_EMPLOYEE_DATA,
    SHOW_COMPLAINT_DETAILS,
    FETCH_SERVICE_CENTERS,
    FETCH_SERVICE_CENTER_REPORT,
    FETCH_SERVICE_CONVERSATIONS,
    GET_PRODUCT_PROBLEM_LIST,
    SET_ACTIVE_FILTERS,
    TRACK_SERVICE_STATUS_DATA,
    FETCH_SERVICE_CENTER_LIST,
    CLEAR_SUCCESS_LIST_SC,
    CLEAR_FAILURE_LIST_SC,
    SUCCESS_LIST_SC,
    FAILURE_LIST_SC,
    SUCCESS_WAY_BILL_LIST,
    FAILURE_WAY_BILL_LIST,
    SUCCESS_LIST_BRPU_SC,
    FAILURE_LIST_BRPU_SC,
    CLEAR_SUCCESS_LIST_BRPU_SC,
    CLEAR_FAILURE_LIST_BRPU_SC,
    FETCH_CHALLAN_DATA,
    SET_COMPLAINT_ARRAY,
    SEND_CHALLAN_ACTION,
    SEND_WAREHOUSE_ACTION,
    FAILED_TO_RECIEVE,
    FETCH_CONVERSATIONS_DATA,
    FETCH_CONVERSATIONS_LOAD,
    FETCH_COMMENT_DATA,
    FETCH_COMMENT_LOAD,
} from "./types";

export const getServiceCenters = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/master/servicecenter", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_SERVICE_DATA,
                    payload: res.data || [],
                });
                dispatch({
                    type: FETCH_PAGINATION_INFO,
                    payload: res.data.data || [],
                });
            } else {
                dispatch({
                    type: FETCH_SERVICE_DATA,
                    payload: null,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const updateCenterInfo = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/master/upsert-service-center", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: UPDATE_SERVICE_DATA,
                    payload: res.data || [],
                });
            } else {
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
            if (e.response.status === 429) {
                alert(e.response.statusText || "Something went wrong");
            }
        });
};

export const removeServiceCenter = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .get(baseUrl + "/master/servicecenter/remove/" + data.id, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                dispatch({
                    type: REMOVE_SERVICE_CENTER,
                    payload: res.data,
                });
            } else {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const callServiceCenter = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .get(baseUrl + "/master/servicecenter/call/" + data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                dispatch({
                    type: CALL_SERVICE_CENTER,
                    payload: res.data,
                });
            } else {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const downlaodCenterReportData = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/service-center-report/", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                // dispatch({
                //   type: FETCH_INVOICE_REPORT_DATA,
                //   payload: res.data.data
                // })
                dispatch({
                    type: FETCH_SERVICE_REPORT_CONFIG,
                    payload: res.data.checkDownloadLimit[0],
                });
            } else {
                // if (res.data.code == 1001 || res.data.code == 1002 || res.data.code == 1003  ){
                //   localStorage.removeItem('token')
                //   history.push('/')
                // }
            }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const ServiceCenterComplaintData = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/get-complaint", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_SERVICE_COMPLAINT_CONFIG,
                    payload: res.data.data,
                });
                dispatch({
                    type: FETCH_PAGINATION_INFO,
                    payload: res.data.data || [],
                });
            }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const addServiceCenter = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/user/add-service-center", data, { headers: { "x-access-token": token } })
        .then(res => {
            dispatch({
                type: ADD_SERVICE_CENTER,
                payload: res.data,
            });
            alert(res.data.message);
        })
        .catch(e => {
            if (e.response.status === 401) history.push("/");
        });
};

export const getEmployeeDetails = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/user/get-sc-user-list", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: GET_EMPLOYEE_DETAILS,
                    payload: res.data.data,
                });
                dispatch({
                    type: FETCH_SERVICE_CENTERS,
                    payload: res.data.data.userData,
                });
                dispatch({
                    type: FETCH_PAGINATION_INFO,
                    payload: res.data.data,
                });
            } else {
                dispatch({
                    type: GET_EMPLOYEE_DETAILS,
                    payload: [],
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) history.push("/");
        });
};

export const addNewServiceCenter = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/user/add-employee", data, { headers: { "x-access-token": token } })
        .then(res => {
            dispatch({
                type: ADD_NEW_SERVICE_CENTER,
                payload: res.data,
            });
            alert(res.data.message);
        })
        .catch(e => {
            if (e.response.status === 401) history.push("/");
        });
};

export const editServiceCenter = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/user/edit-employee", data, { headers: { "x-access-token": token } })
        .then(res => {
            dispatch({
                type: EDIT_SERVICE_CENTER,
                payload: res.data,
            });
            alert(res.data.message);
        })
        .catch(e => {
            if (e.response.status === 401) history.push("/");
        });
};

export const addSubServiceCenterEmployee = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/user/add-employee", data, { headers: { "x-access-token": token } })
        .then(res => {
            dispatch({
                type: ADD_SUB_SERVICE_CENTER_EMPLOYEE,
                payload: res.data,
            });
            alert(res.data.message);
        })
        .catch(e => {
            if (e.response.status === 401) history.push("/");
        });
};

export const editSubServiceCenterEmployee = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/user/edit-employee", data, { headers: { "x-access-token": token } })
        .then(res => {
            dispatch({
                type: EDIT_SUB_SERVICE_CENTER_EMPLOYEE,
                payload: res.data,
            });
            alert(res.data.message);
        })
        .catch(e => {
            if (e.response.status === 401) history.push("/");
        });
};

export const clearMainServiceCenterData = () => async dispatch => {
    dispatch({
        type: CLEAR_MAIN_SERVICE_CENTER_DATA,
    });
};

export const clearSubServiceCenterEmpData = () => async dispatch => {
    dispatch({
        type: CLEAR_SUB_SERVICE_CENTER_EMP_DATA,
    });
};

export const clearSearchData = () => async dispatch => {
    dispatch({
        type: CLEAR_SEARCH_DATA,
    });
};

export const clearEditServiceCenterData = () => async dispatch => {
    dispatch({
        type: CLEAR_EDIT_SERVICE_CENTER_DATA,
    });
};

export const clearEditEmpData = () => async dispatch => {
    dispatch({
        type: CLEAR_EDIT_EMPLOYEE_DATA,
    });
};

export const removeSubServiceCenter = id => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .get(baseUrl + "/user/remove/" + id, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                dispatch({
                    type: DEACTIVATE_SERVICE_CENTER,
                    payload: res.data,
                });
            } else {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getSCComplaintData = data => async dispatch => {
    let token = localStorage.getItem("token");
    // let token = "abc"
    return await axios
        .post(baseUrl + "/sc-complaint/get-complaint", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_COMPLAINTS_DATA,
                    payload: res.data.data.complaint_data || [],
                });
                dispatch({
                    type: FETCH_COMPLAINTS_COUNT,
                    payload: res.data || [],
                });
                dispatch({
                    type: FETCH_PAGINATION_INFO,
                    payload: res.data.data || [],
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getSCComplaintDetail = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .get(baseUrl + "/sc-complaint/get/" + data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: GET_COMPLAINTS_DETAIL,
                    payload: res.data.data[0] || null,
                });
                dispatch({
                    type: FETCH_COMMENTS,
                    payload: res.data.data[0] ? res.data.data[0].comments : [],
                });
                dispatch({
                    type: FETCH_CONVERSATIONS,
                    payload: res.data.data[0] ? res.data.data[0].conversation : [],
                });
            } else {
                dispatch({
                    type: GET_COMPLAINTS_DETAIL,
                    payload: null,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const moveToDispatchInward = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/dispatch-inward", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getSCRpuCourier = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/courier/get-sc-pincode", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: GET_SC_RPU_COURIER,
                    payload: res.data.data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const submitSCRPU = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/reverse-pickup", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert("Successfully sent");
            } else {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

//Search
export const getSearchResults = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/get-complaint", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_SEARCH_RESULTS,
                    payload: res.data.data.complaint_data,
                });
                dispatch({
                    type: FETCH_PAGINATION_INFO,
                    payload: res.data.data || [],
                });
            }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const fetchSCPrintLabel = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/printLabel", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: PRINT_SC_LABEL_DATA,
                    payload: res.data.data,
                });
            } else {
                dispatch({
                    type: PRINT_SC_LABEL_DATA,
                    payload: null,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const onhandOverToCourier = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/handover", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const onRevokeAction = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .get(baseUrl + "/sc-complaint/revoke/" + data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const onRevokeInvAction = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .get(baseUrl + "/sc-complaint/revoke-inv/" + data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const onIQCPassAction = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/iqc-pass", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const onIQCFailAction = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/iqc-fail", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const onClosedAction = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/close", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getEmployeeData = id => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .get(baseUrl + "/user/get/" + id, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: GET_EMPLOYEE_DATA,
                    payload: res.data,
                });
            } else {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const showComplaintDetailsAction = flag => async dispatch => {
    dispatch({
        type: SHOW_COMPLAINT_DETAILS,
        payload: flag,
    });
};

export const exportReport = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/sc-complaint-report", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_SERVICE_CENTER_REPORT,
                    payload: res.data,
                });
            }
            alert(res.data.message);
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const addComments = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/add-comments", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                // dispatch({
                //   type: ADD_COMMENT,
                //   payload: res.data
                // })
                dispatch({
                    type: FETCH_SERVICE_CONVERSATIONS,
                    payload: res.data.data.conversation,
                });
                // dispatch(refreshComplaintList(true))
                // dispatch(showComplaintDetailsAction(false))
                return res.data;
            }
            alert(res.data.message);
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
            if (e.response.status === 429) {
                alert(e.response.statusText || "Something went wrong");
            }
        });
};

export const sendServiceMail = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/msg-customer", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_SERVICE_CONVERSATIONS,
                    payload: res.data.data.conversation,
                });
                return res.data;
                // dispatch(refreshComplaintList(true))
                // dispatch(showComplaintDetailsAction(false))
            }
            alert(res.data.message);
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
            if (e.response.status === 429) {
                alert(e.response.statusText || "Something went wrong");
            }
        });
};

export const setActiveInFilters = data => async dispatch => {
    dispatch({
        type: SET_ACTIVE_FILTERS,
        payload: data,
    });
};

export const fetchServiceTrackStatus = data => async dispatch => {
    let token = localStorage.getItem("token");
    let url = "";
    if (data.tracking === "shiprocket") {
        url = baseUrl + "/courier/shiprocket/track-awb";
    } else {
        url = baseUrl + "/courier/clickpost/track-awb";
    }
    return await axios
        .post(url, data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: TRACK_SERVICE_STATUS_DATA,
                    payload: res.data.data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getProductProblemList = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/product/problem-list", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: GET_PRODUCT_PROBLEM_LIST,
                    payload: res.data.data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getSerCenterList = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/master/get-emp-sc", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_SERVICE_CENTER_LIST,
                    payload: res.data.data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const clearDIDataResults = () => async dispatch => {
    dispatch({
        type: CLEAR_SUCCESS_LIST_SC,
        payload: [],
    });
    dispatch({
        type: CLEAR_FAILURE_LIST_SC,
        payload: [],
    });
};

export const submitDispatchInward = body => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/bulk-dispatch-inward", body, { headers: { "x-access-token": token } })
        .then(res => {
            dispatch({
                type: SUCCESS_LIST_SC,
                payload: res.data.data.success,
            });
            dispatch({
                type: FAILURE_LIST_SC,
                payload: res.data.data.failure,
            });
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const submitWayBillNumbers = body => async dispatch => {
    let token = localStorage.getItem("token");

    return await axios
        .post(baseUrl + "/sc-complaint/bulk-handover", body, { headers: { "x-access-token": token } })
        .then(res => {
            dispatch({
                type: SUCCESS_WAY_BILL_LIST,
                payload: res.data.data.success,
            });
            dispatch({
                type: FAILURE_WAY_BILL_LIST,
                payload: res.data.data.failure,
            });
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const clearBulkRpuDataResults = () => async dispatch => {
    dispatch({
        type: CLEAR_SUCCESS_LIST_BRPU_SC,
        payload: [],
    });
    dispatch({
        type: CLEAR_FAILURE_LIST_BRPU_SC,
        payload: [],
    });
};

export const submitBulkRPU = body => async dispatch => {
    let token = localStorage.getItem("token");

    return await axios
        .post(baseUrl + "/sc-complaint/bulk-courier-gen", body, { headers: { "x-access-token": token } })
        .then(res => {
            dispatch({
                type: SUCCESS_LIST_BRPU_SC,
                payload: res.data.data.success,
            });
            dispatch({
                type: FAILURE_LIST_BRPU_SC,
                payload: res.data.data.failure,
            });
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const challanAction = () => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .get(baseUrl + "/sc-complaint/create-challan-no", { headers: { "x-access-token": token } })
        .then(res => {
            dispatch({
                type: FETCH_CHALLAN_DATA,
                payload: res.data.data,
            });
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const setComplaintsArray = data => async dispatch => {
    dispatch({
        type: SET_COMPLAINT_ARRAY,
        payload: data,
    });
};

export const sendChallanAction = body => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/send-to-msc", body, { headers: { "x-access-token": token } })
        .then(res => {
            dispatch({
                type: SEND_CHALLAN_ACTION,
                payload: res.data.data,
            });
            alert(res.data.message);
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const sendWareHouse = body => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/send-for-replacement", body, { headers: { "x-access-token": token } })
        .then(res => {
            dispatch({
                type: SEND_WAREHOUSE_ACTION,
                payload: res.data.data,
            });
            alert(res.data.message);
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const failedToRecieve = body => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/product-not-received", body, { headers: { "x-access-token": token } })
        .then(res => {
            dispatch({
                type: FAILED_TO_RECIEVE,
                payload: res.data.data,
            });
            alert(res.data.message);
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const gccaseService = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/gc-case", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const refundCaseApproval = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/refund-case", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const freshPRoductAPI = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/fresh-product", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const UnservicesableleAPI = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/unserviceable", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const unmarkedTicketRefund = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/refund-unmark", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const unmarkedTicketGC = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/gc-unmark", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const RtoOutAPi = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/rtoout", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const moveToRtoCase = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/rto-case", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const OutOFStockAPI = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/sc-complaint/move-out-stock", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getSCConversationData = (id, page) => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .get(baseUrl + "/sc-complaint/get-conversation/" + id + "?page=" + page, { headers: { "x-access-token": token } })
        .then(res => {
            dispatch({
                type: FETCH_CONVERSATIONS_DATA,
                payload: res.data.data.conversation,
            });
            dispatch({
                type: FETCH_CONVERSATIONS_LOAD,
                payload: res.data.data,
            });
            return res.data.data.conversation;
        })
        .catch(e => {
            if (e.response.status === 400) {
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getSCCommentData = (id, page) => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .get(baseUrl + "/sc-complaint/get-comments/" + id + "?page=" + page, { headers: { "x-access-token": token } })
        .then(res => {
            dispatch({
                type: FETCH_COMMENT_DATA,
                payload: res.data.data.comments,
            });
            dispatch({
                type: FETCH_COMMENT_LOAD,
                payload: res.data.data,
            });
            return res.data.data.comments;
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};
