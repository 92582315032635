import React, { Component } from 'react';
import { connect } from "react-redux";
import 'react-quill/dist/quill.snow.css';
import { sendMail } from '../../../actions/complaintsDataAction';
import moment from 'moment';
import HtmlParser from 'react-html-parser';
import { addDistributorComments, getRTLConversationData } from '../../../actions/distributorAction';
import ReactModal from 'react-modal';
import ConversationExpand from './ConversationExpand';

const customStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    overflow: "scroll"
  },
  content: {
    inset: '0 0 auto',
    marginRight: '0',
    transform: 'translate(0)',
    background: '#1a1a1a',
    padding: '2rem',
    border: 'none',
    width: '100%',
    position: 'absolute',
    overflow: 'scroll',
    height: '100%'
  },
};

class Conversations extends Component {
  constructor() {
    super();
    this.state = {
      editorHtml: '',
      theme: 'snow',
      file: [],
      expandConversation: false,
      disabled: false,
      typeValue: 1,
      commentsValue: '',
      commentDisabled: false,
      showExpandedConversation: false,
      conversationLIST: [],
      pageno: 1,
      isLoading: false,
    };
    this.showConversation = this.showConversation.bind(this);
    this.expandFunction = this.expandFunction.bind(this);
  }

  componentDidMount() {
    this.getConversationComplaits();
    var myDiv = document.getElementById("conversation_head");
    // myDiv.scrollTop = myDiv.scrollHeight - myDiv.clientHeight;
  }


  getConversationComplaits = async () => {
    this.setState({ isLoading: true });
    let response = await this.props.getRTLConversationData(this.props.distributorComplainDetails.id, this.state.pageno);
    this.setState({ conversationLIST: response, isLoading: false });
  };

  showConversation = async (val) => {
    this.setState({ showExpandedConversation: true });
  };

  expandFunction = async (val) => {
    this.setState({ showExpandedConversation: val });
    if (!val) {
      let response = await this.props.getRTLConversationData(this.props.distributorComplainDetails.id, 1);
      this.setState({ conversationLIST: response });
    }


  };

  loadMore = async () => {
    let response = await this.props.getRTLConversationData(this.props.distributorComplainDetails.id, this.state.pageno + 1);
    this.setState({ conversationLIST: [...this.state.conversationLIST, ...response], pageno: this.state.pageno + 1 });
  };

  render() {
    let userRoleId = localStorage.getItem('userRoleId');
    return (
      <div>
        {!this.state.isLoading ?
          < div >
            {userRoleId !== '4' &&
              <div className="expand_wrapper" onClick={this.showConversation}>
                <i className="fa fa-expand" aria-hidden="true"></i>
              </div>
            }
            <div className="head conversation_head" id="conversation_head">
              {
                this.state.conversationLIST.map((b, index) => (
                  <div key={b.id} style={{ width: "100%", float: "left" }}>
                    {!b.user &&
                      <div>
                        {b.from_email != null
                          ?
                          <div id="coversation_body" className={b.from_email !== "NOISE <help@nexxbase.com>" && 'incoming'} >
                            <div>
                              {b.from_email !== "NOISE <help@nexxbase.com>" &&
                                <h3>Customer Reply</h3>
                              }
                              <p>{b.body_text}</p>
                              <p id="created_at">{moment(b.created_at, ["MMMM DD, YYYY || HH.mm"]).format('MMMM DD, YYYY || hh:mm A')}</p>
                            </div>
                          </div>
                          :
                          <div id="coversation_body" className={b.to_emails === '' && "yellowBG"} >
                            <div>
                              <p>{b.body_text}</p>
                              <p id="created_at">{moment(b.created_at, ["MMMM DD, YYYY || HH.mm"]).format('MMMM DD, YYYY || hh:mm A')}</p>
                            </div>
                          </div>
                        }
                      </div>}
                  </div>
                ))
              }
              {this.props.commentSCpage.next_page == true &&
                <div className="expand_wrapper" onClick={this.loadMore}>
                  <button className='loadMoreBUtton'>Load more..</button>
                </div>}
            </div>
          </div> :
          <div>
            <i id="loadingText">Please wait ....</i>
          </div>}

        <ReactModal
          isOpen={this.state.showExpandedConversation}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          ariaHideApp={false}
          id="conversationExpand"
        >
          <ConversationExpand complaintId={this.props.distributorComplainDetails.id} showExpandedConversation={this.expandFunction} conversationData={this.state.conversationLIST} pageno={this.state.pageno} />
        </ReactModal>
      </div >
    );
  }
}

const mapStateToProps = state => ({
  distributorConversationData: state.distributor.distributorConversationData,
  distributorComplainDetails: state.distributor.distributorComplainDetails,
  commentSCDetails: state.distributor.commentSCDetails,
  commentSCpage: state.distributor.commentSCpage,

});

export default connect(mapStateToProps, { sendMail, addDistributorComments, getRTLConversationData })(Conversations);