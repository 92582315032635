import React, { Component } from 'react';
import './DashboardComponent.css';
import { connect } from "react-redux";
import history from '../../history';
import moment from 'moment'
import Loader from 'react-loader-spinner'
import { fetchDashboardAll, fetchDashboardCourier, fetchDashboardProblem } from '../../actions/dashboardAction';
// import axios from 'axios';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';
import Select from 'react-select';
// import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';
import ViewProfile from '../ViewProfile';
import AccessRestricted from '../AccessRestricted'
import ComplaintRegistration from '../ComplaintRegistration';
import SidebarComponent from '../SidebarComponent/SidebarComponent';

const {
  // allowedMaxDays,
  // allowedDays,
  allowedRange,
  // beforeToday,
  // afterToday,
  // combine
} = DateRangePicker;

// const Ranges = [
//   {
//     label: 'today',
//     value: [startOfDay(new Date()), endOfDay(new Date())]
//   },
//   {
//     label: 'yesterday',
//     value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))]
//   },
//   {
//     label: 'last7Days',
//     value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]
//   }
// ];


class DashboardComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dashboard: {},
      courierList: [],
      summaryBlock: [],
      product_list: [],
      product_list_total: 0,
      courier_count: 0,
      problem_list: [],
      problem_list_total: 0,
      startDate: new Date().setDate(new Date().getDate() - 15),
      endDate: new Date().setDate(new Date().getDate()),
      selectedOption: null,
      problemDropdown: [],
      selectProductName: '',
      courierName: '',
      isLoading: false
    }

    this.updateCourierCount = this.updateCourierCount.bind(this);
    this.handleChangeProblem = this.handleChangeProblem.bind(this);
    this.handleChangeCourier = this.handleChangeCourier.bind(this);

  }
  handleLogout() {
    localStorage.removeItem('token')
    document.querySelector(".modal-backdrop").style.display = "none";
    history.push('/')
  }
  async componentDidMount() {
    this.setState({ isLoading: true })
    let dataLeader = {
      "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
      "endDate": moment(this.state.endDate).format('YYYY-MM-DD')
    }
    await this.props.fetchDashboardAll(dataLeader);
    this.setState({
      isLoading: false,
      dashboard: this.props.dashboardData,
      courierList: this.props.dashboardData.courier ? this.props.dashboardData.courier.dropdown : [],
      summaryBlock: this.props.dashboardData.summaryBlock || [],
      product_list: this.props.dashboardData.product_list ? this.props.dashboardData.product_list.data : [],
      product_list_total: this.props.dashboardData.product_list ? this.props.dashboardData.product_list.total : 0,
      courier_count: this.props.dashboardData.courier ? this.props.dashboardData.courier.total : 0,
      problem_list: this.props.dashboardData.problem_list ? this.props.dashboardData.problem_list.data : [],
      problemDropdown: this.props.dashboardData.problem_list ? this.props.dashboardData.problem_list.dropdown : [],
      selectProductName: this.props.dashboardData.problem_list && this.props.dashboardData.problem_list.dropdown.length > 0 ? this.props.dashboardData.problem_list.dropdown[0].product_name : null,
      problem_list_total: this.props.dashboardData.problem_list ? this.props.dashboardData.problem_list.total : 0,
      courierName: this.props.dashboardData.courier && this.props.dashboardData.courier.dropdown.length > 0 ? this.props.dashboardData.courier.dropdown[0].name : null


    })

  }

  dateSelect = async (value) => {
    this.setState({
      isLoading: true,
      startDate: new Date(value[0]),
      endDate: new Date(value[1])
    })
    let dataLeader = {
      "startDate": moment(new Date(value[0])).format('YYYY-MM-DD'),
      "endDate": moment(new Date(value[1])).format('YYYY-MM-DD')
    }
    await this.props.fetchDashboardAll(dataLeader);
    this.setState({
      isLoading: false,
      dashboard: this.props.dashboardData,
      courierList: this.props.dashboardData.courier.dropdown,
      summaryBlock: this.props.dashboardData.summaryBlock,
      product_list: this.props.dashboardData.product_list.data,
      product_list_total: this.props.dashboardData.product_list.total,
      courier_count: this.props.dashboardData.courier.total,
      problem_list: this.props.dashboardData.problem_list.data,
      problemDropdown: this.props.dashboardData.problem_list.dropdown,
      selectProductName: this.props.dashboardData.problem_list.dropdown.length > 0 ? this.props.dashboardData.problem_list.dropdown[0].product_name : null,
      problem_list_total: this.props.dashboardData.problem_list.total,
      courierName: this.props.dashboardData.courier.dropdown.length > 0 ? this.props.dashboardData.courier.dropdown[0].name : null
    })
  }

  updateCourierCount = async (event) => {
    let courierUpdate = event.target.value
    this.setState({ courierName: courierUpdate, isLoading: true });
    //let selectedCourierOption = document.querySelector('#courier-dropdown').value;
    let selectedCourierOptionId = document.querySelector('#courier-dropdown')
    let OptionId = selectedCourierOptionId.options[selectedCourierOptionId.selectedIndex].getAttribute("data-id")
    let url = '/courier/' + OptionId + '/' + moment(this.state.startDate).format('YYYY-MM-DD') + '/' + moment(this.state.endDate).format('YYYY-MM-DD')
    await this.props.fetchDashboardCourier(url);
    this.setState({
      isLoading: false,
      courier_count: this.props.dashboardDataCourier.courier.total,
    })
  }


  handleChangeCourier = async (courierName) => {
    this.setState({ isLoading: true, courierName: courierName.value });
    // 
    let url = '/courier/' + courierName.id + '/' + moment(this.state.startDate).format('YYYY-MM-DD') + '/' + moment(this.state.endDate).format('YYYY-MM-DD')
    await this.props.fetchDashboardCourier(url);
    // this.setState({
    //   isLoading: false,    
    //   courier_count: this.props.dashboardDataCourier.courier.total
    // })
    if (this.props.dashboardDataCourier.courier) {
      this.setState({
        isLoading: false,
        courier_count: this.props.dashboardDataCourier.courier.total
      })
    }
  };
  handleChangeProblem = async (selectProductName) => {
    this.setState({ isLoading: true, selectProductName: selectProductName.value });
    let url = '/product/' + selectProductName.id + '/' + moment(this.state.startDate).format('YYYY-MM-DD') + '/' + moment(this.state.endDate).format('YYYY-MM-DD')
    await this.props.fetchDashboardProblem(url);
    if (this.props.dashboardDataProblem.problem_list) {
      this.setState({ isLoading: false, problem_list: this.props.dashboardDataProblem.problem_list.data });
    }
  };
  render() {
    const { problemDropdown, selectProductName, courierName, courierList } = this.state;
    let optionDrop = []
    problemDropdown.map((obj, index) => (
      optionDrop.push(
        {
          label: obj.product_name,
          value: obj.product_name,
          id: obj.id
        }
      )
    ))
    let optionDropCourier = []
    courierList.map((obj, index) => (
      optionDropCourier.push(
        {
          label: obj.name,
          value: obj.name,
          id: obj.id
        }
      )
    ))
    let userRoleId = localStorage.getItem('userRoleId');
    if(userRoleId === '4' || userRoleId === '8' || userRoleId === '9' || userRoleId === '10'){
      return( <AccessRestricted/>)
    }
 
    else if (userRoleId === '6') {
      return (<ComplaintRegistration />)
    }
    else {
  return (
    <div>
     { window.innerWidth > 600 && <SidebarComponent sideProp={'DB'} />}
      <ViewProfile />

      {this.state.dashboard &&
        <div className="content-dashboard">
          <div className="content-title">
            <h3>Dashboard</h3>
            <div className="dropdown-wrap">
            <div className="lbtn drop-down-wid">
              <div className="dropdown-bar">
                {/* <h6>Date - (DD/MM/YYYY)</h6> */}
                <DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(new Date(new Date().setDate(new Date().getDate() - 90))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.dateSelect(value) }} value={[new Date(this.state.startDate), new Date(this.state.endDate)]}  />
              </div>
            </div>
          </div>
          </div>
         
          {this.state.isLoading ?
            <div className='loader'>
              <Loader
                type="Oval"
                color="Grey"
                height={200}
                width={200}
              />
            </div>
            :
            <div className="main-wrap">

              <div className="left-grid">
                <div className="complaint-wrapper">
                  <div className="complaint-wrap">
                    {this.state.summaryBlock.map((obj, index) => (
                      <div key={index + 1} className="grid">
                        <h6>{obj.name}</h6>
                        <h2 >{obj.count || 0}</h2>
                      </div>
                    ))}
                  </div>

                </div>
                <div className="progress-wrap">
                  <div className="title-wrap">
                    <h3>{this.props.dashboardData.product_list ? this.props.dashboardData.product_list.name : ''}</h3>
                  </div>
                  <div className="progress-inner-wrap">
                    {this.state.product_list.map((obj, index) => (
                      <div className="progress-wrap-main" key={index}>
                        <p>{obj.name} <span>{obj.count}</span></p>
                        <div className="progress">
                          <div className="progress_inner" style={{ width: obj.count / this.state.product_list_total * 100 + '%' }}></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

              </div>

              <div className="right-grid">
                <div className="complaint-wrapper">
                  <div className="courier-wrap">
                    <div className="grid">
                      <h6>{this.props.dashboardData.courier ? this.props.dashboardData.courier.name : ''}</h6>
                      <h2 className="courier-color">₹{this.state.courier_count}</h2>
                    </div>
                    <div className="grid">
                      <div className="dropdown-wrap-courier">
                        {/* <select id="courier-dropdown" className="dropbtn" onChange={this.updateCourierCount} value={this.state.courierName}> <span className="down-arrow"><i className="fa  fa-angle-down"></i></span>
                       {this.state.courierList.map((obj) => (
                          <option value={obj.name} data-id={obj.id} >{obj.name}</option>
                        ))}
                       </select> */}
                        <Select
                          onChange={this.handleChangeCourier}
                          options={optionDropCourier}
                          isSearchable={true}
                          value={courierName}
                          maxMenuHeight={200}
                          className="problem-product-courier"
                          placeholder={courierName}
                          closeMenuOnSelect={true}
                          styles= {{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              border: '1px solid #595959',
                              boxShadow: '0 !important',
                              '&:hover': {
                                border: '1px solid #595959 !important'
                              },
                              borderRadius: '20px !important',
                              backgroundColor: '#000 !important',
                              padding: '0 5px',
                              }),
                              option: (baseStyles, state) => ({
                                ...baseStyles,
                                background: state.isFocused ? '#402500d7 !important':'#000 !important',
                                color: state.isFocused? '#FA9F22': '#fff !important',
                                width: '100%'

                              }),
                              placeholder: (baseStyles) => ({
                                ...baseStyles,
                                color: '#fff !important'
                              }),
                              menuList: (baseStyles) => ({
                                ...baseStyles,
                                background: '#000 !important'
                              }),
                              Input: (baseStyles) => ({
                                ...baseStyles,
                               
                                color: '#fff !important'
                              }),
                          }}
                        />
                      </div>
                    </div>

                  </div>
                </div>
                <div className="progress-wrap">
                  <div className="title-wrap">
                    <h3>{this.props.dashboardData.problem_list ? this.props.dashboardData.problem_list.name  : ''}</h3>
                    <div className="grid">
                      <div className="dropdown-wrap-problem">
                        {/* <select className="dropbtn"> <span> Ticket Received</span> <i className="fa  fa-angle-down"></i><option>All</option><option>CS Status</option><option>Courier Status</option><option>Cred Status</option><option>Whearhouse Status</option></select> */}
                        {/* <SelectSearch
                            options={options}
                            search
                            placeholder="Select your country"
                        />  */}
                        <h6>Select product</h6>
                        <Select
                          onChange={this.handleChangeProblem}
                          options={optionDrop}
                          isSearchable={true}
                          value={selectProductName}
                          maxMenuHeight={300}
                          className="problem-product-search"
                          placeholder={selectProductName}
                          closeMenuOnSelect={true}
                          styles= {{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              border: '1px solid #595959',
                              boxShadow: '0 !important',
                              '&:hover': {
                                border: '1px solid #595959 !important'
                              },
                              borderRadius: '20px !important',
                              backgroundColor: '#000 !important',
                              padding: '0 5px',
                              }),
                              option: (baseStyles, state) => ({
                                ...baseStyles,
                                background: state.isFocused ? '#402500d7 !important':'#000 !important',
                                color: state.isFocused? '#FA9F22': '#fff !important',
                                width: '100%'

                              }),
                              placeholder: (baseStyles) => ({
                                ...baseStyles,
                                color: '#fff !important'
                              }),
                              menuList: (baseStyles) => ({
                                ...baseStyles,
                                background: '#000 !important'
                              })
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="progress-inner-wrap">
                    {
                      this.state.problem_list.length > 0 ? 
                      <div>
                      {this.state.problem_list.map((obj, index) => (
                        <div className="progress-wrap-main" key={index}>
                          <p>{obj.problem} <span>{obj.count}</span></p>
                          <div className="progress">
                            <div className="progress_inner" style={{ width: obj.count / this.state.problem_list_total * 100 + '%' }}></div>
                          </div>
                        </div>
                      ))}
                      </div> : <p>No problem found </p>
                    }
                   
                  </div>
                </div>
              </div>

            </div>
          }

        </div>
      }
    </div>

  )
    }
  
  }
}
const mapStateToProps = state => ({
  dashboardData: state.dashboard.dashboardData,
  dashboardDataCourier: state.dashboard.dashboardDataCourier,
  dashboardDataProblem: state.dashboard.dashboardDataProblem
})

export default connect(mapStateToProps, { fetchDashboardAll, fetchDashboardCourier, fetchDashboardProblem })(DashboardComponent);
