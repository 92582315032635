import React, { Component } from 'react';
import './Settings.css';
import { connect } from "react-redux";
import history from '../../history';
// import moment from 'moment'
import Loader from 'react-loader-spinner';
//import { getUserData, getUserDetail, createUser, editUserData, getAccessLevelData, getReportinglData, removeUserData } from '../../actions/userActions'
import { getUserDetail, editUserData, getStateList, getCityByState } from '../../actions/userActions';
import {
  fetchReportData, fetchDownloadLimit, fetchActionReportLimit, fetchUserActionReportData,
  fetchOpenCloseReportDownloadData, fetchOpenCloseReportLimit, inVoiceReportData, ivrReportData,fetchReplacementReport,
  bulkMovementReport, refundGCReport, scReport, CronTeleforceAction, rtlReportData, notifyReport, lunaReport, oosReport, oosGiftReport, b2bReportAction
} from '../../actions/reportActions';
import { resetPassword } from '../../actions/loginActions';
import ReactModal from 'react-modal';
import ViewProfile from '../ViewProfile';
// import { CSVLink } from "react-csv";
import moment from 'moment';
import DateRangePicker from 'rsuite/DateRangePicker';
import SidebarComponent from '../SidebarComponent/SidebarComponent';

const {
  allowedRange,
  // allowedMaxDays,
  // before,
  // combine
} = DateRangePicker;

const customStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    inset: '8% 0 0 auto',
    marginRight: '0',
    transform: 'translate(0)',
    background: '#000',
    padding: '2rem',
    border: 'none',
    width: '35%',
    float: 'right',
    marginTop: '5%',
    height: '100%',
    display: 'inline-block'
  },
};

const editStyle = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    inset: '8% 0 0 auto',
    marginRight: '0',
    transform: 'translate(0)',
    background: '#000',
    padding: '2rem',
    border: 'none',
    width: '50%',
    float: 'right',
    marginTop: '5%',
    height: '110%',
    display: 'inline-block'
  },
};

class Settings extends Component {
  constructor() {
    super();
    this.state = {
      reporting: '',
      accessLevel: '',
      logout: false,
      //showMenu: false,
      pageNo: 0,
      pageLimit: 10,
      searchText: '',
      //searchDate: '',
      userList: [],
      userDetailList: [],
      //prevPage: false,
      //nextPage: false,
      userId: '',
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      mobile: '',
      // address: '',
      accessLavel: '',
      reportingTo: '',
      isReporter: '',
      first_name: '',
      last_name: '',
      username: '',
      address: '',
      address_2: '',
      state: '',
      city: '',
      stateList: [],
      cityList: [],
      state_id: '',
      pincode: '',
      fields: {},
      errors: [],
      accessList: [],
      reportList: [],
      reportingList: [],
      accessId: 0,
      reportingId: 0,
      is_reporter: 0,
      reporting_to: 0,
      access_lavel: '',
      reporting_to: '',
      user_role: '',
      user_reporting: '',
      loader: false,
      limit: 10,
      totalcount: '',
      startDate: new Date().setDate(new Date().getDate() - 31),
      endDate: new Date().setDate(new Date().getDate()),
      reportStartDate: new Date().setDate(new Date().getDate()),
      reportEndDate: new Date().setDate(new Date().getDate()),
      actionStartDate: new Date().setDate(new Date().getDate() - 31),
      actionEndDate: new Date().setDate(new Date().getDate()),
      RTLStartDate: new Date().setDate(new Date().getDate() - 31),
      RTLEndDate: new Date().setDate(new Date().getDate()),
      LunaStartDate: new Date().setDate(new Date().getDate() - 31),
      LunaEndDate: new Date().setDate(new Date().getDate()),
      openCloseStartDate: new Date().setDate(new Date().getDate() - 31),
      openCloseEndDate: new Date().setDate(new Date().getDate()),
      ivrCloseStartDate: new Date().setDate(new Date().getDate() - 31),
      ivrCloseEndDate: new Date().setDate(new Date().getDate()),
      refundGCStartDate: new Date().setDate(new Date().getDate() - 31),
      refundGCEndDate: new Date().setDate(new Date().getDate()),
      bulkCloseStartDate: new Date().setDate(new Date().getDate() - 31),
      bulkCloseEndDate: new Date().setDate(new Date().getDate()),
      scREportStartDate: new Date().setDate(new Date().getDate() - 31),
      scReportEndDate: new Date().setDate(new Date().getDate()),
      notifyStartDate: new Date().setDate(new Date().getDate() - 31),
      notifyEndDate: new Date().setDate(new Date().getDate()),
      oosStartDate: new Date().setDate(new Date().getDate() - 31),
      oosEndDate: new Date().setDate(new Date().getDate()),
      oosGiftStartDate: new Date().setDate(new Date().getDate() - 31),
      oosGiftEndDate: new Date().setDate(new Date().getDate()),
      replaceMentStartDate: new Date().setDate(new Date().getDate() - 31),
      replacementEndDate: new Date().setDate(new Date().getDate()),
      b2bStartDate: new Date().setDate(new Date().getDate() - 31),
      b2bEndDate: new Date().setDate(new Date().getDate()),
      prevPage: '',
      nextPage: '',
      showChangePassword: false,
      isPasswordShown1: false,
      isPasswordShown2: false,
      editProfile: false,
      editProfileRetailer: false,
      errorsPhone: '',
      editDateRange: false,
      errorsFirstName: '',
      errorsLastName: '',
      isLoading: false,
      timePeriod: 1,
      openCloseTimePeriod: 1,
      errorPinCode: '',
      errorsAddress: '',
      errorsAddress_2: '',
      userMobile: '',
      errorsState: '',
      errorsCity: '',
    };
    //  this.showMenu = this.showMenu.bind(this);
    //  this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleClosePassword = this.handleClosePassword.bind(this);
    this.handleEditProfile = this.handleEditProfile.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.editRetailerProfile = this.editRetailerProfile.bind(this);
    this.editUserProfile = this.editUserProfile.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.csvLink = React.createRef();
    this.csvLinkUserAction = React.createRef();
    this.csvLinkOpenClose = React.createRef();
    this.handleChangeActionReport = this.handleChangeActionReport.bind(this);
    this.handleChangeOpenCloseReport = this.handleChangeOpenCloseReport.bind(this);
  }

  check(cDate) {
    var today1730 = moment('19:00', 'HH:mm');
    return moment(cDate).isSameOrAfter(today1730);
  }

  handleDownloadReport = async () => {
    let errors = {};
    let diff = [moment(this.state.endDate) - moment(this.state.startDate)];
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (days > 31) {
      errors["dateRange"] = "You can download report for 1 month only. Maximum date range: 31 days)";
    } else {
      // let newDate = {
      //   "fromDate": moment(this.state.startDate).format('YYYY-MM-DD'),
      //   "toDate": moment(this.state.endDate).format('YYYY-MM-DD')
      // }
    }
  };

  fetchOpenCloseReportData = async () => {
    let userRoleId = localStorage.getItem('userRoleId');
    let errors = {};
    let diff = [moment(this.state.openCloseEndDate) - moment(this.state.openCloseStartDate)];
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (userRoleId == 1) {
      if (days > 365) {
        errors["dateRange"] = "You can download report for 12 month only. Maximum date range: 365 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.openCloseStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.openCloseEndDate).format('YYYY-MM-DD')
        };
        await this.props.fetchOpenCloseReportDownloadData(newDate);
      }
    } else {
      if (days > 90) {
        errors["dateRange"] = "You can download report for 12 month only. Maximum date range: 365 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.openCloseStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.openCloseEndDate).format('YYYY-MM-DD')
        };
        await this.props.fetchOpenCloseReportDownloadData(newDate);
      }
    }
  };

  fetchActionReportData = async () => {
    let userRoleId = localStorage.getItem('userRoleId');
    let errors = {};
    let diff = [moment(this.state.actionEndDate) - moment(this.state.actionStartDate)];
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (userRoleId == 1) {
      if (days > 365) {
        alert('You can download report for 12 month only. Maximum date range: 365 days');
        // errors["dateRange"] = "You can download report for 3 month only. Maximum date range: 90 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.actionStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.actionEndDate).format('YYYY-MM-DD')
        };
        await this.props.fetchUserActionReportData(newDate);
      }
    }
    else {
      if (days > 90) {
        alert('You can download report for 3 month only. Maximum date range: 90 days');
        // errors["dateRange"] = "You can download report for 3 month only. Maximum date range: 90 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.actionStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.actionEndDate).format('YYYY-MM-DD')
        };
        await this.props.fetchUserActionReportData(newDate);
      }
    }

  };

  fetchDownloadData = async () => {
    // let errors = {};
    // let diff = [moment(this.state.reportEndDate) - moment(this.state.reportStartDate)];
    // let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    // if (days > 31) {
    //   errors["dateRange"] = "You can download report for 1 month only. Maximum date range: 31 days)";
    // } else {
    // let newDate = {
    //   "fromDate": moment(this.state.reportStartDate).format('YYYY-MM-DD'),
    //   "toDate": moment(this.state.reportEndDate).format('YYYY-MM-DD')
    // }
    await this.props.fetchReportData();
    // setTimeout(() => {
    //   this.csvLink.current.link.click();
    // }, 200)
    // }
  };

  inVoiceReport = async () => {
    await this.props.inVoiceReportData();
    // setTimeout(() => {
    //   this.csvLink.current.link.click();
    // }, 200)
  };

  fetchIVRReportData = async () => {
    let userRoleId = localStorage.getItem('userRoleId');
    let errors = {};
    let diff = [moment(this.state.ivrCloseEndDate) - moment(this.state.ivrCloseStartDate)];
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (userRoleId == 1) {
      if (days > 365) {
        errors["dateRange"] = "You can download report for 12 month only. Maximum date range: 365 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.ivrCloseStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.ivrCloseEndDate).format('YYYY-MM-DD')
        };
        await this.props.ivrReportData(newDate);
      }
    } else {
      if (days > 90) {
        errors["dateRange"] = "You can download report for 3 month only. Maximum date range: 90 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.ivrCloseStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.ivrCloseEndDate).format('YYYY-MM-DD')
        };
        await this.props.ivrReportData(newDate);
      }
    }

  };


  fetchRTLReportData = async () => {
    let userRoleId = localStorage.getItem('userRoleId');
    let errors = {};
    let diff = [moment(this.state.RTLEndDate) - moment(this.state.RTLStartDate)];
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (userRoleId == 1) {
      if (days > 365) {
        errors["dateRange"] = "You can download report for 12 month only. Maximum date range: 365 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.RTLStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.RTLEndDate).format('YYYY-MM-DD')
        };
        await this.props.rtlReportData(newDate);
      }
    } else {
      if (days > 90) {
        errors["dateRange"] = "You can download report for 3 month only. Maximum date range: 90 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.RTLStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.RTLEndDate).format('YYYY-MM-DD')
        };
        await this.props.rtlReportData(newDate);
      }
    }

  };

  LunaReportDownload = async () => {
    let userRoleId = localStorage.getItem('userRoleId');
    let errors = {};
    let diff = [moment(this.state.LunaEndDate) - moment(this.state.LunaStartDate)];
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (userRoleId == 2) {
      if (days > 365) {
        errors["dateRange"] = "You can download report for 12 month only. Maximum date range: 365 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.LunaStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.LunaEndDate).format('YYYY-MM-DD')
        };
        await this.props.lunaReport(newDate);
      }
    } else {
      if (days > 90) {
        errors["dateRange"] = "You can download report for 3 month only. Maximum date range: 90 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.LunaStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.LunaEndDate).format('YYYY-MM-DD')
        };
        await this.props.lunaReport(newDate);
      }
    }

  };

  oosReportDownload = async () => {
    let userRoleId = localStorage.getItem('userRoleId');
    let errors = {};
    let diff = [moment(this.state.oosEndDate) - moment(this.state.oosStartDate)];
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (userRoleId == 2) {
      if (days > 365) {
        errors["dateRange"] = "You can download report for 12 month only. Maximum date range: 365 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.oosStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.oosEndDate).format('YYYY-MM-DD')
        };
        await this.props.oosReport(newDate);
      }
    } else {
      if (days > 90) {
        errors["dateRange"] = "You can download report for 3 month only. Maximum date range: 90 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.oosStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.oosEndDate).format('YYYY-MM-DD')
        };
        await this.props.oosReport(newDate);
      }
    }

  };

  replacemetReport = async () => {
    let userRoleId = localStorage.getItem('userRoleId');
    let errors = {};
    let diff = [moment(this.state.replacementEndDate) - moment(this.state.replaceMentStartDate)];
        let newDate = {
          "fromDate": moment(this.state.replaceMentStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.replacementEndDate).format('YYYY-MM-DD')
        };
        await this.props.fetchReplacementReport(newDate);

  };


  oosGiftReportDownload = async () => {
    let userRoleId = localStorage.getItem('userRoleId');
    let errors = {};
    let diff = [moment(this.state.oosGiftEndDate) - moment(this.state.oosGiftStartDate)];
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (userRoleId == 2) {
      if (days > 365) {
        errors["dateRange"] = "You can download report for 12 month only. Maximum date range: 365 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.oosGiftStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.oosGiftEndDate).format('YYYY-MM-DD')
        };
        await this.props.oosGiftReport(newDate);
      }
    } else {
      if (days > 90) {
        errors["dateRange"] = "You can download report for 3 month only. Maximum date range: 90 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.oosGiftStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.oosGiftEndDate).format('YYYY-MM-DD')
        };
        await this.props.oosGiftReport(newDate);
      }
    }

  };



  fetchNotifyReportData = async () => {

    let userRoleId = localStorage.getItem('userRoleId');
    let errors = {};
    let diff = [moment(this.state.notifyEndDate) - moment(this.state.notifyStartDate)];
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (userRoleId == 1) {
      if (days > 365) {
        errors["dateRange"] = "You can download report for 12 month only. Maximum date range: 365 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.notifyStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.notifyEndDate).format('YYYY-MM-DD')
        };
        await this.props.notifyReport(newDate);
      }
    } else {
      if (days > 90) {
        errors["dateRange"] = "You can download report for 3 month only. Maximum date range: 90 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.notifyStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.notifyEndDate).format('YYYY-MM-DD')
        };
        await this.props.notifyReport(newDate);
      }
    }

  };

  fetchRefundGCReportData = async () => {
    let userRoleId = localStorage.getItem('userRoleId');
    let errors = {};
    let diff = [moment(this.state.refundGCEndDate) - moment(this.state.refundGCStartDate)];
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (userRoleId == 1) {
      if (days > 90) {
        errors["dateRange"] = "You can download report for 3 month only. Maximum date range: 90 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.refundGCStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.refundGCEndDate).format('YYYY-MM-DD')
        };
        await this.props.refundGCReport(newDate);
      }
    } else {

    }

  };

  fetchBulkReportData = async () => {
    let userRoleId = localStorage.getItem('userRoleId');
    let errors = {};
    let diff = [moment(this.state.bulkCloseEndDate) - moment(this.state.bulkCloseStartDate)];
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (userRoleId == 1 || userRoleId == 2) {
      if (days > 365) {
        errors["dateRange"] = "You can download report for 12 month only. Maximum date range: 365 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.bulkCloseStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.bulkCloseEndDate).format('YYYY-MM-DD')
        };
        await this.props.bulkMovementReport(newDate);
      }
    } else {
      if (days > 90) {
        errors["dateRange"] = "You can download report for 3 month only. Maximum date range: 90 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.bulkCloseStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.bulkCloseEndDate).format('YYYY-MM-DD')
        };
        await this.props.bulkMovementReport(newDate);
      }
    }

  };

  fetchScReport = async () => {
    let userRoleId = localStorage.getItem('userRoleId');
    let errors = {};
    let diff = [moment(this.state.scReportEndDate) - moment(this.state.scREportStartDate)];
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (userRoleId == 1) {
      if (days > 365) {
        errors["dateRange"] = "You can download report for 12 month only. Maximum date range: 365 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.scREportStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.scReportEndDate).format('YYYY-MM-DD')
        };
        await this.props.scReport(newDate);
      }
    } else {
      if (days > 90) {
        errors["dateRange"] = "You can download report for 3 month only. Maximum date range: 90 days)";
      } else {
        let newDate = {
          "fromDate": moment(this.state.scREportStartDate).format('YYYY-MM-DD'),
          "toDate": moment(this.state.scReportEndDate).format('YYYY-MM-DD')
        };
        await this.props.scReport(newDate);
      }
    }

  };

  handleClosePassword() {
    this.setState({
      showChangePassword: false
    });
  }

  handleKeyUp = (e) => {
    this.value = e.target.value;
  };

  handleLogout = () => {
    localStorage.removeItem('token');
    document.querySelector(".modal-backdrop").style.display = "none";
    history.push('/');
  };

  handleIconDetails() {
    this.setState({
      logout: !this.state.logout
    });
  }

  onClearForm = (e) => {
    this.setState({
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      mobile: '',
      accessLavel: '',
      reportingTo: '',
      isReporter: '',
      first_name: '',
      last_name: '',
      username: '',
      userMobile: '',
    });
  };

  componentDidMount() {
    // this.getUserList();
    // this.getAccessList();
    // this.getReportingList();
    this.getUserInfo();
    let userRoleId = localStorage.getItem('userRoleId');
    if (window.location.href.indexOf("settings") > -1) {
      if (userRoleId === "6") {
        document.querySelector("body").style.filter = "invert(1)";
        document.querySelector(".log").style.filter = "invert(1)";
        document.querySelector(".list2 span .v").style.filter = "invert(1)";
      }
    }
  }

  componentWillUnmount() {
    document.querySelector("body, .log, .list2 span .v").style.filter = "none";
    document.querySelector(".log").style.filter = "none";
    document.querySelector(".list2 span .v").style.filter = "none";
  }

  getUserInfo = async () => {
    let userId = localStorage.getItem('userId');
    let data = {
      "user_id": userId,
    };
    await this.props.getUserDetail(data);
    let editRes = this.props.userDetail.data;
    let access_level = editRes && editRes.user_role[0] ? editRes.user_role[0].name : 'No Role assigned';
    let reporting = editRes && editRes.reporting && editRes.reporting[0] ? editRes.reporting[0].first_name + ' ' + editRes.reporting[0].last_name : 'No agent assigned';
    let userFName = editRes ? editRes.first_name : "";
    let userLName = editRes ? editRes.last_name : "";
    let email = editRes ? editRes.email : "";
    let userMobileN = editRes ? editRes.mobile : "";
    let userRoleId = localStorage.getItem('userRoleId');
    if (userRoleId === "6") {
      let address = editRes && editRes.address ? editRes.address.address : "";
      let address_2 = editRes && editRes.address ? editRes.address.address_2 : "";
      let pincode = editRes && editRes.address ? editRes.address.pincode : "";
      let state = editRes && editRes.address ? editRes.address.state_name : "";
      let state_id = editRes && editRes.address ? editRes.address.state_id : "";
      let city = editRes && editRes.address ? editRes.address.city : "";
      let city_id = editRes && editRes.address ? editRes.address.city_id : "";
      this.setState({ userName: userFName + ' ' + userLName, email: email, userMobile: userMobileN, accessLevel: access_level, reporting: reporting, firstName: userFName, lastName: userLName, address: address, address_2: address_2, pincode: pincode, state: state, city: city, mobile: userMobileN, state_id: state_id, city_id: city_id });
      if (editRes && editRes.user_role.length > 0 && editRes.user_role[0] && [1, 2].includes(editRes.user_role[0].id)) {
        await this.props.fetchDownloadLimit();
      }
      this.getStateListApi();
      if (editRes.address) {
        this.getCityListApi(this.state.state_id);
      }
    }
    this.setState({ userName: userFName + ' ' + userLName, email: email, userMobile: userMobileN, accessLevel: access_level, reporting: reporting, firstName: userFName, lastName: userLName, mobile: userMobileN, });
    if (editRes && editRes.user_role.length > 0 && editRes.user_role[0] && [1, 2, 5, 7 ,8, 11].includes(editRes.user_role[0].id)) {
      await this.props.fetchDownloadLimit();

    }
  };

  handleValidation() {
    let formIsValid = true;
    this.setState({
      errorsFirstName: "",
      errorsAddress: "",
      errorsAddress_2: "",
      errorsPhone: "",
      errorsLastName: "",
      errorPinCode: "",
      errorsState: "",
      errorsCity: "",
    });
    if (this.state.firstName === '') {
      this.setState({ errorsFirstName: "First name cannot be empty" });
      formIsValid = false;
    }
    if (this.state.firstName !== '') {
      var patternName = new RegExp(/^[ a-zA-Z ]+$/);
      if (!patternName.test(this.state.firstName)) {
        this.setState({ errorsFirstName: "Only characters are allowed" });
        formIsValid = false;
      }
    }
    if (this.state.mobile === '') {
      formIsValid = false;
      this.setState({ errorsPhone: "Phone number cannot be empty" });
    }
    if (this.state.mobile !== '') {
      var pattern = new RegExp(/^[6-9]\d{9}$/);
      if (!pattern.test(this.state.mobile)) {
        // formIsValid = false;
        formIsValid = false;
        this.setState({ errorsPhone: "Enter valid phone number." });
        //  errors["phone"] = "Enter valid phone number.";
        // alert("Enter valid phone number")
      }
      // else {
      //   this.setState({ errorsPhone: null });
      //   formIsValid = true;
      // }
    }
    if (this.state.lastName === '') {
      this.setState({ errorsLastName: "Last name cannot be empty" });
      formIsValid = false;
    }
    if (this.state.lastName !== '') {
      if (!this.state.lastName.match(/^[ a-zA-Z ]+$/)) {
        this.setState({ errorsLastName: "Only characters are allowed" });
        formIsValid = false;
      }
    }
    let userRoleId = localStorage.getItem('userRoleId');
    if (userRoleId === "6") {
      if (this.state.address === '') {
        this.setState({ errorsAddress: "Address 1 cannot be empty" });
        formIsValid = false;
      }
      if (this.state.state_id === 0 || this.state.state_id === "0") {
        this.setState({ errorsState: "State Can't be Empty" });
        formIsValid = false;
      }
      if (this.state.city_id === 0 || this.state.city_id === "0" || this.state.city_id === '') {
        this.setState({ errorsCity: "City Can't Be Empty" });
        formIsValid = false;
      }
      if (this.state.address_2 === '') {
        this.setState({ errorsAddress_2: "Address 2 cannot be empty" });
        formIsValid = false;
      }
      if (this.state.pincode !== '') {
        var pattern = new RegExp(/^[1-9]\d{5}$/);
        if (!pattern.test(this.state.pincode)) {
          // formIsValid = false;
          this.setState({ errorPinCode: "Enter a Valid Pincode ." });
          formIsValid = false;
          //  errors["phone"] = "Enter valid phone number.";
          // alert("Enter valid phone number")
        }
        // else {
        //   this.setState({ errorPinCode: null });
        //   formIsValid = true;
        // }
      }
    }
    return formIsValid;
  }

  editUserProfile = async () => {
    if (this.handleValidation() !== false) {
      let userId = localStorage.getItem('userId');
      let data = {
        "user_id": userId,
        "first_name": this.state.firstName,
        "last_name": this.state.lastName,
        "mobile": this.state.mobile,
        "email": this.state.email,
      };
      await this.props.editUserData(data);
      let editData = this.props.editData;
      if (editData.success) {
        this.setState({ editProfile: false });
        this.setState({
          errorsFirstName: "",
          errorsPhone: "",
          errorsLastName: "",
        });
        alert(editData.message);
        this.getUserInfo();
      } else {
        // alert(editData.message);
      }
    }
  };

  editRetailerProfile = async () => {
    if (this.handleValidation() !== false) {
      let userId = localStorage.getItem('userId');
      let data = {
        "user_id": userId,
        "first_name": this.state.firstName,
        "last_name": this.state.lastName,
        "mobile": this.state.mobile,
        "email": this.state.email,
        "address": this.state.address,
        "address_2": this.state.address_2,
        "state_id": this.state.state_id,
        "city_id": this.state.city_id,
        "pincode": this.state.pincode,
      };
      await this.props.editUserData(data);
      let editData = this.props.editData;
      if (editData.success) {
        this.setState({ editProfileRetailer: false });
        this.setState({
          errorsFirstName: "",
          errorsAddress: "",
          errorsAddress_2: "",
          errorsPhone: "",
          errorsLastName: "",
          errorPinCode: "",
          errorsState: "",
          errorsCity: "",
        });
        let tempAddress = localStorage.getItem('address');
        tempAddress = JSON.parse(tempAddress);
        tempAddress = {
          ...tempAddress,
          ...{
            "address": this.state.address,
            "address_2": this.state.address_2,
            "state_id": this.state.state_id,
            "city_id": this.state.city_id,
            "pincode": this.state.pincode,
            "city": this.state.city,
            "state_name": this.state.state,
            "country_name": "India",
            "country_id": 1
          }
        };
        localStorage.setItem('address', JSON.stringify(tempAddress));
        alert(editData.message);
        this.getUserInfo();
      } else {
        // alert(editData.message);
      }
    }
  };

  changePassword = () => {
    this.setState({ showChangePassword: true });
  };

  togglePassword1 = (e) => {
    const { isPasswordShown1 } = this.state;
    this.setState({ isPasswordShown1: !isPasswordShown1 });
  };

  togglePassword2 = (e) => {
    const { isPasswordShown2 } = this.state;
    this.setState({ isPasswordShown2: !isPasswordShown2 });
  };

  handleChangePassword = async () => {
    let currentPassword = document.getElementById("current_password").value;
    let password = document.getElementById("new_password").value;
    let data = {
      'password': password,
      'currentPassword': currentPassword
    };
    await this.props.resetPassword(data);
  };

  handleEditProfileRetailer = () => {
    this.getUserInfo();
    this.setState({ editProfileRetailer: true });
    //alert("hi")
  };
  handleCronTeleforce = () => {
    this.props.CronTeleforceAction();
  };

  handleEditProfile = () => {
    this.getUserInfo();
    this.setState({ editProfile: true });
    //alert("hi")
  };

  closeEditModalRetailer = () => {
    this.setState({ editProfileRetailer: false });
  };

  closeEditModal = () => {
    this.setState({ editProfile: false });
  };

  closeEditModal = () => {
    this.setState({ editProfile: false });
  };

  handleEdit = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeActionReport(e) {
    this.setState({
      timePeriod: e.target.value
    });
  }

  handleChangeOpenCloseReport(e) {
    this.setState({
      openCloseTimePeriod: e.target.value
    });
  }

  dateSelect = async (value) => {
    this.setState({
      startDate: new Date(value[0]),
      endDate: new Date(value[1]),
      reportsDate: value
    });
  };

  dailyReportDateSelect = async (value) => {
    this.setState({
      isLoading: false,
      reportStartDate: new Date(value[0]),
      reportEndDate: new Date(value[1]),
      reportDate: value
    });
  };

  actionDateSelect = async (value) => {
    this.setState({
      isLoading: false,
      actionStartDate: new Date(value[0]),
      actionEndDate: new Date(value[1]),
      actionDate: value
    });
  };

  openCloseReportDateSelect = async (value) => {
    this.setState({
      isLoading: false,
      openCloseStartDate: new Date(value[0]),
      openCloseEndDate: new Date(value[1]),
      openCloseDate: value
    });
  };

  ivrReportDateSelect = async (value) => {
    this.setState({
      isLoading: false,
      ivrCloseStartDate: new Date(value[0]),
      ivrCloseEndDate: new Date(value[1]),
      // ivrCloseDate: value
    });
  };

  rtlReportDateSelect = async (value) => {
    this.setState({
      isLoading: false,
      RTLStartDate: new Date(value[0]),
      RTLEndDate: new Date(value[1]),
      rtlCloseDate: value
    });
  };

  lunaReportDownload = async (value) => {
    this.setState({
      isLoading: false,
      LunaStartDate: new Date(value[0]),
      LunaEndDate: new Date(value[1]),
      LunaCloseDate: value
    });
  };

  oosDateRange = async (value) => {
    this.setState({
      isLoading: false,
      oosStartDate: new Date(value[0]),
      oosEndDate: new Date(value[1]),
      LunaCloseDate: value
    });
  };

  b2bSetDate = async (value) => {
    this.setState({
      isLoading: false,
      b2bStartDate: new Date(value[0]),
      b2bEndDate: new Date(value[1]),
      LunaCloseDate: value
    });
  };

  oosGiftDateRange = async (value) => {
    this.setState({
      isLoading: false,
      oosGiftStartDate: new Date(value[0]),
      oosGiftEndDate: new Date(value[1]),
      LunaCloseDate: value
    });
  };

  replacementOrderFlow = async (value) => {
    this.setState({
      isLoading: false,
      replaceMentStartDate: new Date(value[0]),
      replacementEndDate: new Date(value[1]),
    });
  };


  b2bReportDownload = async () => {
    let newDate = {
      "fromDate": moment(this.state.b2bStartDate).format('YYYY-MM-DD'),
      "toDate": moment(this.state.b2bEndDate).format('YYYY-MM-DD')
    };
    await this.props.b2bReportAction(newDate);
  };


  notifyReportDateSelect = async (value) => {
    this.setState({
      isLoading: false,
      notifyStartDate: new Date(value[0]),
      notifyEndDate: new Date(value[1]),
      notifyCloseDate: value
    });
  };


  refundGCDateSelect = async (value) => {
    this.setState({
      isLoading: false,
      refundGCStartDate: new Date(value[0]),
      refundGCEndDate: new Date(value[1]),
      refundGCDate: value
    });
  };

  bulkReportDateSelect = async (value) => {
    this.setState({
      isLoading: false,
      bulkCloseStartDate: new Date(value[0]),
      bulkCloseEndDate: new Date(value[1]),
      bulkCloseDate: value
    });
  };

  SCReportDateSelect = async (value) => {
    this.setState({
      isLoading: false,
      scREportStartDate: new Date(value[0]),
      scReportEndDate: new Date(value[1]),
      scReportCloseDate: value
    });
  };

  getStateListApi = async (param) => {
    await this.props.getStateList(param);
    this.setState({ stateList: [] });
    let stateDataList = this.props.stateDataList;
    this.setState({
      stateList: stateDataList.data,
    });
  };

  getCityListApi = async (param) => {
    let data = {
      "state_id": this.state.state_id
    };
    await this.props.getCityByState(data);
    this.setState({ cityList: [] });
    let cityDataList = this.props.cityDataList;
    this.setState({
      cityList: cityDataList.data,
    });
  };

  onCityChange = (e) => {
    this.state.cityList.filter(city => city.id === e.target.value).map(city => (
      this.setState({ city: city.city })
    ));
    this.setState({
      city_id: e.target.value,
    }, () => {
      // this.getStateListApi();
      // this.getCityListApi();
    });
  };

  onStateChange = (e) => {
    this.state.stateList.filter(state => state.id === e.target.value).map(state => (
      this.setState({ state: state.state_name })
    ));
    this.setState({
      country_id: 1, city_id: 0,
      state_id: Number(e.target.value), city: '',
    }, () => {
      // this.getStateListApi();
      this.getCityListApi();
    });
  };

  render() {
    let userRoleId = localStorage.getItem('userRoleId');
    // let list = this.props.userDetail.data
    const { isPasswordShown1, isPasswordShown2 } = this.state;
    // let fileName = "Complaints_" + moment().format('DD/MM/YYYY HH:mm:ss') + ".csv"
    // let actionFileName = "User_Action_Report" + moment().format('DD/MM/YYYY HH:mm:ss') + ".csv"
    // let openCloseFileName = "Open_Close_Report" + moment().format('DD/MM/YYYY HH:mm:ss') + ".csv"
    return (
      <div>
        <div className={userRoleId === '4' ? 'readonlySidebar' : 'sidebar-wrapper'}>
          {userRoleId === '4' &&
            <div className="sidebar">
              <a href="/read-only-user"><img src="/img/nlogo.png" alt='' /></a>
            </div>
          }
          {userRoleId !== '4' && userRoleId !== '6' && window.innerWidth > 600 && window.innerWidth > 600 &&
            <SidebarComponent sideProp={'SS'} />
          }
          <ViewProfile />
        </div>
        {userRoleId === '4' &&
          <div className="readOnly-dashboard">
            <a href="/read-only-user"><i className='fas fa-angle-left'></i>&nbsp;  Back</a>
          </div>
        }
        {userRoleId === '6' &&
          <div className="distributor-dashboard">
            <a href="/complaint-registration"><i className="fas fa-arrow-left"></i>  Back</a>
          </div>
        }
        <div className={userRoleId === '4' || userRoleId === '6' ? 'readonly-user-main content' : 'content manage-user-main'}>
          <span className="complaint-head">{userRoleId == 11 ? "B2B Report" : "Settings"}</span>
          {<ul id="menu">
            <li className="actice2"><a href="#">Profile</a></li>
            {!['11', '6'].includes(userRoleId) && <li ><a href="/service-center">Service Center</a></li>}
          </ul>}
          {this.state.isLoading ?
            <div className='loader'>
              <Loader
                type="Oval"
                color="Grey"
                height={200}
                width={200}
              />
            </div>
            :
            <div style={{ overflow: "hidden !important", height: "500px", width :"100%" }} className="colrow  profile_wrapper">
              <div className="edit col-sm-4">
                <p className="label">Name</p>
                <p className="user_name user_info" id="userName">{this.state.userName}</p>
                <p className="label">Contact</p>
                <p className="user_contact user_info" id="phone">{this.state.userMobile}</p>
                <p className="label">Email</p>
                <p className="name user_info">{this.state.email}</p>
                {/* <p className="label label_password">Password</p> <span onClick={this.changePassword} className="change">Change Password</span>
                <p className="user_password user_info">**********</p> */}
              </div>
              <div className="edit col-sm-4">
                <p className="label">Access Level</p>
                <p className="access_level user_info">{this.state.accessLevel}</p>
                <p className="label">Reporting to</p>
                <p className="reporting user_info">{this.state.reporting}</p>
              </div>
              {userRoleId === '6' ?
                <div id="edit_userInfo" className="col-sm-4 edit">
                  <button type="button" onClick={this.handleEditProfileRetailer} className="edit-user-btn">
                    Edit Profile
                  </button>
                  <br></br>
                </div> :
                <div id="edit_userInfo" className="col-sm-4 edit">
                  {userRoleId == 1 ? <button style={{ marginRight: "15px" }} type="button" onClick={this.handleCronTeleforce} className="edit-user-btn">
                    Agent Cron
                  </button> : null}
                  {userRoleId != 11 && <button type="button" onClick={this.handleEditProfile} className="edit-user-btn">
                    Edit Profile
                  </button>}
                  <br></br>
                </div>
              }
              <div className='download-wrapper'>
                {/* Daily Download Button Code */}
                {
                  this.props.reportConfig.type && this.props.userDetail.data && this.props.userDetail.data.user_role.length > 0 && [1, 2].includes(this.props.userDetail.data.user_role[0].id) ?
                    <div className='report_download col-sm-4 setBorder'>
                      <br></br>
                      <button className='edit-user-btn' onClick={this.fetchDownloadData} >Daily Download Report</button>
                      <div className="maxDlimit">
                        <span style={{ color: "white" }}>Max-Limt {this.props.reportConfig.limit},   </span>
                        <span style={{ color: "#eb951f" }}>{this.props.reportConfig.leftCount} left</span>
                      </div>
                    </div> 
                    : null
                }
                {/* Action Report Download */}
                {
                  this.props.fetchActionReportConfig.type && this.props.userDetail.data && this.props.userDetail.data.user_role.length > 0 && [1, 2].includes(this.props.userDetail.data.user_role[0].id) && this.props.userDetail.data.team === "CS" ?
                    <div className='report_download col-sm-4'>
                      {userRoleId == 1 ?
                        <div className="settingDate">
                          <DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(new Date(new Date().setDate(new Date().getDate() - 365))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.actionDateSelect(value); }} value={[new Date(this.state.actionStartDate), new Date(this.state.actionEndDate)]} />

                        </div>
                        :
                        <div className="settingDate">
                          <DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(new Date(new Date().setDate(new Date().getDate() - 90))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.actionDateSelect(value); }} value={[new Date(this.state.actionStartDate), new Date(this.state.actionEndDate)]} />

                        </div>}
                      <br></br>
                      <button className='edit-user-btn' onClick={this.fetchActionReportData} >Download Action Report</button>
                      <div className="maxDlimit">
                        <span style={{ color: "white" }}>Max-Limit {this.props.fetchActionReportConfig.limit},{"    "}   </span>
                        <span style={{ color: "#eb951f" }}>{this.props.fetchActionReportConfig.leftCount} left</span>
                      </div>
                    </div>
                     : null
                }
                 {/* Open And Close Report Download */}
                 {
                  this.props.fetchOpenCloseReportConfig.type && this.props.userDetail.data && this.props.userDetail.data.user_role.length > 0 && [1, 2].includes(this.props.userDetail.data.user_role[0].id) && this.props.userDetail.data.team === "CS" ?
                    <div className='report_download col-sm-4'>
                      {<div className="settingDate">
                        <DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(new Date(userRoleId == 1 ? new Date().setDate(new Date().getDate() - 365) : new Date().setDate(new Date().getDate() - 90))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.openCloseReportDateSelect(value); }} value={[new Date(this.state.openCloseStartDate), new Date(this.state.openCloseEndDate)]} />
                      </div>}
                      <br></br>
                      <button className='edit-user-btn' onClick={this.fetchOpenCloseReportData}>Download Open Close Report</button>
                      <div className="maxDlimit">
                        <span style={{ color: "white" }} className='mtext'>Max-Limt {this.props.fetchOpenCloseReportConfig.limit},   </span>
                        <span style={{ color: "#eb951f" }}>{this.props.fetchOpenCloseReportConfig.leftCount} left</span>
                      </div>
                    </div> : null
                }
                
                {/* Invoice Report Button  */}
                {
                  this.props.invoiceReportConfig.type && this.props.userDetail.data && this.props.userDetail.data.user_role.length > 0 && [1, 2].includes(this.props.userDetail.data.user_role[0].id) && this.props.userDetail.data.team === "CS" ?
                    <div className='report_download col-sm-4 setBorder'>
                      <br></br>
                      <button className='edit-user-btn' onClick={this.inVoiceReport} >Download InVoice Report</button>
                      <div className="maxDlimit">
                        <span style={{ color: "white" }}>Max-Limt {this.props.invoiceReportConfig.limit},   </span>
                        <span style={{ color: "#eb951f" }}>{this.props.invoiceReportConfig.leftCount} left</span>
                      </div>
                    </div> : null
                }
                 {/* IVR Report Download */}
                 {
                  this.props.ivrReportConfig.type && this.props.userDetail.data && this.props.userDetail.data.user_role.length > 0 && [1, 2].includes(this.props.userDetail.data.user_role[0].id) && this.props.userDetail.data.team === "CS" ?
                    <div className='report_download col-sm-4'>
                      <div className="settingDate"><DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(userRoleId == 1 ? new Date(new Date().setDate(new Date().getDate() - 365)) : new Date(new Date().setDate(new Date().getDate() - 90))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.ivrReportDateSelect(value); }} value={[new Date(this.state.ivrCloseStartDate), new Date(this.state.ivrCloseEndDate)]} />
                      </div>
                      <br></br>
                      <button className='edit-user-btn' onClick={this.fetchIVRReportData}>Download IVR Close Report</button>
                      <div className="maxDlimit">
                        <span style={{ color: "white" }} className='mtext'>Max-Limt {this.props.ivrReportConfig.limit},   </span>
                        <span style={{ color: "#eb951f" }}>{this.props.ivrReportConfig.leftCount} left</span>
                      </div>
                    </div> : null
                }
                {/* Movement Report */}
                 {
                  this.props.bulkReportConfig.type && this.props.userDetail.data && this.props.userDetail.data.user_role.length > 0 && [1, 2].includes(this.props.userDetail.data.user_role[0].id) && this.props.userDetail.data.team === "CS" ?
                    <div className='report_download col-sm-4'>
                      <div className="settingDate"><DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(["1", "2"].includes(userRoleId) ? new Date(new Date().setDate(new Date().getDate() - 365)) : new Date(new Date().setDate(new Date().getDate() - 90))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.bulkReportDateSelect(value); }} value={[new Date(this.state.bulkCloseStartDate), new Date(this.state.bulkCloseEndDate)]} />
                      </div>
                      <br></br>
                      <button className='edit-user-btn' onClick={this.fetchBulkReportData}>Download Movement Report</button>
                      <div className="maxDlimit">
                        <span style={{ color: "white" }} className='mtext'>Max-Limt {this.props.bulkReportConfig.limit},   </span>
                        <span style={{ color: "#eb951f" }}>{this.props.bulkReportConfig.leftCount} left</span>
                      </div>
                    </div> : null
                }
                 {/* Refund GC Report*/}
                 {
                  this.props.refundGCConfig.type && this.props.userDetail.data && this.props.userDetail.data.user_role.length > 0 && [1].includes(this.props.userDetail.data.user_role[0].id) ?
                    <div className='report_download col-sm-4'>
                      <div className="settingDate"><DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(userRoleId == 1 ? new Date(new Date().setDate(new Date().getDate() - 365)) : new Date(new Date().setDate(new Date().getDate() - 90))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.refundGCDateSelect(value); }} value={[new Date(this.state.refundGCStartDate), new Date(this.state.refundGCEndDate)]} />
                      </div>
                      <br></br>
                      <button className='edit-user-btn' onClick={this.fetchRefundGCReportData}>Download Refund GC Report</button>
                      <div className="maxDlimit">
                        <span style={{ color: "white" }} className='mtext'>Max-Limt {this.props.refundGCConfig.limit},   </span>
                        <span style={{ color: "#eb951f" }}>{this.props.refundGCConfig.leftCount} left</span>
                      </div>
                    </div> : null
                }
                {/* sc config Report*/}
                {
                  this.props.scConfig.type && this.props.userDetail.data && this.props.userDetail.data.user_role.length > 0 && [1, 2].includes(this.props.userDetail.data.user_role[0].id) ?
                    <div className='report_download col-sm-4'>
                      <div className="settingDate"><DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(userRoleId == 1 ? new Date(new Date().setDate(new Date().getDate() - 365)) : new Date(new Date().setDate(new Date().getDate() - 90))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.SCReportDateSelect(value); }} value={[new Date(this.state.scREportStartDate), new Date(this.state.scReportEndDate)]} />
                      </div>
                      <br></br>
                      <button className='edit-user-btn' onClick={this.fetchScReport}>Download SC Report</button>
                      <div className="maxDlimit">
                        <span style={{ color: "white" }} className='mtext'>Max-Limt {this.props.scConfig.limit},   </span>
                        <span style={{ color: "#eb951f" }}>{this.props.scConfig.leftCount} left</span>
                      </div>
                    </div>
                    : null
                }
                 {/* RTL Report Download */}
                 {
                  this.props.rtlReportConfig.type && this.props.userDetail.data && this.props.userDetail.data.user_role.length > 0 && [1, 2].includes(this.props.userDetail.data.user_role[0].id) ?
                    <div className='report_download col-sm-4'>
                      <div className="settingDate"><DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(userRoleId == 1 ? new Date(new Date().setDate(new Date().getDate() - 365)) : new Date(new Date().setDate(new Date().getDate() - 90))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.rtlReportDateSelect(value); }} value={[new Date(this.state.RTLStartDate), new Date(this.state.RTLEndDate)]} />
                      </div>
                      <br></br>
                      <button className='edit-user-btn' onClick={this.fetchRTLReportData}>Download RTL Report</button>
                      <div className="maxDlimit">
                        <span style={{ color: "white" }} className='mtext'>Max-Limt {this.props.rtlReportConfig.limit},   </span>
                        <span style={{ color: "#eb951f" }}>{this.props.rtlReportConfig.leftCount} left</span>
                      </div>
                    </div>
                    : null
                }
                  {/* Notify Report Download */}
                  {
                  this.props.notifyReportConfig.type && this.props.userDetail.data && this.props.userDetail.data.user_role.length > 0 && [1, 2].includes(this.props.userDetail.data.user_role[0].id) ?
                    <div className='report_download col-sm-4'>
                      <div className="settingDate" ><DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(userRoleId == 1 ? new Date(new Date().setDate(new Date().getDate() - 365)) : new Date(new Date().setDate(new Date().getDate() - 90))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.notifyReportDateSelect(value); }} value={[new Date(this.state.notifyStartDate), new Date(this.state.notifyEndDate)]} />
                      </div>
                      <br></br>
                      <button className='edit-user-btn' onClick={this.fetchNotifyReportData}>Notify report download</button>
                      <div className="maxDlimit">
                        <span style={{ color: "white" }} className='mtext'>Max-Limt {this.props.notifyReportConfig.limit},   </span>
                        <span style={{ color: "#eb951f" }}>{this.props.notifyReportConfig.leftCount} left</span>
                      </div>
                    </div>
                    : null
                }
                
                 {/* Luna Report Download */}
                 {
                  userRoleId != 11 && userRoleId != 6 ?
                    <div className='report_download col-sm-4'>
                      <div className="settingDate">
                        <DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(userRoleId == 2 ? new Date(new Date().setDate(new Date().getDate() - 365)) : new Date(new Date().setDate(new Date().getDate() - 90))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.lunaReportDownload(value); }} value={[new Date(this.state.LunaStartDate), new Date(this.state.LunaEndDate)]} />
                      </div>
                      <br></br>
                      <button className='edit-user-btn' onClick={this.LunaReportDownload}>LUNA Notify Report</button>
                      <div className="maxDlimit">
                        <span style={{ color: "white" }} className='mtext'>Max-Limt {this.props.lunaReportConfig.limit},   </span>
                        <span style={{ color: "#eb951f" }}>{this.props.lunaReportConfig.leftCount} left</span>
                      </div>
                    </div>
                    : null
                }
                  {
                  userRoleId == 11 ?
                    <div className='report_download col-sm-4'>
                      <div className="settingDate">
                        <DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(new Date(new Date().setDate(new Date().getDate() - 182))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.b2bSetDate(value); }} value={[new Date(this.state.b2bStartDate), new Date(this.state.b2bEndDate)]} />
                      </div>
                      <br></br>
                      <button className='edit-user-btn' onClick={this.b2bReportDownload}> B2B Report</button>
                      <div className="maxDlimit">
                        <span style={{ color: "white" }} className='mtext'>Max-Limt {this.props.b2bReportConfig.limit},   </span>
                        <span style={{ color: "#eb951f" }}>{this.props.b2bReportConfig.leftCount}left</span>
                      </div>
                    </div>
                    : null
                }
                 {/* OOS Report Download */}
                 {
                  this.props.oosReportConfig.type && this.props.userDetail.data && this.props.userDetail.data.user_role.length > 0 && [1, 2, 5, 7, 8].includes(this.props.userDetail.data.user_role[0].id) ?
                    <div className='report_download col-sm-4'>
                      <div className="settingDate"><DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(userRoleId == 1 ? new Date(new Date().setDate(new Date().getDate() - 365)) : new Date(new Date().setDate(new Date().getDate() - 90))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.oosDateRange(value); }} value={[new Date(this.state.oosStartDate), new Date(this.state.oosEndDate)]} />
                      </div>
                      <br></br>
                      <button className='edit-user-btn' onClick={this.oosReportDownload}>OOS Report</button>
                      <div className="maxDlimit">
                        <span style={{ color: "white" }} className='mtext'>Max-Limt {this.props.oosReportConfig.limit},</span>
                        <span style={{ color: "#eb951f" }}>{this.props.oosReportConfig.leftCount} left</span>
                      </div>
                    </div>
                    : null
                }
                 {/* OOS Gift Report Download */}
                 {
                  // this.props.oosGiftReportConfig.type && this.props.userDetail.data && this.props.userDetail.data.user_role.length > 0 && [1,2].includes(this.props.userDetail.data.user_role[0].id) ?
                    <div className='report_download col-sm-4'>
                      <div className="settingDate"><DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(new Date(new Date().setDate(new Date().getDate() - 365))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.oosGiftDateRange(value); }} value={[new Date(this.state.oosStartDate), new Date(this.state.oosEndDate)]} />
                      </div>
                      <br></br>
                      <button className='edit-user-btn' onClick={this.oosGiftReportDownload}>OOS Gift Report</button>
                      <div className="maxDlimit">
                        <span style={{ color: "white" }} className='mtext'>Max-Limt {this.props.oosGiftReportConfig.limit},</span>
                        <span style={{ color: "#eb951f" }}>{this.props.oosGiftReportConfig.leftCount} left</span>
                      </div>
                    </div>
                    // : null
                }
                {/* Replacement Report Download */}
                {
                  // this.props.oosGiftReportConfig.type && this.props.userDetail.data && this.props.userDetail.data.user_role.length > 0 && [1,2].includes(this.props.userDetail.data.user_role[0].id) ?
                    <div className='report_download col-sm-4'>
                      <div className="settingDate"><DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(new Date(new Date().setDate(new Date().getDate() - 365))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.replacementOrderFlow(value); }} value={[new Date(this.state.replaceMentStartDate), new Date(this.state.replacementEndDate)]} />
                      </div>
                      <br></br>
                      <button className='edit-user-btn' onClick={this.replacemetReport}>Replacement Orders</button>
                      <div className="maxDlimit">
                        <span style={{ color: "white" }} className='mtext'>Max-Limt {this.props.replacementOrderConfig.limit},</span>
                        <span style={{ color: "#eb951f" }}>{this.props.replacementOrderConfig.leftCount} left</span>
                      </div>
                    </div>
                    // : null
                }
              </div>
            </div>
          }
        </div>
        <ReactModal
          isOpen={this.state.editProfile}
          contentLabel="Minimal Modal Example"
          style={editStyle}
          ariaHideApp={false}
          className="modal-header edit_profile_modal"
        >
          <div className="div">
            <h2 id="rModal_head">Edit Profile</h2>
            <button id="close_rModal" onClick={this.closeEditModal}><i className="fas fa-times"></i></button>
          </div>
          <div className="div">
            <label>
              First Name
            </label>
            <input id="first_name" value={this.state.firstName} name="firstName" onChange={this.handleEdit} />
            <div className="errorpd">
              <span className="errorShow">{this.state.errorsFirstName}</span>
            </div>
          </div>
          <div className="div">
            <label>
              Last Name
            </label>
            <input id="last_name" value={this.state.lastName} name="lastName" onChange={this.handleEdit} />
            <div className="errorpd">
              <span className="errorShow">{this.state.errorsLastName}</span>
            </div>
          </div>
          <div className="divc">
            <label>
              Contact Number
            </label>
            <input id="contact_number" value={this.state.mobile} name="mobile" onChange={(e) => this.handleEdit(e)} maxLength={10} type="text" />
            <div className="errorpd">
              <span className="errorShow">{this.state.errorsPhone}</span>
            </div>
          </div>
          <div className="div">
            <div className="addbtn" onClick={this.editUserProfile}><button>Update Changes</button></div>
          </div>
        </ReactModal >
        <ReactModal
          isOpen={this.state.editProfileRetailer}
          contentLabel="Minimal Modal Example"
          style={editStyle}
          ariaHideApp={false}
          className="modal-header edit_profile_modal"
        >
          <div className="div">
            <h2 id="rModal_head">Edit Profile</h2>
            <button id="close_rModal" onClick={this.closeEditModalRetailer}><i className="fas fa-times"></i></button>
          </div>
          <div className="div">
            <label>
              First Name
            </label>
            <input id="first_name" value={this.state.firstName} name="firstName" onChange={this.handleEdit} />
            <div className="errorpd">
              <span className="errorShow">{this.state.errorsFirstName}</span>
            </div>
          </div>
          <div className="div">
            <label>
              Last Name
            </label>
            <input id="last_name" value={this.state.lastName} name="lastName" onChange={this.handleEdit} />
            <div className="errorpd">
              <span className="errorShow">{this.state.errorsLastName}</span>
            </div>
          </div>
          <div className="divc">
            <label>
              Contact Number
            </label>
            <input id="contact_number" value={this.state.mobile} name="mobile" onChange={(e) => this.handleEdit(e)} maxLength={10} type="text" />
            <div className="errorpd">
              <span className="errorShow">{this.state.errorsPhone}</span>
            </div>
          </div>
          <div className='div20'>
            <label>
              Address
            </label>
            <input id='address' style={{ "margin-bottom": "25px" }} value={this.state.address} name="address" onChange={e => this.handleEdit(e)} maxLength={50} type="text" />
            <div className="errorpd">
              <span className="errorShow">{this.state.errorsAddress}</span>
            </div>
          </div>
          <div className='div20' >
            <label>
              Address_2
            </label>
            <input id='address_2' style={{ "margin-bottom": "25px", color: 'red' }} value={this.state.address_2} name="address_2" onChange={e => this.handleEdit(e)} maxLength={50} type="text" />
            <div className="errorpd">
              <span className="errorShow">{this.state.errorsAddress_2}</span>
            </div>
          </div>
          <div className='div'>
            <label>
              State
            </label>
            <select className="dropbtn1111" onChange={(e) => this.onStateChange(e)} name="state">
              <option value={'0'}>-- Select State --</option>
              {this.state.stateList.map((obj) => (
                <option value={obj.id} selected={Number(obj.id) === Number(this.state.state_id)}>{obj.state_name}</option>
              ))}
            </select>
            <div className="errorpd">
              <span className="errorShow">{this.state.errorsState}</span>
            </div>
          </div>
          <div className="div">
            <label>
              City
            </label>
            <select className="dropbtn1111" id="select_city" onChange={(e) => this.onCityChange(e)}>
              {/* <i className="fa fa-angle-down" /> */}
              <option value={'0'}>-- Select City --</option>
              {this.state.cityList?.map((obj) => (
                <option value={obj.id} selected={Number(obj.id) === Number(this.state.city_id)}>{obj.city}</option>
              ))}
            </select>
            <div className="errorpd">
              <span className="errorShow">{this.state.errorsCity}</span>
            </div>
          </div>
          <div className="div">
            <label>
              Zip-Code
            </label>
            <input id="pincode" value={this.state.pincode} name="pincode" onChange={e => this.handleEdit(e)} maxLength={6} type="text" />
            <div className="errorpd">
              <span className="errorShow">{this.state.errorPinCode}</span>
            </div>
          </div>
          <div className="div">
            <div className="addbtn" onClick={this.editRetailerProfile}><button>Update Changes</button></div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={this.state.showChangePassword}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          ariaHideApp={false}
          className="modal-header"
        >
          <div className="password_wrapper">
            <h2 id="rModal_head">Change Password</h2>
            <button id="close_rModal" onClick={this.handleClosePassword}><i className="fas fa-times"></i></button>
            <div className="div">
              <label>
                Current Password <sup>*</sup>
              </label>
              <input className='input_password' id="current_password" type={isPasswordShown1 ? "text" : "password"} maxLength={8}>
              </input><i className={`fa ${isPasswordShown1 ? "fa-eye" : "fa-eye-slash"} password-icon`} aria-hidden="true" onClick={(e) => this.togglePassword1(e)} ></i>
            </div>
            <div className="div">
              <label>
                New Password <sup>*</sup>
              </label>
              <input className='input_password' id="new_password" type={isPasswordShown2 ? "text" : "password"} maxLength={8}>
              </input><i className={`fa ${isPasswordShown2 ? "fa-eye" : "fa-eye-slash"} password-icon`} aria-hidden="true" onClick={(e) => this.togglePassword2(e)} ></i>
            </div>
            <button id="send_rModal" type="button" onClick={this.handleChangePassword}>Update Password</button>
          </div>
        </ReactModal>
        {
          this.state.loader &&
          <div className='loader user-loader'>
            <Loader type="Oval" color="Grey" height={200} width={200} />
          </div>
        }
      </div >
    );
  }
}

const mapStateToProps = state => ({
  userDetail: state.user.userDetail,
  userLogin: state.user.userLogin,
  editData: state.user.editData,
  dailyReportData: state.report.dailyReportData,
  actionReportData: state.report.actionReportData,
  openCloseReportData: state.report.openCloseReportData,
  reportConfig: state.report.reportConfig,
  fetchActionReportConfig: state.report.fetchActionReportConfig,
  fetchOpenCloseReportConfig: state.report.fetchOpenCloseReportConfig,
  invoiceReportConfig: state.report.invoiceReportConfig,
  ivrReportConfig: state.report.ivrReportConfig,
  rtlReportConfig: state.report.rtlReportConfig,
  manifestConfig: state.report.manifestReportConfig,
  stateDataList: state.user.stateData,
  cityDataList: state.user.cityData,
  bulkReportConfig: state.report.bulkReportConfig,
  refundGCConfig: state.report.refundGCConfig,
  scConfig: state.report.scConfig,
  notifyReportConfig: state.report.notifyReportConfig,
  lunaReportConfig: state.report.lunaReportConfig,
  oosReportConfig: state.report.oosReportConfig,
  oosGiftReportConfig: state.report.oosGiftReportConfig,
  b2bReportConfig: state.report.b2bReportConfig,
  replacementOrderConfig: state.report.replacementOrderConfig
});

export default connect(mapStateToProps, {
  resetPassword, getUserDetail, editUserData, fetchReportData, fetchDownloadLimit, fetchActionReportLimit,
  fetchUserActionReportData, fetchOpenCloseReportDownloadData, fetchOpenCloseReportLimit, inVoiceReportData,fetchReplacementReport,
  ivrReportData, getStateList, getCityByState, bulkMovementReport, refundGCReport, scReport, CronTeleforceAction, rtlReportData, notifyReport, lunaReport, oosReport, oosGiftReport,b2bReportAction
})(Settings);