import React, { Component } from 'react'
import { connect } from "react-redux";
import moment from 'moment'

class History extends Component {

 
  render() {
    return (
      <div className="history">
          {
            this.props.complaintDetail.history.map((obj) => 
            (
              <div className="history_wrapper" key={obj.id}>
                <p className="date">{moment(obj.created_at).format('MMMM DD, YYYY || hh:mm:ss A')}</p>
                <p className="last_worked">{obj.user ? "Last worked by " + obj.user.first_name : null} || <span>{obj.comments}</span> || <span>{obj.complaint_text && obj.complaint_text.comment}</span>
                {obj.complaint_text.resolution_status && 
                <span> || Resolution Status: {obj.complaint_text.resolution_status} 
                {obj.complaint_text.upgradeProduct && <span> (upgraded with: {obj.complaint_text.upgradeProduct.product_name}{obj.complaint_text.upgradeProduct.size ? ("Size " + obj.complaint_text.upgradeProduct.size) : null}{obj.complaint_text.upgradeProduct.color_name})</span>}
                {obj.complaint_text.replace_accessory && 
                <span> (replaced with: {obj.complaint_text.replace_accessory})</span>
                }
                </span>
                
                }
               
               
                </p>
              </div>
            )
            // {
// {/* <div>dshdfcjfg jgh</div> */}
            // <h2>{obj.id}</h2>
              // <div className="cm1">
              //   <h1> Anshul</h1>
              //   <h1>{moment(obj.created_at).format('MMMM DD, YYYY || hh:mm:ss A')}</h1>
              //   <p>Last worked by </p>
              //   <p></p>
              // </div>
            // }
            )
          }

        </div>

    )
  }
}

const mapStateToProps = state => ({
  complaintDetail: state.complaints.complaintsDetail,
})
export default connect(mapStateToProps, null)(History);