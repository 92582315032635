import React, { Component } from 'react';
import ReactModal from 'react-modal';
import {
  getDistributorData, showComplaintDetailsAction, getDistributorComplaintDetail, addStatusId, getComplaintBuckets,
  onSaveTicket, callCustomer, getRPUCourier, onCloseTicket, onSelfShip, onReversePickupCancel,
  MoveToComplaintClosedClick, moveToRtoOutClick, fetchPrintLabel, revokeTicket, onPickedUp, MoveToOutOfStockClick,
  sendEmail, movedToPickup, moveToIQCClick, distributorScreening, sendBusyEmail, movedToReceive,
  movedToHandover
} from '../../actions/distributorAction';
import { connect } from "react-redux";
// import ReactToPrint from "react-to-print";
// import history from '../../history';
// import { Redirect } from 'react-router-dom';
import ComponentToRPUPrint from './ComponentToRPUPrint';
import ComponentToFPUPrint from './ComponentToFPUPrint';
import RTLComponentPrint from './RTLComponentPrint';
// import Loader from 'react-loader-spinner'

// const moment = require('moment');

const customStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    inset: '8% 0 0 auto',
    marginRight: '0',
    transform: 'translate(0)',
    background: '#000',
    border: 'none',
    width: '35%',
    position: 'absolute',
    height: '100%'
  },
};

// const customStylesPrint = {
//   overlay: {
//     background: 'rgba(0, 0, 0, 0.5)',
//     overflow: "scroll"
//   },
//   content: {
//     position: 'absolute',
//     inset: 'auto',
//     background: 'rgb(0, 0, 0)',
//     overflow: "auto",
//     borderRadius: "4px",
//     outline: "none",
//     padding: "2rem",
//     marginRight: "0px",
//     transform: "translate(0px)",
//     width: "100%",
//     overflow: "scroll"
//   },
// }

const customStylesRPU = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    overflow: "scroll"
  },
  content: {
    inset: '8% 0 0 auto',
    marginRight: '0',
    transform: 'translate(0)',
    background: '#fff',
    padding: '2rem',
    border: 'none',
    width: '100%',
    position: 'absolute',
    overflow: 'scroll',
  },
}

const screeningPrintLabel = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    overflow: "scroll"
  },
  content: {
    inset: '8% 25% 0 25%',
    transform: 'translate(0)',
    background: '#fff',
    padding: '2rem',
    border: 'none',
    width: '50%',
    height: '50%',
    position: 'absolute',
    overflow: 'scroll',
  },
}

// Result 
let result = []

class TicketReceived extends Component {
  constructor() {
    super();
    this.state = {
      showEditTicket: false,
      showRPUModal: false,
      showCallReminder: false,
      searchText: '',
      startDate: new Date().setDate(new Date().getDate() - 90),
      endDate: new Date().setDate(new Date().getDate()),
      current_page: 1,
      teamName: "CS",
      bucket_id: "1",
      sla_breached: null,
      complaint_id: '',
      ticketId: '',
      isWarranty: 0,
      today: '',
      callReminderDate: '',
      callReminderTime: '',
      time: '',
      errors: [],
      complaintId: 0,
      type: '',
      tracking: '',
      courier_id: '',
      showScreeningModal: false,
      showRPUPrintModal: false,
      showFPUModal: false,
      showFPUPrintModal: false,
      // showRPUModal: false,
      isLoadingGif: false,
      courierId: 0,
      productArr: [],
      index: 0,
      infoArr: [],
      screenOptionsArray: [
        { name: "Strap Received", key: "strap_received", isChecked: false },
        { name: "Watch Dial Received", key: "watch_dial_recieved", isChecked: false },
        { name: "Charger Cable Received", key: "charger_cable_received", isChecked: false },
        { name: "Wrong Product Received", key: "wrong_product_received", isChecked: false },
        { name: "Empty box Received", key: "empty_box_received", isChecked: false },
        { name: "Damaged Earbuds Received", key: "damaged_earbuds_received", isChecked: false },
        { name: "Complete Product Received", key: "complete_product_received", isChecked: false },
        { name: "Mismatched SKU Received", key: "mismatched_sku_received", isChecked: false },
        { name: "Partial Product Received", key: "partial_product_received", isChecked: false },
        { name: "Damage Product Received", key: "damage_product_received", isChecked: false },
        { name: "Missing Charger", key: "missing_charger", isChecked: false },
        { name: "One Ear Buds Missing", key: "one_ear_buds_missing", isChecked: false },
        { name: "One Ear Buds Damage Recieved", key: "one_ear_buds_damage_recieved", isChecked: false },
        { name: "USB Cable Missing", key: "usb_cable_missing", isChecked: false },
        { name: "AUX & USB Cable Missing", key: "aux_and_usb_cable_missing", isChecked: false },
        { name: "Strap Damage Recieved ", key: "strap_damage_recieved", isChecked: false },
        { name: "One Side Damage Neckband Received", key: "one_side_damage_neckband_received", isChecked: false },
        { name: "Scratch Watch Display Received", key: "scratch_watch_display_received", isChecked: false },
        { name: "Extra Strap & USB Cable & Charging", key: "extra_strap_&_usb_cable_&_charging", isChecked: false },
        { name: "Both Earbuds Missing", key: "both_earbuds_missing", isChecked: false },
        { name: "Charging Case Missing", key: "charging_case_missing", isChecked: false },
        { name: "Watch Charging Pin Rusted", key: "watch_charging_rin_rusted", isChecked: false },
        { name: "Both Earbuds Received", key: "both_earbuds_received", isChecked: false },
        { name: "Charging Case Received", key: "charging_case_received", isChecked: false },
        { name: "Watch Crown Missing", key: "watch_crown_missing", isChecked: false },
        { name: "Strap Missing", key: "strap_missing", isChecked: false },
        { name: "One Earbuds Received", key: "one_earbuds_received", isChecked: false },
        { name: "Watch Dial Missing", key: "watch_dial_missing", isChecked: false },
        { name: "Watch Dial Damage Received", key: "watch_dial_damage_received", isChecked: false },
        { name: "Wrong Strap Received", key: "wrong_strap_received", isChecked: false },
      ],
      showNext: true,
      showSubmit: false,
      showPrev: false,
      showPrintPreview: false,
      isLoading: false
    };
    this.handleRPUPrint = this.handleRPUPrint.bind(this);
    this.handlePrintModalClose = this.handlePrintModalClose.bind(this);
    this.handleRPUOpenModal = this.handleRPUOpenModal.bind(this);
    this.onRPUClick = this.onRPUClick.bind(this);
    this.handleSendEmail = this.handleSendEmail.bind(this);
    // this.handleRPUSubmit = this.handleRPUSubmit.bind(this)\
    this.handleRPUModalClose = this.handleRPUModalClose.bind(this)
  }

  closeScreeningModal = () => {
    this.setState({ showPrintPreview: false });
    this.props.handleRefreshList();
  }

  onSaveClick = async (e, item) => {
    e.preventDefault()
    this.setState({ isLoading: true })
    let data = {
      'complaint_id': item
    }
    await this.props.onSaveTicket(data)
    this.setState({ isLoading: false })
    this.props.handleRefreshList()
  }

  onMoveToComplaintClosedClick = async (e, item) => {
    e.preventDefault()
    this.setState({ isLoading: true })
    let data = {
      'complaint_id': item,
    }
    await this.props.onCloseTicket(data)
    this.setState({ isLoading: false })
    this.props.handleRefreshList()
  }

  onMoveToSelfShipClick = async (e, item) => {
    e.preventDefault()
    this.setState({ isLoading: true })
    let data = {
      'complaint_id': item,
    }
    await this.props.onSelfShip(data)
    this.setState({ isLoading: false })
    this.props.handleRefreshList()
  }

  handleReversePickupCancel = async (e, item) => {
    e.preventDefault()
    this.setState({ isLoading: true })
    let data = {
      "complaint_id": item,
      "type": "reverse_pick_up"
    }
    await this.props.onReversePickupCancel(data)
    this.setState({ isLoading: false })
    this.props.handleRefreshList()
  }

  handleForwardPickupCancel = async (e, item) => {
    e.preventDefault()
    this.setState({ isLoading: true })
    let data = {
      "complaint_id": item,
      "type": "forward_pick_up"
    }
    await this.props.onReversePickupCancel(data)
    this.setState({ isLoading: false })
    this.props.handleRefreshList()
  }

  // handleOutward = async (e, item) => {
  //   this.setState({ isLoading: true })
  //   e.preventDefault()
  //   let data = {
  //     "complaint_id": item,
  //     "type": "forward_pick_up"
  //   }
  //   await this.props.onReversePickupCancel(data)
  //   this.setState({ isLoading: false })
  //   this.props.handleRefreshList()
  // }

  onMoveToOutOfStockClick = async (e, item) => {
    this.setState({ isLoading: true })
    e.preventDefault()
    let data = {
      'complaint_id': item
    }
    await this.props.MoveToOutOfStockClick(data)
    this.setState({ isLoading: false })
    this.props.handleRefreshList()
  }

  onPhoneClick = async (e, distriburotId, complaintId) => {
    e.preventDefault()
    this.setState({ isLoading: true })
    await this.props.callCustomer(distriburotId, complaintId)
    this.setState({ isLoading: false })
    this.props.handleRefreshList()
  }

  onRPUClick = async () => {
    this.props.handleLoader();
    let data = {
      "complaint_id": this.props.distributorComplainDetails.id,
      "type": "reverse_pick_up"
    }
    await this.props.getRPUCourier(data)
    this.props.handleLoader();
    this.props.handleRefreshList()
  }

  onMoveToRtoInClick = async (e, item) => {
    e.preventDefault()
    this.setState({ isLoading: true })
    let data = {
      "complaint_id": item,
    }
    await this.props.moveToRtoOutClick(data)
    this.setState({ isLoading: false })
    this.props.handleRefreshList()
  }

  handleScreeningOpenModal = () => {
    this.setState({ showScreeningModal: true });
    let productArr = [];
    if (this.props.distributorComplainDetails.product.length > 0) {
      {
        this.props.distributorComplainDetails.product.map((obj) => (
          productArr.push(
            {
              product_name: obj.product_name + " " + obj.color_name,
              productComplaintId: obj.productComplaintId
            }
          )
        ))
      }
    }
    // Check if product array has only one value
    if (this.props.distributorComplainDetails.product.length === 1) {
      this.setState({ showSubmit: true, showNext: false })
    }
    this.setState({ productArr })
    // this.props.handleRefreshList()
  }

  async handleRPUPrint() {
    let data = {
      "complaint_id": this.props.distributorComplainDetails.id,
      "type": "reverse_pick_up"
    }
    await this.props.fetchPrintLabel(data);
    if (this.props.printLabel) {
      // const win = window.open("/rpu-print", "_blank");
      // win.focus();
      // history.push('/rpu-print')
      this.setState({ showRPUPrintModal: true })
    }
    else {
      alert("no data found");
    }
  }

  handleRPUModalClose() {
    this.setState({
      showRPUPrintModal: false
    }, () => {
      this.props.handleRefreshList();
    })
  }

  handleFPUPrint = async () => {
    let data = {
      "complaint_id": this.props.distributorComplainDetails.id,
      "type": "forward_pick_up"
    }
    await this.props.fetchPrintLabel(data)
    if (this.props.printLabel) {
      //history.push('/fpu-print')
      this.setState({ showFPUPrintModal: true })
    } else {
      alert("no courier data found");
    }
  }

  handlePrintModalClose = () => {
    this.setState({
      showScreeningPrintModal: false
    }, () => {
      this.props.handleRefreshList();
    })
  }

  async handleRPUOpenModal() {
    this.props.handleLoader();
    let data = {
      "complaint_id": this.props.distributorComplainDetails.id,
      "type": "reverse_pick_up"
    }
    await this.props.getRPUCourier(data);
    this.props.handleLoader();
    this.props.handleRefreshList();
  }

  async handleOutward(e, item) {
    this.props.handleLoader();
    let data = {
      "complaint_id": item,
      "type": "forward_pick_up"
    }
    // TODO first call list api then click list item and call new save api
    await this.props.getRPUCourier(data)
    this.props.handleLoader();
    this.props.handleRefreshList();
  }

  // handleRPUCloseModal() {
  //   this.setState({ showRPUModal: false });
  // }

  async handleCourierChange(e) {
    this.setState({
      courierId: e.target.value
    })
  }

  // async handleRPUSubmit() {
  //   let cId = this.state.courierId
  //   let selectedC = this.props.courierData.filter((cd) => Number(cd.courier_id) === Number(cId))
  //   if (selectedC.length > 0) {
  //     this.setState({ isLoadingGif: true })
  //     let data = {
  //       "complaint_id": this.props.distributorComplainDetails.id,
  //       "type": "reverse_pick_up",
  //       "courier_id": cId,
  //       "reversePrice": 0
  //     }
  //     await this.props.submitRPU(data);
  //     this.setState({ isLoadingGif: false })
  //     this.props.handleRefreshList();
  //   } else {
  //     alert("Please select courier")
  //   }
  // }

  onRevokeTicket = async (e, item) => {
    await this.props.revokeTicket(item)
    this.props.handleRefreshList();
  }

  async handleSendEmail() {
    let data = {
      complaint_id: this.props.distributorComplainDetails.id
    }
    await this.props.sendBusyEmail(data)
    this.props.handleRefreshList()
  }

  onMoveToPickedUpClick = (e, item) => {
    e.preventDefault()
    let data = {
      "complaint_id": item,
    }
    this.props.movedToPickup(data)
    this.props.handleRefreshList()
  }

  onHandoverClick = (e, item) => {
    e.preventDefault()
    let data = {
      "complaint_id": item,
    }
    this.props.movedToHandover(data)
    this.props.handleRefreshList();
  }

  onProductReceiveClick = (e, item) => {
    e.preventDefault()
    let data = {
      "complaint_id": item,
    }
    this.props.movedToReceive(data)
    this.props.handleRefreshList()
  }

  onMoveToIQCClick = (e, item) => {
    e.preventDefault()
    let data = {
      "complaint_id": item,
    }
    this.props.moveToIQCClick(data)
    this.props.handleRefreshList()
  }

  handleScreeningCloseModal = () => {
    this.setState({
      showScreeningModal: false, productArr: [],
      index: 0,
      infoArr: [],
      screenOptionsArray: [
        { name: "Strap Received", key: "strap_received", isChecked: false },
        { name: "Watch Dial Received", key: "watch_dial_recieved", isChecked: false },
        { name: "Charger Cable Received", key: "charger_cable_received", isChecked: false },
        { name: "Wrong Product Received", key: "wrong_product_received", isChecked: false },
        { name: "Empty box Received", key: "empty_box_received", isChecked: false },
        { name: "Damaged Earbuds Received", key: "damaged_earbuds_received", isChecked: false },
        { name: "Complete Product Received", key: "complete_product_received", isChecked: false },
        { name: "Mismatched SKU Received", key: "mismatched_sku_received", isChecked: false },
        { name: "Partial Product Received", key: "partial_product_received", isChecked: false },
        { name: "Damage Product Received", key: "damage_product_received", isChecked: false },
        { name: "Missing Charger", key: "missing_charger", isChecked: false },
        { name: "One Ear Buds Missing", key: "one_ear_buds_missing", isChecked: false },
        { name: "One Ear Buds Damage Recieved", key: "one_ear_buds_damage_recieved", isChecked: false },
        { name: "USB Cable Missing", key: "usb_cable_missing", isChecked: false },
        { name: "AUX & USB Cable Missing", key: "aux_and_usb_cable_missing", isChecked: false },
        { name: "Strap Damage Recieved ", key: "strap_damage_recieved", isChecked: false },
        { name: "One Side Damage Neckband Received", key: "one_side_damage_neckband_received", isChecked: false },
        { name: "Scratch Watch Display Received", key: "scratch_watch_display_received", isChecked: false },
        { name: "Extra Strap & USB Cable & Charging", key: "extra_strap_&_usb_cable_&_charging", isChecked: false },
        { name: "Both Earbuds Missing", key: "both_earbuds_missing", isChecked: false },
        { name: "Charging Case Missing", key: "charging_case_missing", isChecked: false },
        { name: "Watch Charging Pin Rusted", key: "watch_charging_rin_rusted", isChecked: false },
        { name: "Both Earbuds Received", key: "both_earbuds_received", isChecked: false },
        { name: "Charging Case Received", key: "charging_case_received", isChecked: false },
        { name: "Watch Crown Missing", key: "watch_crown_missing", isChecked: false },
        { name: "Strap Missing", key: "strap_missing", isChecked: false },
        { name: "One Earbuds Received", key: "one_earbuds_received", isChecked: false },
        { name: "Watch Dial Missing", key: "watch_dial_missing", isChecked: false },
        { name: "Watch Dial Damage Received", key: "watch_dial_damage_received", isChecked: false },
        { name: "Wrong Strap Received", key: "wrong_strap_received", isChecked: false },
      ],
      showNext: true,
      showSubmit: false,
      showPrev: false,
    });
  }

  async handleScreeningSubmit() {
    let sName = []
    for (let i = 0; i < this.state.screenOptionsArray.length; i++) {
      if (this.state.screenOptionsArray[i].isChecked) {
        sName.push(this.state.screenOptionsArray[i].key)
      }
    }
    if (sName.length === 0) {
      alert("Please select ")
    } else {
      let data = {
        "complaint_id": this.props.complaintDetail.id,
        "screening": sName,
        "screening_status": ""
      }
      await this.props.distributorScreening(data)
      this.props.handleRefreshList()
      // await this.props.submitScreenName(data)
      // if (this.props.screenRes && this.props.screenRes.printJob) {
      //   this.setState({
      //     showScreeningPrintModal: true
      //   })
      // } else if (this.props.screenRes && !this.props.screenRes.printJob) {
      //   alert("Successfully Moved")
      //   this.props.handleRefreshList();
      // }
    }
  }

  handleChangeChk = (e) => {
    const { name, checked, id } = e.target
    let tempArray = this.state.screenOptionsArray.map((so) => so.key === name ? { ...so, isChecked: checked } : so)
    let selectedData = tempArray.filter((ta) => ta.isChecked).map((ta) => ta.key)
    let selectedObj = {
      "productComplaintId": id,
      "screening": selectedData
    }
    let checkIfProductAlreadyAdded = result.filter((r) => Number(r.productComplaintId) === Number(id))
    // If true 
    if (checkIfProductAlreadyAdded.length > 0) {
      // modify screening key 
      // index = a.findIndex(x => x.prop2 ==="yutu");
      let currIndex = result.findIndex(r => r.productComplaintId === id)
      result[currIndex]["screening"] = selectedData
    } else {
      // Push
      result.push(selectedObj)
    }
    this.setState({
      screenOptionsArray: tempArray,
    })
  }

  decrement = (e, i) => {
    if (i > 0) {
      i = i - 1;
      let tempArray = [];
      let checkIfProductAlreadyAdded = result.filter((r) => Number(r.productComplaintId) === Number(this.state.productArr[i].productComplaintId))
      if (checkIfProductAlreadyAdded.length > 0) {
        //Loop to manipulate temp array
        for (let j = 0; j < this.state.screenOptionsArray.length; j++) {
          // Check if already selected in result
          if (checkIfProductAlreadyAdded[0].screening.includes(this.state.screenOptionsArray[j].key)) {
            let obj = {
              "name": this.state.screenOptionsArray[j].name,
              "key": this.state.screenOptionsArray[j].key,
              "isChecked": true
            }
            tempArray.push(obj)
          } else {
            let obj = {
              "name": this.state.screenOptionsArray[j].name,
              "key": this.state.screenOptionsArray[j].key,
              "isChecked": false
            }
            tempArray.push(obj)
          }
        }
      }
      // let tempArray = this.state.screenOptionsArray.map((so) => so.key ? { ...so, isChecked: false } : so)
      this.setState({
        screenOptionsArray: tempArray
      })
      if (i >= 1) {
        this.setState({ showNext: true, showPrev: true })
      }
      if (i === 0) {
        this.setState({ showPrev: false })
      }
      this.setState({ index: i })
      //  if(i)
      //  let selected = {};
      //  let prevArr = this.state.screenOptionsArray;
      //  selected = this.state.infoArr[i].screening.map((sel) => {
      //   prevArr = this.state.screenOptionsArray.filter(so => so.key == sel)
      //   return prevArr;
      //  })
      //  selected = selected.map((item) => {
      // return item[0]
      //  })
      //  selected = selected.map((item) => {
      //   return {...item, isChecked :true}
      //    })
      //    let newArr = []
      //     this.state.screenOptionsArray.map((item) => (
      //      newArr = {...item , ...selected}
      //    ))
      //  selected = { ...selected, isChecked: true }
      //   let tempArr1 = []
      //   let prevArr = {}
      //   let selectedLine = selected.map((sel) => {
      //     prevArr = this.state.screenOptionsArray.filter(so => so.key == sel)
      //     prevArr = prevArr[0]
      //     prevArr = { ...prevArr, isChecked: true }
      //     return prevArr
      //     //  {...so, isChecked: true } 
      //   })
      //   selectedLine = { ...this.state.screenOptionsArray, ...selectedLine }
      //   //   let selectedLine = this.state.infoArr[i].screening.map((sel) => {
      //   //     let prevArr = this.state.screenOptionsArray.filter(so => so.key == sel).map((so,index) => (
      //   //        {...so, isChecked: true, index:index } 
      //   //       ));
      //   //       // prevArr = prevArr[0]
      //   //        return prevArr[0]    
      //   //   })
      //   //   selectedLine = {...this.state.screenOptionsArray, ...selectedLine};
      //   i = i - 1;
      //   if (i == 0) {
      //     this.setState({ showPrev: false })
      //   }
      // }
      // else {
      //   this.setState({ showPrev: false })
    }
  }

  increment = async (e, i) => {
    let sName = []
    for (let i = 0; i < this.state.screenOptionsArray.length; i++) {
      if (this.state.screenOptionsArray[i].isChecked) {
        sName.push(this.state.screenOptionsArray[i].key)
      }
    }
    if (sName.length < 1) {
      alert("Please select atleast one option")
    } else {
      if (i < this.state.productArr.length) {
        if (i === this.state.productArr.length - 1) {
          let data = {
            "complaint_id": this.props.distributorComplainDetails.id,
            "product": result
          }
          await this.props.distributorScreening(data)
          if (this.props.distributorScreeningRes && this.props.distributorScreeningRes.printJob) {
            this.handleScreeningCloseModal();
            this.setState({ showPrintPreview: true })
          }
          result = [];
          return
        }
        let checkIfProductAlreadyAdded = result.filter((r) => Number(r.productComplaintId) === Number(this.state.productArr[i + 1].productComplaintId))
        let tempArray = [];
        if (checkIfProductAlreadyAdded.length > 0) {
          //Loop to manipulate temp array
          for (let j = 0; j < this.state.screenOptionsArray.length; j++) {
            // Check if already selected in result
            if (checkIfProductAlreadyAdded[0].screening.includes(this.state.screenOptionsArray[j].key)) {
              let obj = {
                "name": this.state.screenOptionsArray[j].name,
                "key": this.state.screenOptionsArray[j].key,
                "isChecked": true
              }
              tempArray.push(obj)
            } else {
              let obj = {
                "name": this.state.screenOptionsArray[j].name,
                "key": this.state.screenOptionsArray[j].key,
                "isChecked": false
              }
              tempArray.push(obj)
            }
          }
        } else {
          tempArray = this.state.screenOptionsArray.map((so) => so.key ? { ...so, isChecked: false } : so)
        }
        // let tempArray = this.state.screenOptionsArray.map((so) => so.key ? { ...so, isChecked: false } : so)
        this.setState({
          screenOptionsArray: tempArray
        })
        if (i >= 0) {
          this.setState({ showPrev: true })
        }
        else {
          this.setState({ showPrev: false })
        }
        if (i === (this.state.productArr.length - 2)) {
          this.setState({ showNext: false, showSubmit: true })
        }
        // if(i == this.state.productArr.length -1){
        // }
        // else if(i !== (this.state.productArr.length - 1)){
        //   this.setState({ showNext: true, showSubmit: false })
        // }
        if (i < this.state.productArr.length) {
          this.setState({ index: i + 1 })
        }
      }
    }
  }

  render() {
    let index = this.state.index;
    return (
   
     <div>
     {
       this.props.statusId === 1 ? <div>
         <a className='dropdown-item'  onClick={this.onRPUClick}><img src="../../img/RPU.svg" alt="" />RPU</a>
         <a className="dropdown-item"  onClick={(e) => this.onMoveToComplaintClosedClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Complaint-Closed.svg" alt="" />Close Complaint</a>
         <a className="dropdown-item"  onClick={(e) => this.handleReversePickupCancel(e, this.props.distributorComplainDetails.id)}><img src="../../img/Reverse-Pickup-Cancelled.svg" alt="" />Reverse pickup cancelled</a>
         <a className="dropdown-item"  onClick={this.handleSendEmail}><img src="../../img/SMS.svg" />Call Reminder</a>
         <a className="dropdown-item"  onClick={(e) => this.onPhoneClick(e, this.props.distributorComplainDetails.distributor_id, this.props.distributorComplainDetails.id)}><img src="../../img/Call.svg" alt="" />Call </a>
       </div> : null
     }
     {
       this.props.statusId === 5 ? <div>
         <a className="dropdown-item"  onClick={this.handleRPUOpenModal}><img src="../../img/RPU.svg" alt="" />Re-arrange Reverse Pickup</a>
         <a className="dropdown-item"  onClick={(e) => this.onPhoneClick(e, this.props.distributorComplainDetails.distributor_id, this.props.distributorComplainDetails.id)}><img src="../../img/Call.svg" alt="" />Call </a>
         <a className="dropdown-item"  onClick={this.handleSendEmail}><img src="../../img/SMS.svg" />Call Reminder</a>
         <a className="dropdown-item"  onClick={(e) => this.onMoveToPickedUpClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Product_Picked_Up.svg" alt="" />Product picked up</a>
         <a className="dropdown-item"  onClick={(e) => this.handleReversePickupCancel(e, this.props.distributorComplainDetails.id)}><img src="../../img/Reverse-Pickup-Cancelled.svg" alt="" />Reverse pickup cancelled</a>
         <a className="dropdown-item"  onClick={(e) => this.onMoveToComplaintClosedClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Complaint-Closed.svg" alt="" />Close Complaint</a>
       </div> : null
     }
     {
       this.props.statusId === 6 ? <div>
         <a className="dropdown-item"  onClick={this.handleRPUOpenModal}><img src="../../img/RPU.svg" alt="" />Re-arrange Reverse Pickup</a>
        <a className="dropdown-item"  onClick={(e) => this.onMoveToComplaintClosedClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Complaint-Closed.svg" alt="" />Close Complaint</a>
        <a className="dropdown-item"  onClick={(e) => this.onPhoneClick(e, this.props.distributorComplainDetails.distributor_id, this.props.distributorComplainDetails.id)}><img src="../../img/Call.svg" alt="" />Call </a>
        <a className="dropdown-item"  onClick={(e) => this.onMoveToPickedUpClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Product_Picked_Up.svg" alt="" />Product picked up</a>
        <a className="dropdown-item"  onClick={(e) => this.onRevokeTicket(e, this.props.distributorComplainDetails.id)}><img src="../../img/Revoke-Ticket.svg" alt="" />Revoke Ticket</a>

       </div> : null
     }
     {
       this.props.statusId === 12 ? <div>
         <a className="dropdown-item"  onClick={this.handleSendEmail}><img src="../../img/SMS.svg" />Call Reminder</a>
         <a className="dropdown-item"  onClick={(e) => this.onProductReceiveClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Product_Received.svg" alt="" />Package received</a>
         <a className="dropdown-item"  onClick={(e) => this.onMoveToComplaintClosedClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Complaint-Closed.svg" alt="" />Close Complaint</a>
         
       </div> : null
     }
     {
       this.props.statusId === 13 ? <div>
         <a className="dropdown-item"  onClick={(e) => this.onMoveToIQCClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/IQC.svg" alt="" />IQC</a>
         <a className="dropdown-item"  onClick={this.handleScreeningOpenModal}><img src="../../img/Screening.svg" alt="" />Screening</a>
         <a className="dropdown-item"  onClick={(e) => this.onMoveToComplaintClosedClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Complaint-Closed.svg" alt="" />Close Complaint</a>
         <a className="dropdown-item"  onClick={(e) => this.onPhoneClick(e, this.props.distributorComplainDetails.distributor_id, this.props.distributorComplainDetails.id)}><img src="../../img/Call.svg" alt="" />Call </a>
         <a className="dropdown-item"  onClick={this.handleSendEmail}><img src="../../img/SMS.svg" />Call Reminder</a>
       </div> : null
     }
     {
       this.props.statusId === 14 ? <div>
         {/* <a className="dropdown-item"  onClick={this.handleDispatchInward}><img src="../../img/Transfer-to-dispatch-inward.svg" alt="" />Transfer to dispatch inward</a> */}
         {/* <a className="dropdown-item"  onClick={this.handleFPUOpenModal}><img src="../../img/Edit.svg" />Transfer to Dispatch Outward</a> */}
         <a className="dropdown-item"  onClick={this.handleRPUPrint}><img src="../../img/Reverse-Pickup-Print.svg" />Reverse Pickup Print</a>
         <a className="dropdown-item"  onClick={this.handleSendEmail}><img src="../../img/SMS.svg" />Call Reminder</a>
         <a className="dropdown-item"  onClick={(e) => this.onPhoneClick(e, this.props.distributorComplainDetails)}><img src="../../img/Call.svg" alt="" />Call </a>
         {/* <a className="dropdown-item"  onClick={(e) => this.onSaveClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Approve.svg" alt="" />Save</a> */}
         <a className="dropdown-item"  onClick={(e) => this.onMoveToComplaintClosedClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Complaint-Closed.svg" alt="" />Close Complaint</a>
         {/* <a className="dropdown-item"  onClick={this.handleScreeningOpenModal}><img src="../../img/Screening.svg" alt="" />Screening</a> */}
         {/* <a className="dropdown-item"  onClick={(e) => this.onMoveToOutOfStockClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Out-of-Stock.svg" alt="" />Out of stock</a> */}
       </div> : null
     }
     {
       this.props.statusId === 17 ? <div>
         {/* <a className="dropdown-item"  onClick={(e) => this.onSaveClick(e, this.props.complaintDetail.id)}><img src="../../img/Save.svg" alt="" />Save</a> */}
         <a className="dropdown-item"  onClick={(e) => this.handleOutward(e, this.props.distributorComplainDetails.id)}><img src="../../img/Edit.svg" />Transfer to Dispatch Outward</a>
         <a className="dropdown-item"  onClick={(e) => this.onMoveToComplaintClosedClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Complaint-Closed.svg" alt="" />Close Complaint</a>
         <a className="dropdown-item"  onClick={this.handleSendEmail}><img src="../../img/SMS.svg" />Call Reminder</a>
         <a className="dropdown-item"  onClick={(e) => this.onPhoneClick(e, this.props.distributorComplainDetails)}><img src="../../img/Call.svg" alt="" />Call </a>
       </div> : null
     }
     {
       this.props.statusId === 18 ? <div>
         {/* <a className="dropdown-item"  onClick={(e) => this.onSaveClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Approve.svg" alt="" />Save</a> */}
         <a className="dropdown-item"  onClick={(e) => this.onMoveToComplaintClosedClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Complaint-Closed.svg" alt="" />Close Complaint</a>
         <a className="dropdown-item"  onClick={this.handleFPUPrint}><img src="../../img/Forward-pickup-print.svg" />Forward Pickup Print</a>
         <a className="dropdown-item"  onClick={(e) => this.onHandoverClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Product_Picked_Up.svg" />Hand Over To Courier</a>
         {/* <a className="dropdown-item"  onClick={(e) => this.onMoveToUnserviceableClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Unserviceable.svg" alt="" />Unserviceable Ticket</a>
         <a className="dropdown-item"  onClick={(e) => this.handleForwardPickupCancel(e, this.props.distributorComplainDetails.id)}><img src="../../img/Reverse-Pickup-Cancelled.svg" alt="" />Forward pickup cancelled</a>
         <a className="dropdown-item"  onClick={(e) => this.onMoveToRtoOutClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/RTO-Out.svg" alt="" />RTO Out</a> */}
       </div> : null
     }
     {
       this.props.statusId === 41 ? <div>
         <a className="dropdown-item"  onClick={(e) => this.onMoveToComplaintClosedClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Complaint-Closed.svg" alt="" />Close Complaint</a>
         {/* <a className="dropdown-item"  onClick={(e) => this.onPhoneClick(e, this.props.distributorComplainDetails)}><img src="../../img/Call.svg" alt="" />Call </a>
         <a className="dropdown-item"  onClick={(e) => this.onMoveToRtoInClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/RTO.svg" alt="" />RTO In</a>
         <a className="dropdown-item"  onClick={this.handleRPUPrint}><img src="../../img/Reverse-Pickup-Print.svg" />Reverse Pickup Print</a>
         <a className="dropdown-item"  onClick={this.handleFPUPrint}><img src="../../img/Forward-pickup-print.svg" />Forward Pickup Print</a>
         <a className="dropdown-item"  onClick={(e) => this.handleForwardPickupCancel(e, this.props.distributorComplainDetails.id)}><img src="../../img/Reverse-Pickup-Cancelled.svg" alt="" />Forward pickup cancelled</a>
         <a className="dropdown-item"  onClick={(e) => this.onMoveToFreshProductClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Fresh-Product.svg" alt="" />Fresh Product</a>
         <a className="dropdown-item"  onClick={this.handleSendEmail}><img src="../../img/SMS.svg" />Call Reminder</a> */}
         <a className="dropdown-item"  onClick={(e) => this.onRevokeTicket(e, this.props.distributorComplainDetails.id)}><img src="../../img/Revoke-Ticket.svg" alt="" />Revoke Ticket</a>
       </div> : null
     }
     {
       this.props.statusId === 42 ? <div>
         <a className="dropdown-item"  onClick={(e) => this.onMoveToComplaintClosedClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Complaint-Closed.svg" alt="" />Close Complaint</a>
         {/* <a className="dropdown-item"  onClick={(e) => this.onPhoneClick(e, this.props.distributorComplainDetails)}><img src="../../img/Call.svg" alt="" />Call </a>
         <a className="dropdown-item"  onClick={(e) => this.onMoveToRtoInClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/RTO.svg" alt="" />RTO In</a>
         <a className="dropdown-item"  onClick={this.handleRPUPrint}><img src="../../img/Reverse-Pickup-Print.svg" />Reverse Pickup Print</a>
         <a className="dropdown-item"  onClick={this.handleFPUPrint}><img src="../../img/Forward-pickup-print.svg" />Forward Pickup Print</a>
         <a className="dropdown-item"  onClick={(e) => this.handleForwardPickupCancel(e, this.props.distributorComplainDetails.id)}><img src="../../img/Reverse-Pickup-Cancelled.svg" alt="" />Forward pickup cancelled</a>
         <a className="dropdown-item"  onClick={(e) => this.onMoveToFreshProductClick(e, this.props.distributorComplainDetails.id)}><img src="../../img/Fresh-Product.svg" alt="" />Fresh Product</a>
         <a className="dropdown-item"  onClick={this.handleSendEmail}><img src="../../img/SMS.svg" />Call Reminder</a> */}
         <a className="dropdown-item"  onClick={(e) => this.onRevokeTicket(e, this.props.distributorComplainDetails.id)}><img src="../../img/Revoke-Ticket.svg" alt="" />Revoke Ticket</a>
       </div> : null
     }
     <ReactModal
       isOpen={this.state.showScreeningModal}
       contentLabel="Minimal Modal Example"
       style={customStyles}
       className="distributor_screening"
     >
       <h2 id="rModal_head">Screening</h2>
       <button id="close_rModal" onClick={this.handleScreeningCloseModal}><i className="fas fa-times"></i></button>
       {this.state.productArr.length > 0 ?
         <div className='screening-list'>
           <div>
             <p className="product_name">{this.state.productArr[index].product_name}</p>
             <p className="currentPage">{index + 1} / {this.state.productArr.length}</p>
             {this.state.screenOptionsArray.map((so, i) => (
               <div key={i} >
                 <input type="checkbox" name={so.key} id={this.state.productArr[index].productComplaintId} onChange={this.handleChangeChk} checked={so.isChecked || false} />
                 <label for="subscribeNews" className='screening-label'>{so.name}</label>
                 {/* <label for="subscribeNews" className='screening-label'>{this.state.productArr[index].productComplaintId}</label> */}
               </div>
             ))
             }
             {this.state.showPrev && <button id="prevProduct" onClick={(e) => this.decrement(e, index)} ><i className='fas fa-angle-left'></i> Prev</button>}
             <button id={this.state.showNext ? "nextProduct" : "submitProduct"} onClick={(e) => this.increment(e, index)} >{this.state.showNext ? "Next" : "Submit"} {this.state.showNext && <i className='fas fa-angle-right'></i>}</button>
             {/* {this.state.showSubmit && <button id="submitProduct" onClick={(e) => this.subitScreeningDistributor(e, index)} >Submit</button>} */}
           </div>
         </div>
         :
         <div className='screening-list'>
           No products found
         </div>
       }
     </ReactModal>
     <ReactModal
       isOpen={this.state.showRPUPrintModal}
       contentLabel="Minimal Modal Example"
       style={customStylesRPU}
       ariaHideApp={false}
       className="rpu_print"
     >
       <button type='button' onClick={this.handleRPUModalClose} id="close_rModal"><i className="fas fa-times"></i></button>
       <ComponentToRPUPrint ref={el => (this.componentRef = el)} />
     </ReactModal>
     <ReactModal
       isOpen={this.state.showFPUPrintModal}
       contentLabel="Minimal Modal Example"
       style={customStylesRPU}
       ariaHideApp={false}
       className="rpu_print"
     >
       <button type='button' onClick={this.handlePrintModalClose} id="close_rModal"><i className="fas fa-times"></i></button>
       <ComponentToFPUPrint ref={el => (this.componentRef = el)} />
     </ReactModal>
     {/* <ReactModal  
       isOpen={this.state.showRPUModal}
       contentLabel="Minimal Modal Example"
       style={customStyles}
       ariaHideApp={false}
     >
       <h2 id="rModal_head">RPU</h2>
       <button id="close_rModal" onClick={this.handleRPUCloseModal}><i className="fas fa-times"></i></button>
       <select id="select_rModal" className="form-control" onChange={this.handleCourierChange}>
         <option value={"0"} selected="selected">Select Courier</option>
         {this.props.courierData.map((obj) => (
           <option value={obj.courier_id}>{obj.courier_name} - {obj.pincode} - Rs.{obj.reverse_cost}</option>
         ))}
       </select>
       {this.state.isLoadingGif ?
         <button id="send_rModal" type="button"><img className="loaderCTA" src="/img/loading-gif.gif" alt="Loader" /></button>
         :
         <button id="send_rModal" type="button" onClick={this.handleRPUSubmit}>Send</button>
       }
     </ReactModal> */}
     <ReactModal
       isOpen={this.state.showPrintPreview}
       contentLabel="Minimal Modal Example"
       style={screeningPrintLabel}
       ariaHideApp={false}
       className="screeningPrint"
     >
       <button id="close_rModal" onClick={this.closeScreeningModal}><i className="fas fa-times"></i></button>
       <RTLComponentPrint ref={el => (this.componentRef = el)} />
     </ReactModal>
   </div>     
    )
  }
}

const mapStateToProps = state => ({
  distributorData: state.distributor.distributorData,
  statusId: state.distributor.statusId,
  complaintsDetail: state.distributor.complaintsDetail,
  showComplaintDetails: state.distributor.showComplaintDetails,
  bucketData: state.distributor.bucketData,
  distributorComplainDetails: state.distributor.distributorComplainDetails,
  paginationCount: state.distributor.paginationCount,
  saveticket: state.distributor.saveticket,
  callServiceCenter: state.distributor.callServiceCenter,
  printLabel: state.distributor.printLabel,
  distributorScreeningRes: state.distributor.distributorScreeningRes
})

export default connect(mapStateToProps, {
  getDistributorData, showComplaintDetailsAction, addStatusId, showComplaintDetailsAction, getComplaintBuckets,
  getDistributorComplaintDetail, onSaveTicket, callCustomer, getRPUCourier, onCloseTicket, onSelfShip,
  onReversePickupCancel, MoveToComplaintClosedClick, moveToRtoOutClick, fetchPrintLabel, revokeTicket, onPickedUp,
  MoveToOutOfStockClick, sendEmail, movedToPickup, moveToIQCClick, distributorScreening, sendBusyEmail,
  movedToReceive, movedToHandover
})(TicketReceived);
