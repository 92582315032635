import React, { Component } from 'react';
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import AccessRestricted from '../AccessRestricted'

var Barcode = require('react-barcode');

class RTLComponentPrint extends Component {
  render() {
    let userRoleId = localStorage.getItem('userRoleId');
    if (userRoleId === '6') {
      return (<AccessRestricted />)
    }
    else {
      return (
        <div>
          {this.props.distributorScreeningRes.printJob ?
            <div>
              <div className='damaged_product' ref={el => (this.componentRef = el)}>
                <style type="text/css" media="print">
                  {"?\@page table{width:100%;float:left;text-align:center !important; padding-top:0 !important;width:100%;float:left;}#rModal_head{margin-top: -5px !important;}#rModal_head b{font-size: 1.8rem;word-wrap: break-word}.damaged_product svg{margin-top: 9px  !important;transform:scale(0.8);}#ticketID b{font-size: 2rem !important;font-weight: 800 !important;margin-top:-5px !important;}\
                                "
                  }
                </style>

                <table>
                  <tbody>
                    <tr>
                      <td id="rModal_head">
                        <b>{this.props.distributorScreeningRes.complaint[0].ticket_id}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Barcode value={this.props.distributorScreeningRes.complaint[0].ticket_id}
                          displayValue={false}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
              <ReactToPrint
                content={() => this.componentRef}
                removeAfterPrint
                trigger={() => <div className='print_cta screening'><button type="button" id="send_rModal" >Print</button></div>}
              />
            </div>
            :
            <h2 style={{ color: "#fff" }}>No data found</h2>
          }
        </div>
      );
    }
  }
}
const mapStateToProps = state => ({
  distributorScreeningRes: state.distributor.distributorScreeningRes
})

export default connect(mapStateToProps, {})(RTLComponentPrint);
