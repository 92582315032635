import React, { Component } from 'react';
import './LoginComponent.css';
import { loginOtp, verifyLoginOtp, createLogin } from "../../actions/loginActions";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import ReactModal from 'react-modal';

const customStylesDO = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    width: 'calc(100% - 20px)', 
    maxWidth: '500px', 
    margin: '0 auto',
    background: '#fff',
    borderRadius: '5px',
    overflowY: 'auto',
    position: 'absolute',
    top: '50%',
    left: '48%', 
    transform: 'translate(-50%, -50%)', 
    paddingBottom: '2%',
    boxSizing: 'border-box'
  },
};

class LoginComponent extends Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
      redirect: false,
      errors: [],
      loginOtp: false,
      seconds: '0',
      inputOtp: '',
      otpType: '',
      isDisabled:false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleValidation() {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const mobileRegex = /^[6789]\d{9}$/;
    let errors = {};
    let validity = true;
    if (this.state.username === '') {
      errors["username"] = "Please enter login credentials";
      validity = false;
      this.setState({ otpType: '' });
    } else if (!emailRegex.test(this.state.username) && !mobileRegex.test(this.state.username)) {
      errors["username"] = "Invalid credentials";
      validity = false;
      this.setState({ otpType: '' });
    } else if (emailRegex.test(this.state.username)) {
      this.setState({ otpType: 'email' });
    } else if (mobileRegex.test(this.state.username)) {
      this.setState({ otpType: 'mobile' });
    }
    // if(this.state.password == ''){
    //   errors["password"] = "Please enter your password";
    //   validity = false
    // }
    this.setState({ errors: errors });
    return validity;
  }

  handleLoginTimer = ()=> {
    setTimeout(()=> {
       this.setState({isDisabled:false})
    },30000)
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      'username': this.state.username,
    }
     if (this.handleValidation() !== false) {
        if(!this.state.isDisabled){
      this.setState({isDisabled:true})
      let resLoginOtp = await this.props.loginOtp(data);
      this.setState({isDisabled:false})
      if (resLoginOtp) {
        this.setState({ openOtp: true });
        if (this.state.seconds <= 0) {
          this.setState({ seconds: 60, inputOtp: '' });
          clearInterval(this.timer)
          this.startTimer();
        }
      }
  }
   
      // this.handleLoginTimer()
    }
  
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  state = {
    isPasswordShown: false
  }

  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  }

  resend = async () => {
    let data = {
      'username': this.state.username
    }
    await this.props.loginOtp(data);
    this.setState({ openOtp: true, seconds: 60, inputOtp: '' });
    clearInterval(this.timer)
    this.startTimer();
  }

  startTimer() {
    this.timer = setInterval(() => {
      const { seconds } = this.state
      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1
        }))
      }
      if (seconds === 0) {
        clearInterval(this.timer)
      }
    }, 1000)
    // this.setState({seconds:5})
  }

  verifyOtp = async () => {
    var regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (regex.test(this.state.inputOtp)) {
      alert("Please remove special characters!")
    }
    else {
      let data = {
        'username': this.state.username,
        'otp': this.state.inputOtp
      }
      await this.props.createLogin(data);
      let loginRes = this.props.loginRes;
      if (loginRes.message === "Account Blocked for an hour!!") {
        this.setState({ openOtp: false });
      }
    }
  }

  render = () => {
    const { redirect, seconds } = this.state
    if (redirect) {
      return <Redirect to={{
        pathname: '/dashboard',
      }} />
    };
    return (
      <div className="container login-container">
        <div className="login-form">
          <div className="loginForm">
            <label className="login">Login</label>

            <form action="" onSubmit={this.handleSubmit}>
              <div>
                <input className="rectangle-user" id ='enterMailMobile' type="username" name="username" placeholder="Email Id/Mobile number" onChange={this.handleChange} />
                <i className="fa fa-user user-icon" ></i>
                <div className="error_add">
                  <span className ="errorShow">{this.state.errors["username"]}</span>
                </div>
              </div>
              {/* <div>
                <input className="rectangle-pass" name="password" type={isPasswordShown ? "text" : "password"} placeholder="Password" onChange={this.handleChange} maxLength={10} />
                < i className={`fa ${isPasswordShown ? "fa-eye" : "fa-eye-slash"} password-icon`} onClick={this.togglePasswordVisiblity} />
                </div>
              <div className="error_add">
                <span className="errorShow">{this.state.errors["password"]}</span>
              </div>
              <div>
                <label className="forget-pass" onClick={this.onForgotPass}><a href="/forgot-password">Forgot password?</a></label>
              </div>
              <div className="rem">
                <input className="checkbox" type="checkbox" value="rememberme" />	<label className="rememberme"> Remember me</label>
              </div> */}
              <div className="buttonLogin" onClick={this.handleSubmit}>
                <label className="loginText">{"Log in"}</label>
              </div>
            </form>
          </div>
        </div>
        <div className="login_img">
          <img src="/img/Tapsee-min.png" alt="" />
        </div>
        <div className="logo">
          <img src="/img/nlogo.png" alt="" />
        </div>
        <ReactModal
          isOpen={this.state.openOtp}
          contentLabel="Minimal Modal Example"
          style={customStylesDO}
          ariaHideApp={false}
          className="loginOtp"
        >
          <div className='modalHeader'>
            <h2 id="rModal_head">PLEASE ENTER OTP TO LOGIN</h2>
            <button id="close_rModal" onClick={() => this.setState({ openOtp: false, inputOtp: '' })}><i className="fas fa-times"></i></button>
          </div>
          <p className="content">We have sent an OTP on your registered {this.state.otpType === 'email' ? 'email address' : 'mobile number'}</p>
          <input maxLength={6} type="text" id="InputLoginOtp" placeholder="Enter OTP" onChange={e => {
            let temp = e.target.value;
            this.setState({ inputOtp: temp })
          }} value={this.state.inputOtp} />
          <div className="otp-btn-wrapper">
            {
              seconds > 0 ? <span>Resend OTP in {seconds}s</span> : <button className='text-center rended-otp-button btn' onClick={this.resend}>
                Resend OTP
              </button>
            }
          </div>
          <button id="send_rModal" type="button" onClick={this.verifyOtp}>Verify OTP</button>
        </ReactModal>
      </div>
    )
  }
}

// LoginComponent.propTypes = {
//   createLogin: PropTypes.func.isRequired
// };

const mapStateToProps = state => ({
  loginRes: state.user.loginRes,
  userLogin: state.user.userLogin
})

export default connect(mapStateToProps, { loginOtp, verifyLoginOtp, createLogin })(LoginComponent);