import axios from 'axios';
import {
    FETCH_LEADERBOARD_ALL,FETCH_LEADERBOARD_ALL_CHANGE
} from "./types";
import { baseUrl } from '../config'
import history from '../history';
// import moment from 'moment'

export const fetchLeaderboardAll = () => async dispatch => {
    let token = localStorage.getItem('token');
    // let token = "$2b$08$t8dmSsTqX6SaD5AH.M3wHOXWjR5/1uzAz6MhHB30jyA6p6MyIlq5i"
    return await axios.post(baseUrl + '/record/leaderboard',{}, { 'headers': { 'x-access-token': token }})
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: FETCH_LEADERBOARD_ALL,
            payload: res.data.data
          })
        }
      })
      .catch((e) => {
        if(e.response.status === 401){
          history.push('/')
        }
      })
  }

  export const fetchLeaderboardAllChange = (body) => async dispatch => {
    let token = localStorage.getItem('token');
    return await axios.post(baseUrl + '/record/leaderboard', body, { 'headers': { 'x-access-token': token } })
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: FETCH_LEADERBOARD_ALL_CHANGE,
            payload: res.data.data
          })
          
        }else{
          dispatch({
            type: FETCH_LEADERBOARD_ALL_CHANGE,
            payload: []
          })
        }
        
      })
      .catch((e) => {
        if(e.response.status === 401){
          history.push('/')
        }
      })
  }



  

  