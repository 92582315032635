import React, { useEffect, useState } from "react";
import "./BulkSCComplats.css";
import SidebarComponent from "../SidebarComponent/SidebarComponent";
import ViewProfile from "../ViewProfile";
import {
  getScProductList,
  getScProblemList,
} from "../../actions/complaintsDataAction";
import { getGroupData, csvUploadAction } from "../../actions/productActions";
import { useDispatch } from "react-redux";
import ReactModal from "react-modal";
import { getCityByState } from "../../actions/complaintsDataAction";
import downloadfile from "./Untitled_sheet.csv";
import DownloadSampleCSV from "./DownloadSampleCSV";
const styleEdit = {
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    inset: "8% 0 0 auto",
    marginRight: "0",
    transform: "translate(0)",
    background: "#000",
    padding: "0",
    border: "none",
    height: "100%",
    overflow: "hidden",
    overflowY: "scroll",
    position: "fixed",
    width: "40%",
    float: "right",
    display: "inline-block",
  },
};

const BulkScComplaints = () => {
  const dispatch = useDispatch();
  const [productData, setProductData] = useState([]);
  const [productPermData, setProductPermData] = useState([]);
  const [problems, setProblems] = useState([]);
  const [productPermProblems, setProductPermProblems] = useState([]);
  const [groupId, setGroupId] = useState("");
  const [menu, setMenu] = useState("1");
  const [groupData, setGroupData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [cityDataPerm, setCityDataPerm] = useState([]);
  const [modatState, setModalState] = useState(false);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    productList(menu);
  }, [menu, groupId]);

  const productList = async (data) => {
    if (menu == 1) {
      let data = await dispatch(getScProductList());
      setProductData(data?.products);
      setProductPermData(data?.products);
    } else if (menu == 2) {
      let gropuDatal;
      if (!(groupData.length > 0)) {
        gropuDatal = await dispatch(getGroupData());
        setGroupData(gropuDatal?.data);
        setGroupId(gropuDatal?.data[0].id);
      }
      let payload = {
        group_id: groupId || gropuDatal?.data[1].id,
      };
      let data = await dispatch(getScProblemList(payload));
      setProblems(data?.products);
      setProductPermProblems(data?.products);
    } else {
      let response = await dispatch(getCityByState({}));
      setCityData(response.data);
      setCityDataPerm(response.data);
    }
  };

  const handleSearch = (value) => {
    setSearchInput(value);
    if (value.length > 0) {
      let data = productPermData?.filter((item) => {
        return item.product_name.toLowerCase().includes(value.toLowerCase());
      });
      setProductData(data);
    } else {
      setProductData(productPermData);
    }
  };

  const handleSearchProblem = (value) => {
    setSearchInput(value);
    if (value.length > 0) {
      let data = productPermProblems?.filter((item) => {
        return item.problem.toLowerCase().includes(value.toLowerCase());
      });
      setProblems(data);
    } else {
      setProblems(productPermProblems);
    }
  };

  const handleSearchcity = (value) => {
    setSearchInput(value);
    if (value.length > 0) {
      let data = cityDataPerm?.filter((item) => {
        return item.city.toLowerCase().includes(value.toLowerCase());
      });
      setCityData(data);
    } else {
      setCityData(cityDataPerm);
    }
  };

  const handleMenu = (value) => {
    setMenu(value);
  };

  const handleGorupchange = (value) => {
    setGroupId(value);
  };

  const handleImage = () => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv";

    input.onchange = () => {
      let file = input.files;
      if (file.length > 0) {
        let fileName = file[0].name.split(".");
        let fileType = fileName[fileName.length - 1].toLowerCase();
        if (fileType === "csv") {
          if (file[0].size / 1024 < 500) {
            setFile(file[0]);
            setFileName(file[0].name);
          } else {
            alert("File size should be less than 500KB");
          }
        } else {
          alert("File should be of type csv");
        }
      }
    };

    input.click();
  };

  const handleSubmit = async () => {
    let firstName = localStorage.getItem("userFirstName");
    let lastName = localStorage.getItem("userLastName");
    let email = localStorage.getItem("userEmail");
    let formData = new FormData();
    formData.append("csv", file);
    formData.append("email", email);
    formData.append("username", firstName + " " + lastName);
    setIsDisabled(true);
    let response = await dispatch(csvUploadAction(formData));
    if (response.success) {
      alert("File SuccessFully Uploaded, ticket creation report will be sent on your email");
    } else {
      alert("Something went wrong please try after some time");
    }
    setFile("");
    setFileName("");
    setModalState(false);
    setIsDisabled(false);
  };

  const handlekeyDown = (e) => {
    if (e.key == "Enter") {
      if (menu == 1) {
        handleSearch(e.target.value);
      } else if (menu == 2) {
        handleSearchProblem(e.target.value);
      } else {
        handleSearchcity(e.target.value);
      }
    }
  };

  const handlesearchClick = (value) => {
    if (menu == 1) {
      handleSearch(value);
    } else if (menu == 2) {
      handleSearchProblem(value);
    } else {
      handleSearchcity(value);
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = downloadfile; // The imported file acts as a URL
    link.setAttribute("download", "Default_Tamplate.csv"); // Name of the file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div>
      <div>
      { window.innerWidth > 600 &&  <SidebarComponent sideProp={"SCV"} />}
        <ViewProfile />
      </div>
      <div className="bss-main-container">
        <div className="header-name">
          <span className="complaint-head">Raise complaint service center</span>
          <button onClick={() => setModalState(true)}>Raise Complaints</button>
        </div>
        <ul className="bss-menu">
          <li
            className={menu == 1 ? "selected" : ""}
            onClick={() => handleMenu("1")}>
            Product
          </li>
          <li
            className={menu == 2 ? "selected" : ""}
            onClick={() => handleMenu("2")}>
            Problem
          </li>
          <li
            className={menu == 3 ? "selected" : ""}
            onClick={() => handleMenu("3")}>
            City
          </li>
        </ul>
        <div className="bss-search-add-button">
          <div className="search bss-search-container">
            {menu == 1 ? (
              <input
                type="username"
                name="searchText"
                id="search_input"
                placeholder="Enter product name or id..."
                onChange={(e) => handleSearch(e.target.value)}
                onKeyDown={(e) => handlekeyDown(e)}
              />
            ) : menu == 2 ? (
              <input
                type="username"
                name="searchText"
                id="search_input"
                placeholder="Enter problem name or id..."
                onChange={(e) => handleSearchProblem(e.target.value)}
                onKeyDown={(e) => handlekeyDown(e)}
              />
            ) : (
              <input
                type="username"
                name="searchText"
                id="search_input"
                placeholder="Enter city name or id..."
                onChange={(e) => handleSearchcity(e.target.value)}
                onKeyDown={(e) => handlekeyDown(e)}
              />
            )}
            <button
              onClick={() => handlesearchClick(searchInput)}
              type="button"
              className="add-user-btn-input">
              <i className="fa fa-search user_search_icon"></i>
            </button>
          </div>
          <DownloadSampleCSV
            productData={productPermData}
            problemData={problems}
            stateCityData={cityData}
            status={menu}
          />
          {menu == 2 && (
            <select onChange={(e) => handleGorupchange(e.target.value)}>
              {groupData.length > 0 &&
                groupData.map((item) => (
                  <option value={item.id}>{item.group_name}</option>
                ))}
            </select>
          )}
        </div>

        {menu == 1 && (
          <div className="list-container-bss">
            <ul className="ul-header-list">
              <li>Product Name</li>
              <li>Product Id</li>
              <li>Color name</li>
              <li>Product Color id</li>
            </ul>
            <div className="list-bss-overflow">
              {productData?.length > 0 &&
                productData?.map((item, index) => (
                  <ul className="list-content-bss">
                    <li>{item.product_name || "---"}</li>
                    <li>{item.product_id || "---"}</li>
                    <li>{item.color_name || "---"}</li>
                    <li>{item?.id || "---"}</li>
                  </ul>
                ))}
            </div>
          </div>
        )}
        {menu == 2 && (
          <div className="list-container-bss">
            <ul className="ul-header-list">
              <li>Problem Name</li>
              <li>Problem Id</li>
              <li>Sub problem name</li>
              <li>Sub problem id</li>
            </ul>
            <div className="list-bss-overflow">
              {problems?.length > 0 &&
                problems?.map((item, index) => (
                  <ul className="list-content-bss">
                    <li>{item.problem}</li>
                    <li>{item.problem_id}</li>
                    <li>{item.sub_problem}</li>
                    <li>{item.sub_problem_id}</li>
                  </ul>
                ))}
            </div>
          </div>
        )}
        {menu == 3 && (
          <div className="list-container-bss">
            <ul className="ul-header-list">
              <li>City Name</li>
              <li>City Id</li>
            </ul>
            <div className="list-bss-overflow">
              {cityData?.length > 0 &&
                cityData?.map((item, index) => (
                  <ul className="list-content-bss">
                    <li>{item.city}</li>
                    <li>{item.id}</li>
                  </ul>
                ))}
            </div>
          </div>
        )}
      </div>
      <ReactModal
        isOpen={modatState}
        style={styleEdit}
        ariaHideApp={false}
        className="modal-header">
        <div>
          <div className="modal-header-ssb">
            <h2>Raise Complaints</h2>
            <button id="close_rModal">
              <i
                className="fas fa-times"
                onClick={() => {
                  setFile("");
                  setFileName("");
                  setModalState(false);
                }}></i>
            </button>
          </div>
          <div className="upload-csv-ssb">
            <div className="sample-button">
              <button onClick={() => handleDownload()}>
                <i className="fas fa-download" /> {"  "} Download Sample CSV
              </button>
            </div>
            {!fileName && (
              <div className="upload-section-ssb">
                <button onClick={() => handleImage()}>
                  <i className="fa fa-upload"></i>Choose file
                </button>
              </div>
            )}
            {fileName && (
              <>
                <div className="upload-section-ssb-uploaded">
                  <button className="uploaded-button">{fileName}</button>
                  <i
                    onClick={() => {
                      setFile("");
                      setFileName("");
                    }}
                    disabled={isDisabled}
                    className="fa fa-times"></i>
                </div>
                <button
                  disabled={isDisabled}
                  className="upload-csv-button-ssb"
                  onClick={() => handleSubmit()}>
                  {isDisabled ? "Wait....." : "Submit"}
                </button>
              </>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default BulkScComplaints;
