import {
    SAVE_CUSTOMER_DATA, SAVE_PRODUCT_DATA, SAVE_COMPLAINT_DATA, CLEAR_COMPLAINT_DATA, CHANGE_COMPLAINT_STATUS,
    FETCH_COMPLAINT_DETAILS, CLEAR_EDITED_COMPLAINT_RES, FETCH_SUB_PROBLEM_ID
} from '../actions/types';

const initialState = {
    customerDetails: {},
    productDetails: {},
    complaintDetails: {},
    complaintStatus: {},
    secondSubProbelmID: ""
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SAVE_CUSTOMER_DATA:
            return {
                ...state,
                customerDetails: action.payload
            };
        case SAVE_PRODUCT_DATA:
            return {
                ...state,
                productDetails: action.payload
            };
        case SAVE_COMPLAINT_DATA:
            return {
                ...state,
                complaintDetails: action.payload,
                customerDetails: {},
                productDetails: {}
            };
        case CLEAR_COMPLAINT_DATA:
            return {
                ...state,
                complaintDetails: {}
            };
        case CHANGE_COMPLAINT_STATUS:
            return {
                ...state,
                complaintStatus: action.payload
            };
        case CLEAR_EDITED_COMPLAINT_RES:
            return {
                ...state,
                complaintStatus: {}
            };
        case FETCH_COMPLAINT_DETAILS:
            return {
                ...state,
                complaintDetails: action.payload
            };
        case FETCH_SUB_PROBLEM_ID:
            return {
                ...state,
                secondSubProbelmID: action.payload
            };
        default:
            return state;
    }
}
