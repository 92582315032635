import axios from 'axios';
import {
    FETCH_DASHBOARD_ALL,FETCH_DASHBOARD_COURIER,FETCH_DASHBOARD_PROBLEM
} from "./types";
import { baseUrl } from '../config'
import history from '../history';

  export const fetchDashboardAll = (body) => async dispatch => {
    let token = localStorage.getItem('token');
    
    // let token = "$2b$08$t8dmSsTqX6SaD5AH.M3wHOXWjR5/1uzAz6MhHB30jyA6p6MyIlq5i"
    return await axios.post(baseUrl + '/record/dashboard', body, { 'headers': { 'x-access-token': token } })
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: FETCH_DASHBOARD_ALL,
            payload: res.data.data
          })
          
        }else{
          dispatch({
            type: FETCH_DASHBOARD_ALL,
            payload: []
          })
        }
        
      })
      .catch((e) => {
        if(e.response.status === 401){
          history.push('/')
        }
      })
  }

  export const fetchDashboardCourier = (url) => async dispatch => {
    let token = localStorage.getItem('token');
    
    // let token = "$2b$08$t8dmSsTqX6SaD5AH.M3wHOXWjR5/1uzAz6MhHB30jyA6p6MyIlq5i"
    return await axios.get(baseUrl + '/record/dashboard'+url, { 'headers': { 'x-access-token': token } })
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: FETCH_DASHBOARD_COURIER,
            payload: res.data.data
          })
          
        }else{
          dispatch({
            type: FETCH_DASHBOARD_COURIER,
            payload: []
          })
        }
        
      })
      .catch((e) => {
      if(e.response.status === 401){
        history.push('/')
      }
      })
  }

  export const fetchDashboardProblem = (url) => async dispatch => {
    let token = localStorage.getItem('token');
    
    // let token = "$2b$08$t8dmSsTqX6SaD5AH.M3wHOXWjR5/1uzAz6MhHB30jyA6p6MyIlq5i"
    return await axios.get(baseUrl + '/record/dashboard'+url, { 'headers': { 'x-access-token': token } })
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: FETCH_DASHBOARD_PROBLEM,
            payload: res.data.data
          })
          
        }else{
          dispatch({
            type: FETCH_DASHBOARD_PROBLEM,
            payload: []
          })
        }
        
      })
      .catch((e) => {
      if(e.response.status === 401){
        history.push('/')
      }
      })
  }



  

  