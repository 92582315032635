import React, { Component } from 'react';
import './SidebarComponent.css';
import { connect } from "react-redux";


class SidebarComponent extends Component {
    constructor(props) {
        super();
        this.state = {
            logout: false,
            sideprops: 'DB',
            isSidebarVisible: true,
        }
    }

    componentDidMount() {
        this.setState({ 
            sideprops: this.props.sideProp,
            isSidebarVisible: this.props.isVisible !== undefined ? this.props.isVisible : true 
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isVisible !== this.props.isVisible) {
            this.setState({ isSidebarVisible: this.props.isVisible })
        }
    }

    render() {

        let userRoleId = localStorage.getItem('userRoleId');
        return (
            <div className="sidebar">
                <img src="/img/nlogo.png" alt="" />
                <div className={`sidebar-container ${!this.state.isSidebarVisible && window.innerWidth <= 600 ? 'd-none' : ''}`}>
                    {userRoleId != "11" ?
                        <>
                            <a href="/dashboard" onClick={() => this.sideBarChange('DB')} className={this.state.sideprops == 'DB' ? "nav-highligh" : ''}><i className={this.state.sideprops == 'DB' ? "fa fa-th-large menucolor2" : "fa fa-th-large"}></i><span className={this.state.sideprops == 'DB' ? "menucolor" : ''}>Dashboard</span></a>
                            <a href="/complaints-data" className={this.state.sideprops == 'CC' ? "nav-highligh" : ''} onClick={() => this.sideBarChange('CC')}><i className={this.state.sideprops == "CC" ? "fa fa-exclamation-circle menucolor2" : "fa fa-exclamation-circle "}></i><span className={this.state.sideprops == 'CC' ? "menucolor" : ''}>Complaints</span></a>
                            <a href="/bose-complaints-data" className={this.state.sideprops == 'BC' ? "nav-highligh" : ''} onClick={() => this.sideBarChange('BC')}><i className={this.state.sideprops == "BC" ? "fa fa-exclamation-circle menucolor2" : "fa fa-exclamation-circle "}></i><span className={this.state.sideprops == 'BC' ? "menucolor" : ''}>Master Buds</span></a>
                            <a href="/rtl-data" className={this.state.sideprops == 'RD' ? "nav-highligh" : ''} onClick={() => this.sideBarChange('RD')}><i className={this.state.sideprops === 'RD' ? "fa fa-exclamation-circle menucolor2" : "fa fa-exclamation-circle "}></i><span className={this.state.sideprops == 'RD' ? "menucolor" : ''} >RTL Complaints</span></a>
                            <a href="/Sc-complaints" className={this.state.sideprops == 'SC' ? "nav-highligh" : ''} onClick={() => this.sideBarChange('SC')}><i className={this.state.sideprops === 'SC' ? 'fa fa-exclamation-circle menucolor2' : "fa fa-exclamation-circle "}></i><span className={this.state.sideprops == 'SC' ? "menucolor" : ''} >SC Complaitns</span></a>
                            <a href="/refund" className={this.state.sideprops == 'RS' ? "nav-highligh" : ''} onClick={() => this.sideBarChange('RS')}><i className={this.state.sideprops === 'RS' ? "fa fa-rupee menucolor2" : "fa fa-rupee "}></i><span className={this.state.sideprops == 'RS' ? "menucolor" : ''}>Refund</span></a>
                            <a href="/manage-user" className={this.state.sideprops == 'MU' ? "nav-highligh" : ''} onClick={() => this.sideBarChange('MU')}><i className={this.state.sideprops === 'MU' ? "fas fa-users menucolor2" : "fas fa-users"}></i><span className={this.state.sideprops == 'MU' ? "menucolor" : ''}>Manage Users</span></a>
                            <a href="/leaderboard" className={this.state.sideprops == 'LB' ? "nav-highligh" : ''} onClick={() => this.sideBarChange('LB')}><i className={this.state.sideprops === 'LB' ? "fas fa-signal menucolor2" : "fas fa-signal "}></i><span className={this.state.sideprops == '' ? "menucolor" : 'LB'}>Reports</span></a>
                            <a href="/manage-product" className={this.state.sideprops == 'MP' ? "nav-highligh" : ''} onClick={() => this.sideBarChange('MP')}><i className={this.state.sideprops === 'MP' ? "far fa-building menucolor2" : "far fa-building"}></i><span className={this.state.sideprops == 'MP' ? "menucolor" : ''}>Product</span></a>
                            <a href="/bulk_movement" className={this.state.sideprops == 'BM' ? "nav-highligh" : ''} onClick={() => this.sideBarChange('MB')}><i className={this.state.sideprops === "BM" ? "fas fa-dumpster menucolor2" : "fas fa-dumpster"}></i><span className={this.state.sideprops == 'BM' ? "menucolor" : ''}>Bulk Movement</span></a>
                            <a href="/warranty-data" className={this.state.sideprops == 'WD' ? "nav-highligh" : ''} onClick={() => this.sideBarChange('WD')}><i className={this.state.sideprops === 'WD' ? 'fas fa-user-shield menucolor2' : 'fas fa-user-shield'}></i><span className={this.state.sideprops == 'WD' ? "menucolor" : ''}>Customer</span></a>
                            <a href="/settings" className={this.state.sideprops == 'SS' ? "nav-highligh" : ''} onClick={() => this.sideBarChange('SS')}><i className={this.state.sideprops === 'SS' ? "fa fa-cog menucolor2 " : "fa fa-cog"}></i><span className={this.state.sideprops == 'SS' ? "menucolor" : ''}>Settings</span></a>
                            <a href="/bulk-sc-complaints" className={this.state.sideprops == 'SCV' ? "nav-highligh" : ''} onClick={() => this.sideBarChange('SCV')}><i className={this.state.sideprops === 'SCV' ? "fa fa-cog menucolor2 " : "fa fa-cog"}></i><span className={this.state.sideprops == 'SCV' ? "menucolor" : ''}>Service Center</span></a>
                        </>
                        :
                        <a href="/settings" className={this.state.sideprops == 'SS' ? "nav-highligh" : ''} onClick={() => this.sideBarChange('SS')}><i className={this.state.sideprops === 'SS' ? "fa fa-cog menucolor2 " : "fa fa-cog"}></i><span className={this.state.sideprops == 'SS' ? "menucolor" : ''}>B2B Report</span></a>
                    }

                </div>
            </div >
        )
    }

}
const mapStateToProps = state => ({
    // user: state.user.user
    // sideProp:state.complaints.sideProp,
})


export default connect(mapStateToProps, {})(SidebarComponent);
