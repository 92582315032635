import React, { Component } from 'react';

import { connect } from "react-redux";
import {
    showComplaintDetailsAction,getComplaintData,
   
  } from '../../actions/complaintsDataAction'
  import moment from 'moment'


class SidebarComponent extends Component {
    constructor(props) {
        super();
        this.state = {
          pageLimit:10,
          current_page: 1,
          isLoading: false,
          totalPage: 1,
          totalCount: 0,
          prevPage: false,
          nextPage: false,
          pageNo: 0,
          }
    }
    

      render() {
        let paginationCount = this.props.paginationCount;
        let new_page = this.props.new_page;
        let pageNo=this.props.pageNo;
        let access=this.props.cc

        return (
          
       access==="kk"?
      <div className="footers">
    
    <div className="pages1">
      
      {paginationCount.total > 0 ?
        <div>
          {paginationCount.no_page === 1 ?
            <p>
              1 - {paginationCount.total} of {paginationCount.total} results
            </p>
            :
            <p>
              {this.state.pageLimit * new_page > 1 ?
                this.state.pageLimit * new_page
                :
                '1'
              }
              -
              {((this.state.pageLimit * this.props.current_page) > (paginationCount.total)) && ((paginationCount.total) > (this.state.pageLimit * new_page)) ?
                (paginationCount.total)
                :
                (this.state.pageLimit * this.props.current_page)} of {paginationCount.total} results
              <i className="fas fa-chevron-left" onClick={this.props.onPrevPress} />
              <span>{this.props.current_page}</span>
              <i className="fa fa-chevron-right" onClick={this.props.onNextPress} />
            </p>
          }
        </div>
        : ''
      }
    </div>
  </div>:
        this.props.totalcount!=0?
       <div className="footers">
        <div className="pages1">
         <p>{this.state.pageLimit * (pageNo) > 1 ? this.state.pageLimit * (pageNo) : '1'} - {this.state.pageLimit * (pageNo + 1)} of {this.props.totalcount} results
           <i className="fas fa-chevron-left" onClick={this.props.onPrevPress} />
           <span>{Number(this.state.pageNo) + 1}</span>
           <i className="fa fa-chevron-right" onClick={this.props.onNextPress} />
         </p>
       </div>
       </div>:null
        )
    }

}
const mapStateToProps = state => ({
    paginationCount: state.complaints.paginationCount,

})


export default connect(mapStateToProps, {showComplaintDetailsAction,getComplaintData})(SidebarComponent);
