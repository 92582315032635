import React, { useEffect, useState } from 'react'
import { useSelector,useDispatch} from 'react-redux'
// import {
//   ServiceCenterComplaintData
// } from '../../actions/serviceCenterActions';
import moment from 'moment'
import "./ServiceCenter.css"
// import DateRangePicker from 'rsuite/DateRangePicker';
import Loader from 'react-loader-spinner'
import { fetchComplaintDetails, TroubleShootShow } from '../../actions/serviceCenterComplaintAction'
import { useHistory } from 'react-router-dom';


function SearchResult() {
  const history = useHistory();

  const initialValues = {
    totalPage: 1,
    totalCount: 0,
    prevPage: false,
    nextPage: false,
    current_page: 1,
    pageLimit: 12,
    searchTextLength: 0,
    searchText: ''
  };

  const [values, setValues] = useState(initialValues);
  const dispatch = useDispatch();

  const onNextPress = () => {
    if (paginationCount.next_page) {
      setValues({ current_page: (values.current_page + 1) }, async () => {
        // getComplaintList()
      })
    }
  }

  const onPrevPress = () => {
    if (paginationCount.prev_page) {
      setValues(...values,
        {
          current_page: (values.current_page - 1)
        }
      )
      // getComplaintList()
    }
  }

  const searchResults = useSelector((state) => state.serviceCenter.searchResults);
  const [showLoader, setShowLoader] = useState(true)

  useEffect(() => {
    setShowLoader(true)
  }, [searchResults])

  const handleTicketClick = (e, item) => {
    dispatch(fetchComplaintDetails(item.id));
    dispatch(TroubleShootShow(true))
    history.push('/complaint-details')
  }

  const paginationCount = useSelector((state) => state.serviceCenter.paginationCount);
  let new_page = ((paginationCount.no_page - (paginationCount.no_page - values.current_page)) - 1);

  return (
    <div className='searchWrapper'>
      <h1 className='heading'>Search Results...</h1>
      {!searchResults && showLoader ?
        <div className='loader'>
          <Loader
            type="Oval"
            color="Grey"
            height={200}
            width={200}
            className='loaderNew'
          />
        </div>
        :
        <div className="servicecenter_listContainer">
          {
            searchResults.length > 0 ? searchResults.map((item, index) => {
              return (
                <div className="center_ticketContainer" key={item.id} onClick={e => handleTicketClick(e, item)}>
                  <h2>{item.service_center ? item.service_center.name : null}</h2>
                  <p id="borderService"></p>
                  <div className='cdetils'>
                    <span id="name2">Compliants Details: </span>
                    <span id="problemss">{item.problem}  </span>
                  </div>
                  <div className='cdetils'>
                    <span id="name2">Product Name: </span>
                    <span id="problemss">{item.product_name}  </span>
                  </div>
                  <p id="borderService"></p>
                  <div className="statusDetais">
                    <span id="name3">Current Status: </span>
                    <span id="name4">{item.complaint_status_name == null ? "null" : item.complaint_status_name} </span>
                  </div>
                  <div className="statusDetais">
                    <span id="name3">screening Status : </span>
                    <span id="name4">{item.screening_status} </span>
                  </div>
                  <div className="statusDetais">
                    <span id="name3">Date </span>
                    <span id="name4">{moment(item.created_at).format('DD-MM-YYYY')}</span>
                  </div>
                </div>
              )
            }) :
              <div className="Noreult">
                <span>No complaints found</span>
              </div>
          }
        </div>
      }
      <div className="footers">
        <div className="pages">
        </div>
        <div className="pages1">
          {searchResults.length > 0 ?
            <div>
              {paginationCount.no_page === 1 ?
                <p>
                  1 - {searchResults.length} of {searchResults.length} results
                </p>
                :
                <p>
                  {values.pageLimit * new_page > 1 ?
                    values.pageLimit * new_page
                    :
                    '1'
                  }
                  -
                  {((values.pageLimit * values.current_page) > (searchResults.length)) && ((searchResults.length) > (values.pageLimit * new_page)) ?
                    (searchResults.length)
                    :
                    (values.pageLimit * values.current_page)} of {searchResults.length} results
                  <i className="fas fa-chevron-left" onClick={onPrevPress} />
                  <span>{values.current_page}</span>
                  <i className="fa fa-chevron-right" onClick={onNextPress} />
                </p>
              }
            </div>
            : ''
          }
        </div>
      </div>
    </div>
  )
}

export default SearchResult