import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

const TicketModal = ({ isOpen, onClose, ticketId, onSetTeamName }) => {
  const history = useHistory();

  const handleClose = () => {
    if (onSetTeamName) {
      onSetTeamName("CS");
    }
    onClose();
    setTimeout(() => {
      history.push("/bose-complaints-data");
      window.location.reload();
    }, 300);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Complaint Registered Successfully</DialogTitle>
      <DialogContent>
        <p>Your ticket ID is: <strong>{ticketId}</strong></p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TicketModal;
