import history from '../history';
import axios from 'axios';
import { baseUrl } from '../config'
import { FETCH_LOGIN_USER_DATA, FETCH_LOGIN_RESPONSE } from "./types";


//New login with OTP
export const loginOtp = loginData => async dispatch => {
  localStorage.clear();
  return await axios.post(baseUrl + '/user/send-otp-login', { username: loginData.username },
    { 'headers': { 'Content-Type': 'application/json' } }
  ).then(res => {
    if (res.data.success) {
      if (res.data.otp) {
        alert(res.data.message);
        return true;
      } else {
        dispatch({
          type: FETCH_LOGIN_RESPONSE,
          payload: res.data
        });
        localStorage.setItem('token', res.data.data.accessToken)
        // localStorage.setItem('userDetails', res.data.data)
        localStorage.setItem('userRole', JSON.stringify(res.data.data.user_role))
        localStorage.setItem('userRoleId', res.data.data.user_role[0].id)
        localStorage.setItem('userId', res.data.data.id);
        localStorage.setItem('userFirstName', res.data.data.first_name);
        localStorage.setItem('userLastName', res.data.data.last_name);
        localStorage.setItem('userEmail', res.data.data.email);
        localStorage.setItem('distributorId', res.data.data.id);
        if (res.data.data.address) {
          localStorage.setItem('address', JSON.stringify(res.data.data.address));
        }
        let user = res.data.data
        dispatch({
          type: FETCH_LOGIN_USER_DATA,
          payload: user
        })

        if (res.data.data.user_role) {
          let userRoleId = res.data.data.user_role[0].id;

          userRoleId == '4' ?
            history.push('/read-only-user')
            :
            userRoleId == '6' ?
              history.push('/complaint-registration')
              :
              userRoleId == '8' ?
                history.push('/main-service-center')
                :
                userRoleId == '9' ?
                  history.push('/sub-service-center')
                  :
                  userRoleId == '10' ?
                    history.push('/sub-service-center')
                    :
                    userRoleId == '11' ?
                      history.push('/settings')

                      :
                      history.push('/settings')

        }
      }
    } else {
      alert(res.data.message)
    }
  }).catch((e) => {
    if (e.response.status === 429) {
      alert(e.response.data.message || "Something went wrong")
    }
  })
};

export const createLogin = data => async dispatch => {
  localStorage.clear();
  return await axios.post(baseUrl + '/user/auth/signin', data,
    { 'headers': { 'Content-Type': 'application/json' } }
  ).then(res => {
    dispatch({
      type: FETCH_LOGIN_RESPONSE,
      payload: res.data
    })
    if (!res.data.success) {
      alert(res.data.message)
      return;
    }
    if (res.status) {
      localStorage.setItem('token', res.data.data.accessToken)
      // localStorage.setItem('userDetails', res.data.data)
      localStorage.setItem('userRole', JSON.stringify(res.data.data.user_role))
      localStorage.setItem('userRoleId', res.data.data.user_role[0].id)
      localStorage.setItem('userId', res.data.data.id);
      localStorage.setItem('userFirstName', res.data.data.first_name);
      localStorage.setItem('userLastName', res.data.data.last_name);
      localStorage.setItem('userEmail', res.data.data.email);
      localStorage.setItem('distributorId', res.data.data.id);
      // if (res.data.data.scAddress) {
      //   localStorage.setItem('sc_address',JSON.stringify(res.data.data.scAddress));
      // }
      if (res.data.data.address) {
        localStorage.setItem('address', JSON.stringify(res.data.data.address));
      }
      if (res.data.data.scAddress) {
        localStorage.setItem('scAddress', JSON.stringify(res.data.data.scAddress));
      }
      let user = res.data.data;
      dispatch({
        type: FETCH_LOGIN_USER_DATA,
        payload: user
      })
      if (res.data.data.user_role) {
        let userRoleId = res.data.data.user_role[0].id;

        userRoleId == '4' ?
          history.push('/read-only-user')
          :
          userRoleId == '6' ?
            history.push('/complaint-registration')
            :
            userRoleId == '8' ?
              history.push('/main-service-center')
              :
              userRoleId == '9' ?
                history.push('/sub-service-center')
                :
                userRoleId == '10' ?
                  history.push('/sub-service-center')
                  : userRoleId == '11' ?
                    history.push('/settings')
                    :
                    history.push('/complaints-data')

      }
    }
  }).catch((e) => {
    alert("Something went wrong")
  })
};

export const resetPassword = loginData => async dispatch => {
  let token = localStorage.getItem('token');
  if (loginData.password === loginData.currentPassword) {
    history.push('/');
    alert('Your current and new password cannot be the same!')
  }
  return await axios.post(baseUrl + '/user/auth/reset_password', loginData, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.status) {
        alert(res.data.message)
        //  history.push('/');
      }
    }).catch((e) => {
      if (e.response.status === 401) {
        history.push('/')
      }
    })
};

// Admin Login Action
export const createPassword = loginData => async dispatch => {
  if (loginData.password !== loginData.confirmpassword) {
    // history.push('/');
    alert('Your password and confirmation password do not match!')
  }
  return await axios.post(baseUrl + '/user/auth/reset_password', {
    headers: { 'Content-Type': 'application/json' },
    token: loginData.token, password: loginData.password
  }).then(res => {
    if (res.status) {
      if (res.status) {
        alert(res.data.message)
        history.push('/');
      }
    }
  }).catch((e) => {
    if (e.response.status === 401) {
      history.push('/')
    }
  })
};

export const verifyLoginOtp = (inputOtp) => async () => {
  let token = localStorage.getItem('token');
  return await axios.post(
    baseUrl + '/user/auth/verify-login-otp/',
    { 'headers': { 'x-access-token': token }, inputOtp: inputOtp }
  ).then(res => {
    if (res.status) {
      //  alert(res)
      //  history.push('/');
    }
  }).catch((e) => {
    // if(e.response.status === 401){
    //   history.push('/')
    // }
  })
}

export const verifyUser = (hashId) => async () => {
  let token = localStorage.getItem('token');
  return await axios.get(
    baseUrl + '/user/verify-user/' + hashId,
    { 'headers': { 'x-access-token': token } }
  ).then(res => {
    if (res.status) {
      //  alert(res)
      //  history.push('/');
    }
  }).catch((e) => {
    // if(e.response.status === 401){
    //   history.push('/')
    // }
  })
}