import React, { Component } from 'react'
import './ServiceCenterComplaint.css';
import { connect } from "react-redux";
import {
    ServiceCenterComplaintData
  } from '../../actions/serviceCenterActions';
  import AccessRestricted from '../AccessRestricted'

class ServiceCenterComplaint extends Component {
   
    componentDidMount = async () => {
        document.getElementById("root").style.background = "#fff";
        document.querySelector("body").style.background = "#fff";
        await this.props.ServiceCenterComplaintData();
       
    
    }

    componentWillUnmount() {
        document.getElementById("root").style.background = "#121212";
        document.querySelector("body").style.background = "#121212";
      }
    render() {
      let userRoleId = localStorage.getItem('userRoleId');
    if (!['8', '9', '10'].includes(userRoleId)){
      return( <AccessRestricted/>)
    }
      else{
        return (  
          <div className='main-container'>
          
            <div className='fiter-container '>
              <span id='allText'>All</span>
                <div className='filters'>
         <select   className='service-filter'>
            <option value={0}>ServiceCenter</option>
         </select>
          <select className='service-filter'>
            <option value={0}>TroubleShoot</option>
          </select>
            <select className='service-filter'>
            <option value={0}>Date</option>
            </select>
         </div>
   </div>
             <div className='tickets-container'>
              <div className='ticket-wrapper2'>
                <div className='ticket-header'>
                 <span id="nm">Service Center Name</span>
                   </div>
                <p id="border"></p>
              <div className='compliantdetils'>
                 <span>Complaints Detail :  <span id='tt1'>Tsjlkabj,ahfb,ajhf</span></span>
             </div>
              <p id="border"></p>
              <div className='ticket-status'>
              <div className='statusName'>
             <span >Product Name:</span>
              <h6>j hjh hzhk kgh v </h6> 
            </div>
            <div className='statusName'>
             <span >Product Name:</span>
              <h6>j hjh hzhk kgh v </h6> 
            </div>
            <div className='statusName'>
             <span >Product Name:</span>
              <h6>j hjh hzhk kgh v </h6> 
            </div>
              
              </div>
            </div>
           </div>
          </div>
  
          // Ticket Status for all 
              // <div className='tickets-container'>
              // {  this.props.serviceNewData.map((e,item)=> <div className="ticket-wrapper">
              //    <p> Complaint No. {item.customer_id} </p>
              //            <span className='border'></span>
              //            <div className='product-details'>
              //            <div className='productName'>
              //             <span >Product Name:</span>
              //             <h6>{item.product_name} </h6> 
              //          </div> 
              //          <div className='productName'>
              //             <span >Status: </span>
              //               <h6>{item.ticket_status}</h6>
                          
              //          </div>
              //          <div className='productName'>
              //             <span >Date:  </span>
              //             <h6>{item.purchase_date}</h6>
              //          </div>
              //             </div>
                          
  
              //    </div>)
              //    }
                   
              //      </div>
          )
      }
    }

}
// const mapStateToProps = state => ({
// })
const mapStateToProps = state => ({
    serviceNewData: state.serviceCenter.serviceNewData
   
  })
export default connect(mapStateToProps, {ServiceCenterComplaintData
    
  })(ServiceCenterComplaint);   