import React, { Component } from 'react';
import './ComplaintRegistration.css';
import { connect } from "react-redux";
import { getDistributorStatusData } from "../actions/distributorAction"
import AccessRestricted from './AccessRestricted'
import moment from 'moment'
import RTLProductDetails from './RTLProductDetails'
import ViewProfile from './ViewProfile'


class ComplaintRegistration extends Component {
    constructor() {
        super();
        this.state = {
            fullName: '',
            contactNumber: '',
            emailAddress: '',
            errors: '',
            activeMenu: 'All',
            showProductDetails: false,
            showTrackingDetails: true
        };

    }

    componentDidMount = async () => {
        document.getElementById("root").style.background = "#fff";
        document.querySelector("body").style.background = "#fff";
        // let distributorId = localStorage.getItem('distributorId');
        // let data = {
        //     "distributor_id": distributorId
        // }
        await this.props.getDistributorStatusData();
    }

    componentWillUnmount() {
        document.getElementById("root").style.background = "#121212";
        document.querySelector("body").style.background = "#121212";
      }

    onMenuClick = (e, type) => {
        this.setState({ activeMenu: type });
    }

    showProductDetails = () => {
        this.setState({ showProductDetails: true, showTrackingDetails: false })
    }

    render = () => {
        let userRoleId = localStorage.getItem('userRoleId');
        if(userRoleId === '8' || userRoleId === '9' || userRoleId === '10'){
          return( <AccessRestricted/>)
        }
        return (
            <div className="complaintRegistration">
                {this.state.showTrackingDetails &&
                    <div>
                        <div className="header">
                            <div className="rtl_logo_wrapper">
                                <img className="rtl_logo" src="/img/rtl-logo.png" alt="" />
                                <p>Noise Connect</p><br />
                                <span>Customer Support</span>
                            </div>

                            <div className="rtl_account crProfile">
                                <ViewProfile />
                            </div>

                        </div>
                        {
                            this.props.distributorComplaintStatus.length > 0 ?
                                <div className="complaintTracking">
                                    <h3>Complaints</h3>
                                    <div className="newComplaint">
                                        <button type="button" className="newComplaintCTA" onClick={this.showProductDetails}><i className="fa fa-plus"></i> NEW COMPLAINT</button>
                                    </div>
                                    <div className="complaintList">
                                        <p className={this.state.activeMenu === 'All' ? "activeList " : null} onClick={(e) => this.onMenuClick(e, "All")}>All</p>
                                        <p className={this.state.activeMenu === 'Completed' ? "activeList" : null} onClick={(e) => this.onMenuClick(e, "Completed")}>Completed</p>
                                        <p className={this.state.activeMenu === 'Pending' ? "activeList" : null} onClick={(e) => this.onMenuClick(e, "Pending")}>Pending</p>
                                        {this.state.activeMenu == 'All' &&
                                            <div className="statusWrapper">
                                                {
                                                    this.props.distributorComplaintStatus.map((item, index) => (
                                                        <div className="ticketWrapper">
                                                            <h3># {item.ticket_id}</h3>
                                                            <div id="ticketInfoWrapper">
                                                                <p className="col-sm-6">Total products:<br></br><span>{item.productCount ? item.productCount : "1"}</span></p>
                                                                <p className="col-sm-6">ETA<br></br><span>{moment(item.updated_at).format('DD-MM-YYYY')}</span></p>
                                                            </div>
                                                            <div className={item.complaint_closed ? "closedTciket" : "ticketInProgress"}>
                                                                <p>
                                                                    {item.complaint_closed ? "Completed" : "In Progress"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                    )
                                                }
                                            </div>
                                        }
                                        {this.state.activeMenu === 'Completed' &&

                                            <div className="statusWrapper">
                                                {
                                                    this.props.distributorComplaintStatus.map((item, index) => (
                                                        <div className='ticketOuterWrapper'>
                                                            {item.complaint_closed &&
                                                                <div className="ticketWrapper">
                                                                    <h3># {item.ticket_id}</h3>
                                                                    <div id="ticketInfoWrapper">
                                                                        <p className="col-sm-6">Total products:<br></br><span>{item.productCount ? item.productCount : "1"}</span></p>
                                                                        <p className="col-sm-6">ETA<br></br><span>{moment(item.updated_at).format('DD-MM-YYYY')}</span></p>
                                                                    </div>
                                                                    <div className="closedTciket">
                                                                        <p>
                                                                            Completed
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        }
                                        {this.state.activeMenu === 'Pending' &&

                                            <div className="statusWrapper">
                                                {
                                                    this.props.distributorComplaintStatus.map((item, index) => (
                                                        <div className='ticketOuterWrapper'>
                                                            {!item.complaint_closed &&
                                                                <div className="ticketWrapper">
                                                                    <h3># {item.ticket_id}</h3>
                                                                    <div id="ticketInfoWrapper">
                                                                        <p className="col-sm-6">Total products:<br></br><span>{item.productCount ? item.productCount : "1"}</span></p>
                                                                        <p className="col-sm-6">ETA<br></br><span>{moment(item.updated_at).format('DD-MM-YYYY')}</span></p>
                                                                    </div>
                                                                    <div className="ticketInProgress">
                                                                        <p>
                                                                            In Progress
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                                :
                                <div className="noComplaintsWrapper">
                                    <img className="empty_folder" src="/img/rtl-empty-folder.png" alt="" />
                                    <h3>No active complaints</h3>
                                    <p>To register a complaint click below</p>
                                    <div className="newComplaint">
                                        <button type="button" className="newComplaintCTA" onClick={this.showProductDetails}><i className="fa fa-plus"></i> NEW COMPLAINT</button>
                                    </div>
                                </div>

                        }
                    </div>
                }

                {this.state.showProductDetails &&
                    <RTLProductDetails />
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    distributorComplaintStatus: state.distributor.distributorComplaintStatus
})

export default connect(mapStateToProps, { getDistributorStatusData })(ComplaintRegistration);