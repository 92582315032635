import React, {useEffect, useState, useRef} from 'react'
import './AddEmployeeComponent.css'

import {addSubServiceCenterEmployee} from '../../actions/serviceCenterActions'

import { useSelector,useDispatch } from 'react-redux';

import {useHistory} from 'react-router-dom'

import AccessRestricted from '../AccessRestricted';

function AddEmployeeComponent() {

  const history = useHistory();
  const dispatch = useDispatch();
  let btnRef = useRef();

  let response = useSelector((state) => state.serviceCenter.subServiceCenterEmp);
  if(response !== undefined) {
    if(response.success === true) {
      history.push('/sub-service-center')
    }
    
    if(response.success === false) {
      btnRef.current.innerText = 'Save';
      btnRef.current.style.width = '130px';
      btnRef.current.removeAttribute("disabled");
    }
  }

  useEffect(() => {
    document.getElementById('root').style.background = '#F5FBFB';
    document.querySelector('body').style.background = '#F5FBFB';
  
    return () => {
      document.getElementById('root').style.background = '#121212';
      document.querySelector('body').style.background = '#121212';
    }
  }, [])

  const handleBackButton = () => {
    history.push('/sub-service-center');
  }

  const initialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    companyEmail: '',
    recoveryEmail: '',
  }

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const handleValidation = (empDetails) => {
    let errors = {}
    let validity = true;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const mobileRegex = /^[6789]\d{9}$/;
   
    if(empDetails.first_name === undefined || empDetails.first_name === '') {
      errors['firstName'] = 'First name cannot be empty'
      validity = false
    }
    else if(empDetails.first_name.trim().length === 0) {
      errors['firstName'] = 'Please provide a valid first name'
      validity = false
    }
    else if(( /^[A-Za-z\s]*$/).test(empDetails.first_name) === false) {
      errors['firstName'] = 'Please provide a valid first name'
    }

    if(empDetails.last_name === undefined || empDetails.last_name === '') {
      errors['lastName'] = 'Last name cannot be empty'
      validity = false
    }
    else if(empDetails.last_name.trim().length === 0) {
      errors['lastName'] = 'Please provide a valid last name'
      validity = false
    }
    else if(( /^[A-Za-z\s]*$/).test(empDetails.last_name) === false) {
      errors['lastName'] = 'Please provide a valid last name'
    }
    
    if(empDetails.mobile === undefined || empDetails.mobile === '') {
      errors['phone'] = 'Phone number cannot be empty'
      validity = false;
    }
    else if(mobileRegex.test(empDetails.mobile) === false) {
      errors['phone'] = 'Please enter valid contact number'
      validity = false
    }
    
    if(empDetails.email === undefined || empDetails.email === '') {
      errors['companyEmail'] = 'Company email cannot be empty'
      validity = false;
    }
    else if(emailRegex.test(empDetails.email) === false) {
      errors['companyEmail'] = 'Please enter valid company email'
      validity = false;
    }

    setErrors(errors)

    return validity;
  }


  const handleSaveBtn = (e) => {
    let data = {
      "first_name": values.firstName,
      "last_name": values.lastName,
      "email": values.companyEmail,
      "mobile": values.phone
    }

    let validity = handleValidation(data);

    if(validity === true) {
      if(btnRef.current) {
        btnRef.current.setAttribute("disabled", "disabled");
        btnRef.current.innerText = 'Please Wait...'
        btnRef.current.style.width = '150px';
      }
      dispatch(addSubServiceCenterEmployee(data))
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  let userRoleId = localStorage.getItem('userRoleId');
  if (!['9', '10'].includes(userRoleId)){
    const style = {
      fontSize:"24px",
      color:"#000",
      textAlign:"center",
      fontWeight:"500",
      paddingTop:"5%"
    }
    return( <AccessRestricted style={style}/>)
  }
  else{
    return (
      <React.Fragment>
        <div className='header pd headerNew'>
          <div className="backHome" onClick={handleBackButton}>
            <i className='fas fa-arrow-left'></i> Back to dashboard
          </div>
        </div>
        <div className='main-container main-container-new'>
          <div className='addEmployeeForm'>
            <h1 className='form-heading'>Add new employee</h1>
            <div className='horizontal'></div>
            <form>
            <div className='row__two'>
              <div>
                <label htmlFor='customer name'>First Name</label>
                <input type="text" name='firstName' value={values.firstName} onChange={handleInputChange} />
                <div className='error_add'>
                  <span className='errorShow'>{errors.firstName}</span>
                </div>
              </div>
              <div>
                <label htmlFor='customer name'>Last Name</label>
                <input type="text" name='lastName' value={values.lastName} onChange={handleInputChange} />
                <div className='error_add'>
                  <span className='errorShow'>{errors.lastName}</span>
                </div>
              </div>
            </div>
            <div className='row__oneNew'>
              <div>
                <label htmlFor='customer name'>Contact number</label>
                <input type="tel" name='phone' value={values.phone} maxLength="10" onChange={handleInputChange} />
                <div className='error_add'>
                  <span className='errorShow'>{errors.phone}</span>
                </div>
              </div>
              <div style={{width: '310px'}}>
                <label htmlFor="contact number">Company email ID</label>
                <input type='email' className='addEmployeeInput' id='companyEmailInput' name='companyEmail'
                 value={values.companyEmail}
                 onChange={handleInputChange}
                 placeholder='@noise.com'
                />
                <div className='error_add'>
                  <span className='errorShow'>{errors.companyEmail}</span>
                </div>
              </div>
              <div style={{width: '370px'}}>
                <label htmlFor='customer email'>Recovery email ID</label>
                <input type='email' className='addEmployeeInput' name='recoveryEmail' value={values.recoveryEmail}
                 onChange={handleInputChange}
                 placeholder='admin@noise.com'
                />
              </div>
            </div>
          </form>
          <div className='horizontal'></div>
          <button style={{float:"right"}} className='navBtn' id='addSubSCEmpBtn' onClick={handleSaveBtn} ref={btnRef}>Save</button>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default AddEmployeeComponent