import React, { Component } from 'react'
import { connect } from "react-redux";
import moment from 'moment'
import { fetchTrackStatus } from '../../../actions/complaintsDataAction'
import ReactModal from 'react-modal';

//let tBktIds = [5, 6, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 33, 35, 41, 42, 45]

const customStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    inset: '8% 0 0 auto',
    marginRight: '0',
    transform: 'translate(0)',
    background: '#000',
    padding: '2rem',
    border: 'none',
    width: '35%',
    color: "#999999",
    marginTop: "2%"
  },
};

const customStylesTicketStatus = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    inset: '8% 0 0 auto',
    marginRight: '0',
    transform: 'translate(0)',
    background: '#000',
    padding: '2rem',
    border: 'none',
    width: '45%',
    color: "#999999",
    marginTop: "2%"
  },
};

class ProductDetails extends Component {

  constructor() {
    super();
    this.state = {
      showTrackStatusModal: false,
      showLiveStatusModal: false,
      showReverseTrackStatusModal: false,
      showReverseLiveStatusModal: false
    }
    this.handleForwardTrackStatusOpenModal = this.handleForwardTrackStatusOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleForwardLiveStatusOpenModal = this.handleForwardLiveStatusOpenModal.bind(this);
    // this.handleForwardLiveStatusCloseModal = this.handleForwardLiveStatusCloseModal.bind(this);
    this.handleReverseTrackStatusOpenModal = this.handleReverseTrackStatusOpenModal.bind(this);
    // this.handleReverseTrackStatusCloseModal = this.handleReverseTrackStatusCloseModal.bind(this);
    this.handleReverseLiveStatusOpenModal = this.handleReverseLiveStatusOpenModal.bind(this);
    // this.handleReverseLiveStatusCloseModal = this.handleReverseLiveStatusCloseModal.bind(this);
  }

  async handleReverseTrackStatusOpenModal() {
    let data = {
      "waybill": this.props.complaintsDetail.reverse_waybill_number,
      // "waybill" : "69630848534",
      "courier_id": this.props.complaintsDetail.reverse_courier_id,
      // "courier_id" : "4",
      "tracking": this.props.complaintsDetail.reverse_tracking,
    }
    await this.props.fetchTrackStatus(data)
    this.setState({
      showReverseTrackStatusModal: true
    })
  }

  async handleReverseLiveStatusOpenModal() {
    let data = {
      "waybill": this.props.complaintsDetail.reverse_waybill_number,
      // "waybill" : "69630848534",
      "courier_id": this.props.complaintsDetail.reverse_courier_id,
      // "courier_id" : "4",
      "tracking": this.props.complaintsDetail.reverse_tracking,
    }
    await this.props.fetchTrackStatus(data)
    this.setState({
      showReverseLiveStatusModal: true
    })
  }

  handleCloseModal() {
    this.setState({
      showLiveStatusModal: false,
      showTrackStatusModal: false,
      showReverseTrackStatusModal: false,
      showReverseLiveStatusModal: false
    })
  }

  async handleForwardTrackStatusOpenModal() {
    let data = {
      "waybill": this.props.complaintsDetail.forward_waybill_number,
      // "waybill" : "69630848534",
      "courier_id": this.props.complaintsDetail.forward_courier_id,
      // "courier_id" : "4",
      "tracking": this.props.complaintsDetail.forward_tracking,
    }
    await this.props.fetchTrackStatus(data)
    this.setState({
      showTrackStatusModal: true
    })
  }

  async handleForwardLiveStatusOpenModal() {
    let data = {
      "waybill": this.props.complaintsDetail.forward_waybill_number,
      // "waybill" : "69630848534",
      "courier_id": this.props.complaintsDetail.forward_courier_id,
      // "courier_id" : "4",
      "tracking": this.props.complaintsDetail.forward_tracking,
    }
    await this.props.fetchTrackStatus(data)
    this.setState({
      showLiveStatusModal: true
    })
  }

  // handleForwardTrackStatusCloseModal(){
  //   this.setState({
  //     showTrackStatusModal : false
  //   })
  // }

  render() {
    let item = this.props.complaintsDetail;
    return (
      <div className="product_details1" style={{heigth:"auto"}}>
        <div className="first">
          <div className="firstbox">
            <span>Product</span>
            <p>{item.product.product_name} ({item.size && "Size " + item.size} {item.product.color_name})</p>
          </div>
          {
            // tBktIds.includes(this.props.statusId) ?
            item.reverse_waybill_number !== "0" && item.reverse_waybill_number !== null ?
              <div className="secondbox">
                <span>Reverse Waybill Number</span>
                <p>{item.reverse_waybill_number || 'N/A'}</p>
                {
                  item.reverse_waybill_number ? <div>
                    <a className='ticket-status' onClick={this.handleReverseTrackStatusOpenModal}>Tracking Status</a>
                    <br></br>
                    <a  className='ticket-status' onClick={this.handleReverseLiveStatusOpenModal}>Live Status</a>
                  </div>
                    : null
                }
              </div> : null
          }
          <div className="thirdbox">
            <span>Reverse Pick-Up Cost</span>
            {/* TODO...  Not in api*/}
            <p>{item.reverse_courier_cost || 'N/A'}</p>
          </div>
          <div className="fourthbox">
            <span>Product Cost</span>
            {/* TODO...  check in api*/}
            <p>{item.product.product_price || 'N/A'}</p>
          </div>
          {item.upgradeProduct &&
            <div className="fourthbox">
              <span>Upgrade to</span>
              {/* TODO...  Not in api*/}
              <p>{item.upgradeProduct.product_name} {item.upgradeProduct.color_name}</p>
            </div>
          }
          {
            // tBktIds.includes(this.props.statusId) ?
            item.forward_waybill_number !== "0" && item.forward_waybill_number !== null ?
              <div className="fourthbox">
                <span>Forward Waybill Number</span>
                <p>{item.forward_waybill_number || 'N/A'}</p>
                {
                  item.forward_waybill_number ? <div>
                    <a  className='ticket-status' onClick={this.handleForwardTrackStatusOpenModal}>Tracking Status</a>
                    <br></br>
                    <a  className='ticket-status' onClick={this.handleForwardLiveStatusOpenModal}>Live Status</a>
                  </div> : null
                }
              </div> : null
          }
        </div>
        <div className="second">
          <div className="firstbox">
            <span>Purchase Date</span>
            <p>{item.purchase_date ? moment(item.purchase_date).format('MMMM DD, YYYY') : 'N/A'}</p>
          </div>
          
          <div className="thirdbox">
            <span>Forward Pick-Up Cost</span>
            {/* TODO...  Not in api*/}
            <p>{item.forward_courier_cost || 'N/A'}</p>
          </div>
          <div className="fourthbox">
            <span>Refunded Amount</span>
            {/* <p>{item.customer_order_id || 'N/A'}</p> */}
            <p>{item.refund_amount}</p>
          </div>
        </div>
        <div className="third">
          <div className="firstbox">
            <span>Operating System</span>
            {/* TODO...  Not in api*/}
            <p>{item.operating_software || 'N/A'}</p>
          </div>
          <div className="secondbox">
            <span>SLA Breached</span>
            {/* TODO...  only id in api*/}
            <p>{item.sla_breached ? "Yes" : "No"}</p>
          </div>
          {
            this.props.statusId === 15 || this.props.statusId === 16 || this.props.statusId === 17 || this.props.statusId === 18 ?
              <div className="thirdbox">
                <span>Testing Status</span>
                <p>{item.testing_status || 'N/A'}</p>
              </div> : null
          }
           <div className="fourthbox">
            <span>Order ID </span>
            {/* TODO...  Not in api*/}
            {/* <p>{item.operating_software || 'N/A'}</p> */}
            <p>{item.customer_order_id || 'N/A'}</p>
          </div>
        </div>
        <ReactModal
          isOpen={this.state.showTrackStatusModal}
          contentLabel="Minimal Modal Example"
          style={customStylesTicketStatus}
          ariaHideApp={false}
        >
          <h2 id="rModal_head" className='liveStatusHead'>Forward Waybill Ticket Status</h2>
          <button id="close_rModal" onClick={this.handleCloseModal}><i className="fas fa-times"></i></button>
          {
            this.props.trackStatusData.scans ? this.props.trackStatusData.scans.map((sd, index) => (
              <div key={index} className='liveStatus'>
                {sd.timestamp} - {sd.remark}
              </div>
            )) : <React.Fragment>
              {
                this.props.trackStatusData.shipment_track_activities ? this.props.trackStatusData.shipment_track_activities.map((sd, index) => (
                  <div key={index} className='liveStatus'>
                    {sd.date} - {sd.activity}
                  </div>
                )) : null
              }
            </React.Fragment>
          }
        </ReactModal>
        <ReactModal
          isOpen={this.state.showLiveStatusModal}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          ariaHideApp={false}
        >
          <h2 id="rModal_head" className='liveStatusHead'>Live Status</h2>
          <button id="close_rModal" onClick={this.handleCloseModal}><i className="fas fa-times"></i></button>
          <div className='liveStatus'>AWB Number
            <p>{this.props.complaintsDetail.forward_waybill_number || null}</p></div>
          <div className='liveStatus'>Courier
            <p>{this.props.trackStatusData.additional ? this.props.trackStatusData.additional.courier_partner_name : (this.props.trackStatusData.shipment_track ? this.props.trackStatusData.shipment_track[0].courier_partner_name : null)}</p>
          </div>
          <div className='liveStatus'>Status
            <p>{this.props.trackStatusData.latest_status ? this.props.trackStatusData.latest_status.remark : (this.props.trackStatusData.shipment_track ? this.props.trackStatusData.shipment_track[0].current_status : null)}</p>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={this.state.showReverseTrackStatusModal}
          contentLabel="Minimal Modal Example"
          style={customStylesTicketStatus}
          ariaHideApp={false}
        >
          <h2 id="rModal_head" className='liveStatusHead'>Reverse Waybill Ticket Status</h2>
          <button id="close_rModal" onClick={this.handleCloseModal}><i className="fas fa-times"></i></button>
          {
            this.props.trackStatusData.scans ? this.props.trackStatusData.scans.map((sd, index) => (
              <div key={index} className='liveStatus'>
                {sd.timestamp} - {sd.remark}
              </div>
            )) : <React.Fragment>
              {
                this.props.trackStatusData.shipment_track_activities ? this.props.trackStatusData.shipment_track_activities.map((sd, index) => (
                  <div key={index} className='liveStatus'>
                    {sd.date} - {sd.activity}
                  </div>
                )) : null
              }
            </React.Fragment>
          }
        </ReactModal>
        <ReactModal
          isOpen={this.state.showReverseLiveStatusModal}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          ariaHideApp={false}
        >
          <h2 id="rModal_head" className='liveStatusHead'>Live Status</h2>
          <button id="close_rModal" onClick={this.handleCloseModal}><i className="fas fa-times"></i></button>
          <div className='liveStatus'>AWB Number
            <p>{this.props.complaintsDetail.reverse_waybill_number || null}</p></div>
          <div className='liveStatus'>Courier
            <p>{this.props.trackStatusData.additional ? this.props.trackStatusData.additional.courier_partner_name : (this.props.trackStatusData.shipment_track ? this.props.trackStatusData.shipment_track[0].courier_partner_name : null)}</p>
          </div>
          <div className='liveStatus'>Status
            <p>{this.props.trackStatusData.latest_status ? this.props.trackStatusData.latest_status.remark : (this.props.trackStatusData.shipment_track ? this.props.trackStatusData.shipment_track[0].current_status : null)}</p>
          </div>
        </ReactModal>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  complaintsDetail: state.complaints.complaintsDetail,
  statusId: state.complaints.statusId,
  trackStatusData: state.complaints.trackStatusData
})
export default connect(mapStateToProps, { fetchTrackStatus })(ProductDetails);