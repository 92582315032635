import React, { useState, useEffect } from 'react';
import './ServiceCenterSidebar.css';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ServiceCenterSideBar({ changeActive, searchtext, setSearchText }) {

  const [active, setActive] = useState("");
  const activeFilter = useSelector((state) => state.serviceCenter.activeFilter);

  useEffect(() => {
    if (activeFilter !== undefined && activeFilter !== null) {
      changeActive(activeFilter)
      setActive(activeFilter)
      changeImgSrcOnMount()
    }
  });

  //setActive(false)

  const history = useHistory();

  const handleComplaintRegistration = () => {
    history.push('/complaint-registration-template')
  }

  const changeImgSrcOnMount = () => {
    let siblings = document.querySelector('.sideBarFilters').childNodes;
    if (activeFilter === 1) {
      siblings[0].firstChild.firstChild.src = siblings[0].firstChild.firstChild.dataset.src;
    }
    if (activeFilter === 2) {
      siblings[1].firstChild.firstChild.src = siblings[1].firstChild.firstChild.dataset.src;
    }
    if (activeFilter === 3) {
      siblings[2].firstChild.firstChild.src = siblings[2].firstChild.firstChild.dataset.src;
    }
    if (activeFilter === 4) {
      siblings[3].firstChild.firstChild.src = siblings[3].firstChild.firstChild.dataset.src;
    }
    if (activeFilter === 5) {
      document.getElementById('manageUsersIcon').src = '/img/ManageUsersIcon-blue.svg'
    }
  }

  const changeImgSrc = (buttonName) => {
    let siblings = document.querySelector('.sideBarFilters').childNodes;
    for (let i = 0; i < siblings.length; i++) {
      if (siblings[i].innerText === 'All' && siblings[i].innerText !== buttonName) {
        siblings[i].firstChild.firstChild.src = '/img/window.svg'
      }
      if (siblings[i].innerText === "Active" && siblings[i].innerText !== buttonName) {
        siblings[i].firstChild.firstChild.src = "/img/userLogo.svg"
      }
      if (siblings[i].innerText === 'Replacement' && siblings[i].innerText !== buttonName) {
        siblings[i].firstChild.firstChild.src = '/img/pauseIcon.svg'
      }
      if (siblings[i].innerText === 'Closed' && siblings[i].innerText !== buttonName) {
        siblings[i].firstChild.firstChild.src = '/img/clock.svg'
      }
    }
  }

  const handleIconChange = (e) => {
    setSearchText(false)
    document.getElementById('searchInput').value = '';
    let clickedBtnName = e.target.innerText;
    if (clickedBtnName === 'All') {
      changeActive(1);
      setActive(1);
      e.target.firstChild.src = e.target.firstChild.dataset.src;
      changeImgSrc(clickedBtnName);
      document.getElementById('manageUsersIcon').src = '/img/ManageUsersIcon.svg'
    } else if (clickedBtnName === 'Active') {
      changeActive(2);
      setActive(2);
      e.target.firstChild.src = e.target.firstChild.dataset.src;
      changeImgSrc(clickedBtnName);
    } else if (clickedBtnName === 'Replacement') {
      changeActive(3);
      setActive(3);
      e.target.firstChild.src = e.target.firstChild.dataset.src;
      changeImgSrc(clickedBtnName);
      document.getElementById('manageUsersIcon').src = '/img/ManageUsersIcon.svg'
    } else if (clickedBtnName === 'Closed') {
      changeActive(4);
      setActive(4);
      e.target.firstChild.src = e.target.firstChild.dataset.src;
      changeImgSrc(clickedBtnName);
      document.getElementById('manageUsersIcon').src = '/img/ManageUsersIcon.svg'
    } else if (clickedBtnName === 'Manage Users') {
      changeActive(5);
      setActive(5);
      e.target.firstChild.src = e.target.firstChild.dataset.src;
      changeImgSrc(clickedBtnName);
    }
  }

  const handleFilterClick = (e) => {
    setSearchText(false)
    let clickedBtnName = e.target.innerText
    if (clickedBtnName === 'All') {
      changeActive(1);
      setActive(1);
      e.target.firstChild.src = e.target.firstChild.dataset.src;
      changeImgSrc(clickedBtnName);
      document.getElementById('manageUsersIcon').src = '/img/ManageUsersIcon.svg'
    }
    if (clickedBtnName === 'Active') {
      changeActive(2);
      setActive(2);
      e.target.firstChild.src = e.target.firstChild.dataset.src;
      changeImgSrc(clickedBtnName);
    }
    if (clickedBtnName === 'Replacement') {
      changeActive(3);
      setActive(3);
      e.target.firstChild.src = e.target.firstChild.dataset.src;
      changeImgSrc(clickedBtnName);
      document.getElementById('manageUsersIcon').src = '/img/ManageUsersIcon.svg'
    }
    if (clickedBtnName === 'Closed') {
      changeActive(4);
      setActive(4);
      e.target.firstChild.src = e.target.firstChild.dataset.src;
      changeImgSrc(clickedBtnName);
      document.getElementById('manageUsersIcon').src = '/img/ManageUsersIcon.svg'
    }
    if (clickedBtnName === 'Manage Users') {
      changeActive(5);
      setActive(5);
      e.target.firstChild.src = e.target.firstChild.dataset.src;
      changeImgSrc(clickedBtnName);
    }
  }

  if (searchtext === true) {
    let active = document.getElementById('active');
    if(active !== null)
      active.firstChild.firstChild.src = active.firstChild.firstChild.dataset.orgsrc;
  }

  return (
    <div className='serviceCenterSidebar'>
      <h4 className='sideBarHeading'>Complaints</h4>
      {searchtext === true ?
        <div>
          <ul className='sideBarFilters'>
            <li><a onClick={handleIconChange}><img src='/img/window.svg' data-src='/img/window.svg' data-orgsrc='/img/window.svg' alt='window logo Icon' />All</a></li>
            <li><a onClick={handleIconChange}><img src='/img/userLogo.svg' data-src='/img/userLogo.svg' data-orgsrc='/img/userLogo.svg' alt='User Logo Icon' />Active</a></li>
            <li><a onClick={handleIconChange}><img src='/img/pauseIcon.svg' data-src='/img/pauseIcon.svg' data-orgsrc='/img/pauseIcon.svg' alt='Replace Icon' />Replacement</a></li>
            <li><a onClick={handleIconChange}><img id='clockIcon' src='/img/clock.svg' data-src='/img/clock.svg' data-orgsrc='/img/clock.svg' alt='Clock Icon' />Closed</a></li>
          </ul>
          <button onClick={handleComplaintRegistration} className='sideBarBtn' id='complaintBtn'>
            <i className="fa fa-plus" aria-hidden="true"></i>
            &nbsp;New Complaint
          </button>
          <h4 className='sideBarHeading'>Manage</h4>
          <ul className='sideBarFilters'>
            <li><a onClick={handleFilterClick}><img id='manageUsersIcon' data-src='/img/ManageUsersIcon-blue.svg' src='/img/ManageUsersIcon.svg' alt='user-icon' />Manage Users</a></li>
          </ul>
        </div>
        :
        <div>
          <ul className='sideBarFilters'>
            <li id={active === 1 ? 'active' : ''} ><a onClick={handleFilterClick}><img data-src='/img/window-blue.svg' src='/img/window.svg' data-orgsrc='/img/window.svg' alt='window logo Icon' />All</a></li>
            <li id={active === 2 ? 'active' : ''}><a onClick={handleFilterClick}><img data-src='/img/userLogo-blue.svg' src='/img/userLogo.svg' data-orgsrc='/img/userLogo.svg' alt='User Logo Icon' />Active</a></li>
            <li id={active === 3 ? 'active' : ''}><a onClick={handleFilterClick}><img data-src='/img/pauseIcon-blue.svg' src='/img/pauseIcon.svg' data-orgsrc='/img/pauseIcon.svg' alt='Replace Icon' />Replacement</a></li>
            <li id={active === 4 ? 'active' : ''}><a onClick={handleFilterClick}><img id='clockIcon' data-src='/img/clock-blue.svg' src='/img/clock.svg' data-orgsrc='/img/clock.svg' alt='Clock Icon' />Closed</a></li>
          </ul>
          <button onClick={handleComplaintRegistration} className='sideBarBtn' id='complaintBtn'>
            <i className="fa fa-plus" aria-hidden="true"></i>
            &nbsp;New Complaint
          </button>
          <h4 className='sideBarHeading'>Manage</h4>
          <ul className='sideBarFilters'>
            <li id={active === 5 ? 'active' : ''}><a onClick={handleFilterClick}><img id='manageUsersIcon' data-src='/img/ManageUsersIcon-blue.svg' src='/img/ManageUsersIcon.svg' alt='user-icon' />Manage Users</a></li>
          </ul>
        </div>
      }
    </div>
  )
}

export default ServiceCenterSideBar;