import React, { Component } from "react";
import "./ProductComponent.css";
import { connect } from "react-redux";
import history from "../../history";
// import moment from 'moment'
// import Loader from 'react-loader-spinner'
import {
  getProductData,
  createProduct,
  getProductDetail,
  editProductData,
  getGroupData,
  removeProductinfo,
  getProductNameData,
  getProductColorData,
  setProductID,
  addNewColor,
} from "../../actions/productActions";
import ViewProfile from "../ViewProfile";
import AccessRestricted from "../AccessRestricted";
import ComplaintRegistration from "../ComplaintRegistration";
import Footer from "../FooterComponent/footer";
import SidebarComponent from "../SidebarComponent/SidebarComponent";
import AddColorComponent from "./AddColorComponent";
import ReactModal from "react-modal";
const customStylesDO = {
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    inset: "8% 0 0 auto",
    marginRight: "0",
    transform: "translate(0)",
    background: "#000",
    padding: "2rem",
    border: "none",
    width: "35%",
    position: "absolute",
  },
};

class ProductComponent extends Component {
  constructor() {
    super();

    this.state = {
      //showMenu: false,
      pageNo: 0,
      //pageLimit: 10,
      pageLimit: 10,
      searchText: "",
      logout: false,
      //searchDate: '',
      productList: [],
      groupList: [],
      productDetails: [],
      productGroupList: [],
      productNameListData: [],
      productColorList: [],
      //prevPage: false,
      //nextPage: false,
      errors: [],
      productId: "",
      product_name: "",
      product_url: "",
      warranty_in_months: "",
      product_price: "",
      sku_code: "",
      buy_back_value: "",
      troubleshoot_url: "",
      delhivery_st_vol_weight: "",
      delhivery_st_actual_weight: "",
      express_st_vol_weight: "",
      express_st_actual_weight: "",
      bluedart_st_vol_weight: "",
      bluedart_st_actual_weight: "",
      xpressbees_air_st_vol_weight: "",
      xpressbees_air_st_actual_weight: "",
      ecom_express_st_vol_weight: "",
      ecom_express_st_actual_weight: "",
      shadowfax_st_vol_weight: "",
      shadowfax_st_actual_weight: "",
      product_color: "",
      product_group_id: "",
      product_color_id: "",
      product_color_name_id: "",
      limit: "",
      totalcount: "",
      prevPage: "",
      nextPage: "",
      productResponse1: [],
      pageNos: [
        { id: 1, value: "10" },
        { id: 2, value: "25" },
        { id: 3, value: "50" },
      ],
      showcolorModal: false,
      colorName: "",
      colorDesctiption: "",
      isproductAdd: false,
      isEdit: false,
      isAdd:false,
      editProduct: {},
    };

    this.showMenu = this.showMenu.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout() {
    localStorage.removeItem("token");
    document.querySelector(".modal-backdrop").style.display = "none";
    history.push("/");
  }

  handleIconDetails() {
    this.setState({
      logout: !this.state.logout,
    });
  }

  onClearForm = (e) => {
    this.setState({
      productId: "",
      product_name: "",
      product_url: "",
      warranty_in_months: "",
      product_price: "",
      sku_code: "",
      buy_back_value: "",
      troubleshoot_url: "",
      delhivery_st_vol_weight: "",
      delhivery_st_actual_weight: "",
      express_st_vol_weight: "",
      express_st_actual_weight: "",
      bluedart_st_vol_weight: "",
      bluedart_st_actual_weight: "",
      xpressbees_air_st_vol_weight: "",
      xpressbees_air_st_actual_weight: "",
      ecom_express_st_vol_weight: "",
      ecom_express_st_actual_weight: "",
      shadowfax_st_vol_weight: "",
      shadowfax_st_actual_weight: "",
      product_color_name_id: "",
    });
  };

  componentDidMount() {
    this.getProductList();

    //this.getProductName();
    // this.getProductColor();
    // this.getProductGroup();
  }

  getProductGroup = async () => {
    this.setState({ isLoading: true, List: [] });
    await this.props.getGroupData();
    let groupDataList = this.props.groupData;
    this.setState({
      productGroupList: groupDataList.data,
    });
  };

  getProductColor = async () => {
    this.setState({ isLoading: true, productColorList: [] });
    let data = {
      offset: 0,
      limit: 300,
    };
    await this.props.getProductColorData(data);
    let colorData = this.props.clolorList;
    this.setState({
      productColorList: colorData.data ? colorData.data.colors : "",
    });
  };

  getProductName = async (reqest) => {
    this.setState({ isLoading: true, productNameListData: [] });
    await this.props.getProductNameData(reqest);
    let productNameData = this.props.productNameList;
    this.setState({
      productNameListData: productNameData.data,
    });
  };

  onProductGroupChange = (e) => {
    this.setState({ product_group_id: e.target.value }, () => {});

    let data = {
      category_id: e.target.value,
    };
    this.getProductName(data);
  };

  onProductColorChange = (e) => {
    this.setState({ product_color: e.target.value }, () => {});
  };

  onProductNameChange = (e) => {
    this.setState({ product_name: e.target.value }, () => {});
  };

  onPageLimitChange = (e) => {
    this.setState({ pageLimit: e.target.value }, () => {
      this.getProductList();
    });
  };

  onPrevPress = () => {
    if (this.state.prevPage) {
      this.setState({ pageNo: this.state.pageNo - 1 }, () => {
        this.getProductList();
      });
    }
  };

  onNextPress = () => {
    if (this.state.nextPage) {
      this.setState({ pageNo: this.state.pageNo + 1 }, () => {
        this.getProductList();
      });
    }
  };

  // onNumberLimitChange = (e) => {
  //   this.setState({ limit: e.target.value }, () => {
  //   })
  //   alert(this.state.limit);
  //   this.getProductList();
  // }

  onNumberLimitChange(selVal) {
    this.setState({ limit: selVal }, () => {
      this.getProductList();
    });
  }

  getProductList = async () => {
    let data = {
      //"page_no": this.state.pageNo,
      limit: Number(this.state.pageLimit),
      //"status_by": this.state.searchText,
      //"problem_by": 1,
      //"search_by_date": this.state.searchDate
      filterText: this.state.searchText,
      offset: Number(this.state.pageNo),
    };
    this.setState({ isLoading: true, productList: [] });
    //await this.props.getUserData(data);
    await this.props.getProductData(data);
    let productData = this.props.productData;
    if (productData.success) {
      this.setState({
        isLoading: false,
        prevPage: productData.data.offset,
        nextPage: productData.data.next_page,
        totalcount: productData.data.count,
        limit: this.state.limit,
        productList: productData.data.products,
      });
    }
  };

  handleValidationadd() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.product_color) {
      formIsValid = false;
      errors["error_product_color"] = "Cannot be empty";
    }

    if (!this.state.product_url) {
      formIsValid = false;
      errors["error_product_url"] = "Cannot be empty";
    }

    if (!this.state.product_price) {
      formIsValid = false;
      errors["error_product_price"] = "Cannot be empty";
    }

    if (this.state.product_price < 1) {
      formIsValid = false;
      errors["error_product_price"] = "Enter a valid price";
    }

    if (this.state.product_price !== "0") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(this.state.product_price)) {
        formIsValid = false;
        errors["error_product_price"] = "Enter only number";
      }
    }

    // if (!this.state.troubleshoot_url) {
    //   formIsValid = false;
    //   errors["error_troubleshoot_url"] = "Cannot be empty";
    // }

    if (!this.state.sku_code) {
      formIsValid = false;
      errors["error_sku_code"] = "Cannot be empty";
    }

    if (!this.state.product_group_id) {
      formIsValid = false;
      errors["error_product_group_id"] = "Cannot be empty";
    }
    if (!this.state.product_name) {
      formIsValid = false;
      errors["error_product_name"] = "Cannot be empty";
    }

    if (!this.state.warranty_in_months) {
      formIsValid = false;
      errors["error_warranty_in_months"] = "Cannot be empty";
    }
    if (this.state.warranty_in_months !== "") {
      if (
        this.state.warranty_in_months === 3 ||
        this.state.warranty_in_months === 6 ||
        this.state.warranty_in_months === 9 ||
        this.state.warranty_in_months === 12 ||
        this.state.warranty_in_months === 18 ||
        this.state.warranty_in_months === 24
      ) {
        errors["error_warranty_in_months"] = "";
      } else {
        formIsValid = false;
        errors["error_warranty_in_months"] = "Enter a valide warranty months";
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleValidation() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.product_color || this.state.product_color === 0) {
      formIsValid = false;
      errors["edit_error_product_color"] = "Cannot be empty";
    }

    if (!this.state.product_url) {
      formIsValid = false;
      errors["edit_error_product_url"] = "Cannot be empty";
    }

    if (!this.state.product_price) {
      formIsValid = false;
      errors["edit_error_product_price"] = "Cannot be empty";
    }

    if (this.state.product_price < 1) {
      formIsValid = false;
      errors["edit_error_product_price"] = "Enter a valid price";
    }

    if (this.state.product_price !== "0") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(this.state.product_price)) {
        formIsValid = false;
        errors["edit_error_product_price"] = "Enter only number";
      }
    }

    if (!this.state.sku_code) {
      formIsValid = false;
      errors["edit_error_sku_code"] = "Cannot be empty";
    }

    if (!this.state.product_group_id || this.state.product_group_id === 0) {
      formIsValid = false;
      errors["edit_error_product_group_id"] = "Cannot be empty";
    }
    if (!this.state.product_name || this.state.product_color === 0) {
      formIsValid = false;
      errors["edit_error_product_name"] = "Cannot be empty";
    }

    if (!this.state.warranty_in_months) {
      formIsValid = false;
      errors["edit_error_warranty_in_months"] = "Cannot be empty";
    }

    if (this.state.warranty_in_months !== "") {
      if (
        this.state.warranty_in_months === 3 ||
        this.state.warranty_in_months === 6 ||
        this.state.warranty_in_months === 9 ||
        this.state.warranty_in_months === 12 ||
        this.state.warranty_in_months === 18 ||
        this.state.warranty_in_months === 24
      ) {
        errors["edit_error_warranty_in_months"] = "";
      } else {
        formIsValid = false;
        errors["edit_error_warranty_in_months"] =
          "Enter a valide warranty months";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleEdit = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // onProductChange = (e) => {
  //   this.setState({ productId: e.target.value }, () => {
  //     this.getComplaintList();
  //   })
  // }

  onEditClickData = (de) => {
    //e.preventDefault()

    this.setState({ product_color_id: de }, () => {
      this.onEditClick();
    });
  };

  // onEditClick = async () => {
  //   let data = {
  //     "product_color_id": this.state.product_color_id
  //   }
  //   this.setState({ isLoading: true, productDetails: [] })
  //   await this.props.getProductDetail(data);
  //   //let detailsData = this.props.detailData;
  //    this.setState({
  //  // productDetails: detailsData.data,
  //  })
  // }

  onEditClick = (e, item) => {
    this.onResetErorClick();
    let data = {
      category_id: item.product_group_id,
    };
    this.getProductName(data);
    this.setState({
      product_color_id: item.product_color_id,
      product_color: item.color_id,
      product_group_id: item.product_group_id,
      productId: item.id,
      product_name: item.product_name,
      product_url: item.product_image_url,
      warranty_in_months: item.warranty_in_months,
      product_price: item.product_price,
      sku_code: item.sku_code,
      buy_back_value: item.buy_back_value,
      troubleshoot_url: item.troubleshoot_url,
      delhivery_st_vol_weight: item.dv_vol_weight,
      delhivery_st_actual_weight: item.dv_act_weight,
      express_st_vol_weight: item.dv_exp_vol_weight,
      express_st_actual_weight: item.dv_exp_act_weight,
      bluedart_st_vol_weight: item.bd_vol_weight,
      bluedart_st_actual_weight: item.bd_act_weight,
      xpressbees_air_st_vol_weight: item.xp_air_vol_weight,
      xpressbees_air_st_actual_weight: item.xp_air_act_weight,
      ecom_express_st_vol_weight: item.ecom_xp_vol_weight,
      ecom_express_st_actual_weight: item.ecom_xp_act_weight,
      shadowfax_st_vol_weight: item.sf_vol_weight,
      shadowfax_st_actual_weight: item.sf_act_weight,
    });
  };

  showMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true });
  }

  handleDate = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.getUserList();
    });
  };

  //this.setState({ [e.target.name]: e.target.value })

  handleChange = (e) => {
    if (e.target.name === "searchByname") {
      this.setState({ searchText: e.target.value }, () => {
        this.getProductList();
      });
    } else {
      this.setState({ [e.target.name]: e.target.value }, () => {});
    }
  };

  handleSubmit(e) {
    e.preventDefault();
    let data = {
      color_id: this.state.product_color,
      product_image_url: this.state.product_url,
      product_price: this.state.product_price,
      troubleshoot_url: this.state.troubleshoot_url,
      sku_code: this.state.sku_code,
      buy_back_value: this.state.buy_back_value
        ? this.state.buy_back_value
        : 0.0,
      product_group_id: this.state.product_group_id,
      warranty_in_months: this.state.warranty_in_months,
      product_id: this.state.product_name,
      dv_vol_weight: this.state.delhivery_st_vol_weight
        ? this.state.delhivery_st_vol_weight
        : 0.0,
      dv_act_weight: this.state.delhivery_st_actual_weight
        ? this.state.delhivery_st_actual_weight
        : 0.0,
      dv_exp_vol_weight: this.state.express_st_vol_weight
        ? this.state.express_st_vol_weight
        : 0.0,
      dv_exp_act_weight: this.state.express_st_actual_weight
        ? this.state.express_st_actual_weight
        : 0.0,
      xp_air_vol_weight: this.state.xpressbees_air_st_vol_weight
        ? this.state.xpressbees_air_st_vol_weight
        : 0.0,
      xp_air_act_weight: this.state.xpressbees_air_st_actual_weight
        ? this.state.xpressbees_air_st_actual_weight
        : 0.0,
      bd_vol_weight: this.state.bluedart_st_vol_weight
        ? this.state.bluedart_st_vol_weight
        : 0.0,
      bd_act_weight: this.state.bluedart_st_actual_weight
        ? this.state.bluedart_st_actual_weight
        : 0.0,
      ecom_xp_vol_weight: this.state.ecom_express_st_vol_weight
        ? this.state.ecom_express_st_vol_weight
        : 0.0,
      ecom_xp_act_weight: this.state.ecom_express_st_actual_weight
        ? this.state.ecom_express_st_actual_weight
        : 0.0,
      sf_vol_weight: this.state.shadowfax_st_vol_weight
        ? this.state.shadowfax_st_vol_weight
        : 0.0,
      sf_act_weight: this.state.shadowfax_st_actual_weight
        ? this.state.shadowfax_st_actual_weight
        : 0.0,
    };

    if (this.handleValidationadd() !== false) {
      this.props.createProduct(data);
      // let productResponse = this.props.productResponse;
      this.getProductList();
      document.getElementById("add_form").reset();
      // var element = document.querySelector(".modal-backdrop");
      // element.classList.remove("show");
      // document.getElementById("exampleModal").style.display = "none";
      // document.querySelector(".modal-backdrop").style.display = "none";

      //     var link = document.getElementById('product_close_icon_id_add');
      // link.click();
      this.onClearForm();
      //this.props.location && this.props.location.callBack && this.props.location.callBack()
      //history.goBack();
      //}
    }
  }
  handleEdit = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onUpdateClick = (e) => {
    e.preventDefault();
    let data = {
      product_color_id: this.state.product_color_id,
      color_id: this.state.product_color,
      product_id: this.state.productId,
      product_image_url: this.state.product_url,
      product_price: this.state.product_price,
      troubleshoot_url: this.state.troubleshoot_url,
      sku_code: this.state.sku_code,
      buy_back_value: this.state.buy_back_value
        ? this.state.buy_back_value
        : 0.0,
      product_group_id: this.state.product_group_id,
      warranty_in_months: this.state.warranty_in_months,
      dv_vol_weight: this.state.delhivery_st_vol_weight
        ? this.state.delhivery_st_vol_weight
        : 0.0,
      dv_act_weight: this.state.delhivery_st_actual_weight
        ? this.state.delhivery_st_actual_weight
        : 0.0,
      dv_exp_vol_weight: this.state.express_st_vol_weight
        ? this.state.express_st_vol_weight
        : 0.0,
      dv_exp_act_weight: this.state.express_st_actual_weight
        ? this.state.express_st_actual_weight
        : 0.0,
      xp_air_vol_weight: this.state.xpressbees_air_st_vol_weight
        ? this.state.xpressbees_air_st_vol_weight
        : 0.0,
      xp_air_act_weight: this.state.xpressbees_air_st_actual_weight
        ? this.state.xpressbees_air_st_actual_weight
        : 0.0,
      bd_vol_weight: this.state.bluedart_st_vol_weight
        ? this.state.bluedart_st_vol_weight
        : 0.0,
      bd_act_weight: this.state.bluedart_st_actual_weight
        ? this.state.bluedart_st_actual_weight
        : 0.0,
      ecom_xp_vol_weight: this.state.ecom_express_st_vol_weight
        ? this.state.ecom_express_st_vol_weight
        : 0.0,
      ecom_xp_act_weight: this.state.ecom_express_st_actual_weight
        ? this.state.ecom_express_st_actual_weight
        : 0.0,
      sf_vol_weight: this.state.shadowfax_st_vol_weight
        ? this.state.shadowfax_st_vol_weight
        : 0.0,
      sf_act_weight: this.state.shadowfax_st_actual_weight
        ? this.state.shadowfax_st_actual_weight
        : 0.0,
    };

    if (this.handleValidation() !== false) {
      this.callEditApi(data);
    }
  };
  onResetErorClick = (e) => {
    this.setState({ errors: [] });
  };

  // onDeleteClick = (e,item) => {
  //   e.preventDefault()
  //   let data = {
  //     "product_id": item.id,
  //   }

  //   this.callDeleteApi(data)
  // }

  onDeleteClick = (de) => {
    this.callDeleteApi(de);
  };

  callDeleteApi = async (param) => {
    await this.props.removeProductinfo(param);
    //let editRes = this.props.editData
    this.getProductList();
  };

  callEditApi = async (param) => {
    await this.props.editProductData(param);
    let editRes = this.props.editData;

    if (editRes.message === "Product Updated Successfully.") {
      alert(editRes.message);
      var link = document.getElementById("product_close_icon_id");
      link.click();

      this.getProductList();
    } else {
      alert(editRes.message);
    }
    //alert(editRes.message);
  };

  handleEditProduct = (value, edit, add) => {
    this.setState({
      editProduct: value,
      isproductAdd: true,
      isEdit: edit,
      isAdd: add,
    });
  };

  handleValidationaddColor() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.colorName) {
      formIsValid = false;
      errors["error_color"] = "Color can't be empty";
    }

    if (!this.state.colorDesctiption) {
      formIsValid = false;
      errors["error_description"] = " Description Can't be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleColorAdd = async (a) => {
    let validation = this.handleValidationaddColor();
    if (validation) {
      let data = {
        color_name: this.state.colorName,
        description: this.state.colorDesctiption,
      };
      this.setState({ isLoading: true, showcolorModal: false });
      let response = await this.props.addNewColor(data);
      if (response.success) {
        this.setState({ isLoading: false });
        alert(response.message);
      }
    }
  };

  cancleAddProduct = () => {
    this.setState({
      isproductAdd: false,
      colorName: "",
      colorDesctiption: "",
      isEdit: false,
      isAdd:false,
      editProduct: {},
    });
    this.getProductList();
  };

  render() {
    let userRoleId = localStorage.getItem("userRoleId");
    if (
      userRoleId === "4" ||
      userRoleId === "8" ||
      userRoleId === "9" ||
      userRoleId === "10"
    ) {
      return <AccessRestricted />;
    } else if (userRoleId === "6") {
      return <ComplaintRegistration />;
    } else {
      return (
        <div className="manage-product product-component-main">
         {window.innerWidth > 600 && <SidebarComponent sideProp={"MP"} />}
          <ViewProfile />

          <div className="content">
            <div className="search_header_product">
              <span>Product</span>
              <div className="search" style={{ marginRight: "unset" }}>
                <i className="fa fa-search"></i>
                <input
                  type="username"
                  style={{ width: "230px" }}
                  name="searchByname"
                  placeholder=" Search By Product Name, Product Prefix"
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <ul id="menu">
              <li className="actice2">
                <a href="/manage-product">Product Info</a>
              </li>
              <li>
                <a href="/manage-group" className="noactive">
                  Product Group
                </a>
              </li>
              <li>
                <a href="/manage-message" className="noactive">
                  Message
                </a>
              </li>
              <li>
                <a href="/channels" className="noactive">
                  Channels
                </a>
              </li>
            </ul>
            {!this.state.isproductAdd && (
              <div className="add-color-product">
                <button
                  onClick={() =>
                    this.setState({
                      isproductAdd: true,
                      colorName: "",
                      colorDesctiption: "",
                      isEdit: false,
                      isAdd:false,
                      editProduct: {},
                    })
                  }
                  className="add-message-btn add-color">
                  Add product
                </button>
                <button
                  onClick={() =>
                    this.setState({
                      showcolorModal: true,
                      colorName: "",
                      colorDesctiption: "",
                      isEdit: false,
                      isAdd:false,
                      editProduct: {},
                    })
                  }
                  className="add-message-btn add-color">
                  Add color
                </button>
              </div>
            )}
            <div className="list">
              {/* <i className="fa fa-search"></i>
            <input type="username" name="searchByname" placeholder="Search By Product Name, Product Prefix" onChange={this.handleChange} /> */}
              <button
                type="button"
                onClick={(e) => this.onResetErorClick(e)}
                data-toggle="modal"
                data-target="#exampleModal">
                Add Product
              </button>

              <div className="modal fade" id="exampleModal">
                <div className="modal-dialog" role="document">
                  <div className="moc">
                    <form action="" id="add_form" onSubmit={this.handleSubmit}>
                      <div className="modal-header">
                        <div className="list2 add_product">
                          <span>Add Product</span>
                        </div>
                        <div
                          data-dismiss="modal"
                          aria-label="Close"
                          id="product_close_icon_id_add"
                          className="close_icon">
                          <span aria-hidden="true">
                            <i
                              className="fas fa-times"
                              style={{ color: "#fff" }}></i>
                          </span>
                        </div>
                      </div>

                      <div className="form-row mg-left add_product_form">
                        <div className="col-md-6" style={{ margintop: "0px" }}>
                          <label
                            className="lableClass"
                            htmlFor="validationCustom01">
                            Product Group Id <span className="mdcolor">*</span>
                          </label>
                          <select
                            className="dropbtn1111"
                            onChange={(e) => this.onProductGroupChange(e)}>
                            {/* <i className="fas fa-chevron-down"></i> */}
                            <option value={0}> Product Group Id </option>
                            {this.state.productGroupList.map((obj, index) => (
                              <option
                                value={obj.id}
                                key={index}
                                selected={
                                  Number(obj.id) ===
                                  Number(this.state.product_group_id)
                                }>
                                {obj.group_name}
                              </option>
                            ))}
                          </select>
                          <div className="errorpd">
                            <span className="errorShow">
                              {this.state.errors["error_product_group_id"]}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom02">
                            Product Color <span className="mdcolor">*</span>
                          </label>
                          <select
                            className="dropbtn1111"
                            onChange={(e) => this.onProductColorChange(e)}>
                            {/* <i className="fas fa-chevron-down"></i> */}
                            <option value={0}> Product Color </option>
                            {this.state.productColorList.length > 0 &&
                              this.state.productColorList.map((obj) => (
                                <option value={obj.id}>{obj.name}</option>
                              ))}
                          </select>
                          <div className="errorpd">
                            <span className="errorShow">
                              {this.state.errors["error_product_color"]}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-row mg-left">
                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom01">
                            Product Name <span className="mdcolor">*</span>
                          </label>
                          <select
                            className="dropbtn1111"
                            onChange={(e) => this.onProductNameChange(e)}>
                            {/* <i className="fas fa-chevron-down"></i> */}
                            <option value={0}> Product Name </option>
                            {this.state.productNameListData?.map((obj) => (
                              <option value={obj.product_group_id}>
                                {obj.product_name}
                              </option>
                            ))}
                          </select>
                          <div className="errorpd">
                            <span className="errorShow">
                              {this.state.errors["error_product_name"]}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom02">
                            Product URL <span className="mdcolor">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="product_url"
                            onChange={this.handleChange}
                            placeholder="Product URL"
                            required
                          />
                          <div className="errorpd">
                            <span className="errorShow">
                              {this.state.errors["error_product_url"]}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="form-row mg-left">
                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom01">
                            Warranty In Months{" "}
                            <span className="mdcolor">*</span>
                          </label>
                          <input
                            type="text"
                            onKeyPress={(event) => {
                              // if (!/[0-9]/.test(event.key)) {
                              //   event.preventDefault();
                              // }
                            }}
                            className="form-control"
                            name="warranty_in_months"
                            placeholder="Warranty In Months"
                            onChange={this.handleChange}
                            required
                          />
                          <div className="errorpd">
                            <span className="errorShow">
                              {this.state.errors["error_warranty_in_months"]}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom02">
                            Product Price <span className="mdcolor">*</span>
                          </label>
                          <input
                            type="text"
                            onKeyPress={(event) => {
                              // if (!/[0-9\.]/.test(event.key)) {
                              //   event.preventDefault();
                              // }
                            }}
                            className="form-control"
                            name="product_price"
                            placeholder="Product Price"
                            onChange={this.handleChange}
                            required
                          />
                          <div className="errorpd">
                            <span className="errorShow">
                              {this.state.errors["error_product_price"]}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="form-row mg-left tt">
                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom01">
                            Troubleshoot URL
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="troubleshoot_url"
                            placeholder="Troubleshoot URL"
                            onChange={this.handleChange}
                            required
                          />
                          <div className="errorpd">
                            {/* <span className="errorShow">{this.state.errors["error_product_url"]}</span> */}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom02">
                            Buy Back Value
                          </label>
                          <input
                            type="text"
                            onKeyPress={(event) => {
                              // if (!/[0-9\.]/.test(event.key)) {
                              //   event.preventDefault();
                              // }
                            }}
                            className="form-control"
                            name="buy_back_value"
                            placeholder="Buy Back Value"
                            id="buy_back_value"
                            onChange={this.handleChange}
                            required
                          />
                          <div className="errorpd">
                            {/* <span className="errorShow">{this.state.errors["last_name"]}</span> */}
                          </div>
                        </div>
                      </div>
                      <div className="form-row mg-left">
                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom01">
                            {" "}
                            SKU Code <span className="mdcolor">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="sku_code"
                            placeholder="SKU Code"
                            onChange={this.handleChange}
                            required
                          />
                          <div className="errorpd">
                            <span className="errorShow">
                              {this.state.errors["error_sku_code"]}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="form-row mg-left">
                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom02">
                            Delhivery standards volumetric Weight
                          </label>
                          <input
                            type="text"
                            onKeyPress={(event) => {
                              // if (!/[0-9\.]/.test(event.key)) {
                              //   event.preventDefault();
                              // }
                            }}
                            className="form-control"
                            name="delhivery_st_vol_weight"
                            placeholder="Delhivery_Standards volumetric Weight"
                            onChange={this.handleChange}
                            required
                          />
                          <div className="errorpd">
                            {/* <span className="errorShow">{this.state.errors["last_name"]}</span> */}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom02">
                            Delhivery standards actual weight
                          </label>
                          <input
                            type="text"
                            onKeyPress={(event) => {
                              // if (!/[0-9\.]/.test(event.key)) {
                              //   event.preventDefault();
                              // }
                            }}
                            className="form-control"
                            name="delhivery_st_actual_weight"
                            placeholder="Delhivery_Standards Actual Weight"
                            onChange={this.handleChange}
                            required
                          />
                          <div className="errorpd">
                            {/* <span className="errorShow">{this.state.errors["last_name"]}</span> */}
                          </div>
                        </div>
                      </div>

                      <div className="form-row mg-left">
                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom01">
                            Express volumetric Weight
                          </label>
                          <input
                            type="text"
                            onKeyPress={(event) => {
                              // if (!/[0-9\.]/.test(event.key)) {
                              //   event.preventDefault();
                              // }
                            }}
                            className="form-control"
                            name="express_st_vol_weight"
                            placeholder="Express volumetric Weight"
                            onChange={this.handleChange}
                            required
                          />
                          <div className="errorpd">
                            {/* <span className="errorShow">{this.state.errors["first_name"]}</span> */}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom01">
                            Express Actual Weight
                          </label>
                          <input
                            type="text"
                            onKeyPress={(event) => {
                              // if (!/[0-9\.]/.test(event.key)) {
                              //   event.preventDefault();
                              // }
                            }}
                            className="form-control"
                            name="express_st_actual_weight"
                            placeholder="Express Actual Weight"
                            onChange={this.handleChange}
                            required
                          />
                          <div className="errorpd">
                            {/* <span className="errorShow">{this.state.errors["first_name"]}</span> */}
                          </div>
                        </div>
                      </div>
                      <div className="form-row mg-left">
                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom02">
                            Xpressbees_Air volumetric Weight
                          </label>
                          <input
                            type="text"
                            onKeyPress={(event) => {
                              // if (!/[0-9\.]/.test(event.key)) {
                              //   event.preventDefault();
                              // }
                            }}
                            className="form-control"
                            name="xpressbees_air_st_vol_weight"
                            placeholder="Xpressbees_Air volumetric Weight"
                            id="buy_back_value"
                            onChange={this.handleChange}
                            required
                          />
                          <div className="errorpd">
                            {/* <span className="errorShow">{this.state.errors["last_name"]}</span> */}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom02">
                            Xpressbees_Air Actual Weight
                          </label>
                          <input
                            type="text"
                            onKeyPress={(event) => {
                              // if (!/[0-9\.]/.test(event.key)) {
                              //   event.preventDefault();
                              // }
                            }}
                            className="form-control"
                            name="xpressbees_air_st_actual_weight"
                            placeholder="Xpressbees_Air Actual Weight"
                            onChange={this.handleChange}
                            required
                          />
                          <div className="errorpd">
                            {/* <span className="errorShow">{this.state.errors["last_name"]}</span> */}
                          </div>
                        </div>
                      </div>

                      <div className="form-row mg-left">
                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom01">
                            Bluedart volumetric Weight
                          </label>
                          <input
                            type="text"
                            onKeyPress={(event) => {
                              // if (!/[0-9\.]/.test(event.key)) {
                              //   event.preventDefault();
                              // }
                            }}
                            className="form-control"
                            name="bluedart_st_vol_weight"
                            placeholder="Bluedart volumetric Weight"
                            onChange={this.handleChange}
                            required
                          />
                          <div className="errorpd">
                            {/* <span className="errorShow">{this.state.errors["first_name"]}</span> */}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom01">
                            Bluedart Actual Weight
                          </label>
                          <input
                            type="text"
                            onKeyPress={(event) => {
                              // if (!/[0-9\.]/.test(event.key)) {
                              //   event.preventDefault();
                              // }
                            }}
                            className="form-control"
                            name="bluedart_st_actual_weight"
                            placeholder="Bluedart Actual Weight"
                            onChange={this.handleChange}
                            required
                          />
                          <div className="errorpd">
                            {/* <span className="errorShow">{this.state.errors["first_name"]}</span> */}
                          </div>
                        </div>
                      </div>

                      <div className="form-row mg-left">
                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom02">
                            Ecom_Express Volumetric Weight
                          </label>
                          <input
                            type="text"
                            onKeyPress={(event) => {
                              // if (!/[0-9\.]/.test(event.key)) {
                              //   event.preventDefault();
                              // }
                            }}
                            className="form-control"
                            name="ecom_express_st_vol_weight"
                            placeholder="Ecom_Express Volumetric Weight"
                            onChange={this.handleChange}
                            required
                          />
                          <div className="errorpd">
                            {/* <span className="errorShow">{this.state.errors["last_name"]}</span> */}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom01">
                            Ecom_Express Actual Weight
                          </label>
                          <input
                            type="text"
                            onKeyPress={(event) => {
                              // if (!/[0-9\.]/.test(event.key)) {
                              //   event.preventDefault();
                              // }
                            }}
                            className="form-control"
                            name="ecom_express_st_actual_weight"
                            placeholder="Ecom_Express Actual Weight"
                            onChange={this.handleChange}
                            required
                          />
                          <div className="errorpd">
                            {/* <span className="errorShow">{this.state.errors["first_name"]}</span> */}
                          </div>
                        </div>
                      </div>

                      <div className="form-row mg-left">
                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom01">
                            Shadowfax volumetric Weight
                          </label>
                          <input
                            type="text"
                            onKeyPress={(event) => {
                              // if (!/[0-9\.]/.test(event.key)) {
                              //   event.preventDefault();
                              // }
                            }}
                            className="form-control"
                            name="shadowfax_st_vol_weight"
                            placeholder="Shadowfax volumetric Weight"
                            onChange={this.handleChange}
                            required
                          />
                          <div className="errorpd">
                            {/* <span className="errorShow">{this.state.errors["first_name"]}</span> */}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label
                            className="lableClass"
                            htmlFor="validationCustom01">
                            Shadowfax Actual Weight
                          </label>
                          <input
                            type="text"
                            onKeyPress={(event) => {
                              // if (!/[0-9\.]/.test(event.key)) {
                              //   event.preventDefault();
                              // }
                            }}
                            className="form-control"
                            name="shadowfax_st_actual_weight"
                            placeholder="Shadowfax Actual Weight"
                            onChange={this.handleChange}
                            required
                          />
                          <div className="errorpd">
                            {/* <span className="errorShow">{this.state.errors["first_name"]}</span> */}
                          </div>
                        </div>
                      </div>

                      <div className="form-row mg-left">
                        <div className="">
                          <button onClick={this.handleSubmit}>
                            Add Product
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {this.state.isproductAdd && (
              <AddColorComponent
                isEdit={this.state.isEdit}
                isAdd={this.state.isAdd}
                cancleAddProduct={this.cancleAddProduct}
                editProduct={this.state.editProduct}
              />
            )}
          </div>

          <div className="modal fade" id="viewModal">
            <div className="modal-dialog" role="document">
              <div className="moc">
                {/* // <form action="" onSubmit={this.handleEdit}> */}
                <div className="modal-header">
                  <div className="list2 add_product">
                    <span>Edit product</span>
                  </div>

                  <div
                    data-dismiss="modal"
                    aria-label="Close"
                    id="product_close_icon_id"
                    className="close_icon">
                    <span aria-hidden="true">
                      <i className="fas fa-times" style={{ color: "#fff" }}></i>
                    </span>
                  </div>
                </div>
                {/* {this.state.productDetails.map((obj) => ( */}
                <div className="edit-product-modal">
                  <div className="form-row mg-left">
                    <div className="col-md-6">
                      <label className="" htmlFor="validationCustom01">
                        Product Group Id <span className="mdcolor">*</span>
                      </label>

                      <select
                        className="dropbtn1111"
                        onChange={(e) => this.onProductGroupChange(e)}>
                        {/* <i className="fas fa-chevron-down"></i> */}
                        <option value={0}> Product Group Id </option>
                        {this.state.productGroupList.map((obj) => (
                          <option value={obj.id} selected={obj.id === 1}>
                            {obj.group_name}
                          </option>
                        ))}
                      </select>
                      <div className="errorpd">
                        <span className="errorShow">
                          {this.state.errors["edit_error_product_group_id"]}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label className="" htmlFor="validationCustom02">
                        Product Color <span className="mdcolor">*</span>
                      </label>
                      <select
                        className="dropbtn1111"
                        onChange={(e) => this.onProductColorChange(e)}>
                        {/* <i className="fas fa-chevron-down"></i> */}
                        <option value={0}> Product Color </option>
                        {this.state.productColorList.length > 0 &&
                          this.state.productColorList.map((obj, index) => (
                            <option
                              value={obj.id}
                              key={index}
                              selected={obj.id === 1}>
                              {obj.name}
                            </option>
                          ))}
                      </select>
                      <div className="errorpd">
                        <span className="errorShow">
                          {this.state.errors["edit_error_product_color"]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="form-row mg-left">
                    <div className="col-md-6 pro_name">
                      <label className="" htmlFor="validationCustom01">
                        Product Name <span className="mdcolor">*</span>
                      </label>
                      <select
                        className="dropbtn1111"
                        onChange={(e) => this.onProductNameChange(e)}>
                        {/* <i className="fas fa-chevron-down"></i> */}
                        <option value={0}> Product Name </option>
                        {this.state.productNameListData.map((obj) => (
                          <option value={obj.id} selected={obj.id === 1}>
                            {obj.product_prefix}
                          </option>
                        ))}
                      </select>
                      <div className="errorpd">
                        <span className="errorShow">
                          {this.state.errors["edit_error_product_name"]}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label className="" htmlFor="validationCustom02">
                        Product URL <span className="mdcolor">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="product_url"
                        value={this.state.product_url}
                        onChange={this.handleEdit}
                        placeholder="Product URL"
                        required
                      />
                      <div className="errorpd">
                        <span className="errorShow">
                          {this.state.errors["edit_error_product_url"]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="form-row mg-left">
                    <div className="col-md-6 warr">
                      <label className="" htmlFor="validationCustom01">
                        Warranty In Months <span className="mdcolor">*</span>
                      </label>
                      <input
                        type="text"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        className="form-control"
                        name="warranty_in_months"
                        placeholder="Warranty In Months"
                        value={this.state.warranty_in_months}
                        onChange={this.handleEdit}
                        required
                      />
                      <div className="errorpd">
                        <span className="errorShow">
                          {this.state.errors["edit_error_warranty_in_months"]}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label
                        className="lableClass"
                        htmlFor="validationCustom02">
                        Product Price <span className="mdcolor">*</span>
                      </label>
                      <input
                        type="text"
                        onKeyPress={(event) => {
                          // if (!/[0-9\.]/.test(event.key)) {
                          //   event.preventDefault();
                          // }
                        }}
                        className="form-control"
                        name="product_price"
                        placeholder="Product Price"
                        value={this.state.product_price}
                        onChange={this.handleEdit}
                        required
                      />
                      <div className="errorpd">
                        <span className="errorShow">
                          {this.state.errors["edit_error_product_price"]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="form-row mg-left">
                    <div className="col-md-6 troubleshoot">
                      <label className="" htmlFor="validationCustom01">
                        Troubleshoot URL
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="troubleshoot_url"
                        placeholder="Troubleshoot URL"
                        value={this.state.troubleshoot_url}
                        onChange={this.handleEdit}
                        required
                      />
                      <div className="errorpd">
                        {/* <span className="errorShow">{this.state.errors["first_name"]}</span> */}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label
                        className="lableClass"
                        htmlFor="validationCustom02">
                        Buy Back Value
                      </label>
                      <input
                        type="text"
                        onKeyPress={(event) => {
                          // if (!/[0-9\.]/.test(event.key)) {
                          //   event.preventDefault();
                          // }
                        }}
                        className="form-control"
                        name="buy_back_value"
                        placeholder="Buy Back Value"
                        id="buy_back_value"
                        value={this.state.buy_back_value}
                        onChange={this.handleEdit}
                        required
                      />
                      <div className="errorpd">
                        {/* <span className="errorShow">{this.state.errors["last_name"]}</span> */}
                      </div>
                    </div>
                  </div>

                  <div className="form-row mg-left">
                    <div className="col-md-6">
                      <label className="" htmlFor="validationCustom01">
                        {" "}
                        SKU Code <span className="mdcolor">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="sku_code"
                        placeholder="SKU Code"
                        value={this.state.sku_code}
                        onChange={this.handleEdit}
                        required
                      />
                      <div className="errorpd">
                        <span className="errorShow">
                          {this.state.errors["edit_error_sku_code"]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="form-row mg-left">
                    <div className="col-md-6">
                      <label
                        className="lableClass"
                        htmlFor="validationCustom02">
                        Delhivery standards volumetric Weight
                      </label>
                      <input
                        type="text"
                        onKeyPress={(event) => {
                          // if (!/[0-9\.]/.test(event.key)) {
                          //   event.preventDefault();
                          // }
                        }}
                        className="form-control"
                        name="delhivery_st_vol_weight"
                        value={
                          this.state.delhivery_st_vol_weight !== 0
                            ? this.state.delhivery_st_vol_weight
                            : ""
                        }
                        placeholder="Delhivery_Standards volumetric Weight"
                        onChange={this.handleChange}
                        required
                      />
                      <div className="errorpd">
                        {/* <span className="errorShow">{this.state.errors["last_name"]}</span> */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label
                        className="lableClass"
                        htmlFor="validationCustom02">
                        Delhivery_Standards Actual Weight
                      </label>
                      <input
                        type="text"
                        onKeyPress={(event) => {
                          // if (!/[0-9\.]/.test(event.key)) {
                          //   event.preventDefault();
                          // }
                        }}
                        className="form-control"
                        name="delhivery_st_actual_weight"
                        value={
                          this.state.delhivery_st_actual_weight !== 0
                            ? this.state.delhivery_st_actual_weight
                            : ""
                        }
                        placeholder="Delhivery_Standards Actual Weight"
                        onChange={this.handleChange}
                        required
                      />
                      <div className="errorpd">
                        {/* <span className="errorShow">{this.state.errors["last_name"]}</span> */}
                      </div>
                    </div>
                  </div>

                  <div className="form-row mg-left">
                    <div className="col-md-6">
                      <label className="" htmlFor="validationCustom01">
                        Delhivery_Express volumetric Weight
                      </label>
                      <input
                        type="text"
                        onKeyPress={(event) => {
                          // if (!/[0-9\.]/.test(event.key)) {
                          //   event.preventDefault();
                          // }
                        }}
                        className="form-control"
                        name="express_st_vol_weight"
                        placeholder="Delhivery_Express volumetric Weight"
                        value={
                          this.state.express_st_vol_weight !== 0
                            ? this.state.express_st_vol_weight
                            : ""
                        }
                        onChange={this.handleChange}
                        required
                      />
                      <div className="errorpd">
                        {/* <span className="errorShow">{this.state.errors["first_name"]}</span> */}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label className="" htmlFor="validationCustom01">
                        Delhivery_Express Actual Weight
                      </label>
                      <input
                        type="text"
                        onKeyPress={(event) => {
                          // if (!/[0-9\.]/.test(event.key)) {
                          //   event.preventDefault();
                          // }
                        }}
                        className="form-control"
                        name="express_st_actual_weight"
                        value={
                          this.state.express_st_actual_weight !== 0
                            ? this.state.express_st_actual_weight
                            : ""
                        }
                        placeholder="Delhivery_Express Actual Weight"
                        onChange={this.handleChange}
                        required
                      />
                      <div className="errorpd">
                        {/* <span className="errorShow">{this.state.errors["first_name"]}</span> */}
                      </div>
                    </div>
                  </div>

                  <div className="form-row mg-left">
                    <div className="col-md-6">
                      <label
                        className="lableClass"
                        htmlFor="validationCustom02">
                        Xpressbees_Air volumetric Weight
                      </label>
                      <input
                        type="text"
                        onKeyPress={(event) => {
                          // if (!/[0-9\.]/.test(event.key)) {
                          //   event.preventDefault();
                          // }
                        }}
                        className="form-control"
                        name="xpressbees_air_st_vol_weight"
                        value={
                          this.state.xpressbees_air_st_vol_weight !== 0
                            ? this.state.xpressbees_air_st_vol_weight
                            : ""
                        }
                        placeholder="Xpressbees_Air volumetric Weight"
                        onChange={this.handleChange}
                        required
                      />
                      <div className="errorpd">
                        {/* <span className="errorShow">{this.state.errors["last_name"]}</span> */}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label
                        className="lableClass"
                        htmlFor="validationCustom02">
                        Xpressbees_Air Actual Weight
                      </label>
                      <input
                        type="text"
                        onKeyPress={(event) => {
                          // if (!/[0-9\.]/.test(event.key)) {
                          //   event.preventDefault();
                          // }
                        }}
                        className="form-control"
                        name="xpressbees_air_st_actual_weight"
                        value={
                          this.state.xpressbees_air_st_actual_weight !== 0
                            ? this.state.xpressbees_air_st_actual_weight
                            : ""
                        }
                        placeholder="Xpressbees_Air Actual Weight"
                        onChange={this.handleEdit}
                        required
                      />
                      <div className="errorpd">
                        {/* <span className="errorShow">{this.state.errors["last_name"]}</span> */}
                      </div>
                    </div>
                  </div>

                  <div className="form-row mg-left">
                    <div className="col-md-6">
                      <label className="" htmlFor="validationCustom01">
                        Bluedart Volumetric Weight
                      </label>
                      <input
                        type="text"
                        onKeyPress={(event) => {
                          // if (!/[0-9\.]/.test(event.key)) {
                          //   event.preventDefault();
                          // }
                        }}
                        className="form-control"
                        name="bluedart_st_vol_weight"
                        value={
                          this.state.bluedart_st_vol_weight !== 0
                            ? this.state.bluedart_st_vol_weight
                            : ""
                        }
                        placeholder="Bluedart Volumetric Weight"
                        onChange={this.handleEdit}
                        required
                      />
                      <div className="errorpd">
                        {/* <span className="errorShow">{this.state.errors["first_name"]}</span> */}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label className="" htmlFor="validationCustom01">
                        Bluedart Actual Weight
                      </label>
                      <input
                        type="text"
                        onKeyPress={(event) => {
                          // if (!/[0-9\.]/.test(event.key)) {
                          //   event.preventDefault();
                          // }
                        }}
                        className="form-control"
                        name="bluedart_st_actual_weight"
                        value={
                          this.state.bluedart_st_actual_weight !== 0
                            ? this.state.bluedart_st_actual_weight
                            : ""
                        }
                        placeholder="Bluedart Actual Weight"
                        onChange={this.handleEdit}
                        required
                      />
                      <div className="errorpd">
                        {/* <span className="errorShow">{this.state.errors["first_name"]}</span> */}
                      </div>
                    </div>
                  </div>

                  <div className="form-row mg-left">
                    <div className="col-md-6">
                      <label
                        className="lableClass"
                        htmlFor="validationCustom02">
                        Ecom_Express Volumetric Weight
                      </label>
                      <input
                        type="text"
                        onKeyPress={(event) => {
                          // if (!/[0-9\.]/.test(event.key)) {
                          //   event.preventDefault();
                          // }
                        }}
                        className="form-control"
                        name="ecom_express_st_vol_weight"
                        placeholder="Ecom_Express Volumetric Weight"
                        value={
                          this.state.ecom_express_st_vol_weight !== 0
                            ? this.state.ecom_express_st_vol_weight
                            : ""
                        }
                        onChange={this.handleEdit}
                        required
                      />
                      <div className="errorpd">
                        {/* <span className="errorShow">{this.state.errors["last_name"]}</span> */}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label
                        className="lableClass"
                        htmlFor="validationCustom02">
                        Ecom_Express Actual Weight
                      </label>
                      <input
                        type="text"
                        onKeyPress={(event) => {
                          // if (!/[0-9\.]/.test(event.key)) {
                          //   event.preventDefault();
                          // }
                        }}
                        className="form-control"
                        name="ecom_express_st_actual_weight"
                        placeholder="Ecom_Express Actual Weight"
                        value={
                          this.state.ecom_express_st_actual_weight !== 0
                            ? this.state.ecom_express_st_actual_weight
                            : ""
                        }
                        onChange={this.handleEdit}
                        required
                      />
                      <div className="errorpd">
                        {/* <span className="errorShow">{this.state.errors["last_name"]}</span> */}
                      </div>
                    </div>
                  </div>

                  <div className="form-row mg-left">
                    <div className="col-md-6">
                      <label className="" htmlFor="validationCustom01">
                        Shadowfax volumetric Weight
                      </label>
                      <input
                        type="text"
                        onKeyPress={(event) => {
                          // if (!/[0-9\.]/.test(event.key)) {
                          //   event.preventDefault();
                          // }
                        }}
                        className="form-control"
                        name="shadowfax_st_vol_weight"
                        placeholder="Shadowfax volumetric Weight"
                        value={
                          this.state.shadowfax_st_vol_weight !== 0
                            ? this.state.shadowfax_st_vol_weight
                            : ""
                        }
                        onChange={this.handleChange}
                        required
                      />
                      <div className="errorpd">
                        {/* <span className="errorShow">{this.state.errors["first_name"]}</span> */}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label className="" htmlFor="validationCustom01">
                        Shadowfax Actual Weight
                      </label>
                      <input
                        type="text"
                        onKeyPress={(event) => {
                          // if (!/[0-9\.]/.test(event.key)) {
                          //   event.preventDefault();
                          // }
                        }}
                        className="form-control"
                        name="shadowfax_st_actual_weight"
                        placeholder="Shadowfax Actual Weight"
                        value={
                          this.state.shadowfax_st_actual_weight !== 0
                            ? this.state.shadowfax_st_actual_weight
                            : ""
                        }
                        onChange={this.handleEdit}
                        required
                      />
                      <div className="errorpd">
                        {/* <span className="errorShow">{this.state.errors["first_name"]}</span> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* // )) } */}
                <div className="form-row mg-left">
                  <div className="">
                    <button
                      type="button"
                      onClick={(e) => this.onUpdateClick(e)}>
                      Update Changes
                    </button>
                  </div>

                  <div></div>

                  {/* </div> */}

                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>

          {!this.state.isproductAdd && (
            <div className="colrow product">
              <div className="row customrow" style={{ marginTop: "0px" }}>
                <div className="col-sm" style={{ color: "#eb951f" }}>
                  Product Name
                </div>
                <div className="col-sm" style={{ color: "#eb951f " }}>
                  Product Group Name
                </div>
                <div className="col-sm" style={{ color: "#eb951f" }}>
                  Product Prefix
                </div>
                <div className="col-sm" style={{ color: "#eb951f" }}>
                  Product URL
                </div>
                <div className="col-sm" style={{ color: "#eb951f " }}>
                  Warranty
                </div>
              </div>

              {this.state.productList.length > 0 ? (
                this.state.productList.map((item) => {
                  return (
                    <div key={item.id} className="row customrow product-col">
                      <div className="col ">
                        {item.product_name}(
                        {item.size && "Size " + item.size + " | "}
                        {item.color_name})
                      </div>
                      <div className="col">
                        {item.product_prefix ? item.group_name : "NA"}
                      </div>
                      <div className="col">
                        {item.product_prefix ? item.product_prefix : "NA"}
                      </div>
                      <div className="col image-url">
                        <span>
                          <a
                            className="acolor"
                            target={"_blank"}
                            href={
                              item.product_image_url
                                ? item.product_image_url
                                : "NA"
                            }>
                            {item.product_image_url
                              ? item.product_image_url
                              : "NA"}
                          </a>
                        </span>
                      </div>

                      <div className="col warranty-col">
                        <span>
                          {" "}
                          {item.warranty_in_months
                            ? item.warranty_in_months
                            : "NA"}
                        </span>
                        <div className="col">
                          <div className="dropdown product_dropdown">
                            <div className="btn btn-link ">
                              <i
                                style={{ marginRight: "10px" }}
                                onClick={() =>
                                  this.handleEditProduct(item, true, false)
                                }
                                className="fas fa-pencil-alt"></i>
                              <i
                                onClick={() =>
                                  this.handleEditProduct(item, false, true)
                                }
                                className="fas fa-plus"></i>
                            </div>
                            {/* <button className="btn btn-link " type="button" id="dropdownMenuButton" data-toggle="dropdown" ria-expanded="false">
                        <i className="fas fa-pencil-alt"></i>
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item" href="" value={item.product_color_id} onClick={(e) => this.onEditClick(e, item)} data-toggle="modal" data-target="#viewModal"> <i className="fas fa-pencil-alt"></i>Edit</a>
                        <a className="dropdown-item" href="#" value={item.product_color_id}  onClick={(e) => this.onEditClickData(item.product_color_id)} data-toggle="modal" data-target="#viewModal"> <i className="fas fa-pencil-alt"></i>Edit</a>
                        <a className="dropdown-item" href="" onClick={(e) => this.onDeleteClick(item.product_color_id)} ><i className="fas fa-exclamation-circle"></i>Remove</a>
                      </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="no-result-text">
                  <p>No result found</p>
                </div>
              )}
              <Footer
                onPrevPress={this.onPrevPress}
                onNextPress={this.onNextPress}
                pageNo={this.state.pageNo}
                cc={"jj"}
                totalcount={this.state.totalcount}
              />
            </div>
          )}

          <ReactModal
            isOpen={this.state.showcolorModal}
            contentLabel="Minimal Modal Example"
            style={customStylesDO}
            ariaHideApp={false}>
            <div className="modal-head-product">
              <h2 id="rModal_head">Add Color</h2>
              <i
                className="fa fa-times"
                aria-hidden="true"
                onClick={() => this.setState({ showcolorModal: false })}></i>
            </div>

            <div className="add-product-color">
              <div className="form-group add-color">
                <label>Color Name</label>
                <input
                  type="text"
                  value={this.state.colorName}
                  className="form-control inputclass"
                  name="subject"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.startsWith(" ")) return;
                    this.setState({ colorName : e.target.value })
                  }}
                    // this.setState({ colorName: e.target.value })}
                  placeholder="Please enter color name"
                  required
                />
                <div className="errorpd">
                  <span className="errorShow">
                    {this.state.errors["error_color"]}
                  </span>
                </div>
              </div>
              <div className="form-group add-color">
                <label>Description</label>
                <input
                  type="text"
                  value={this.state.colorDesctiption}
                  className="form-control inputclass"
                  name="body"
                  onChange={(e) => {
                     const value = e.target.value;
                    if (value.startsWith(" ")) return;
                    this.setState({ colorDesctiption: e.target.value })
                  }
                    // this.setState({
                    //   colorDesctiption: e.target.value,
                    // })
                  }
                  placeholder="Please enter description"
                  required
                />
                <div className="errorpd">
                  <span className="errorShow">
                    {this.state.errors["error_description"]}
                  </span>
                </div>
              </div>
              <div className="addbtn">
                <button onClick={this.handleColorAdd}>Add Color</button>
              </div>
            </div>
          </ReactModal>

          {/* <div className="footer">
          <div className="pages">
          

          </div>
          <div className="pages1">
            <p>{this.state.pageLimit * (this.state.pageNo) > 1 ? this.state.pageLimit * (this.state.pageNo) : '1'} - {this.state.pageLimit * (this.state.pageNo + 1)} of {this.state.totalcount} results
              <i className="fas fa-chevron-left" onClick={this.onPrevPress} />
              <span>{Number(this.state.pageNo) + 1}</span>
              <i className="fa fa-chevron-right" onClick={this.onNextPress} />
            </p>
          </div>
          </div> */}
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  productData: state.product.productData,
  detailData: state.product.detailData,
  editData: state.product.editData,
  productResponse: state.product.productResponse,
  groupList: state.product.groupList,
  groupData: state.product.groupData,
  clolorList: state.product.clolorList,
  productNameList: state.product.productNameList,
});

export default connect(mapStateToProps, {
  getProductData,
  createProduct,
  getProductDetail,
  editProductData,
  getGroupData,
  removeProductinfo,
  getProductNameData,
  getProductColorData,
  setProductID,
  addNewColor,
})(ProductComponent);
