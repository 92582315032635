import React, { Component } from 'react';
import { connect } from "react-redux";
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';
import HtmlParser from 'react-html-parser';
import { addComments, getCommentData } from '../../../actions/complaintsDataAction';

class CommentExpand extends Component {

    constructor() {
        super();
        this.state = {
            editorHtml: '',
            file: {},
            disabled: false,
            commentsValue: '',
            commentDisabled: false,
            commentList: [],
            loadState: true,
            pageno: 1
        };
        this.handleAttachment = this.handleAttachment.bind(this);
        this.handleCommentsInputValue = this.handleCommentsInputValue.bind(this);
        this.handleCommentSubmit = this.handleCommentSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }




    handleAttachment(e) {
        document.getElementById("files").classList.remove("removeValue");
        this.setState({
            file: e.target.files[0],
        });
    }

    async handleCommentSubmit(e) {
        // e.preventDefault();
        if (this.state.commentsValue !== "") {
            let currentdate = new Date();
            let datetime = "Last Sync: " + currentdate.getDate() + "/"
                + (currentdate.getMonth() + 1) + "/"
                + currentdate.getFullYear() + " @ "
                + currentdate.getHours() + ":"
                + currentdate.getMinutes() + ":"
                + currentdate.getSeconds();
            this.setState({ current_date: datetime, commentDisabled: true });

            let data = new FormData();
            data.append('complaint_id', this.props.complaintsDetail.id);
            data.append("comment", this.state.commentsValue,);
            if (this.state.file && this.state.file.size) {
                if (this.state.file.size / 1000 > 25 && this.state.file.size / 1000 < 1024) {
                    data.append('commentImg', this.state.file);
                    //  data = {
                    //   "complaint_id": this.props.complaintsDetail.id,
                    //   "comment": this.state.commentsValue,
                    //   "userMap": [
                    //   ]
                    // };
                } else {
                    alert("File size will be more than 25kb and and less than 1mb");
                    document.getElementById("files").value = '';
                    this.setState({ commentDisabled: false, file: {} });
                    return false;
                }
            }
            await this.callToCommentApi(data);
            document.getElementById("files").value = '';
            this.setState({ commentsValue: '', commentDisabled: false, file: {} });
            var myDiv = document.getElementById("expand_conversation_head");
            myDiv.scrollTop = myDiv.scrollHeight - myDiv.clientHeight;
            // myDiv.scrollTop = 0;
        } else {
            alert("Please enter comment");
        }
    }

    callToCommentApi = async (param) => {
        let response = await this.props.addComments(param);
        var myDiv = document.getElementById("expand_conversation_head");
        myDiv.scrollTop = myDiv.scrollHeight - myDiv.clientHeight;
        let userEmail = localStorage.getItem('userEmail');
        let data = [{
            fullName: response.data.user,
            created_at: response.data.date,
            user: { email: userEmail },
            type: "comment",
            img: response.data.img == "" ? null : response.data.img,
            notes: this.state.commentsValue,
        }];
        if (response.success) {
            this.setState({ commentList: [...this.state.commentList, ...data], loadState: false });
        }

        if (response) {
            this.setState({ commentsValue: '', commentDisabled: false, });
            var myDiv = document.getElementById("expand_conversation_head");
            myDiv.scrollTop = myDiv.scrollHeight - myDiv.clientHeight;
        }
    };


    handleCommentsInputValue(e) {
        this.setState({ commentsValue: e.target.value });

    }

    componentDidMount() {
        var myDiv = document.getElementById("expand_conversation_head");
        myDiv.scrollTop = myDiv.scrollHeight - myDiv.clientHeight;
        this.setState({ commentList: this.props.commentData, pageno: this.props.pageno });
    }

    closeModal() {
        this.props.showExpandedComment(false);
    }

    loadMore = async () => {
        let response = await this.props.getCommentData(this.props.complaintsDetail.id, this.state.pageno + 1);
        this.setState({ commentList: [...this.state.commentList, ...response], pageno: this.state.pageno + 1 });
    };

    render() {
        let userRoleId = localStorage.getItem('userRoleId');
        let userEmail = localStorage.getItem('userEmail');

        return (
            <div>
                <div id="conversationWrapper">
                    {userRoleId !== '4' &&
                        <div className="shrink_wrapper" onClick={this.closeModal}>
                            <i className="fa fa-compress" aria-hidden="true"></i>
                        </div>
                    }
                    <div className="head conversation_head expanded" id="expand_conversation_head">
                        {
                            this.state.commentList.map((b, index) => (
                                <div style={{ width: "100%", float: "left" }}>
                                    {b.user &&
                                        <div className={b.user.email === userEmail ? 'same-user message' : 'other-user message'}>
                                            <div>
                                                {b.type == "comment" && b.img != null ?
                                                    <span>
                                                        <a className="download_icon2" href={b.img} target='_blank' > <i className="fas fa-download" />
                                                            Download Image</a></span>
                                                    : null}
                                                <p>{HtmlParser(b.notes)}</p>
                                                <span>{b.fullName ? <p id="userName">- {b.fullName}</p> : <p id="userName">- {b.user.first_name} {b.user.last_name}</p>} </span>
                                                <p id="created_at">{moment(b.created_at, ["MMMM DD, YYYY || HH.mm"]).format('MMMM DD, YYYY || hh:mm A')}</p>

                                            </div>
                                        </div>}
                                </div>
                            ))
                        }
                        {(this.props.commentpage.next_page == true && this.state.loadState) &&
                            <div style={{ display: "flex", justifyContent: "center" }} className="expand_wrapper" onClick={this.loadMore}>
                                <button className='loadMoreBUtton'>Load more..</button>
                            </div>}
                    </div>
                    <div className="foot conversation_foot expand_conversation_footer" id="collapseFooter">
                        <form>
                            <div className="comments_wrapper">
                                <textarea className="inputComments" onChange={this.handleCommentsInputValue} value={this.state.commentsValue} />
                                <div className="comment_reply">
                                    <input id="files" type="file" onChange={this.handleAttachment} title='Add Attachment' />
                                    <button type="button" id="mailReply" className="btn btn-info" onClick={this.handleCommentSubmit} disabled={this.state.commentDisabled}>Reply</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        );
    }
}


const mapStateToProps = state => ({
    commentDetails: state.complaints.commentDetails,
    commentpage: state.complaints.commentpage,
    complaintsDetail: state.complaints.complaintsDetail,
});

export default connect(mapStateToProps, { addComments, getCommentData })(CommentExpand);