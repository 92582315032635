import React, {useEffect, useState, useRef} from 'react'
import './AddServiceCenterComponent.css'
import {editSubServiceCenterEmployee} from '../../actions/serviceCenterActions';
import { useSelector,useDispatch } from 'react-redux';
import AccessRestricted from '../AccessRestricted';
import {useHistory} from 'react-router-dom'
import Loader from 'react-loader-spinner'

function EditEmployeeComponent() {

  const employeeDetails = useSelector((state) => state.serviceCenter.employeeDetails.data);
  const [showLoader, setShowLoader] = useState(true)
  const history = useHistory();
  const dispatch = useDispatch()
  let btnRef = useRef();

  useEffect(() => {
    document.getElementById('root').style.background = '#F5FBFB';
    document.querySelector('body').style.background = '#F5FBFB';
  
    return () => {
      document.getElementById('root').style.background = '#121212';
      document.querySelector('body').style.background = '#121212';
    }
  }, [])

  useEffect(() => {
    setShowLoader(true)
    let interval = setTimeout(() => {
        if (employeeDetails === undefined) {
          history.push('/sub-service-center')
        }
    }, 5000)

    return () => {
        clearInterval(interval)
    }
  }, [employeeDetails])

  let response = useSelector((state) => state.serviceCenter.editSubServiceCenterEmp);
  if(response !== undefined) {
    if(response.success === true) {
      history.push('/sub-service-center')
    }
    
    if(response.success === false) {
      btnRef.current.innerText = 'Save';
      btnRef.current.style.width = '130px';
      btnRef.current.removeAttribute("disabled");
    }
  }

  const handleBackButton = () => {
    history.push('/sub-service-center')
  }

  const initialValues = {
    firstName: employeeDetails && employeeDetails.first_name,
    lastName: employeeDetails && employeeDetails.last_name,
    phone: employeeDetails && employeeDetails.mobile,
    companyEmail: employeeDetails && employeeDetails.email,
    recoveryEmail: '',
    userId: employeeDetails && employeeDetails.id
  }

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({})

  const handleValidation = (empDetails) => {
    let errors = {}
    let validity = true;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const mobileRegex = /^[6789]\d{9}$/;

    if(empDetails.firstName === undefined || empDetails.firstName === '') {
      errors['firstName'] = 'First name cannot be empty'
      validity = false
    }
    else if(empDetails.firstName.trim().length === 0) {
      errors['firstName'] = 'Please provide a valid first name'
      validity = false
    }
    else if(( /^[A-Za-z\s]*$/).test(empDetails.firstName) === false) {
      errors['firstName'] = 'Please provide a valid first name'
      validity = false
    }

    if(empDetails.lastName === undefined || empDetails.lastName === '') {
      errors['lastName'] = 'Last name cannot be empty'
      validity = false
    }
    else if(empDetails.lastName.trim().length === 0) {
      errors['lastName'] = 'Please provide a valid last name'
      validity = false
    }
    else if(( /^[A-Za-z\s]*$/).test(empDetails.lastName) === false) {
      errors['lastName'] = 'Please provide a valid last name'
      validity = false
    }
    
    
    if(empDetails.phone === undefined || empDetails.phone === '') {
      errors['phone'] = 'Phone number cannot be empty'
      validity = false;
    }
    else if(mobileRegex.test(empDetails.phone) === false) {
      errors['phone'] = 'Please enter valid contact number'
      validity = false
    }
    
    if(empDetails.companyEmail === undefined || empDetails.companyEmail === '') {
      errors['companyEmail'] = 'Company email cannot be empty'
      validity = false;
    }
    else if(emailRegex.test(empDetails.companyEmail) === false) {
      errors['companyEmail'] = 'Please enter valid company email'
      validity = false;
    }

    // if(empDetails.recoveryEmail === undefined || empDetails.recoveryEmail === '') {
    //   errors['recoveryEmail'] = 'Recovery email cannot be empty'
    //   validity = false;
    // }
    // else if(emailRegex.test(empDetails.companyEmail) === false) {
    //   errors['recoveryEmail'] = 'Please enter valid recovery email'
    //   validity = false;
    // }

    setErrors(errors)

    return validity;
  }


  const handleSaveBtn = (e) => {
    let employeeDetails = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      companyEmail: values.companyEmail,
      recoveryEmail: values.recoveryEmail,
    }

    let validity = handleValidation(employeeDetails);

    if(validity === true) {
      let sc_details = JSON.parse(localStorage.getItem('scAddress'))
      let sc_id = sc_details.service_center_id
      let data = {
        "user_id": values.userId,
        "first_name": values.firstName,
        "last_name": values.lastName,
        "email": values.companyEmail,
        "mobile": values.phone,
        "sc_id": sc_id
      }

      if(btnRef.current) {
        btnRef.current.setAttribute("disabled", "disabled");
        btnRef.current.innerText = 'Please Wait...';
        btnRef.current.style.width = '150px';
      }

      dispatch(editSubServiceCenterEmployee(data))
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  let userRoleId = localStorage.getItem('userRoleId');
  if (!['9', '10'].includes(userRoleId)){
    const style = {
      fontSize:"24px",
      color:"#000",
      textAlign:"center",
      fontWeight:"500",
      paddingTop:"5%"
    }
    return( <AccessRestricted style={style}/>)
  }
  else{
    return (
      <React.Fragment>
      <div className='header pd headerNew'>
        <div className="backHome" onClick={handleBackButton}>
          <i className='fas fa-arrow-left'></i> Back to dashboard
        </div>
      </div>
      {
        !employeeDetails && showLoader ?
        <div className='loader'>
          <Loader
          type="Oval"
          color="Grey"
          height={200}
          width={200}
          className='loaderNew'
          />
        </div>
        :
        <div className='main-container main-container-new'>
          <div className='addEmployeeForm'>
            <h1 className='form-heading'>Edit Profile</h1>
            <div className='horizontal'></div>
            <form>
            <div className='row__two'>
              <div>
                <label htmlFor='customer name'>First Name</label>
                <input type="text" name='firstName' value={values.firstName} onChange={handleInputChange} />
                <div className='error_add'>
                  <span className='errorShow'>{errors.firstName}</span>
                </div>
              </div>
              <div>
                <label htmlFor='customer name'>Last Name</label>
                <input type="text" name='lastName' value={values.lastName} onChange={handleInputChange} />
                <div className='error_add'>
                  <span className='errorShow'>{errors.lastName}</span>
                </div>
              </div>
            </div>
            <div className='row__oneNew'>
              <div>
                <label htmlFor='customer name'>Contact number</label>
                <input type="tel" name='phone' value={values.phone} maxLength="10" onChange={handleInputChange} />
                <div className='error_add'>
                  <span className='errorShow'>{errors.phone}</span>
                </div>
              </div>
              <div style={{width: '310px'}}>
                <label htmlFor="contact number">Company email ID</label>
                <input type='email' className='addEmployeeInput' id='companyEmailInput' name='companyEmail'
                value={values.companyEmail}
                onChange={handleInputChange}
                placeholder='@noise.com'
                />
                <div className='error_add'>
                  <span className='errorShow'>{errors.companyEmail}</span>
                </div>
              </div>
              <div style={{width: '370px'}}>
                <label htmlFor='customer email'>Recovery email ID</label>
                <input type='email' className='addEmployeeInput' name='recoveryEmail' value={values.recoveryEmail}
                onChange={handleInputChange}
                placeholder='admin@noise.com'
                />
              </div>
            </div>
          </form>
          <div className='horizontal'></div>
          <button style={{float:"right"}} id='editEmp' className='navBtn' onClick={handleSaveBtn} ref={btnRef}>Save</button>
          </div>
        </div>
      }
    </React.Fragment>
    )
  }
}

export default EditEmployeeComponent