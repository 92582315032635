import React, { Component } from 'react';
import './product-dropdown-mobile.css';
import './ComplaintsComponent.css';
import { connect } from "react-redux";
import moment from 'moment';
import Loader from 'react-loader-spinner';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SidebarComponent from '../SidebarComponent/SidebarComponent';
import {
  getComplaintData, getComplaintBuckets, getComplaintStatus, getProductList,
  getProductProblemList, getRpuCourier, editComplaintData, getComplaintDetail, callCustomer,
  addStatusId, showComplaintDetailsAction, getStateList, getCityByState,
  submitRPU, revokeTicket, fetchPrintLabel, rescheduleIVR, getChannelList, adminList, getSubProblemList, addBucketID, getAllNonNfa, getFaultsList
} from '../../actions/complaintsDataAction';
import history from '../../history';
import ComplaintDetailsNew from './ComplaintDetailsNew';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';
// import Select from 'react-select';
// import ReactTooltip from "react-tooltip";
import ReactModal from 'react-modal';
import Select from 'react-select';
import ViewProfile from '../ViewProfile';
import Footer from '../FooterComponent/footer';
import ComponentToFPUPrint from './ComponentToFPUPrint';
import AccessRestricted from '../AccessRestricted';
import ComplaintRegistration from '../ComplaintRegistration';

const {
  // allowedMaxDays,
  // allowedDays,
  allowedRange,
  // beforeToday,
  // afterToday,
  // combine
} = DateRangePicker;



const customStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    inset: '8% 0 0 auto',
    marginRight: '0',
    transform: 'translate(0)',
    background: '#000',
    padding: '2rem',
    border: 'none'
  },
};



const customStylesDO = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    inset: '8% 0 0 auto',
    marginRight: '0',
    transform: 'translate(0)',
    background: '#000',
    padding: '2rem',
    border: 'none',
    width: window.innerWidth > 600 ? '35%': "100%",
    position: 'absolute'
  },
};

const customStylesCallReminder = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    inset: '60%  7%0 auto',
    border: '1px solid #eb951f',
    marginRight: '0',
    transform: 'translate(0)',
    background: '#000',
    padding: ' 0',
    borderRadius: '20px',
    width: window.innerWidth < 600 ?"100%" : '35%',
    position: 'absolute'
  },
};

const styleEdit = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    inset: '1% 0 0 auto',
    marginRight: '0',
    transform: 'translate(0)',
    background: '#000',
    padding: '0',
    border: 'none',
    height: '600px',
    overflow: 'hidden',
    overflowY: 'scroll',
    position: 'fixed',
    width: window.innerWidth < 600 ?"100%" : '50%',
    float: 'right',
    height: '98%'
  },
};

const customStylesRPU = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    overflow: "scroll"
  },
  content: {
    inset: '8% 0 0 auto',
    marginRight: '0',
    transform: 'translate(0)',
    background: '#fff',
    padding: '2rem',
    border: 'none',
    width: window.innerWidth < 600 ?"100%" : '100%',
    position: 'absolute',
    overflow: 'scroll',
  },
};

class ComplaintsComponent extends Component {
  constructor() {
    super();
    this.state = {
      showEditTicket: false,
      isLoading: false,
      showMenu: false,
      logout: false,
      pageNo: 1,
      pageLimit: 10,
      searchText: '',
      searchDate: '',
      complaintList: [],
      prevPage: false,
      nextPage: false,
      statusList: [],
      productList: [],
      newProductList: [],
      SLAList: [{ "id": 'true', "value": "Yes" }, { "id": 'false', "value": "No" }],
      problemList: [],
      productId: 0,
      SLAId: '',
      warranty: '',
      problemId: 0,
      subproblemId: 0,
      totalPage: 1,
      totalCount: 0,
      teamName: "CS",
      complaintId: 0,
      productName: '',
      problemName: '',
      problemDesc: '',
      email: '',
      mobile: '',
      customer_id: 0,
      purchaseDate: '',
      address1: '',
      address2: '',
      pinCode: '',
      state: '',
      city: '',
      comment: '',
      waybillNo: '',
      gstin: '',
      pageNos: [{ id: 1, value: '10' }, { id: 2, value: '25' }, { id: 3, value: '50' }],
      courierData: [],
      showDetails: false,
      startDate: new Date().setDate(new Date().getDate() - 90),
      endDate: new Date().setDate(new Date().getDate()),
      stateList: [],
      cityList: [],
      channellist: [],
      state_id: '',
      searchUniversal: "",
      showBucketName: false,
      isSearch: false,
      current_page: 1,
      courierId: 0,
      showRPUModal: false,
      assignedStatus: false,
      errors: [],
      isWarranty: 0,
      ticketId: '',
      showFPUModal: false,
      productColorId: "",
      showFPUPrintModal: false,
      loader2: false,
      value: '',
      copied: false,
      selectedProduct: [],
      isLoadingGif: false,
      current_prefix: '',
      showCallReminder: false,
      today: '',
      callReminderDate: '',
      callReminderTime: '',
      time: '',
      isPriority: false,
      showPriority: false,
      channel_name: '',
      sideProp: "CC",
      otherProblem: "",
      subProblemName: "",
      showDecription: true,
      edit_product_id: "",
      old_mac_id: null,
      nfa_product_list:[],
      mac_address:"",
      complaint_status_id:'',
      product_group_id:'',
      faultsList: [],
      fault_id:"0",
      fault_name:""
    };
    this.showMenu = this.showMenu.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.onProductChange = this.onProductChange.bind(this);
    this.handleRefreshList = this.handleRefreshList.bind(this);
    this.getComplaintList = this.getComplaintList.bind(this);
    this.handleSearchBy = this.handleSearchBy.bind(this);
    this.handleRPUSubmit = this.handleRPUSubmit.bind(this);
    this.handleCourierChange = this.handleCourierChange.bind(this);
    this.handleRPUCloseModal = this.handleRPUCloseModal.bind(this);
    this.handleCloseEditTicket = this.handleCloseEditTicket.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this.handleChangeProductSelection = this.handleChangeProductSelection.bind(this);
    this.handleFPUOpenModal = this.handleFPUOpenModal.bind(this);
    this.handleFPUCloseModal = this.handleFPUCloseModal.bind(this);
    this.onFPUSubmit = this.onFPUSubmit.bind(this);
    this.handlePrintModalClose = this.handlePrintModalClose.bind(this);
    this.handleFPUPrint = this.handleFPUPrint.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.prefixChange = this.prefixChange.bind(this);
    this.openScheduleCall = this.openScheduleCall.bind(this);
    this.closeCallReminder = this.closeCallReminder.bind(this);
    this.handleCallReminder = this.handleCallReminder.bind(this);
    this.callReminderValidation = this.callReminderValidation.bind(this);
    this.handlePriority = this.handlePriority.bind(this);
    this.filterall = this.filterall.bind(this);

  }


  handlePriority = async (id, priorityCheck) => {
    let data = {};
    if (priorityCheck === "0") {
      this.setState({ isPriority: "1" });
      data = {
        "complaint_id": id,
        "is_priority": "1",
        "sla_breached": "0",
        "flag": "ticket_priority",
        "update_comment": "Marked as priority ticket"
      };
    } else {
      this.setState({ isPriority: "0" });
      data = {
        "complaint_id": id,
        "is_priority": "0",
        "sla_breached": "0",
        "flag": "ticket_unpriority",
        "update_comment": "Marked as unpriority ticket"
      };
    }
    await this.props.editComplaintData(data);
    let editRes = this.props.editData;
    if (editRes.success) {
      this.getComplaintList();
    }
  };

  async onRevokeClick(e, item) {
    await this.props.revokeTicket(item.id);
    this.getComplaintList();
    this.setState({
      showRPUModal: false
    });
  }

  _handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.handleChangeButton(e);
    }
  }

  handleRPUCloseModal() {
    this.setState({
      showRPUModal: false
    });
  }

  async handleCourierChange(e) {
    this.setState({
      courierId: e.target.value
    });
  }
  // async handleFaultChange(e) {
  //   this.setState({
  //     fault_id: e.target.value
  //   });
  // }

  onRPUClick = async (e, item) => {
    e.preventDefault();
    let data = {
      "complaint_id": item.id,
      "type": "reverse_pick_up"
    };
    // TODO first call list api then click list item and call new save api
    this.setState({
      isLoading: true,
      complaintId: item.id
    });
    await this.props.getRpuCourier(data);
    this.setState({ isLoading: false, showRPUModal: true });
    // this.onRPUdata(data)
  };

  handleRPUSubmit = async () => {
    let cId = this.state.courierId;
    let selectedC = this.props.courierData.filter((cd) => Number(cd.courier_id) === Number(cId));
    if (selectedC.length > 0) {
      this.setState({ isLoadingGif: true });
      let data = {
        "complaint_id": this.state.complaintId,
        "type": "reverse_pick_up",
        "courier_id": cId,
        "reversePrice": 0
      };
      await this.props.submitRPU(data);
      this.setState({
        isLoadingGif: false
      });
      this.getComplaintList();
      this.setState({
        showRPUModal: false
      });
    } else {
      alert("Please select courier id");
    }
  };

  handleFPUOpenModal = async (e, item) => {
    let complaintId = item.id;
    this.setState({ complaintId: complaintId });
    let data = {
      "complaint_id": item.id,
      "type": "forward_pick_up"
    };
    // TODO first call list api then click list item and call new save api
    this.setState({
      isLoading: true
    });
    await this.props.getRpuCourier(data);
    this.setState({ isLoading: false, showFPUModal: true });
  };

  onFPUSubmit = async () => {
    let cId = this.state.courierId;
    let selectedC = this.props.courierData.filter((cd) => Number(cd.courier_id) === Number(cId));
    if (selectedC.length > 0) {
      this.setState({ isLoadingGif: true });
      let data = {
        "complaint_id": this.state.complaintId,
        "type": "forward_pick_up",
        "courier_id": cId,
        "reversePrice": 0,
        "forwardPrice": 0,
      };
      await this.props.submitRPU(data);
      this.setState({
        isLoadingGif: false,
      });
      this.handleFPUPrint(this.state.complaintId)
      this.getComplaintList();
      this.setState({
        showFPUModal: false
      });
    } else {
      alert("Please select courier");
      return;
    }
  };

  handleFPUCloseModal() {
    this.setState({ showFPUModal: false });
  }

  handleSearchBy(e) {
    this.setState({
      searchUniversal: e.target.value
    });
  }

  handleLogout() {
    localStorage.removeItem('token');
    document.querySelector(".modal-backdrop").style.display = "none";
    history.push('/');
  }

  handleIconDetails() {
    this.setState({
      logout: !this.state.logout
    });
  }

  async componentDidMount() {
    let data = {
      team: this.state.teamName
    };
    await this.props.getComplaintBuckets(data);
    await this.props.getComplaintStatus({});
    await this.props.getProductList({});
    await this.props.adminList({});
    this.setState({
      productList: this.props.productData.data ? this.props.productData.data.products : []
    });
    if (this.props.bucketData.length > 0) {
      await this.props.addStatusId(this.props.bucketData[0].id);
      this.props.addBucketID(this.props.bucketData[0].id)
    }
    this.getComplaintList();
  }

  onPrevPress = () => {
    if (this.props.paginationCount.prev_page) {
      this.setState({ current_page: (this.state.current_page - 1) }, async () => {
        await this.props.showComplaintDetailsAction(false);
        this.getComplaintList();
      });
    }
  };

  onNextPress = () => {
    let paginationCount = this.props.paginationCount;
    let new_page = ((paginationCount.no_page - (paginationCount.no_page - this.state.current_page)) - 1);

    if (this.props.paginationCount.next_page) {
      // let new_page = (this.state.new_page1 - 1);
      // this.setState({new_page1: new_page});
      this.setState({ current_page: (this.state.current_page + 1) }, async () => {
        await this.props.showComplaintDetailsAction(false);
        this.getComplaintList();
      });
    }
    // if (this.state.nextPage) {
    //   this.setState({ pageNo: (this.state.pageNo + 1) }, () => {
    //     this.getComplaintList()
    //   })
    // }
  };

  getComplaintList = async () => {
    let data = {};
    if (this.state.searchText.length > 0) {
      data = {
        "page_no": this.state.current_page,
        "search_by": this.state.searchText
      };
      this.setState({
        showBucketName: true
      });
    } else if (this.state.showPriority) {
      data = {
        "page_no": this.state.current_page,
        "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
        "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
        "is_priority": "1"
      };

      if (Number(this.state.productId) > 0)
        data["product_id"] = this.state.productId;
      if (this.state.SLAId && this.state.SLAId !== "0")
        data["sla_breached"] = this.state.SLAId;
      if (this.state.warranty && this.state.warranty !== 'All')
        data["is_warranty"] = this.state.warranty;
      if (this.state.problemId && this.state.problemId !== 0)
        data["problem_id"] = this.state.problemId;
      if (this.state.subproblemId && this.state.subproblemId !== 0)
        data["sub_product_problem_id"] = this.state.subproblemId;
    } else if (!this.state.assignedStatus) {
      data = {
        "page_no": this.state.current_page,
        "limit": this.state.pageLimit,
        "search_by_date": this.state.searchDate,
        // "search_by": this.state.searchText,
        "status_by": '',
        "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
        "endDate": moment(this.state.endDate).format('YYYY-MM-DD')
      };
      if (Number(this.state.productId) > 0)
        data["product_id"] = this.state.productId;
      if (this.state.SLAId && this.state.SLAId !== "0")
        data["sla_breached"] = this.state.SLAId;
      if (this.state.warranty && this.state.warranty !== 'All')
        data["is_warranty"] = this.state.warranty;
      if (this.state.problemId && this.state.problemId !== 0)
        data["problem_id"] = this.state.problemId;
      if (this.state.subproblemId && this.state.subproblemId !== 0)
        data["sub_product_problem_id"] = this.state.subproblemId;
      // if (this.state.searchDate)
      //   data["search_by_date"] = this.state.searchDate
      data["bucket_id"] = this.state.teamName == "OOS" ? "51" : this.props.BucketIDlist;
    } else {
      data = {
        "page_no": this.state.current_page,
        "assignedStatus": this.state.assignedStatus,
        "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
        "endDate": moment(this.state.endDate).format('YYYY-MM-DD')
      };
      if (Number(this.state.productId) > 0)
        data["product_id"] = this.state.productId;
      if (this.state.SLAId && this.state.SLAId !== "0")
        data["sla_breached"] = this.state.SLAId;
      if (this.state.warranty && this.state.warranty !== 'All')
        data["is_warranty"] = this.state.warranty;
      if (this.state.problemId && this.state.problemId !== 0)
        data["problem_id"] = this.state.problemId;
      if (this.state.subproblemId && this.state.subproblemId !== 0)
        data["sub_product_problem_id"] = this.state.subproblemId;
    }
    // if(Number(this.state.productId) > 0){
    //   data.bucket_id = 0 
    // }
    this.setState({ isLoading: true, complaintList: [], showBucketName: false });
    await this.props.getComplaintData(data);
    // let complaintsData = this.props.complaintsData
    let complaintDataCount = this.props.complaintsDataCount;
    // let paginationCount = this.props.paginationCount;
    this.setState({
      isLoading: false,
      totalCount: complaintDataCount.total,
      totalPage: complaintDataCount.data ? complaintDataCount.data.no_page : 0,
      prevPage: complaintDataCount.data ? complaintDataCount.data.prev_page : 0,
      nextPage: complaintDataCount.data ? complaintDataCount.data.next_page : 1,
    });
  };

  showMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true });
  }

  handleDate = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.getComplaintList();
    });
  };

  handleChange = (e) => {
    this.setState({ searchText: e.target.value }, () => {
      this.getComplaintList();
    });
  };

  handleChangeEvent = async (e) => {
    this.setState({ searchText: e.target.value, current_page: 1 });
    if (e.target.value.length === 0) {
      this.setState({ isSearch: false });
      await this.props.showComplaintDetailsAction(false);
      this.getComplaintList();
    }
  };

  handleClose = async (e) => {
    this.setState({ searchText: '', current_page: 1 });
    document.getElementById("search_input").value = '';
    this.setState({ isSearch: false });
    await this.props.showComplaintDetailsAction(false);
    this.props.addBucketID("1")
    this.getComplaintList();
  };

  handleChangeButton = async (e) => {
    e.preventDefault();
    // this.handleRefreshList();
    let val = document.getElementById("search_input").value;
    if (val.length > 0) {
      this.setState({ isSearch: true });
    } else {
      this.setState({ isSearch: false });

    }
    let res = await this.props.showComplaintDetailsAction(false);
    this.setState({ searchText: val, current_page: 1 }, () => {
      this.props.addBucketID("1")
      this.getComplaintList();
    });
  };

  onStatusChange = async (e) => {
    // this.setState({ statusId: e.target.value }, () => {
    //   this.getComplaintList();
    //   await this.props.addStatusId(e.target.value)
    // })
    this.setState({ current_page: 1 });
    this.props.addBucketID(e.target.value)
    this.props.showComplaintDetailsAction(false);
    await this.props.addStatusId(e.target.value);
    this.getComplaintList();
  };

  onProductChange(a) {
    this.setState({ productId: a.value }, async () => {
      this.getComplaintList();
      await this.props.showComplaintDetailsAction(false);
      let pId = this.state.productId;
      // if(Number(pid) > 0){
      // }
      let selected = this.state.productList.filter((pl) => Number(pl.product_color_id) === Number(pId));
      let selectedProduct = [];
      {
        selected.length > 0 ?
          selectedProduct.push(
            {
              value: selected[0].product_color_id,
              label: selected[0].id == 239 ? selected[0].product_name + "(" + selected[0].size + "|" + selected[0].color_name + ")" : selected[0].product_name + "(" + selected[0].color_name + ")"
            }
          )
          :
          selectedProduct.push(
            {
              value: 0,
              label: "Select All",
            }
          );
      }
      this.setState({ selectedProduct: selectedProduct });
      // await this.props.getProductProblemList({"category_id" : e.target});
      let category_id = selected[0] ? selected[0].product_group_id : null;
      if (category_id) {
        await this.props.getProductProblemList({ "category_id": category_id });
      }
    });
  }

  onProductDateTimeChange = (e) => {
    this.setState({ searchDate: e.target.value }, () => {
      this.getComplaintList();
    });
  };

  onSLAChange = (e) => {
    this.setState({ SLAId: e.target.value });
    //   , async () => {
    //   this.getComplaintList();
    //   await this.props.showComplaintDetailsAction(false);

    // });
  };



  onProducdProblemChange = async (e) => {
    let value = e.target.value;
    if (value != 45) {
      let data = {
        "problem_id": [e.target.value]
      };
      await this.props.getSubProblemList(data);
      this.setState({ showDecription: false });
    } else {
      this.setState({ otherProblem: value });
      this.setState({ showDecription: true });
    }
    this.setState({ problemId: value });
  };

  subProblemChange = async (e) => {
    this.setState({ subproblemId: e.target.value });
    // , async () => {
    //   this.getComplaintList();
    //   await this.props.showComplaintDetailsAction(false);
    // });
  };

  // onPageLimitChange = (e) => {
  //   this.setState({ pageLimit: e.target.value }, () => {
  //     this.getComplaintList();
  //   })
  // }

  onMenuClick = (e, teamName) => {
    this.setState({ teamName: teamName, current_page: 1 }, async () => {
      let data = {
        team: this.state.teamName
      };
      if (teamName != "OOS") {
        if (this.state.teamName !== "my_tickets") {
          if (this.state.teamName === "priority_tickets") {
            this.setState({ showPriority: true, assignedStatus: true });
          } else {
            this.setState({
              assignedStatus: false, showPriority: false
            });
            if (!(this.state.teamName == "OOS")) {
              await this.props.getComplaintBuckets(data);
              if (this.props.bucketData.length > 0) {
                this.props.addBucketID(this.props.bucketData[0].id)
                await this.props.addStatusId(this.props.bucketData[0].id);
              } else {
                this.props.addBucketID("51")
                await this.props.addStatusId("51");
              }
            }

          }
        } else {
          this.setState({
            assignedStatus: true, showPriority: false
          });
        }
      }

      await this.props.showComplaintDetailsAction(false);
      this.getComplaintList();
    });
  };

  handleEdit = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeProductSelection(e) {
    this.setState({ productColorId: e.value, edit_product_id: e.product_id });
  }

  handleChangeProductSelectionsecond(e) {
    let data = e.target.value.split(',')

    this.setState({ productColorId: data[0], edit_product_id: data[1],mac_address:data[2],purchaseDate:data[3] });
  }

  onEditClick = async (e, item) => {
    let data = item.id;
    this.getComplaintDetailApi(data);
    let body;
    console.log("ANshul",item.old_mac_id)
    if (item.old_mac_id != null && item.old_mac_id != 'DOA' && item.old_mac_id != 'NON-NFA' && item.old_mac_id != "null") {
      body = {
        product_id: [item.product_id]
      }
    }
  
    else if (item.old_mac_id == 'NON-NFA') {
      await this.props.getAllNonNfa();
      let tempNonNfa = this.props.nonNfa.data;
      let nonNfaProductIds = tempNonNfa.map(item => item.product_id);
      body = {
        product_id: nonNfaProductIds
      }
    }
    else {
      body = {
        product_group_id: item.product_group_id
      }
    }
    let res = await this.props.getProductList(body);
    this.setState({
      showEditTicket: true, ticketId: item.ticket_id, isWarranty: item.is_warranty,
      newProductList: this.props.productData.data ? this.props.productData.data.products : [],
      fault_id : "0",
      fault_name : ""
    });
  };



  getComplaintDetailApi = async (param) => {
    await this.props.getComplaintDetail(param);
    this.setState({ complaintDetailsList: [] });
    let complaintDetailsList = this.props.complaintsDetail;
    const transformedData = [];
    if(complaintDetailsList.old_mac_id != null || complaintDetailsList.old_mac_id != 'DOA' || complaintDetailsList.old_mac_id != 'NON-NFA' || complaintDetailsList.old_mac_id != "null"){
   let datas= complaintDetailsList?.nfa_product?.forEach(obj => {
    obj.product_details.forEach(detail => {
        const newObj = {
            ...detail,
            mac_id: obj.address,
            purchase_date:obj.created_date,
            warranty_status:obj.warranty_status
        };
        // Add the new object to the transformed data array
        transformedData.push(newObj);
    });
}) }
    this.setState({
      nfa_product_list:transformedData,
      complaintId: complaintDetailsList.id ? complaintDetailsList.id : '',
      city_id: complaintDetailsList.customer.city_id ? complaintDetailsList.customer.city_id : '',
      state_id: complaintDetailsList.customer.state_id ? complaintDetailsList.customer.state_id : '',
      productName: complaintDetailsList.product_id == 239 ? (complaintDetailsList.product.product_name + ' (' + "Size " + complaintDetailsList.product.size + " | " + complaintDetailsList.product.color_name + ')') : complaintDetailsList.product.product_name + "(" + complaintDetailsList.product.color_name + ")",
      problemDesc: complaintDetailsList.problem_description ? complaintDetailsList.problem_description : '',
      problemName: complaintDetailsList.product.problem ? complaintDetailsList.product.problem : '',
      subProblemName: complaintDetailsList.product.sub_problem ? complaintDetailsList.product.sub_problem : '',
      email: complaintDetailsList.customer.email ? complaintDetailsList.customer.email : '',
      mobile: complaintDetailsList.customer.mobile ? complaintDetailsList.customer.mobile : '',
      customer_id: complaintDetailsList.customer_id ? complaintDetailsList.customer_id : 0,
      purchaseDate: transformedData?.lenght > 0 ?  moment(transformedData[0].purchase_date).format('YYYY-MM-DD') : moment(complaintDetailsList.purchase_date).format('YYYY-MM-DD'),
      address1: complaintDetailsList.customer.address ? complaintDetailsList.customer.address : '',
      address2: complaintDetailsList.customer.address_2 ? complaintDetailsList.customer.address_2 : '',
      pinCode: complaintDetailsList.customer.pincode ? complaintDetailsList.customer.pincode : '',
      //state: complaintDetailsList.customer.state_name ? complaintDetailsList.customer.state_name : '',
      city: complaintDetailsList.customer.city ? complaintDetailsList.customer.city : '',
      // comment: complaintDetailsList.comments[0] ? complaintDetailsList.comments[0].notes : '',
      waybillNo: complaintDetailsList.reverse_waybill_number ? complaintDetailsList.reverse_waybill_number : '',
      productColorId: complaintDetailsList.product_color_id,
      edit_product_id: complaintDetailsList?.product_id,
      gstin: complaintDetailsList.gstin ? complaintDetailsList.gstin : '',
      current_prefix: complaintDetailsList.current_prefix ? complaintDetailsList.current_prefix : '',
      channel_id: complaintDetailsList.channel_id ? complaintDetailsList.channel_id : '',
      // channel : complaintDetailsList.customer.channel ? complaintDetailsList.customer.channel: '',
      channel_name: this.props.complaintsDetail.channel_name,
      old_mac_id: complaintDetailsList.old_mac_id ? complaintDetailsList.old_mac_id : null,
      mac_address:transformedData.length>0 ?transformedData[0].mac_id :null,
      product_group_id:complaintDetailsList.product.product_group_id,
      complaint_status_id:complaintDetailsList.complaint_status_id,
    });

    this.getStateListApi();
    this.getCityListApi(this.state.state_id);
    this.getChannelListApi();
    this.getFaultListApi();
  };
  handleValidation() {
    // alert("validqation")
    // document.querySelector(".modal-backdrop").style.display="none";
    let errors = {};
    let formIsValid = true;
    if (this.state.purchaseDate === '') {
      errors["purchaseDate"] = "Please enter purchase date";
      formIsValid = false;
    }
    if (this.state.purchaseDate !== '') {
      let b = this.state.purchaseDate;
      let UserDate = new Date(b);
      let ToDate = new Date();
      if (UserDate.getTime() > ToDate.getTime()) {
        formIsValid = false;
        errors["purchaseDate"] = "Purchase date cannot be future date";
      }
    }
    if (this.state.email === '') {
      errors["email"] = "Email cannot be empty";
      formIsValid = false;
    }
    if (this.state.email !== '') {
      var pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (!pattern.test(this.state.email)) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }
    if (this.state.mobile === '') {
      formIsValid = false;
      errors["mobile"] = "Enter your phone number.";
    }
    // if (this.state.mobile !== '') {
    //   let mobile = this.state.mobile
    //   var pattern = new RegExp(/^[6-9]\d{9}$/);
    //   if (!pattern.test(mobile) || (mobile.length != 10)) {
    //     formIsValid = false;
    //     errors["mobile"] = "Enter valid phone number.";
    //   }
    // }
    if (this.state.address1 === '') {
      errors["address1"] = "Address cannot be empty";
      formIsValid = false;
    }
    if (this.state.state_id === 0 || this.state.state_id === "0") {
      errors["state"] = "State cannot be empty";
      formIsValid = false;
    }
    if (this.state.city_id === 0 || this.state.city_id === "0" || this.state.city_id === '') {
      errors["city"] = "City cannot be empty";
      formIsValid = false;
    }
    if (this.state.channel_id === 0 || this.state.channel_id === "0" || this.state.channel_id === '') {
      errors["channel"] = "channel cannot be empty";
      formIsValid = false;
    }
    if (this.state.gstin !== "") {
      let gst = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(this.state.gstin);
      if (!gst) {
        errors["gstin"] = "Incorrect GST format!";
        formIsValid = false;
      }
    }
    // if(this.state.current_suffix == '0'){
    //   errors["current_suffix"] = "Please choose suffix";
    //   formIsValid = false;
    // }
    this.setState({ errors: errors });
    return formIsValid;
  }
  

  onUpdateClick = (e) => {
    // let userRoleId = localStorage.getItem('userRoleId');
    e.preventDefault();
    let data = {
      "complaint_id": this.state.complaintId,
      "problem_description": this.state.problemDesc,
      "purchase_date": this.state.purchaseDate,
      "address1": this.state.address1,
      "address2": this.state.address2,
      "pincode": this.state.pinCode,
      "state": this.state.state_id,
      "city": this.state.city_id,
      "waybill_number": this.state.waybillNo,
      "product_color_id": this.state.productColorId,
      "customer_id": this.state.customer_id,
      "email": this.state.email,
      "mobile": this.state.mobile,
      "gstin": this.state.gstin.toUpperCase(),
      "current_prefix": this.state.current_prefix,
      "channel_id": this.state.channel_id,
      "product_id": this.state.edit_product_id,
      "old_mac_id": this.state.mac_address,
      "fault_id": this.state.fault_id,
      "fault_name":this.state.fault_name

    };
    console.log("Fault Data----->", this.state.fault_name)
    if (this.handleValidation() !== false) {

      this.callEditApi(data);
    }
  };

  onSaveClick = (e, item) => {
    e.preventDefault();
    let data = {
      "complaint_id": item.id,
      //"complaint_status_id": 25 // approved
      "complaint_status_id": 4,  //complaint_registered
      "product_registration": 'approved',
      "status_enum": 'approved',
      "sla_breached": '0',
      "update_comment": 'Complaint Approved',
      "flag": 'approve_complaint',
    };
    this.callEditApi(data);
  };

  onCredClick = (e, item) => {
    e.preventDefault();
    let data = {
      "complaint_id": item.id,
      "complaint_status_id": 96, // cred
      "sla_breached": '0',
      "update_comment": 'Move to Cred',
      "flag": 'move_cred'
    };
    this.callEditApi(data);
  };

  callEditApi = async (param) => {

    await this.props.editComplaintData(param);
    let editRes = this.props.editData;
    if (editRes.success) {
      this.setState({ showEditTicket: false, fault_id : "0", fault_name : "" });
      alert(editRes.message);
      this.getComplaintList();
    } else {
      alert(this.props.editData.message);
      this.setState({ showEditTicket: true });
    }
  };

  handleCloseEditTicket() {
    this.setState({ showEditTicket: false });
  }

  // call to edit api without any message
  calltoEditApi = async (param) => {
    await this.props.editComplaintData(param);
    // let editRes = this.props.editData
    this.getComplaintList();
  };

  onPhoneClick = async () => {
    this.setState({ isLoading: true });
    await this.props.callCustomer(this.state.customerId, this.state.complaintId);
    let callRes = this.props.callRes;
    this.setState({ showCallReminder: false, isLoading: false });
    this.getComplaintList();

    //alert(callRes.success);
    // if (callRes.success === true) {
    //   let data = {
    //     "complaint_id": item.id,
    //     "complaint_status_id": 41,
    //     "status_enum": 'approved',
    //     "sla_breached": '0',
    //     "complaint_status": 'callback',
    //     "update_comment": 'Complaint Callback',
    //     "flag": 'complaint_callback'
    //   }
    //   this.calltoEditApi(data)
    // }

  };

  // onRPUdata = async (param) => {
  //   //this.setState({ isLoading: true, courierResData: [] })
  //   // this.setState({ isLoading: true, courierResData: [] })
  //   this.setState({
  //     isLoading: true
  //   })
  //   await this.props.getRpuCourier(param)
  //   this.setState({ isLoading: false })
  // }

  onItemPress = async (e, item) => {
    e.preventDefault();
    await this.props.showComplaintDetailsAction(true);
    this.setState({
      loader2: true
    });
    await this.props.addStatusId(item.bucketId);
    await this.props.getComplaintDetail(item.id);
    this.setState({
      loader2: false
    });
    const element = document.querySelector(".activeColor");
    element.scrollIntoView({ block: "center" });
  };

  onWarrantyChange = (e) => {
    this.setState({ warranty: e.target.value });
    //   , async () => {
    //   this.getComplaintList();
    //   await this.props.showComplaintDetailsAction(false);
    // });
  };

  dateSelect = async (value) => {
    this.setState({

      startDate: new Date(value[0]),
      endDate: new Date(value[1])
    }, async () => {
      this.getComplaintList();
      await this.props.showComplaintDetailsAction(false);

    });
  };

  onStateChange = (e) => {
    this.setState({ country_id: 1, city_id: 0, state_id: Number(e.target.value) }, () => {
      // this.getStateListApi();
      this.getCityListApi();
    });
  };

  getStateListApi = async (param) => {
    await this.props.getStateList(param);
    this.setState({ stateList: [] });
    let stateDataList = this.props.stateDataList;
    this.setState({
      stateList: stateDataList.data,
    });
  };

  onCityChange = (e) => {
    this.setState({ city_id: e.target.value, state_id: this.state.state_id }, () => {
      // this.getStateListApi();
      this.getCityListApi();
    });
  };

  getChannelListApi = async (param) => {
    await this.props.getChannelList(param);
    this.setState({ channellist: [] });
    let channelDatalist = this.props.channelDatalist;
    this.setState({
      channellist: channelDatalist.data,
    });
  };

  onChannelhange = (e) => {
    this.setState({ channel_id: e.target.value }, () => {
      // this.getStateListApi();
      this.getChannelListApi();
    });
  };

  onFaultChange = (e) => {
    // this.setState({ fault_id: e.target.value });
    const selectedFaultId = e.target.value;
    const selectedFault = this.state.faultsList.find(fault => fault.id === Number(selectedFaultId));
    this.setState({
      fault_id: selectedFaultId,
      fault_name: selectedFault ? selectedFault.name : ''
    });
  };

  prefixChange = (e) => {
    this.setState({ current_prefix: e.target.value });
  };

  getCityListApi = async () => {
    let data = {
      "state_id": this.state.state_id
    };
    await this.props.getCityByState(data);
    this.setState({ cityList: [] });
    let cityDataList = this.props.cityDataList;
    this.setState({
      cityList: cityDataList.data,
    });
  };

  getFaultListApi = async () => {
    if (this.state.complaint_status_id == 43){
    let faultsDataList = await this.props.getFaultsList(this.state.product_group_id);
    if(faultsDataList?.success){
      this.setState({
        faultsList: faultsDataList.data,
      });
    }else{
      this.setState({
        faultsList: [],
      });
    }
  }};

  handleRefreshList() {
    this.props.showComplaintDetailsAction(false);
    this.getComplaintList();
  }

  handleProdutcRefreshList = async () => {
    this.setState({
      loader2: true
    });
    await this.props.getComplaintDetail(this.props.complaintsDetail.id);
    this.setState({
      loader2: false
    });
  }

  onReset = () => {
    //  document.getElementById("bucket").value = 1;
    //  await this.props.addStatusId(1);
    //   this.getComplaintList();
    window.location.reload();
  };

  handleFPUPrint = async (e,item) => {
    let complaintId = item;   
    this.setState({ complaintId: complaintId });
    let data = {
      "complaint_id": item,
      "type": "forward_pick_up"
    };
    await this.props.fetchPrintLabel(data);
    if (this.props.printLabel) {
      //history.push('/fpu-print')
      this.setState({ showFPUPrintModal: true });
    } else {
      alert("no courier data found");
    }
  };

  handlePrintModalClose() {
    this.setState({
      showFPUPrintModal: false
    }, () => {

    });
  }

  onCopy() {
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    alert("Copied!");
  }

  openScheduleCall = async (e, item) => {
    let complaintId = item.id;
    let customerId = item.customer_id;
    this.setState({ complaintId: complaintId, customerId: customerId });
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
    var yyyy = today.getFullYear();
    var hours = today.getHours();
    var minutes = today.getMinutes();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    today = yyyy + '-' + mm + '-' + dd;
    var time = hours + ':' + minutes;
    this.setState({ showCallReminder: true, today: today, time: time });
  };

  closeCallReminder() {
    this.setState({ callReminderDate: '', callReminderTime: '', isLoading: false, showCallReminder: false, errors: [] });
  }

  callReminderValidation() {
    let errors = {};
    let validity = true;
    if (this.state.callReminderDate === '') {
      errors["errorDate"] = "Please enter Date!";
      validity = false;
    }
    if (this.state.callReminderDate !== '') {
      var currentDay = new Date();
      var dd = currentDay.getDate();
      var mm = currentDay.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
      var yyyy = currentDay.getFullYear();
      var hours = currentDay.getHours();
      var minutes = currentDay.getMinutes();
      // var currentTime = hours + ':' + minutes;
      currentDay = yyyy + '-' + mm + '-' + dd;
      const date1 = new Date(currentDay);
      var userTimezoneOffset = date1.getTimezoneOffset() * 60000;
      const date2 = new Date(new Date(this.state.callReminderDate + "T00:00:00+00:00").getTime() - userTimezoneOffset);
      const diffTime = (date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays <= 0) {
        errors["errorDate"] = "Invalid date selected!";
        this.setState({ callReminderTime: '' });
        validity = false;
      } else {
        errors["errorDate"] = "";
        if (this.state.callReminderTime === '') {
          errors["errorTime"] = "Please select time!";
          validity = false;
        }
        if (this.state.callReminderTime !== '') {
          const isValid = e => e.parentElement.querySelector(':invalid') === e;
          const myDiv = document.getElementById('appt');
          const invalidity = isValid(myDiv);
          if (invalidity) {
            if (diffDays <= 1) {
              errors["errorTime"] = "Selected time should be more than current time";
              validity = false;
            }
          }
        }
      }
    }
    this.setState({ errors: errors });
    return validity;
  }

  handleCallReminder = async () => {
    if (this.callReminderValidation() !== false) {
      let data = {
        "last_update_datetime": this.state.callReminderDate + " " + this.state.callReminderTime,
        "complaint_id": this.state.complaintId,
        "comment": ""
      };
      await this.props.rescheduleIVR(data);
      if (this.props.rescheduleIvr.success) {
        this.setState({ callReminderDate: '', callReminderTime: '', showCallReminder: false });
      }
    }
  };

  filterall = async () => {
    this.getComplaintList();
    // await this.props.showComplaintDetailsAction(false);
  };

  handleLoader=(value)=> {
    this.setState({
      loader2: value
    });

  }
  
  render() {
    let optionBucketDrop = [
      {
        value: 0,
        label: "Select All",
      }
    ];
    {
      this.state.productList.map((obj) => (
        optionBucketDrop.push(
          {
            // label: obj.name,
            value: obj.product_color_id,
            label: obj.id == 239 ? obj.product_name + "(" + "Size " + obj.size + " | " + obj.color_name + ")" : obj.product_name + "(" + obj.color_name + ")"
          }
        )
      ));
    }

    let optionProduct = [
      {

      }
    ];

    {
      this.state.newProductList.map((obj) => (
        optionProduct.push(
          {
            // label: obj.name,
            key: obj.product_color_id,
            value: obj.product_color_id,
            label: obj.id == 239 ? obj.product_name + "(" + "Size " + obj.size + " | " + obj.color_name + ")" : obj.product_name + "(" + obj.color_name + ")",
            selected: Number(obj.product_color_id) === Number(this.state.productColorId),
            product_id: obj.id,
            // id: obj.id
          }
        )
      ));

    }
    let paginationCount = this.props.paginationCount;
    let new_page = ((paginationCount.no_page - (paginationCount.no_page - this.state.current_page)) - 1);
    let userRoleId = localStorage.getItem('userRoleId');
    if (userRoleId === '4' || userRoleId === '8' || userRoleId === '9' || userRoleId === '10') {
      return (<AccessRestricted />);
    } else if (userRoleId === '6') {
      return (<ComplaintRegistration />);
    }
    else {
      return (
        <div>
          { window.innerWidth > 600 &&<SidebarComponent sideProp={'CC'} />}
          <ViewProfile />
          <div className="content complaint-main">
            <span className="complaint-head">Complaints</span>
            <div className="search">
              <input type="username" name="searchText" id="search_input" placeholder="Search by Ticket id, customer number..." onChange={this.handleChangeEvent} onKeyDown={this._handleKeyDown} />
              {this.state.searchText &&
                <button type="button" className="close-search" onClick={this.handleClose}><i className="fa fa-times" aria-hidden="true"></i></button>
              }
              <button type="button" className="add-user-btn-input" onClick={this.handleChangeButton}><i className="fa fa-search user_search_icon"></i></button>
            </div>
            {!this.state.isSearch && !this.props.showComplaintDetails &&
              <div className='menu_wrapper'>
                <ul id="menu">
                  <li className={this.state.teamName === 'CS' ? "selectedMenu" : null} onClick={(e) => this.onMenuClick(e, "CS")}>CS Team {this.state.teamName === 'CS' && paginationCount.total && !this.state.isLoading ? ' (' + paginationCount.total + ')' : ''}</li>
                  <li className={this.state.teamName === 'WH' ? "selectedMenu" : null} onClick={(e) => this.onMenuClick(e, "WH")}>Warehouse Team {this.state.teamName === 'WH' && paginationCount.total && !this.state.isLoading ? ' (' + paginationCount.total + ')' : ''}</li>
                  <li className={this.state.teamName === 'OOS' ? "selectedMenu" : null} onClick={(e) => this.onMenuClick(e, "OOS")}>Feedback (OOS){this.state.teamName === 'OOS' && paginationCount.total && !this.state.isLoading ? ' (' + paginationCount.total + ')' : ''}</li>
                  <li className={this.state.teamName === 'FD' ? "selectedMenu" : null} onClick={(e) => this.onMenuClick(e, "FD")}>Freshdesk {this.state.teamName === 'FD' && paginationCount.total && !this.state.isLoading ? ' (' + paginationCount.total + ')' : ''}</li>
                  <li className={this.state.teamName === 'Cred' ? "selectedMenu" : null} onClick={(e) => this.onMenuClick(e, "Cred")}>Cred {this.state.teamName === 'Cred' && paginationCount.total && !this.state.isLoading ? ' (' + paginationCount.total + ')' : ''}</li>
                  <li className={this.state.teamName === 'my_tickets' ? "selectedMenu" : null} onClick={(e) => this.onMenuClick(e, "my_tickets")}>My Tickets {this.state.teamName === 'my_tickets' && paginationCount.total && !this.state.isLoading ? ' (' + paginationCount.total + ')' : ''}</li>
                  <li className={this.state.teamName === 'priority_tickets' ? "selectedMenu" : null} onClick={(e) => this.onMenuClick(e, "priority_tickets")}>Priority Tickets {this.state.teamName === 'priority_tickets' && paginationCount.total && !this.state.isLoading ? ' (' + paginationCount.total + ')' : ''}</li>
                  {/* <li className={this.state.teamName == '6' ? "selectedMenu" : null}>Complaints closed </li> */}
                </ul>
                <div id ='complaintsfilters'>
                 {
                  !this.state.assignedStatus && !(this.state.teamName == "OOS") &&
                  <div id="st-box">
                    <div className="">
                      <h6>Select Bucket</h6>
                      {/* <Select
                    onChange={(e) => this.onStatusChange(e)}
                   options={optionBucketDrop}
                   isSearchable={true}
                   value={this.props.statusId}
                   maxMenuHeight={200}
                   className="problem-product-courier"
                    placeholder={this.props.statusId}
                   closeMenuOnSelect={true}                  
                    /> */}
                      {/* <i className="fa fa-angle-down" /> */}
                      <select id="bucket" className="dropbtn" value={this.props.statusId} onChange={(e) => this.onStatusChange(e)} disabled={this.state.isLoading}>
                        {this.props.bucketData.map((obj, index) => (
                          <option value={obj.id} key={index} selected={obj.id === this.props.statusId}>{obj.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                }
                

                {!(this.state.teamName == "OOS") &&
                  <div id="nd-box" className="select-prod-comp">
                    <div className="dropdown-wrap-problem">
                      <h6>Select Product</h6>
                      <Select
                        onChange={this.onProductChange}
                        options={optionBucketDrop}
                        isSearchable={true}
                        className="select-product-css selectProductDropdown"
                        //values={this.state.productId}
                        defaultValue={this.state.selectedProduct}
                        maxMenuHeight={200}
                        placeholder="Search Product.."
                        // closeMenuOnSelect={true}
                        styles={{
                          singleValue: provided => ({
                            ...provided,
                            color: '#fff !important'
                          }),
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: '1px solid #595959',
                            boxShadow: '0 !important',
                            '&:hover': {
                              border: '1px solid #595959 !important'
                            },
                            color: '#fff !important',
                            borderRadius: '20px !important',
                            backgroundColor: '#000 !important',
                            padding: '0 5px',
                            fontSize: '14px !important',
                            minHeight: '32px !important',
                            height: '32px !important'
                          }),
                          option: (baseStyles, state) => ({
                            ...baseStyles,
                            background: state.isFocused ? '#402500d7 !important' : '#000 !important',
                            color: state.isFocused ? '#FA9F22 !important' : '#fff !important',
                            width: '100%',
                            fontSize: '14px !important'
                          }),
                          Input: (baseStyles, state) => ({
                            ...baseStyles,
                            color: state.isFocused ? '#fff !important' : '#000'
                          }),
                          placeholder: (baseStyles) => ({
                            ...baseStyles,
                            color: '#fff !important'
                          }),
                          indicatorSeparator: () => null,
                          valueContainer: (baseStyles) => ({
                            ...baseStyles,
                            minHeight: 'auto !important',
                            height: 'auto !important',
                            color: '#fff !important',
                            marginTop: '-13px !important'
                          }),
                          DropdownIndicator: (baseStyles) => ({
                            ...baseStyles,
                            height: '25px !important',
                            marginBottom: '-13px !important',
                          }),
                          menuList: (baseStyles) => ({
                            ...baseStyles,
                            background: '#000 !important'
                          })
                        }}
                      />
                    </div>
                  </div>
                }
                {!(this.state.teamName == "OOS") &&
                  <div id="rd-box">
                    <div className="dateFilter">
                      <h6>Date - (DD/MM/YYYY)</h6>
                      <DateRangePicker showOneCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(new Date(new Date().setDate(new Date().getDate() - 120))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.dateSelect(value); }} value={[new Date(this.state.startDate), new Date(this.state.endDate)]} />
                    </div>
                  </div>
                }
                {!(this.state.teamName == "OOS") &&
                  <div id="dd-box">
                    <h6>More</h6>
                    <button data-toggle="modal" data-target="#modallong">More <i className="fas fa-filter"></i></button>
                    <div className="modal fade" id="modallong" tabIndex="-1" role="dialog" aria-labelledby="modallongtitle" aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modf">
                          <div className="modal-header">
                            <div className="Et">
                              <span>Filters</span>
                            </div> <div data-dismiss="modal" aria-label="Close" id="close_filters">
                              <span aria-hidden="true"><i className="fas fa-times"></i></span>
                            </div>
                          </div>
                          <div className="more5">
                            <div className="">
                              <h6>Product Problem</h6>
                              <select className="dropbtn" onChange={(e) => this.onProducdProblemChange(e)}>
                                {/* <i className="fa fa-angle-down" /> */}
                                <option value={'0'}> Select Product Problem</option>
                                {
                                  this.props.problemData.problems && this.props.problemData.problems.length > 0 ?
                                    this.props.problemData.problems.map((obj) => (<option value={obj.id}>{obj.problem}</option>))
                                    : null
                                }
                                {/* {this.state.problemList.map((obj) => (
                         <option value={obj.id}>{obj.category_name}</option>
                       ))} */}
                              </select>
                              <div style={{ "color": "grey", "fontSize": '90%', 'marginLeft': '12px' }}>Note: Please Select Problem</div>
                            </div>
                          </div>
                          {!this.state.otherProblem &&
                            <div className="more5">
                              <div className="">
                                <h6>Sub Problem</h6>
                                <select className="dropbtn" onChange={(e) => this.subProblemChange(e)}>
                                  <option value={'0'}> Select sub problem</option>
                                  {
                                    this.props.subproblemData && this.props.subproblemData.sub_problems && this.props.subproblemData.sub_problems.length > 0 ?
                                      this.props.subproblemData.sub_problems.map((obj) => (<option value={obj.id}>{obj.sub_problem}</option>))
                                      : null
                                  }
                                </select>
                                <div style={{ "color": "grey", "fontSize": '90%', 'marginLeft': '12px' }}>Note: Please Select sub problem</div>
                              </div>
                            </div>}
                          <div className="more4">
                            <div className="">
                              <h6>SLA Breached</h6>
                              <select className="dropbtn" onChange={(e) => this.onSLAChange(e)}>
                                {/* <i className="fa fa-angle-down" /> */}
                                <option value={0}> Select SLA Breached</option>
                                {this.state.SLAList.map((obj, index) => (
                                  <option value={obj.id} key={index}>{obj.value}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="warranty">
                            <div className="">
                              <h6>Warranty</h6>
                              <select className="dropbtn" onChange={(e) => this.onWarrantyChange(e)}>
                                {/* <i className="fa fa-angle-down" /> */}
                                <option> All</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                          </div>
                          <div className="applyFilter">
                            <button data-dismiss="modal" onClick={this.filterall}>Apply Filters</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {!(this.state.teamName == "OOS") &&
                  !this.state.assignedStatus && <div id="reset" onClick={(e) => this.onReset(e)}>
                    <button className="resetIcon">
                      <img src="../../../img/reset-icons.svg" alt="" /><p>Reset</p>
                    </button>
                  </div>
                }
                </div>
               
              </div>
            }
            {this.state.isLoading ?
              <div className='loader menumobileLoader'>
                <Loader
                  type="Oval"
                  color="Grey"
                  height={200}
                  width={200}
                />
              </div>
              : <div>
                {this.state.isSearch &&
                  <div className='search_title'>Search Results</div>
                }
                {
                  !this.props.showComplaintDetails ?
                    <div className="list-container">
                      {this.props.complaintsData.length > 0 ?
                        this.props.complaintsData.map((item, index) => {
                          return (
                            <div className="container6" key={index}>
                              {/* onClick={(e) => this.onItemPress(e, item)} */}
                              <div className="box" >
                                <div className="handclass" >
                                  <div className={(userRoleId === '1' || userRoleId === '2') ? "priority_wrapper pointer" : "priority_wrapper default"}>
                                    <i onClick={() => (userRoleId === '1' || userRoleId === '2') && this.handlePriority(item.id, item.is_priority)} className={item.is_priority === "1" ? "filled fa fa-star" : "fa fa-star-o"} title="Priority"></i>
                                  </div>
                                  <div className="handclassWrapper" onClick={(e) => this.onItemPress(e, item)}>
                                    <h6>{item.ticket_id}{item?.essential_warranty=="1"?"- 18MW":""}{item?.new_launch_tag=="1" ? "- NL" : ""}
                                      {Number(item.is_warranty) === 1 ?
                                        <button className="active">Active</button>
                                        :
                                        <button className="active">Expire</button>
                                      }
                                    </h6>
                                    {item.is_flag == 1 &&
                                      <img id="redFlag" src="/img/redflag.svg" />
                                    }
                                    <span style={{ cursor: 'pointer' }}>{item.product_name}({item.size && "Size " + item.size + " | "}{item.color_name})</span>
                                    {item.upgradeProduct &&
                                      <span className='upg'>
                                        UPG - {item.upgradeProduct.product_name}{item.upgradeProduct.size && "Size " + item.upgradeProduct.size + " | "}{item.upgradeProduct.color_name}
                                      </span>
                                    }
                                    <p style={{ cursor: 'pointer' }}>{moment(item.created_at).format('MMMM DD, YYYY || hh:mm:ss A')} || {item.channel_name}</p>
                                  </div>

                                </div>
                                <div className="box1" style={{paddingRight:"35px !important"}} onClick={(e) => this.onItemPress(e, item)}>
                                  <h6 style={{ cursor: 'pointer' }}>{item.problem}</h6>
                                  <span style={{ cursor: 'pointer' }} data-bs-toggle="tooltip" data-bs-placement="bottom" className="tooltip-white text-start" title={this.state.showDecription ? item.problem_description : item.sub_problem}>{this.state.showDecription ? item.problem_description : item.sub_problem}</span>
                                </div>
                                <div className="box2" onClick={(e) => this.onItemPress(e, item)}>
                                  {this.props.BucketIDlist != 0 ?
                                    <div>
                                      {
                                        [104, 106, 108].includes(item.complaint_status_id) ?
                                          <h6 style={{ cursor: 'pointer', color: 'red' }}>{this.state.isSearch || this.state.assignedStatus ? item.bucket_name : item.complaint_status_name}</h6>
                                          :
                                          <h6 style={{ cursor: 'pointer' }}>{this.state.isSearch || this.state.assignedStatus ? item.bucket_name : item.complaint_status_name}</h6>
                                      }
                                    </div> :
                                    this.state.isSearch ?
                                      <div>
                                        {
                                          [104, 106, 108].includes(item.complaint_status_id) ?
                                            <h6 style={{ cursor: 'pointer', color: 'red' }}>{this.state.isSearch || this.state.assignedStatus ? item.bucket_name : item.complaint_status_name}</h6>
                                            :
                                            <h6 style={{ cursor: 'pointer' }}>{this.state.isSearch || this.state.assignedStatus ? item.bucket_name : item.complaint_status_name}</h6>
                                        }
                                      </div> : ""

                                  }

                                  <span style={{ cursor: 'pointer' }}>{item.user_first_name} {item.user_last_name}</span>
                                  <p style={{ cursor: 'pointer' }}>{moment(item.updated_at).format('MMMM DD, YYYY || hh:mm:ss A')}</p>
                                </div>
                                <div className="box3">
                                  {
                                    item.bucketId === 11 ?
                                      <div>
                                        <div className='closedDuration'>
                                          <p>Closed time : <span>{item.closeDuration}</span></p>
                                        </div>
                                        <div>
                                          <i className="fas fa-phone" title="Call option" onClick={(e) => this.onPhoneClick(e, item)}></i>{item.no_of_manual_callsdone > 0 && <sup className='callCount'>({item.no_of_manual_callsdone})</sup>}
                                          <img src="/img/Revoke-Ticket-yellow.svg" alt="" className="fas fa-star" title="Revoke Ticket" onClick={(e) => this.onRevokeClick(e, item)} />
                                        </div>
                                      </div>
                                      :
                                      <div>
                                        <i className="fas fa-pencil-alt" title="Edit" onClick={(e) => this.onEditClick(e, item)}></i>

                                        {
                                          item.bucketId !== 47 && (item.bucketId !== 2 && item.bucketId !== 48) ?
                                            <i className="fas fa-star" title="Save Ticket" onClick={(e) => this.onSaveClick(e, item)}></i>
                                            : null
                                        }
                                        {/* {item.bucketId !== 17 ?
                                        <img src="/img/rpu-icon.svg" alt="" className="fas fa-star" title="Reverse Pickup" data-toggle="modal" data-target="#exampleModalabc" onClick={(e) => this.onRPUClick(e, item)} />
                                        :
                                        <i className="fa fa-arrow-right" title="Dispatch Outward" onClick={(e) => this.handleFPUOpenModal(e, item)}></i>
                                      } */}
                                        {
                                          (item.bucketId === 17 && item.current_prefix != "OOS") ?
                                            <i className="fa fa-arrow-right" title="Dispatch Outward" onClick={(e) => this.handleFPUOpenModal(e, item)}></i>
                                            : null
                                        }

                                        {
                                          ![12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 33, 35, 41, 42, 45, 47, 48].includes(item.bucketId) ?
                                            <img src="/img/rpu-icon.svg" alt="" className="fas fa-star" title="Reverse Pickup" data-toggle="modal" data-target="#exampleModalabc" onClick={(e) => this.onRPUClick(e, item)} /> : null
                                        }
                                        <i className="fas fa-phone" title="Call option" onClick={(e) => this.openScheduleCall(e, item)}></i>{item.no_of_manual_callsdone > 0 && <sup className='callCount'>({item.no_of_manual_callsdone})</sup>}

                                        {item.bucketId !== 17 && (item.bucketId !== 47 && item.bucketId !== 48) ?
                                          <i className="fas fa-arrow-circle-left" title="Cred" onClick={(e) => this.onCredClick(e, item)}></i>
                                          : item.bucketId === 17 ?
                                            <i className="fa fa-print" title="Forward pickup print" onClick={(e) => this.handleFPUPrint(e,item.id)} ></i>
                                            :
                                            null}

                                      </div>
                                  }
                                  {/* <i className="fas fa-star" title="Save Ticket" onClick={(e) => this.onSaveClick(e, item)}></i>
                                <img src="https://cdn.shopify.com/s/files/1/0997/6284/files/RPU_b70fcd2e-8194-4eb8-bc19-358949885b0c.svg?v=1637931896" alt="" className="fas fa-star" title="Reverse Pickup" data-toggle="modal" data-target="#exampleModalabc" onClick={(e) => this.onRPUClick(e, item)} />
                                <i className="fas fa-phone" title="Call option" onClick={(e) => this.onPhoneClick(e, item)}></i>
                                <i className="fas fa-arrow-circle-left" title="Cred" onClick={(e) => this.onCredClick(e, item)}></i> */}
                                  {Number(item.is_duplicate) === 1 ?
                                    <p>Duplicate</p>
                                    : null
                                  }
                                </div>
                                <CopyToClipboard
                                                               text={
                                                                 item.product_id === 239
                                                                   ? String(item.ticket_id).split("-")[0]
                                                                   : String(item.ticket_id).split("-")[0]
                                                               }
                                                               onCopy={this.onCopy}
                                                             >
                                                               <div className="copy_icon">
                                                                 <i className="fa fa-copy"></i>
                                                               </div>
                                                             </CopyToClipboard>
                              </div>
                            </div>
                          );
                        })
                        :
                        <div className="no-result-text">
                          <p>No result found</p>
                        </div>
                      }
                    </div> :
                    
                    <div className='loader mobileloader' style={{marginTop :  this.state.loader2 ? "7%" : "1%"}}>
                      {this.state.loader2 ?
                        <Loader
                          type="Oval"
                          color="Grey"
                          height={200}
                          width={200}
                        />
                        :
                        <ComplaintDetailsNew handleRefreshList={this.handleRefreshList} handleProdutcRefreshList={this.handleProdutcRefreshList} handleLoader ={this.handleLoader} />
                      }
                    </div>
                }
                <ReactModal
                  isOpen={this.state.showCallReminder}
                  contentLabel="Minimal Modal Example"
                  style={customStylesCallReminder}
                  ariaHideApp={false}
                  className="callReminder">
                  <h2>Schedule Call <i className="fa fa-times" aria-hidden="true" onClick={this.closeCallReminder}></i></h2>
                  <div className="callReminderDate">
                    <div className="dateWrapper">
                      <label for="date-complaint">Enter Date</label>
                      <input type="date" id="date-complaint" placeholder={this.state.today} onChange={(e) => this.setState({ callReminderDate: e.target.value })} min={this.state.today} value={this.state.callReminderDate}></input>
                      <span id="errorDate">{this.state.errors["errorDate"]}</span>
                    </div>
                    <div className="timeWrapper">
                      <label for="appt">Select a time:</label>
                      <input type="time" id="appt" name="appt" min={this.state.time} onChange={(e) => this.setState({ callReminderTime: e.target.value })} value={this.state.callReminderTime} />
                      <span id="errorDate">{this.state.errors["errorTime"]}</span>
                    </div>
                  </div>
                  <div className="scheduleCtaWrapper">
                    <button className="callCta save" onClick={this.handleCallReminder}>Save</button>
                    <button className="callCta cancel" onClick={this.closeCallReminder}>Cancel</button>
                  </div>
                  <div className="footer" onClick={this.onPhoneClick}>
                    <i className="fas fa-phone"></i> Direct Call
                  </div>
                </ReactModal>
              </div>
            }
            <ReactModal
              isOpen={this.state.showFPUModal}
              contentLabel="Minimal Modal Example"
              style={customStylesDO}
              ariaHideApp={false}
            >
              <h2 id="rModal_head">Forward Pickup</h2>
              <button id="close_rModal" onClick={this.handleFPUCloseModal}><i className="fas fa-times"></i></button>
              <select id="select_rModal" className="form-control" onChange={this.handleCourierChange}>
                <option value={"0"} selected="selected">Select Courier</option>
                {this.props.courierData.map((obj) => (
                  <option value={obj.courier_id}>{obj.courier_name} - {obj.pincode} - Rs.{obj.reverse_cost}</option>
                ))}
              </select>
              {this.state.isLoadingGif ?
                <button id="send_rModal" type="button"><img className="loaderCTA" src="/img/loading-gif.gif" alt="Loader" /></button>
                :
                <button id="send_rModal" type="button" onClick={this.onFPUSubmit}>Send</button>
              }
            </ReactModal>
            {/* FPU print modal */}
            <ReactModal
              isOpen={this.state.showFPUPrintModal}
              contentLabel="Minimal Modal Example"
              style={customStylesRPU}
              ariaHideApp={false}
              className="rpu_print"
            >
              <button type='button' onClick={this.handlePrintModalClose} id="close_rModal"><i className="fas fa-times"></i></button>
              <ComponentToFPUPrint ref={el => (this.componentRef = el)} />
            </ReactModal>
            <ReactModal
              isOpen={this.state.showEditTicket}
              contentLabel="Minimal Modal Example"
              style={styleEdit}
              ariaHideApp={false}
              className="modal-header"
            >
              <div className="Et">
                <div className="ticket_wrapper">
                  <span>Edit Ticket</span>
                  <span className="s">{this.state.ticketId}</span>
                  {Number(this.state.isWarranty) === 1 ?
                    <button className="active">Active</button>
                    :
                    <button className="active">Expire</button>
                  }
                  <button id="close_rModal" onClick={this.handleCloseEditTicket}><i className="fas fa-times"></i></button>
                </div>
                <div className="lbl">
                  <p className="og_product">Poduct</p>
                  {/* <input type="text" disabled placeholder="Enter Product Name" name="productName" value={this.state.productName} onChange={this.handleEdit} /> */}
                  {
                    (this.state.old_mac_id == null || this.state.old_mac_id == 'DOA' || this.state.old_mac_id != 'NON-NFA' || this.state.old_mac_id == "null" )
                      ?
                      <Select
                        inputId="selectProductInput"
                        onChange={this.handleChangeProductSelection}
                        options={optionProduct}
                        isSearchable={true}
                        className="select-product-css dropbtn1111"
                        maxMenuHeight={200}
                        placeholder={this.state.productName}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: '1px solid #595959',
                            boxShadow: '0 !important',
                            '&:hover': {
                              border: '1px solid #595959 !important'
                            },
                            borderRadius: '20px !important',
                            backgroundColor: '#000 !important',
                            padding: '0 5px',
                            fontSize: '14px !important',
                            height: '40px !important',
                            width: "100%",
                          }),
                          input: (baseStyles, state) => ({
                            ...baseStyles,
                            color: '#fff !important',
                            background: '#fff !important'
                          }),
                          option: (baseStyles, state) => ({
                            ...baseStyles,
                            background: state.isFocused ? '#402500d7 !important' : '#000 !important',
                            color: state.isFocused ? '#FA9F22' : '#fff !important',
                            width: '100%'
                          }),
                          placeholder: (baseStyles) => ({
                            ...baseStyles,
                            color: '#000 !important'
                          }),
                          indicatorSeparator: () => null,
                          valueContainer: (baseStyles) => ({
                            ...baseStyles,
                            minHeight: '32px !important',
                            height: '32px !important',
                            color: '#fff !important'
                          }),
                          menuList: (baseStyles) => ({
                            ...baseStyles,
                            background: '#000 !important'
                          }),
                        }}
                      // closeMenuOnSelect={true}  
                      />
                      :
                      <select className="dropbtn1111" onChange={((e) => this.handleChangeProductSelectionsecond(e))}>
                        <option value={'0'} disabled> Select Product</option>
                        {this.state.nfa_product_list.map((obj, index) => (
                          <option disabled ={obj.warranty_status != "valid"}  key={obj.product_id} value={`${obj.product_color_id},${obj.product_id},${obj.mac_id},${obj.purchase_date}`} name={obj.product_id} selected={Number(obj.product_color_id) === Number(this.state.productColorId)}>{obj.product_name} ({obj.color_name})</option>
                        ))}
                      </select>
                  }

                  {/* <i className="fas fa-chevron-down"></i> */}
                </div>
                {this.state.complaint_status_id == 43 && 
                <div className="lbl9" style={{float:"none"}}>
                  <p>Faults</p>
                  <select className="dropbtn1111" value={this.state.fault_id} onChange={(e) => this.onFaultChange(e)}>
                    <option value={'0'} disabled> Select Fault </option>
                    {this.state.faultsList.map((obj) => (
                      <option key={obj.id} value={obj.id} selected={Number(obj.id) === Number(this.state.fault_id)}>{obj.name}</option>
                    ))}
                  </select>
                  <div className="error_add">
                    <span className="errorShow">{this.state.errors["channel"]}</span>
                  </div>
                </div>
                }
                <div className="lbl2">
                  <p>Product Problem</p>
                  <input type="text" disabled placeholder="Enter Product Problem" name="problemName" value={this.state.problemName} onChange={this.handleEdit} />
                  {/* <i className="fas fa-chevron-down"></i> */}
                </div>
                <div className="lbl3">
                  <p>Sub Problem</p>
                  <input type="text" disabled placeholder="Enter Sub Problem" name="problemDesc" value={this.state.subProblemName} onChange={this.handleEdit} />
                </div>
                <div className="lbl7">
                  <p>Problem Description</p>
                  <input type="text" placeholder="Enter Problem Description" name="problemDesc" value={this.state.problemDesc} onChange={this.handleEdit} />
                </div>



                {/* <div className="lbl6">
                  <p>Purchase Date</p>
                  <input type="date" placeholder="Enter Purchase Date" name="purchaseDate" value={this.state.purchaseDate} onChange={this.handleEdit} max="9999-12-31" />
                  <div className="error_add">
                    <span className="errorShow">{this.state.errors["purchaseDate"]}</span>
                  </div>
                </div> */}
                <div className="lbl8">
                  <p>Purchase Date</p>
                  <input type="text" placeholder="Enter Purchase Date" disabled = {this.state.nfa_product_list?.length > 0 ? true : false} name="purchaseDate" value={this.state.purchaseDate} onChange={this.handleEdit} />
                  <div className="error_add">
                    <span className="errorShow">{this.state.errors["purchaseDate"]}</span>
                  </div>
                </div>
                <div className="lbl9">
                  <p>Channel</p>
                  <select className="dropbtn1111" onChange={(e) => this.onChannelhange(e)} name="channel">
                    {/* <i className="fa fa-angle-down" /> */}
                    <option value={'0'}> Select Channel </option>
                    {this.state.channellist.map((obj) => (
                      <option key={obj.id} value={obj.id} selected={Number(obj.id) === Number(this.state.channel_id)}>{obj.name}</option>
                    ))}
                  </select>
                  <div className="error_add">
                    <span className="errorShow">{this.state.errors["channel"]}</span>
                  </div>
                </div>

                <div className="lbl4">
                  <p>Email</p>
                  <input type="text" placeholder="Enter Email" name="email" value={this.state.email} onChange={this.handleEdit} />
                  <div className="error_add">
                    <span className="errorShow">{this.state.errors["email"]}</span>
                  </div>
                </div>
                <div className="lbl5">
                  <p>Mobile number</p>
                  <input type="text" disabled placeholder="Enter Mobile number" name="mobile" value={this.state.mobile} onChange={this.handleEdit} maxLength={10} />
                  <div className="error_add">
                    <span className="errorShow">{this.state.errors["mobile"]}</span>
                  </div>
                </div>
                <div className="lbl7">
                  <p>Address 1</p>
                  <input type="text" placeholder="Enter Address1" name="address1" value={this.state.address1} onChange={this.handleEdit} required />
                  <div className="error_add">
                    <span className="errorShow">{this.state.errors["address1"]}</span>
                  </div>
                  <p className="second-child">Address 2</p>
                  <input type="text" placeholder="Enter Address2" name="address2" value={this.state.address2} onChange={this.handleEdit} />
                </div>
                <div className="lbl2">
                  <p>Zip Code</p>
                  <input type="text" placeholder="Enter Pincode" name="pinCode" value={this.state.pinCode} onChange={this.handleEdit} />
                </div>
                <div className="lbl9">
                  <p>State</p>
                  {/* <input type="text" placeholder="Enter State" name="state" value={this.state.state} onChange={this.handleEdit} /> */}
                  {/* <i className="fas fa-chevron-down"></i> */}
                  <select className="dropbtn1111" onChange={(e) => this.onStateChange(e)} name="state">
                    {/* <i className="fa fa-angle-down" /> */}
                    <option value={'0'}> select state</option>
                    {this.state.stateList.map((obj) => (
                      <option key={obj.id} value={Number(obj.id)} selected={Number(obj.id) === Number(this.state.state_id)}>{obj.state_name}</option>
                    ))}
                  </select>
                  <div className="error_add">
                    <span className="errorShow">{this.state.errors["state"]}</span>
                  </div>
                </div>
                <div className="lbl10">
                  <p>City</p>
                  {/* <input type="text" placeholder="Enter City" name="city" value={this.state.city} onChange={this.handleEdit} /> */}
                  {/* <i className="fas fa-chevron-down"></i> */}
                  <select className="dropbtn1111" style={{ 'width': "92%" }} id="select_city" onChange={(e) => this.onCityChange(e)}>
                    {/* <i className="fa fa-angle-down" /> */}
                    <option value={'0'}> select city</option>
                    {this.state.cityList?.map((obj) => (
                      <option key={obj.id} value={obj.id} selected={Number(obj.id) === Number(this.state.city_id)}>{obj.city}</option>
                    ))}
                  </select>
                  <div className="error_add">
                    <span className="errorShow">{this.state.errors["city"]}</span>
                  </div>
                </div>
                {/* <div className="lbl11">
                <p>Comment</p>
                <input type="text" placeholder="Enter Comment" name="comment" value={this.state.comment} onChange={this.handleEdit} />
              </div> */}
                <div className="lbl12" style={{ "margin-left": "-25px" }} >
                  <p>Waybill No.</p>
                  <input type="text" placeholder="Enter Waybill No." name="waybillNo" value={this.state.waybillNo} onChange={this.handleEdit} />
                </div>
                <div className="gstin">
                  <p>GSTIN</p>
                  <input maxLength={15} id="gtin1" type="text" placeholder="Enter GSTIN" name="gstin" value={this.state.gstin.toUpperCase()} onChange={this.handleEdit} />
                  <div className="error_add">
                    <span className="errorShow">{this.state.errors["gstin"]}</span>
                  </div>
                </div>
             
                <div className="prefix">
                  <p>Warehouse</p>
                  <select className="dropbtn1111" id="select_warehouse" onChange={this.prefixChange}>
                    <option selected={this.state.current_prefix === ''} value={''}>Gurugram</option>
                    <option selected={this.state.current_prefix === 'MUM'} value={'MUM'}>Mumbai</option>
                  </select>
                  <div className="error_add">
                    <span className="errorShow">{this.state.errors["current_prefix"]}</span>
                  </div>
                </div>
                <div className="lbl13 update_ticket">
                  <button onClick={(e) => this.onUpdateClick(e)} >Update Ticket</button>
                </div>
              </div>
            </ReactModal>
            <ReactModal
              isOpen={this.state.showRPUModal}
              contentLabel="Minimal Modal Example"
              style={customStyles}
              ariaHideApp={false}
            >
              <h2 id="rModal_head">RPU</h2>
              <button id="close_rModal" onClick={this.handleRPUCloseModal}><i className="fas fa-times"></i></button>
              <select id="select_rModal" className="form-control" onChange={this.handleCourierChange}>
                <option value={"0"} selected="selected">Select Courier</option>
                {this.props.courierData.map((obj) => (
                  <option value={obj.courier_id}>{obj.courier_name} - {obj.pincode} - Rs.{obj.reverse_cost}</option>
                ))}
              </select>
              {this.state.isLoadingGif ?
                <button id="send_rModal" type="button"><img className="loaderCTA" src="/img/loading-gif.gif" alt="Loader" /></button>
                :
                <button id="send_rModal" type="button" onClick={this.handleRPUSubmit}>Send</button>
              }
            </ReactModal>
            <Footer new_page={new_page} onPrevPress={this.onPrevPress} onNextPress={this.onNextPress} current_page={this.state.current_page} cc={"kk"} />
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  complaintsData: state.complaints.complaintsData,
  bucketData: state.complaints.bucketData,
  statusData: state.complaints.statusData,
  productData: state.complaints.productData,
  problemData: state.complaints.problemData,
  editData: state.complaints.editData,
  callRes: state.complaints.callRes,
  complaintsDetail: state.complaints.complaintsDetail,
  courierData: state.complaints.courierData,
  statusId: state.complaints.statusId,
  showComplaintDetails: state.complaints.showComplaintDetails,
  stateDataList: state.complaints.stateData,
  cityDataList: state.complaints.cityData,
  complaintsDataCount: state.complaints.complaintsDataCount,
  refreshComplaintList: state.complaints.refreshComplaintList,
  paginationCount: state.complaints.paginationCount,
  printLabel: state.complaints.printLabel,
  rescheduleIvr: state.complaints.rescheduleIvr,
  channelDatalist: state.complaints.channelDatalist,
  subproblemData: state.complaints.subproblemData,
  BucketIDlist: state.complaints.BucketIDlist,
  nonNfa: state.complaints.nonNfa,
});

export default connect(mapStateToProps, {
  getComplaintData, getComplaintBuckets, getComplaintStatus, getProductList, getProductProblemList, getAllNonNfa,
  getRpuCourier, editComplaintData, getComplaintDetail, callCustomer, addStatusId, showComplaintDetailsAction,
  getStateList, getCityByState, submitRPU, revokeTicket, fetchPrintLabel, rescheduleIVR, getChannelList, adminList, getSubProblemList, addBucketID,getFaultsList
})(ComplaintsComponent);