import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  ServiceCenterComplaintData, exportReport, showComplaintDetailsAction, getSerCenterList
} from '../../actions/serviceCenterActions';
import moment from 'moment'
import "./ServiceCenter.css"
import DateRangePicker from 'rsuite/DateRangePicker';
import Loader from 'react-loader-spinner'
// import { addStatusId } from '../../actions/complaintsDataAction';
import { fetchComplaintDetails, TroubleShootShow } from '../../actions/serviceCenterComplaintAction'
import { useHistory } from 'react-router-dom';

const {
  // allowedMaxDays,
  // allowedDays,
  allowedRange,
  // beforeToday,
  // afterToday,
  // combine
} = DateRangePicker;

function AllComplaints() {
  const history = useHistory();
  const initialValues = {
    totalPage: 1,
    totalCount: 0,
    prevPage: false,
    nextPage: false,
    current_page: 1,
    pageLimit: 12,
    searchTextLength: 0,
    searchText: '',
    serviceCenterID: '',
    startDate: new Date().setDate(new Date().getDate() - 90),
    endDate: new Date().setDate(new Date().getDate()),
  };

  const [values, setValues] = useState(initialValues);
  const [isLoading, setisLoading] = useState(false);
  const dispatch = useDispatch();
  const serviceData = useSelector((state) => state.serviceCenter.serviceNewData)
  const [showLoader, setShowLoader] = useState(true)
  const serviceComplaints = serviceData && serviceData.complaint_data || []
  const sclist = useSelector((state) => state.serviceCenter.scList);
  let new_page = ((serviceData && serviceData.no_page - (serviceData && serviceData.no_page - values.current_page)) - 1);

  useEffect(() => {
    let timer = setTimeout(() => {
      let data = {
        detail: true
      }
      dispatch(getSerCenterList(data))
    }, 100)

    return () => { clearInterval(timer) }

  }, [])

  useEffect(() => {
    let timer = setTimeout(() => {
      getComplaintList()
    }, 100)

    return () => { clearInterval(timer) }

  }, [values.serviceCenterID, values.current_page, values.startDate, values.endDate]);

  const getComplaintList = async () => {
    // let scAddress = localStorage.getItem('scAddress');
    let data = {
      "limit": 12,
      "page_no": values.current_page,
      "service_center_id": values.serviceCenterID,
      "startDate": moment(values.startDate).format('YYYY-MM-DD'),
      "endDate": moment(values.endDate).format('YYYY-MM-DD'),
      "ticketStatus": "all",
      "tag": "panel",
    }
    setisLoading(true)
    await dispatch(ServiceCenterComplaintData(data))
    setisLoading(false)
    setValues({
      ...values,
      totalCount: serviceData.total,
      totalPage: serviceData ? serviceData.no_page : 0,
      prevPage: serviceData ? serviceData.prev_page : 0,
      nextPage: serviceData ? serviceData.next_page : 1,
    })
  }
  const handleTicketClick = (e, item) => {
    dispatch(fetchComplaintDetails(item.id));
    dispatch(TroubleShootShow(true))
    history.push('/complaint-details')
  }


  const onPrevPress = () => {
    if (serviceData.prev_page) {
      setValues({ ...values, current_page: (values.current_page - 1) }, async () => {
        getComplaintList()
      })
    }
  }


  const onNextPress = () => {
    if (serviceData.next_page) {
      setValues({ ...values, current_page: (values.current_page + 1) }, async () => {
        getComplaintList()
      })
    }
  }

  const onStatusChange = (e) => {
    setValues({
      ...values,
      serviceCenterID: e.target.value, current_page: 1
    })
  }

  const onExportClick = async () => {
    let data = {
      "fromDate": moment(values.startDate).format('YYYY-MM-DD'),
      "toDate": moment(values.endDate).format('YYYY-MM-DD'),
    }
    dispatch(exportReport(data))
  }

  const dateSelect = async (value) => {
    setValues({
      ...values,
      startDate: new Date(value[0]),
      endDate: new Date(value[1])
    }, async () => {
      getComplaintList();
      dispatch(showComplaintDetailsAction(false))
    })
  }

  return (
    <>
      <div className='activeComplaints mainContent'>
        <div className='headingService  '>
          <h1>All Complaints</h1>
          <div className="filters">
            <div className=''>{
              sclist && sclist.length > 0 ?
                <select className="dropbtnService" onChange={(e) => onStatusChange(e)}>
                  {sclist ? sclist.map((obj, index) => (
                    <option value={obj.service_center_id} key={index}>{obj.name}</option>
                  )) : 'null'}
                </select> : null

            }</div>

            <div className="service-filterDate">
              <DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(new Date(new Date().setDate(new Date().getDate() - 120))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { dateSelect(value) }} value={[new Date(values.startDate), new Date(values.endDate)]} />
            </div>
            <button className='exportOptions' onClick={(e) => onExportClick(e)}><i className="fa fa-thin fa-upload"></i></button>
          </div>
        </  div>
        {isLoading ?
          <div className='loader'>
            <Loader
              type="Oval"
              color="Grey"
              height={200}
              width={200}
              className='loaderNew'
            />
          </div> :
          <div>
            <div className="servicecenter_listContainer">
              {

                serviceComplaints && serviceComplaints.map((item, index) => {
                  return (
                    <div className="center_ticketContainer" key={item.id} onClick={e => handleTicketClick(e, item)}>
                      <h2>{item && item.service_center ? item.service_center.name : "null"}</h2>
                      <div id="borderService"></div>
                      <div className='cdetils'>
                        <span id="name2">Compliants Details: </span>
                        <span id="problemss">{item.problem}  </span>
                      </div>
                      <div className='cdetils'>
                        <span id="name2">Product Name: </span>
                        <span id="problemss">{item.product_name}  </span>
                      </div>
                      <p id="borderService"></p>
                      <div className="statusDetais">
                        <span id="name3">Current Status: </span>
                        <span id="name4">{item.complaint_status_name == null ? "null" : item.complaint_status_name} </span>
                      </div>
                      <div className="statusDetais">
                        <span id="name3">screening Status : </span>
                        <span id="name4">{item.screening_status} </span>
                      </div>
                      <div className="statusDetais">
                        <span id="name3">Date </span>
                        <span id="name4">{moment(item.created_at).format('DD-MM-YYYY')}</span>
                      </div>
                    </div>
                  )
                })

              }</div>
            <div className="footers">
              <div className="pages1">
                {serviceData && serviceData.total > 0 ?
                  <div>
                    {serviceData && serviceData.no_page === 1 ?
                      <p style={{ color: 'black', fontWeight: "500" }}>
                        1 - {serviceData && serviceData.total} of {serviceData && serviceData.total} results
                      </p>
                      :
                      <p>
                        {values.pageLimit * new_page > 1 ?
                          values.pageLimit * new_page
                          :
                          '1'
                        }
                        -
                        {((values.pageLimit * values.current_page) > (serviceData && serviceData.total)) && ((serviceData && serviceData.total) > (values.pageLimit * new_page)) ?
                          (serviceData && serviceData.total)
                          :
                          (values.pageLimit * values.current_page)} of {serviceData && serviceData.total} results
                        {/* <i  onClick={onPrevPress}><i className="fas fa-chevron-left" style={{color:"black"}} /></i> */}
                        <i className="fa fa-chevron-left" style={{ color: "black" }} onClick={onPrevPress} />
                        <span>{values.current_page}</span>
                        <i className="fa fa-chevron-right" style={{ color: "black" }} onClick={onNextPress} />
                      </p>
                    }
                  </div>
                  : <div className="Noreult">
                    <span>No complaints found</span>
                  </div>
                }
              </div>

            </div>
          </div>
        }
      </div>
    </>
  )
}

export default AllComplaints