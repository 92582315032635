import axios from "axios";
import {
    FETCH_USER_DATA,
    FETCH_ACCESSLAVEL_DATA,
    FETCH_REPORTING_DATA,
    GET_COMPLAINT_BUCKET,
    FETCH_TICKET_DATA,
    GET_USER_DETAIL,
    SET_USER_DATA,
    EDIT_USER_DATA,
    USER_DELETE,
    UNASSIGNED_COMPLAINT_LIST,
    TICKET_ASSIGNED,
    FETCH_SERVICE_REPORT_CONFIG,
    GET_STATE_LIST,
    GET_CITY_LIST,
} from "./types";
import { baseUrl, oldUrl, webBaseUrl } from "../config";
import history from "../history";

// import moment from 'moment'

// export const clearUserData = () => async dispatch => {
//   dispatch({
//     type: CLEAR_ALL_USER_DATA,
//   })
// }

// export const addUserSelection = (data) => async dispatch => {
//   dispatch({
//     type: ADD_USER_SELECTION,
//     payload: data
//   })
// }

// export const removeUserSelection = () => async dispatch => {
//   dispatch({
//     type: REMOVE_USER_SELECTION,
//   })
// }

export const goBack = () => {
    history.goBack();
};

export const downlaodCenterReportData = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/service-center-report/", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                // dispatch({
                //   type: FETCH_INVOICE_REPORT_DATA,
                //   payload: res.data.data
                // })
                dispatch({
                    type: FETCH_SERVICE_REPORT_CONFIG,
                    payload: res.data.checkDownloadLimit[0],
                });
            } else {
                // if (res.data.code == 1001 || res.data.code == 1002 || res.data.code == 1003  ){
                //   localStorage.removeItem('token')
                //   history.push('/')
                // }
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getUserData = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/user/list", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_USER_DATA,
                    payload: res.data,
                });
            } else {
                // if (res.data.code == 1001 || res.data.code == 1002 || res.data.code == 1003  ){
                //   localStorage.removeItem('token')
                //   history.push('/')
                // }
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getUnassignTicket = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/complaint/get-complaint", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                //   var link = document.querySelectorAll('input[type="checkbox"]:checked');
                //         for(var i=0;i<link.length;i++){
                //           link[i].click();
                //         }
                // var link = document.querySelectorAll('.container3 .bx input[type="checkbox"]:checked');
                //         for(var i=0;i<link.length;i++){
                //           link[i].click();
                //         }
                dispatch({
                    type: FETCH_TICKET_DATA,
                    payload: res.data,
                });
                dispatch({
                    type: UNASSIGNED_COMPLAINT_LIST,
                    payload: res.data.data.complaint_data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

// need to change this
export const assignUserTicket = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/user/list-a", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_USER_DATA,
                    payload: res.data,
                });
            } else {
                // if (res.data.code == 1001 || res.data.code == 1002 || res.data.code == 1003  ){
                //   localStorage.removeItem('token')
                //   history.push('/')
                // }
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getUserDetail = data => async dispatch => {
    let token = localStorage.getItem("token");
    let id = data.user_id;
    return await axios
        .get(baseUrl + "/user/get/" + id, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: GET_USER_DETAIL,
                    payload: res.data,
                });
            } else {
                // if (res.data.code == 1001 || res.data.code == 1002 || res.data.code == 1003  ){
                //   localStorage.removeItem('token')
                //   history.push('/')
                // }
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const createUser = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/user/add", data, { headers: { "x-access-token": token } })
        .then(res => {
            alert(res.data.message);
            if (res.data.success) {
                dispatch({
                    type: SET_USER_DATA,
                    payload: res.data,
                });
            } else {
                // if (res.data.code == 1001 || res.data.code == 1002 || res.data.code == 1003  ){
                //   localStorage.removeItem('token')
                //   history.push('/')
                // }
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
            if (e.response.status === 429) {
                alert(e.response.statusText || "Something went wrong");
            }
        });
};

export const editUserData = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/user/edit", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: EDIT_USER_DATA,
                    payload: res.data,
                });
            } else {
                alert(res.data.message);
            }

            // alert(res.data.message)
            if (res.data.success) {
                return true;
            } else {
                return false;
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getAccessLevelData = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .get(baseUrl + "/user/rolelist", { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_ACCESSLAVEL_DATA,
                    payload: res.data,
                });
            } else {
                // if (res.data.code == 1001 || res.data.code == 1002 || res.data.code == 1003  ){
                //   localStorage.removeItem('token')
                //   history.push('/')
                // }
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getReportinglData = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/user/list", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_REPORTING_DATA,
                    payload: res.data,
                });
            } else {
                // if (res.data.code == 1001 || res.data.code == 1002 || res.data.code == 1003  ){
                //   localStorage.removeItem('token')
                //   history.push('/')
                // }
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getComplaintBuckets = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/complaint/buckets", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: GET_COMPLAINT_BUCKET,
                    payload: res.data,
                });
            } else {
                // if (res.data.code === 1001 || res.data.code === 1002 || res.data.code === 1003  ){
                //   localStorage.removeItem('token')
                //   history.push('/')
                // }
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const removeUserData = data => async dispatch => {
    let token = localStorage.getItem("token");
    let id = data.user_id;
    return await axios
        .get(baseUrl + "/user/remove/" + id, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: USER_DELETE,
                    payload: res.data,
                });
            } else {
                // if (res.data.code === 1001 || res.data.code === 1002 || res.data.code === 1003  ){
                //   localStorage.removeItem('token')
                //   history.push('/')
                // }
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

// get all ticket data

// export const getAllTicket = (data) => async dispatch => {
//   let token = localStorage.getItem('token');
//   return await axios.post(baseUrl + '/complaint/tickets', data, { 'headers': { 'x-access-token': token } })
//     .then(res => {
//       if (res.data.success) {
//         dispatch({
//           type: GET_COMPLAINT_TICKET,
//           payload: res.data
//         })
//       }
//     })
//     .catch((e) => {
//       history.push('/')
//     })
// }

export const assignTicket = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/complaint/assign", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                //   alert(res.data.message);
                dispatch({
                    type: TICKET_ASSIGNED,
                    payload: res.data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getStateList = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/master/state", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: GET_STATE_LIST,
                    payload: res.data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getCityByState = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/master/city", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: GET_CITY_LIST,
                    payload: res.data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};
