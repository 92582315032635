
import axios from "axios";
import { baseUrl, webBaseUrl } from "../config";
import history from "../history";
import {
  GET_WARRANTY_DATA_INTERNATIONAL,
  GET_PRODUCTS,
  GET_WARRANTY_DATA,
  GET_NOTIFY_DATA,
  FETCH_PAGINATION_INFO,
} from "./types";

export const getwarrantyData = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/warranty/get", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_WARRANTY_DATA,
          payload: res.data || [],
        });
        dispatch({
          type: FETCH_PAGINATION_INFO,
          payload: res.data || [],
        });
      } else {
        alert(res.data.message);
      }
    })
    .catch((e) => {
      history.push("/");
    });
};
export const getwarrantyInternational = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/warranty/getForeignWarranty", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_WARRANTY_DATA_INTERNATIONAL,
          payload: res.data || [],
        });
        dispatch({
          type: FETCH_PAGINATION_INFO,
          payload: res.data || [],
        });
      } 
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getProductList = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/product/get-details", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_PRODUCTS,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};
export const getNotifyData = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(webBaseUrl + "/website/notify-me/fetch", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_NOTIFY_DATA,
          payload: res.data || [],
        });
        dispatch({
          type: FETCH_PAGINATION_INFO,
          payload: res.data || [],
        });
      } else {
        dispatch({
          type: GET_WARRANTY_DATA,
          payload: null,
        });
        alert(res.data.message);
      }
    })
    .catch((e) => {
      history.push("/");
    });
};
