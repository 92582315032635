import React, { Component } from 'react'
import './ReadOnly.css';
import { connect } from "react-redux";
import moment from 'moment'
import Loader from 'react-loader-spinner'
import {
    getComplaintData, getComplaintBuckets, getComplaintStatus, getProductList,
    getProductProblemList, getRpuCourier, editComplaintData, getComplaintDetail, callCustomer,
    addStatusId, showComplaintDetailsAction, getStateList, getCityByState,
    submitRPU, revokeTicket, fetchPrintLabel
} from '../../actions/complaintsDataAction'
import history from '../../history';
import ReadOnlyTicketDetails from './ReadOnlyTicketDetails';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';
// import Select from 'react-select';
// import ReactTooltip from "react-tooltip";
import ReactModal from 'react-modal';
import Select from 'react-select';
import ViewProfile from '../ViewProfile';
// import ComponentToFPUPrint from './ComponentToFPUPrint';

const {
    // allowedMaxDays,
    // allowedDays,
    // allowedRange,
    // beforeToday,
    // afterToday,
    // combine
} = DateRangePicker;

const customStyles = {
    overlay: {
        background: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
        inset: '8% 0 0 auto',
        marginRight: '0',
        transform: 'translate(0)',
        background: '#000',
        padding: '2rem',
        border: 'none'
    },
};
const customStylesDO = {
    overlay: {
        background: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
        inset: '8% 0 0 auto',
        marginRight: '0',
        transform: 'translate(0)',
        background: '#000',
        padding: '2rem',
        border: 'none',
        width: '35%',
        position: 'absolute'
    },
};
const styleEdit = {
    overlay: {
        background: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
        inset: '8% 0 0 auto',
        marginRight: '0',
        transform: 'translate(0)',
        background: '#000',
        padding: '0',
        border: 'none',
        height: '600px',
        overflow: 'hidden',
        overflowY: 'scroll',
        position: 'fixed',
        width: '50%',
        float: 'right'
    },
};
const customStylesRPU = {
    overlay: {
        background: 'rgba(0, 0, 0, 0.5)',
        overflow: "scroll"
    },
    content: {
        inset: '8% 0 0 auto',
        marginRight: '0',
        transform: 'translate(0)',
        background: '#fff',
        padding: '2rem',
        border: 'none',
        width: '100%',
        position: 'absolute',
        overflow: 'scroll',

    },
}

class ReadOnly extends Component {
    constructor() {
        super();
        this.state = {
            showEditTicket: false,
            isLoading: false,
            showMenu: false,
            logout: false,
            pageNo: 1,
            pageLimit: 10,
            searchText: '',
            searchDate: '',
            complaintList: [],
            prevPage: false,
            nextPage: false,
            statusList: [],
            productList: [],
            SLAList: [{ "id": 'true', "value": "Yes" }, { "id": 'false', "value": "No" }],
            problemList: [],
            statusId: 1,
            productId: 0,
            SLAId: '',
            warranty: '',
            problemId: 0,
            totalPage: 1,
            totalCount: 0,
            teamName: "CS",
            complaintId: 0,
            productName: '',
            problemName: '',
            problemDesc: '',
            email: '',
            mobile: '',
            customer_id: 0,
            purchaseDate: '',
            address1: '',
            address2: '',
            pinCode: '',
            state: '',
            city: '',
            comment: '',
            waybillNo: '',
            pageNos: [{ id: 1, value: '10' }, { id: 2, value: '25' }, { id: 3, value: '50' }],
            courierData: [],
            showDetails: false,
            startDate: new Date().setDate(new Date().getDate() - 90),
            endDate: new Date().setDate(new Date().getDate()),
            stateList: [],
            cityList: [],
            state_id: '',
            searchUniversal: "",
            showBucketName: false,
            isSearch: false,
            current_page: 1,
            courierId: 0,
            showRPUModal: false,
            assignedStatus: false,
            errors: [],
            isWarranty: 0,
            ticketId: '',
            showFPUModal: false,
            productColorId: "",
            showFPUPrintModal: false,
            loader2: false,
            value: '',
            copied: false
        }
        this.showMenu = this.showMenu.bind(this);
        this.handleLogout = this.handleLogout.bind(this)
        this.onProductChange = this.onProductChange.bind(this);
        this.handleRefreshList = this.handleRefreshList.bind(this);
        this.handleSearchBy = this.handleSearchBy.bind(this);
        this.handleRPUSubmit = this.handleRPUSubmit.bind(this);
        this.handleCourierChange = this.handleCourierChange.bind(this);
        this.handleRPUCloseModal = this.handleRPUCloseModal.bind(this);
        this.handleCloseEditTicket = this.handleCloseEditTicket.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.handleChangeProductSelection = this.handleChangeProductSelection.bind(this);
        this.handleFPUOpenModal = this.handleFPUOpenModal.bind(this);
        this.handleFPUCloseModal = this.handleFPUCloseModal.bind(this);
        this.onFPUSubmit = this.onFPUSubmit.bind(this);
        this.handlePrintModalClose = this.handlePrintModalClose.bind(this);
        this.handleFPUPrint = this.handleFPUPrint.bind(this);
        this.onCopy = this.onCopy.bind(this);
    }

    async onRevokeClick(e, item) {
        await this.props.revokeTicket(item.id)
        this.getComplaintList();
        this.setState({
            showRPUModal: false
        })
    }

    _handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.handleChangeButton(e)
        }
    }

    handleRPUCloseModal() {
        this.setState({
            showRPUModal: false
        })
    }

    async handleCourierChange(e) {
        this.setState({
            courierId: e.target.value
        })
    }

    async handleRPUSubmit() {
        let cId = this.state.courierId
        let selectedC = this.props.courierData.filter((cd) => Number(cd.courier_id) === Number(cId))
        if (selectedC.length > 0) {
            let data = {
                "complaint_id": this.state.complaintId,
                "type": "reverse_pick_up",
                "courier_id": cId,
                "reversePrice": selectedC[0].reverse_cost
            }
            await this.props.submitRPU(data)
            this.getComplaintList();
            this.setState({
                showRPUModal: false
            })
        } else {
            alert("Please select courier id")
        }
    }

    handleSearchBy(e) {
        this.setState({
            searchUniversal: e.target.value
        })
    }

    handleLogout() {
        localStorage.removeItem('token')
        document.querySelector(".modal-backdrop").style.display = "none";
        history.push('/')
    }

    handleIconDetails() {
        this.setState({
            logout: !this.state.logout
        })
    }

    async componentDidMount() {
        // let data = {
        //   team: this.state.teamName
        // }
        // await this.props.getComplaintBuckets(data);
        // await this.props.getComplaintStatus({});
        // await this.props.getProductList({});
        // this.setState({
        //   productList: this.props.productData.data.products
        //  })
        // if (this.props.bucketData.length > 0) {
        //   await this.props.addStatusId(this.props.bucketData[0].id)
        // }
        // this.getComplaintList();
    }

    onPrevPress = () => {
        if (this.props.paginationCount.prev_page) {
            // let new_page = (this.state.new_page1 - 1);
            // this.setState({new_page1: new_page});
            this.setState({ current_page: (this.state.current_page - 1) }, async () => {
                await this.props.showComplaintDetailsAction(false);
                this.getComplaintList()
            })
        }
    }

    onNextPress = () => {
        if (this.props.paginationCount.next_page) {
            // let new_page = (this.state.new_page1 - 1);
            // this.setState({new_page1: new_page});
            this.setState({ current_page: (this.state.current_page + 1) }, async () => {
                await this.props.showComplaintDetailsAction(false);
                this.getComplaintList()
            })
        }
        // if (this.state.nextPage) {
        //   this.setState({ pageNo: (this.state.pageNo + 1) }, () => {
        //     this.getComplaintList()
        //   })
        // }
    }

    getComplaintList = async () => {
        let data = {};
        if (this.state.searchText.length > 0) {
            data = {
                "page_no": this.state.current_page,
                "search_by": this.state.searchText
            }
            this.setState({
                showBucketName: true
            })
        } else if (!this.state.assignedStatus) {
            data = {
                "page_no": this.state.current_page,
                "limit": this.state.pageLimit,
                "search_by_date": this.state.searchDate,
                // "search_by": this.state.searchText,
                "status_by": '',
                "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
                "endDate": moment(this.state.endDate).format('YYYY-MM-DD')
            }
            if (Number(this.state.productId) > 0)
                data["product_id"] = this.state.productId
            if (this.state.SLAId && this.state.SLAId !== "0")
                data["sla_breached"] = this.state.SLAId
            if (this.state.warranty && this.state.warranty !== 'All')
                data["is_warranty"] = this.state.warranty
            if (this.state.problemId && this.state.problemId !== 0)
                data["problem_by"] = this.state.problemId
            // if (this.state.searchDate)
            //   data["search_by_date"] = this.state.searchDate
            data["bucket_id"] = this.props.statusId
        } else {
            data = {
                "page_no": this.state.current_page,
                "assignedStatus": this.state.assignedStatus,
                "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
                "endDate": moment(this.state.endDate).format('YYYY-MM-DD')
            }
            if (Number(this.state.productId) > 0)
                data["product_id"] = this.state.productId
            if (this.state.SLAId && this.state.SLAId !== "0")
                data["sla_breached"] = this.state.SLAId
            if (this.state.warranty && this.state.warranty !== 'All')
                data["is_warranty"] = this.state.warranty
            if (this.state.problemId && this.state.problemId !== 0)
                data["problem_by"] = this.state.problemId
        }
        // if(Number(this.state.productId) > 0){
        //   data.bucket_id = 0 
        // }
        this.setState({ isLoading: true, complaintList: [], showBucketName: false })
        await this.props.getComplaintData(data);
        // let complaintsData = this.props.complaintsData
        let complaintDataCount = this.props.complaintsDataCount;
        // let paginationCount = this.props.paginationCount;
        this.setState({
            isLoading: false,
            totalCount: complaintDataCount.total,
            totalPage: complaintDataCount.data ? complaintDataCount.data.no_page : 0,
            prevPage: complaintDataCount.data ? complaintDataCount.data.prev_page : 0,
            nextPage: complaintDataCount.data ? complaintDataCount.data.next_page : 1,
            // complaintList: complaintsData.data.complaint_data,
        })
    }

    showMenu(event) {
        event.preventDefault();
        this.setState({ showMenu: true });
    }

    handleDate = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.getComplaintList();
        })
    }

    handleChange = (e) => {
        this.setState({ searchText: e.target.value }, () => {
            this.getComplaintList();
        })
    }

    handleChangeEvent = async (e) => {
        this.setState({ searchText: e.target.value, current_page: 1 })
        if (e.target.value.length === 0) {
            this.setState({ isSearch: false });
            await this.props.showComplaintDetailsAction(false);
            this.getComplaintList();
        }
    }

    handleClose = async (e) => {
        this.setState({ searchText: '', current_page: 1 });
        document.getElementById("search_input").value = '';
        this.setState({ isSearch: false });
        // await this.props.showComplaintDetailsAction(false);
        // this.getComplaintList();
    }

    handleChangeButton = async (e) => {
        e.preventDefault();
        // this.handleRefreshList();
        let val = document.getElementById("search_input").value;
        if (val.length > 0) {
            this.setState({ isSearch: true });
        }
        else {
            this.setState({ isSearch: false });
        }
        await this.props.showComplaintDetailsAction(false);
        this.setState({ searchText: val, current_page: 1, assignedStatus: false }, () => {
            this.getComplaintList();
        })
    }

    onStatusChange = async (e) => {
        // this.setState({ statusId: e.target.value }, () => {
        //   this.getComplaintList();
        //   await this.props.addStatusId(e.target.value)
        // })
        this.setState({ current_page: 1 });
        this.props.showComplaintDetailsAction(false)
        await this.props.addStatusId(e.target.value)
        this.getComplaintList();
    }

    onProductChange(a) {
        this.setState({ productId: a.value }, async () => {
            this.getComplaintList();
            await this.props.showComplaintDetailsAction(false)
            let pId = this.state.productId;
            // if(Number(pid) > 0){
            // }
            let selected = this.state.productList.filter((pl) => Number(pl.product_color_id) === Number(pId))
            // await this.props.getProductProblemList({"category_id" : e.target});
            let category_id = selected[0] ? selected[0].product_group_id : null
            if (category_id) {
                await this.props.getProductProblemList({ "category_id": category_id });
            }
        })
    }

    onProductDateTimeChange = (e) => {
        this.setState({ searchDate: e.target.value }, () => {
            this.getComplaintList();
        })
    }

    onSLAChange = (e) => {
        this.setState({ SLAId: e.target.value }, async () => {
            this.getComplaintList();
            await this.props.showComplaintDetailsAction(false)

        })
    }

    onProducdProblemChange = (e) => {
        this.setState({ problemId: e.target.value }, async () => {
            this.getComplaintList();
            await this.props.showComplaintDetailsAction(false)
        })
    }

    // onPageLimitChange = (e) => {
    //   this.setState({ pageLimit: e.target.value }, () => {
    //     this.getComplaintList();
    //   })
    // }

    onMenuClick = (e, teamName) => {
        this.setState({ teamName: teamName, current_page: 1 }, async () => {
            let data = {
                team: this.state.teamName
            }
            if (this.state.teamName !== "my_tickets") {
                this.setState({
                    assignedStatus: false
                })
                await this.props.getComplaintBuckets(data);
                if (this.props.bucketData.length > 0) {
                    await this.props.addStatusId(this.props.bucketData[0].id)
                }
                // await this.props.showComplaintDetailsAction(false)
                // this.getComplaintList();
            } else {
                this.setState({
                    assignedStatus: true
                })
            }
            await this.props.showComplaintDetailsAction(false)
            this.getComplaintList();
        })
    }

    handleEdit = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeProductSelection(a) {
        this.setState({ productColorId: a.value })
    }

    onEditClick = (e, item) => {
        let data = item.id;
        this.getComplaintDetailApi(data)
        this.setState({ showEditTicket: true, ticketId: item.ticket_id, isWarranty: item.is_warranty });
    }

    getComplaintDetailApi = async (param) => {
        await this.props.getComplaintDetail(param);
        this.setState({ complaintDetailsList: [] })
        let complaintDetailsList = this.props.complaintsDetail
        this.setState({
            complaintId: complaintDetailsList.id ? complaintDetailsList.id : '',
            city_id: complaintDetailsList.customer.city_id ? complaintDetailsList.customer.city_id : '',
            state_id: complaintDetailsList.customer.state_id ? complaintDetailsList.customer.state_id : '',
            productName: complaintDetailsList.product.product_name ? complaintDetailsList.product.product_name + ' (' + complaintDetailsList.product.color_name + ')' : '',
            problemName: complaintDetailsList.product.problem ? complaintDetailsList.product.problem : '',
            problemDesc: complaintDetailsList.problem_description ? complaintDetailsList.problem_description : '',
            email: complaintDetailsList.customer.email ? complaintDetailsList.customer.email : '',
            mobile: complaintDetailsList.customer.mobile ? complaintDetailsList.customer.mobile : '',
            customer_id: complaintDetailsList.customer_id ? complaintDetailsList.customer_id : 0,
            purchaseDate: moment(complaintDetailsList.purchase_date).format('YYYY-MM-DD'), //('MMMM DD, YYYY || hh:mm:ss A'),
            address1: complaintDetailsList.customer.address ? complaintDetailsList.customer.address : '',
            address2: complaintDetailsList.customer.address_2 ? complaintDetailsList.customer.address_2 : '',
            pinCode: complaintDetailsList.customer.pincode ? complaintDetailsList.customer.pincode : '',
            //state: complaintDetailsList.customer.state_name ? complaintDetailsList.customer.state_name : '',
            city: complaintDetailsList.customer.city ? complaintDetailsList.customer.city : '',
            // comment: complaintDetailsList.comments[0] ? complaintDetailsList.comments[0].notes : '',
            waybillNo: complaintDetailsList.reverse_waybill_number ? complaintDetailsList.reverse_waybill_number : '',
            productColorId: complaintDetailsList.product_color_id
        })
        this.getStateListApi();
        this.getCityListApi(this.state.state_id);
    }

    handleValidation() {
        // alert("validqation")
        // document.querySelector(".modal-backdrop").style.display="none";
        let errors = {};
        let formIsValid = true;
        if (this.state.purchaseDate === '') {
            errors["purchaseDate"] = "Please enter purchase date"
        }
        if (this.state.purchaseDate !== '') {
            let b = this.state.purchaseDate;
            let UserDate = new Date(b);
            let ToDate = new Date();
            if (UserDate.getTime() > ToDate.getTime()) {
                formIsValid = false;
                errors["purchaseDate"] = "Purchase date cannot be future date"
            }
        }
        if (this.state.email === '') {
            errors["email"] = "Email cannot be empty";
            formIsValid = false;
        }
        if (this.state.email !== '') {
            var pattern = new RegExp(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
            if (!pattern.test(this.state.email)) {
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
        }
        if (this.state.mobile === '') {
            formIsValid = false;
            errors["mobile"] = "Enter your phone number.";
        }
        if (this.state.mobile !== '') {
            let mobile = this.state.mobile
            var pattern = new RegExp(/^[6-9]\d{9}$/);
            if (!pattern.test(mobile) || (mobile.length !== 10)) {
                formIsValid = false;
                errors["mobile"] = "Enter valid phone number.";
            }
        }
        if (this.state.address1 === '') {
            errors["address1"] = "Address cannot be empty";
            formIsValid = false;
        }
        if (this.state.state_id === 0 || this.state.state_id === "0") {
            errors["state"] = "State cannot be empty";
            formIsValid = false;
        }
        if (this.state.city_id === 0 || this.state.city_id === "0" || this.state.city_id === '') {
            errors["city"] = "City cannot be empty";
            formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    onUpdateClick = (e) => {
        e.preventDefault();
        let data = {
            "complaint_id": this.state.complaintId,
            "problem_description": this.state.problemDesc,
            "purchase_date": this.state.purchaseDate,
            "address1": this.state.address1,
            "address2": this.state.address2,
            "pincode": this.state.pinCode,
            "state": this.state.state_id,
            "city": this.state.city_id,
            // "comment": this.state.comment,
            "waybill_number": this.state.waybillNo,
            "product_color_id": this.state.productColorId,
            "customer_id": this.state.customer_id,
            "email": this.state.email,
            "mobile": this.state.mobile,
        }

        if (this.handleValidation() !== false) {
            this.callEditApi(data);
        }
    }

    onSaveClick = (e, item) => {
        e.preventDefault()
        let data = {
            "complaint_id": item.id,
            //"complaint_status_id": 25 // approved
            "complaint_status_id": 4,  //complaint_registered
            "product_registration": 'approved',
            "status_enum": 'approved',
            "sla_breached": '0',
            "update_comment": 'Complaint Approved',
            "flag": 'approve_complaint',
        }
        this.callEditApi(data)
    }

    onCredClick = (e, item) => {
        e.preventDefault()
        let data = {
            "complaint_id": item.id,
            "complaint_status_id": 96, // cred
            "sla_breached": '0',
            "update_comment": 'Move to Cred',
            "flag": 'move_cred'
        }
        this.callEditApi(data)
    }

    callEditApi = async (param) => {
        await this.props.editComplaintData(param)
        let editRes = this.props.editData


        if (editRes.success) {
            this.setState({ showEditTicket: false });
            alert(editRes.message)
            this.getComplaintList()
        }
        else {

            alert(this.props.editData.message);
            this.setState({ showEditTicket: true });
        }

    }

    handleCloseEditTicket() {
        this.setState({ showEditTicket: false });
    }

    // call to edit api without any message
    calltoEditApi = async (param) => {
        await this.props.editComplaintData(param)
        // let editRes = this.props.editData
        this.getComplaintList()
    }

    onPhoneClick = async (e, item) => {
        e.preventDefault()
        await this.props.callCustomer(item.customer_id, item.id)
        let callRes = this.props.callRes

        //alert(callRes.success);
        // if (callRes.success === true) {
        //   let data = {
        //     "complaint_id": item.id,
        //     "complaint_status_id": 41,
        //     "status_enum": 'approved',
        //     "sla_breached": '0',
        //     "complaint_status": 'callback',
        //     "update_comment": 'Complaint Callback',
        //     "flag": 'complaint_callback'
        //   }
        //   this.calltoEditApi(data)
        // }
        alert(callRes.message)
        this.getComplaintList()
    }

    onRPUClick = async (e, item) => {
        e.preventDefault()
        let data = {
            // "complaint_id": item.id,
            "complaint_id": item.id,
            // "type" : "reverse_pick_up"
        }
        // TODO first call list api then click list item and call new save api
        this.setState({
            isLoading: true,
            complaintId: item.id
        })
        await this.props.getRpuCourier(data)
        this.setState({ isLoading: false, showRPUModal: true })
        // this.onRPUdata(data)
    }

    // onRPUdata = async (param) => {
    //   //this.setState({ isLoading: true, courierResData: [] })
    //   // this.setState({ isLoading: true, courierResData: [] })
    //   this.setState({
    //     isLoading: true
    //   })
    //   await this.props.getRpuCourier(param)
    //   this.setState({ isLoading: false })
    // }

    onItemPress = async (e, item) => {
        e.preventDefault();

        await this.props.showComplaintDetailsAction(true);
        this.setState({
            loader2: true
        })
        // Store selected Complaint
        await this.props.addStatusId(item.bucketId);
        await this.props.getComplaintDetail(item.id);

        this.setState({
            loader2: false
        });
        if (this.props.complaintsDetail) {
            const element = document.querySelector(".activeColor");
            element.scrollIntoView({ block: "center" });
        }

        // element.scrollTop();
    }

    onWarrantyChange = (e) => {
        this.setState({ warranty: e.target.value }, async () => {
            this.getComplaintList();
            await this.props.showComplaintDetailsAction(false)
        })
    }

    dateSelect = async (value) => {
        this.setState({
            startDate: new Date(value[0]),
            endDate: new Date(value[1])
        }, async () => {
            this.getComplaintList();
            await this.props.showComplaintDetailsAction(false)

        })
    }

    onStateChange = (e) => {
        this.setState({ country_id: 1, city_id: 0, state_id: Number(e.target.value) }, () => {
            // this.getStateListApi();
            this.getCityListApi();
        })
    }

    getStateListApi = async (param) => {
        await this.props.getStateList(param)
        this.setState({ stateList: [] })
        let stateDataList = this.props.stateDataList
        this.setState({
            stateList: stateDataList.data,
        })
    }

    onCityChange = (e) => {
        this.setState({ city_id: e.target.value }, () => {
            // this.getStateListApi();
            this.getCityListApi();
        })
    }

    getCityListApi = async (param) => {
        let data = {
            "state_id": this.state.state_id
        }
        await this.props.getCityByState(data)
        this.setState({ cityList: [] })
        let cityDataList = this.props.cityDataList
        this.setState({
            cityList: cityDataList.data,
        })
    }

    handleRefreshList() {
        this.props.showComplaintDetailsAction(false)
        this.getComplaintList()
    }

    onReset = () => {
        //  document.getElementById("bucket").value = 1;
        //  await this.props.addStatusId(1);
        //   this.getComplaintList();
        window.location.reload();
    }

    handleFPUOpenModal = async (e, item) => {
        let complaintId = item.id
        this.setState({ complaintId:complaintId });
        let data = {
            "complaint_id": item.id,
            "type": "forward_pick_up"
        }
        // TODO first call list api then click list item and call new save api
        this.setState({
            isLoading: true
        })
        await this.props.getRpuCourier(data)
        this.setState({ isLoading: false, showFPUModal: true })
    }
    handleFPUCloseModal() {
        this.setState({ showFPUModal: false });
    }
    async onFPUSubmit() {
        let cId = this.state.courierId
        let selectedC = this.props.courierData.filter((cd) => Number(cd.courier_id) === Number(cId))
        if (selectedC.length > 0) {
            let data = {
                "complaint_id": this.state.complaintId,
                "type": "forward_pick_up",
                "courier_id": cId,
                "reversePrice": selectedC[0].reverse_cost,
                "forwardPrice": selectedC[0].forward_cost,
            }
            await this.props.submitRPU(data)
            this.props.handleRefreshList();
        } else {
            alert("Please select courier")
            return
        }

    }
    handleFPUPrint = async (e, item) => {
        let complaintId = item.id
        this.setState({ complaintId:complaintId });
        let data = {
            "complaint_id": item.id,
            "type": "forward_pick_up"
        }
        await this.props.fetchPrintLabel(data)
        if (this.props.printLabel) {
            //history.push('/fpu-print')
            this.setState({ showFPUPrintModal: true })
        }
        else {
            alert("no courier data found");
        }
    }

    handlePrintModalClose() {
        this.setState({
            showFPUPrintModal: false
        }, () => {

        })
    }

    onCopy() {
        if (!e) var e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();
        alert("Copied!");
    }

    render() {
        let optionBucketDrop = [
            {
                value: 0,
                label: "Select All",
            }
        ]
        
            {
                this.state.productList.map((obj) => (
                    optionBucketDrop.push(
                        {
                            // label: obj.name,
                            value: obj.product_color_id,
                            label: obj.product_name + "(" + obj.color_name + ")",
                            // id: obj.id
                        }
                    )
                ))
            }
        
        let optionProduct = [
            {

            }
        ]
        
            {
                this.state.productList.map((obj) => (
                    optionProduct.push(
                        {
                            // label: obj.name,
                            key: obj.product_color_id,
                            value: obj.product_color_id,
                            label: obj.product_name + "(" + obj.color_name + ")",
                            selected: Number(obj.product_color_id) === Number(this.state.productColorId),
                            // id: obj.id
                        }
                    )
                ))
            }
        
        let paginationCount = this.props.paginationCount;
        let new_page = ((paginationCount.no_page - (paginationCount.no_page - this.state.current_page)) - 1);
        return (
            <div>
                <div className="sidebar">
                    <img src="/img/nlogo.png" alt="" />
                </div>
                {/* 
                <div className="modal fade" id="exampleModals">
                    <div className="modal-dialog" role="document">
                        <div className="mo">
                            <div className="header">
                                <ViewProfile />
                            </div>
                            <div className="log" onClick={this.handleLogout}>
                                <i className="fas fa-power-off"></i><p>Log Out</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nav dashboard">
                    <span>
                        <i className="fas fa-user-circle" data-toggle="modal" data-target="#exampleModals"></i> </span>
                </div> */}
                <ViewProfile />

                <div className="content complaint-main read-only">
                    <div className="search">
                        <input type="username" name="searchText" id="search_input" placeholder="Search by Ticket id, customer number..." onChange={this.handleChangeEvent} onKeyDown={this._handleKeyDown} />
                        {this.state.searchText &&
                            <button type="button" className="close-search" onClick={this.handleClose}><i className="fa fa-times" aria-hidden="true"></i></button>
                        }
                        <button type="button" className="add-user-btn-input" onClick={this.handleChangeButton}><i className="fa fa-search user_search_icon"></i></button>
                    </div>
                    {this.state.isLoading ?
                        <div className='loader'>
                            <Loader
                                type="Oval"
                                color="Grey"
                                height={200}
                                width={200}
                            />
                        </div>
                        : <div>
                            {this.state.isSearch ?
                                <div>
                                    <div className='search_title'>Search Results</div>
                                    <div>
                                        {
                                            !this.props.showComplaintDetails ?
                                                <div className="list-container">
                                                    {this.props.complaintsData.map((item, index) => {
                                                        return (
                                                            <div className="container6" key={index}>
                                                                {/* onClick={(e) => this.onItemPress(e, item)} */}
                                                                <div className="box" >
                                                                    <div className="handclass" onClick={(e) => this.onItemPress(e, item)}>
                                                                        <div className="priority_wrapper default">
                                                                            <i className={item.is_priority === "1" ? "filled fa fa-star" : "fa fa-star-o"} title="Priority"></i>
                                                                        </div>
                                                                        <h6>{item.ticket_id}
                                                                            {Number(item.is_warranty) === 1 ?
                                                                                <button className="active">Active</button>
                                                                                :
                                                                                <button className="active">Expire</button>
                                                                            }
                                                                        </h6>
                                                                        <span style={{ cursor: 'pointer' }}>{item.product_name}({item.size && "Size " + item.size + " | "} {item.color_name})</span>
                                                                        {item.upgradeProduct &&
                                                                            <span className='upg'>
                                                                                UPG - {item.upgradeProduct.product_name} + {item.upgradeProduct.color_name}
                                                                            </span>
                                                                        }
                                                                        <p style={{ cursor: 'pointer' }}>{moment(item.created_at).format('MMMM DD, YYYY || hh:mm:ss A')} || {item.channel_name}</p>
                                                                    </div>
                                                                    <div className="box1" onClick={(e) => this.onItemPress(e, item)}>
                                                                        <h6 style={{ cursor: 'pointer' }}>{item.problem}</h6>
                                                                        <span style={{ cursor: 'pointer' }} data-bs-toggle="tooltip" data-bs-placement="bottom" className="tooltip-white text-start" title={item.problem_description}>{item.problem_description}</span>
                                                                    </div>
                                                                    <div className="box2" onClick={(e) => this.onItemPress(e, item)}>
                                                                        <h6 style={{ cursor: 'pointer' }}>{this.state.isSearch || this.state.assignedStatus ? item.bucket_name : item.complaint_status_name}</h6>
                                                                        <span style={{ cursor: 'pointer' }}>{item.user_first_name} {item.user_last_name}</span>
                                                                        <p style={{ cursor: 'pointer' }}>{moment(item.updated_at).format('MMMM DD, YYYY || hh:mm:ss A')}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                    {this.props.complaintsData.length > 0 ?
                                                        null
                                                        :
                                                        <div>
                                                            <div className="no-result-text">
                                                                <p>No result found</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="footers">
                                                        <div className="pages1">

                                                            {paginationCount.total > 0 ?
                                                                <div>
                                                                    {paginationCount.no_page === 1 ?
                                                                        <p>
                                                                            1 - {paginationCount.total} of {paginationCount.total} results
                                                                        </p>
                                                                        :
                                                                        <p>
                                                                            {this.state.pageLimit * new_page > 1 ?
                                                                                this.state.pageLimit * new_page
                                                                                :
                                                                                '1'
                                                                            }
                                                                            -
                                                                            {((this.state.pageLimit * this.state.current_page) > (paginationCount.total)) && ((paginationCount.total) > (this.state.pageLimit * new_page)) ?
                                                                                (paginationCount.total)
                                                                                :
                                                                                (this.state.pageLimit * this.state.current_page)} of {paginationCount.total} results
                                                                            <i className="fas fa-chevron-left" onClick={this.onPrevPress} />
                                                                            <span>{this.state.current_page}</span>
                                                                            <i className="fa fa-chevron-right" onClick={this.onNextPress} />
                                                                        </p>
                                                                    }
                                                                </div>
                                                                : ''
                                                            }
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div className='loader'>
                                                    {this.state.loader2 ?
                                                        <Loader
                                                            type="Oval"
                                                            color="Grey"
                                                            height={200}
                                                            width={200}
                                                        />
                                                        :
                                                        <ReadOnlyTicketDetails handleRefreshList={this.handleRefreshList} />
                                                    }
                                                </div>
                                        }

                                    </div>
                                </div>
                                :
                                null
                            }

                        </div>
                    }

                    <ReactModal
                        isOpen={this.state.showFPUModal}
                        contentLabel="Minimal Modal Example"
                        style={customStylesDO}
                        ariaHideApp={false}
                    >
                        <h2 id="rModal_head">Forward Pickup</h2>
                        <button id="close_rModal" onClick={this.handleFPUCloseModal}><i className="fas fa-times"></i></button>
                        <select id="select_rModal" className="form-control" onChange={this.handleCourierChange}>
                            <option value={"0"} selected="selected">Select Courier</option>
                            {this.props.courierData.map((obj) => (
                                <option value={obj.courier_id}>{obj.courier_name} - {obj.pincode} - Rs.{obj.reverse_cost}</option>
                            ))}
                        </select>
                        <button id="send_rModal" type="button" onClick={this.onFPUSubmit}>Send</button>
                    </ReactModal>

                    {/* FPU print modal */}

                    <ReactModal
                        isOpen={this.state.showFPUPrintModal}
                        contentLabel="Minimal Modal Example"
                        style={customStylesRPU}
                        ariaHideApp={false}
                        className="rpu_print"
                    >
                        <button type='button' onClick={this.handlePrintModalClose} id="close_rModal"><i className="fas fa-times"></i></button>

                        {/* <ComponentToFPUPrint ref={el => (this.componentRef = el)} /> */}

                    </ReactModal>

                    <ReactModal
                        isOpen={this.state.showEditTicket}
                        contentLabel="Minimal Modal Example"
                        style={styleEdit}
                        ariaHideApp={false}
                        className="modal-header"
                    >
                        <div className="Et">
                            <span>Edit Ticket</span>
                            <span className="s">{this.state.ticketId}</span>
                            {Number(this.state.isWarranty) === 1 ?
                                <button className="active">Active</button>
                                :
                                <button className="active">Expire</button>
                            }
                            <button id="close_rModal" onClick={this.handleCloseEditTicket}><i className="fas fa-times"></i></button>
                            <div className="lbl">
                                <p>Original Product</p>

                                <Select
                                    onChange={this.handleChangeProductSelection}
                                    options={optionProduct}
                                    isSearchable={true}
                                    className="select-product-css dropbtn1111"
                                    maxMenuHeight={200}
                                    placeholder={this.state.productName}
                                // closeMenuOnSelect={true}  
                                />
                                {/* <i className="fas fa-chevron-down"></i> */}
                            </div>
                            <div className="lbl2">
                                <p>Product Problem</p>
                                <input type="text" disabled placeholder="Enter Product Problem" name="problemName" value={this.state.problemName} onChange={this.handleEdit} />
                                {/* <i className="fas fa-chevron-down"></i> */}
                            </div>
                            <div className="lbl3">
                                <p>Problem Discription</p>
                                <input type="text" placeholder="Enter Problem Discription" name="problemDesc" value={this.state.problemDesc} onChange={this.handleEdit} />
                            </div>
                            <div className="lbl6">
                                <p>Purchase Date</p>
                                <input type="date" placeholder="Enter Purchase Date" name="purchaseDate" value={this.state.purchaseDate} onChange={this.handleEdit} max="9999-12-31" />
                                <div className="error_add">
                                    <span className="errorShow">{this.state.errors["purchaseDate"]}</span>
                                </div>
                            </div>
                            <div className="lbl4">
                                <p>Email</p>
                                <input type="text" placeholder="Enter Email" name="email" value={this.state.email} onChange={this.handleEdit} />
                                <div className="error_add">
                                    <span className="errorShow">{this.state.errors["email"]}</span>
                                </div>
                            </div>
                            <div className="lbl5">
                                <p>Mobile number</p>
                                <input type="text" placeholder="Enter Mobile number" name="mobile" value={this.state.mobile} onChange={this.handleEdit} maxLength={10} />
                                <div className="error_add">
                                    <span className="errorShow">{this.state.errors["mobile"]}</span>
                                </div>
                            </div>
                            <div className="lbl7">
                                <p>Address 1</p>
                                <input type="text" placeholder="Enter Address1" name="address1" value={this.state.address1} onChange={this.handleEdit} required />
                                <div className="error_add">
                                    <span className="errorShow">{this.state.errors["address1"]}</span>
                                </div>
                                <p className="second-child">Address 2</p>
                                <input type="text" placeholder="Enter Address2" name="address2" value={this.state.address2} onChange={this.handleEdit} />
                            </div>
                            <div className="lbl8">
                                <p>Zip Code</p>
                                <input type="text" placeholder="Enter Pincode" name="pinCode" value={this.state.pinCode} onChange={this.handleEdit} />
                            </div>
                            <div className="lbl9">
                                <p>State</p>
                                {/* <input type="text" placeholder="Enter State" name="state" value={this.state.state} onChange={this.handleEdit} /> */}
                                {/* <i className="fas fa-chevron-down"></i> */}
                                <select className="dropbtn1111" onChange={(e) => this.onStateChange(e)} name="state">
                                    {/* <i className="fa fa-angle-down" /> */}
                                    <option value={'0'}> select state</option>
                                    {this.state.stateList.map((obj) => (
                                        <option value={obj.id} selected={Number(obj.id) === Number(this.state.state_id)}>{obj.state_name}</option>
                                    ))}
                                </select>
                                <div className="error_add">
                                    <span className="errorShow">{this.state.errors["state"]}</span>
                                </div>
                            </div>
                            <div className="z">
                                <p>City</p>
                                {/* <input type="text" placeholder="Enter City" name="city" value={this.state.city} onChange={this.handleEdit} /> */}
                                {/* <i className="fas fa-chevron-down"></i> */}
                                <select className="dropbtn1111" id="select_city" onChange={(e) => this.onCityChange(e)}>
                                    {/* <i className="fa fa-angle-down" /> */}
                                    <option value={'0'}> select city</option>
                                    {this.state.cityList.map((obj) => (
                                        <option value={obj.id} selected={Number(obj.id) === Number(this.state.city_id)}>{obj.city}</option>
                                    ))}
                                </select>
                                <div className="error_add">
                                    <span className="errorShow">{this.state.errors["city"]}</span>
                                </div>
                            </div>

                            <div className="lbl12">
                                <p>Waybill No.</p>
                                <input type="text" placeholder="Enter Waybill No." name="waybillNo" value={this.state.waybillNo} onChange={this.handleEdit} />
                            </div>
                            <div className="lbl13 update_ticket">
                                <button onClick={(e) => this.onUpdateClick(e)} >Update Ticket</button>
                            </div>
                        </div>
                    </ReactModal>
                    <ReactModal
                        isOpen={this.state.showRPUModal}
                        contentLabel="Minimal Modal Example"
                        style={customStyles}
                        ariaHideApp={false}
                    >
                        <h2 id="rModal_head">RPU</h2>
                        <button id="close_rModal" onClick={this.handleRPUCloseModal}><i className="fas fa-times"></i></button>
                        <select id="select_rModal" className="form-control" onChange={this.handleCourierChange}>
                            <option value={"0"} selected="selected">Select Courier</option>
                            {this.props.courierData.map((obj) => (
                                <option value={obj.courier_id}>{obj.courier_name} - {obj.pincode} - Rs.{obj.reverse_cost}</option>
                            ))}
                        </select>
                        <button id="send_rModal" type="button" onClick={this.handleRPUSubmit}>Send</button>
                    </ReactModal>

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    complaintsData: state.complaints.complaintsData,
    bucketData: state.complaints.bucketData,
    statusData: state.complaints.statusData,
    productData: state.complaints.productData,
    problemData: state.complaints.problemData,
    editData: state.complaints.editData,
    callRes: state.complaints.callRes,
    complaintsDetail: state.complaints.complaintsDetail,
    courierData: state.complaints.courierData,
    statusId: state.complaints.statusId,
    showComplaintDetails: state.complaints.showComplaintDetails,
    stateDataList: state.complaints.stateData,
    cityDataList: state.complaints.cityData,
    complaintsDataCount: state.complaints.complaintsDataCount,
    refreshComplaintList: state.complaints.refreshComplaintList,
    paginationCount: state.complaints.paginationCount,
    printLabel: state.complaints.printLabel,
})

export default connect(mapStateToProps, { getComplaintData, getComplaintBuckets, getComplaintStatus, getProductList, getProductProblemList, getRpuCourier, editComplaintData, getComplaintDetail, callCustomer, addStatusId, showComplaintDetailsAction, getStateList, getCityByState, submitRPU, revokeTicket, fetchPrintLabel })(ReadOnly);