

import { ADD_WARRANTY_SELECTION, GET_WARRANTY_DATA, REMOVE_WARRANTY_SELECTION, FETCH_WARRANTY_DATA, CLEAR_ALL_WARRANTY_DATA,GET_NOTIFY_DATA,FETCH_PAGINATION_INFO,GET_WARRANTY_DATA_INTERNATIONAL,FETCH_PAGINATION_INFO_INTERNATIONAL } from '../actions/types';

const initialState = {
  warrantySelection: "",
  warrantyData: [],
  warrantyDataInter:[],
  downloadData: [],
  notifyData:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_WARRANTY_DATA:
      let newArray = [...state.warrantyData]
      newArray[action.offset] = action.payload

      return {
        ...state,
        warrantyData: newArray,
        downloadData: action.payloadDownload
      };
    case ADD_WARRANTY_SELECTION:
      return {
        ...state,
        warrantySelection: action.payload
      };
    case REMOVE_WARRANTY_SELECTION:
      return {
        ...state,
        warrantySelection: ""
      };

    case CLEAR_ALL_WARRANTY_DATA:
      return {
        ...state,
        warrantyData: [],
        downloadData: []
      };
    case GET_WARRANTY_DATA:
      return {
        ...state,
        warrantyData: action.payload
      };
      case GET_WARRANTY_DATA_INTERNATIONAL:
      return {
        ...state,
        warrantyDataInter: action.payload
      };
      case GET_NOTIFY_DATA:
      return {
        ...state,
        notifyData: action.payload
      };
      case FETCH_PAGINATION_INFO:
      return {
        ...state,
        paginationCount: action.payload,
      };

    default:
      return state;
  }
}
