import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import "./ServiceCenter.css";
import ReactModal from 'react-modal'
import { getEmployeeDetails, removeSubServiceCenter, getEmployeeData, } from '../../actions/serviceCenterActions';
import { useSelector, useDispatch } from 'react-redux';
// import EditEmployeeComponent from '../AddServiceCenterComponent/EditEmployeeComponent'
import Loader from 'react-loader-spinner'
function ManageUsers({ setActive }) {

  // const employeeData = useSelector((state) => state.serviceCenter.employeeData);
  // let userData = employeeData && employeeData.userData
  // const userDataFiltered = useSelector((state) => state.serviceCenter.employeeDetails);

  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)
  const [employeeUserId, setEmployeeUserId] = useState("")
  const [selectedEmployee, setSelectedEmployee] = useState([])
  const [userAddress, setUserAddress] = useState({})
  const [reporter, setReporter] = useState({})
  const initialValues = {
    totalPage: 1,
    totalCount: 0,
    prevPage: false,
    nextPage: false,
    current_page: 1,
    pageLimit: 12,
    searchTextLength: 0,
    searchText: '',
    serviceCenterID: '',
    firstPage: 1
  };
  const [values, setValues] = useState(initialValues);
  const [isLoading, setisLoading] = useState(false);
  const handleModal = (e, id) => {
    if (e !== undefined) {
      let tagname = e.target.tagName;
      if (tagname !== 'IMG' && tagname !== 'BUTTON') {
        setEmployeeUserId(id)
        dispatch(getEmployeeData(id))
      } else {
        setEmployeeUserId("")
        setSelectedEmployee([])
        setUserAddress({})
        setReporter({})
      }
      filterEmployeeData(id)
    }
    setIsModalOpen(!isModalOpen)
  }

  const filterEmployeeData = (target_id) => {
    let employee = userData && userData.filter(emp => emp.id === target_id);
    setSelectedEmployee(employee[0]);
    if (employee[0] !== undefined) {
      setUserAddress(employee[0].serviceCenter);
      setReporter(employee[0].reporter);
    }
  }

  const handlePopUp = () => {
    setIsPopUpOpen(!isPopUpOpen)
  }

  const handleNewServiceCenterBtn = () => {
    history.push('/add-service-center')
  }

  const employeeData = useSelector((state) => state.serviceCenter.employeeData);
  const userData = employeeData && employeeData.userData;
  // const paginationCount = useSelector((state) => state.serviceCenter.paginationCount)

  const getComplaintList = async () => {
    let data = {
      "page_no": values.current_page,
      "limit": values.pageLimit
    };
    setisLoading(true)
    await dispatch(getEmployeeDetails(data));
    setisLoading(false)
    setValues({
      ...values,
      totalCount: employeeData && employeeData.total,
      totalPage: employeeData ? employeeData.no_page : 0,
      prevPage: employeeData ? employeeData.prev_page : 0,
      nextPage: employeeData ? employeeData.next_page : 1,
    })
  }

  // useEffect(() => {
  //   let data = {  
  //   "page_no": 1,
  //   "limit": 10
  //   }
  //   dispatch(getEmployeeDetails(data));
  // },[])
  // const serviceCenters = employeeData && employeeData.userData && employeeData.userData.serviceCenter;

  const styleEdit = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      inset: '0 0 0 auto',
      marginRight: '25%',
      transform: 'translate(0)',
      background: '#fff',
      padding: '40px',
      border: 'none',
      borderRadius: '6px',
      height: '435px',
      position: 'absolute',
      top: '20%',
      width: '680px',
    },
  };

  const styleEdit2 = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      inset: '0 0 0 auto',
      marginRight: '37%',
      transform: 'translate(0)',
      background: '#fff',
      fontSize: '14px',
      padding: '15px',
      border: 'none',
      borderRadius: '6px',
      height: '100px',
      position: 'absolute',
      top: '50%',
      width: '420px',
      zIndex: '99999',
    },
  };

  // useEffect(() => {
  //   let data = {
  //     "page_no": values.current_page,
  //     "limit": values.pageLimit
  //   }
  //   dispatch(getEmployeeDetails(data));
  // }, [])

  useEffect(() => {
    getComplaintList()
  }, [values.current_page])

  const onPrevPress = () => {
    if (employeeData.prev_page) {
      setValues({ ...values, current_page: (values.current_page - 1) }, async () => {
        getComplaintList()
      })
    }
  }

  const onNextPress = () => {
    if (employeeData.next_page) {
      setValues({ ...values, current_page: (values.current_page + 1) }, async () => {
        getComplaintList()
      })
    }
  }

  const handleDeactivation = () => {
    handlePopUp()
    handleModal()
    dispatch(removeSubServiceCenter(employeeUserId))
    setTimeout(() => {
      getComplaintList()
    }, 500)
  }

  const handleEditBtn = () => {
    return history.push('/edit-service-center')
  }

  let new_page = ((employeeData && employeeData.no_page - (employeeData && employeeData.no_page - values.current_page)) - 1);

  return (
    <div className='users mainContent'>
      <h1 className='heading'>Manage Users</h1>
      {isLoading ?
        <div className='loader'>
          <Loader
            type="Oval"
            color="Grey"
            height={200}
            width={200}
            className='loaderNew'
          />
        </div>
        :
        <div>
          <div className="user_main_container">
            <div className='userWrapper'>
              <button onClick={handleNewServiceCenterBtn} className='' id='newEmpBtn'><p id='empBtnIcon'>+</p><span id='empBtnText'>New Service Center</span></button>
            </div>
            {

              userData && userData.map((item, index) => {
                return (
                  <div className='userWrapper' key={item.id} onClick={(e) => handleModal(e, item.id)}>
                    <div className="headerUser">
                      <span id="name1">{item.first_name}{" "}{item.last_name}</span>
                      <p id="userRole">{item.role}</p>
                      <p id="borderUser"></p>
                    </div>
                    <div className
                      ="userInfoContainer">
                      <div className='userProfileInfo'>
                        <span id='mails'>E-Mail:</span>
                        <span id='maildId'>{item.email.length > 20 ? item.email.substring(0, 20) + "..." : item.email}</span>
                      </div>
                      <div className='mobileContainer'>
                        <span id='mails'>Mobile:</span>
                        <span id='maildId'>{item.mobile}</span>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
          <div className="footers">
            <div className="pages1">
              {employeeData && employeeData.total > 0 ?
                <div>
                  {employeeData && employeeData.no_page === 1 ?
                    <p style={{ color: 'black', fontWeight: "500" }}>
                      1 - {employeeData && employeeData.total} of {employeeData && employeeData.total} results
                    </p>
                    :
                    <p>
                      {values.pageLimit * new_page > 1 ?
                        values.pageLimit * new_page
                        :
                        '1'
                      }
                      -
                      {((values.pageLimit * values.current_page) > (employeeData && employeeData.total)) && ((employeeData && employeeData.total) > (values.pageLimit * new_page)) ?
                        (employeeData && employeeData.total)
                        :
                        (values.pageLimit * values.current_page)} of {employeeData && employeeData.total} results
                      <i className="fa fa-chevron-left" style={{ color: "black" }} onClick={onPrevPress} />
                      <span>{values.current_page}</span>
                      <i className="fa fa-chevron-right" style={{ color: "black" }} onClick={onNextPress} />
                    </p>
                  }
                </div>
                : <div className="Noreult">
                  <span>No User found</span>
                </div>
              }
            </div>
          </div>
        </div>}
      {selectedEmployee &&
        <ReactModal
          isOpen={isModalOpen}
          ariaHideApp={false}
          style={styleEdit}
          className='editEmployeeModal'
          contentLabel='Employee Details'
        >
          <div className='flexRow'>
            <h1 id='employeeName'>{selectedEmployee.first_name} {selectedEmployee.last_name}</h1>
            <button id='handleModalBtn' onClick={handleModal}><img src='/img/closeIcon.svg' alt='close-icon' /></button>
          </div>
          <div className='horizontal'></div>
          <div className='gridBox'>
            <div className='column1'>
              <div>
                <h2 className='modalHeading'>Contact Number</h2>
                <span className='modalContent'>+91 {selectedEmployee.mobile}</span>
              </div>
              <div style={{ marginRight: '150px' }}>
                <h2 className='modalHeading'>Address</h2>
                <p className='modalContent'>{userAddress.name}</p>
                <p className='modalContent'>{userAddress.address}</p>
                <p className='modalContent'>{userAddress.city}</p>
                <p className='modalContent'>{userAddress.state_name} - {userAddress.pincode}</p>
              </div>
            </div>
            <div className='column2'>
              <h2 className='modalHeading' style={{ marginTop: '-50px' }}>Email ID</h2>
              <span className='modalContent'>{selectedEmployee.email}</span>
            </div>
          </div>
          <div className='horizontal'></div>
          <h2 className='modalHeading'>Reporting Manager</h2>
          <span className='modalContent'>{reporter.name}</span>
          <div className='horizontal'></div>
          <div style={{ display: 'flex' }}>
            <button style={{ marginRight: '12px' }} onClick={handleEditBtn} className='editBtn'><img src='/img/editIcon.svg' alt='edit' />Edit</button>
            <button className='editBtn' onClick={handlePopUp}><img src='/img/deleteIcon.svg' alt='delete' />Deactivate</button>
          </div>
        </ReactModal>}
      {selectedEmployee &&
        <ReactModal
          isOpen={isPopUpOpen}
          ariaHideApp={false}
          style={styleEdit2}
          className='deactivateEmployeeModal'
          contentLabel='Deactivate Service Center'
        >
          <p>Are you sure you want to deactivate this service center?</p>
          <div style={{ display: 'flex' }}>
            <button className='editBtn' style={{ marginRight: '20px' }} onClick={handleDeactivation}>Yes</button>
            <button className='editBtn' onClick={handlePopUp}>No</button>
          </div>
        </ReactModal>}
    </div>
  )
}

export default ManageUsers;