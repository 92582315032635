import React from 'react';
import ReactModal from 'react-modal';

const ConfirmationDialog = ({ isOpen, onClose, onConfirm, formData }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal-dialog modal-dialog-centered"
      overlayClassName="modal-backdrop"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Confirm Complaint Details</h5>
          <button type="button" className="close" onClick={onClose}>
            <span>&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="confirmation-details">
            
          <div className="detail-row"><strong>Name:</strong> {formData.name}</div>
          <div className="detail-row"><strong>Email:</strong> {formData.email}</div>
          <div className="detail-row"><strong>Phone Number:</strong> {formData.mobile}</div>
          <div className="detail-row"><strong>Product Group:</strong> {formData.productGroup}</div>
          <div className="detail-row"><strong>Channel:</strong> {formData.channelName}</div>
          <div className="detail-row"><strong>Product:</strong> {formData.productName}</div>
          <div className="detail-row"><strong>Color:</strong> {formData.colorName}</div>
          {formData.serialNumber && (
  <div className="detail-row">
    <strong>Serial Number:</strong> {formData.serialNumber}
  </div>
)}
          <div className="detail-row"><strong>Problem:</strong> {formData.problemName}</div>
          <div className="detail-row"><strong>Sub Problem:</strong> {formData.subProblemName}</div>
          <div className="detail-row"><strong>Purchase Date:</strong> {formData.purchaseDate}</div>
          <div className="detail-row"><strong>Description:</strong> {formData.description}</div>
          <div className="detail-row"><strong>Address:</strong> {formData.address}, {formData.address2}, {formData.cityName}, {formData.stateName} - {formData.pincode}</div>
          {/* <div className="detail-row"><strong>Product Image:</strong> {formData.productImage ? "Uploaded" : "Not uploaded"}</div> */}
          {/* <div className="detail-row"><strong>Invoice Image:</strong> {formData.invoiceImage ? "Uploaded" : "Not uploaded"}</div> */}
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={onClose}>Edit</button>
          <button type="button" className="btn btn-primary" onClick={onConfirm}>Confirm & Submit</button>
        </div>
      </div>
    </ReactModal>
  );
};

export default ConfirmationDialog;