import React, { Component } from 'react';
import './DashboardHeaderComponent.css';
import history from '../../history';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

class DahsboardHeaderComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      logout: false,
      name: localStorage.getItem('name'),
      isShowHeader: false,
      isCatHeader: false,
      isOTTHeader: false,
      isSCMHeader: false,
      isCatSubHeader: false,
      isOTTSubHeader: false,
      isSCMSubHeader: false
    }
    this.handleIconDetails = this.handleIconDetails.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    if (e === 'complaint') {
    }
  }

  handleLogout() {
    localStorage.removeItem('token')
    document.querySelector(".modal-backdrop").style.display = "none";
    history.push('/')
  }

  handleIconDetails() {
    this.setState({
      logout: !this.state.logout
    })
  }

  onCategoryPress = () => {
    this.setState({ isCatSubHeader: true, isOTTSubHeader: false, isSCMSubHeader: false })
    this.redireactTo('/category-form', 'catHeader')
  }

  onOttPress = () => {
    this.setState({ isOTTSubHeader: true, isCatSubHeader: false, isSCMSubHeader: false })
    this.redireactTo('/category-data', 'ottHeader')
  }

  onSCMPress = () => {
    this.setState({ isSCMSubHeader: true, isCatSubHeader: false, isOTTSubHeader: false })
    this.redireactTo('/lot-data', 'scmHeader')
  }

  componentDidMount() {
    let userRole = localStorage.getItem('userRole')
    this.setState({
      isShowHeader: (userRole === 'inword' || userRole === 'CATEGORY_TEAM' || userRole === 'ORDER_TRACKING_TEAM' || userRole === 'SCM_TEAM') ? false : true,
      isCatHeader: userRole === 'CATEGORY_TEAM' ? true : false,
      isOTTHeader: userRole === 'ORDER_TRACKING_TEAM' ? true : false,
      isSCMHeader: userRole === 'SCM_TEAM' ? true : false,
    }, () => {
      if (this.state.isShowHeader) {
        // if (window.location.href.indexOf("complaints-data") > -1) {
        //   document.getElementById('complaint-text-id').style.color = "#4ac0b0"
        //   document.getElementById("complaint-image").src = "/img/complaint-active.png";
        //   document.getElementById("complaint-image").srcset = "/img/complaint-active@2x.png 2x, /img/complaint-active@3x.png 3x";
        //   let node = document.createElement("div");
        //   node.classList.add('active-line')
        //   document.getElementById("complaint-col").appendChild(node);
        // }
        if (window.location.href.indexOf("dashboard") > -1) {
          document.getElementById('dashboard-text-id').style.color = "#4ac0b0"
          document.getElementById("dashboard-image").src = "/img/dashboard-icon.png";
          document.getElementById("dashboard-image").srcset = "/img/dashboard-icon@2x.png 2x, /img/dashboard-icon@3x.png 3x";
          let node1 = document.createElement("div");
          node1.classList.add('active-line')
          document.getElementById("dashboard-col").appendChild(node1);
        }
        // if (window.location.href.indexOf("crm-data") > -1) {
        //   document.getElementById('prospective-text-id').style.color = "#4ac0b0"
        //   document.getElementById("prospective-image").src = "/img/prospective-active.png";
        //   document.getElementById("prospective-image").srcset = "/img/prospective-active@2x.png 2x, /img/prospective-active@3x.png 3x";
        //   let node2 = document.createElement("div");
        //   node2.classList.add('active-line')
        //   document.getElementById("prospective-col").appendChild(node2);
        // }
        // if (window.location.href.indexOf("order-data") > -1) {
        //   document.getElementById('order-text-id').style.color = "#4ac0b0"
        //   document.getElementById("order-image").src = "/img/order-active.png";
        //   document.getElementById("order-image").srcset = "/img/order-active@2x.png 2x, /img/order-active@3x.png 3x";
        //   let node2 = document.createElement("div");
        //   node2.classList.add('active-line')
        //   document.getElementById("order-col").appendChild(node2);
        // }
        if (window.location.href.indexOf("warranty-data") > -1) {
          document.getElementById('warranty-text-id').style.color = "#4ac0b0"
          document.getElementById("warranty-image").src = "/img/warranty-active.png";
          document.getElementById("warranty-image").srcset = "/img/warranty-active@2x.png 2x, /img/warranty-active@3x.png 3x";
          var node2 = document.createElement("div");
          node2.classList.add('active-line')
          document.getElementById("warranty-col").appendChild(node2);
        }
        // if (window.location.href.indexOf("inventory-data") > -1) {
        //   document.getElementById('inventory-text-id').style.color = "#4ac0b0"
        //   document.getElementById("inventory-image").src = "/img/inventory-active.png";
        //   document.getElementById("inventory-image").srcset = "/img/inventory-active@2x.png 2x, /img/inventory-active@3x.png 3x";
        //   var node2 = document.createElement("div");
        //   node2.classList.add('active-line')
        //   document.getElementById("inventory-col").appendChild(node2);
        // }
      }
      if (window.location.href.indexOf("category-form") > -1) {
        // if (this.state.isCatSubHeader) {
        document.getElementById('category1-text-id').style.color = "#4ac0b0"
        document.getElementById("category1-image").src = "/img/inventory-active.png";
        document.getElementById("category1-image").srcset = "/img/inventory-active@2x.png 2x, /img/inventory-active@3x.png 3x";
        let node2 = document.createElement("div");
        node2.classList.add('active-line')
        document.getElementById("category1-col").appendChild(node2);
      }
      if (window.location.href.indexOf("category-data") > -1) {
        document.getElementById('category-text-id').style.color = "#4ac0b0"
        document.getElementById("category-image").src = "/img/inventory-active.png";
        document.getElementById("category-image").srcset = "/img/inventory-active@2x.png 2x, /img/inventory-active@3x.png 3x";
        // var node2 = document.createElement("div");
        // node2.classList.add('active-line')
        // document.getElementById("category-col").appendChild(node2);
      }
      if (window.location.href.indexOf("lot-data") > -1) {
        document.getElementById('ott-text-id').style.color = "#4ac0b0"
        document.getElementById("ott-image").src = "/img/inventory-active.png";
        document.getElementById("ott-image").srcset = "/img/inventory-active@2x.png 2x, /img/inventory-active@3x.png 3x";
        let node2 = document.createElement("div");
        node2.classList.add('active-line')
        document.getElementById("ott-col").appendChild(node2);
      }
      if (window.location.href.indexOf("scm-data") > -1) {
        document.getElementById('scm-text-id').style.color = "#4ac0b0"
        document.getElementById("scm-image").src = "/img/inventory-active.png";
        document.getElementById("scm-image").srcset = "/img/inventory-active@2x.png 2x, /img/inventory-active@3x.png 3x";
        let node2 = document.createElement("div");
        node2.classList.add('active-line')
        document.getElementById("scm-col").appendChild(node2);
      }
      if (window.location.href.indexOf("rejected-order") > -1) {
        document.getElementById('reject-text-id').style.color = "#4ac0b0"
        document.getElementById("reject-image").src = "/img/inventory-active.png";
        document.getElementById("reject-image").srcset = "/img/inventory-active@2x.png 2x, /img/inventory-active@3x.png 3x";
        let node2 = document.createElement("div");
        node2.classList.add('active-line')
        document.getElementById("reject-col").appendChild(node2);
      }
    })


    if (this.props.headerName && this.props.headerName === 'catHeader' && (window.location.href.indexOf("category-form") > -1 || window.location.href.indexOf("category-data") > -1 || window.location.href.indexOf("rejected-order") > -1)) {
      this.setState({ isCatSubHeader: true })
    }
    if (this.props.headerName && this.props.headerName === 'ottHeader' && (window.location.href.indexOf("category-data") > -1 || window.location.href.indexOf("lot-data") > -1 || window.location.href.indexOf("rejected-order") > -1)) {
      this.setState({ isOTTSubHeader: true })
    }
    if (this.props.headerName && this.props.headerName === 'scmHeader' && (window.location.href.indexOf("scm-data") > -1 || window.location.href.indexOf("lot-data") > -1)) {
      this.setState({ isSCMSubHeader: true })
    }
  }

  redireactTo = (path, param) => {
    // history.push({ pathname: path, state: param });
    history.push({
      pathname: path,
      state: param
    });
  }

  render() {
    return (
      <div className='header'>
        {!(this.state.isCatHeader || this.state.isSCMHeader || this.state.isOTTHeader) ?
          <div className='row'>
            <div className='col-md-2 logo'>
              <span>
                <img src="/img/group-19.png"
                  srcSet="/img/group-19@2x.png 2x,
                      /img/group-19@3x.png 3x"
                  className="Group-19" alt='' />
              </span>
              <span className='CRM'>CRM</span>
            </div>
            {this.state.isShowHeader ?
              <div className='col-md-2 col-width2' id="dashboard-col">
                <Link to="/dashboard">
                  <span><img id='dashboard-image' src="/img/dashboard.png"
                    srcSet="/img/dashboard@2x.png 2x, /img/dashboard@3x.png 3x"
                    className="dashboard-layer" alt='' /></span>
                  <span className='dashboard-text' id='dashboard-text-id'>Dashboard</span>
                </Link>
              </div>
              :
              <div className='col-1 col-width' />
            }
            {/* {this.state.isShowHeader ?
              <div className='col-md-1 col-width' id="complaint-col">
                <Link to="/complaints-data">
                  <span><img id='complaint-image' src="/img/complaint-icon.png"
                    srcSet="/img/complaint-icon@2x.png 2x, /img/complaint-icon@3x.png 3x"
                    className="dashboard-layer" alt='' /></span>
                  <span className='complaint-text' id='complaint-text-id'>Complaints</span>
                </Link>
              </div> :
              <div className='col-1 col-width' />
            } */}
            {/* {this.state.isShowHeader ?
              <div className='col-md-1 col-width' id="prospective-col">
                <Link to="/crm-data">
                  <span><img id='prospective-image' src="/img/prospective-icon.png"
                    srcSet="/img/prospective-icon@2x.png 2x, /img/prospective-icon@3x.png 3x"
                    className="dashboard-layer" alt='' /></span>
                  <span className='prospective-text' id='prospective-text-id'>Prospective</span>
                </Link>
              </div> :
              <div className='col-1 col-width' />
            } */}
            {/* {this.state.isShowHeader ?
              <div className='col-md-1 col-width' id="order-col">
                <Link to="/order-data">
                  <span><img id='order-image' src="/img/order.png"
                    srcSet="/img/order@2x.png 2x, /img/order@3x.png 3x"
                    className="dashboard-layer" alt='' /></span>
                  <span className='prospective-text' id='order-text-id'>Order</span>
                </Link>
              </div> :
              <div className='col-1 col-width' />
            } */}
             {this.state.isShowHeader ?
              <div className='col-md-1 col-width' id="warranty-col">
                <Link to="/warranty-data">
                  <span><img id='warranty-image' src="/img/warranty.png"
                    srcSet="/img/warranty@2x.png 2x, /img/warranty@3x.png 3x"
                    className="dashboard-layer" alt='' /></span>
                  <span className='prospective-text' id='warranty-text-id'>Warranty</span>
                </Link>
              </div> :
              <div className='col-1 col-width' />
            }
            {/* {this.state.isShowHeader ?
              <div className='col-md-1 col-width' id="inventory-col">
                <Link to="/inventory-data">
                  <span><img id='inventory-image' src="/img/inventory.png"
                    srcSet="/img/inventory@2x.png 2x, /img/inventory@3x.png 3x"
                    className="dashboard-layer" alt='' /></span>
                  <span className='prospective-text' id='inventory-text-id'>Inventory</span>
                </Link>
              </div> :
              <div className='col-1 col-width' />
            } */}

            {this.state.isShowHeader ?
              <div className='col-md-2 col-width2' id="sub-category-col" >
                <a href="" onClick={this.onCategoryPress}>
                  <span><img id='sub-category-image' src={this.state.isCatSubHeader ? "/img/inventory-active.png" : "/img/inventory.png"}
                    srcSet={this.state.isCatSubHeader ? "/img/inventory-active@2x.png 2x, /img/inventory-active@3x.png 3x" : "/img/inventory@2x.png 2x, /img/inventory@3x.png 3x"}
                    className="dashboard-layer" alt='' /></span>
                  <span className='prospective-text' id='sub-category-text-id' style={{ color: this.state.isCatSubHeader ? "#4ac0b0" : '' }}>Category Team</span>
                </a>

                {this.state.isCatSubHeader ? <div className='active-line'></div> : null}
              </div>
              : null
            }

            {this.state.isShowHeader ?
              <div className='col-md-2 col-width2' id="sub-ott-col">
                <a onClick={this.onOttPress}>
                  <span><img id='sub-ott-image' src={this.state.isOTTSubHeader ? "/img/inventory-active.png" : "/img/inventory.png"}
                    srcSet={this.state.isOTTSubHeader ? "/img/inventory-active@2x.png 2x, /img/inventory-active@3x.png 3x" : "/img/inventory@2x.png 2x, /img/inventory@3x.png 3x"}
                    className="dashboard-layer" alt='' /></span>
                  <span className='prospective-text' id='sub-ott-text-id' style={{ color: this.state.isOTTSubHeader ? "#4ac0b0" : '' }}>Order Tracking Team</span>
                </a>
                {this.state.isOTTSubHeader ? <div className='active-line'></div> : null}
              </div>
              : null
            }

            {this.state.isShowHeader ?
              <div className='col-md-1 col-width1' id="sub-scm-col">
                <a onClick={this.onSCMPress}>
                  <span><img id='sub-scm-image' src={this.state.isSCMSubHeader ? "/img/inventory-active.png" : "/img/inventory.png"}
                    srcSet={this.state.isSCMSubHeader ? "/img/inventory-active@2x.png 2x, /img/inventory-active@3x.png 3x" : "/img/inventory@2x.png 2x, /img/inventory@3x.png 3x"}
                    className="dashboard-layer" alt='' /></span>
                  <span className='prospective-text' id='sub-scm-text-id' style={{ color: this.state.isSCMSubHeader ? "#4ac0b0" : '' }}>SCM</span>
                </a>
                {this.state.isSCMSubHeader ? <div className='active-line'></div> : null}
              </div>
              : null
            }
            <div className='col-md-1 text-right'>
              <span className=''><img src='/img/profile.png' className='profile-image' alt=''></img></span>
              <span className='profile-name'>{this.state.name}</span>
              <span onClick={this.handleIconDetails}><img src='/img/down_arrow.png' className='down_icon' alt=''></img></span>
              {this.state.logout ?
                <div className="menu">
                  <div className="row buttonView" onClick={this.handleLogout}>
                    <img className="icon" alt="" src="/img/logout.png" srcSet="/img/logout@2x.png 2x, /img/logout@3x.png 3x" />
                    <label className="logoutText">Logout</label>
                  </div>
                </div>
                : null
              }
            </div>
          </div>
          :
          <div className='row'>
            <div className='col-md-3 logo'>
              <span>
                <img src="/img/group-19.png"
                  srcSet="/img/group-19@2x.png 2x,
                      /img/group-19@3x.png 3x"
                  className="Group-19" alt='' />
              </span>
              <span className='CRM'>CRM</span>
            </div>

            {this.state.isCatHeader ?
              <div className='col-md-2 col-width2' id="category1-col">
                <Link to="/category-form">
                  <span><img id='category1-image' src="/img/inventory.png"
                    srcSet="/img/inventory@2x.png 2x, /img/inventory@3x.png 3x"
                    className="dashboard-layer" alt='' /></span>
                  <span className='prospective-text' id='category1-text-id'>Add Category</span>
                </Link>
              </div>
              : null
            }

            {(this.state.isCatHeader || this.state.isOTTHeader) ?
              <div className='col-md-2 col-width2' id="category-col">
                <Link to="/category-data">
                  <span><img id='category-image' src="/img/inventory.png"
                    srcSet="/img/inventory@2x.png 2x, /img/inventory@3x.png 3x"
                    className="dashboard-layer" alt='' /></span>
                  <span className='prospective-text' id='category-text-id'>Category Team Orders</span>
                </Link>
              </div>
              : null
            }

            {(this.state.isOTTHeader || this.state.isSCMHeader) ?
              <div className='col-md-3 col-width2' id="ott-col">
                <Link to="/lot-data">
                  <span><img id='ott-image' src="/img/inventory.png"
                    srcSet="/img/inventory@2x.png 2x, /img/inventory@3x.png 3x"
                    className="dashboard-layer" alt='' /></span>
                  <span className='prospective-text' id='ott-text-id'>Order Tracking Team Orders</span>
                </Link>
              </div>
              : null
            }

            {(this.state.isCatHeader || this.state.isOTTHeader) ?
              <div className='col-md-2 col-width2' id="reject-col">
                <Link to="/rejected-order">
                  <span><img id='reject-image' src="/img/inventory.png"
                    srcSet="/img/inventory@2x.png 2x, /img/inventory@3x.png 3x"
                    className="dashboard-layer" alt='' /></span>
                  <span className='prospective-text' id='reject-text-id'>Rejected Orders</span>
                </Link>
              </div>
              : null
            }

            {this.state.isSCMHeader ?
              <div className='col-md-2 col-width2' id="scm-col">
                <Link to="/scm-data">
                  <span><img id='scm-image' src="/img/inventory.png"
                    srcSet="/img/inventory@2x.png 2x, /img/inventory@3x.png 3x"
                    className="dashboard-layer" alt='' /></span>
                  <span className='prospective-text' id='scm-text-id'>SCM Team Orders</span>
                </Link>
              </div>
              : null
            }

            {this.state.isSCMHeader ?
              <div className='col-md-2' />
              :
              // <div className='col-md-2' />
              null
            }

            <div className='col-md-2 text-right'>
              <span className=''><img src='/img/profile.png' className='profile-image' alt=''></img></span>
              <span className='profile-name'>{this.state.name}</span>
              <span onClick={this.handleIconDetails}><img src='/img/down_arrow.png' className='down_icon' alt=''></img></span>
              {this.state.logout ?
                <div className="menu">
                  <div className="row buttonView" onClick={this.handleLogout}>
                    <img className="icon" alt="" src="/img/logout.png" srcSet="/img/logout@2x.png 2x, /img/logout@3x.png 3x" />
                    <label className="logoutText">Logout</label>
                  </div>
                </div>
                : null
              }
            </div>
          </div>
        }

        <div className='row subHeader'>
          {this.state.isCatSubHeader ?
            <div className='col-md-2 col-width2' id="category1-col">
              <a href="" onClick={() => this.redireactTo('/category-form', 'catHeader')}>
                <span><img id='category1-image' src="/img/inventory.png"
                  srcSet="/img/inventory@2x.png 2x, /img/inventory@3x.png 3x"
                  className="dashboard-layer" alt='' /></span>
                <span className='prospective-text-small' id='category1-text-id'>Add Category</span>
              </a>
            </div>
            : null
          }

          {this.state.isCatSubHeader ?
            <div className='col-md-2 col-width2' id="category-col">
              <a href="" onClick={() => this.redireactTo('/category-data', 'catHeader')}>
                <span><img id='category-image' src="/img/inventory.png"
                  srcSet="/img/inventory@2x.png 2x, /img/inventory@3x.png 3x"
                  className="dashboard-layer" alt='' /></span>
                <span className='prospective-text-small' id='category-text-id'>Category Team Orders</span>
              </a>
            </div>
            : null
          }

          {this.state.isCatSubHeader ?
            <div className='col-md-2 col-width2' id="reject-col">
              <a href="" onClick={() => this.redireactTo('/rejected-order', 'catHeader')}>
                <span><img id='reject-image' src="/img/inventory.png"
                  srcSet="/img/inventory@2x.png 2x, /img/inventory@3x.png 3x"
                  className="dashboard-layer" alt='' /></span>
                <span className='prospective-text-small' id='reject-text-id'>Rejected Orders</span>
              </a>
            </div>
            : null
          }

          {this.state.isOTTSubHeader ?
            <div className='col-md-2 col-width2' id="category-col">
              <a href="" onClick={() => this.redireactTo('/category-data', 'ottHeader')}>
                <span><img id='category-image' src="/img/inventory.png"
                  srcSet="/img/inventory@2x.png 2x, /img/inventory@3x.png 3x"
                  className="dashboard-layer" alt='' /></span>
                <span className='prospective-text-small' id='category-text-id'>Category Team Orders</span>
              </a>
            </div>
            : null
          }

          {this.state.isOTTSubHeader ?
            <div className='col-md-3 col-width2' id="ott-col">
              <a href="" onClick={() => this.redireactTo('/lot-data', 'ottHeader')}>
                <span><img id='ott-image' src="/img/inventory.png"
                  srcSet="/img/inventory@2x.png 2x, /img/inventory@3x.png 3x"
                  className="dashboard-layer" alt='' /></span>
                <span className='prospective-text-small' id='ott-text-id'>Order Tracking Team Orders</span>
              </a>
            </div>
            : null
          }

          {this.state.isOTTSubHeader ?
            <div className='col-md-2 col-width2' id="reject-col">
              <a href="" onClick={() => this.redireactTo('/rejected-order', 'ottHeader')}>
                <span><img id='reject-image' src="/img/inventory.png"
                  srcSet="/img/inventory@2x.png 2x, /img/inventory@3x.png 3x"
                  className="dashboard-layer" alt='' /></span>
                <span className='prospective-text-small' id='reject-text-id'>Rejected Orders</span>
              </a>
            </div>
            : null
          }

          {this.state.isSCMSubHeader ?
            <div className='col-md-3 col-width2' id="ott-col">
              <a href="" onClick={() => this.redireactTo('/lot-data', 'scmHeader')}>
                <span><img id='ott-image' src="/img/inventory.png"
                  srcSet="/img/inventory@2x.png 2x, /img/inventory@3x.png 3x"
                  className="dashboard-layer" alt='' /></span>
                <span className='prospective-text-small' id='ott-text-id'>Order Tracking Team Orders</span>
              </a>
            </div>
            : null
          }

          {this.state.isSCMSubHeader ?
            <div className='col-md-2 col-width2' id="scm-col">
              <a href="" onClick={() => this.redireactTo('/scm-data', 'scmHeader')}>
                <span><img id='scm-image' src="/img/inventory.png"
                  srcSet="/img/inventory@2x.png 2x, /img/inventory@3x.png 3x"
                  className="dashboard-layer" alt='' /></span>
                <span className='prospective-text-small' id='scm-text-id'>SCM Team Orders</span>
              </a>
            </div>
            : null
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user
})


export default connect(mapStateToProps, {})(DahsboardHeaderComponent);
