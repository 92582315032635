import React, { Component } from 'react';
import { connect } from 'react-redux';
import {verifyUser} from '../../actions/loginActions'



class VerificationLink extends Component {
  
    async componentDidMount() {
        let hashId = this.props.match.params.hashId;
       await this.props.verifyUser(hashId);
      }

    render() {
        const style = {
            fontSize:"24px",
            color:"#fff",
            textAlign:"center",
            fontWeight:"500",
            paddingTop:"5%"
        }
        return (
            <div style={style}>Verification Link</div>
        )
    }
}

export default connect(null, {verifyUser})(VerificationLink)