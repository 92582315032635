import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import { setComplaintData } from '../../actions/serviceCenterComplaintAction';

function ReviewDetails({ prevStep }) {
  // const history = useHistory();
  let btnRef = useRef();
  let roleId = JSON.parse(localStorage.getItem('userRoleId'));

  let dispatch = useDispatch();

  const handleBackBtn = () => {
    prevStep(3);
  };

  const editCustomerDetails = () => {
    prevStep(2);
  };

  const customerData = useSelector((state) => state.serviceCenterComplaint.customerDetails);
  const productDetails = useSelector((state) => state.serviceCenterComplaint.productDetails);
  const response = useSelector((state) => state.serviceCenterComplaint.complaintDetails);
  if (JSON.stringify(response) !== JSON.stringify({}) && response !== undefined) {
    if (response.success === true) {
      if (roleId === 8)
        return <Redirect to='/main-service-center' />;

      if (roleId === 9)
        return <Redirect to='/sub-service-center' />;

      if (roleId === 10)
        return <Redirect to='/sub-service-center' />;
    }

    if (response.success === false) {
      btnRef.current.innerText = 'Submit';
      btnRef.current.removeAttribute("disabled");
    }
  }

  const handleSubmit = (e) => {
    let sc_details = JSON.parse(localStorage.getItem('scAddress'));
    let sc_id = sc_details && sc_details.service_center_id;
    let data = {
      "name": customerData.name,
      "email": customerData.email,
      "mobile": customerData.phone,
      "address": customerData.address,
      "address2": customerData.landmark,
      "pincode": customerData.pincode,
      "state_id": customerData.stateCode,
      "city_id" : customerData.cityCode,
      "product_color_id" : productDetails.productCode,
      "product_problem_id": productDetails.complainCategoryCode,
      "problem_description": productDetails.description ? productDetails.description : '' ,
      "screening": productDetails.screeningStatusDescription[0],
      "service_center_id": sc_id,
      "purchase_date": productDetails.purchaseDate,
      "sub_product_problem_id": productDetails.sub_product_problem_id
    };
    if (btnRef.current) {
      btnRef.current.innerText = "Please wait...";
      btnRef.current.setAttribute("disabled", "disabled");
    }
    dispatch(setComplaintData(data));
  };

  return (
    <div className='review'>
      <h1 className='form-heading'>Review</h1>
      <div className='horizontal'></div>
      <div className='alignFlex'>
        <h2 className='form-subheading'>Customer details</h2>
        <button className='editBtn' onClick={editCustomerDetails}><img src='/img/editIcon.svg' alt='edit' />Change</button>
      </div>
      <table className='reviewDetailsTable'>
        <tbody>
          <tr>
            <th className='primary-heading'>{customerData && customerData.name}</th>
            <th className='primary-heading'>Contact Number</th>
            <th className='primary-heading'>Email ID</th>
          </tr>
          <tr>
            <td>{customerData && customerData.address}</td>
            <td>{customerData && customerData.phone}</td>
            <td>{customerData && customerData.email}</td>
          </tr>
        </tbody>
      </table>
      <div>
        <span>{customerData && customerData.cityName}</span>
      </div>
      <div>
        <span>{customerData && customerData.stateName} - </span>
        <span>{customerData && customerData.pincode}</span>
      </div>
      <div className='horizontal'></div><div className='alignFlex'>
        <h2 className='form-subheading'>Product details</h2>
        <button className='editBtn' onClick={handleBackBtn}><img src='/img/editIcon.svg' alt='edit' />Change</button>
      </div>
      <table className='reviewDetailsTable'>
        <tbody>
          <tr>
            <th className='primary-heading'>Category</th>
            <th className='primary-heading'>Product Name</th>
            <th className='primary-heading'>Problem</th>
          </tr>
          <tr>
            <td>{productDetails && productDetails.categoryName}</td>
            <td>{productDetails && productDetails.productName}</td>
            <td>{productDetails && productDetails.complainCategoryName}</td>
          </tr>
        </tbody>
      </table>
      <h3 className='primary-heading' style={{ margin: '20px 0 0 0', wordBreak: 'break-word' }}>{productDetails && productDetails.complainCategoryName == "Issue not listed" ? "Description" : "Sub problem"}</h3>
      <p style={{ wordBreak: 'break-word' }}>{productDetails && productDetails.complainCategoryName == "Issue not listed" ? productDetails.description : productDetails.subProblemName}</p>
      <h3 className='primary-heading'>Screening Status</h3>
      <p>{productDetails && productDetails.screeningStatusDescription[0]}</p>
      <h3 className='primary-heading'>Purchase Date </h3>
      <p>{productDetails && moment(productDetails.purchaseDate).format("DD-MM-YYYY")}</p>
      <div className='horizontal'></div>
      <div className='buttons'>
        <button className='navBtn' id='prevBtn' onClick={handleBackBtn}>Back</button>
        <button className='navBtn' id='submitBtn' onClick={handleSubmit} ref={btnRef}>Submit</button>
      </div>
    </div>
  );
}

export default ReviewDetails;