import React, { Component } from 'react';
import ReactModal from 'react-modal';
import {
    moveToDispatchInward, getSCRpuCourier, submitSCRPU, fetchSCPrintLabel, onhandOverToCourier, refundCaseApproval, freshPRoductAPI, UnservicesableleAPI, unmarkedTicketRefund,
    unmarkedTicketGC, RtoOutAPi, moveToRtoCase, OutOFStockAPI, onRevokeInvAction,
    onRevokeAction, onClosedAction, gccaseService
} from "../../actions/serviceCenterActions";
import ComponentToFPUPrint from './PrintComponents/ComponentToFPUPrint';
import { connect } from "react-redux";
const moment = require('moment');

const CustomGCStyle = {
    overlay: {
        background: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
        inset: '8% 0 0 auto',
        marginRight: '0',
        transform: 'translate(0)',
        background: '#000',
        padding: '2rem',
        border: 'none',
        width: '35%',
        position: 'absolute'
    }
};


const customStylesDO = {
    overlay: {
        background: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
        inset: '8% 0 0 auto',
        marginRight: '0',
        transform: 'translate(0)',
        background: '#000',
        padding: '2rem',
        border: 'none',
        width: '35%',
        position: 'absolute'
    },
};

const customStyles = {
    overlay: {
        background: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
        inset: '8% 0 0 auto',
        marginRight: '0',
        transform: 'translate(0)',
        background: '#000',
        padding: '2rem',
        border: 'none',
        width: '25%',
        position: 'absolute'
    },
};

const customStylesRPU = {
    overlay: {
        background: 'rgba(0, 0, 0, 0.5)',
        overflow: "scroll"
    },
    content: {
        inset: '8% 0 0 auto',
        marginRight: '0',
        transform: 'translate(0)',
        background: '#fff',
        padding: '2rem',
        border: 'none',
        width: '100%',
        position: 'absolute',
        overflow: 'scroll',
    },
};

class SCActions extends Component {

    constructor() {
        super();
        this.state = {
            orderId: "",
            showRefundModal: false,
            adminStatus: '',
            order_id: '',
            showFPUModal: false,
            courierId: 0,
            showFPUPrintModal: false,
            isLoadingGif: false,
            errors: [],
            loadingInModal: false,
            showGcModal: false,
        };
        this.handleRefundCloseModal = this.handleRefundCloseModal.bind(this);
        this.handleGCCaseCloseModal = this.handleGCCaseCloseModal.bind(this);
        this.handleGCCase = this.handleGCCase.bind(this);
        this.handleRefundSubmit = this.handleRefundSubmit.bind(this);
        this.handleDispatchInward = this.handleDispatchInward.bind(this);
        this.handleCourierChange = this.handleCourierChange.bind(this);
        this.handleFPUOpenModal = this.handleFPUOpenModal.bind(this);
        this.handleFPUCloseModal = this.handleFPUCloseModal.bind(this);
        this.handlePrintModalClose = this.handlePrintModalClose.bind(this);
        this.handleFPUPrint = this.handleFPUPrint.bind(this);
        this.handleRefundCase = this.handleRefundCase.bind(this);
        this.handleAdminStatus = this.handleAdminStatus.bind(this);
        this.GccaseValidation = this.GccaseValidation.bind(this);
        this.handleGCCaseSubmit = this.handleGCCaseSubmit.bind(this);
        this.onClickRefundCaseSubmit = this.onClickRefundCaseSubmit.bind(this);
    }

    handleFPUPrint = async (e) => {
        let complaintId = this.props.complaintsDetail.id;
        this.setState({ complaintId: complaintId });
        let data = {
            "complaint_id": complaintId,
            "type": "forward_pick_up"
        };
        await this.props.fetchSCPrintLabel(data);
        if (this.props.printSCLabel) {
            //history.push('/fpu-print')
            this.setState({ showFPUPrintModal: true });
        } else {
            alert("no courier data found");
        }
    };

    handlePrintModalClose() {
        this.setState({
            showScreeningPrintModal: false
        }, () => {
            this.props.handleRefreshList();
        });
    }

    async handleCourierChange(e) {
        this.setState({
            courierId: e.target.value
        });
    }


    async handleDispatchInward(e,) {

        let data = {
            complaint_id: this.props.complaintsDetail.id
        };
        await this.props.moveToDispatchInward(data);
        this.props.handleRefreshList();
    }

    async handleRefundSubmit(e, item) {
        this.setState({
            showRefundModal: true
        });
    }

    handleGCCase = () => {
        this.setState({
            showGcModal: true
        });
    };

    handleRefundCase(e) {
        this.setState({
            order_id: e.target.value
        });
    }

    handleFPUOpenModal = async (e, item) => {
        let data = {
            "complaint_id": this.props.complaintsDetail.id,
            "type": "forward_pick_up"
        };
        this.setState({ complaintId: item });
        this.setState({
            isLoading: true
        });
        await this.props.getSCRpuCourier(data);
        this.setState({ isLoading: false, showFPUModal: true });
    };

    handleFPUCloseModal() {
        this.setState({ showFPUModal: false });
    }

    handleGCCaseCloseModal() {
        this.setState({ showGcModal: false });
    }

    handleRefundCloseModal() {
        this.setState({ showRefundModal: false });
    }

    handleRefundCaseValidation() {
        let flag = true;
        let errors = {};
        if (this.state.order_id === '') {
            errors["order_id"] = "Order ID is mandatory";
            flag = false;
        }
        if (this.state.adminStatus === 0) {
            errors["adminStatus"] = "Please Select User Name ";
            flag = false;
        }
        this.setState({ errors: errors });
        return flag;
    }

    GccaseValidation() {
    let flag = true;
        let errors = {};
        if (this.state.order_id === '') {
            errors["order_id"] = "Order ID is mandatory";
            flag = false;
        }
        if (this.state.adminStatus === 0) {
            errors["adminStatus"] = "Please Select User Name ";
            flag = false;
        }
        this.setState({ errors: errors });
        return flag;
    }

    handleGCCaseSubmit = async () => {
        this.setState({ loadingInModal: true });
        if (this.GccaseValidation() == true) {
            let data = {
                "complaint_id": this.props.complaintsDetail.id,
                "order_id": this.state.order_id,
                "gc_approve": this.state.adminStatus
            };
            await this.props.gccaseService(data);
            this.setState({ showGcModal: false });
            this.props.handleRefreshList();
        }
    };

    onClickRefundCaseSubmit = async () => {
        this.setState({ loadingInModal: true });
        if (this.handleRefundCaseValidation() == true) {
            let data = {
                "complaint_id": this.props.complaintsDetail.id,
                "order_id": this.state.order_id,
                "refund_approve": this.state.adminStatus
            };
            await this.props.refundCaseApproval(data);
            this.setState({ showRefundModal: false });
            this.props.handleRefreshList();
        }
    };

    onMoveToFreshProductClick = async (e, item) => {
        e.preventDefault();
        let data = {
            "complaint_id": item,
        };
        await this.props.freshPRoductAPI(data);
        this.props.handleRefreshList();
    };

    onMoveToOutOfStockClick = async (e, item) => {
        e.preventDefault();
        let data = {
            "complaint_id": item,
        };
        await this.props.OutOFStockAPI(data);
        this.props.handleRefreshList();
    };

    onMoveToUnserviceableClick = (e, item) => {
        e.preventDefault();
        let data = {
            "complaint_id": item,
        };
        this.props.UnservicesableleAPI(data);
        this.props.handleRefreshList();
    };

    onMoveToRtoOutClick = async (e, item) => {
        e.preventDefault();
        let data = {
            "complaint_id": this.props.complaintsDetail.id,
        };
        await this.props.RtoOutAPi(data);
        this.props.handleRefreshList();
    };

    onMoveToRtoInClick = async (e, item) => {
        e.preventDefault();
        let data = {
            "complaint_id": item,
        };
        await this.props.moveToRtoCase(data);
        this.props.handleRefreshList();
    };

    handleRevokeTicket = async (e, item) => {
        await this.props.onRevokeAction(item);
        this.props.handleRefreshList();
    };

    onUnmarkedTicketRefund = async (e, item) => {
        let data = {
            "complaint_id": item,
        };
        await this.props.unmarkedTicketRefund(data);
        this.props.handleRefreshList();
    };

    onUnmarkedTicketGC = async (e, item) => {
        let data = {
            "complaint_id": item,
        };
        await this.props.unmarkedTicketGC(data);
        this.props.handleRefreshList();
    };

    handleAdminStatus(e) {
        this.setState({
            adminStatus: e.target.value
        });
    }


    onFPUSubmit = async () => {
        let cId = this.state.courierId;
        let selectedC = this.props.courierSCData.filter((cd) => Number(cd.courier_id) === Number(cId));
        if (selectedC.length > 0) {
            this.setState({ isLoadingGif: true });
            let data = {
                "complaint_id": this.props.complaintsDetail.id,
                "courier_id": cId,
            };
            await this.props.submitSCRPU(data);
            this.setState({
                isLoadingGif: false,
            });
            this.setState({
                showFPUModal: false
            });
            this.props.handleRefreshList();
        } else {
            alert("Please select courier");
            return;
        }
    };

    handleHandover = async (e, item) => {
        let data = {
            "complaint_id": this.props.complaintsDetail.id,
        };
        await this.props.onhandOverToCourier(data);
        this.props.handleRefreshList();
    };


    render() {
        let userRoleId = localStorage.getItem('userRoleId');
        if (this.props.adminUserlist.data) {
        }
        else {
        }
        return (
            <div>
                {
                    //TS Unsuccsfull
                    this.props.statusId === 4 ? <div>
                        <a className="dropdown-item"  onClick={(e) => this.handleDispatchInward(e, this.props.complaintsDetail.id)}><img src="../../img/Complaint-Closed.svg" alt="" />Dispatch Inward</a>
                        <a className="dropdown-item"  onClick={(e) => this.onMoveToOutOfStockClick(e, this.props.complaintsDetail.id)}><img src="../../img/Out-of-Stock.svg" alt="" />Out of stock</a>
                    </div> : null
                }

                {
                    //dispatch inward
                    this.props.statusId === 17 ? <div>
                        <a className="dropdown-item"  onClick={this.handleFPUOpenModal}><img src="../../img/Edit.svg" />Dispatch OutWard</a>
                        <a className="dropdown-item"  onClick={this.handleGCCase}><img src="../../img/Gift-Card.svg" alt="" />GC Case</a>
                        <a className="dropdown-item"  onClick={this.handleRefundSubmit}><img src="../../img/Refund.svg" alt="" />Refund</a>
                        <a className="dropdown-item"  onClick={(e) => this.onMoveToFreshProductClick(e, this.props.complaintsDetail.id)}><img src="../../img/Fresh-Product.svg" alt="" />Fresh Product</a>
                    </div> : null
                }
                {
                    //dispatch outward
                    this.props.statusId === 18 ? <div>
                        <a className="dropdown-item"  onClick={this.handleFPUPrint}><img src="../../img/Forward-pickup-print.svg" />Forward Pickup Print</a>
                        <a className="dropdown-item"  onClick={(e) => this.onMoveToUnserviceableClick(e, this.props.complaintsDetail.id)}><img src="../../img/Unserviceable.svg" alt="" />Unserviceable Ticket</a>
                        <a className="dropdown-item"  onClick={this.handleGCCase}><img src="../../img/Gift-Card.svg" alt="" />GC Case</a>
                        <a className="dropdown-item"  onClick={this.handleRefundSubmit}><img src="../../img/Refund.svg" alt="" />Refund</a>
                        <a className="dropdown-item"  onClick={(e) => this.onMoveToFreshProductClick(e, this.props.complaintsDetail.id)}><img src="../../img/Fresh-Product.svg" alt="" />Fresh Product</a>
                        <a className="dropdown-item"  onClick={(e) => this.handleHandover(e, this.props.complaintsDetail.id)}><img src="../../img/Complaint-Closed.svg" alt="" />HandOver Courier</a>
                    </div> : null
                }
                {
                    //rto inward
                    this.props.statusId === 19 ? <div>
                        <a className="dropdown-item"  onClick={(e) => this.onMoveToRtoOutClick(e, this.props.complaintsDetail.id)}><img src="../../img/RTO-Out.svg" alt="" />RTO Out</a>
                        <a className="dropdown-item"  onClick={this.handleFPUOpenModal}><img src="../../img/Re-Dispatch.svg" />Re-dispatch</a>
                        <a className="dropdown-item"  onClick={(e) => this.onMoveToUnserviceableClick(e, this.props.complaintsDetail.id)}><img src="../../img/Unserviceable.svg" alt="" />Unserviceable Ticket</a>
                        <a className="dropdown-item"  onClick={this.handleGCCase}><img src="../../img/Gift-Card.svg" alt="" />GC Case</a>
                        <a className="dropdown-item"  onClick={this.handleRefundSubmit}><img src="../../img/Refund.svg" alt="" />Refund</a>
                        <a className="dropdown-item"  onClick={(e) => this.onMoveToFreshProductClick(e, this.props.complaintsDetail.id)}><img src="../../img/Fresh-Product.svg" alt="" />Fresh Product</a>
                    </div> : null
                }
                {
                    //rto outward
                    this.props.statusId === 20 ? <div>
                        {/* <a className="dropdown-item"  ><img src="../../img/Edit.svg" />Re-dispatch</a> */}
                        <a className="dropdown-item"  onClick={this.handleFPUOpenModal}><img src="../../img/Re-Dispatch.svg" />Re-dispatch</a>
                        <a className="dropdown-item"  onClick={this.handleGCCase}><img src="../../img/Gift-Card.svg" alt="" />GC Case</a>
                        <a className="dropdown-item"  onClick={this.handleRefundSubmit}><img src="../../img/Refund.svg" alt="" />Refund</a>
                        <a className="dropdown-item"  onClick={(e) => this.onMoveToFreshProductClick(e, this.props.complaintsDetail.id)}><img src="../../img/Fresh-Product.svg" alt="" />Fresh Product</a>
                    </div> : null
                }
                {
                    //unserviceable
                    this.props.statusId === 21 ? <div>
                        <a className="dropdown-item"  onClick={this.handleFPUOpenModal}><img src="../../img/Re-Dispatch.svg" />Re-dispatch</a>
                        <a className="dropdown-item"  onClick={this.handleGCCase}><img src="../../img/Gift-Card.svg" alt="" />GC Case</a>
                        <a className="dropdown-item"  onClick={this.handleRefundSubmit}><img src="../../img/Refund.svg" alt="" />Refund</a>
                        <a className="dropdown-item"  onClick={(e) => this.onMoveToFreshProductClick(e, this.props.complaintsDetail.id)}><img src="../../img/Fresh-Product.svg" alt="" />Fresh Product</a>
                    </div> : null
                }
                {
                    //fresh products
                    this.props.statusId === 33 ? <div>
                        <a className="dropdown-item"  onClick={(e) => this.handleDispatchInward(e, this.props.complaintsDetail.id)}><img src="../../img/Complaint-Closed.svg" alt="" />Dispatch Inward</a>
                        <a className="dropdown-item"  onClick={this.handleGCCase}><img src="../../img/Gift-Card.svg" alt="" />GC Case</a>
                        <a className="dropdown-item"  onClick={this.handleRefundSubmit}><img src="../../img/Refund.svg" alt="" />Refund</a>
                    </div> : null
                }
                {
                    //out of stock
                    this.props.statusId === 35 ? <div>
                        <a className="dropdown-item"  onClick={(e) => this.handleRevokeTicket(e, this.props.complaintsDetail.id)}><img src="../../img/Revoke-Ticket.svg" alt="" />Back to stock</a>
                        <a className="dropdown-item"  onClick={(e) => this.onMoveToFreshProductClick(e, this.props.complaintsDetail.id)}><img src="../../img/Fresh-Product.svg" alt="" />Fresh Product</a>
                    </div> : null
                }
                {
                    //handover to courier
                    this.props.statusId === 41 ? <div>
                        <a className="dropdown-item"  onClick={(e) => this.onMoveToRtoInClick(e, this.props.complaintsDetail.id)}><img src="../../img/RTO.svg" alt="" />RTO In</a>
                        <a className="dropdown-item"  onClick={this.handleGCCase}><img src="../../img/Gift-Card.svg" alt="" />GC Case</a>
                        <a className="dropdown-item"  onClick={this.handleRefundSubmit}><img src="../../img/Refund.svg" alt="" />Refund</a>
                        <a className="dropdown-item"  onClick={(e) => this.onMoveToFreshProductClick(e, this.props.complaintsDetail.id)}><img src="../../img/Fresh-Product.svg" alt="" />Fresh Product</a>
                        <a className="dropdown-item"  onClick={this.handleFPUPrint}><img src="../../img/Forward-pickup-print.svg" />Forward Pickup Print</a>
                    </div> : null
                }   {
                    //dispatch delivered
                    this.props.statusId === 42 ? <div>
                        <a className="dropdown-item"  onClick={this.handleGCCase}><img src="../../img/Gift-Card.svg" alt="" />GC Case</a>
                        <a className="dropdown-item"  onClick={this.handleRefundSubmit}><img src="../../img/Refund.svg" alt="" />Refund</a>
                        <a className="dropdown-item"  onClick={(e) => this.onMoveToRtoInClick(e, this.props.complaintsDetail.id)}><img src="../../img/RTO.svg" alt="" />RTO In</a>
                        <a className="dropdown-item"  onClick={this.handleFPUPrint}><img src="../../img/Forward-pickup-print.svg" />Forward Pickup Print</a>
                        <a className="dropdown-item"  onClick={(e) => this.onMoveToFreshProductClick(e, this.props.complaintsDetail.id)}><img src="../../img/Fresh-Product.svg" alt="" />Fresh Product</a>
                    </div> : null
                }
                {
                    this.props.statusId === 43 ? <div>
                        {this.props.complaintsDetail.current_suffix == "REF" &&
                            <div>
                                <a className="dropdown-item"  onClick={this.handleGCCase}><img src="../../img/Gift-Card.svg" alt="" />GC Case</a>
                                <a className="dropdown-item"  onClick={(e) => this.onUnmarkedTicketRefund(e, this.props.complaintsDetail.id)}><img src="../../img/Revoke-Ticket.svg" alt="" />Unmarked Refund </a>

                            </div>}
                        {this.props.complaintsDetail.current_suffix == "GC" &&
                            <div>
                                <a className="dropdown-item"  onClick={this.handleRefundSubmit}><img src="../../img/Refund.svg" alt="" />Refund</a>
                                <a className="dropdown-item"  onClick={(e) => this.onUnmarkedTicketGC(e, this.props.complaintsDetail.id)}><img src="../../img/Revoke-Ticket.svg" alt="" />Unmarked GC </a>
                            </div>}

                        <a className="dropdown-item"  onClick={(e) => this.onMoveToFreshProductClick(e, this.props.complaintsDetail.id)}><img src="../../img/Fresh-Product.svg" alt="" />Fresh Product</a>
                    </div>
                        : null
                }
                {
                    //direct dispatch
                    this.props.statusId === 45 ? <div>
                        <a className="dropdown-item"  onClick={this.handleGCCase}><img src="../../img/Gift-Card.svg" alt="" />GC Case</a>
                        <a className="dropdown-item"  onClick={this.handleRefundSubmit}><img src="../../img/Refund.svg" alt="" />Refund</a>
                        <a className="dropdown-item"  onClick={(e) => this.onMoveToOutOfStockClick(e, this.props.complaintsDetail.id)}><img src="../../img/Out-of-Stock.svg" alt="" />Out of stock</a>
                        <a className="dropdown-item"  onClick={(e) => this.onMoveToRtoInClick(e, this.props.complaintsDetail.id)}><img src="../../img/RTO.svg" alt="" />RTO In</a>
                        <a className="dropdown-item"  onClick={this.handleFPUPrint}><img src="../../img/Forward-pickup-print.svg" />Forward Pickup Print</a>
                        <a className="dropdown-item"  onClick={(e) => this.onMoveToFreshProductClick(e, this.props.complaintsDetail.id)}><img src="../../img/Fresh-Product.svg" alt="" />Fresh Product</a>
                    </div> : null
                }
                
                <ReactModal
                    isOpen={this.state.showGcModal}
                    contentLabel="Minimal Modal Example"
                    style={CustomGCStyle}>
                    <h2 id="rModal_head">GC Case</h2>
                    <button id="close_rModal" onClick={this.handleGCCaseCloseModal}><i className="fas fa-times"></i></button>
                    <div className="gc_wrapper">
                        <label style={{ "color": 'aliceblue' }}>Order Id</label>
                        <input type="text" value={this.state.order_id} onChange={this.handleRefundCase} placeholder="Enter Order ID"></input>
                        <div className="error_add">
                            <span className="errorShow">{this.state.errors["order_id"]}</span>
                        </div>
                    </div>
                    <div className='gc_wrapper'>
                        <label style={{ "color": 'aliceblue' }}>Approved By</label>
                        <select onChange={this.handleAdminStatus} id="select_aaModal">
                            <option value={"0"} defaultValue="selected">Select User</option>
                            {this.props.adminUserlist.data &&
                                this.props.adminUserlist.data.map((obj, index) => (
                                    <option value={obj.user_id} key={index}>{obj.first_name}{obj.last_name}</option>
                                ))
                            }
                        </select>
                        <div className="error_add">
                            <span className="errorShow">{this.state.errors["adminStatus"]}</span>
                        </div>
                        <div>
                            <button id="send_raModal" type="button" onClick={this.handleGCCaseSubmit}>Save</button>
                        </div>
                    </div>
                </ReactModal>
                <ReactModal
                    isOpen={this.state.showRefundModal}
                    contentLabel="Minimal Modal Example"
                    style={customStyles}
                >
                    <h2 id="rModal_head" style={{ "margin-bottom": "30px" }}>Refund Case</h2>
                    <button id="close_rModal" onClick={this.handleRefundCloseModal}><i className="fas fa-times"></i></button>
                    <div className="gc_wrapper">
                        <label>Order Id</label>
                        <input type="text" value={this.state.order_id} onChange={this.handleRefundCase} placeholder="Enter OrderID"></input>
                        <div className="error_add">
                            <span className="errorShow">{this.state.errors["order_id"]}</span>
                        </div>
                    </div>
                    <div className="gc_wrapper" >
                        <label>User</label>
                        <select onChange={this.handleAdminStatus} id="select_aaModal" >

                            <option value={"0"} defaultValue="selected">Select User</option>
                            {this.props.adminUserlist.data &&
                                this.props.adminUserlist.data.map((obj, index) => (
                                    <option value={obj.user_id} key={index}>{obj.first_name}{obj.last_name}</option>
                                ))
                            }
                        </select>
                        <div className="error_add">
                            <span className="errorShow">{this.state.errors["adminStatus"]}</span>
                        </div>
                        <div>
                            <button id="send_raModal" type="button" onClick={this.onClickRefundCaseSubmit}>Save</button>
                        </div>
                    </div>
                </ReactModal>
                <ReactModal
                    isOpen={this.state.showFPUModal}
                    contentLabel="Minimal Modal Example"
                    style={customStylesDO}
                    ariaHideApp={false}
                >
                    <h2 id="rModal_head">Forward Pickup</h2>
                    <button id="close_rModal" onClick={this.handleFPUCloseModal}><i className="fas fa-times"></i></button>
                    <select id="select_rModal" className="form-control" onChange={this.handleCourierChange}>
                        <option value={"0"} selected="selected">Select Courier</option>
                        {this.props.courierSCData.map((obj) => (
                            <option value={obj.courier_id}>{obj.courier_name} - {obj.pincode} - Rs.{obj.reverse_cost}</option>
                        ))}
                    </select>
                    {this.state.isLoadingGif ?
                        <button id="send_rModal" type="button"><img className="loaderCTA" src="/img/loading-gif.gif" alt="Loader" /></button>
                        :
                        <button id="send_rModal" type="button" onClick={this.onFPUSubmit}>Send</button>
                    }
                </ReactModal>
                <ReactModal
                    isOpen={this.state.showFPUPrintModal}
                    contentLabel="Minimal Modal Example"
                    style={customStylesRPU}
                    ariaHideApp={false}
                    className="rpu_print"
                >
                    <button type='button' onClick={this.handlePrintModalClose} id="close_rModal"><i className="fas fa-times"></i></button>
                    <ComponentToFPUPrint ref={el => (this.componentRef = el)} />
                </ReactModal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    complaintsDetail: state.serviceCenter.complaintsDetail,
    statusId: state.complaints.statusId,
    printLabel: state.complaints.printLabel,
    adminUserlist: state.complaints.adminUserlist,
    printSCLabel: state.serviceCenter.printSCLabel,
    courierSCData: state.serviceCenter.courierSCData,
});

export default connect(mapStateToProps, {
    moveToDispatchInward, moveToRtoCase,
    refundCaseApproval, unmarkedTicketRefund, unmarkedTicketGC, getSCRpuCourier,
    submitSCRPU, fetchSCPrintLabel, onhandOverToCourier, onRevokeAction, onClosedAction,
    gccaseService, freshPRoductAPI, UnservicesableleAPI, OutOFStockAPI, RtoOutAPi, onRevokeInvAction,
})(SCActions);