import React, { Component } from 'react';
import './LoginComponent.css';
import { createPassword } from "../../actions/loginActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import AccessRestricted from '../AccessRestricted'
import ComplaintRegistration from '../ComplaintRegistration';

class CreatePasswordComponent extends Component {
  constructor() {
    super();
    this.state = {
      password: '',
      confirmpassword: '',
      redirect: false

    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(e) {
    e.preventDefault();
    let urlString = window.location.href
    let url = new URL(urlString);
    let token = url.searchParams.get("token");
    let data = {
      'password': this.state.password,
      'confirmpassword': this.state.confirmpassword,
      'token': token,
    }
    this.props.createPassword(data);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  state = {
    isPasswordShown : false,
    isPasswordShown2: false
  }
 togglePasswordVisiblity = () => {
    const {isPasswordShown} = this.state;
    this.setState({isPasswordShown : !isPasswordShown});
  }
  togglePasswordVisiblity2 = () => {
    const {isPasswordShown2} = this.state;
    this.setState({isPasswordShown2 : !isPasswordShown2});
  }

  render =() => {
    const{ isPasswordShown, isPasswordShown2} = this.state;
    const { redirect } = this.state
    if (redirect) {
      return <Redirect to={{
        pathname: '/dashboard',
     } } />
    };
    
    let userRoleId = localStorage.getItem('userRoleId');
    if(userRoleId === '4' || userRoleId === '8' || userRoleId === '9' || userRoleId === '10'){
      return( <AccessRestricted/>)
    }
    else if (userRoleId === '6'){
      return (<ComplaintRegistration />)
  }
    else{
    return (
      <div className="container login-container ">

          <div className="login-form">
            <div className="loginForm">
              <label className="create">Create your password</label>

              <form action="" onSubmit={this.handleSubmit}>
                <div className="pass1">
                  
                <input className="password" name="password"   type= {isPasswordShown ? "text":"Password"} placeholder="Password" onChange={this.handleChange}/>
                  < i className={`fa ${isPasswordShown ? "fa-eye-slash" : "fa-eye"} password-icon`} onClick={this.togglePasswordVisiblity} /></div>
                
                <div className="pass2">  
                  <input className="confirmpassword" name="confirmpassword"  type= {isPasswordShown2 ? "text":"Password"} placeholder="Confirm Password" onChange={this.handleChange}/>
                  < i className={`fa ${isPasswordShown2 ? "fa-eye-slash" : "fa-eye"} password-icon`} onClick={this.togglePasswordVisiblity2} /></div>
                
                  <div className="button" onClick={this.handleSubmit}>
                  <label className="loginText">Create</label>
                  </div>
                  </form>
                  </div></div> 
                  
                  <div className="login_img">
                  <img src="/img/Tapsee-min.png" alt="" />
                  </div>
                  <div className="logo">
                  <img src="/img/nlogo.png" alt=""/>
                  </div>      
</div>
 )
    }
}
}
CreatePasswordComponent.propTypes = {
  createPassword: PropTypes.func.isRequired
};
export default connect(null, { createPassword })(CreatePasswordComponent);