import React, { Component } from 'react';
import '../Settings/Settings.css';
import { connect } from "react-redux";
import './Warranty.css';
// import Select from 'react-select';
// import history from '../../history';
// import Loader from 'react-loader-spinner'
import {
    notifyReport
} from '../../actions/reportActions';
// import { getServiceCenters, } from '../../actions/serviceCenterActions'
// import { fetchChannels } from '../../actions/reportActions'
// import { getCityByState, getStateList } from '../../actions/complaintsDataAction';
// import { getUserDetail } from '../../actions/userActions'
// import ReactModal from 'react-modal';
import ViewProfile from '../ViewProfile';
import AccessRestricted from '../AccessRestricted';
import { getNotifyData } from '../../actions/warrantyDataAction';
import moment from 'moment';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';
import Footer from '../FooterComponent/footer';
import SidebarComponent from '../SidebarComponent/SidebarComponent';

const {
    allowedRange
} = DateRangePicker;

class Warranty extends Component {

    constructor() {
        super();
        this.state = {
            notifyData: [],
            accessLevel: '',
            productList: [],
            pageLimit: 10,
            loader: false,
            totalcount: '',
            isLoading: false,
            prevPage: false,
            nextPage: false,
            errors: [],
            showChangePassword: false,//it's use
            dropDownOpen: false,//this one
            current_page: 1,
            selectedProduct: [],
            totalPage: '',
            productArrays: [],
            channelArrays: [],
            warrantyStartDate: new Date().setDate(new Date().getDate() - 45),
            warrantyEndDate: new Date(),
            isSearch: false,
            searchText: '',
            searchDate: '',
            notifyDataList: []
        };
        this.getWarrantyList = this.getWarrantyList.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
    }

    componentDidMount() {
        // this.getUserInfo();/
        this.getWarrantyList();
    }

    getWarrantyList = async () => {
        this.setState({ isLoading: true });
        let offset = (this.state.current_page - 1);
        let data = {
            "limit": 10,
            "offset": offset,
        };
        if (this.state.searchText.length > 0) {
            data.filterText = this.state.searchText;
        }
        if (this.state.warrantyStartDate && this.state.warrantyEndDate) {
            data.fromDate = moment(this.state.warrantyStartDate).format('YYYY-MM-DD');
            data.endDate = moment(this.state.warrantyEndDate).format('YYYY-MM-DD');
        }
        this.setState({ isLoading: true, notifyDataList: [] });
        await this.props.getNotifyData(data);
        let insdieNotifyData = this.props.notifyData;
        this.setState({
            isLoading: false,
            totalCount: insdieNotifyData ? insdieNotifyData.total : 1,
            totalPage: insdieNotifyData ? insdieNotifyData.no_page : 0,
            prevPage: insdieNotifyData ? insdieNotifyData.prev_page : 0,
            nextPage: insdieNotifyData ? insdieNotifyData.next_page : 1,
            notifyDataList: insdieNotifyData ? insdieNotifyData.data : [],
        });
    };

    downloadReport = () => {
        let fromDate = moment(this.state.warrantyStartDate).format('YYYY-MM-DD');
        let endDate = moment(this.state.warrantyEndDate).format('YYYY-MM-DD');
        let data = {
            "fromDate": fromDate,
            "toDate": endDate,
        };
        this.props.notifyReport(data);
    };

    onPrevPress = () => {
        if (this.state.prevPage) {
            this.setState({ current_page: (this.state.current_page - 1) }, () => {
                this.getWarrantyList();
            });
        }
    };

    onNextPress = () => {
        if (this.state.nextPage) {
            this.setState({ current_page: (this.state.current_page + 1) }, () => {
                this.getWarrantyList();
            });
        }
    };

    notifyReportSelectDate = async (value) => {
        this.setState({
            warrantyStartDate: new Date(value[0]),
            warrantyEndDate: new Date(value[1]),
        }, () => {
            this.getWarrantyList();
        });
    };

    handleChange = async (e) => {
        if (e.target.name === 'searchByEmailNumber') {
            this.setState({ searchText: e.target.value, current_page: 1 }, () => {
                if (this.state.searchText.length === 0) {
                    this.getWarrantyList();
                }
            });
        }
    };

    _handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.handleChangeButton(e);
        }
    }

    handleChangeButton = (e) => {
        e.preventDefault();
        this.getWarrantyList();

    };

    render() {
        let new_page = (this.state.current_page - 1);
        let userRoleId = localStorage.getItem('userRoleId');
        if (userRoleId === '4') {
            return (<AccessRestricted />);
        } else {
            return (
                <div>
                    <div>
                      { window.innerWidth > 600 &&  <SidebarComponent sideProp={'WD'} />}
                        <ViewProfile />
                    </div>
                    <div className="content manage-user-main">
                        <div className="menuBar">
                            <span className="complaint-head">Customer</span>
                            <ul id="menu">
                                <li ><a href="/warranty-data">Warranty</a></li>
                                <li className="actice2"><a href="notify">Notify</a></li>
                                <li><a href="/internation-warranty">International warranty</a></li>
                            </ul>
                        </div>
                        <div className='service-center-wrapper'>
                            <div>
                                <div className="search" style={{ 'marginTop': "3%", float: 'left', "marginLeft": "1%" }}>
                                    <input type="username" name="searchByEmailNumber" id="search_input" placeholder="Search by customer number Email.." onChange={this.handleChange} onKeyDown={this._handleKeyDown} />
                                    <button type="button" className="add-user-btn-input" onClick={this.handleChangeButton}><i className="fa fa-search user_search_icon"></i></button>
                                </div>
                                <div id="datebox" >
                                    <h6>Date - (DD/MM/YYYY)</h6>
                                    <DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(new Date(new Date().setDate(new Date().getDate() - 90))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.notifyReportSelectDate(value); }} value={[new Date(this.state.warrantyStartDate), new Date(this.state.warrantyEndDate)]} />
                                </div>
                                {/* {
                                    (['1', '2'].includes(userRoleId)) ?
                                        <div className="addServiceCenter">
                                            <button type="button" className="bbts" onClick={this.downloadReport}>Export</button>
                                        </div>
                                        : null
                                } */}
                                {
                                    <div className='colrow service-center warranty-col-row'>
                                        <div className='row header-row' >
                                            <div className='col-sm' style={{ color: '#FC940D' }}>
                                                Customer Name
                                            </div>
                                            <div className='col-sm' style={{ color: '#FC940D' }}>
                                                Product
                                            </div>
                                            <div className='col-sm' style={{ color: '#FC940D' }}>
                                                Notify Date
                                            </div>
                                            <div className='col-sm' style={{ color: '#FC940D' }}>
                                                Contacts
                                            </div>
                                            <div className='col-sm' style={{ color: '#FC940D' }}>
                                                Alternate Contacts
                                            </div>
                                            {/* <div className='col-sm'>
                                            Remarks
                                        </div>
                                        <div className='col-sm'>
                                            Actions
                                        </div> */}
                                        </div>
                                        {this.state.notifyDataList.length > 0
                                            ?
                                            <div>
                                                {this.state.notifyDataList.map((item, index) => (
                                                    <div key={item.id} className='row list-row'>
                                                        <div className='col-sm'>
                                                            {item.customerDetails.customer_name}
                                                        </div>
                                                        <div className='col-sm'>
                                                            {item.product}
                                                        </div>
                                                        <div className='col-sm'>
                                                            {new Date(item.created_at).toLocaleDateString('en-GB', { year: "numeric", month: "short", day: "numeric" })}
                                                        </div>
                                                        <div className='col-sm' style={{ "marginTop": "-12px" }}>
                                                            {item.customerDetails.email}<br />
                                                            {item.customerDetails.mobile}
                                                        </div>
                                                        <div className='col-sm' style={{ "marginTop": "-12px" }}>
                                                            {item.customerDetails.alternate_email}<br />
                                                            {item.customerDetails.alternate_mobile_number}
                                                        </div>
                                                    </div>
                                                ))
                                                }

                                            </div>
                                            :
                                            <p className="noResult">No result found</p>
                                        }
                                        <Footer new_page={new_page} onPrevPress={this.onPrevPress} onNextPress={this.onNextPress} current_page={this.state.current_page} cc={"kk"} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = state => ({
    userDetail: state.user.userDetail,
    userLogin: state.user.userLogin,
    serviceData: state.serviceCenter.serviceData,
    fetchChannelList: state.report.fetchChannelList,
    warrantyData: state.warranty.warrantyData,
    productData: state.complaints.productData,
    newServiceData: state.serviceCenter.newServiceData,
    stateDataList: state.complaints.stateData,
    cityDataList: state.complaints.cityData,
    serviceListRemoved: state.serviceCenter.serviceListRemoved,
    callServiceCenter: state.serviceCenter.callServiceCenter,
    notifyData: state.warranty.notifyData
});

export default connect(mapStateToProps, { getNotifyData, notifyReport })(Warranty);