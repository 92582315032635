import React, { useState } from "react";

const DownloadSampleCSV = ({
  productData,
  problemData,
  stateCityData,
  status,
}) => {
  // Define the headers
  const headersProduct = [
    "Product Name",
    "Product ID",
    "Color Name",
    "Product Color ID",
  ];
  const headersProblem = [
    "Problem Name",
    "Problem ID",
    "Sub Problem Name",
    "Sub Problem ID",
  ];
  const headersCity = ["City Name", "City ID", "State ID"];

  // Function to convert the data to CSV format
  const convertCsvProduct = (productData, headersProduct) => {
    // Create the header row
    const headerRow = headersProduct.join(",") + "\n";

    // Create the data rows
    const rows = productData
      .map((obj) =>
        [obj.product_name, obj.product_id, obj.color_name, obj.id].join(
          ","
        )
      )
      .join("\n");

    return headerRow + rows;
  };

  // Function to convert the data to CSV format
  const convertCsvPrOBLEM = (problemData, headersProblem) => {
    // Create the header row
    const headerRow = headersProblem.join(",") + "\n";

    // Create the data rows
    const rows = problemData
      .map((obj) =>
        [obj.problem, obj.problem_id, obj.sub_problem, obj.sub_problem_id].join(
          ","
        )
      )
      .join("\n");

    return headerRow + rows;
  };

  // Function to convert the data to CSV format
  const convertCsvState = (stateCityData, headersCity) => {
    // Create the header row
    const headerRow = headersCity.join(",") + "\n";

    // Create the data rows
    const rows = stateCityData
      .map((obj) => [obj.city, obj.id, obj.state_id].join(","))
      .join("\n");

    return headerRow + rows;
  };

  // Function to trigger CSV download
  const downloadProductCsv = (csvData, filename = "Product_data.csv") => {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const downloadProblemCsv = (csvData, filename = "Problem_data.csv") => {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const downloadCityCsv = (csvData, filename = "State_city_data.csv") => {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleProductDonwload = () => {
    const csvData = convertCsvProduct(productData, headersProduct);
    downloadProductCsv(csvData);
  };

  const handleProblemDonwload = () => {
    const csvData = convertCsvPrOBLEM(problemData, headersProblem);
    downloadProblemCsv(csvData);
  };

  const handleStateDonwload = () => {
    const csvData = convertCsvState(stateCityData, headersCity);
    downloadCityCsv(csvData);
  };

  const handleDownload = () => {
    if (status == 1) {
      handleProductDonwload();
    } else if (status == 2) {
      handleProblemDonwload();
    } else {
      handleStateDonwload();
    }
  };

  return <button style={{marginRight:"10px"}} onClick={() => handleDownload()}>Download {`${status == 1 ? "product" : status==2?"problem" : "city"}`} csv</button>;
};

export default DownloadSampleCSV;
