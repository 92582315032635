import React, { useEffect, useState, useRef } from 'react'
import './AddServiceCenterComponent.css'
import { getStateList, getCityByState } from '../../actions/complaintsDataAction';
import { editServiceCenter } from '../../actions/serviceCenterActions';
import { useSelector, useDispatch } from 'react-redux';
import AccessRestricted from '../AccessRestricted';
import { useHistory } from 'react-router-dom'
import Loader from 'react-loader-spinner'

function EditServiceCenterComponent() {

  const employeeDetails = useSelector((state) => state.serviceCenter.employeeDetails.data);
  const [showLoader, setShowLoader] = useState(true)
  const history = useHistory();
  const dispatch = useDispatch();
  let btnRef = useRef();

  useEffect(() => {
    dispatch(getStateList())
    document.getElementById('root').style.background = '#F5FBFB';
    document.querySelector('body').style.background = '#F5FBFB';
    return () => {
      document.getElementById('root').style.background = '#121212';
      document.querySelector('body').style.background = '#121212';
    }
  }, [])

  useEffect(() => {
    setShowLoader(true)
    let interval = setTimeout(() => {
        if (employeeDetails === undefined) {
          history.push('/main-service-center')
        }
    }, 5000)

    return () => {
        clearInterval(interval)
    }
  }, [employeeDetails])

  const handleBackButton = () => {
    history.push('/main-service-center');
  }

  const states = useSelector((state) => state.complaints.stateData.data);
  const cities = useSelector((state) => state.complaints.cityData.data);
  let response = useSelector((state) => state.serviceCenter.editServiceCenter);
  if (response !== undefined) {
    if (response.success === true) {
      history.push('/main-service-center')
    }
    if (response.success === false) {
      btnRef.current.innerText = 'Save';
      btnRef.current.style.width = '130px';
      btnRef.current.removeAttribute("disabled");
    }
  }

  const initialValues = {
    firstName: employeeDetails && employeeDetails.first_name,
    lastName: employeeDetails && employeeDetails.last_name,
    serviceCenter: employeeDetails && employeeDetails.scAddress.name,
    serviceCenterId: employeeDetails && employeeDetails.scAddress.service_center_id,
    phone: employeeDetails && employeeDetails.mobile,
    companyEmail: employeeDetails && employeeDetails.email,
    recoveryEmail: '',
    address: employeeDetails && employeeDetails.scAddress.address,
    pincode: employeeDetails && employeeDetails.scAddress.pincode,
    stateCode: employeeDetails && employeeDetails.scAddress.state_id,
    stateName: employeeDetails && employeeDetails.scAddress.state_name,
    cityCode: employeeDetails && employeeDetails.scAddress.city_id,
    cityName: employeeDetails && employeeDetails.scAddress.city,
    userId: employeeDetails && employeeDetails.id
  }

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const handleCityChange = (e) => {
    const select = e.target;
    const desc = select.selectedOptions[0].text;
    setValues({
      ...values,
      cityCode: e.target.value,
      cityName: desc
    })
  }

  const handleValidation = (scDetails) => {
    let errors = {}
    let validity = true;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const mobileRegex = /^[6789]\d{9}$/;
    const pincodeRegex = /^[1-9]\d{5}$/;
    if (scDetails.firstName === undefined || scDetails.firstName === '') {
      errors['firstName'] = 'First name cannot be empty'
      validity = false
    } else if (scDetails.firstName.trim().length === 0) {
      errors['firstName'] = 'Please provide a valid first name'
      validity = false
    } else if ((/^[a-zA-Z]+$/).test(scDetails.firstName) === false) {
      errors['firstName'] = 'Please provide a valid first name'
      validity = false
    }
    if (scDetails.lastName === undefined || scDetails.lastName === '') {
      errors['lastName'] = 'Last name cannot be empty'
      validity = false
    } else if (scDetails.lastName.trim().length === 0) {
      errors['lastName'] = 'Please provide a valid last name'
      validity = false
    } else if ((/^[a-zA-Z]+$/).test(scDetails.lastName) === false) {
      errors['lastName'] = 'Please provide a valid last name'
      validity = false
    }
    if (scDetails.serviceCenter === undefined || scDetails.serviceCenter === '') {
      errors['serviceCenter'] = 'Service Center name cannot be empty'
      validity = false
    } else if (scDetails.serviceCenter.trim().length === 0) {
      errors['serviceCenter'] = 'Please provide a valid service center name'
      validity = false
    }
    if (scDetails.phone === undefined || scDetails.phone === '') {
      errors['phone'] = 'Phone number cannot be empty'
      validity = false;
    } else if (mobileRegex.test(scDetails.phone) === false) {
      errors['phone'] = 'Please enter valid contact number'
      validity = false
    }
    if (scDetails.companyEmail === undefined || scDetails.companyEmail === '') {
      errors['companyEmail'] = 'Company email cannot be empty'
      validity = false;
    } else if (emailRegex.test(scDetails.companyEmail) === false) {
      errors['companyEmail'] = 'Please enter valid company email'
      validity = false;
    }

    if (scDetails.address === undefined || scDetails.address === '') {
      errors['address'] = 'Address cannot be empty'
      validity = false;
    } else if (scDetails.address.trim().length === 0) {
      errors['address'] = 'Please provide a valid address'
      validity = false
    }
    if (scDetails.pincode === undefined || scDetails.pincode === '') {
      errors['pincode'] = 'Pincode cannot be empty'
      validity = false;
    } else if (pincodeRegex.test(scDetails.pincode) === false) {
      errors['pincode'] = 'Please provide a valid pincode'
      validity = false;
    }
    if (scDetails.stateCode === undefined || scDetails.stateCode === '') {
      errors['state'] = 'State cannot be empty'
      validity = false
    }
    if (scDetails.cityCode === undefined || scDetails.cityCode === '') {
      errors['city'] = 'City cannot be empty'
      validity = false;
    }
    setErrors(errors)
    return validity;
  }

  const postData = () => {
    let serviceCenterData = {
      "user_id": values.userId,
      "sc_name": values.serviceCenter,
      "sc_id": values.serviceCenterId,
      "email": values.companyEmail,
      "first_name": values.firstName,
      "last_name": values.lastName,
      "mobile": values.phone,
      "address": values.address,
      "pincode": values.pincode,
      "state_id": values.stateCode,
      "city_id": values.cityCode
    }

    dispatch(editServiceCenter(serviceCenterData))
  }

  const handleSaveBtn = () => {
    let serviceCenterDetails = {
      firstName: values.firstName,
      lastName: values.lastName,
      serviceCenter: values.serviceCenter,
      phone: values.phone,
      companyEmail: values.companyEmail,
      recoveryEmail: values.recoveryEmail,
      address: values.address,
      pincode: values.pincode,
      stateCode: values.stateCode,
      stateName: values.stateName,
      cityCode: values.cityCode,
      cityName: values.cityName
    }
    let validity = handleValidation(serviceCenterDetails);
    if (validity === true) {
      if(btnRef.current) {
        btnRef.current.setAttribute("disabled", "disabled");
        btnRef.current.innerText = 'Please Wait...';
        btnRef.current.style.width = '150px'
      }
      postData()
    }
  }

  useEffect(() => {
    let data = {
      "state_id": values.stateCode
    }
    dispatch(getCityByState(data))
  },[])

  const getCityList = (e) => {
    if(e !== undefined) {
      const select = e.target;
      const desc = select.selectedOptions[0].text;
      setValues({
        ...values,
        stateCode: e.target.value,
        stateName: desc,
        cityCode: '',
        cityName: ''
      })
    }
    let data = {
      "state_id": e.target.value || values.stateCode
    }
    dispatch(getCityByState(data))
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  let userRoleId = JSON.parse(localStorage.getItem('userRoleId'));
  if (userRoleId !== 8) {
    const style = {
      fontSize: "24px",
      color: "#000",
      textAlign: "center",
      fontWeight: "500",
      paddingTop: "5%"
    }
    return <AccessRestricted style={style} />
  }

  return (
    <React.Fragment>
      <div className='header pd headerNew'>
        <div className="backHome" onClick={handleBackButton}>
          <i className='fas fa-arrow-left'></i> Back to dashboard
        </div>
      </div>
      {!employeeDetails && showLoader ? 
        <div className='loader'>
          <Loader
          type="Oval"
          color="Grey"
          height={200}
          width={200}
          className='loaderNew'
          />
        </div>
        :
        <div className='main-container main-container-new'>
          <div className='addEmployeeForm addServiceCenterForm'>
            <h1 className='form-heading'>Edit service center</h1>
            <div className='horizontal'></div>
            <form>
              <div className='row__two'>
                <div style={{ width: '330px' }}>
                  <label htmlFor='customer name'>First Name</label>
                  <input type="text" name='firstName' value={values.firstName} onChange={handleInputChange} />
                  <div className='error_add'>
                    <span className='errorShow'>{errors.firstName}</span>
                  </div>
                </div>
                <div>
                  <label htmlFor='customer name'>Last Name</label>
                  <input type="text" name='lastName' value={values.lastName} onChange={handleInputChange} />
                  <div className='error_add'>
                    <span className='errorShow'>{errors.lastName}</span>
                  </div>
                </div>
                <div>
                  <label htmlFor='service center name'>Service Center Name</label>
                  <input type='text' name='serviceCenter' value={values.serviceCenter} onChange={handleInputChange} />
                  <div className='error_add'>
                    <span className='errorShow'>{errors.serviceCenter}</span>
                  </div>
                </div>
              </div>
              <div className='row__oneNew'>
                <div>
                  <label htmlFor='customer name'>Contact number</label>
                  <input type="tel" name='phone' value={values.phone} maxLength="10" onChange={handleInputChange} />
                  <div className='error_add'>
                    <span className='errorShow'>{errors.phone}</span>
                  </div>
                </div>
                <div style={{ width: '310px' }}>
                  <label htmlFor="contact number">Company email ID</label>
                  <input type='email' className='addEmployeeInput' id='companyEmailInput' name='companyEmail'
                    value={values.companyEmail}
                    onChange={handleInputChange}
                    placeholder='@noise.com'
                  />
                  <div className='error_add'>
                    <span className='errorShow'>{errors.companyEmail}</span>
                  </div>
                </div>
                <div style={{ width: '370px' }}>
                  <label htmlFor='customer email'>Recovery email ID</label>
                  <input type='email' className='addEmployeeInput' name='recoveryEmail' value={values.recoveryEmail}
                    onChange={handleInputChange}
                    placeholder='admin@noise.com'
                  />
                  <div className='error_add'>
                    <span className='errorShow'>{errors.recoveryEmail}</span>
                  </div>
                </div>
              </div>
              <div className='horizontal'></div>
              <div className='row__two'>
                <div>
                  <label htmlFor='customer address line 1'>Address</label>
                  <input type='text' name='address' value={values.address} onChange={handleInputChange} />
                  <div className='error_add'>
                    <span className='errorShow'>{errors.address}</span>
                  </div>
                </div>
              </div>
              <div className='row__threeNew'>
                <div>
                  <label htmlFor='pincode'>Pincode</label>
                  <input type='text' name='pincode' value={values.pincode} maxLength="6" onChange={handleInputChange} required={true} />
                  <div className='error_add'>
                    <span className='errorShow'>{errors.pincode}</span>
                  </div>
                </div>
                <div style={{ width: '310px' }}>
                  <label htmlFor='state'>State</label>
                  <select name='states' value={values.stateCode} onChange={getCityList}>
                    <option value=''>Select State</option>
                    {
                      states && states.map((state, index) => {
                        return <option key={state.id} value={state.id} text={state.state_name}>{state.state_name}</option>
                      })
                    }
                  </select>
                  <div className='error_add'>
                    <span className='errorShow'>{errors.state}</span>
                  </div>
                </div>
                <div style={{ width: '370px' }}>
                  <label htmlFor='city'>City</label>
                  <select name='cities' value={values.cityCode} onChange={handleCityChange}>
                    <option value=''>Select City</option>
                    {
                      cities && cities.map((city, index) => {
                        return <option key={city.id} value={city.id}>{city.city}</option>
                      })
                    }
                  </select>
                  <div className='error_add'>
                    <span className='errorShow'>{errors.city}</span>
                  </div>
                </div>
              </div>
            </form>
            <div className='horizontal'></div>
            <button style={{ float: "right" }} className='navBtn' id='addNewServiceCenterBtn' onClick={handleSaveBtn} ref={btnRef}>Save</button>
          </div>
        </div>
      }
    </React.Fragment>
  )
}

export default EditServiceCenterComponent;