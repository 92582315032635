import {
    FETCH_DASHBOARD_ALL,FETCH_DASHBOARD_COURIER,FETCH_DASHBOARD_PROBLEM
  } from '../actions/types';
  
  const initialState = {
    dashboardData: {},
    dashboardDataCourier:{},
    dashboardDataProblem:{}
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case FETCH_DASHBOARD_ALL:
        return {
          ...state,
          dashboardData: action.payload,
        };
        case FETCH_DASHBOARD_COURIER:
          return {
            ...state,
            dashboardDataCourier: action.payload,
          };
          case FETCH_DASHBOARD_PROBLEM:
            return {
              ...state,
              dashboardDataProblem: action.payload,
            };
      default:
        return state;
    }
  }
  