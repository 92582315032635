import axios from "axios";
import {
  FETCH_PRODUCT_DATA,
  FETCH_GROUP_DATA,
  FETCH_MESSAGE_DATA,
  GET_PRODUCT_DETAIL,
  GET_USER_DETAIL,
  SET_PRODUCT_DATA,
  EDIT_PRODUCT_DATA,
  SET_PRODUCTGROUP_DATA,
  EDIT_MESSAGE_DATA,
  FETCH_COLOR_DATA,
  FETCH_PRODUCT_NAME_DATA,
  FETCH_PRODUCT_BY_CATEGORY,
  SET_EDIT_PRODUCT_DATA
} from "./types";
import { baseUrl, webBaseUrl } from "../config";
import history from "../history";
// import moment from 'moment'
import 'url-search-params-polyfill';
// var config = require('../../../server/rms/rms-service-products/config/config')(process.env.NODE_ENV);

export const getProductData = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/product/get", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      dispatch({
        type: FETCH_PRODUCT_DATA,
        payload: res.data,
      });
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const setNewProduct = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  // return await axios.post(baseUrl+"/product/add-new-product", data, {
  return await axios.post(webBaseUrl + "/connect-product/v1/product/add-product", data, {

      headers: { "x-access-token": token },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
      return e.response.data;
    });
};

export const addnewVariant = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  // return await axios.post(baseUrl + "/product/add-new-variant", data, {
  return await axios.post(webBaseUrl + "/connect-product/v1/product/add-variant", data, {

      headers: { "x-access-token": token },
    })
    .then((res) => {      
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
      return e.response.data;

    });
};

export const assignUserTicket = (data) => async (dispatch) => {
  return await axios
    .post(baseUrl + "/user/list-a", data, { headers: {} })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: FETCH_PRODUCT_DATA,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getProductDetail = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/product/get", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_PRODUCT_DETAIL,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const createProduct = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/product/upsert", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      alert(res.data.message);
      var link = document.getElementById("product_close_icon_id_add");
      link.click();
      if (res.data.success) {
        dispatch({
          type: SET_PRODUCT_DATA,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
      if (e.response.status === 429) {
        alert(e.response.statusText || "Something went wrong");
      }
    });
};

export const editProductData = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/product/upsert", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: EDIT_PRODUCT_DATA,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getGroupData = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/product/getproductCategories", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: FETCH_GROUP_DATA,
          payload: res.data,
        });
        return res.data
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const fetchAllProductsByCategoryId = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  const params = new URLSearchParams(data);
  return await axios
    .get(
      baseUrl + "/product/fetch-all-products?" + params.toString(),
      { headers: { "x-access-token": token } }
    )
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: FETCH_PRODUCT_BY_CATEGORY,
          payload: res.data.data,
        });
      } else {
        alert(res.data.message);
      }
    })
    .catch((e) => {
      history.push("/");
    });
};

export const getProductNameData = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/product/get", data, {
      headers: { api_key: "d8f021fd65e25982003ddce3cac2225e" },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: FETCH_PRODUCT_NAME_DATA,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getProductColorData = (data) => async (dispatch) => {
  return await axios
    .post(baseUrl + "/product/color-list", data, {
      headers: { api_key: "d8f021fd65e25982003ddce3cac2225e" },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: FETCH_COLOR_DATA,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const createProductGroup = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/product/upsert-category", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      alert(res.data.message);
      var link = document.getElementById("product_close_id");
      link.click();
      if (res.data.success) {
        dispatch({
          type: SET_PRODUCTGROUP_DATA,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getMessageData = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/message/get", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      dispatch({
        type: FETCH_MESSAGE_DATA,
        payload: res.data,
      });
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getMessageDetail = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/user/get/2", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_USER_DETAIL,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getGroupDetail = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/user/get/2", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_USER_DETAIL,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const editMessageData = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/message/upsert", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      alert(res.data.message);
      if (res.data.success) {
        dispatch({
          type: EDIT_MESSAGE_DATA,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
      if (e.response.status === 429) {
        alert(e.response.statusText || "Something went wrong");
      }
    });
};

export const removeMessageData = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  let id = data.message_id;
  return await axios
    .get(baseUrl + "/message/remove/" + id, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const removeGroupData = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  let id = data.group_id;

  return await axios
    .get(baseUrl + "/product/remove-category/" + id, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const removeProductinfo = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");

  return await axios
    .get(baseUrl + "/product/remove/" + data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const addNewColor = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    // .post(baseUrl + "/product/create-new-color" ,data, {
    .post(webBaseUrl + "/connect-product/v1/add-new-color", data, {

      headers: { "x-access-token": token },
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
      return e.response.data;

    });
};
export const csvUploadAction = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");

  return await axios
    .post(baseUrl + "/complaint/csv-upload" ,data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const setProductID = (data) => async (dispatch) => {
  dispatch({
    type: SET_EDIT_PRODUCT_DATA,
    payload: data,
  });
};

export const addChannels = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");

  return await axios
    .post(baseUrl + "/complaint/add-channels" ,data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });

};

export const updateChannels = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");

  return await axios
    .post(baseUrl + "/complaint/update-channels" ,data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const updateVariant = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    // .post(baseUrl + "/product/update-variant" ,data, {
    .post(webBaseUrl + "/connect-product/v1/product/update-variant", data, {

      headers: { "x-access-token": token },
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

