import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  ServiceCenterComplaintData, challanAction, setComplaintsArray, sendChallanAction
} from '../../actions/serviceCenterActions';
import moment from 'moment'
// import "./ServiceCenter.css"
import Modal from 'react-modal';
import Loader from 'react-loader-spinner';
import { fetchComplaintDetails,TroubleShootShow } from '../../actions/serviceCenterComplaintAction'
import { useHistory } from 'react-router-dom';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
    background: "#FFFFFF",
    height: "auto",
    border: " 1px solid rgba(38, 37, 44, 0.1)",
    "box-shadow": "0px 9.46px 23.65px rgb(233 238 242 / 40%)",
    "border-radius": " 8px",
  },
};


function ReplacementComplaints() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedTicketsCount, setSelectedTicketsCount] = useState(0);
  const [showLoader, setShowLoader] = useState(true)
  
  const setActiveTab = (item1, item2) => {
    setValues({
      ...values, tab: item1, ticketStatus:item2,current_page:1,
       
    })
 if(serviceComplaints.length>0){
  if(item1 == 'tab1' || item1 == 'tab3') {
      document.getElementById('selectBtnWrapper').style.display = 'none';
      document.getElementById('selectOption').style.display = 'inline';
    } else {
      document.getElementById('selectBtnWrapper').style.display = 'none';
      document.getElementById('selectOption').style.display = 'none';
    }}

  }

  const initialValues = {
    totalPage: 1,
    totalCount: 0,
    prevPage: false,
    nextPage: false,
    current_page: 1,
    pageLimit: 12,
    searchTextLength: 0,
    searchText: '',
    serviceCenterID: '',
    name: '',
    Challandate: '',
    box: '',
    awb_no: '',
    challanNumber: "",
    ticketStatus: 'pending_replacement',
    startDate: new Date().setDate(new Date().getDate() - 90),
    endDate: new Date().setDate(new Date().getDate()),
    tab: "tab1",
    checkValue: true,
    ticket_id: [],

  };

  const [errors, setErrors] = useState(initialValues);
  const [values, setValues] = useState(initialValues);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const handleValidation = (customerDetails) => {

    let errors = {}
    let validity = true;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const mobileRegex = /^[6789]\d{9}$/;
    const pincodeRegex = /^[1-9]\d{5}$/;
    if (customerDetails.courier_name === undefined || customerDetails.courier_name === '') {
      errors['name'] = 'Name cannot be empty'
      validity = false
    } else if (customerDetails.courier_name.trim().length === 0) {
      errors['name'] = 'Please provide a valid name'
      validity = false
    } else if ((/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/).test(customerDetails.courier_name) === false) {
      errors['name'] = 'Please provide a valid name'
      validity = false
    }
    if (customerDetails.awb_no === undefined || customerDetails.awb_no === '') {
      errors['awb_no'] = 'AWB cannot be empty'
      validity = false;
    }
    if (customerDetails.no_of_boxes === undefined || customerDetails.no_of_boxes === '') {
      errors['box'] = 'Box Number cannot be empty'
      validity = false;
    } var pattern = new RegExp(/[^0-9]/);
    if (pattern.test(customerDetails.no_of_boxes)) {
      errors["box"] = "Quantity can only be number"
      validity = false;

    }
    setErrors(errors);
    return validity;
  }

  //   useEffect(() => {
  //     let scAddress =localStorage.getItem('scAddress');
  //     let serviceAdress=JSON.parse(scAddress)
  //     let data={  
  //       "ticketStatus": "replace",
  //       "limit": 10,
  //       "offset": 0,
  //       "service_center_id": serviceAdress.service_center_id,
  //       "page_no": 1,
  //        "startDate": moment(values.startDate).format('YYYY-MM-DD'),
  //       "endDate": moment(values.endDate).format('YYYY-MM-DD'),
  //     }
  //   dispatch(ServiceCenterComplaintData(data))
  //  },[])


  useEffect(() => {
    // setShowLoader(true)
    getComplaintList()
  }, [values.current_page, values.tab, modalIsOpen]);

  // useEffect(()=>{
  //   dispatch(challanAction())
  // },[modalIsOpen])

  const getComplaintList = async () => {
    let scAddress = localStorage.getItem('scAddress');
    let serviceAdress = JSON.parse(scAddress)
    let data=[]
    if(values.ticketStatus==="pending_replacement"){
      data = {
        "limit": 12,
        "page_no": values.current_page,
        "endDate": moment(values.endDate).format("YYYY-MM-DD"),
        "startDate": moment(values.startDate).format("YYYY-MM-DD"),
        "ticketStatus": values.ticketStatus,
        "tag":"panel",
        "service_center_id": serviceAdress.service_center_id,
      }
    }else{
            data = {
              "limit": 12,
              "page_no": values.current_page,
              "endDate": moment(values.endDate).format("YYYY-MM-DD"),
              "startDate": moment(values.startDate).format("YYYY-MM-DD"),
              "ticketStatus": values.ticketStatus,
              "tag":"panel",
            }}
   
    setisLoading(true)
    await dispatch(ServiceCenterComplaintData(data))
    setisLoading(false)
    if(values.ticketStatus==="pending_replacement"||values.ticketStatus==="received_inventory"){
      document.getElementById('selectOption').style.display = 'inline';
      document.getElementById('selectBtnWrapper').style.display = 'none';
      setSelectedTicketsCount(0)
     
      }
    setValues({
      ...values,
      totalCount: serviceData.total,
      totalPage: serviceData ? serviceData.no_page : 0,
      prevPage: serviceData ? serviceData.prev_page : 0,
      nextPage: serviceData ? serviceData.next_page : 1,
    })
  }

  const handleTicketClick = (e, item) => {
    dispatch(fetchComplaintDetails(item.id));
    dispatch(TroubleShootShow(true))
    history.push('/complaint-details')
  }


  const onPrevPress = () => {
    if (serviceData.prev_page) {
      setValues({ ...values, current_page: (values.current_page - 1) }, async () => {
       getComplaintList()
       dispatch(setComplaintsArray([]))
      })
    }
  }

  const onNextPress = () => {
    if (serviceData.next_page) {
      setValues({ ...values, current_page: (values.current_page + 1) }, async () => {
        getComplaintList()
        dispatch(setComplaintsArray([]))
      })
    }
  }

  const paginationCount = useSelector((state) => state.serviceCenter.paginationCount)
  const complaintsArray = useSelector((state) => state.serviceCenter.complaintsArray)
  const serviceData = useSelector((state) => state.serviceCenter.serviceNewData)
  const serviceComplaints = serviceData && serviceData.complaint_data
  let new_page = ((serviceData && serviceData.no_page - (serviceData && serviceData.no_page - values.current_page)) - 1);
  const challanData = useSelector((state) => state.serviceCenter.challanData)

  const handleSelectBtn = () => {
    document.getElementById('selectBtnWrapper').style.display = 'block';
    document.getElementById('selectOption').style.display = 'none';
    document.querySelectorAll('.selectTicketCheckBox').forEach((checkbox) => {
      checkbox.style.display = 'inline'
    });
  }

  const handleSelectAllBtn = () => {
    document.querySelectorAll('.selectTicketCheckBox').forEach((checkbox) => checkbox.checked = "true")
    setSelectedTicketsCount(document.querySelectorAll('.selectTicketCheckBox:checked').length);
    let complaintArray = serviceComplaints.map((item) => {
      return item.ticket_id.split(' ');
    })
    let newArray = complaintArray.map((item) => {
      return item[0];
    })
    dispatch(setComplaintsArray(newArray))

  }



  const handleCloseBtn = () => {
    document.getElementById('selectOption').style.display = 'inline';
    document.getElementById('selectBtnWrapper').style.display = 'none';
    document.querySelectorAll('.selectTicketCheckBox:checked').forEach((checkbox) => {
      checkbox.checked = false
    })
    document.querySelectorAll('.selectTicketCheckBox').forEach((checkbox) => {
      checkbox.style.display = 'none';
    });
    setSelectedTicketsCount(0)
  }

  const handleSelectCheckbox = (e, data) => {
    setSelectedTicketsCount(document.querySelectorAll('.selectTicketCheckBox:checked').length);
    let ticketID = data.ticket_id.split(" ");
    let newArray = complaintsArray;
    let index = complaintsArray.indexOf(ticketID[0]);
    if (index > -1) {
      newArray.splice(index, 1)
    } else {
      newArray.push(ticketID[0])
    }
    dispatch(setComplaintsArray(newArray))
  }



  function openModal() {
  setValues({...values,name:'',awb_no:'',box:'',})
   if (selectedTicketsCount === 0) {
      alert("Please Select Atleast One Ticekt")
    } else {
      dispatch(challanAction())
      setIsOpen(true);
    }
  }

  function closeModal() {
    setValues({
      ...values, box: "", awb_no: '', name: '',
    })
    setErrors('')
    setIsOpen(false);
    document.getElementById('selectOption').style.display = 'inline';
    document.getElementById('selectBtnWrapper').style.display = 'none';
    document.querySelectorAll('.selectTicketCheckBox:checked').forEach((checkbox) => {
      checkbox.checked = false
    })
    document.querySelectorAll('.selectTicketCheckBox').forEach((checkbox) => {
      checkbox.style.display = 'none';
    });
    setSelectedTicketsCount(0)

  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleContinueBtn = async () => {
    let userEmail = localStorage.getItem('userEmail')
    let customerDetails = {
      "ticketNumbers": complaintsArray,
      "sender_email": userEmail,
      "challan_no": challanData.challan_no,
      "challan_date": challanData.date,
      "courier_name": (values.name||"").trim(),
      "awb_no": values.awb_no,
      "no_of_boxes": values.box,
      
    }
    if (handleValidation(customerDetails) == true) {
      setisLoading(true)
      await dispatch(sendChallanAction(customerDetails))
      setIsOpen(false);
      setisLoading(false)
      document.getElementById('selectOption').style.display = 'inline';
      document.getElementById('selectBtnWrapper').style.display = 'none';
      setSelectedTicketsCount(0)
      dispatch(setComplaintsArray([]))
      
    };
  }
 


  return (
    <>
      <div className='activeComplaints mainContent'>
        <h1 className='heading'>Replacement</h1>
        <div className='inventBTn'>
          <div className='flexEnd'>
            <ul className='replacementTabs'>
              <li className={values.tab === 'tab1' ? 'activeTab' : 'unselctActive'} onClick={() => setActiveTab("tab1", "pending_replacement")}>Pending Tickets</li>
              <li className={values.tab === 'tab2' ? 'activeTab' : 'unselctActive'} onClick={() => setActiveTab("tab2", "sent_to_ms")}>Sent Inventory</li>
              <li className={values.tab === 'tab3' ? 'activeTab' : 'unselctActive'} onClick={() => setActiveTab("tab3", "failed_from_ms")}>failed Recieved</li>
            </ul>


          </div>
          
        { 
        serviceComplaints.length>0 && <div>
            <button className='selectBtn' id='selectOption' onClick={handleSelectBtn}>Select</button>
            {
              <div id='selectBtnWrapper'>
                <button className='selectBtn selectBtnTwo' onClick={handleSelectAllBtn}>Select All</button>
                <button className='selectBtn' onClick={handleCloseBtn}>Close</button>
              </div>}
          </div>}
          </div>
          {
            isLoading ?
            <div className='loader'>
              <Loader
                type="Oval"
                color="Grey"
                height={200}
                width={200}
                className='loaderNew'
              />
            </div> :<div>
        <div className='complaits_container'>
          { 
            serviceComplaints && serviceComplaints.map((item, index) => {
              return (
                <div key={item.id} className="ticket-wrapper" >
                  <input type='checkbox' className='selectTicketCheckBox' onClick={(e) => { handleSelectCheckbox(e, item) }} />
                  <span id='header1'>Complaint No. {item.ticket_id}
                    {/* { values.tab=="tab2"?<i style={{marginLeft: "12px"}}className="fa fa-cross" />:null} */}
                  </span>
                  <p id='insideline'></p>
                  <div className='product_details' onClick={e => handleTicketClick(e, item)}>
                    <p className='products_info' >
                      <span className='productname'>Product Name: </span>
                      <span id='nm1'>{item.product_name}</span>
                    </p>
                    <p className='products_info' >
                      <span className='productname'>Status: </span>
                      <span id='nm1'>{item.complaint_status_name}</span>
                    </p>
                    <p className='products_info' >
                      <span className='productname'>Date: </span>
                      <span id='nm1'>{moment(item.updated_at).format('DD-MM-YYYY')} </span>
                    </p>
                  </div>
                </div>)

            })
          }
        </div>
        <div className="footers">
          <div className="pages1">
            {serviceData && serviceData.total > 0 ?
              <div>
                {serviceData && serviceData.no_page === 1 ?
                  <p style={{ color: 'black', fontWeight: "500" }}>
                    1 - {serviceData && serviceData.total} of {serviceData && serviceData.total} results
                  </p>
                  :
                  <p>
                    {values.pageLimit * new_page > 1 ?
                      values.pageLimit * new_page
                      :
                      '1'
                    }
                    -
                    {((values.pageLimit * values.current_page) > (serviceData && serviceData.total)) && ((serviceData && serviceData.total) > (values.pageLimit * new_page)) ?
                      (serviceData && serviceData.total)
                      :
                      (values.pageLimit * values.current_page)} of {serviceData && serviceData.total} results
                    <i className="fa fa-chevron-left" style={{ color: "black" }} onClick={onPrevPress} />
                    <span>{values.current_page}</span>
                    <i className="fa fa-chevron-right" style={{ color: "black" }} onClick={onNextPress} />
                  </p>
                }
              </div>
              : <div className="Noreult">
                <span>No complaints found</span>
              </div>
            }
          </div>
        </div></div>}
      </div>
      {
        (values.tab == "tab1" || values.tab == "tab3") && (serviceComplaints.length > 0) ?
          <div className='actionsWrapper'>
            <span>Selected: {selectedTicketsCount}</span>
            <button onClick={openModal}>Send</button>
          </div> : null
      }


      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="challan">
          <div className="header">Challan
            <button id="closeModalREP" onClick={closeModal} ><i className="fas fa-times"></i></button></div>
          {/* <button id="close_rModal"><i className="fas fa-times"></i></button> */}
          <div className="challanInfo">
            <div>
              <label className='cnNUmber' for="challan NUmber">Delivery Challan Number : <input type="" value={challanData.challan_no} disabled
              /></label>
            
            </div>
            <div>
              <label for="challan NUmber">Delivery Challan Date:
                <input value={challanData.date} disabled></input>
              </label>
              {/* <div className='error_add'>
              <span className='errorShow'style={{"float":"right",marginRight:"155px"}}>{errors.Challandate}</span>
            </div> */}
            </div>
            <div>
              <label for="challan NUmber">courier Name: <input type="text" name='name' value={values.name} onChange={handleInputChange}
              /></label>
              <div className='error_add'>
                <span className='errorShow' style={{ "float": "right", marginRight: "150px" }}>{errors.name}</span>
              </div>
            </div>
            <div>
              <label for="challan NUmber">Courier AWB Number  <input type="text" name='awb_no' value={values.awb_no } onChange={handleInputChange}
              /></label>
              <div className='error_add'>
                <span className='errorShow' style={{ "float": "right", marginRight: "155px" }}>{errors.awb_no}</span>
              </div>
            </div>
            <div>
              <label for="challan NUmber">Number Of Box: <input type="text" name='box' value={values.box } onChange={handleInputChange}
              /></label>
              <div className='error_add'>
                <span className='errorShow' style={{ "float": "right", marginRight: "115px" }}>{errors.box}</span>
              </div>
            </div>
          </div>
          <button id="sendButton" onClick={handleContinueBtn}>Send</button>
        </div>
      </Modal>

    </>


  )
}

export default ReplacementComplaints