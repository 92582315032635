import React, { Component } from 'react';
import './App.css';
import history from './history';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import LoginComponent from './components/LoginComponent/LoginComponent';
import UserComponent from './components/UserComponent/UserComponent';
import ProductComponent from './components/ProductComponent/ProductComponent';
import GroupComponent from './components/ProductComponent/GroupComponent';
import MessageComponent from './components/ProductComponent/MessageComponent';
import AssignComponent from './components/UserComponent/AssignComponent';
import ReadOnly from './components/UserComponent/ReadOnly';
import ReportComponent from './components/ReportComponent/ReportComponent';
import CourierComponent from './components/ReportComponent/CourierComponent';
import LeaderboardComponent from './components/ReportComponent/LeaderboardComponent';
import CreatePasswordComponent from './components/LoginComponent/CreatePasswordComponent';
import ComplaintsComponent from './components/ComplaintsComponent/ComplaintsComponent';
import DashboardComponent from './components/DashboardComponent/DashboardComponent';
import UserProfileComponent from './components/UserProfileComponent/UserProfileComponent';
// import ComplaintsDetails from './components/ComplaintsComponent/ComplaintsDetails';
import BulkmovementComponent from './components/BulkmovementComponent/BulkmovementComponent';
import Settings from './components/Settings/Settings';
import ColorComponent from './components/ProductComponent/ColorComponent';
import ComponentToRPUPrint from './components/ComplaintsComponent/ComponentToRPUPrint';
import ComponentToFPUPrint from './components/ComplaintsComponent/ComponentToFPUPrint';
import ComponentToPrint from './components/ComplaintsComponent/ComponentToPrint';
import AccessRestricted from './components/AccessRestricted';
import VerificationLink from './components/LoginComponent/VerificationLink';
import ComplaintRegistration from './components/ComplaintRegistration';
import RTLComplaintComponent from './components/RTLComplaintComponent/RTLComplaintComponent';
import BoseComplaintsComponent from './components/BoseComplaintComponent/BoseComplaintsComponent';
import RefundGCComponent from './components/RefundComponent/RefundGCComponent';
import Warranty from './components/Warranty/Warranty';
import ServiceCenterComplaint from './components/ServiceCenterComplaint/ServiceCenterComplaint';
import notify from './components/Warranty/notify';
import ServiceCenterComplaintRegistration from './components/ServiceCenterComplaintRegistrationComponent';
import AddServiceCenterComponent from './components/AddServiceCenterComponent/AddServiceCenterComponent';
import AddEmployeeComponent from './components/AddEmployeeComponent/AddEmployeeComponent';
import EditEmployeeComponent from './components/AddServiceCenterComponent/EditEmployeeComponent';
import EditServiceCenterComponent from './components/AddServiceCenterComponent/EditServiceCenterComponent';
import SubServiceCenter from './components/SubServiceCenter/SubServiceCenter';
import ComplaintDetailsComponent from './components/ComplaintDetailsComponent/ComplaintDetailsComponent'
import MainServiceCenter from './components/ServiceCenter/ServiceCenter';
import SCComponent from './components/ServiceCenterComponent/SCComponent';
import ServiceCenter from './components/Settings/ServiceCenter';
import AddColorComponent from './components/ProductComponent/AddColorComponent';
import BulkScComplaints from './components/BulkScComplaints/BulkScComplaints';
// import BulkDispatch from './components/ServiceCenterComponent/BulkDispatchInwa'
import ChannelsComponent from './components/ProductComponent/ChannelsComponent';
import InternationalWarranty from './components/Warranty/InternationalWarranty';


class App extends Component {
  async componentDidMount() {
    // await this.props.saveCatalogue();
  }

  render() {
    const App = () => (
      <Router history={history}>
        <div> 
          {/* <Route exact path='/dashboard' component={History} /> */}
          {/* <Route exact path='/dashboard' component={Comments} /> */}
          {/* <Route exact path='/dashboard' component={Conversations} /> */}
          {/* <Route exact path='/dashboard' component={CustomerDetails} /> */}
          {/* <Route exact path='/dashboard' component={Product} /> */}
          {/* <Route exact path='/dashboard' component={Attachment} /> */}
          <Switch>
            <Route exact path='/' component={LoginComponent} />
            <Route exact path='/not-found' component={AccessRestricted} />
            <Route exact path='/read-only-user' component={ReadOnly} />
            <Route exact path='/complaint-registration' component={ComplaintRegistration} />
            <Route exact path='/dashboard' component={DashboardComponent} />
            <Route exact path='/manage-user' component={UserComponent} />
            <Route exact path='/assign-ticket' component={AssignComponent} />
            <Route exact path='/manage-product' component={ProductComponent} />
            <Route exact path='/manage-group' component={GroupComponent} />
            <Route exact path='/manage-message' component={MessageComponent} />
            <Route exact path='/create-password' component={CreatePasswordComponent} />
            {/* <Route exact path='/complaint-detail' component={ComplaintsDetails} /> */}
            <Route exact path='/complaints-data' component={ComplaintsComponent} />
            <Route exact path='/bose-complaints-data' component={BoseComplaintsComponent} />
            <Route exact path='/user-profile' component={UserProfileComponent} />
            <Route exact path='/reports' component={ReportComponent} />
            <Route exact path='/courier' component={CourierComponent} />
            <Route exact path='/leaderboard' component={LeaderboardComponent} />
            <Route exact path='/bulk_movement' component={BulkmovementComponent} />
            <Route exact path='/settings' component={Settings} />
            <Route exact path='/service-center' component={ServiceCenter} />
            <Route exact path='/rpu-print' component={ComponentToRPUPrint} />
            <Route exact path='/fpu-print' component={ComponentToFPUPrint} />
            <Route exact path='/screening' component={ComponentToPrint} />
            <Route path='/verification-link/:hashId' component={VerificationLink} />
            <Route exact path='/rtl-data' component={RTLComplaintComponent} />
            <Route exact path='/refund' component={RefundGCComponent} />
            <Route exact path='/warranty-data' component={Warranty} />
            <Route exact path='/service-center-complaint-registration' component={ServiceCenterComplaint} />
            <Route exact path='/notify' component={notify} />
            <Route exact path='/complaint-registration-template' component={ServiceCenterComplaintRegistration} />
            <Route exact path='/add-service-center' component={AddServiceCenterComponent} />
            <Route exact path='/add-employee' component={AddEmployeeComponent} />
            <Route exact path='/edit-employee' component={EditEmployeeComponent} />
            <Route exact path='/edit-service-center' component={EditServiceCenterComponent} />
            <Route exact path='/sub-service-center' component={SubServiceCenter} />
            <Route exact path='/complaint-details' component={ComplaintDetailsComponent} />
            <Route exact path='/main-service-center' component = {MainServiceCenter} />
            <Route exact path='/sc-complaints' component={SCComponent} />
            <Route exact path="/add-product" component={AddColorComponent} />
            <Route exact path="/bulk-sc-complaints" component={BulkScComplaints} />
            <Route exact path="/colors" component={ColorComponent} />
            <Route exact path="/channels" component={ChannelsComponent} />
            <Route exact path="/internation-warranty" component={InternationalWarranty} />
            {/* <Route exact path='/bulkDispatch' component={BulkDispatch}/> */}
          </Switch>
          {/* <Route exact path='/order-admin' component={OrderAdmin} />
            <Route exact path='/order-tracking' component={OrderTracking} />
            <Route exact path='/order-scm' component={OrderSCM} />
            <Route exact path='/form-scm' component={FormSCM} /> */}
        </div>
      </Router>
    );

    return (
      <Switch>
        <App />
      </Switch>
    )
  }
}

export default connect(null, {})(App);