import React, { Component } from 'react'
import '../Settings/Settings.css';
import { connect } from "react-redux";
import './Warranty.css';
import Select from 'react-select';
// import history from '../../history';
import Loader from 'react-loader-spinner'
import { getUserDetail } from '../../actions/userActions'
// import ReactModal from 'react-modal';
import ViewProfile from '../ViewProfile'
import AccessRestricted from '../AccessRestricted'
import { getwarrantyInternational, getProductList } from '../../actions/warrantyDataAction';
import moment from 'moment';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';
import Footer from'../FooterComponent/footer';
import SidebarComponent from '../SidebarComponent/SidebarComponent';

const {
    allowedRange
} = DateRangePicker;

class InternationalWarranty extends Component {

    constructor() {
        super();
        this.state = {
            accessLevel: '',
            productList: [],
            pageLimit: 10,
            loader: false,
            totalcount: '',
            isLoading: false,
            prevPage: false,
            nextPage: false,
            errors: [],
            showChangePassword: false,//it's use
            dropDownOpen: false,//this one
            current_page: 1,
            selectedProduct: [],
            totalPage: '',
            productArrays: [],
            channelArrays: [],
            warrantyStartDate: new Date().setDate(new Date().getDate() - 45),
            warrantyEndDate: new Date()
        }
        this.getComplaintList = this.getComplaintList.bind(this);
        this.getproductslist = this.getproductslist.bind(this);
        // this.handleSelectAll = this.handleSelectAll.bind(this)
        this.onProductChange = this.onProductChange.bind(this);
    }

    componentDidMount() {
        this.getUserInfo();
        this.getComplaintList();
        this.getproductslist();
    }

    getComplaintList = async () => {
        this.setState({ isLoading: true })
        let offset = (this.state.current_page - 1);
        let data = {
            "limit": 10,
            "offset": offset
        }
        if (this.state.productArrays.length > 0) {
            data.product_ids = this.state.productArrays;
        }
        if (this.state.channelArrays.length > 0) {
            data.channel_ids = this.state.channelArrays;
        }
        if (this.state.warrantyStartDate && this.state.warrantyEndDate) {
            data.startDate = moment(this.state.warrantyStartDate).format('YYYY-MM-DD');
            data.endDate = moment(this.state.warrantyEndDate).format('YYYY-MM-DD');
        }
        await this.props.getwarrantyInternational(data);
        if (this.props.warrantyDataInter.data?.length > 0) {

            let insideWarrantyData = this.props.warrantyDataInter;
            this.setState({
                isLoading: false,
                totalCount: insideWarrantyData.total,
                totalPage: insideWarrantyData ? insideWarrantyData.no_page : 0,
                prevPage: insideWarrantyData ? insideWarrantyData.prev_page : 0,
                nextPage: insideWarrantyData ? insideWarrantyData.next_page : 1,
            })
        } else {
            this.setState({
                isLoading: false
            })
            // alert("No result found!")
        }
    }


    getproductslist = async () => {
        await this.props.getProductList({});
        this.setState({
            productList: this.props.productData.data ? this.props.productData.data : []
        })
    }

    onChannelList = (selectedChannels) => {
        let channel_ids = selectedChannels.map((element) => {
            return element.value;
        })
        this.setState({ channelArrays: channel_ids }, () => {
            this.getComplaintList();
        })
    }

    onProductChange = async (a) => {
        let product_color_ids = a.map((element) => {
            return element.value;
        })
        this.setState({ productArrays: product_color_ids }, () => {
            this.getComplaintList();
        })
    }

    getUserInfo = async () => {
        let userId = localStorage.getItem('userId');
        let data = {
            "user_id": userId,
        }
        await this.props.getUserDetail(data)
        let editRes = this.props.userDetail.data;
        let access_level = editRes.user_role[0] ? editRes.user_role[0].name : 'No Role assigned';
        this.setState({ accessLevel: access_level })
    }

    onPrevPress = () => {
        if (this.state.prevPage) {
            this.setState({ current_page: (this.state.current_page - 1) }, () => {
                this.getComplaintList()
            })
        }
    }

    onNextPress = () => {
        if (this.state.nextPage) {
            this.setState({ current_page: (this.state.current_page + 1) }, () => {
                this.getComplaintList();
            })
        }
    }

    warrantyReportSelectDate = async (value) => {

        this.setState({
            warrantyStartDate: new Date(value[0]),
            warrantyEndDate: new Date(value[1]),
        }, () => {
            this.getComplaintList();
        })
    }

    dateSelect = async (value) => {
        this.setState({
            isLoading: true,
            startDate: new Date(value[0]),
            endDate: new Date(value[1]),
            reportDate: value
        })
        let dataLeader = {
            "team": this.state.selectValue.toUpperCase(),
            "startDate": moment(new Date(value[0])).format('YYYY-MM-DD'),
            "endDate": moment(new Date(value[1])).format('YYYY-MM-DD')
        }
        await this.props.fetchLeaderboardAllChange(dataLeader);
        this.setState({
            isLoading: false,
            leaderBoard: this.props.leaderboardDataChange,
            lederBoardDropDown: this.props.leaderboardDataChange.dropDownMenu,
            summaryBlock: this.props.leaderboardDataChange.summaryBlock,
            summaryBlockTable: this.props.leaderboardDataChange.summaryBlock.month,
            summaryBlockData: this.props.leaderboardDataChange.summaryBlock,
            tableHeading: this.props.leaderboardDataChange.tableHeading,
            teams: this.props.leaderboardDataChange.teams,
            grandTotal: this.props.leaderboardDataChange.grandTotal,
            selectValue: this.state.selectValue
        })
    }

    render() {
        let optionBucketDrop = [
            {
                value: 0,
                label: "Select All",
            }
        ]
        
            {
                this.state.productList.map((obj) => (
                    optionBucketDrop.push(
                        {
                            // label: obj.name,
                            value: obj.product_id,
                            label: obj.product_name,
                            // id: obj.id
                        }
                    )
                ))
            }
        
        let channelList = [
            {
                value: 1,
                label: "Offline",
            },
            {
                value: 2,
                label: "Online",
            },
            {
                value: 3,
                label: "Others",
            },
        ];
       
        let new_page = (this.state.current_page - 1);
        let userRoleId = localStorage.getItem('userRoleId');
        if (['4', '5', '6', '8', '9', '10'].includes(userRoleId)){
            return (<AccessRestricted />)
        } else {
            return (
                <div>
                    <div>
                   { window.innerWidth > 600 && <SidebarComponent sideProp={'WD'} /> }
                        <ViewProfile />
                    </div>
                    <div className="content manage-user-main">
                        <div className="menuBar">
                            <span className="complaint-head">Customer</span>
                            <ul id="menu">
                                <li><a href="/warranty-data">Warranty</a></li>
                                <li><a href="/notify">Notify</a></li>
                                <li  className="actice2"><a href="/internation-warranty">International warranty</a></li>
                            </ul>
                        </div>
                        <div className='service-center-wrapper'>
                            {this.props.warrantyDataInter.data ?
                                <div>
                                
                                  { 
                                  <div className='filterwrapper'>
                                     <div className=" prodSelection">
                                        <div className="dropdown-wrap-problem">
                                            <h6>Select Product</h6>
                                            <Select
                                                onChange={this.onProductChange}
                                                options={optionBucketDrop}//
                                                isSearchable={true}
                                                className="select-product-css select-warranty-prod selectProductDropdown"
                                                maxMenuHeight={200}//what is this
                                                placeholder="Search Product.."//what is this
                                                isMulti
                                                styles= {{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        border: '1px solid #595959',
                                                        boxShadow: '0 !important',
                                                        '&:hover': {
                                                        border: '1px solid #595959 !important'
                                                        },
                                                        borderRadius: '20px !important',
                                                        backgroundColor: '#000 !important',
                                                        padding: '0 5px',
                                                        fontSize: '14px !important',
                                                        minHeight: 'auto !important',

                                                        height: 'auto !important'
                                                    }),
                                                    option: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        background: state.isFocused ? '#402500d7 !important':'#000 !important',
                                                        color: state.isFocused? '#FA9F22': '#fff !important',
                                                        width: '100%'
                                                    }),
                                                    placeholder: (baseStyles) => ({
                                                        ...baseStyles,
                                                        color: '#fff !important'
                                                    }),
                                                    // indicatorSeparator: () => null,
                                                    indicatorSeparator: (baseStyles) => ({
                                                        ...baseStyles,
                                                        height: '15px !important'
                                                      }),
                                                    valueContainer: (baseStyles) => ({
                                                        ...baseStyles,
                                                        minHeight: 'auto !important',
                                                        height: 'auto !important',
                                                        color: '#fff !important'
                                                    }),
                                                    menuList: (baseStyles) => ({
                                                        ...baseStyles,
                                                        background: '#000 !important'
                                                    }),
                                                    Input: (baseStyles) => ({
                                                        ...baseStyles,
                                                       
                                                        color: '#fff !important'
                                                      }),
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className=" prodSelection">
                                        <div className="dropdown-wrap-problem">
                                            <h6>Select Channel</h6>
                                            <Select
                                                onChange={this.onChannelList}
                                                options={channelList}//
                                                isSearchable={true}
                                                className="select-product-css select-warranty-prod selectProductDropdown"
                                                maxMenuHeight={200}//what is this
                                                placeholder="Search Channel.."//what is this
                                                isMulti
                                                styles= {{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        border: '1px solid #595959',
                                                        boxShadow: '0 !important',
                                                        '&:hover': {
                                                        border: '1px solid #595959 !important'
                                                        },
                                                        borderRadius: '20px !important',
                                                        backgroundColor: '#000 !important',
                                                        padding: '0 5px',
                                                        fontSize: '14px !important',
                                                        minHeight: 'auto !important',

                                                        height: 'auto !important'
                                                    }),
                                                    option: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        background: state.isFocused ? '#402500d7 !important':'#000 !important',
                                                        color: state.isFocused? '#FA9F22': '#fff !important',
                                                        width: '100%'
                                                    }),
                                                    placeholder: (baseStyles) => ({
                                                        ...baseStyles,
                                                        color: '#fff !important'
                                                    }),
                                                    // indicatorSeparator: () => null,
                                                    indicatorSeparator: (baseStyles) => ({
                                                        ...baseStyles,
                                                        height: '15px !important'
                                                      }),
                                                    valueContainer: (baseStyles) => ({
                                                        ...baseStyles,
                                                        minHeight: 'auto !important',
                                                        height: 'auto !important',
                                                        color: '#fff !important'
                                                    }),
                                                    menuList: (baseStyles) => ({
                                                        ...baseStyles,
                                                        background: '#000 !important'
                                                    }),
                                                    Input: (baseStyles) => ({
                                                        ...baseStyles,
                                                       
                                                        color: '#fff !important'
                                                      }),
                                                }}
                                            />
                                        </div>
                                    </div>
                                
                                    {/* <div className="dropdown-wrap-problem">
                                        <h6>Select channel</h6>
                                        <Select
                                            onChange={this.onChannelList}
                                            options={channelList}//
                                            isSearchable={true}
                                            className="select-product-css select-warranty-prod selectProductDropdown"
                                            maxMenuHeight={200}
                                            placeholder="Search Channel.."
                                            isMulti={true}
                                            styles= {{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    border: '1px solid #595959',
                                                    boxShadow: '0 !important',
                                                    '&:hover': {
                                                    border: '1px solid #595959 !important'
                                                    },
                                                    borderRadius: '20px !important',
                                                    backgroundColor: '#000 !important',
                                                    padding: '0 5px',
                                                    fontSize: '14px !important',
                                                    minHeight: 'auto !important',
                                                    height: 'auto !important'
                                                }),
                                                option: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    background: state.isFocused ? '#402500d7 !important':'#000 !important',
                                                    color: state.isFocused? '#FA9F22': '#fff !important',
                                                    width: '100%'
                                                }),
                                                placeholder: (baseStyles) => ({
                                                    ...baseStyles,
                                                    color: '#fff !important'
                                                }),
                                                indicatorSeparator: () => null,
                                                valueContainer: (baseStyles) => ({
                                                    ...baseStyles,
                                                    minHeight: 'auto !important',
                                                    height: 'auto !important'
                                                }),
                                                menuList: (baseStyles) => ({
                                                    ...baseStyles,
                                                    background: '#000 !important'
                                                })
                                                }}
                                        />
                                    
                                </div> */}
                                <div id="nd-box" className="select-prod-comp date12">
                                    <h6>Date - (DD/MM/YYYY)</h6>
                                    <DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(new Date(new Date().setDate(new Date().getDate() - 90))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.warrantyReportSelectDate(value) }} value={[new Date(this.state.warrantyStartDate), new Date(this.state.warrantyEndDate)]} />
                                </div></div>}
                                    {this.state.isLoading ?
                                        <div className='loader'>
                                            <Loader
                                                type="Oval"
                                                color="Grey"
                                                height={200}
                                                width={200}
                                                className="warrantyLoader"
                                            />
                                        </div>
                                        :
                                        <div className='colrow service-center warranty-col-row'>
                                            <div className='row header-row'>
                                                <div className='col-sm' style={{ color: '#FC940D',width:"12%" }}>
                                                    Customer Name
                                                </div>
                                                <div className='col-sm' style={{ color: '#FC940D', width:"12%" }}>
                                                    Product
                                                </div>
                                                <div className='col-sm' style={{ color: '#FC940D',width:"12%" }}>
                                                    Market Place
                                                </div>
                                                <div className='col-sm' style={{ color: '#FC940D',width:"12%" }}>
                                                   country
                                                </div>
                                                <div className='col-sm' style={{ color: '#FC940D',width:"10%" }}>
                                                    Issue Date
                                                </div>
                                                <div className='col-sm' style={{ color: '#FC940D',width:"20%" }}>
                                                    Contacts
                                                </div>
                                               
                                                {/* <div className='col-sm'>
                                                    Remarks
                                                </div>
                                                <div className='col-sm'>
                                                    Actions
                                                </div> */}
                                            </div>
                                            {this.props.warrantyDataInter.data.length > 0
                                                ?
                                                <div>
                                                    {this.props.warrantyDataInter.data.map((item, index) => (
                                                        <div className='row list-row'>
                                                            <div className='col-sm' style={{width:"12%" }}>
                                                                {item.customerDetails.customer_name || "---"}
                                                            </div>
                                                            <div className='col-sm' style={{width:"12%" }}>
                                                                {item.productDetails.product_name || "---"}
                                                            </div>
                                                            <div className='col-sm' style={{width:"12%" }}>
                                                                {item.channelDetails.name || "---"}
                                                            </div>
                                                            <div className='col-sm' style={{width:"12%" }}>
                                                                {item.country_name || "---"}
                                                            </div>
                                                            <div className='col-sm' style={{width:"12%" }}>
                                                                {new Date(item.registration_time).toLocaleDateString('en-GB', { year: "numeric", month: "short", day: "numeric" })}
                                                            </div>
                                                            <div className='col-sm' style={{ "margin-top": "-12px", width:"12%" }}>
                                                                {item.customerDetails.email }<br />
                                                                {item.customerDetails.mobile || "---"}
                                                            </div>
                                                            {/* <div className='col-sm'>
                                                            { }
                                                        </div> */}
                                                            {/* <div className='col-sm'>
                                                            {'calling button'}
                                                        </div> */}
                                                        </div>
                                                    ))
                                                    }
                                                <Footer new_page={new_page} onPrevPress={this.onPrevPress} onNextPress={this.onNextPress} current_page={this.state.current_page} cc={"kk"}/>
                                                </div>
                                                :
                                                // <div className='loader'>
                                                //     <Loader
                                                //         type="Oval"
                                                //         color="Grey"
                                                //         height={200}
                                                //         width={200}
                                                //         className="warrantyLoader"
                                                //     />
                                                // </div>
                                                <p className="noResult">No result found</p>
                                            }
                                        </div>
                                    }
                                </div>
                                :
                                // <div style={{ color: "#fff" }}>No result found</div>
                                <div className='loader'>
                                    <Loader
                                        type="Oval"
                                        color="Grey"
                                        height={200}
                                        width={200}
                                        className="warrantyLoader"
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = state => ({
    userDetail: state.user.userDetail,
    warrantyData: state.warranty.warrantyData,
    warrantyDataInter:state.warranty.warrantyDataInter,
    productData: state.complaints.productData,
})

export default connect(mapStateToProps, {
     getwarrantyInternational, getProductList, getUserDetail
})(InternationalWarranty);