import React, { Component } from 'react';
import { connect } from 'react-redux';
import { goBack } from "../actions/userActions";


class AccessRestricted extends Component {
    render() {
        const style = {
            fontSize:"24px",
            color:"#fff",
            textAlign:"center",
            fontWeight:"500",
            paddingTop:"5%"
        }

        const btnstyle = {
            float: "none",
            fontSize: "21px",
            color: "rgb(255, 255, 255)",
            textAlign: "center",
            fontWeight: "500",
            background: "rgb(235, 149, 31)",
            width: "10%",
            borderRadius: "35px",
            padding: "3px 0",
            margin: "3% 45% 0"

        }
      
        return (
            <div>
<div style={this.props.style || style}>Access Restricted</div>
<button type="button" style={btnstyle} onClick={() => this.props.goBack()}>Back</button>
            </div>
            
        )
    }
}

export default connect(null, {goBack})(AccessRestricted)

