import React, { Component } from 'react';
import { connect } from "react-redux";
import DashboardHeaderComponent from '../DashboardComponent/DashboardHeaderComponent'
// import OrderComponent from '../DashboardComponent/DashboardComponent'
import './UserProfileComponent.css';
// import moment from 'moment'
// import Pagination from "react-js-pagination";
// import CsvDownload from "react-json-to-csv";
// import { CSVLink } from "react-csv";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
// import ReactReadMoreReadLess from "react-read-more-read-less";
import AccessRestricted from '../AccessRestricted'
import ComplaintRegistration from '../ComplaintRegistration';

class UserProfileComponent extends Component {
    constructor(props) {
        super();
        this.state = {
            userItem: '',
            data: [
                { id: 0, title: 'Orders Registered', count: '02', color: '#8a83be', isSelected: false, image: 'registered_order' },
                { id: 1, title: 'Complaints Registered', count: '00', color: '#f15a5d', isSelected: true, image: 'complaints_registered' },
                { id: 2, title: 'Unique Products Purchased', count: '02', color: '#4ac0b0', isSelected: false, image: 'unique_products' },
                { id: 3, title: 'Warranty Registered', count: '02', color: '#0b3b60', isSelected: false, image: 'warranty_registered' },
                { id: 4, title: 'Feedbacks', count: '02', color: '#c2a392', isSelected: false, image: 'Feedbacks' },
                { id: 5, title: 'Leads', count: '11', color: '#4b384c', isSelected: false, image: 'Leads' },
            ]
        }
    }

    async componentDidMount() {
        if (this.props.location.state && this.props.location.state.item) {
            this.setState({ userItem: this.props.location.state.item })
            // item.customer_id
        }
    }

    onItemSeleted = (id) => {
        let data = this.state.data
        data.map((obj) => {
            if (obj.id === id) {
                obj.isSelected = true
            }
            else {
                obj.isSelected = false
            }
        })
        this.setState({ data })
    }

    render() {
        // let fileName = "Inventory(" + moment(this.state.startDate).format('DD-MM-YYYY') + " - " + moment(this.state.endDate).format('DD-MM-YYYY') + ").csv"
        let userRoleId = localStorage.getItem('userRoleId');
        if(userRoleId === '4' || userRoleId === '8' || userRoleId === '9' || userRoleId === '10'){
          return( <AccessRestricted/>)
        }
        else if (userRoleId === '6'){
            return (<ComplaintRegistration />)
        }
        else{
        return (
            <div>
                <DashboardHeaderComponent />

                <div className="leftContainer">

                    <label className="name">{this.state.userItem.name}</label>

                    <div className='table-responsive'>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" className='heading'>
                                        <a href="" id='invoice_id'>Invoice Id</a>
                                    </th>
                                    <th scope="col" className='heading'>
                                        <a href="" id='contact_number'>Phone No.</a>
                                    </th>
                                    <th scope="col" className='heading'>
                                        <a href="" id='email_id'>Email</a>
                                    </th>
                                    <th scope="col" className='heading'>
                                        <a href="" id='order_date'>Date
                                            {/* {this.state.rowData.length > 0 ?
                                                <i className="fa fa-fw fa-sort sort-icon" id='date' onClick={(e) => this.handleSort(e.target.id)}></i>
                                                : null
                                            } */}
                                        </a>
                                    </th>
                                    <th scope="col" className='heading'>
                                        <a href="" id='product_name'>Product Name</a>
                                    </th>
                                </tr>
                            </thead>
                            {this.state.isLoading ?
                                <div className='loader'>
                                    <Loader
                                        type="Oval"
                                        color="Grey"
                                        height={200}
                                        width={200}
                                    />
                                </div>
                                :
                                <tbody>
                                    {/* {this.state.currentRowData.length > 0 ? this.state.currentRowData.map((d, index) =>
                                        (
                                            <tr key={index}>
                                                <td scope="row" className='data'>{d.name || 'N/A'}</td>
                                                <td className='data'>{d.invoice_id || 'N/A'}</td>
                                                <td className='data'>{d.contact_number || 'N/A'}</td>
                                                <td className='data'>{d.email_id || 'N/A'}</td>
                                                <td className='data'>{d.newDate || 'N/A'}</td>
                                                <td className='data'>
                                                    <ReactReadMoreReadLess
                                                        charLimit={70}
                                                        readMoreText={"Read more ▼"}
                                                        readLessText={"Read less ▲"}
                                                        readMoreClassName="read-more-less--more"
                                                        readLessClassName="read-more-less--less"

                                                    >
                                                        {d.product_name || 'N/A'}
                                                    </ReactReadMoreReadLess>
                                                </td>
                                            </tr>
                                        )) :
                                        <div className="no-result-text">
                                            <label>No result found</label>
                                        </div>
                                    } */}
                                </tbody>
                            }
                        </table>
                    </div>
                </div>

                <div className="row rightContainer">
                    {this.state.data.map((item, index) => {
                        return (
                            <div key={index} className="Rectangle"
                                onClick={() => this.onItemSeleted(item.id)}
                                style={{ backgroundColor: item.color }}>
                                <div className="image-container">
                                    <img className="image" alt='' src={`/img/${item.image}.png`}
                                        srcset={`/img/${item.image}@2x.png 2x, /img/${item.image}@3x.png 3x`} />

                                    {item.isSelected ?
                                        <img className="tick-image" alt = "" src="/img/Ellipse.png"
                                            srcset="img/Ellipse@2x.png 2x, img/Ellipse@3x.png 3x" />
                                        : null
                                    }
                                </div>
                                <label className="title-text">{item.title}</label>
                                <label className="count-text">{item.count}</label>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
                }
    }
}

const mapStateToProps = state => ({
    inventoryData: state.inward.inventoryData,
    downloadData: state.inward.downloadData
})


export default connect(mapStateToProps, { })(UserProfileComponent);
