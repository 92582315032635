import React, { Component } from 'react'
import './ReportComponent.css';
import { connect } from "react-redux";
import history from '../../history';
import moment from 'moment';
// import Loader from 'react-loader-spinner';
import { fetchLeaderboardAll,fetchLeaderboardAllChange } from '../../actions/leaderboardAction';
// import axios from 'axios';
// import { baseUrl } from '../../config'
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';
import Select from 'react-select';
import ViewProfile from '../ViewProfile'
import AccessRestricted from '../AccessRestricted'
import ComplaintRegistration from '../ComplaintRegistration';
import SidebarComponent from '../SidebarComponent/SidebarComponent';
const {
  // allowedMaxDays,
  // allowedDays,
  allowedRange,
  // beforeToday,
  // afterToday,
  // combine
} = DateRangePicker;

class ReportComponent extends Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this)
  }

  handleLogout=()=> {
    localStorage.removeItem('token')
    document.querySelector(".modal-backdrop").style.display = "none";
    history.push('/')
  }
 
  render() {
    const options = [
      { value: 'Buy Back', label: 'Buy Back' },
      { value: 'Ticket Journey', label: 'Ticket Hourney' },
      { value: 'Courier Report', label: 'Courier Report' },
    ];
    let userRoleId = localStorage.getItem('userRoleId');
    if(userRoleId === '4' || userRoleId === '8' || userRoleId === '9' || userRoleId === '10'){
      return( <AccessRestricted/>)
    }
    else if (userRoleId === '6'){
      return (<ComplaintRegistration />)
  }
    else{
      return (
            <div className="leaderboard">
              { window.innerWidth > 600 && <SidebarComponent sideProp={'LB'} /> }
                            <ViewProfile />

              <div>{this.content}</div>
              <div className="report-main-table content-leaderboard">
                <div className="content-title">
                  <h3>Reports</h3>
                </div>
                <ul id="menu">
                  {/* <li className="actice2-border"><a href="/reports" className="actice2">Report</a></li> */}
                  <li><a href="/leaderboard" className="noactive">Leaderboard</a></li>
                </ul>
                
                <div className="dropdown-wrap">
                <div className="lbtn">
                    <div className="dropdown-bar">
                      <h6>Report Type</h6>
                      <Select
                      onChange={this.handleChangeLeader}
                      options={options}
                      isSearchable={true}
                      // value={selectValue}
                      maxMenuHeight={200}
                      className="problem-product-courier"
                      placeholder={options[0].value}
                      closeMenuOnSelect={true}  
                      
                      />
                    </div>
                  </div>
                   <div className="dbtn">
                    <div className="dropdown-bar">
                      <h6>Date</h6>
                        <DateRangePicker showTwoCalendar format="yyyy-MM-dd" disabledDate={allowedRange(moment(new Date(new Date().setDate(new Date().getDate() - 90))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))}  />
                    </div>
                  </div>
                </div>
                <div className="main-table">
                  <table>
                    <thead>
                        <tr>
                          <th >Complaint id</th>
                          <th >Name</th>
                          <th >Product</th>
                          <th >Channel</th>
                          <th >Order Id</th>
                          <th >GC issued Amount</th>
                        </tr>
                    </thead>
                    <tbody> 
                      <tr >
                        <td >454185</td>
                        <td >Balaji sk</td>
                        <td >NoiseFit Fusion<br/>(Vintage Brown)</td>
                        <td >Others</td>
                        <td >175</td>
                        <td >₹3499.50</td>  
                      </tr>
                      <tr >
                        <td >454185</td>
                        <td >Balaji sk</td>
                        <td >NoiseFit Fusion<br/>(Vintage Brown)</td>
                        <td >Others</td>
                        <td >175</td>
                        <td >₹3499.50</td>  
                      </tr>
                      <tr >
                        <td >454185</td>
                        <td >Balaji sk</td>
                        <td >NoiseFit Fusion<br/>(Vintage Brown)</td>
                        <td >Others</td>
                        <td >175</td>
                        <td >₹3499.50</td>  
                      </tr>
                      <tr >
                        <td >454185</td>
                        <td >Balaji sk</td>
                        <td >NoiseFit Fusion<br/>(Vintage Brown)</td>
                        <td >Others</td>
                        <td >175</td>
                        <td >₹3499.50</td>  
                      </tr>
                      <tr >
                        <td >454185</td>
                        <td >Balaji sk</td>
                        <td >NoiseFit Fusion<br/>(Vintage Brown)</td>
                        <td >Others</td>
                        <td >175</td>
                        <td >₹3499.50</td>  
                      </tr>  
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
      )
    }
}
}
const mapStateToProps = state => ({
  leaderboardData:state.report.leaderboardData,
  leaderboardDataChange:state.report.leaderboardDataChange
})


export default connect(mapStateToProps, { fetchLeaderboardAll,fetchLeaderboardAllChange })(ReportComponent);