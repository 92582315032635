import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  ServiceCenterComplaintData, challanAction, setComplaintsArray, sendChallanAction, sendWareHouse, failedToRecieve,
} from '../../actions/serviceCenterActions';
import moment from 'moment'
import { fetchComplaintDetails,TroubleShootShow } from '../../actions/serviceCenterComplaintAction'
// import "./ServiceCenter.css"
import Modal from 'react-modal';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';


function ReplacementComplaints() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedTicketsCount, setSelectedTicketsCount] = useState(0);


  const setActiveTab = (item1, item2) => {
    setValues({
      ...values, tab: item1, ticketStatus: item2, current_page:1
    })
    if(serviceComplaints.length>0){
    if (item1 == 'tab1' || item1 == 'tab2') {
      document.getElementById('selectBtnWrapper').style.display = 'none';
      document.getElementById('selectOption').style.display = 'inline';
    } else {
      document.getElementById('selectBtnWrapper').style.display = 'none';
      document.getElementById('selectOption').style.display = 'none';
    }}

  }

    const initialValues = {
    totalPage: 1,
    totalCount: 0,
    prevPage: false,
    nextPage: false,
    current_page: 1,
    pageLimit: 12,
    searchTextLength: 0,
    searchText: '',
    serviceCenterID: '',
    name: '',
    Challandate: '',
    box: '',
    awb_no: '',
    challanNumber: "",
    ticketStatus: 'pending_replacement',
    startDate: new Date().setDate(new Date().getDate() - 90),
    endDate: new Date().setDate(new Date().getDate()),
    tab: "tab1",
    checkValue: true,
    ticket_id: [],
    sendMain: false

  };

  const [errors, setErrors] = useState(initialValues);
  const [values, setValues] = useState(initialValues);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [isLoading, setisLoading] = useState(false);





  useEffect(() => {
    getComplaintList()
    }, [values.current_page, values.tab, values.sendMain]);

    
    const getComplaintList = async () => {
      let scAddress = localStorage.getItem('scAddress');
      let serviceAdress = JSON.parse(scAddress)
      let data=[]
      if(values.ticketStatus==="pending_replacement"){
        data = {
          "limit": 12,
          "page_no": values.current_page,
          "endDate": moment(values.endDate).format("YYYY-MM-DD"),
          "startDate": moment(values.startDate).format("YYYY-MM-DD"),
          "ticketStatus": values.ticketStatus,
          "tag":"panel",
          "service_center_id": serviceAdress.service_center_id,
        }
      }else{
              data = {
                "limit": 12,
                "page_no": values.current_page,
                "endDate": moment(values.endDate).format("YYYY-MM-DD"),
                "startDate": moment(values.startDate).format("YYYY-MM-DD"),
                "ticketStatus": values.ticketStatus,
                "tag":"panel",
              }}
     
      setisLoading(true)
      await dispatch(ServiceCenterComplaintData(data))
      setisLoading(false)
      if(values.ticketStatus==="pending_replacement"||values.ticketStatus==="received_inventory"){
      document.getElementById('selectOption').style.display = 'inline';
      document.getElementById('selectBtnWrapper').style.display = 'none';
      setSelectedTicketsCount(0)
   
      }
    
      setValues({
        ...values,
        totalCount: serviceData.total,
        totalPage: serviceData ? serviceData.no_page : 0,
        prevPage: serviceData ? serviceData.prev_page : 0,
        nextPage: serviceData ? serviceData.next_page : 1,
      })
    }
    const handleTicketClick = (e, item) => {
      dispatch(fetchComplaintDetails(item.id));
      dispatch(TroubleShootShow(true))
      history.push('/complaint-details')
    }

  const onPrevPress = () => {
    if (serviceData.prev_page) {
      setValues({ ...values, current_page: (values.current_page - 1) }, async () => {
        getComplaintList()
        dispatch(setComplaintsArray([]))
      })
    }
  }

  const onNextPress = () => {
    if (serviceData.next_page) {
      setValues({ ...values, current_page: (values.current_page + 1) }, async () => {
        getComplaintList();
        dispatch(setComplaintsArray([]))
      })
    }
  }
  const paginationCount = useSelector((state) => state.serviceCenter.paginationCount)
  const complaintsArray = useSelector((state) => state.serviceCenter.complaintsArray)
  const serviceData = useSelector((state) => state.serviceCenter.serviceNewData)
  const serviceComplaints = serviceData && serviceData.complaint_data
  let new_page = ((serviceData && serviceData.no_page - (serviceData && serviceData.no_page - values.current_page)) - 1);
  const challanData = useSelector((state) => state.serviceCenter.challanData)
 






  const handleSelectBtn = () => {
    document.getElementById('selectBtnWrapper').style.display = 'block';
    document.getElementById('selectOption').style.display = 'none';
    document.querySelectorAll('.selectTicketCheckBox').forEach((checkbox) => {
      checkbox.style.display = 'inline'
    });
  }

  const handleSelectAllBtn = () => {
    document.querySelectorAll('.selectTicketCheckBox').forEach((checkbox) => checkbox.checked = "true")
    setSelectedTicketsCount(document.querySelectorAll('.selectTicketCheckBox:checked').length);
    let complaintArray = serviceComplaints.map((item) => {
      return item.id
    })
    dispatch(setComplaintsArray(complaintArray))
  }



  const handleCloseBtn = () => {
    document.getElementById('selectOption').style.display = 'inline';
    document.getElementById('selectBtnWrapper').style.display = 'none';
    document.querySelectorAll('.selectTicketCheckBox:checked').forEach((checkbox) => {
      checkbox.checked = false
    })
    document.querySelectorAll('.selectTicketCheckBox').forEach((checkbox) => {
      checkbox.style.display = 'none';
    });
    setSelectedTicketsCount(0)
  }

  const handleSelectCheckbox = (e, data) => {
    setSelectedTicketsCount(document.querySelectorAll('.selectTicketCheckBox:checked').length);

    let complaiatnsID = data.id
    let newArray = complaintsArray;
    let index = complaintsArray.indexOf(complaiatnsID);
    if (index > -1) {
      newArray.splice(index, 1)
    } else {
      newArray.push(complaiatnsID)
    }

    dispatch(setComplaintsArray(newArray))
  }

  const sendMainToWarehouse = async () => {
    if(complaintsArray.length==0){
      alert("Please Select At Least one Ticket")
    }else{
      let data = {
        complaint_id: complaintsArray
      }
      setisLoading(true)
      await dispatch(sendWareHouse(data));
      await getComplaintList()
      setisLoading(false)
      document.getElementById('selectOption').style.display = 'inline';
      document.getElementById('selectBtnWrapper').style.display = 'none';
      setSelectedTicketsCount(0)
      dispatch(setComplaintsArray([]))
    } 
   
  }

  const failedTicket = async (e, item) => {
    let data = {
      complaint_id: item
    }
    setisLoading(true)
    await dispatch(failedToRecieve(data))
    await getComplaintList()
    setisLoading(false)
    document.getElementById('selectOption').style.display = 'inline';
    document.getElementById('selectBtnWrapper').style.display = 'none';
    setSelectedTicketsCount(0)
    dispatch(setComplaintsArray([]))
  }
  return (
    <>
      <div className='activeComplaints mainContent'>
        <h1 className='heading'>Replacement</h1>
        <div className='inventBTn'>
          <div className='flexEnd'>
            <ul className='replacementTabs'>
              <li className={values.tab === 'tab1' ? 'activeTab' : 'unselctActive'} onClick={() => setActiveTab("tab1", "pending_replacement")}>Pending Tickets</li>
              <li className={values.tab === 'tab2' ? 'activeTab' : 'unselctActive'} onClick={() => setActiveTab("tab2", "received_inventory")}>Inventory Recieved</li>
              <li className={values.tab === 'tab3' ? 'activeTab' : 'unselctActive'} onClick={() => setActiveTab("tab3", "sent_to_wh")}>Sent Inventory</li>
            </ul>


          </div>
        {
          serviceComplaints.length>0 && 
          <div>
          <button className='selectBtn' id='selectOption' onClick={handleSelectBtn}>Select</button>
          {
            <div id='selectBtnWrapper'>
              <button className='selectBtn selectBtnTwo' onClick={handleSelectAllBtn}>Select All</button>
              <button className='selectBtn' onClick={handleCloseBtn}>Close</button>
            </div>}
        </div>}
          </div>
      {
      isLoading ?
      <div className='loader'>
        <Loader
          type="Oval"
          color="Grey"
          height={200}
          width={200}
          className='loaderNew'
        />
      </div> : <div>
        <div className='complaits_container'>
          {
            serviceComplaints && serviceComplaints.map((item, index) => {
              return (
                <div key={item.id} className="ticket-wrapper" >
                  <input type='checkbox' className='selectTicketCheckBox' onClick={(e) => { handleSelectCheckbox(e, item) }} />
                  <span id='header1'>Complaint No. {item.ticket_id}
                    {values.tab == "tab2" ? <img src="../../img/close.svg" style={{ width: "25px", marginLeft: '12px', color: "red" }} onClick={(e) => failedTicket(e, item.id)} alt=" " /> : null}
                  </span>
                  <p id='insideline'></p>
                  <div className='product_details' onClick={e => handleTicketClick(e, item)}>
                    <p className='products_info' >
                      <span className='productname'>Product Name: </span>
                      <span id='nm1'>{item.product_name}</span>
                    </p>
                    <p className='products_info' >
                      <span className='productname'>Status: </span>
                      <span id='nm1'>{item.complaint_status_name}</span>
                    </p>
                    <p className='products_info' >
                      <span className='productname'>Date: </span>
                      <span id='nm1'>{moment(item.updated_at).format('DD-MM-YYYY')} </span>
                    </p>
                  </div>
                </div>)

            })
          }
       
        <div className="footers">
          <div className="pages1">
            {serviceData && serviceData.total > 0 ?
              <div>
                {serviceData && serviceData.no_page === 1 ?
                  <p style={{ color: 'black', fontWeight: "500" }}>
                    1 - {serviceData && serviceData.total} of {serviceData && serviceData.total} results
                  </p>
                  :
                  <p>
                    {values.pageLimit * new_page > 1 ?
                      values.pageLimit * new_page
                      :
                      '1'
                    }
                    -
                    {((values.pageLimit * values.current_page) > (serviceData && serviceData.total)) && ((serviceData && serviceData.total) > (values.pageLimit * new_page)) ?
                      (serviceData && serviceData.total)
                      :
                      (values.pageLimit * values.current_page)} of {serviceData && serviceData.total} results
                    <i className="fa fa-chevron-left" style={{ color: "black" }} onClick={onPrevPress} />
                    <span>{values.current_page}</span>
                    <i className="fa fa-chevron-right" style={{ color: "black" }} onClick={onNextPress} />
                  </p>
                }
              </div>
              : <div className="Noreult">
                <span>No complaints found</span>
              </div>
            }
            </div>
          </div>
        </div>
    
      {
        (values.tab == "tab1" || values.tab == "tab2") && (serviceComplaints.length )?
          <div className='actionsWrapper'>
            <span>Selected: {selectedTicketsCount}</span>
            <button onClick={sendMainToWarehouse}>Send</button>
          </div> : null
      }
 </div>}
 </div>
    </>
 )
}

export default ReplacementComplaints