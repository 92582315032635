// Fetch all App Version
import axios from "axios";
import {
  FETCH_COMPLAINTS_DATA,
  ADD_COMPLAINT_SELECTION,
  REMOVE_COMPLAINT_SELECTION,
  CLEAR_ALL_COMPLAINT_DATA,
  GET_PRODUCT_LIST,
  GET_PRODUCT_PROBLEM_LIST,
  GET_COMPLAINT_BUCKET,
  GET_COMPLAINT_STATUS,
  EDIT_COMPLAINTS_DATA,
  GET_COMPLAINTS_DETAIL,
  CALL_CUSTOMER,
  GET_RPU_COURIER,
  ADD_STATUS_ID,
  SHOW_COMPLAINT_DETAILS,
  GET_CITY_LIST,
  REFRESH_COMPLAINT_LIST,
  GET_STATE_LIST,
  FETCH_COMPLAINTS_COUNT,
  FETCH_COMMENTS,
  FETCH_CONVERSATIONS,
  FETCH_PAGINATION_INFO,
  TRACK_STATUS_DATA,
  SCREENING_RESPONSE,
  PRINT_LABEL_DATA,
  TRANSFER_TO_DD,
  RESEND_MAIL,
  FETCH_CANNED_REPONSES,
  RESCHEDULE_IVR,
  FETCH_REFUND_EDIT,
  FETCH_GENERATE_LINK,
  GET_CHANNEL_LIST,
  FETCH__GC_AAPPROVED_LINK,
  FETCH_REUND_CASE,
  FETCH_ADMIN_LIST,
  FETCH_GC_CASE,
  FETCH_GCAPPROVAL_CASE,
  FETCH_REFUNDAPPROVAL_CASE,
  FETCH_DOWNLOAD_REPORT,
  FETCH_REFUND_RESEND,
  FETCH_GC_APPROVAL_CASE,
  GET_SUB_PROBLEM_LIST,
  FETCH_CONVERSATIONS_DATA,
  COSMOS_RES,
  FETCH_CONVERSATIONS_LOAD,
  FETCH_COMMENT_DATA,
  FETCH_COMMENT_LOAD,
  SET_BUCKET_ID_LIST,
  SET_ACTION_TRUE,
  SAVE_MAC_ID,
  GET_NON_NFA,
  GET_FAULTS_LIST,
} from "./types";
import { baseUrl, webBaseUrl, oldUrl } from "../config";
import history from "../history";

export const rescheduleIVR = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/schedule-ivr", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: RESCHEDULE_IVR,
          payload: res.data,
        });
      }
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const sendEmail = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/send-customer-mail", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        // dispatch({
        //   type : PRINT_LABEL_DATA,
        //   payload : res.data.data
        // })
        alert("Successfully done");
      } else {
        alert("Something went wrong");
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const submitFreshDeskClick = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/freshdesk-move", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        // dispatch({
        //   type : PRINT_LABEL_DATA,
        //   payload : res.data.data
        // })
        alert("Successfully done");
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const regenerateCoupons = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/regenerate-coupon", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        // dispatch({
        //   type : PRINT_LABEL_DATA,
        //   payload : res.data.data
        // })
        alert("Successfully done");
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
      if (e.response.status === 429) {
        alert(e.response.statusText || "Something went wrong");
      }
    });
};

export const qcSubmit = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/testing", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        // dispatch({
        //   type : PRINT_LABEL_DATA,
        //   payload : res.data.data
        // })
        alert("Successfully done");
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const repairPaymentSubmit = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/repair-payment", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        // dispatch({
        //   type : PRINT_LABEL_DATA,
        //   payload : res.data.data
        // })
        alert("Successfully done");
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
      if (e.response.status === 429) {
        alert(e.response.statusText || "Something went wrong");
      }
    });
};

export const handleRequestPaymentSubmit = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/fd-request-payment", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        // dispatch({
        //   type : PRINT_LABEL_DATA,
        //   payload : res.data.data
        // })
        alert("Successfully done");
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const fetchPrintLabel = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/printLabel", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: PRINT_LABEL_DATA,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: PRINT_LABEL_DATA,
          payload: null,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const returnPolicyAction = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/return_policy", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        alert("Successfully sent");
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
      if (e.response.status === 429) {
        alert(e.response.statusText || "Something went wrong");
      }
    });
};

export const fetchTrackStatus = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  let url = "";
  if (data.tracking === "shiprocket") {
    url = baseUrl + "/courier/shiprocket/track-awb";
  } else {
    url = baseUrl + "/courier/clickpost/track-awb";
  }
  return await axios
    .post(url, data, { headers: { "x-access-token": token } })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: TRACK_STATUS_DATA,
          payload: res.data.data,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const forwardPickupCancel = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/forward-cancel", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        // history.push('/complaints-data')
        // window.location.reload()
        alert("Successfully Cancelled");
      } else {
        alert("Cancellation failed!");
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const reversePickupCancel = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/forward-cancel", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        alert("Successfully Cancelled");
      } else {
        alert(res.data ? res.data.message : "Failed!");
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const moveToRtoCase = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/rto-case", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        // history.push('/complaints-data')
        // window.location.reload()
        alert("Successfully Moved");
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const transferTodirectDispatch = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/direct-dispatch", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: TRANSFER_TO_DD,
          payload: res.data,
        });
        // history.push('/complaints-data')
        // window.location.reload()
        alert("Successfully Dispatched");
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const transferTodirectDispatchFreshdesk = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/direct-dispatch", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: TRANSFER_TO_DD,
          payload: res.data,
        });
        // history.push('/complaints-data')
        // window.location.reload()
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const moveToDispatchInward = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/dispatch-inward", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        // history.push('/complaints-data')
        // window.location.reload()
        alert(res.data.message);
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

// submitRPU
export const submitRPU = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/reverse-pickup", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        alert("Successfully sent");
      } else {
        alert(res.data.message);
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
      // history.push('/')
    });
};

export const unmarkedTicketRefund = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/refund-unmark", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        alert(res.data.message);
      } else {
        alert(res.data.message);
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
      // history.push('/')
    });
};

export const unmarkedTicketGC = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/gc-unmark", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        alert(res.data.message);
      } else {
        alert(res.data.message);
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
      // history.push('/')
    });
};

export const revokeTicket = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .get(baseUrl + "/complaint/revoke/" + data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        alert("Successfully Revoked");
        //  history.push('/complaints-data')
        // window.location.reload()
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
      // history.push('/')
    });
};

export const clearComplaintData = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ALL_COMPLAINT_DATA,
  });
};

export const addComplaintSelection = (data) => async (dispatch) => {
  dispatch({
    type: ADD_COMPLAINT_SELECTION,
    payload: data,
  });
};

export const removeComplaintSelection = () => async (dispatch) => {
  dispatch({
    type: REMOVE_COMPLAINT_SELECTION,
  });
};

export const getRefundData = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  // let token = "abc"
  return await axios
    .post(baseUrl + "/complaint/get-complaint", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: FETCH_COMPLAINTS_DATA,
          payload: res.data.data.complaint_data || [],
        });
        dispatch({
          type: FETCH_COMPLAINTS_COUNT,
          payload: res.data || [],
        });
        dispatch({
          type: FETCH_PAGINATION_INFO,
          payload: res.data.data || [],
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getComplaintData = (data,boseFlag=false) => async (dispatch) => {
  let token = localStorage.getItem("token");
  // let token = "abc"
  return await axios
    .post( baseUrl+"/complaint/get-complaint", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: FETCH_COMPLAINTS_DATA,
          payload: res.data.data.complaint_data,
        });
        dispatch({
          type: FETCH_COMPLAINTS_COUNT,
          payload: res.data || [],
        });
        dispatch({
          type: FETCH_PAGINATION_INFO,
          payload: res.data.data || [],
        });
      } else {
        dispatch({
          type: FETCH_COMPLAINTS_DATA,
          payload: res.data,
        });
        dispatch({
          type: FETCH_COMPLAINTS_COUNT,
          payload: [],
        });
        dispatch({
          type: FETCH_PAGINATION_INFO,
          payload: [],
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const moveToTestingInward = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/testing-inward", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        // dispatch({
        //   type: FETCH_COMPLAINTS_DATA,
        //   payload: res.data.data.complaint_data || []
        // })
        alert("Successfully Moved");
        // window.location.reload()
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const moveToTestingOutward = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/testing-outward", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        // dispatch({
        //   type: FETCH_COMPLAINTS_DATA,
        //   payload: res.data.data.complaint_data || []
        // })
        alert("Successfully Moved");
        // window.location.reload()
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const regeneratePaymentLink = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/regenerate-payment", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        // dispatch({
        //   type: FETCH_COMPLAINTS_DATA,
        //   payload: res.data.data.complaint_data || []
        // })
        alert("Successfully Moved");
        // window.location.reload()
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const submitScreenName = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/screening", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        // dispatch({
        //   type: FETCH_COMPLAINTS_DATA,
        //   payload: res.data.data.complaint_data || []
        // })
        // alert("Successfully Moved")
        // window.location.reload()
        dispatch({
          type: SCREENING_RESPONSE,
          payload: res.data.data,
        });
      }
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const callbackTagSubmit = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/callback-tagging", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        // dispatch({
        //   type: FETCH_COMPLAINTS_DATA,
        //   payload: res.data.data.complaint_data || []
        // })
        alert("Successfully Moved");
        // window.location.reload()
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getComplaintBuckets = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  dispatch({
    type: GET_COMPLAINT_BUCKET,
    payload: [],
  });
  return await axios
    .post(baseUrl + "/complaint/buckets", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_COMPLAINT_BUCKET,
          payload: res.data.data,
        });
        // dispatch({
        //   type: ADD_STATUS_ID,
        //   payload: res.data.data[0].id
        // })
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getComplaintStatus = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/status", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_COMPLAINT_STATUS,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getProductList = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/product/get", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_PRODUCT_LIST,
          payload: res.data,
        });
        return res.data;
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getAllNonNfa = () => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(
      baseUrl + "/complaint/fetch-all-non-nfa",
      {},
      { headers: { api_key: "d8f021fd65e25982003ddce3cac2225e" } }
    )
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_NON_NFA,
          payload: res.data,
        });
        return res.data;
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getProductProblemList = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/product/problem-list", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_PRODUCT_PROBLEM_LIST,
          payload: res.data.data,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getSubProblemList = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/product/sub-problem-list", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_SUB_PROBLEM_LIST,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: GET_SUB_PROBLEM_LIST,
          payload: [],
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const editComplaintData = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/edit", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      dispatch({
        type: EDIT_COMPLAINTS_DATA,
        payload: res.data,
      });
      // dispatch(refreshComplaintList(true))
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const addComments = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/add-comments", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        // dispatch({
        //   type: ADD_COMMENT,
        //   payload: res.data
        // })
        dispatch({
          type: FETCH_CONVERSATIONS,
          payload: res.data,
        });
        return res.data;
        // dispatch(refreshComplaintList(true))
        // dispatch(showComplaintDetailsAction(false))
      }
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
      if (e.response.status === 429) {
        alert(e.response.statusText || "Something went wrong");
      }
    });
};

export const sendMail = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/msg-customer", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: FETCH_CONVERSATIONS,
          payload: res.data.data.conversation,
        });
        return res.data;
      }
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
      if (e.response.status === 429) {
        alert(e.response.statusText || "Something went wrong");
      }
    });
};

export const getComplaintDetail = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .get(baseUrl + "/complaint/get/" + data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_COMPLAINTS_DETAIL,
          payload: res.data.data[0] || null,
        });
        dispatch({
          type: FETCH_COMMENTS,
          payload: res.data.data[0] ? res.data.data[0].comments : [],
        });
        dispatch({
          type: FETCH_CONVERSATIONS,
          payload: res.data.data[0] ? res.data.data[0].conversation : [],
        });
      } else {
        dispatch({
          type: GET_COMPLAINTS_DETAIL,
          payload: null,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const callCustomer = (customerId, complaintId) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .get(baseUrl + "/customer/call/" + customerId + "/" + complaintId, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      alert(res.data.message);
      if (res.data.success) {
        dispatch({
          type: CALL_CUSTOMER,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getRpuCourier = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/courier/get-pincode", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_RPU_COURIER,
          payload: res.data.data,
        });
        // dispatch({
        //   type : COURIER_ID,
        //   payload : res.data.data[0] ? res.data.data[0].courier_id : null
        // })
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const addStatusId = (data) => async (dispatch) => {
  dispatch({
    type: ADD_STATUS_ID,
    payload: Number(data),
  });
};

export const showComplaintDetailsAction = (flag) => async (dispatch) => {
  dispatch({
    type: SHOW_COMPLAINT_DETAILS,
    payload: flag,
  });
};

export const refreshComplaintList = (flag) => async (dispatch) => {
  dispatch({
    type: REFRESH_COMPLAINT_LIST,
    payload: flag,
  });
};

export const getStateList = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/master/state", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_STATE_LIST,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getFaultsList = (product_group_id) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .get(baseUrl + `/product/get-faults/${product_group_id}`, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_FAULTS_LIST,
          payload: res.data,
        });
        return res.data;
      }
    })
    
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getCityByState = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/master/city", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_CITY_LIST,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_CITY_LIST,
          payload: [],
        });
      }
      return res.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getChannelList = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/channels", data, {
      headers: {
        "x-access-token": token,
        api_key: "d8f021fd65e25982003ddce3cac2225e",
      },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: GET_CHANNEL_LIST,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const sendCustomerInvoice = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/resend-invoice", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      // if (res.data.success) {
      dispatch({
        type: RESEND_MAIL,
        payload: res.data.message,
      });
      // }
    })
    .catch((e) => {
      history.push("/");
    });
};

export const cannedResponse = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/message/get-canned", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: FETCH_CANNED_REPONSES,
          payload: res.data.data,
        });
      } else {
        alert(res.data.message);
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
      if (e.response.status === 429) {
        alert(e.response.statusText || "Something went wrong");
      }
    });
};

export const refundEdit = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/refund/", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      dispatch({
        type: FETCH_REFUND_EDIT,
        payload: res.data.data,
      });
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) history.push("/");
    });
};

export const generateLink = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/re-refund", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      dispatch({
        type: FETCH_GENERATE_LINK,
        payload: res.data.data,
      });
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) history.push("/");
    });
};

export const GCApproval = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/gc-approve", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      dispatch({
        type: FETCH__GC_AAPPROVED_LINK,
        payload: res.data,
      });
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) history.push("/");
    });
};

export const resendReund = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/resend-gc", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      dispatch({
        type: FETCH_REFUND_RESEND,
        payload: res.data,
      });
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) history.push("/");
    });
};

export const refundCaseApproval = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/refund-case", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      dispatch({
        type: FETCH_REUND_CASE,
        payload: res.data,
      });
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) history.push("/");
    });
};

export const adminList = () => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .get(baseUrl + "/user/cs", { headers: { "x-access-token": token } })
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: FETCH_ADMIN_LIST,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const gcCase = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/gc-case", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      dispatch({
        type: FETCH_GC_CASE,
        payload: res.data,
      });
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) history.push("/");
    });
};

export const gcApprovalSubmit = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/gc-approval", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      dispatch({
        type: FETCH_GCAPPROVAL_CASE,
        payload: res.data,
      });
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) history.push("/");
    });
};

export const refundApprovalSubmit = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/refund-approval", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      dispatch({
        type: FETCH_REFUNDAPPROVAL_CASE,
        payload: res.data,
      });
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) history.push("/");
    });
};

export const downlodReport = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/record/refund-report", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        alert(res.data.message);
        dispatch({
          type: FETCH_DOWNLOAD_REPORT,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 400) {
        alert(e.response.data.message);
      } else if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const moveToReadyToDispatch = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/ready-to-dispatch", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      if (res.data.success) {
        alert(res.data.message);
        // dispatch({
        //   type: FETCH_DOWNLOAD_REPORT,
        //   payload: res.data
        // })
      }
    })
    .catch((e) => {
      if (e.response.status === 400) {
        alert(e.response.data.message);
      } else if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const cosmosAction = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/add-cosmos", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      alert(res.data.message);
      dispatch({
        type: COSMOS_RES,
        payload: res.data,
      });
    })
    .catch((e) => {
      if (e.response.status === 400) {
        alert(e.response.data.message);
      } else if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getConversationData = (id, page) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .get(baseUrl + "/complaint/get-conversation/" + id + "?page=" + page, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      dispatch({
        type: FETCH_CONVERSATIONS_DATA,
        payload: res.data.data.conversation,
      });
      dispatch({
        type: FETCH_CONVERSATIONS_LOAD,
        payload: res.data.data,
      });
      return res.data.data.conversation;
    })
    .catch((e) => {
      if (e.response.status === 400) {
        alert(e.response.data.message);
      } else if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const flagAction = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/flag-complaint", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 400) {
        alert(e.response.data.message);
      } else if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getCommentData = (id, page) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .get(baseUrl + "/complaint/get-comments/" + id + "?page=" + page, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      dispatch({
        type: FETCH_COMMENT_DATA,
        payload: res.data.data.comments,
      });
      dispatch({
        type: FETCH_COMMENT_LOAD,
        payload: res.data.data,
      });
      return res.data.data.comments;
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getSCConversationData = (id, page) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .get(baseUrl + "/complaint/get-comments/" + id + "?page=" + page, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      dispatch({
        type: FETCH_COMMENT_DATA,
        payload: res.data.data.comments,
      });
      dispatch({
        type: FETCH_COMMENT_LOAD,
        payload: res.data.data,
      });
      return res.data.data.comments;
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const addBucketID = (data) => async (dispatch) => {
  dispatch({
    type: SET_BUCKET_ID_LIST,
    payload: data,
  });
};

export const setACtionTrue = (data) => async (dispatch) => {
  dispatch({
    type: SET_ACTION_TRUE,
    payload: data,
  });
};

export const saveMACID = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/add-new-mac", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      dispatch({
        type: SAVE_MAC_ID,
        payload: res.data,
      });
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const oosTestingAction = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/complaint/oos/testing-inward", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getScProductList = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .get(baseUrl + "/product/get_service_center_products", {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};

export const getScProblemList = (data) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return await axios
    .post(baseUrl + "/product/get_all_problem_list", data, {
      headers: { "x-access-token": token },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push("/");
      }
    });
};
