import React, { useState, useEffect } from 'react';
import '../ComplaintsComponent/ComplaintsComponent.mobile.css';
import { getStateList, getCityByState } from '../../actions/complaintsDataAction';
import { useDispatch, useSelector } from "react-redux";
import { setCustomerDetails, clearComplaintData } from '../../actions/serviceCenterComplaintAction';

function CustomerDetails({ nextStep }) {

  const dispatch = useDispatch();

  const initialValues = {
    name: '',
    phone: '',
    email: '',
    address: '',
    landmark: '',
    pincode: '',
    stateCode: '',
    stateName: '',
    cityCode: '',
    cityName: ''
  };

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(initialValues);
  const states = useSelector((state) => state.complaints.stateData.data);
  const cities = useSelector((state) => state.complaints.cityData.data);
  const customerData = useSelector((state) => state.serviceCenterComplaint.customerDetails);


  useEffect(() => {
    dispatch(getStateList());
    dispatch(clearComplaintData({}));
  }, []);

  useEffect(() => {
    if (customerData !== undefined && customerData !== null )
      setValues(customerData);
  }, [customerData]);

  const handleValidation = (customerDetails) => {
    let errors = {};
    let validity = true;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const mobileRegex = /^[6789]\d{9}$/;
    const pincodeRegex = /^[1-9]\d{5}$/;

    if (customerDetails.name === undefined || customerDetails.name === '') {
      errors['name'] = 'Name cannot be empty';
      validity = false;
    } else if (customerDetails.name.trim().length === 0) {
      errors['name'] = 'Please provide a valid name';
      validity = false;
    } else if ((/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/).test(customerDetails.name) === false) {
      errors['name'] = 'Please provide a valid name';
      validity = false;
    }
    if (customerDetails.phone === undefined || customerDetails.phone === '') {
      errors['phone'] = 'Phone number cannot be empty';
      validity = false;
    } else if (mobileRegex.test(customerDetails.phone) === false) {
      errors['phone'] = 'Please enter valid contact number';
      validity = false;
    }
    if (customerDetails.email === undefined || customerDetails.email === '') {
      errors['email'] = 'Email cannot be empty';
      validity = false;
    } else if (emailRegex.test(customerDetails.email) === false) {
      errors['email'] = 'Please enter valid email';
      validity = false;
    }
    if (customerDetails.address === undefined || customerDetails.address === '') {
      errors['address'] = 'Address cannot be empty';
      validity = false;
    } else if (customerDetails.address.trim().length === 0) {
      errors['address'] = 'Please provide a valid address';
      validity = false;
    }
    if (customerDetails.pincode === undefined || customerDetails.pincode === '') {
      errors['pincode'] = 'Pincode cannot be empty';
      validity = false;
    } else if (pincodeRegex.test(customerDetails.pincode) === false) {
      errors['pincode'] = 'Please provide a valid pincode';
      validity = false;
    }
    if (customerDetails.stateCode === undefined || customerDetails.stateCode === '') {
      errors['state'] = 'State cannot be empty';
      validity = false;
    }
    if (customerDetails.cityCode === undefined || customerDetails.cityCode === '') {
      errors['city'] = 'City cannot be empty';
      validity = false;
    }
    setErrors(errors);
    return validity;
  };

  const handleContinueBtn = () => {
    let customerDetails = {
      name: (values.name || "").trim(),
      phone: values.phone,
      email: (values.email || "").trim(),
      address: (values.address || "").trim(),
      landmark: (values.landmark || "").trim(),
      pincode: values.pincode,
      stateCode: values.stateCode,
      stateName: values.stateName,
      cityCode: values.cityCode,
      cityName: values.cityName,
    };
    let validity = handleValidation(customerDetails);
    if (validity === true) {
      dispatch(setCustomerDetails(customerDetails));
      nextStep(1);
    }
  };



  const getCityList = (e) => {
    const select = e.target;
    const desc = select.selectedOptions[0].text;
    setValues({
      ...values,
      stateCode: e.target.value,
      stateName: desc,
      cityCode: '',
      cityName: ''
    });
    let data = {
      "state_id": e.target.value
    };
    if (data.state_id !== '') {
      dispatch(getCityByState(data));
    }
  };

  const handleCityChange = (e) => {
    const select = e.target;
    const desc = select.selectedOptions[0].text;
    setValues({
      ...values,
      cityCode: e.target.value,
      cityName: desc
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };


  return (
    <div>
      <h1 className='form-heading'>Customer details</h1>
      <div className='horizontal'></div>
      <form>
        <div className='row__one'>
          <div>
            <label htmlFor='customer name'>Customer Name</label>
            <input type="text" name='name' value={values.name || ''} onChange={handleInputChange}
            />
            <div className='error_add'>
              <span className='errorShow'>{errors.name}</span>
            </div>
          </div>
          <div>
            <label htmlFor="contact number">Contact Number</label>
            <input type='tel' name='phone' value={values.phone || ''} maxLength="10" onChange={handleInputChange} />
            <div className='error_add'>
              <span className='errorShow'>{errors.phone}</span>
            </div>
          </div>
          <div>
            <label htmlFor='customer email'>Email ID</label>
            <input type='email' name='email' value={values.email || ''} onChange={handleInputChange} required={true} />
            <div className='error_add'>
              <span className='errorShow'>{errors.email}</span>
            </div>
          </div>
        </div>
        <div className='row__two'>
          <div>
            <label htmlFor='customer address line 1'>Address</label>
            <input type='text' name='address' value={values.address || ''} onChange={handleInputChange} required={true} />
            <div className='error_add'>
              <span className='errorShow'>{errors.address}</span>
            </div>
          </div>
          <div>
            <label htmlFor='landmark'>Landmark</label>
            <input type='text' name='landmark' value={values.landmark || ''} onChange={handleInputChange} required={true} />
          </div>
        </div>
        <div className='row__three'>
          <div>
            <label htmlFor='pincode'>Pincode</label>
            <input type='text' name='pincode' value={values.pincode || ''} onChange={handleInputChange} maxLength="6" required={true} />
            <div className='error_add'>
              <span className='errorShow'>{errors.pincode}</span>
            </div>
          </div>
          <div>
            <label htmlFor='state'>State</label>
            <select name='states' value={values.stateCode || ''} onChange={getCityList}>
              <option value=''>Select State</option>
              {
                states && states.map((state, index) => {
                  return <option key={state.id} value={state.id} text={state.state_name}>{state.state_name}</option>;
                })
              }
            </select>
            <div className='error_add'>
              <span className='errorShow'>{errors.state}</span>
            </div>
          </div>
          <div>
            <label htmlFor='city'>City</label>
            <select name='cities' value={values.cityCode || ''} onChange={handleCityChange}>
              <option value=''>Select City</option>
              {
                cities && cities.map((city, index) => {
                  return <option key={city.id} value={city.id}>{city.city}</option>;
                })
              }
            </select>
            <div className='error_add'>
              <span className='errorShow'>{errors.city}</span>
            </div>
          </div>
        </div>
      </form>
      <div className='horizontal'></div>
      <button style={{ float: "right" }} className='navBtn' onClick={handleContinueBtn}>Continue</button>
    </div>
  );
}

export default CustomerDetails;