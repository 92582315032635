import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import CutomerDetails from './Retail/CustomerDetails';
import ProductDetails from './Retail/ProductDetails';
import History from './Retail/History';
import Conversations from './Retail/Conversations';
import Comments from './Retail/Comments';
import TicketReceived from './ticketReceived';
import { getComplaintDetail, showComplaintDetailsAction, getConversationData, addStatusId, setACtionTrue } from '../../actions/complaintsDataAction';
// import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css';
// import history from '../../history';
import Loader from 'react-loader-spinner';

class ComplaintDetailsNew extends Component {
  constructor() {
    super();
    this.state = {
      ticketReceived: true,
      approvedTicket: true,
      subMenu: 1,
      click: 0,
      showLoader: false,
      isDisabled: false,
      showMacID: false
    };
    // this.handleRefreshList = this.props.handleRefreshList.bind(this)
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);

  }

  onSubMenuClick = async (e) => {
    this.setState({ subMenu: e.target.value });
  };

  onItemPress = async (e, item) => {
    this.setState({ showLoader: true });
    await this.props.getComplaintDetail(item.id);
    await this.props.addStatusId(item.bucketId);
    this.setState({ showLoader: false, subMenu: 1 });
    const element = document.querySelector(".activeColor");
    if (element) {
      element.scrollIntoView({ block: "center" });
    }

    let createdAt = new Date(this.props.complaintsDetail?.created_at)
    let new_date = new Date("2023-10-25")
    let dateCheck = createdAt > new_date
    if (dateCheck) {
      this.setState({ showMacID: true })
    } else {
      this.setState({ showMacID: false })
    }
    if(this.props.complaintsDetail?.current_suffix != "SC"){
      if ((this.props.complaintsDetail?.current_prefix == "OOS" || this.props.complaintsDetail?.current_prefix == "QcFailOrder" || this.props.complaintsDetail?.current_suffix == "SC" ) && this.props.statusId != "51") {
        this.props.setACtionTrue(true)
      } else {
        if (this.props.complaintsDetail?.status_name == "testing_inward" && !(this.props.complaintsDetail?.old_mac_verification == "TRUE") && (this.props.complaintsDetail?.product?.product_group_id == 2 && dateCheck) && !(this.props.complaintsDetail?.old_mac_id == "NON-NFA" || this.props.complaintsDetail?.old_mac_id == "DOA"  )) {
          this.props.setACtionTrue(true)
        } else {
          if ((this.props.complaintsDetail?.old_mac_verification == "TRUE")) {
            this.props.setACtionTrue(false)
          } else {
            if ((this.props.complaintsDetail?.product?.product_group_id == 2 && dateCheck) && (this.props.complaintsDetail?.old_mac_id)) {
              if (this.props.statusId == 15) {
                if (this.props.complaintsDetail?.new_mac_id) {
                  this.props.setACtionTrue(false)
                } else if (!(this.props.complaintsDetail?.old_mac_id == "NON-NFA" || this.props.complaintsDetail?.old_mac_id == "DOA")) {
                  this.props.setACtionTrue(true)
                } else {
                  this.props.setACtionTrue(false)
                }
              } else {
                this.props.setACtionTrue(false)
              }
            }
            else {
              this.props.setACtionTrue(false)
            }
          }
        }
      }
    }else{
      if(this.props.complaintsDetail?.current_prefix == "OOS" || this.props.complaintsDetail?.current_prefix  == "QcFailOrder" ){
        this.props.setACtionTrue(true)
      }else{
        this.props.setACtionTrue(false)

      }
    }
  };

  onCloseDetail = (e) => {
    e.preventDefault();
    this.props.showComplaintDetailsAction(false);
  };

  onDownloanInvoice = (url) => {
  };

  componentDidMount() {
    let createdAt = new Date(this.props.complaintsDetail?.created_at)
    let new_date = new Date("2023-10-25")
    let dateCheck = createdAt > new_date

    if (dateCheck) {
      this.setState({ showMacID: true })
    } else {
      this.setState({ showMacID: false })
    }
    if(this.props.complaintsDetail?.current_suffix != "SC"){
      if ((this.props.complaintsDetail?.current_prefix == "OOS" || this.props.complaintsDetail?.current_prefix == "QcFailOrder" || this.props.complaintsDetail?.current_suffix == "SC" ) && this.props.statusId != "51") {
        this.props.setACtionTrue(true)
      } else {
        if (this.props.complaintsDetail?.status_name == "testing_inward" && !(this.props.complaintsDetail?.old_mac_verification == "TRUE") && (this.props.complaintsDetail?.product?.product_group_id == 2 && dateCheck) && !(this.props.complaintsDetail?.old_mac_id == "NON-NFA" || this.props.complaintsDetail?.old_mac_id == "DOA"  )) {
          this.props.setACtionTrue(true)
        } else {
          if ((this.props.complaintsDetail?.old_mac_verification == "TRUE")) {
            this.props.setACtionTrue(false)
          } else {
            if ((this.props.complaintsDetail?.product?.product_group_id == 2 && dateCheck) && (this.props.complaintsDetail?.old_mac_id)) {
              if (this.props.statusId == 15) {
                if (this.props.complaintsDetail?.new_mac_id) {
                  this.props.setACtionTrue(false)
                } else if (!(this.props.complaintsDetail?.old_mac_id == "NON-NFA" || this.props.complaintsDetail?.old_mac_id == "DOA")) {
                  this.props.setACtionTrue(true)
                } else {
                  this.props.setACtionTrue(false)
                }
              } else {
                this.props.setACtionTrue(false)
              }
            }
            else {
              this.props.setACtionTrue(false)
            }
          }
        }
      }
    }else{
      if(this.props.complaintsDetail?.current_prefix == "OOS" || this.props.complaintsDetail?.current_prefix == "QcFailOrder" ){
        this.props.setACtionTrue(true)
      }else{
        this.props.setACtionTrue(false)

      }
    }
    }

  zoomIn() {
    this.setState({ click: this.state.click + 1 });
    if (this.state.click > 1) {
      this.setState({ click: 2 });
    }
  }
  zoomOut() {
    this.setState({ click: this.state.click - 1 });
    if (this.state.click < 1) {
      this.setState({ click: 0 });
    }
  }

  showWarrantyPopup = () => {
    if (this.props.complaintsDetail?.current_prefix == "OOS" || this.props.complaintsDetail?.current_prefix == "QcFailOrder" || this.props.complaintsDetail?.current_suffix == "SC" ) {
      alert("No action on this Ticket")
    } else {
      if (this.props.complaintsDetail?.old_mac_verification == "TRUE") {
        alert("Please Entered MAC ID")
      } else {
        alert("Please Verify MAC ID")
      }
    }

  }

  render() {
    return (
      <div className="main-container">
        {this.state.showLoader ?
          <div className='loader'>
            <Loader
              type="Oval"
              color="Grey"
              height={200}
              width={200}
            />
          </div>
          :
          <div className="main-container">
            <div className="left-container">
              {this.props.complaintsData.length > 0 ?
                this.props.complaintsData.map((item, index) => {
                  return (
                    <div key={index} onClick={(e) => this.onItemPress(e, item)}>
                      {/* {item.id == this.props.location.state.item.id ? */}
                      {this.props.complaintsDetail ?
                        <div className={this.props.complaintsDetail.id === item.id ? "container7 activeColor" : "container7"} >
                          <div className="box">
                            <h6>{item.ticket_id}{item?.new_launch_tag =="1"? "- NL" : ""}
                              {Number(item.is_warranty) === 1 ?
                                <button className="active ">Active</button>
                                :
                                <button className="active">Expire</button>
                              }
                            </h6>
                            <span style={{ cursor: 'pointer' }}>{item.product_name}({item.size && "Size " + item.size + " | "}{item.color_name})</span>
                            {item.upgradeProduct &&
                              <span className='upg'>
                                UPG - {item.upgradeProduct.product_name} ({item.size && "Size " + item.size + " | "}{item.upgradeProduct.color_name})
                              </span>
                            }
                            <p style={{ cursor: 'pointer' }}>{moment(item.created_at, ["YYYY-MM-DD || HH.mm"]).format('MMMM DD, YYYY || hh:mm A')} || {item.channel_name}</p>

                          </div>
                        </div>
                        :
                        <div></div>
                        // <div className={this.props.complaintsDetail == item.ticket_id ? "box" : null}>
                        //   <h6>{item.ticket_id}
                        //     {item.is_warranty == 1 ?
                        //       <button className="active">Active</button>
                        //       :
                        //       <button className="active">Expire</button>
                        //     }
                        //   </h6>
                        //   <span style={{ cursor: 'pointer' }}>{item.product_name}({item.color_name})</span>
                        //   <p style={{ cursor: 'pointer' }}>{moment(item.created_at).format('MMMM DD, YYYY || hh:mm:ss A')} || {item.channel_name}</p>

                        // </div>
                      }
                    </div>
                  );
                })
                :
                <div className="no-result-text">

                  <p>No result found</p>

                </div>
              }
            </div>
            {this.props.complaintsData.length > 0 ?
              <div>
                {this.props.complaintsDetail ?
                  <div className="right-container">
                    <div className="box">
                      <h6>{this.props.complaintsDetail.ticket_id} {this.props.complaintsDetail?.new_launch_tag =="1"? "- NL" : ""}
                        {
                          Number(this.props.complaintsDetail.is_warranty) === 1 ?
                          <button className="active">Active</button>
                          :
                          <button className="active">Expire</button>
                          
                        }
                        <div className="ppti abc">  <i className="fas fa-times" onClick={(e) => this.onCloseDetail(e)}></i></div>

                      </h6>
                      <div className="ppt setwidth">
                        <p>{this.props.complaintsDetail.customer ? this.props.complaintsDetail.customer.name : null} || {moment(this.props.complaintsDetail.updated_at).format('MMMM DD, YYYY')} || {this.props.complaintsDetail.status_name}</p>
                      </div>
                      <div className="details">  <h6>{this.props.complaintsDetail.product.problem}</h6>
                      </div>

                      {/* <div className="buton">   <button className="bbts"><i className="fas fa-ellipsis-v"></i>Actions</button></div> */}

                      {this.props.statusId !== 47 ?
                        <div className="col">
                          {this.props.showAction ?
                            <button onClick={this.showWarrantyPopup} className="bbts" id="dropdownMenuButton" >
                              <i className="fas fa-ellipsis-v"></i>Actions
                            </button>
                            :
                            <button onClick={this.actions} className="bbts" id="dropdownMenuButton" data-toggle="dropdown" >
                              <i className="fas fa-ellipsis-v"></i>Actions
                            </button>}
                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {this.state.ticketReceived &&
                              <TicketReceived handleRefreshList={this.props.handleRefreshList}  handleLoader={this.props.handleLoader} />
                            }
                            {/* {this.state.approvedTicket &&
                            (
                              <p>Approved Ticket</p>
                            )
                          } */}
                            {/* <a className="dropdown-item" style={{padding:'0px  0.0rem'}} href="#" data-toggle="modal" data-target="#exampleModalabc" onClick={(e) => this.onRPUClick(e,this.props.complaintsDetail.id)} ><i className="fas fa-exclamation-circle"></i>RPU</a>
                      <a className="dropdown-item" style={{padding:'0px  0.0rem'}} href="#" onClick={(e) => this.onInvalidInvoiceClick(e,this.props.complaintsDetail.id)} ><i className="far fa-exclamation-square"></i>Invalid Invoice</a>
                      <a className="dropdown-item" style={{padding:'0px  0.0rem'}} href="#" onClick={(e) => this.onWarrantyExpiredClick(e,this.props.complaintsDetail.id)} ><i className="fas fa-exclamation-circle"></i>Warranty Expired</a>
                      <a className="dropdown-item" style={{padding:'0px  0.0rem'}} href="#" onClick={(e) => this.directDispatch(e,this.props.complaintsDetail.id)} ><i className="fas fa-exclamation-circle"></i>Direct Dispatch</a>
                      <a className="dropdown-item" style={{padding:'0px  0.0rem'}} href="#" onClick={(e) => this.onFreshdeskClick(e,this.props.complaintsDetail.id)} ><i className="fas fa-exclamation-circle"></i>Freshdesk</a>
                      <a className="dropdown-item" style={{padding:'0px  0.0rem'}} href="#" onClick={(e) => this.onRefundClick(e,this.props.complaintsDetail.id)} ><i className="bi bi-currency-dollar"></i>Refund</a> */}
                          </div>
                        </div> : null}

                      <div className="mitem">
                        <ul id="menu2">
                          <li value="1" className={this.state.subMenu === 1 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>Attachments</li>
                          <li value="2" className={this.state.subMenu === 2 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>Product details</li>
                          <li value="3" className={this.state.subMenu === 3 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>Customer details</li>
                          <li value="4" className={this.state.subMenu === 4 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>Conversations</li>
                          <li value="5" className={this.state.subMenu === 5 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>Comments</li>
                          <li value="6" className={this.state.subMenu === 6 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>History</li>
                          
                        </ul>
                      </div></div>
                    {this.state.subMenu === 1 ?
                      <div className="detail_wrapper">
                        <div className="div5 p_image">
                          <h6>Product image</h6>
                          <img className="imageWidthHight" alt="No image available" src={this.props.complaintsDetail.image_url ? this.props.complaintsDetail.image_url : ''} data-toggle="modal" data-target="#immos" />
                          {/* <img src={this.props.complaintsDetail.image_url} /> */}
                          {/* <img src={"https://picsum.photos/200/300"} /> */}

                        </div>

                        <div className="modal fade" id="immos">
                          <div className="zoom-icons">
                            {this.state.click < 3 &&
                              <div>
                                <i className="fas fa-search-plus" onClick={(this.state.click < 3) && this.zoomIn}></i>
                                <i className="fas fa-search-minus" onClick={(this.state.click < 3) && this.zoomOut}></i>
                              </div>
                            }

                          </div>
                          <div className="modal-dialog" role="document">
                            <div className="immo">
                              <div className="imgs" data-dismiss="modal">
                                {this.state.click === 0 &&
                                  <img style={{ transform: "scale(1)" }} alt="No image available" src={this.props.complaintsDetail.image_url ? this.props.complaintsDetail.image_url : ''} />
                                }
                                {this.state.click === 1 &&
                                  <img style={{ transform: "scale(1.5)", marginTop: "15%" }} alt="No image available" src={this.props.complaintsDetail.image_url ? this.props.complaintsDetail.image_url : ''} />
                                }
                                {this.state.click === 2 &&
                                  <img style={{ transform: "scale(2.5)", marginTop: "75%" }} alt="No image available" src={this.props.complaintsDetail.image_url ? this.props.complaintsDetail.image_url : ''} />
                                }
                                {/* <img alt="" src={this.props.complaintsDetail.image_url ? this.props.complaintsDetail.image_url : '/img/ss2.jpg'} /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        {this.props.complaintsDetail.invoice_url &&
                          <div className="div6 invoice">
                            <h6>Invoice</h6>
                            <span onClick={() => this.onDownloanInvoice(this.props.complaintsDetail.invoice_url)}>
                              <a className="download_icon" href={this.props.complaintsDetail.invoice_url ? this.props.complaintsDetail.invoice_url : ''} target='_blank'> <i className="fas fa-download" />
                                Download Invoice</a></span>
                            <img alt="No preview available for PDF. Please Download from Above" className="imageWidthHight render-class" src={this.props.complaintsDetail.invoice_url ? this.props.complaintsDetail.invoice_url : ''} width="750px" height="900px" data-toggle="modal" data-target="#immos1" />
                          </div>
                        }
                        <div className="modal fade" id="immos1">
                          <div className="zoom-icons">
                            {this.state.click < 3 &&
                              <div>
                                <i className="fas fa-search-plus" onClick={(this.state.click < 3) && this.zoomIn}></i>
                                <i className="fas fa-search-minus" onClick={(this.state.click < 3) && this.zoomOut}></i>
                              </div>
                            }

                          </div>
                          <div className="modal-dialog" role="document">
                            <div className="immo" >
                              <div className="imgs" data-dismiss="modal">
                                {this.state.click === 0 &&
                                  <img style={{ transform: "scale(1)" }} alt="No invoice available" src={this.props.complaintsDetail.invoice_url ? this.props.complaintsDetail.invoice_url : ''} />
                                }
                                {this.state.click === 1 &&
                                  <img style={{ transform: "scale(1.5)", marginTop: "15%" }} alt="No invoice available" src={this.props.complaintsDetail.invoice_url ? this.props.complaintsDetail.invoice_url : ''} />
                                }
                                {this.state.click === 2 &&
                                  <img style={{ transform: "scale(2.5)", marginTop: "75%" }} alt="No invoice available" src={this.props.complaintsDetail.invoice_url ? this.props.complaintsDetail.invoice_url : ''} />
                                }

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      this.state.subMenu === 2 ?
                        <ProductDetails
                          handleProdutcRefreshList={this.props.handleProdutcRefreshList}
                          handleRefreshList={this.props.handleRefreshList}
                        />
                        :
                        this.state.subMenu === 3 ?
                          <CutomerDetails
                            item={this.props.complaintsDetail}
                          />
                          :
                          this.state.subMenu === 4 ?
                            <Conversations
                            // item={this.props.complaintsDetail}
                            />
                            :
                            this.state.subMenu === 5 ?
                              <Comments
                              // item={this.props.complaintsDetail}
                              />
                              :

                              this.state.subMenu === 6 ?
                                <History
                                // item={this.props.complaintsDetail}
                                />
                                : null
                    }
                  </div>
                  : <h3 style={{ color: "#fff" }}>Complaint Details not found</h3>
                }
              </div>
              : null
            }
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  complaintsData: state.complaints.complaintsData,
  complaintsDetail: state.complaints.complaintsDetail,
  showComplaintDetails: state.complaints.showComplaintDetails,
  statusId: state.complaints.statusId,
  showAction: state.complaints.showAction,
});


export default connect(mapStateToProps, { getComplaintDetail, showComplaintDetailsAction, getConversationData, addStatusId, setACtionTrue })(ComplaintDetailsNew);
