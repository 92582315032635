import React, { Component } from 'react'
import './RefundGC.css';
import { connect } from "react-redux";
import moment from 'moment'
import Loader from 'react-loader-spinner'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  getComplaintData, getComplaintBuckets, getComplaintStatus, getProductList,
  getProductProblemList, getRpuCourier, editComplaintData, getComplaintDetail, callCustomer,
  addStatusId, showComplaintDetailsAction, getStateList, getCityByState,
  submitRPU, revokeTicket, fetchPrintLabel, rescheduleIVR, refundEdit, downlodReport
} from '../../actions/complaintsDataAction'
import history from '../../history';
import ComplaintDetailsNew from './RefundGCNew';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';
import ReactModal from 'react-modal';
import Select from 'react-select';
import ViewProfile from '../ViewProfile';
import AccessRestricted from '../AccessRestricted';
import Footer from'../FooterComponent/footer';
import SidebarComponent from '../SidebarComponent/SidebarComponent';

const {
  allowedRange,
} = DateRangePicker;

const customStylesFinance = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    width: 'calc(100% - 20px)',
    maxWidth: '400px',
    height: '60%',
    margin: '0 auto',
    background: '#fff',
    borderRadius: '10px',
    overflowY: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    paddingBottom: '2%'
  },
};

// const customStylesDO = {
//   overlay: {
//     background: 'rgba(0, 0, 0, 0.5)',
//   },
//   content: {
//     inset: '8% 0 0 auto',
//     marginRight: '0',
//     transform: 'translate(0)',
//     background: '#000',
//     padding: '2rem',
//     border: 'none',
//     width: '35%',
//     position: 'absolute'
//   },
// };

// const customStylesCallReminder = {
//   overlay: {
//     background: 'rgba(0, 0, 0, 0.5)',
//   },
//   content: {
//     inset: '60%  7%0 auto',
//     border: '1px solid #eb951f',
//     marginRight: '0',
//     transform: 'translate(0)',
//     background: '#000',
//     padding: ' 0',
//     borderRadius: '20px',
//     width: '35%',
//     position: 'absolute'
//   },
// };

// const styleEdit = {
//   overlay: {
//     background: 'rgba(0, 0, 0, 0.5)',
//   },
//   content: {
//     inset: '8% 0 0 auto',
//     marginRight: '0',
//     transform: 'translate(0)',
//     background: '#000',
//     padding: '0',
//     border: 'none',
//     height: '600px',
//     overflow: 'hidden',
//     overflowY: 'scroll',
//     position: 'fixed',
//     width: '50%',
//     float: 'right'
//   },
// };

// const customStylesRPU = {
//   overlay: {
//     background: 'rgba(0, 0, 0, 0.5)',
//     overflow: "scroll"
//   },
//   content: {
//     inset: '8% 0 0 auto',
//     marginRight: '0',
//     transform: 'translate(0)',
//     background: '#fff',
//     padding: '2rem',
//     border: 'none',
//     width: '100%',
//     position: 'absolute',
//     overflow: 'scroll',
//   },
// }

class RefundGCComponent extends Component {
  constructor() {
    super();
    this.state = {
      showEditTicket: false,
      isLoading: false,
      showMenu: false,
      logout: false,
      pageNo: 1,
      pageLimit: 10,
      searchText: '',
      searchDate: '',
      complaintList: [],
      prevPage: false,
      nextPage: false,
      statusList: [],
      productList: [],
      SLAList: [{ "id": 'true', "value": "Yes" }, { "id": 'false', "value": "No" }],
      problemList: [],
      productId: 0,
      SLAId: '',
      warranty: '',
      problemId: 0,
      totalPage: 1,
      totalCount: 0,
      teamName: "CS",
      complaintId: 0,
      productName: '',
      problemName: '',
      problemDesc: '',
      email: '',
      mobile: '',
      customer_id: 0,
      purchaseDate: '',
      address1: '',
      address2: '',
      pinCode: '',
      state: '',
      city: '',
      comment: '',
      waybillNo: '',
      gstin: '',
      pageNos: [{ id: 1, value: '10' }, { id: 2, value: '25' }, { id: 3, value: '50' }],
      courierData: [],
      showDetails: false,
      startDate: new Date().setDate(new Date().getDate() - 90),
      endDate: new Date().setDate(new Date().getDate()),
      stateList: [],
      cityList: [],
      state_id: '',
      searchUniversal: "",
      showBucketName: false,
      isSearch: false,
      current_page: 1,
      courierId: 0,
      showRPUModal: false,
      assignedStatus: false,     
      isWarranty: 0,
      ticketId: '',
      showFPUModal: false,
      productColorId: "",
      showFPUPrintModal: false,
      loader2: false,
      value: '',
      copied: false,
      selectedProduct: [],
      isLoadingGif: false,
      current_prefix: '',
      showCallReminder: false,
      today: '',
      callReminderDate: '',
      callReminderTime: '',
      time: '',
      isPriority: false,
      showPriority: false,
      showFinanceModal: false,
      inputAmount: '',
      errors: [],
      product: '',
      refundTicketStatus: '101',
      refundComplaintId: '',
      refundStatus: '',
      listStatus: '101',
      refundNote: '',
      loadingInModal: false,
      isChecked: false,
      complaintsData: [],
      checkedList: [],
      selectAll: false,
      showSelectAll: false,
      showAllCSPending: false,      
    }
    this.showMenu = this.showMenu.bind(this);
    this.handleLogout = this.handleLogout.bind(this)
    this.onProductChange = this.onProductChange.bind(this);
    this.handleRefreshList = this.handleRefreshList.bind(this);
    this.handleSearchBy = this.handleSearchBy.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this.handleFPUOpenModal = this.handleFPUOpenModal.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.prefixChange = this.prefixChange.bind(this);
    this.handleChangeChk = this.handleChangeChk.bind(this);
    this.handleSubmitFApproved = this.handleSubmitFApproved.bind(this);
    this.handleSubmitFDeclined = this.handleSubmitFDeclined.bind(this);
    this.handleSubmitCSDeclined = this.handleSubmitCSDeclined.bind(this);
  }

 
  async onRevokeClick(e, item) {
    await this.props.revokeTicket(item.id)
    this.getComplaintList();
    this.setState({
      showRPUModal: false
    })
  }

  _handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.handleChangeButton(e)
    }
  }

  handleRPUCloseModal() {
    this.setState({
      showRPUModal: false
    })
  }

  async handleCourierChange(e) {
    this.setState({
      courierId: e.target.value
    })
  }

  onRPUClick = async (e, item) => {
    e.preventDefault()
    let data = {
      "complaint_id": item.id,
      "type": "reverse_pick_up"
    }
    // TODO first call list api then click list item and call new save api
    this.setState({
      isLoading: true,
      complaintId: item.id
    })
    await this.props.getRpuCourier(data)
    this.setState({ isLoading: false, showRPUModal: true })
    // this.onRPUdata(data)
  }

  handleRPUSubmit = async () => {
    let cId = this.state.courierId;
    let selectedC = this.props.courierData.filter((cd) => Number(cd.courier_id) === Number(cId))
    if (selectedC.length > 0) {
      this.setState({ isLoadingGif: true })
      let data = {
        "complaint_id": this.state.complaintId,
        "type": "reverse_pick_up",
        "courier_id": cId,
        "reversePrice": 0
      }
      await this.props.submitRPU(data)
      this.setState({
        isLoadingGif: false
      });
      this.getComplaintList();
      this.setState({
        showRPUModal: false
      })
    } else {
      alert("Please select courier id")
    }
  }

  handleFPUOpenModal = async (e, item) => {
    let complaintId = item.id
    this.setState({ complaintId: complaintId });
    let data = {
      "complaint_id": item.id,
      "type": "forward_pick_up"
    }
    // TODO first call list api then click list item and call new save api
    this.setState({
      isLoading: true
    })
    await this.props.getRpuCourier(data)
    this.setState({ isLoading: false, showFPUModal: true })
  }

  onFPUSubmit = async () => {
    let cId = this.state.courierId
    let selectedC = this.props.courierData.filter((cd) => Number(cd.courier_id) === Number(cId))
    if (selectedC.length > 0) {
      this.setState({ isLoadingGif: true });
      let data = {
        "complaint_id": this.state.complaintId,
        "type": "forward_pick_up",
        "courier_id": cId,
        "reversePrice": 0,
        "forwardPrice": 0,
      }
      await this.props.submitRPU(data)
      this.setState({
        isLoadingGif: false,
      });
      this.getComplaintList();
      this.setState({
        showFPUModal: false
      })
    } else {
      alert("Please select courier")
      return
    }
  }

  handleFPUCloseModal() {
    this.setState({ showFPUModal: false });
  }

  handleSearchBy(e) {
    this.setState({
      searchUniversal: e.target.value
    })
  }

  handleLogout() {
    localStorage.removeItem('token')
    document.querySelector(".modal-backdrop").style.display = "none";
    history.push('/')
  }

  handleIconDetails() {
    this.setState({
      logout: !this.state.logout
    })
  }

  async componentDidMount() {
    await this.props.getComplaintStatus({});
    await this.props.getProductList({});
    this.setState({
      // statusList: this.props.bucketData.data,
      productList: this.props.productData.data ? this.props.productData.data.products : []
      // problemList: this.props.problemData.data.problems,
      // statusId: this.props.bucketData.length > 0 ? this.props.bucketData[0].id : this.props.statusId
    })
    if (this.state.refundTicketStatus > 0) {
      await this.props.addStatusId(this.state.refundTicketStatus)
    }
    this.getComplaintList();
  }

  onPrevPress = () => {
    if (this.props.paginationCount.prev_page) {
      // let new_page = (this.state.new_page1 - 1);
      // this.setState({new_page1: new_page});
      this.setState({ current_page: (this.state.current_page - 1) }, async () => {
        await this.props.showComplaintDetailsAction(false);
        this.getComplaintList()
      })
    }
  }

  onNextPress = () => {
    if (this.props.paginationCount.next_page) {
      this.setState({ current_page: (this.state.current_page + 1) }, async () => {
        await this.props.showComplaintDetailsAction(false);
        this.getComplaintList()
      })
    }
  
  }


  getComplaintList = async () => {
    let data = {};
    if (this.state.searchText.length > 0) {
      data = {
        "page_no": this.state.current_page,
        "refundBoard": true,
        "search_by": this.state.searchText
      }
      this.setState({
        showBucketName: true
      })
    }
    else if (!this.state.assignedStatus) {
      data = {
        "page_no": this.state.current_page,
        "limit": this.state.pageLimit,
        "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
        "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
        "refundBoard": true,
        "refund_ticket_status": this.state.refundTicketStatus,
      }
      if (Number(this.state.productId) > 0) { data["product_id"] = this.state.productId }
      if (this.state.SLAId && this.state.SLAId !== "0") {
        data["sla_breached"] = this.state.SLAId
        data["refund_ticket_status"] = this.props.statusId
      }
    }
    else {
      data = {
        "page_no": this.state.current_page,
        "assignedStatus": this.state.assignedStatus,
        "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
        "endDate": moment(this.state.endDate).format('YYYY-MM-DD'),
      }
      if (Number(this.state.productId) > 0) { data["product_id"] = this.state.productId }
      if (this.state.SLAId && this.state.SLAId !== "0") { data["sla_breached"] = this.state.SLAId }
    }
    // if(Number(this.state.productId) > 0){
    //   data.bucket_id = 0 
    // }
    this.setState({ isLoading: true, complaintList: [], showBucketName: false })
    await this.props.getComplaintData(data);
    // let complaintsData = this.props.complaintsData
    let complaintDataCount = this.props.complaintsDataCount;
    this.setState({
      isLoading: false,
      totalCount: complaintDataCount.total,
      totalPage: complaintDataCount.data ? complaintDataCount.data.no_page : 0,
      prevPage: complaintDataCount.data ? complaintDataCount.data.prev_page : 0,
      nextPage: complaintDataCount.data ? complaintDataCount.data.next_page : 1,
      complaintsData: this.props.complaintsData
      // complaintList: complaintsData.data.complaint_data,
    })
  }

  showMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true });
  }

  handleDate = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.getComplaintList();
    })
  }

  handleChange = (e) => {
    this.setState({ searchText: e.target.value }, () => {
      this.getComplaintList();
    })
  }

  handleChangeEvent = async (e) => {
    this.setState({ searchText: e.target.value, current_page: 1 })
    if (e.target.value.length === 0) {
      this.setState({ isSearch: false });
      await this.props.showComplaintDetailsAction(false);
      this.getComplaintList();
      await this.props.addStatusId(this.state.refundTicketStatus)
    }
  }

  handleChangeButton = async (e) => {
    e.preventDefault();
    let val = document.getElementById("search_input").value;
    if (val.length > 0) {
      this.setState({ isSearch: true });
    } else {
      this.setState({ isSearch: false });
    }
    await this.props.showComplaintDetailsAction(false);
    this.setState({ searchText: val, current_page: 1 }, () => {
      this.getComplaintList();
    })
  }

  onStatusChange = async (e) => {
    this.setState({ current_page: 1, listStatus: e.target.value, searchText: '' });
    this.props.showComplaintDetailsAction(false)
    let ticketStatus = e.target.value;
    if (ticketStatus === "103") {
      this.setState({ showSelectAll: true })
    } else {
      this.setState({ showSelectAll: false })
    }
    if (ticketStatus === "111") {
      this.setState({ showAllCSPending: true })
    } else {
      this.setState({ showAllCSPending: false })
    }
    this.setState({ refundTicketStatus: ticketStatus })
    await this.props.addStatusId(e.target.value)
    this.getComplaintList();

  };

  onProductChange(a) {
    this.setState({ productId: a.value }, async () => {
      this.getComplaintList();
      await this.props.showComplaintDetailsAction(false)
      let pId = this.state.productId;
      // if(Number(pid) > 0){
      // }
      let selected = this.state.productList.filter((pl) => Number(pl.product_color_id) === Number(pId))
      let selectedProduct = [];
      {
        selected.length > 0 ?
          selectedProduct.push(
            {
              value: selected[0].product_color_id,
              label: selected[0].id == 239 ? selected[0].product_name + "(" + selected[0].size + "|" + selected[0].color_name + ")" : selected[0].product_name + "(" + selected[0].color_name + ")",
            }
          )
          :
          selectedProduct.push(
            {
              value: 0,
              label: "Select All",
            }
          )
      }
      this.setState({ selectedProduct: selectedProduct })
      let category_id = selected[0] ? selected[0].product_group_id : null
      if (category_id) {
        await this.props.getProductProblemList({ "category_id": category_id });
      }
    })
  }

  onProductDateTimeChange = (e) => {
    this.setState({ searchDate: e.target.value }, () => {
      this.getComplaintList();
    })
  }

  onSLAChange = (e) => {
    this.setState({ SLAId: e.target.value }, async () => {
      this.getComplaintList();
      await this.props.showComplaintDetailsAction(false)

    })
  }

  onProducdProblemChange = (e) => {
    this.setState({ problemId: e.target.value }, async () => {
      this.getComplaintList();
      await this.props.showComplaintDetailsAction(false)
    })
  }

  onMenuClick = (e, teamName) => {
    this.setState({ teamName: teamName, current_page: 1 }, async () => {
      let data = {
        team: this.state.teamName
      }
      if (this.state.teamName !== "my_tickets") {
        if (this.state.teamName === "priority_tickets") {
          this.setState({ showPriority: true, assignedStatus: true })
        } else {
          this.setState({
            assignedStatus: false, showPriority: false
          })
          await this.props.getComplaintBuckets(data);
          if (this.props.bucketData.length > 0) {
            await this.props.addStatusId(this.props.bucketData[0].id)
          }
        }
      } else {
        this.setState({
          assignedStatus: true, showPriority: false
        })
      }
      await this.props.showComplaintDetailsAction(false)
      this.getComplaintList();
    })
  }

  getComplaintDetailApi = async (param) => {
    await this.props.getComplaintDetail(param);
    this.setState({ complaintDetailsList: [] })
    let complaintDetailsList = this.props.complaintsDetail
    this.setState({
      complaintId: complaintDetailsList.id ? complaintDetailsList.id : '',
      city_id: complaintDetailsList.customer.city_id ? complaintDetailsList.customer.city_id : '',
      state_id: complaintDetailsList.customer.state_id ? complaintDetailsList.customer.state_id : '',
      productName: complaintDetailsList.product_id == 239 ? (complaintDetailsList.product.product_name + ' (' + "Size " + complaintDetailsList.product.size + " | " + complaintDetailsList.product.color_name + ')') : complaintDetailsList.product.product_name + "(" + complaintDetailsList.product.color_name + ")",
      problemName: complaintDetailsList.product.problem ? complaintDetailsList.product.problem : '',
      problemDesc: complaintDetailsList.problem_description ? complaintDetailsList.problem_description : '',
      email: complaintDetailsList.customer.email ? complaintDetailsList.customer.email : '',
      mobile: complaintDetailsList.customer.mobile ? complaintDetailsList.customer.mobile : '',
      customer_id: complaintDetailsList.customer_id ? complaintDetailsList.customer_id : 0,
      purchaseDate: moment(complaintDetailsList.purchase_date).format('YYYY-MM-DD'), 
      address1: complaintDetailsList.customer.address ? complaintDetailsList.customer.address : '',
      address2: complaintDetailsList.customer.address_2 ? complaintDetailsList.customer.address_2 : '',
      pinCode: complaintDetailsList.customer.pincode ? complaintDetailsList.customer.pincode : '',
      city: complaintDetailsList.customer.city ? complaintDetailsList.customer.city : '',
      waybillNo: complaintDetailsList.reverse_waybill_number ? complaintDetailsList.reverse_waybill_number : '',
      productColorId: complaintDetailsList.product_color_id,
      gstin: complaintDetailsList.gstin ? complaintDetailsList.gstin : '',
      current_prefix: complaintDetailsList.current_prefix ? complaintDetailsList.current_prefix : '',
    })
    this.getStateListApi();
    this.getCityListApi(this.state.state_id);
  }

  handleValidation() {
    let errors = {};
    let formIsValid = true;
    if (this.state.purchaseDate === '') {
      errors["purchaseDate"] = "Please enter purchase date"
    }
    if (this.state.purchaseDate !== '') {
      let b = this.state.purchaseDate;
      let UserDate = new Date(b);
      let ToDate = new Date();
      if (UserDate.getTime() > ToDate.getTime()) {
        formIsValid = false;
        errors["purchaseDate"] = "Purchase date cannot be future date"
      }
    }
    if (this.state.email === '') {
      errors["email"] = "Email cannot be empty";
      formIsValid = false;
    }
    if (this.state.email !== '') {
      var pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (!pattern.test(this.state.email)) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }
    if (this.state.mobile === '') {
      formIsValid = false;
      errors["mobile"] = "Enter your phone number.";
    }
    if (this.state.address1 === '') {
      errors["address1"] = "Address cannot be empty";
      formIsValid = false;
    }
    if (this.state.state_id === 0 || this.state.state_id === "0") {
      errors["state"] = "State cannot be empty";
      formIsValid = false;
    }
    if (this.state.city_id === 0 || this.state.city_id === "0" || this.state.city_id === '') {
      errors["city"] = "City cannot be empty";
      formIsValid = false;
    }
    if (this.state.gstin !== "") {
      let gst = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(this.state.gstin)
      if (!gst) {
        errors["gstin"] = "Incorrect GST format!";
        formIsValid = false;
      }
    }
    if (this.state.inputAmount === '') {
      formIsValid = false;
      errors['inputAmount'] = 'Please Enter Ammount'
    }
    if (this.state.inputAmount) {
      let ammount = this.state.inputAmount
      var pattern = new RegExp(/^[0-9]+$/);
      if (!pattern.test(ammount)) {
        formIsValid = false
        errors['inputAmount'] = 'Please Enter Valid Ammount'
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  amountValidation() {
    let formIsValid = true
    let errors = {};
    if (this.state.inputAmount === '') {
      formIsValid = false;
      errors['inputAmount'] = 'Please Enter Ammount'
    }
    if (this.state.inputAmount !== '') {
      let ammount = this.state.inputAmount
      var pattern = new RegExp(/^\d+(\.\d{1,2})?$/);
      if (!pattern.test(ammount)) {
        formIsValid = false
        errors['inputAmount'] = 'Please Enter Valid Amount'
      }
    }
    if (this.state.refundNote === "") {
      formIsValid = false
      errors['refundNote'] = 'Please enter Note'
    }
    this.setState({ errors: errors, loadingInModal: false });
    return formIsValid;
  }

  onUpdateClick = (e) => {
    e.preventDefault();
    let data = {
      "complaint_id": this.state.complaintId,
      "problem_description": this.state.problemDesc,
      "purchase_date": this.state.purchaseDate,
      "address1": this.state.address1,
      "address2": this.state.address2,
      "pincode": this.state.pinCode,
      "state": this.state.state_id,
      "city": this.state.city_id,
      // "comment": this.state.comment,
      "waybill_number": this.state.waybillNo,
      "product_color_id": this.state.productColorId,
      "customer_id": this.state.customer_id,
      "email": this.state.email,
      "mobile": this.state.mobile,
      "gstin": this.state.gstin.toUpperCase(),
      "current_prefix": this.state.current_prefix
    }
    if (this.handleValidation() !== false) {
      this.callEditApi(data);
    }
  }

  handleValidationRefund() {
    let errors = {};
    let flag = true;
    if (this.state.inputAmount === '') {
      errors["inputAmount"] = "Amount is compulsary!";
      flag = false;
    }
    if (this.state.inputAmount === 0) {
      errors["inputAmount"] = "Amount cannot be 0";
      flag = false;
    }
    if (this.state.inputAmount !== '') {
      let ammount = this.state.inputAmount
      var pattern = new RegExp(/^\d+(\.\d{1,2})?$/);
      if (!pattern.test(ammount)) {
        flag = false
        errors['inputAmount'] = 'Please Enter Valid Ammount'
      }
    }
    if (this.state.refundNote === '') {
      errors["refundNote"] = "Note is compulsary!";
      flag = false;
    }
    this.setState({ errors });
    return flag;
  }

  requestAmount = async (e) => {
    e.preventDefault();
    if (this.handleValidationRefund() === true) {
      this.setState({ loadingInModal: true })
      let data = {
        "complaint_id": [this.state.refundComplaintId],
        "refund_status": this.state.refundStatus,
        "refund_amount": this.state.inputAmount,
        "notes": this.state.refundNote
      }
      if (this.amountValidation() !== false) {
        await this.props.refundEdit(data);
        this.handleRefreshList();
      }
      this.setState({ showFinanceModal: false, inputAmount: '', refundNote: '', loadingInModal: false })
    }
  }

  refundCancle = () => {
    this.setState({ showFinanceModal: false, inputAmount: '', refundNote: '' })
    this.handleRefreshList();
  }

  onPhoneClick = async () => {
    this.setState({ isLoading: true })
    await this.props.callCustomer(this.state.customerId, this.state.complaintId)
    let callRes = this.props.callRes;
    if (callRes) {
      this.setState({ showCallReminder: false, isLoading: false });
      this.getComplaintList();
    }
  }

  onItemPress = async (e, item) => {
    e.preventDefault();
    await this.props.showComplaintDetailsAction(true);
    this.setState({
      loader2: true
    })

    // Store selected Complaint
    await this.props.addStatusId(item.bucketId);
    await this.props.getComplaintDetail(item.id);
    this.setState({
      loader2: false
    });
    const element = document.querySelector(".activeColor");
    element.scrollIntoView({ block: "center" });
    // element.scrollTop();
  }

  onWarrantyChange = (e) => {
    this.setState({ warranty: e.target.value }, async () => {
      this.getComplaintList();
      await this.props.showComplaintDetailsAction(false)
    })
  }

  dateSelect = async (value) => {
    this.setState({
      startDate: new Date(value[0]),
      endDate: new Date(value[1])
    }, async () => {
      this.getComplaintList();
      await this.props.showComplaintDetailsAction(false)

    })
  }

  onStateChange = (e) => {
    this.setState({ country_id: 1, city_id: 0, state_id: Number(e.target.value) }, () => {
      // this.getStateListApi();
      this.getCityListApi();
    })
  }

  getStateListApi = async (param) => {
    await this.props.getStateList(param)
    this.setState({ stateList: [] })
    let stateDataList = this.props.stateDataList
    this.setState({
      stateList: stateDataList.data,
    })
  }

  onCityChange = (e) => {
    this.setState({ city_id: e.target.value }, () => {
      // this.getStateListApi();
      this.getCityListApi();
    })
  }

  prefixChange = (e) => {
    this.setState({ current_prefix: e.target.value })
  }

  getCityListApi = async (param) => {
    let data = {
      "state_id": this.state.state_id
    }
    await this.props.getCityByState(data)
    this.setState({ cityList: [] })
    let cityDataList = this.props.cityDataList
    this.setState({
      cityList: cityDataList.data,
    })
  }

  handleRefreshList() {
    this.props.showComplaintDetailsAction(false)
    this.getComplaintList()
  }

  onReset = () => {
    window.location.reload();
  }

  onCopy() {
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    alert("Copied!");
  }

  onStatusEdit = async (e, item) => {
    e.preventDefault();
    let data = {
      "complaint_id": [item.id],
      "refund_status": e.target.value
    }
    if (e.target.value === "111") {
      let rComplaintId = item.id;
      let product1 = item.product_name
      let rStatus = e.target.value
      this.setState({ showFinanceModal: true, refundComplaintId: rComplaintId, refundStatus: rStatus, product: product1, errors: '' })
    } else {
      let confirm = true;
      if (["104", "105", "106"].includes(data.refund_status)) {
        confirm = window.confirm("Are you sure you want to continue..?")
      }
      if (confirm) {
        await this.props.refundEdit(data)
      }
      this.handleRefreshList()
    }
  }

  handleChangeChk(e) {
    const { name, checked } = e.target;
    let tempComplaints = []
    if (name === "allSelect") {
      tempComplaints = this.state.complaintsData.map((td) => {
        return { ...td, isChecked: checked }
      })
      this.setState({
        complaintsData: tempComplaints
      })
    } else {
      tempComplaints = this.state.complaintsData.map((td) => td.id === name ? { ...td, isChecked: checked } : td)
      this.setState({
        complaintsData: tempComplaints
      })
    }
  }

  handleSubmitFApproved = async () => {
    let compIds = []
    for (let i = 0; i < this.state.complaintsData.length; i++) {
      if (this.state.complaintsData[i].isChecked) {
        compIds.push(this.state.complaintsData[i].id)
      }
    }
    if (compIds.length < 1) {
      alert("Please select atleast 1 complaint")
    } else {
      let data = {
        bulk_movement: true,
        complaint_id: compIds,
        refund_status: "105"
      }
      await this.props.refundEdit(data);
      this.handleRefreshList();
    }
  }

  handleSubmitFDeclined = async () => {
    let compIds = []
    for (let i = 0; i < this.state.complaintsData.length; i++) {
      if (this.state.complaintsData[i].isChecked) {
        compIds.push(this.state.complaintsData[i].id)
      }
    }
    if (compIds.length < 1) {
      alert("Please select atleast 1 complaint")
    } else {
      let data = {
        bulk_movement: true,
        complaint_id: compIds,
        refund_status: "106"
      }
      await this.props.refundEdit(data);
      this.handleRefreshList();
    }
  }

  handleSubmitCSDeclined = async () => {
    let compIds = []
    for (let i = 0; i < this.state.complaintsData.length; i++) {
      if (this.state.complaintsData[i].isChecked) {
        compIds.push(this.state.complaintsData[i].id)
      }
    }
    if (compIds.length < 1) {
      alert("Please select atleast 1 complaint")
    } else {
      let data = {
        bulk_movement: true,
        complaint_id: compIds,
        refund_status: "104"
      }
      await this.props.refundEdit(data);
      this.handleRefreshList();
    }
  }

  handleSubmitFPending = async () => {
    let compIds = []
    for (let i = 0; i < this.state.complaintsData.length; i++) {
      if (this.state.complaintsData[i].isChecked) {
        compIds.push(this.state.complaintsData[i].id)
      }
    }
    if (compIds.length < 1) {
      alert("Please select atleast 1 complaint")
    } else {
      let data = {
        bulk_movement: true,
        complaint_id: compIds,
        refund_status: "103"
      }
      await this.props.refundEdit(data);
      this.handleRefreshList();
    }
  }


  onDownloadReport = async () => {
    await this.props.downlodReport()
  }

  render() {
    let optionBucketDrop = [
      {
        value: 0,
        label: "Select All",
      }
    ]
    {
      {
        this.state.productList.map((obj) => (
          optionBucketDrop.push(
            {
              value: obj.product_color_id,
              label: obj.id == 239 ? obj.product_name + "(" + obj.size + "|" + obj.color_name + ")" : obj.product_name + "(" + obj.color_name + ")"
            }
          )
        ))
      }
    }
    let optionProduct = [
      {

      }
    ]
    
      {
        this.state.productList.map((obj) => (
          optionProduct.push(
            {
              // label: obj.name,
              key: obj.product_color_id,
              value: obj.product_color_id,
              label: obj.id == 239 ? obj.product_name + "(" + obj.size + "|" + obj.color_name + ")" : obj.product_name + "(" + obj.color_name + ")",
              selected: Number(obj.product_color_id) === Number(this.state.productColorId),
              // id: obj.id
            }
          )
        ))
      }
    let paginationCount = this.props.paginationCount;
    let new_page = ((paginationCount.no_page - (paginationCount.no_page - this.state.current_page)) - 1);
    let userRoleId = localStorage.getItem('userRoleId');
    if (['3', '4', '5', '6', '8', '9', '10'].includes(userRoleId)) {
      return (<AccessRestricted />)
    } else {
      return (
        <div>
         {window.innerWidth > 600 && <SidebarComponent sideProp={'RS'} />}
          <ViewProfile />
          <div className="content complaint-main">
            <span className="complaint-head">Refund</span>
            <div className="search">
              <input type="username" name="searchText" id="search_input" placeholder="Search by Ticket id, customer number..." onChange={this.handleChangeEvent} onKeyDown={this._handleKeyDown} />
              {/* {this.state.searchText &&
                <button type="button" className="close-search" onClick={this.handleClose}><i className="fa fa-times" aria-hidden="true"></i></button>
              } */}
              <button type="button" className="add-user-btn-input" onClick={this.handleChangeButton}><i className="fa fa-search user_search_icon"></i></button>
            </div>
            {!this.state.isSearch &&  !this.props.showComplaintDetails &&
              <div className='menu_wrapper'>
                <ul id="menu">
                  <li className={this.state.teamName === 'CS' ? "selectedMenu" : null} onClick={(e) => this.onMenuClick(e, "CS")}>Refund{this.state.teamName === 'CS' && paginationCount.total && !this.state.isLoading ? ' (' + paginationCount.total + ')' : ''}</li>
                  {/* <li className={this.state.teamName === 'FI' ? "selectedMenu" : null} onClick={(e) => this.onMenuClick(e, "Fi")}>Finance Team {this.state.teamName === 'FI' && paginationCount.total && !this.state.isLoading ? ' (' + paginationCount.total + ')' : ''}</li> */}
                </ul>
                {
                  !this.state.assignedStatus && <div id="st-box">
                    <div className="">
                      <h6 >Choose Status</h6>
                      <select id="bucket" className="dropbtn" onChange={(e) => this.onStatusChange(e)}>
                        <option value={"101"}> Approval Pending </option>
                        <option value={"111"}> CS Admin Pending </option>
                        <option value={"103"}> Finance Team Pending</option>
                        <option value={"104"}> CS Team Declined</option>
                        <option value={"105"}> Finance Team Approved</option>
                        <option value={"106"}> Finance Team Declined </option>
                        <option value={"107"}> User Reedemed</option>
                        <option value={"108"}> User Declined</option>
                        <option value={"109"}> Link Sent</option>
                        <option value={"110"}> Link Expire</option>
                        {/* {this.props.bucketData.map((obj, index) => (
                          <option selected={obj.id == this.props.statusId && 'selected'} value={obj.id} key={index}>{obj.name}</option>
                        ))} */}
                      </select>
                    </div>
                  </div>
                }
                {
                  <div id="nd-box" className="select-prod-comp">
                    <div className="dropdown-wrap-problem">
                      <h6>Select Product</h6>
                       <Select
                        onChange={this.onProductChange}
                        options={optionBucketDrop}
                        isSearchable={true}
                        className="select-product-css selectProductDropdown"
                        //values={this.state.productId}
                        defaultValue={this.state.selectedProduct}
                        maxMenuHeight={200}
                        placeholder="Search Product.."
                       // closeMenuOnSelect={true}
                        styles= {{
                          singleValue: provided => ({
                            ...provided,
                            color: '#fff !important'
                          }),
                          control: (baseStyles, state) => ({
                          ...baseStyles,
                          border: '1px solid #595959',
                          boxShadow: '0 !important',
                          '&:hover': {
                            border: '1px solid #595959 !important'
                          },
                          color: '#fff !important',
                            borderRadius: '20px !important',
                            backgroundColor: '#000 !important',
                            padding: '0 5px',
                            fontSize: '14px !important',
                            minHeight: '32px !important',
                            height: '32px !important'
                          }),
                          option: (baseStyles, state) => ({
                            ...baseStyles,
                            background: state.isFocused ? '#402500d7 !important':'#000 !important',
                            color: state.isFocused? '#FA9F22': '#fff !important',
                            width: '100% !important'

                          }),
                          placeholder: (baseStyles) => ({
                            ...baseStyles,
                            color: '#fff !important'
                          }),
                          indicatorSeparator: () => null,
                          valueContainer: (baseStyles) => ({
                            ...baseStyles,
                            minHeight: '32px !important',
                            height: '32px !important',
                            color: '#fff !important',
                            marginTop: '-13px !important'
                          }),
                          menuList: (baseStyles) => ({
                            ...baseStyles,
                            background: '#000 !important'
                          }),
                          Input: (baseStyles) => ({
                            ...baseStyles,
                           
                            color: '#fff !important'
                          }),
                        }}  
                      />
                    </div>
                  </div>
                }
                {
                  <div id="rd-box">
                    <div className="">
                      <h6>Date - (DD/MM/YYYY)</h6>
                      <DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(new Date(new Date().setDate(new Date().getDate() - 120))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.dateSelect(value) }} value={[new Date(this.state.startDate), new Date(this.state.endDate)]} />
                    </div>
                  </div>
                }
                {
                  <div id="dd-box">
                    <h6>More</h6>
                    <button data-toggle="modal" data-target="#modallong">More <i className="fas fa-filter"></i></button>
                    <div className="modal fade" id="modallong" tabIndex="-1" role="dialog" aria-labelledby="modallongtitle" aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modf">
                          <div className="modal-header">
                            <div className="Et">
                              <span>Fliters</span>
                            </div> <div data-dismiss="modal" aria-label="Close" id="close_filters">
                              <span aria-hidden="true"><i className="fas fa-times"></i></span>
                            </div>
                          </div>
                          {/* <div className="more5">
                            <div className="">
                              <h6>Product Problem</h6>
                              <select className="dropbtn" onChange={(e) => this.onProducdProblemChange(e)}>                              
                                <option value={'0'}> select Product Problem</option>
                                {
                                  this.props.problemData.problems && this.props.problemData.problems.length > 0 ?
                                    this.props.problemData.problems.map((obj) => (<option value={obj.id}>{obj.problem}</option>))
                                    : null
                                }                              
                              </select>
                              <div style={{ "color": "grey" }}>Note: Please Select Product</div>
                            </div>
                          </div> */}
                          <div className="more4">
                            <div className="">
                              <h6>SLA Breached</h6>
                              <select className="dropbtn" onChange={(e) => this.onSLAChange(e)}>
                                {/* <i className="fa fa-angle-down" /> */}
                                <option value={0}> select SLA Breached</option>
                                {this.state.SLAList.map((obj, index) => (
                                  <option value={obj.id} key={index}>{obj.value}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          {/* <div className="warranty">
                            <div className="">
                              <h6>Warranty</h6>
                              <select className="dropbtn" onChange={(e) => this.onWarrantyChange(e)}>
                              
                                <option> All</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                          </div> */}
                          {/* <div className="more6">
                          <button data-dismiss="modal">Apply Filters</button>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {
                  !this.state.assignedStatus && <div id="reset" onClick={(e) => this.onReset(e)}>
                    <button className="resetIcon">
                      <img src="../../../img/reset-icons.svg" alt="" /><p>Reset</p>
                    </button>
                  </div>
                }
              </div>
            }
            {this.state.isLoading ?
              <div className='loader'>
                <Loader
                  type="Oval"
                  color="Grey"
                  height={200}
                  width={200}
                />
              </div>
              : <div>
                {this.state.isSearch &&
                  <div className='search_title'>Search Results</div>
                }
                {
                  !this.props.showComplaintDetails ?
                    <div className="list-container">
                      {this.state.showSelectAll && this.props.complaintsData.length > 0 && userRoleId === "7" && this.state.isSearch === false &&
                        <div>
                          <input id="selectAll" name="allSelect" className="input-wrap-bulk" type="checkbox" onChange={this.handleChangeChk} checked={this.state.complaintsData.length > 0 ? this.state.complaintsData.filter(td => td.isChecked !== true).length < 1 : false} />
                          <label for="selectAll" style={{ color: "white" }}>Select All</label>
                          <button type="button" onClick={this.onDownloadReport} className="transferButton" style={{ float: "right", 'margin-left': '5px', 'margin-top': '5px' }}>Download Report</button>
                          <div className="financeApproved">
                          <button type="button" onClick={this.handleSubmitFApproved} className="transferButton">Finance approved</button>
                            <button type="button" onClick={this.handleSubmitFDeclined} className="transferButton">Finance Declined</button>

                          </div>
                        </div>
                      }
                      {this.state.showAllCSPending && this.props.complaintsData.length > 0 && (userRoleId === "1" || userRoleId === "2" ) && this.state.isSearch === false &&
                        <div>
                          <input id="selectAll" name="allSelect" className="input-wrap-bulk" type="checkbox" onChange={this.handleChangeChk} checked={this.state.complaintsData.length > 0 ? this.state.complaintsData.filter(td => td.isChecked !== true).length < 1 : false} />
                          <label for="selectAll" style={{ color: "white" }}>Select All</label>
                          <div className="financeApproved">
                            <button type="button" onClick={this.handleSubmitCSDeclined} className="transferButton">CS Team Declined</button>
                            <button type="button" onClick={this.handleSubmitFPending} className="transferButton">Finance pending</button>
                          </div>
                        </div>
                      }
                      {this.props.complaintsData.length > 0 ?
                        this.state.complaintsData.map((item, index) => {
                          return (
                            <div className="container6" key={index}>
                              {/* onClick={(e) => this.onItemPress(e, item)} */}
                              <div className="box" >
                                {this.state.showSelectAll && userRoleId === "7" && this.state.isSearch === false &&
                                  <div className="selectInput">
                                    <input id={'ticket-list-index-' + index} className="input-wrap-bulk" type="checkbox" name={item.id} onChange={this.handleChangeChk} value={index} checked={item.isChecked || false} />
                                    <label for={'ticket-list-index-' + index}></label>
                                  </div>
                                }
                                {this.state.showAllCSPending && ['1', '2'].includes(userRoleId) && this.state.isSearch === false &&
                                  <div className="selectInput">
                                    <input id={'ticket-list-index-' + index} className="input-wrap-bulk" type="checkbox" name={item.id} onChange={this.handleChangeChk} value={index} checked={item.isChecked || false} />
                                    <label for={'ticket-list-index-' + index}></label>
                                  </div>
                                }
                                <div className="handclass" >
                                  <div className="handclassWrapper" onClick={(e) => this.onItemPress(e, item)}>
                                    <h6>{item.ticket_id}
                                      {Number(item.is_warranty) === 1 ?
                                        <button className="active">Active</button>
                                        :
                                        <button className="active">Expire</button>
                                      }
                                    </h6>
                                    <span style={{ cursor: 'pointer' }}>{item.product_name}({item.size && "Size " + item.size + " | "}{item.color_name})</span>
                                    {item.upgradeProduct &&
                                      <span className='upg'>
                                        UPG - {item.upgradeProduct.product_name}{item.size && "Size " + item.size + " | "}{item.upgradeProduct.color_name}
                                      </span>
                                    }
                                    <p style={{ cursor: 'pointer' }}>{moment(item.created_at).format('MMMM DD, YYYY || hh:mm:ss A')} || {item.channel_name}</p>
                                  </div>
                                </div>
                                <div className="box1" onClick={(e) => this.onItemPress(e, item)}>
                                  <h6 style={{ cursor: 'pointer' }}>{item.problem}</h6>
                                  <span style={{ cursor: 'pointer' }} data-bs-toggle="tooltip" data-bs-placement="bottom" className="tooltip-white text-start" title={item.problem_description}>{item.problem_description}</span>
                                </div>
                                <div className="box2" onClick={(e) => this.onItemPress(e, item)}>
                                  {[104, 106, 108].includes(item.complaint_status_id) ?
                                    <h6 style={{ cursor: 'pointer', color: 'red' }}>{this.state.isSearch || this.state.assignedStatus ? item.bucket_name : item.complaint_status_name}</h6>
                                    :
                                    <h6 style={{ cursor: 'pointer' }}>{this.state.isSearch || this.state.assignedStatus ? item.bucket_name : item.complaint_status_name}</h6>}
                                  <span style={{ cursor: 'pointer' }}>{item.user_first_name} {item.user_last_name}</span>
                                  <p style={{ cursor: 'pointer' }}>{moment(item.updated_at).format('MMMM DD, YYYY || hh:mm:ss A')}</p>
                                </div>
                                <div className="box3">
                                  <h6 style={{ 'marginTop': '4%', 'marginLeft': '35px' }}>{item.complaint_status_name}</h6>
                                  {
                                    [101, 106, 108].includes(item.complaint_status_id) && ['1', '2'].includes(userRoleId) ?
                                      <select id="bucket" style={{ "width": '95%', 'marginBottom': '6%' }} className="dropbtn1111" onChange={(e) => this.onStatusEdit(e, item)}>
                                        <option value={0} disbled>Choose Status </option>
                                        <option value={111}> CS Admin Pending</option>
                                      </select> :
                                      [111].includes(item.complaint_status_id) && ['1', '2'].includes(userRoleId) && this.state.isSearch === true ?
                                        <select id="bucket" style={{ "width": '95%', 'marginBottom': '6%' }} className="dropbtn1111" onChange={(e) => this.onStatusEdit(e, item)}>
                                          <option value={0} disbled>Choose Status </option>
                                          <option value={103}> Finance Team Pending</option>
                                          <option value={104}> CS Team Declined</option>
                                        </select> :
                                        [103].includes(item.complaint_status_id) && userRoleId === '7' && this.state.isSearch === true ?
                                          <select id="bucket" style={{ "width": '95%', 'marginBottom': '6%' }} className="dropbtn1111" onChange={(e) => this.onStatusEdit(e, item)}>
                                            <option value={0} disbled>Choose Status </option>
                                            <option value={105} >Finance Team Approve</option>
                                            <option value={106} >Finance Team Declined</option>
                                          </select> :
                                          [104].includes(item.complaint_status_id) && ['1', '2'].includes(userRoleId) ?
                                            <select id="bucket" style={{ "width": '95%', 'marginBottom': '6%' }} className="dropbtn1111" onChange={(e) => this.onStatusEdit(e, item)}>
                                              <option value={0} disbled>Choose Status </option>
                                              <option value={111}> CS Admin Pending</option>
                                            </select> :
                                            [105].includes(item.complaint_status_id) && ['1', '2', '7'].includes(userRoleId) ?
                                              <select id="bucket" style={{ "width": '95%', 'marginBottom': '6%' }} className="dropbtn1111" onChange={(e) => this.onStatusEdit(e, item)}>
                                                <option value={0} disbled>Choose Status </option>
                                                <option value={109} >Link Sent</option>
                                                {userRoleId === '7' ?
                                                  <option value={106} >Finance Team Declined</option> : ''
                                                }
                                              </select> : ""
                                  }
                                </div>
                                <CopyToClipboard text={item.product_id == 239 ? item.ticket_id + ' ' + item.product_name + ' ' + "Size " + item.size + ' ' +item.color_name : item.ticket_id + ' ' + item.product_name + ' ' + item.color_name}
                                  onCopy={this.onCopy}>
                                  <div className='copy_icon'><i className="fa fa-copy"></i></div>
                                </CopyToClipboard>
                              </div>
                            </div>
                          )
                        })
                        :
                        <div className="no-result-text">
                          <p>No result found</p>
                        </div>
                      }
                    </div> :
                    <div className='loader'>
                      {this.state.loader2 ?
                        <Loader
                          type="Oval"
                          color="Grey"
                          height={200}
                          width={200}
                        />
                        :
                        <ComplaintDetailsNew handleRefreshList={this.handleRefreshList} refundStatus={this.state.refundStatus} />
                      }
                    </div>
                }
              </div>
            }
            <Footer new_page={new_page} onPrevPress={this.onPrevPress} onNextPress={this.onNextPress} current_page={this.state.current_page} cc={"kk"}/>
          
          </div>
          <ReactModal
            isOpen={this.state.showFinanceModal}
            contentLabel="Minimal Modal Example"
            style={customStylesFinance}
            ariaHideApp={false}
            className="refund"
          >
            {this.state.loadingInModal ?
              <div className='loader modalLoader'>
                <Loader
                  type="Oval"
                  color="Grey"
                  height={200}
                  width={200}
                />
              </div>
              :
              <div>
                <div className='modalHeader'>
                  <h2 id="rModal_head">{this.state.product}</h2>
                  <button id="close_rModal" onClick={() => this.refundCancle()}><i className="fas fa-times"></i></button>
                </div>
                <input type="text" id="inputAmount" placeholder="Enter Amount" onChange={e => {
                  let temp = e.target.value;
                  this.setState({ inputAmount: temp })
                }} value={this.state.inputAmount} />
                <div className="error_add1">
                  <span className="errorShow">{this.state.errors["inputAmount"]}</span>
                </div>
                <textarea style={{ 'width': '90%' }} type="text" id="inputRefundNote" placeholder="Enter note" onChange={e => {
                  let temp = e.target.value;
                  this.setState({ refundNote: temp })
                }} value={this.state.refundNote} ></textarea>
                <div className="error_add1">
                  <span className="errorShow">{this.state.errors["refundNote"]}</span>
                </div>
                <button id="send_rModal" type="button" onClick={this.requestAmount}>Submit</button>
                <button id="send_rModal" type="button" style={{ 'background-color': 'grey', color: 'black' }} onClick={() => this.refundCancle()}>Cancel</button>
              </div>
            }
          </ReactModal>
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
  complaintsData: state.complaints.complaintsData,
  bucketData: state.complaints.bucketData,
  statusData: state.complaints.statusData,
  productData: state.complaints.productData,
  problemData: state.complaints.problemData,
  editData: state.complaints.editData,
  callRes: state.complaints.callRes,
  complaintsDetail: state.complaints.complaintsDetail,
  courierData: state.complaints.courierData,
  statusId: state.complaints.statusId,
  showComplaintDetails: state.complaints.showComplaintDetails,
  stateDataList: state.complaints.stateData,
  cityDataList: state.complaints.cityData,
  complaintsDataCount: state.complaints.complaintsDataCount,
  refreshComplaintList: state.complaints.refreshComplaintList,
  paginationCount: state.complaints.paginationCount,
  printLabel: state.complaints.printLabel,
  rescheduleIvr: state.complaints.rescheduleIvr,
  linkGenerteL: state.complaints.linkGenerte,
  editRefund: state.complaints.editRefund
})

export default connect(mapStateToProps, {
  getComplaintData, getComplaintBuckets, getComplaintStatus, getProductList, getProductProblemList, getRpuCourier,
  editComplaintData, getComplaintDetail, callCustomer, addStatusId, showComplaintDetailsAction, getStateList,
  getCityByState, submitRPU, revokeTicket, fetchPrintLabel, rescheduleIVR, refundEdit, downlodReport
})(RefundGCComponent);