import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import "./ComplaintsComponent.css";
import ConfirmationDialog from "../ComplaintsComponent/ConfirmationDialog";
import TicketModal from "./TicketModal";
import "./RaiseComplaint.css";
import Loader from 'react-loader-spinner';
import { baseUrl } from "../../config";
class RaiseComplaint extends Component {
  constructor(props) {
    super(props);
    this.handleSetTeamName = this.handleSetTeamName.bind(this);
  }


  handleSetTeamName(value) {
    if (this.props.setTeamName) {
      this.props.setTeamName(value);
    }
  }
  state = {
    dropdownOptions: [],
    productOptions: [],
    colorOptions: [],
    problemOptions: [],
    isConfirmationOpen: false,
    isTicketModalOpen: false,
    ticketId: null,
    productGroup: "",
    selectedChannel: "",
    selectedChannelName:"",
    selectedProduct: "",
    selectedProductName:"",
    selectedColor: "",
    selectedColorName:"",
    isLoading: false,
    serialNumber: "",
    selectedProblem: "",
    selectedProblemName:"",
    subProblemOptions: [],
    selectedSubProblem: "",
    selectedSubProblemName:"",
    complaintDescription: "",
    purchaseDate: "",
    productImage: null,
    productImageError: "",
    invoiceImage: null,
    invoiceImageError: "",
    // User details
    name: "",
    phoneNumber: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    pincode: "",
    state: "",
    selectedStateName:"",
    selectedCityName:"",
    city: "",

    showImages:false,
  };

  componentDidMount() {
    this.fetchDropdownData();
    this.fetchStates(); // Fetch states on component mount
    this.fetchProductGroupData();

  }

  fetchDropdownData = async () => {
    const response = await axios.post(
      baseUrl+"-complaint/v1/registration/channel/list"
    );
    this.setState({ dropdownOptions: response.data.data });
  };

  fetchProductGroupData = async () => {

    try {
        const response = await axios.post(
            baseUrl + "-product/v1/groups/list",
        );

        const productGroups = response.data.data;

        // Find the product group with groupId 21
        const selectedGroup = productGroups.find(group => group.id === 21);
        
        this.setState({ productGroup: selectedGroup.group_name, selectedGroup });
    } catch (error) {
        console.error("Error fetching product group data:", error);
    }
};


  fetchProductData = async () => {
    if (!this.state.selectedChannel) return;

    const response = await axios.post(
      baseUrl+"-product/v1/product/list",
      { productGroupIds: [21] }
    );
    this.setState({ productOptions: response.data.data });
  };

  fetchColorData = async () => {
    if (!this.state.selectedProduct) return;
    const productId = parseInt(this.state.selectedProduct, 10);

    const response = await axios.post(
      baseUrl+"-product/v1/product-colors/list-by-product-id",
      { productIds: [productId] }
    );

    this.setState({ colorOptions: response.data.data });
  };

  fetchProblemData = async () => {
    if (!this.state.selectedProduct) return;
    const selectedProductObj = this.state.productOptions.find(
      (item) => item.id === parseInt(this.state.selectedProduct, 10)
    );
    if (!selectedProductObj) return;

    const productGroupId = selectedProductObj.product_group_id;
    const response = await axios.post(
      baseUrl+"-product/v1/problems/list-by-group-id",
      { productGroupIds: [productGroupId] }
    );

    this.setState({ problemOptions: response.data.data });
  };

  fetchStates = async () => {
    try {
      const response = await axios.post(
        baseUrl+"-master/v1/state/list",
        { country_id: 1 }
      );
      this.setState({ stateOptions: response.data.data });
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  fetchSubProblems = async (problemId) => {
    if (!problemId) return;
  
    try {
      const response = await axios.post(
        baseUrl+"-product/v1/sub-problems/list-by-problem-id",
        { problemIds: [problemId] }
      );
      this.setState({ subProblemOptions: response.data.data });
    } catch (error) {
      console.error("Error fetching sub-problems:", error);
    }
  };

  fetchCities = async (stateId) => {
    if (!stateId) return;
  
    try {
      const response = await axios.post(
        baseUrl+"-master/v1/city/list",
        { state_id: stateId }
      );
      this.setState({ cityOptions: response.data.data });
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };


  
  handleChannelChange = (event) => {
    const selectedId = event.target.value;
    const selectedItem = this.state.dropdownOptions.find(item => item.id == selectedId);
    const selectedName = selectedItem ? selectedItem.name : "";
    this.setState(
      { selectedChannel: event.target.value, selectedProduct: "", selectedColor: "" ,selectedChannelName:selectedName },
      this.fetchProductData
    );
  };

  handleProductChange = (event) => {
    const selectedId = event.target.value;
    const selectedItem = this.state.productOptions.find(item => item.id == selectedId);
    const selectedName = selectedItem ? selectedItem.product_name : "";
    this.setState({ selectedProduct: event.target.value, selectedColor: "",selectedProductName:selectedName }, () => {
      this.fetchColorData();
      this.fetchProblemData();
    });
  };

  handleColorChange = (event) => {
    const selectedId = event.target.value;
        const selectedItem = this.state.colorOptions.find(item => item.id == selectedId);
        const selectedName = selectedItem ? selectedItem.color_name : "";
    this.setState({ selectedColor: event.target.value,selectedColorName:selectedName });
  };

  handleSerialNumberChange = (event) => {
    this.setState({ serialNumber: event.target.value.trim() });
  };

  handleProblemChange = (event) => {
    const selectedProblem = this.state.problemOptions.find(
      (item) => item.id === parseInt(event.target.value, 10)
    );
    const selectedId = event.target.value;
        const selectedItem = this.state.problemOptions.find(item => item.id == selectedId);
        const selectedName = selectedItem ? selectedItem.problem : "";

    this.setState(
      { selectedProblem: selectedProblem.id, subProblemOptions: [], selectedSubProblem: "",selectedProblemName:selectedName },
      () => this.fetchSubProblems(selectedProblem.id) // Fetch sub-problems after setting problem
    );
  };

  handleSubProblemChange = (event) => {
    const selectedId = event.target.value;
    const selectedItem = this.state.subProblemOptions.find(item => item.id == selectedId);
    const selectedName = selectedItem ? selectedItem.sub_problem : "";
    this.setState({ selectedSubProblem: event.target.value,selectedSubProblemName:selectedName });
  };

  handleDescriptionChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= 250) {
      this.setState({ complaintDescription: inputText });
    }
  };

  handleConfirmSubmit = async () => {
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("email", this.state.email);
    formData.append("mobile", this.state.phoneNumber);
    formData.append("product_problem_id", this.state.selectedProblem);
    formData.append("problem_description", this.state.complaintDescription);
    formData.append("sub_product_problem_id", this.state.selectedSubProblem || "");
    formData.append("product_id", this.state.selectedProduct);
    formData.append("product_color_id", this.state.selectedColor);
    formData.append("purchase_date", this.state.purchaseDate);
    formData.append("channel_id", this.state.selectedChannel);
    formData.append("image", this.state.productImage);
    formData.append("invoice", this.state.invoiceImage);
    formData.append("address", this.state.addressLine1);
    formData.append("address2", this.state.addressLine2);
    formData.append("pincode", this.state.pincode);
    formData.append("state_id", this.state.stateId);
    formData.append("city_id", this.state.cityId);
    formData.append("product_group_id", 21);
    formData.append("serial_number", this.state.serialNumber);
    formData.append("raisedByAgent", true);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    };

    try {
      const response = await axios.post(
        baseUrl+"-complaint/v1/complaint/add/bose",
        formData,
        config
      );

      console.log("Complaint submitted successfully:", response.data);
      this.setState({
        isConfirmationOpen: false,
        isTicketModalOpen: true,
        ticketId: response.data.data.ticket_id,
        isLoading: false
      });
    } catch (error) {
      console.error("Error submitting complaint:", error);
      this.setState({
        isConfirmationOpen: false,
        isLoading: false
      });
      alert("Failed to submit complaint. Please try again.");
    }
  };

  handleDateChange = (event) => {
    this.setState({ purchaseDate: event.target.value });
  };

  handleStateChange = (event) => {
    const selectedState = this.state.stateOptions.find(
      (item) => item.state_name === event.target.value
    );
    const selectedName = event.target.value;
       if (selectedState) {
      this.setState(
        {
          state: selectedState.state_name,
          stateId: selectedState.id, // Store state ID
          city: "",
          cityId: null,
          cityOptions: [],
          selectedStateName:selectedName
        },
        () => this.fetchCities(selectedState.id) // Fetch cities after setting state
      );
    }
  };

  handleCityChange = (event) => {
    const selectedCity = this.state.cityOptions.find(
      (item) => item.city === event.target.value
    );
    const selectedName = event.target.value;
    if (selectedCity) {
      this.setState({
        city: selectedCity.city,
        cityId: selectedCity.id, 
        selectedCityName:selectedName
      });
    }
  };

  handlePhoneNumberChange = (e) => {
    const input = e.target.value;
    // Allow only numbers and ensure max length of 10
    if (/^\d{0,10}$/.test(input)) {
      this.setState({ phoneNumber: input });
    }
  };
  handleEmailChange = (e) => {
    this.setState({ email: e.target.value.trim() }); // Remove all spaces
  };
  handleProductImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const allowedExtensions = ['jpg', 'jpeg', 'png'];

      // Strict validation of file type and extension
      if (!allowedTypes.includes(file.type) || !allowedExtensions.includes(fileExtension)) {
        alert("Invalid file type! Only JPEG and PNG files are allowed.");
        this.setState({ productImageError: "Invalid file type. Only JPEG and PNG files are allowed.", productImage: null });
        e.target.value = ""; 
      } else {
        this.setState({ productImage: file, productImageError: "" });
      }
    }
  };


  handleInvoiceUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'png'];

      // Strict validation of file type and extension
      if (!allowedTypes.includes(file.type) || !allowedExtensions.includes(fileExtension)) {
        alert("Invalid file type! Only PDF, JPEG, and PNG files are allowed.");
        this.setState({ invoiceImageError: "Invalid file type. Please upload a PDF, JPEG, or PNG file.", invoiceImage: null });
        e.target.value = ""; // Clear the input field
      } else {
        this.setState({ invoiceImage: file, invoiceImageError: "" });
      }
    }
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    let token = localStorage.getItem("token");

    if(this.state.selectedProblemName !== "Pre-pass")
    {
      debugger;
    try {
      const response = await axios.post(
        baseUrl + "-complaint/v1/complaint/valid-serial-number-temp",
        { serial_number: this.state.serialNumber }, 
        {
          headers: { "x-access-token": token }
        }
      );
      
        
        if (response.data.data == true) {
            this.setState({ isConfirmationOpen: true });
        } else {
            alert("Invalid Serial Number. Please check and try again.");
        }
    } catch (error) {
        console.error("Error validating serial number:", error);
        alert("Error validating serial number. Please try again.");
    }
  }
  else{
    this.setState({ isConfirmationOpen: true });
  }
};



  render() {
    const {
      dropdownOptions,
      productOptions,
      colorOptions,
      problemOptions,
      selectedChannel,
      selectedProduct,
      selectedColor,
      productGroup,
      serialNumber,
      selectedProblem,
      complaintDescription,
      purchaseDate,
      productImage,
      productImageError,
      invoiceImage,
      invoiceImageError,
    } = this.state;

    return (
      <div className="complaint-form">
        {this.state.isLoading && (
          <div className="loader-overlay">
            <Loader
              type="Oval"
              color="#00BFFF"
              height={80}
              width={80}
            />
          </div>
        )}
        <TicketModal
          isOpen={this.state.isTicketModalOpen}
          onClose={() => this.setState({ isTicketModalOpen: false })}
          ticketId={this.state.ticketId}
          onSetTeamName={this.handleSetTeamName}
        />
        <ConfirmationDialog
          isOpen={this.state.isConfirmationOpen}
          onClose={() => this.setState({ isConfirmationOpen: false })}
          onConfirm={this.handleConfirmSubmit}
          formData={{
            name: this.state.name,
            email: this.state.email,
            mobile: this.state.phoneNumber,
            productGroup: this.state.productGroup,
            channel: this.state.selectedChannel,
            channelName: this.state.selectedChannelName,
            productName: this.state.selectedProductName,
            color: this.state.selectedColor,
            colorName:this.state.selectedColorName,
            ...(this.state.selectedProblem !==105  && { serialNumber: this.state.serialNumber }),
            problemName: this.state.selectedProblemName,
            subProblemName: this.state.selectedSubProblemName,
            description: this.state.complaintDescription,
            purchaseDate: this.state.purchaseDate,
            address: this.state.addressLine1,
            address2: this.state.addressLine2,
            pincode: this.state.pincode,
             cityName: this.state.selectedCityName,
             stateName: this.state.selectedStateName,
          //  productImage: this.state.productImage ? "Uploaded" : null,
            //invoiceImage: this.state.invoiceImage ? "Uploaded" : null
          }}
        />
        <form onSubmit={this.handleSubmit}>
          {/* Two Column Layout */}
          <div className="form-container">
            {/* Left Column - Product Details */}
            <div className="form-column">
            <div className="form-group">
  <label>Product Group*</label>
  <select disabled>
    <option>{this.state.productGroup || "Loading..."}</option>
  </select>
</div>


              <div className="form-group">
                <label>Select Channel of Purchase*</label>
                <select value={selectedChannel} onChange={this.handleChannelChange} required>
                  <option value="">-- Select --</option>
                  {dropdownOptions.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>Select Product*</label>
                <select value={selectedProduct} onChange={this.handleProductChange} disabled={!selectedChannel} required>
                  <option value="">-- Select Product --</option>
                  {productOptions.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.product_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>Select Product Color*</label>
                <select value={selectedColor} onChange={this.handleColorChange} disabled={!selectedProduct} required>
                  <option value="">-- Select Color --</option>
                  {colorOptions.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.color_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>Select Problem*</label>
                <select value={selectedProblem} onChange={this.handleProblemChange} disabled={!selectedProduct} required>
                  <option value="">-- Select Problem --</option>
                  {problemOptions.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.problem}
                    </option>
                  ))}
                </select>
              </div>

              {this.state.subProblemOptions.length > 0 && (
                <div className="form-group">
                  <label>Select Sub-Problem*</label>
                  <select value={this.state.selectedSubProblem} onChange={this.handleSubProblemChange} required>
                    <option value="">-- Select Sub-Problem --</option>
                    {this.state.subProblemOptions.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.sub_problem}
                      </option>
                    ))}
                  </select>
                </div>
              )}
{this.state.selectedProblem !== 105 && (
  <div className="form-group">
    <label>
      Serial Number*
      <p 
        style={{ marginBottom: "0rem" }}  
        className="serial-number-help" 
        onClick={() => this.setState({ showImages: !this.state.showImages })}
      >
        (How to find your serial number?) CLICK
      </p>
    </label>
    <input
      type="text"
      value={this.state.serialNumber}
      onChange={this.handleSerialNumberChange}
      placeholder="Enter Serial Number"
      required
    />
  </div>
)}


{this.state.showImages && (
  <div className="serial-number-images-popup">
    <div className="serial-number-images-popup-inner-wrapper">
      <div className="popup-header">
        <h2>Locating the Serial Number</h2>
        <button 
          className="popup-close-btn" 
          onClick={() => this.setState({ showImages: false })}
        >
          &times;
        </button>
      </div>
      <p>Here's an example of what a serial number on a product can look like:</p>
      <div className="serial-number-images">
        <img
          src="https://cdn.shopify.com/s/files/1/0587/5898/7944/files/Serial_number1.jpg?v=1740549067"
          alt="Serial number location 1"
          className="serial-image"
        />
        <img
          src="https://cdn.shopify.com/s/files/1/0587/5898/7944/files/Serial_number2.jpg?v=1740549071"
          alt="Serial number location 2"
          className="serial-image"
        />
      </div>
    </div>
  </div>
)}




              <div className="form-group">
                <label>Complaint Description (Max 250 characters)*</label>
                <textarea
                  value={complaintDescription}
                  onChange={(e) => {
                    const value = e.target.value.replace(/^\s+/, ''); // Remove leading spaces

                    this.setState({ complaintDescription: value });
                  }}
                  placeholder="Describe the issue..."
                  maxLength="250"
                  rows="3"
                  required
                />
                <small style={{ color: 'white' }}> {250 - complaintDescription.length} characters remaining</small>
              </div>


              <div className="form-group">
                <label>Date of Purchase*</label>
                <input type="date" value={purchaseDate} onChange={this.handleDateChange} max={new Date().toISOString().split('T')[0]} required />
              </div>

              {/* <div className="form-group">
                <label>Product Image (JPEG, PNG)*</label>
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={this.handleProductImageUpload}
                  required
                />
                {this.state.productImage && <p style={{ color: 'white' }}>Selected File: {this.state.productImage.name}</p>}
                {this.state.productImageError && <p className="error">{this.state.productImageError}</p>}
              </div> */}


              {/* <div className="form-group">
                <label>Invoice (PDF, JPEG, PNG)*</label>
                <input type="file" accept="application/pdf, image/jpeg, image/png" onChange={this.handleInvoiceUpload} required />
                {this.state.invoiceImage && <p style={{ color: "white" }}>Selected File: {this.state.invoiceImage.name}</p>}
                {this.state.invoiceImageError && <p className="error" style={{ color: "red" }}>{this.state.invoiceImageError}</p>}
              </div> */}
            </div>

            {/* Right Column - User Details */}
            <div className="form-column">
              <div className="form-group">
                <label>Customer Name*</label>
                <input
                  type="text"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value.trimStart() })}
                  onBlur={(e) => this.setState({ name: e.target.value.trim() })}
                  placeholder="Enter customer name"
                  required
                />
              </div>


              <div className="form-group">
                <label>Phone Number*</label>
                <input
                  type="tel"
                  value={this.state.phoneNumber}
                  onChange={this.handlePhoneNumberChange}
                  placeholder="Enter customer phone number"
                  required
                />
              </div>

              <div className="form-group">
                <label>Email*</label>
                <input
                  type="email"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  placeholder="Enter customer email"
                  required
                />
              </div>


              <div className="form-group">
                <label>Address Line 1*</label>
                <input
                  type="text"
                  value={this.state.addressLine1}
                  onChange={(e) => this.setState({ addressLine1: e.target.value.replace(/^\s+/, '') })}
                  placeholder="Enter address line 1"
                  required
                />
              </div>

              <div className="form-group">
                <label>Address Line 2</label>
                <input
                  type="text"
                  value={this.state.addressLine2}
                  onChange={(e) => this.setState({ addressLine2: e.target.value.replace(/^\s+/, '') })}
                  placeholder="Enter address line 2"
                />
              </div>


              <div className="form-group">
                <label>Pincode*</label>
                <input
                  type="text"
                  value={this.state.pincode}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                    if (value.length <= 6) {
                      this.setState({ pincode: value });
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value.length !== 6) {
                      alert("Pincode must be exactly 6 digits.");
                      this.setState({ pincode: "" }); // Clear input if invalid
                    }
                  }}
                  placeholder="Enter pincode"
                  required
                />
              </div>


              <div className="form-group">
                <label>State*</label>
                <select value={this.state.state} onChange={this.handleStateChange} required>
                  <option value="">-- Select State --</option>
                  {this.state.stateOptions?.map((item) => (
                    <option key={item.id} value={item.state_name}>
                      {item.state_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>City*</label>
                <select value={this.state.city} onChange={this.handleCityChange} required disabled={!this.state.state}>
                  <option value="">-- Select City --</option>
                  {this.state.cityOptions?.map((item) => (
                    <option key={item.id} value={item.city_name}>
                      {item.city}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <button type="submit">Submit</button>
        </form>
      </div>
    );

  }
}

export default connect((state) => ({
  complaintData: state.complaints.complaintData,
}))(RaiseComplaint);
