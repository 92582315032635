import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  ServiceCenterComplaintData
} from '../../actions/serviceCenterActions';
import { fetchComplaintDetails,TroubleShootShow } from '../../actions/serviceCenterComplaintAction'
import moment from 'moment'
import { useHistory } from 'react-router-dom';
// import "./ServiceCenter.css"
import Loader from 'react-loader-spinner';

function ActiveComplaints() {

  const history = useHistory();
  const dispatch = useDispatch();


  const initialValues = {
    totalPage: 1,
    totalCount: 0,
    prevPage: false,
    nextPage: false,
    current_page: 1,
    pageLimit: 12,
    searchTextLength: 0,
    searchText: '',
    serviceCenterID: '',
    startDate: new Date().setDate(new Date().getDate() - 90),
    endDate: new Date().setDate(new Date().getDate()),

  };

  const [values, setValues] = useState(initialValues);
  const [isLoading, setisLoading] = useState(false);

  //   useEffect(() => {
  //     let sc_address = localStorage.getItem('scAddress');
  //     let serviceAdress =JSON.parse(sc_address)
  //     let data={  
  //       "ticketStatus": "active",
  //       "limit": 10,
  //       "offset": 0,
  //       "service_center_id": serviceAdress.service_center_id,
  //       "page_no": 1,
  //        "startDate": moment(values.startDate).format('YYYY-MM-DD'),
  //       "endDate": moment(values.endDate).format('YYYY-MM-DD'),
  //     }
  //   dispatch(ServiceCenterComplaintData(data))
  //  },[])

  useEffect(() => {
    getComplaintList()
  }, [values.current_page]);





  const getComplaintList = async () => {
    let scAddress = localStorage.getItem('scAddress');
    let serviceAdress = JSON.parse(scAddress)
    let data = {
      "ticketStatus": "active",
      "limit": 12,
      "offset": 0,
      "service_center_id": serviceAdress.service_center_id,
      "page_no": values.current_page,
      "endDate": moment(values.endDate).format("YYYY-MM-DD"),
      "startDate": moment(values.startDate).format("YYYY-MM-DD"),
      "tag": "panel",
    }
    setisLoading(true)
    await dispatch(ServiceCenterComplaintData(data))
    setisLoading(false)
    setValues({
      ...values,
      totalCount: serviceData.total,
      totalPage: serviceData ? serviceData.no_page : 0,
      prevPage: serviceData ? serviceData.prev_page : 0,
      nextPage: serviceData ? serviceData.next_page : 1,
    })
  }


  const onPrevPress = () => {
    if (serviceData.prev_page) {
      setValues({ ...values, current_page: (values.current_page - 1) }, async () => {
        getComplaintList()
      })
    }
  }

  const onNextPress = () => {
    if (serviceData.next_page) {
      setValues({ ...values, current_page: (values.current_page + 1) }, async () => {
        getComplaintList()
      })
    }
  }
  // const paginationCount = useSelector((state)=> state.serviceCenter.paginationCount)
  const serviceData = useSelector((state) => state.serviceCenter.serviceNewData)
  const serviceComplaints = serviceData && serviceData.complaint_data





  let new_page = ((serviceData && serviceData.no_page - (serviceData && serviceData.no_page - values.current_page)) - 1);

  const handleTicketClick = (e, item) => {
    dispatch(fetchComplaintDetails(item.id));
    dispatch(TroubleShootShow(false))
    history.push('/complaint-details')
  }

  return (
    <>
      <div className='activeComplaints mainContent'>
        <h1 className='heading'>Active</h1>
        {isLoading ?
          <div className='loader'>
            <Loader
              type="Oval"
              color="Grey"
              height={200}
              width={200}
              className='loaderNew'
            />
          </div> :
          <div>
            <div className='complaits_container'>
              {
                serviceComplaints && serviceComplaints.map((item, index) => {
                  return (
                    <div key={item.id} className="ticket-wrapper" onClick={e => handleTicketClick(e, item)}>
                      <span id='header1'>Complaint No. {item.ticket_id}</span>
                      <p id='insideline'></p>
                      <div className='product_details1'>
                        <p className='products_info' >
                          <span className='productname'>Product Name: </span>
                          <span id='nm1'>{item.product_name}</span>
                        </p>
                        <p className='products_info' >
                          <span className='productname'>Status: </span>
                          <span id='nm1'>{item.complaint_status_name}</span>
                        </p>
                        <p className='products_info' >
                          <span className='productname'>Date: </span>
                          <span id='nm1'>{moment(item.updated_at).format('DD-MM-YYYY')} </span>
                        </p>
                      </div>
                    </div>)

                })
              }
            </div>

            {serviceComplaints && serviceComplaints.length ?
              <div className="footers">
                <div className="pages1">
                  {serviceData && serviceData.total > 0 ?
                    <div>
                      {serviceData && serviceData.no_page === 1 ?
                        <p style={{ color: 'black', fontWeight: "500" }}>
                          1 - {serviceData && serviceData.total} of {serviceData && serviceData.total} results
                        </p>
                        :
                        <p>
                          {values.pageLimit * new_page > 1 ?
                            values.pageLimit * new_page
                            :
                            '1'
                          }
                          -
                          {((values.pageLimit * values.current_page) > (serviceData && serviceData.total)) && ((serviceData && serviceData.total) > (values.pageLimit * new_page)) ?
                            (serviceData && serviceData.total)
                            :
                            (values.pageLimit * values.current_page)} of {serviceData && serviceData.total} results
                          <i className="fa fa-chevron-left" style={{ color: "black" }} onClick={onPrevPress} />
                          <span>{values.current_page}</span>
                          <i className="fa fa-chevron-right" style={{ color: "black" }} onClick={onNextPress} />
                        </p>
                      }
                    </div>
                    : <div className="Noreult">
                      <span>No complaints found</span>
                    </div>
                  }
                </div>
              </div> : <span>No complaints found</span>}
          </div>}
      </div>
    </>
  )
}

export default ActiveComplaints