import React, { useState } from "react";
import ReactModal from "react-modal";
import "./CustomerForm.css";

const ContactForm = ({ sendBoseSms2, customStylesDO }) => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({ name: "", mobile: "" });

  const handleNameChange = (e) => {
    let value = e.target.value;

    if (!/^[A-Za-z]*$/.test(value.charAt(0))) {
      value = value.trimStart();
    }
    
    value = value.replace(/[^A-Za-z\s]/g, ""); 

    if (value.length > 25) {
      value = value.slice(0, 25); 
    }

    setName(value);
  };

  const handleMobileChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    setMobile(value);
  };

  const handleMessageSubmit = async (e) => {
    e.preventDefault();

    if (!name || !mobile) {
      alert("Both fields are required.");
      return;
    }

    const mobileRegex = /^[6-9]\d{9}$/;
    if (!mobileRegex.test(mobile)) {
      alert("Invalid mobile number");
      return;
    }

    setShowModal(true);
  };

  const confirmSubmit = async () => {
    try {
      await sendBoseSms2({ mobile, name });
      console.log("Message sent successfully.");
      setName("");
      setMobile("");
      setShowModal(false);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const cancelSubmit = () => {
    setShowModal(false);
  };

  return (
    <div className="contact-form-container">
      <form onSubmit={handleMessageSubmit}>
        <div>
          <label htmlFor="name">Customer Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={handleNameChange}
            maxLength={45} 
          />
        </div>
        
        <div>
          <label htmlFor="mobile">Mobile Number:</label>
          <input
            type="text"
            id="mobile"
            value={mobile}
            onChange={handleMobileChange}
            maxLength={10} 
          />
        </div>
        
        <button type="submit" className="round">
          Submit
        </button>

        {showModal && (
          <ReactModal
            isOpen={showModal}
            contentLabel="Confirm Submission"
            style={customStylesDO}
            ariaHideApp={false}
            className="confirmationModal"
          >
            <div className="modalHeader">
              <h2 id="modal_head">Confirm Submission</h2>
              <button id="closeModal" onClick={cancelSubmit}>
                <i className="fas fa-times"></i>
              </button>
            </div>
            <p style={{ color: "white" }}>
              <strong>Name:</strong> {name}
            </p>
            <p style={{ color: "white" }}>
              <strong>Mobile:</strong> {mobile}
            </p>
            <div className="modal-buttons">
              <button type="button" onClick={confirmSubmit} className="round">
                Confirm
              </button>
              <button type="button" onClick={cancelSubmit} className="round">
                Cancel
              </button>
            </div>
          </ReactModal>
        )}
      </form>
    </div>
  );
};

export default ContactForm;
