import React, { Component } from 'react'
import './ReportComponent.css';
import { connect } from "react-redux";
import history from '../../history';
// import moment from 'moment'
// import Loader from 'react-loader-spinner'
import { getGroupData } from '../../actions/reportActions'
import AccessRestricted from '../AccessRestricted'
import ComplaintRegistration from '../ComplaintRegistration';
import SidebarComponent from '../SidebarComponent/SidebarComponent';


class CourierComponent extends Component {
  constructor() {
    super();

    this.state = {
      //showMenu: false,
      //pageNo: 1,
      //pageLimit: 10,
      searchText: '',
      //searchDate: '',
      groupList: [],
      //prevPage: false,
      //nextPage: false,

      userId: '',
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      mobile: '',
      accessLavel: '',
      reportingTo: '',
      isReporter: '',
      first_name: '',
      last_name: '',
      username: ''

    }

    this.showMenu = this.showMenu.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onClearForm = (e) => {
    this.setState({
      firstName: '',
      lastName: '',
      mobile: '',
      email: '',
      is_reporter: '',
      reporting_to: '',
    })
  }

  componentDidMount() {
    this.getGroupList();
  }

  onPrevPress = () => {
    if (this.state.prevPage) {
      this.setState({ pageNo: (this.state.pageNo - 1) }, () => {
        this.getGroupList()
      })
    }
  }

  onNextPress = () => {
    if (this.state.nextPage) {
      this.setState({ pageNo: (this.state.pageNo + 1) }, () => {
        this.getGroupList()
      })
    }
  }

  getGroupList = async () => {
    let data = {
      //"page_no": this.state.pageNo,
      //"limit": this.state.pageLimit,
      //"status_by": this.state.searchText,
      //"problem_by": 1,
      //"search_by_date": this.state.searchDate
      "filterText": this.state.searchText
    }
    this.setState({ isLoading: true, groupList: [] })
    //await this.props.getUserData(data);
    await this.props.getGroupData(data);
    let groupData = this.props.groupData;

    this.setState({
      //   isLoading: false,
      //   prevPage: usersData.data.prev_page,
      //   nextPage: usersData.data.next_page,
      groupList: groupData.data
    })
  }

  handleEdit = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }


  onEditClick = (e, item) => {
    this.setState({
      userId: item.id,
      firstName: item.first_name,
      lastName: item.last_name,
      userName: item.username,
      email: item.email,
      mobile: item.mobile,
      accessLavel: '',
      reportingTo: item.reporter_username,
      isReporter: ''
    })
  }

  showMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true });
  }

  handleDate = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.getUserList();
    })
  }

  //this.setState({ [e.target.name]: e.target.value })

  handleChange = (e) => {
    if (e.target.name === 'searchByusername') {
      this.setState({ searchText: e.target.value }, () => {
        this.getUserList();
      })
    } else {
      this.setState({ [e.target.name]: e.target.value }, () => {

      })
    }
  }



  handleSubmit(e) {
    e.preventDefault();
    let data = {
      'first_name': this.state.first_name,
      'last_name': this.state.last_name,
      'email': this.state.username,
      'username': this.state.first_name + this.state.last_name,
      'mobile': this.state.mobile,
      'is_reporter': '0',
      'reporting_to': '1'
    }

    this.props.createUser(data);
    // let addresponce = this.props.lotResponse;
    // if (this.props.lotResponse.success) {
    alert(this.props.lotResponse.message);
    this.onClearForm();
    this.props.location && this.props.location.callBack && this.props.location.callBack()
    history.goBack();
    //}
  }

  onUpdateClick = (e) => {
    e.preventDefault()
    let data = {
      "user_id": this.state.userId,
      "first_name": this.state.firstName,
      "last_name": this.state.lastName,
      "email": this.state.email,
      "mobile": this.state.mobile,
      "username": this.state.userName,
      "reporting_to": this.state.reportingTo,
      "is_reporter": this.state.isReporter
    }
    this.callEditApi(data)
  }

  onDeleteClick = (e) => {
    e.preventDefault()
    let data = {
      "user_id": this.state.userId,
      "first_name": this.state.firstName,
      "last_name": this.state.lastName,
      "email": this.state.email,
      "mobile": this.state.mobile,
      "username": this.state.userName,
      "is_active": '0'
    }
    this.callEditApi(data)
  }



  callEditApi = async (param) => {
    await this.props.editUserData(param)
    // let editRes = this.props.editData
    this.getUserList();
  }
  render() {
    let userRoleId = localStorage.getItem('userRoleId');
    if(userRoleId === '4' || userRoleId === '8' || userRoleId === '9' || userRoleId === '10'){
      return( <AccessRestricted/>)
    }
    else if (userRoleId === '6'){
      return (<ComplaintRegistration />)
  }
    else{
    return (
      <div>
        { window.innerWidth > 600 && <SidebarComponent sideProp={'LB'} /> }
        <div className="nav">
          <span> 
            {/* <i className="fa fa-bell"></i> */}
            <i className="fas fa-user-circle"></i> </span>
        </div>
        <div className="content">
          <span>Product</span>
          <ul id="menu">
            <li><a href="/manage-product" className="noactive">Product Info</a></li>
            <li><a href="/manage-group" className="actice2">Product Group</a></li>
            <li><a href="/manage-message" className="noactive">Message</a></li>

          </ul>
        </div>
        <div className="list">
          <i className="fa fa-search"></i>
          <input type="username" name="searchByusername" placeholder=" Search" onChange={this.handleChange} />
          <button type="button" data-toggle="modal" data-target="#exampleModal">
            Add Group
          </button>

          <div className="modal fade" id="exampleModal">
            <div className="modal-dialog" role="document">
              <div className="modc">
                <form action="" onSubmit={this.handleSubmit}>
                  <div className="modal-header">

                    <div className="list2">
                      <span>Add User</span>
                    </div>
                    <div data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true"><i className="fas fa-times"></i></span>
                    </div></div>

                  <div className="label">
                    <p>First Name</p>
                    <input type="text" name="first_name" onChange={this.handleChange} placeholder="First Name" />
                  </div>
                  <div className="labels1">
                    <p>User</p>
                    <input type="text" name="username" onChange={this.handleChange} placeholder="name@company.com" />
                  </div>
                  <div className="labels2">
                    <p>Contact Number</p>
                    <input class type="text" name="mobile" maxLength="10" onChange={this.handleChange} placeholder="99XXXXXXX9" ></input>

                  </div>
                  <div className="la3">
                    <p>Last Name</p>
                    <input type="text" name="last_name" onChange={this.handleChange} placeholder="Last Name" />
                  </div>
                  <div className="labl1">
                    <p>Access Lavel</p>

                    <select>
                      <option>User</option></select></div>
                  <div className="labl2">
                    <p>Reporting To</p>

                    <select>
                      <option>Peter Parker</option>
                    </select>
                  </div>


                  {/* <div className="sign">
    <i className="fas fa-plus-circle"></i> Add Another</div> */}
                  <div className="addbtn">
                    <button onClick={this.handleSubmit}>Add User</button></div>
                </form>
              </div>

            </div>
          </div>
        </div>

        <div className="modal fade" id="viewModal" >
          <div className="modal-dialog" role="document">
            <div className="modc">
              <div className="modal-header">

                <div className="list2">
                  <span>Edit User</span>
                </div>
                <div data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><i className="fas fa-times"></i></span>
                </div>
              </div>

              <div className="label">
                <p>First Name</p>
                <input type="text" name="first_name" onChange={this.handleChange} placeholder="First Name" />
              </div>
              <div className="labels1">
                <p>User</p>
                <input type="text" name="username" onChange={this.handleChange} placeholder="name@company.com" />
              </div>
              <div className="labels2">
                <p>Contact Number</p>
                <input class type="text" name="mobile" maxLength="10" onChange={this.handleChange} placeholder="99XXXXXXX9" ></input>

              </div>
              <div className="la3">
                <p>Last Name</p>


                <input type="text" name="last_name" onChange={this.handleChange} placeholder="Last Name" />
              </div>
              <div className="labl1">
                <p>Access Lavel</p>

                <select>
                  <option>User</option></select></div>
              <div className="labl2">
                <p>Reporting To</p>

                <select>
                  <option>Peter Parker</option>
                </select>
              </div>

              <div className="addbtn">
                <button onClick={(e) => this.onUpdateClick(e)}>Update User</button></div>

            </div>

          </div>
        </div>


        <div className="colrow">
          <div className="row customrow">
            <div className="col-sm">Product Group</div>
          </div>

          {this.state.groupList.length > 0 ?
            this.state.groupList.map((item) => {
              return (
                <div className="row customrow">
                  <div className="col">
                    <p>{item.group_name ? item.group_name : 'NA'}</p>

                    <div className="dropdown">
                      <button className="btn btn-link rightclass" type="button" id="dropdownMenuButton" data-toggle="dropdown" ria-expanded="false">
                        <i className="fas fa-ellipsis-h"></i>
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" onClick={(e) => this.onEditClick(e, item)}>
                        <a className="dropdown-item" href="" data-toggle="modal" data-target="#viewModal"> <i className="fas fa-pencil-alt"></i>Edit</a>
                        <a className="dropdown-item" href="" onClick={(e) => this.onDeleteClick(e)} ><i className="fas fa-exclamation-circle"></i>Remove</a>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
            :
            <div className="no-result-text">
              <p>No result found</p>
            </div>
          }
        </div>
        <div className="footer">
          <div className="pages">
            <span>Display</span>
            <button>
              1
            </button>
            <p>Item per page</p>
          </div>
          <div className="pages1">
            <p>1-1 of 1 results
              <i className="fas fa-chevron-left" />
              <button>1</button>
              <i className="fa fa-chevron-right" />
            </p>
          </div></div>
      </div>
    )
        }
  }
}
const mapStateToProps = state => ({
  groupData: state.product.groupData
})

export default connect(mapStateToProps, { getGroupData })(CourierComponent);