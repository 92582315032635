import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import CutomerDetails from './Retail/CustomerDetails';
import ProductDetails from './Retail/ProductDetails';
import History from './Retail/History';
import Conversations from './Retail/Conversations';
import Comments from './Retail/Comments';
import ReactModal from 'react-modal';
import { showComplaintDetailsAction } from '../../actions/complaintsDataAction';
import {
  getSCComplaintDetail, getSCRpuCourier, submitSCRPU, fetchSCPrintLabel, onhandOverToCourier, onRevokeAction, onIQCPassAction, onIQCFailAction
  , onClosedAction, onRevokeInvAction
} from '../../actions/serviceCenterActions';
import { moveToDispatchInward } from "../../actions/serviceCenterActions";
import 'react-medium-image-zoom/dist/styles.css';
import Loader from 'react-loader-spinner';
import ComponentToFPUPrint from './PrintComponents/ComponentToFPUPrint';
import SCActions from './SCActions';

const customStylesDO = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    inset: '8% 0 0 auto',
    marginRight: '0',
    transform: 'translate(0)',
    background: '#000',
    padding: '2rem',
    border: 'none',
    width: '35%',
    position: 'absolute'
  },
};
const customStylesRPU = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    overflow: "scroll"
  },
  content: {
    inset: '8% 0 0 auto',
    marginRight: '0',
    transform: 'translate(0)',
    background: '#fff',
    padding: '2rem',
    border: 'none',
    width: '100%',
    position: 'absolute',
    overflow: 'scroll',
  },
};

class SCComplaintDetailsNew extends Component {
  constructor() {
    super();
    this.state = {
      ticketReceived: true,
      approvedTicket: true,
      subMenu: 1,
      click: 0,
      showLoader: false,
      complaintId: 0,
      showFPUModal: false,
      courierId: 0,
      showFPUPrintModal: false,
    };
    // this.handleRefreshList = this.props.handleRefreshList.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.handleDispatchInward = this.handleDispatchInward.bind(this);
    this.onFPUSubmit = this.onFPUSubmit.bind(this);
    this.handleCourierChange = this.handleCourierChange.bind(this);
    this.handlePrintModalClose = this.handlePrintModalClose.bind(this);
    this.handleFPUCloseModal = this.handleFPUCloseModal.bind(this);
  }

  onSubMenuClick = (e) => {
    this.setState({ subMenu: e.target.value }, () => {
      // this.getComplaintList();
    });
  };

  onItemPress = async (e, item) => {
    this.setState({ showLoader: true });
    await this.props.getSCComplaintDetail(item.id);
    this.setState({ showLoader: false, subMenu: 1 });
    const element = document.querySelector(".activeColor");
    if (element) {
      element.scrollIntoView({ block: "center" });
    }
  };

  onCloseDetail = (e) => {
    e.preventDefault();
    this.props.showComplaintDetailsAction(false);
  };

  onDownloanInvoice = (url) => {
  };

  zoomIn() {
    this.setState({ click: this.state.click + 1 });
    if (this.state.click > 1) {
      this.setState({ click: 2 });
    }
  }

  zoomOut() {
    this.setState({ click: this.state.click - 1 });
    if (this.state.click < 1) {
      this.setState({ click: 0 });
    }
  }

  async handleDispatchInward() {
    let data = {
      complaint_id: this.props.complaintsDetail.id
    };
    await this.props.moveToDispatchInward(data);
    this.props.handleRefreshList();
  }

  handleFPUOpenModal = async (e, item) => {
    let data = {
      "complaint_id": item,
      "type": "forward_pick_up"
    };
    this.setState({ complaintId: item });
    this.setState({
      isLoading: true
    });
    await this.props.getSCRpuCourier(data);
    this.setState({ isLoading: false, showFPUModal: true });
  };

  handleFPUCloseModal() {

    this.setState({ showFPUModal: false });
  }

  async handleCourierChange(e) {
    this.setState({
      courierId: e.target.value
    });
  }

  onFPUSubmit = async () => {
    let cId = this.state.courierId;
    let selectedC = this.props.courierSCData.filter((cd) => Number(cd.courier_id) === Number(cId));
    if (selectedC.length > 0) {
      this.setState({ isLoadingGif: true });
      let data = {
        "complaint_id": this.state.complaintId,
        "courier_id": cId,
      };
      await this.props.submitSCRPU(data);
      this.setState({
        isLoadingGif: false,
      });
      this.setState({
        showFPUModal: false
      });
      this.props.handleRefreshList();
    } else {
      alert("Please select courier");
      return;
    }
  };

  handleFPUPrint = async (e, item) => {
    let complaintId = item;
    this.setState({ complaintId: complaintId });
    let data = {
      "complaint_id": item,
      "type": "forward_pick_up"
    };
    await this.props.fetchSCPrintLabel(data);
    if (this.props.printSCLabel) {
      //history.push('/fpu-print')
      this.setState({ showFPUPrintModal: true });
    } else {
      alert("no courier data found");
    }
  };

  handlePrintModalClose() {
    this.setState({
      showFPUPrintModal: false
    }, () => {
    });
  }

  handleHandover = async (e, item) => {
    let data = {
      "complaint_id": item
    };
    await this.props.onhandOverToCourier(data);
    this.props.handleRefreshList();
  };

  handleRevokeTicket = async (e, item) => {

    await this.props.onRevokeAction(item);
    this.props.handleRefreshList();
  };


  handleRevokeINVTicket = async (e, item) => {
    await this.props.onRevokeInvAction(item);
    this.props.handleRefreshList();
  };

  handleIqcPass = async (e, item) => {
    let data = {
      "complaint_id": item
    };
    await this.props.onIQCPassAction(data);
    this.props.handleRefreshList();
  };

  handleIqcFail = async (e, item) => {
    let data = {
      "complaint_id": item
    };
    await this.props.onIQCFailAction(data);
    this.props.handleRefreshList();
  };

  handleclosedTicket = async (e, item) => {
    let data = {
      "complaint_id": item
    };
    await this.props.onClosedAction(data);
    this.props.handleRefreshList();
  };





  render() {
    return (
      <div className="main-container">
        {this.state.showLoader ?
          <div className='loader'>
            <Loader
              type="Oval"
              color="Grey"
              height={200}
              width={200}
            />
          </div>
          :
          <div>
            <div className="left-container">
              {this.props.sccomplaintsData.length > 0 ?
                this.props.sccomplaintsData.map((item, index) => {
                  return (
                    <div onClick={(e) => this.onItemPress(e, item)} key={index} >
                      {this.props.complaintsDetail ?
                        <div className={this.props.complaintsDetail.id === item.id ? "container7 activeColor" : "container7"} >
                          <div className="box">
                            <h6>{item.ticket_id}
                              {Number(item.is_warranty) === 1 ?
                                <button className="active ">Active</button>
                                :
                                <button className="active">Expire</button>
                              }
                            </h6>
                            <span style={{ cursor: 'pointer' }}>{item.product_name}({item.color_name})</span>
                            {item.upgradeProduct &&
                              <span className='upg'>
                                UPG - {item.upgradeProduct.product_name} + {item.upgradeProduct.color_name}
                              </span>
                            }
                            <p style={{ cursor: 'pointer' }}>{moment(item.created_at, ["YYYY-MM-DD || HH.mm"]).format('MMMM DD, YYYY || hh:mm A')} || {item.channel_name}</p>
                          </div>
                        </div>
                        :
                        <div></div>
                      }
                    </div>
                  );
                })
                :
                <div className="no-result-text">
                  <p>No result found</p>
                </div>
              }
            </div>
            {this.props.sccomplaintsData.length > 0 ?
              <div>
                {this.props.complaintsDetail ?
                  <div className="right-container">
                    <div className="box">
                      <h6>{this.props.complaintsDetail.ticket_id}
                        {Number(this.props.complaintsDetail.is_warranty) === 1 ?
                          <button className="active">Active</button>
                          :
                          <button className="active">Expire</button>
                        }
                      </h6>
                      <div className="ppt setwidth">
                        <p>{this.props.complaintsDetail.customer ? this.props.complaintsDetail.customer.name : null} || {moment(this.props.complaintsDetail.updated_at).format('MMMM DD, YYYY')} || {this.props.actionOptions == true ? this.props.complaintsDetail.inv_status_name : this.props.complaintsDetail.status_name}</p>
                      </div> <div className="ppti abc">  <i className="fas fa-times" onClick={(e) => this.onCloseDetail(e)}></i></div>
                      <div className="details">  <h6>{this.props.complaintsDetail.product.problem}</h6>
                      </div>
                      <div>
                        {this.props.actionOptions === false ?
                          [4, 17, 18, 19, 20, 21, 33, 35, 41, 42, 43, 45,
                          ].includes(this.props.statusId) ?
                            <div className="col">
                              <button onClick={this.actions} className="bbts" id="dropdownMenuButton" data-toggle="dropdown">
                                <i className="fas fa-ellipsis-v"></i>Actions
                              </button>
                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {/* {[74].includes(this.props.complaintsDetail.complaint_status_id) ?
                                  <a className="dropdown-item"  onClick={(e) => this.handleDispatchInward(e, this.props.complaintsDetail.id)}><img src="../../img/Complaint-Closed.svg" alt="" />Dispatch Inward</a>
                                  // <i className="fa fa-arrow-left" title="Dispatch Inward" onClick={(e)=> this.handleDispatchInward(e, this.props.complaintsDetail.id)}></i>
                                  : null}
                                {[44, 46, 47, 48, 49, 50, 51, 61, 62, 63, 64, 65, 66, 67, 91, 92].includes(this.props.complaintsDetail.complaint_status_id) ?
                                  <a className="dropdown-item"  onClick={(e) => this.handleFPUOpenModal(e, this.props.complaintsDetail.id)}><img src="../../img/Complaint-Closed.svg" alt="" />Dispatch OutWard</a>
                                  : null}
                                {[19, 20, 21, 22, 23, 14, 15, 16, 17, 38, 39, 68, 69, 70, 18, 5].includes(this.props.complaintsDetail.complaint_status_id) ?
                                  <a className="dropdown-item"  onClick={(e) => this.handleHandover(e, this.props.complaintsDetail.id)}><img src="../../img/Complaint-Closed.svg" alt="" />HandOver Courier</a>
                                  : null}
                                {[19, 20, 21, 22, 23, 14, 15, 16, 17, 38, 39, 68, 69, 70, 18, 5].includes(this.props.complaintsDetail.complaint_status_id) ?
                                  <a className="dropdown-item"  onClick={(e) => this.handleFPUPrint(e, this.props.complaintsDetail.id)}><img src="../../img/IQC.svg" alt="" />Forward Pickup Print</a>
                                  : null} */}
                                <SCActions handleRefreshList={this.props.handleRefreshList} />

                              </div>
                            </div>
                            : null
                          : null}</div>

                      <div>
                        {this.props.actionOptions === true ?
                          <div className="col">
                            <button onClick={this.actions} className="bbts" id="dropdownMenuButton" data-toggle="dropdown">
                              <i className="fas fa-ellipsis-v"></i>Actions
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              {[116].includes(this.props.complaintsDetail.inv_status_id) ?
                                <a className="dropdown-item"  onClick={(e) => this.handleRevokeINVTicket(e, this.props.complaintsDetail.id)}><img src="../../img/Revoke-Ticket.svg" alt="" />Revoke Ticket</a>
                                : null}
                              {[115, 114].includes(this.props.complaintsDetail.inv_status_id) ?
                                <a className="dropdown-item"  onClick={(e) => this.handleIqcPass(e, this.props.complaintsDetail.id)}><img src="../../img/QC-Pass-White.svg" alt="" />IQC Pass</a>
                                : null}
                              {[115].includes(this.props.complaintsDetail.inv_status_id) ?
                                <a className="dropdown-item"  onClick={(e) => this.handleIqcFail(e, this.props.complaintsDetail.id)}><img src="../../img/QC-Fail-White.svg" alt="" />Failed IQC</a>
                                : null}
                              {[115, 114].includes(this.props.complaintsDetail.inv_status_id) ?
                                <a className="dropdown-item"  onClick={(e) => this.handleclosedTicket(e, this.props.complaintsDetail.id)}><img src="../../img/Complaint-Closed.svg" alt="" />Closed </a>
                                : null}
                            </div>
                          </div> : null}
                      </div>
                      <div className="mitem">
                        <ul id="menu2">
                          {/* <li value="1" className={this.state.subMenu === 1 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>Attachments</li> */}
                          <li value="1" className={this.state.subMenu === 1 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>Product details</li>
                          <li value="2" className={this.state.subMenu === 2 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>Customer details</li>
                          <li value="3" className={this.state.subMenu === 3 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>Conversations</li>
                          <li value="4" className={this.state.subMenu === 4 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>Comments</li>
                          <li value="5" className={this.state.subMenu === 5 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>History</li>
                        </ul>
                      </div></div>
                    {
                      this.state.subMenu === 1 ?
                        <ProductDetails
                        />
                        :
                        this.state.subMenu === 2 ?
                          <CutomerDetails
                            item={this.props.complaintsDetail}
                          />
                          :
                          this.state.subMenu === 3 ?
                            <Conversations
                            // item={this.props.complaintsDetail}
                            /> :
                            this.state.subMenu === 4 ?
                              <Comments
                              // item={this.props.complaintsDetail}
                              />

                              :
                              this.state.subMenu === 5 ?
                                <History
                                  history={this.props.actionOptions === true ? this.props.complaintsDetail.historyInv : this.props.complaintsDetail.history}
                                />
                                : null
                    }
                  </div>
                  : <h3 style={{ color: "#fff" }}>Complaint Details not found</h3>
                }
                <ReactModal
                  isOpen={this.state.showFPUModal}
                  contentLabel="Minimal Modal Example"
                  style={customStylesDO}
                  ariaHideApp={false}
                >
                  <h2 id="rModal_head">Forward Pickup</h2>
                  <button id="close_rModal" onClick={this.handleFPUCloseModal}><i className="fas fa-times"></i></button>
                  <select id="select_rModal" className="form-control" onChange={this.handleCourierChange}>
                    <option value={"0"} selected="selected">Select Courier</option>
                    {this.props.courierSCData.map((obj) => (
                      <option value={obj.courier_id}>{obj.courier_name} - {obj.pincode} - Rs.{obj.reverse_cost}</option>
                    ))}
                  </select>
                  {this.state.isLoadingGif ?
                    <button id="send_rModal" type="button"><img className="loaderCTA" src="/img/loading-gif.gif" alt="Loader" /></button>
                    :
                    <button id="send_rModal" type="button" onClick={this.onFPUSubmit}>Send</button>
                  }
                </ReactModal>
                <ReactModal
                  isOpen={this.state.showFPUPrintModal}
                  contentLabel="Minimal Modal Example"
                  style={customStylesRPU}
                  ariaHideApp={false}
                  className="rpu_print"
                >
                  <button type='button' onClick={this.handlePrintModalClose} id="close_rModal"><i className="fas fa-times"></i></button>
                  <ComponentToFPUPrint ref={el => (this.componentRef = el)} />
                </ReactModal>s
              </div>
              : null
            }
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sccomplaintsData: state.serviceCenter.sccomplaintsData,
  complaintsDetail: state.serviceCenter.complaintsDetail,
  showComplaintDetails: state.complaints.showComplaintDetails,
  courierSCData: state.serviceCenter.courierSCData,
  printSCLabel: state.serviceCenter.printSCLabel,
  statusId: state.complaints.statusId,
});

export default connect(mapStateToProps, {
  getSCComplaintDetail, fetchSCPrintLabel, submitSCRPU, onhandOverToCourier, showComplaintDetailsAction,
  moveToDispatchInward, getSCRpuCourier, onRevokeAction, onIQCPassAction, onIQCFailAction, onClosedAction, onRevokeInvAction
})(SCComplaintDetailsNew);