import React, { Component } from 'react'
import { connect } from "react-redux";
import moment from 'moment'

class History extends Component {


  render() {
    return (
      <div className="history">
        {
            this.props.distributorComplainDetails?this.props.distributorComplainDetails.history.map((item)=> {
                
                    return (
                      <div className = "history_wrapper" key={item.id}>
                        <div className></div>
                        <p className="date">{moment(item.created_at).format('MMMM DD, YYYY || hh:mm:ss A')}</p>
                        <p className="last_worked">{item.user ? "Last worked by " + item.user.first_name : null} || <span>{item.comments}</span> || <span>{item.complaint_text && item.complaint_text.comment}</span>
                {item.complaint_text.resolution_status && 
                <span> || Resolution Status: {item.complaint_text.resolution_status} 
                {item.complaint_text.upgradeProduct && <span> (upgraded with: {item.complaint_text.upgradeProduct.product_name} {item.complaint_text.upgradeProduct.color_name})</span>}
                {item.complaint_text.replace_accessory && 
                <span> (replaced with: {item.complaint_text.replace_accessory})</span>
                }
                </span>
                
                }
               
               
                </p>

                      </div>
                    )
                
                
                
            }) : 'null'
        }
          

        </div>

    )
  }
}

const mapStateToProps = state => ({
      distributorComplainDetails : state.distributor.distributorComplainDetails,
})
export default connect(mapStateToProps, null)(History);