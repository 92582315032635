import React, { Component } from 'react'
// import { getDistributorComplaintDetail, showComplaintDetailsAction,getDistributorData } from '../../actions/distributorAction'

export default class CustomerDetails extends Component {
   render() {
        let item = this.props.item
        return (
            <div className='product_details' >
                <div className='first' style={{ 'marginLeft': '10px',"width":"32%" }}>
                    <div className='firstbox'>
                        <span>Name</span>
                        <p style={{ color: "white" }}>{item.retailer ? item.retailer.first_name  : "null" }{" "}{ item.retailer ?   item.retailer.last_name : "null" }</p>
                    </div>
                    <div className='secondbox'>
                        <span>Address 1</span>
                        <p style={{ color: "white" }}>{item.retailer ? item.retailer.address : "null"}</p>
                    </div>
                   
                </div>
                <div className='second' style={{ 'marginLeft': "8px" }}>
                    <div className="firstbox">
                        <span>E Mail</span>
                        <p style={{ color: "white" }}>{item.retailer ? item.retailer.email : "null"}</p>
                    </div>
                    <div className='secondbox'>
                        <span>Address 2</span>
                        <p style={{ color: "white" }}>{item.retailer ? item.retailer.address_2 : "null"}</p>
                    </div>
                    <div>
                        <span>Pincode</span>
                        <p style={{ color: "white" }}>{item.retailer ? item.retailer.pincode : "null"}</p>
                    </div>
                </div>
                <div className='third'>
                    <div className="firstbox">
                        <span>Mobile Number</span>
                        <p style={{ color: "white" }}>{item.retailer ? item.retailer.mobile : "null"}</p>
                    </div>
                    <div className='secondbox'>
                        <span>State</span>
                        <p >{item.retailer ? item.retailer.state_name : "null"}</p>
                    </div>
                    <div className='thirdBox'>
                        <span>City</span>
                        <p style={{ color: "white" }}>{item.retailer ? item.retailer.city : "null"}</p>
                    </div>
                </div>
            </div>
        )
    }
}