import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import HtmlParser from 'react-html-parser';
import ReactModal from 'react-modal';
import CommentExpand from './CommentExpand';
import { getRTLCommentsData } from '../../../actions/distributorAction';

const customStyles = {
    overlay: {
        background: 'rgba(0, 0, 0, 0.5)',
        "overflow-y": "scroll"
    },
    content: {
        inset: '0 0 auto',
        marginRight: '0',
        transform: 'translate(0)',
        background: '#1a1a1a',
        padding: '2rem',
        border: 'none',
        width: '100%',
        position: 'absolute',
        "overflow-y": 'scroll',
        height: '100%'
    },
};

class Comments extends Component {
    constructor() {
        super();
        this.state = {
            disabled: false,
            showExpandedComment: false,
            pageno: 1,
            commentList: [],
            isLoading: false,
        };
        this.showComment = this.showComment.bind(this);
        this.expandFunction = this.expandFunction.bind(this);
    }

    componentDidMount() {
        this.getConversationComplaits();
        var myDiv = document.getElementById("conversation_head");
        // myDiv.scrollTop = myDiv.scrollHeight - myDiv.clientHeight;
    }

    getConversationComplaits = async () => {
        this.setState({ isLoading: true });
        let response = await this.props.getRTLCommentsData(this.props.distributorComplainDetails.id, this.state.pageno);
        this.setState({ commentList: response, isLoading: false });
    };

    showComment() {
        this.setState({ showExpandedComment: true });
    }

    expandFunction = async (val) => {
        if (!val) {
            let response = await this.props.getRTLCommentsData(this.props.distributorComplainDetails.id, 1);
            this.setState({ commentList: response });
        }
        this.setState({ showExpandedComment: val });
    };


    loadMore = async () => {
        let response = await this.props.getRTLCommentsData(this.props.distributorComplainDetails.id, this.state.pageno + 1);
        this.setState({ commentList: [...this.state.commentList, ...response], pageno: this.state.pageno + 1 });
    };

    render() {
        let userRoleId = localStorage.getItem('userRoleId');
        let userEmail = localStorage.getItem('userEmail');
        return (
            < div >
                {!this.state.isLoading ?
                    <div>
                        {userRoleId !== '4' &&
                            <div className="expand_wrapper" onClick={this.showComment}>
                                <i className="fa fa-expand" aria-hidden="true"></i>
                            </div>
                        }
                        <div className="head conversation_head" id="conversation_head">
                            {
                                this.state.commentList.map((b, index) => (
                                    <div key={b.id} style={{ width: "100%", float: "left" }}>
                                        {b.user &&
                                            <div>
                                                <div className={b.user?.email === userEmail ? 'same-user message' : 'other-user message'}>
                                                    <div>
                                                        {b.type == "comment" && b.img != null ?
                                                            <span>
                                                                <a className="download_icon" href={b.img} target='_blank' > <i className="fas fa-download" />
                                                                    Download Image</a></span>
                                                            : null}
                                                        <p>{HtmlParser(b.notes)}</p>
                                                        <p id="userName">- {b.user?.first_name} {b.user?.last_name}</p>
                                                        <p id="created_at">{moment(b.created_at, ["MMMM DD, YYYY || HH.mm"]).format('MMMM DD, YYYY || hh:mm A')}</p>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                ))
                            }
                            {this.props.SCconversationpage.next_page == true &&
                                <div className="expand_wrapper" onClick={this.loadMore}>
                                    <button className='loadMoreBUtton'>Load more..</button>
                                </div>}
                        </div>
                    </div> :
                    <div>
                        <i id="loadingText">Please wait ....</i>
                    </div>}


                <ReactModal
                    isOpen={this.state.showExpandedComment}
                    contentLabel="Minimal Modal Example"
                    style={customStyles}
                    ariaHideApp={false}
                    id="conversationExpand"
                >
                    <CommentExpand complaintId={this.props.distributorComplainDetails.id} showExpandedComment={this.expandFunction} commentData={this.state.commentList} pageno={this.state.pageno} />
                </ReactModal>
            </div >
        );
    }
}


const mapStateToProps = state => ({
    distributorConversationData: state.distributor.distributorConversationData,
    distributorComplainDetails: state.distributor.distributorComplainDetails,
    SCconversationDetails: state.distributor.SCconversationDetails,
    SCconversationpage: state.distributor.SCconversationpage,
});

export default connect(mapStateToProps, { getRTLCommentsData })(Comments);