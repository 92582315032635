import axios from 'axios';
import {
  FETCH_DISTRIBUTOR_DATA,
  GET_DISTRIBUTOR_COMPLAINTS_DETAIL, ADD_STATUS_ID, SHOW_COMPLAINT_DETAILS, GET_COMPLAINTS_DETAIL, GET_COMPLAINT_BUCKET,
  DISTRIBUTOR_COMPLAINT_STATUS, ADD_COMPLAINT_DISTRIBUTOR, CALL_SERVICE_CENTER,
  FETCH_COMPLAINTS_COUNT, FETCH_DISTRIBUTOR_CONVERSATIONS, PRINT_LABEL_DATA, FETCH_PAGINATION_INFO, DISTRIBUTOR_SCREENING_RESPONSE,
  FETCH_INDIVIDUAL_PRODUCTS, COMPLAINT_PRODUCT_LIST, EDIT_COMPLAINT, FETCH_ALL_PRODUCTS_CLUBBED, FETCH_COMMENT_DATA, FETCH_COMMENT_LOAD, FETCH_CONVERSATIONS_LOAD, FETCH_CONVERSATIONS_DATA,
} from "./types";
import { baseUrl } from '../config';
import history from '../history';

export const addDistributorComplaint = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  // let token = "abc"
  return await axios.post(baseUrl + '/distributor/add', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: ADD_COMPLAINT_DISTRIBUTOR,
          payload: res.data
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
    });
};

export const distributorScreening = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  // let token = "abc"
  return await axios.post(baseUrl + '/distributor/screening', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      alert(res.data.message);
      if (res.data.success) {
        dispatch({
          type: DISTRIBUTOR_SCREENING_RESPONSE,
          payload: res.data.data
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
    });
};

export const getDistributorData = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  // let token = "abc"
  return await axios.post(baseUrl + '/distributor/get-complaint', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: FETCH_DISTRIBUTOR_DATA,
          payload: res.data.data.complaint_data
        });
        dispatch({
          type: FETCH_COMPLAINTS_COUNT,
          payload: res.data.data || []
        });
        dispatch({
          type: FETCH_PAGINATION_INFO,
          payload: res.data.data || []
        });


      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
    });
};

export const showComplaintDetailsAction = (flag) => async dispatch => {
  dispatch({
    type: SHOW_COMPLAINT_DETAILS,
    payload: flag
  });
};

export const addStatusId = (data) => async dispatch => {
  dispatch({
    type: ADD_STATUS_ID,
    payload: Number(data)
  });
};

export const onSaveTicket = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/distributor/save/', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      // if(res.data.success){
      //   // dispatch ({
      //   //   type: FETCH_SAVE_TICKET,
      //   //   payload: res.data.data
      //   // })
      // }
      alert(res.data.message);
    });
};

export const getDistributorComplaintDetail = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.get(baseUrl + '/distributor/get/' + data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: GET_DISTRIBUTOR_COMPLAINTS_DETAIL,
          payload: res.data.data[0] || null
        });
        // dispatch({
        //   type: FETCH_COMMENTS,
        //   payload: res.data.data[0] ? res.data.data[0].comments : []
        // })
        dispatch({
          type: FETCH_DISTRIBUTOR_CONVERSATIONS,
          payload: res.data.data[0] ? res.data.data[0].conversation : []
        });
        let productColrIds = [];
        let tempProductList = res.data.data[0].product.filter((e) => {
          if (productColrIds.includes(e.product_color_id)) {
            return false;
          } else {
            productColrIds.push(e.product_color_id);
            return true;
          }
        }).map((e) => {
          return {
            product_color_id: e.product_color_id,
            product_name: e.product_name,
            color_name: e.color_name,
          };
        });
        dispatch({
          type: FETCH_INDIVIDUAL_PRODUCTS,
          payload: tempProductList,
        });
        tempProductList = [];
        for (let i = 0; i < res.data.data[0].product.length; i++) {
          let temp = res.data.data[0].product[i];
          if (temp.product_color_id === res.data.data[0].product[0].product_color_id) {
            if (tempProductList.length > 0) {
              let status = false;
              for (let j = 0; j < tempProductList.length; j++) {
                if (tempProductList[j].product_problem_id === temp.product_problem_id &&
                  tempProductList[j].purchase_date === temp.purchase_date &&
                  tempProductList[j].problem_description === temp.problem_description) {
                  tempProductList[j].quantity += 1;
                  status = true;
                  break;
                }
              }
              if (!status) {
                tempProductList.push({ ...temp, quantity: 1 });
              }
            } else {
              tempProductList.push({ ...temp, quantity: 1 });
            }
          }
        }

        dispatch({
          type: COMPLAINT_PRODUCT_LIST,
          payload: tempProductList,
        });

        tempProductList = [];
        for (let i = 0; i < res.data.data[0].product.length; i++) {
          let temp = res.data.data[0].product[i];
          // if (temp.product_color_id == res.data.data[0].product[0].product_color_id) {
          if (tempProductList.length > 0) {
            let status = false;
            for (let j = 0; j < tempProductList.length; j++) {
              if (tempProductList[j].product_problem_id === temp.product_problem_id &&
                tempProductList[j].purchase_date === temp.purchase_date &&
                tempProductList[j].problem_description === temp.problem_description) {
                tempProductList[j].quantity += 1;
                status = true;
                break;
              }
            }
            if (!status) {
              tempProductList.push({ ...temp });
            }
          } else {
            tempProductList.push({ ...temp, quantity: 1 });
          }
          // }
        }
        dispatch({
          type: FETCH_ALL_PRODUCTS_CLUBBED,
          payload: tempProductList,
        });
      } else {
        dispatch({
          type: GET_COMPLAINTS_DETAIL,
          payload: null
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
    });
};

export const getComplaintBuckets = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  dispatch({
    type: GET_COMPLAINT_BUCKET,
    payload: []
  });
  return await axios.post(baseUrl + '/complaint/buckets', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: GET_COMPLAINT_BUCKET,
          payload: res.data.data
        });
        // dispatch({
        //   type: ADD_STATUS_ID,
        //   payload: res.data.data[0].id
        // })
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
    });
};

export const callCustomer = (distriburotId, complaintId) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.get(baseUrl + '/distributor/call/' + distriburotId + '/' + complaintId, { 'headers': { 'x-access-token': token } })
    .then(res => {
      alert(res.data.message);
      if (res.data.success) {
        dispatch({
          type: CALL_SERVICE_CENTER,
          payload: res.data
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
    });
};



export const onCloseTicket = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/distributor/close', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        alert('Successfully Done');
      } else {
        alert('Something Went wrong');
      }
    })
    .catch((e) => {
      if (e.response.status === 401)
        history.push('/');
    });
};

export const onSelfShip = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/distributor/selfship/', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401)
        history.push('/');
    });
};

export const MoveToOutOfStockClick = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/distributor/move-out-stock', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401)
        history.push('/');
    });
};

export const MoveToComplaintClosedClick = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/distributor/close', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401)
        history.push('/');
    });
};

export const moveToRtoOutClick = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/distributor/rto-case', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401)
        history.push('/');
    });
};

export const onReversePickupCancel = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/distributor/forward-cancel', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        alert(res.data.message);
      } else {
        alert(res.data.message);
      }
    })
    .catch((e) => {
      if (e.response.status === 401)
        history.push('/');
    });
};

//   export  const showComplaintDetailsAction= (flag) => async dispatch => {
//   dispatch({
//     type:SHOW_DISTRIBUTOR_COMPLAIN_DETAILS,
//     payload: flag
//   })
// }

// export const addDistributorStatusId  = (data) => async dispatch => {
//   dispatch({
//     type: ADD_DISTRIBUTOR_STATUS_ID,
//     payload: Number(data)
//   })
// }

// export const getDistributorComplaintDetail = (data) => async dispatch => {
//   let token = localStorage.getItem('token');
//   return await axios.get(baseUrl + '/complaint/get/' + data, { 'headers': { 'x-access-token': token } })
//     .then(res => {
//       if (res.data.success) {
//         dispatch({
//           type: GET_DISTRIBUTOR_COMPLAINTS_DETAIL,
//           payload: res.data.data[0] || null
//         })
//         // dispatch({
//         //   type: FETCH_COMMENTS,
//         //   payload: res.data.data[0] ? res.data.data[0].comments : []
//         // })
//         // dispatch({
//         //   type: FETCH_CONVERSATIONS,
//         //   payload: res.data.data[0] ? res.data.data[0].conversation : []
//         // })
//       } else {
//         dispatch({
//           type: GET_DISTRIBUTOR_COMPLAINTS_DETAIL,
//           payload: null
//         })
//       }
//     })
//     .catch((e) => {
//       if (e.response.status === 401) {
//         history.push('/')
//       }
//     })
// }

export const getDistributorStatusData = () => async dispatch => {
  let token = localStorage.getItem('token');
  // let token = "abc"
  return await axios.get(baseUrl + '/distributor/get-complaint-status', { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: DISTRIBUTOR_COMPLAINT_STATUS,
          payload: res.data.data
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
    });
};

export const addDistributorComments = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/distributor/add-comments', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        // dispatch({
        //   type: ADD_COMMENT,
        //   payload: res.data
        // })
        dispatch({
          type: FETCH_DISTRIBUTOR_CONVERSATIONS,
          payload: res.data.data.conversation
        });
        return res.data;
        // dispatch(refreshComplaintList(true))
        // dispatch(showComplaintDetailsAction(false))
      }
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
      if (e.response.status === 429) {
        alert(e.response.statusText || "Something went wrong");
      }
    });
};

export const sendMail = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/distributor/msg-customer', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: FETCH_DISTRIBUTOR_CONVERSATIONS,
          payload: res.data.data.conversation
        });
        // dispatch(refreshComplaintList(true))
        // dispatch(showComplaintDetailsAction(false))
      }
      return res.data;
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
      if (e.response.status === 429) {
        alert(e.response.statusText || "Something went wrong");
      }
    });
};

export const sendBusyEmail = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/distributor/send-customer-mail', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
      if (e.response.status === 429) {
        alert(e.response.statusText || "Something went wrong");
      }
    });
};

export const fetchPrintLabel = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/distributor/printlabel', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: PRINT_LABEL_DATA,
          payload: res.data.data
        });
      }
      else {
        dispatch({
          type: PRINT_LABEL_DATA,
          payload: null
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
    });
};

export const revokeTicket = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.get(baseUrl + '/distributor/revoke/' + data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        alert("Successfully Revoked");
        //  history.push('/complaints-data')
        // window.location.reload()
      } else {
        alert(res.data.message);
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
      // history.push('/')
    });
};

export const onPickedUp = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/distributor/move-pickup', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        alert(res.data.message);
        //  history.push('/complaints-data')
        // window.location.reload()
      } else {
        alert(res.data.message);
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
      // history.push('/')
    });
};

export const sendEmail = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/distributor/msg-customer', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        // dispatch({
        //   type : PRINT_LABEL_DATA,
        //   payload : res.data.data
        // })
        alert("Successfully done");
      } else {
        alert("Something went wrong");
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
    });
};

export const movedToPickup = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/distributor/move-pickup', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        // dispatch({
        //   type : PRINT_LABEL_DATA,
        //   payload : res.data.data
        // })
        alert("Successfully done");
      } else {
        alert("Something went wrong");
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
    });
};

export const movedToReceive = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/distributor/product-received', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        // dispatch({
        //   type : PRINT_LABEL_DATA,
        //   payload : res.data.data
        // })
        alert("Successfully done");
      } else {
        alert("Something went wrong");
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
    });
};

export const movedToHandover = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/distributor/handover', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        // dispatch({
        //   type : PRINT_LABEL_DATA,
        //   payload : res.data.data
        // })
        alert("Successfully done");
      } else {
        alert("Something went wrong");
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
    });
};

export const getRPUCourier = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/distributor/reverse-pickup', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        alert('Successfully Done');
      } else {
        alert('SomeThing went wrong');
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
    });
};

export const moveToIQCClick = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/distributor/move-to-iqc', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        alert("Successfully done");
      } else {
        alert("Something went wrong");
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
    });
};

export const editDistributorComplaint = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/distributor/edit', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: EDIT_COMPLAINT,
          payload: res.data
        });
      } else {
        alert(res.data.message);
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
    });
};

export const getRTLConversationData = (id, page) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.get(baseUrl + '/distributor/get-conversation/' + id + "?page=" + page, { 'headers': { 'x-access-token': token } })
    .then(res => {
      dispatch({
        type: FETCH_COMMENT_DATA,
        payload: res.data.data.conversation
      });
      dispatch({
        type: FETCH_COMMENT_LOAD,
        payload: res.data.data
      });
      return res.data.data.conversation;
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
    });
};

export const getRTLCommentsData = (id, page) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.get(baseUrl + '/distributor/get-comments/' + id + "?page=" + page, { 'headers': { 'x-access-token': token } })
    .then(res => {
      dispatch({
        type: FETCH_CONVERSATIONS_DATA,
        payload: res.data.data.comments
      });
      dispatch({
        type: FETCH_CONVERSATIONS_LOAD,
        payload: res.data.data
      });
      return res.data.data.comments;
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
    });
};