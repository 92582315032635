import React, { Component } from 'react'
import './UserComponent.css';
import { connect } from "react-redux";
import history from '../../history';
import moment from 'moment';
import { getComplaintBuckets, getUserData, getUnassignTicket, assignTicket } from '../../actions/userActions'
import DateRangePicker from 'rsuite/DateRangePicker';
import Select from 'react-select';
import ViewProfile from '../ViewProfile'
import AccessRestricted from '../AccessRestricted'
import ComplaintRegistration from '../ComplaintRegistration';
import Footer from'../FooterComponent/footer';
import SidebarComponent from '../SidebarComponent/SidebarComponent';

const {
  // allowedMaxDays,
  // allowedDays,
  allowedRange,
  // beforeToday,
  // afterToday,
  // combine
} = DateRangePicker;

class AssignTicket extends Component {
  constructor() {
    super();
    this.state = {
      checked: false,
      value: 'show',
      logout: false,
      statusList: [],
      userList: [],
      totalPage: '',
      userUnassign: [],
      pageNo: '',
      totalCount: '',
      assign_to_user_id: '',
      ticket_id: '',
      bucket_id: 1,
      prevPage: '',
      nextPage: '',
      pageLimit: 10,
      current_page: 1,
      sla_breached: '',
      startDate: new Date().setDate(new Date().getDate() - 15),
      endDate: new Date().setDate(new Date().getDate()),
      SLAList: [{ "id": true, "value": "Yes" }, { "id": false, "value": "No" }],
      checkedItems: new Map(),
      ticketId: []
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeChk = this.handleChangeChk.bind(this);
    this.onUserChange = this.onUserChange.bind(this);
  }

  handleChange(event) {
    //var item = event.target.value;
    //let isChecked = e.target.checked;
    //this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.ticketId.length === 0) {
      alert("Please select a ticket before assigning");
      return;
    }

    if (this.state.assign_to_user_id === null || this.state.assign_to_user_id === '') {
      alert("Please select user");
      return;
    }

    if (this.state.ticketId.length !== 0 && this.state.assign_to_user_id !== 0) {
      let data = {
        "complaint_ids": this.state.ticketId,    // tickets with no user_id in it
        "user_id": this.state.assign_to_user_id,
        "bucket_id": Number(this.state.bucket_id)
      }
      this.ticketAssign(data);
    }
  }


  ticketAssign = async (data) => {
    await this.props.assignTicket(data);
    let ticketAssigned = this.props.ticketAssigned;
    if (ticketAssigned.success) {
      this.setState({
        ticketId: '',
        assign_to_user_id: '',
        bucket_id: 1
      })
      document.getElementById("assignUser_close").click();
      this.getUnassignList();
    }
    alert(ticketAssigned.message)

  }
  handleLogout() {
    localStorage.removeItem('token')
    document.querySelector(".modal-backdrop").style.display = "none";
    history.push('/')
  }

  handleIconDetails() {
    this.setState({
      logout: !this.state.logout
    })
  }

  onPrevPress = () => {
    if (this.props.unassingTicketData.data.prev_page) {
      // let pageNo = (this.state.pageNo1 - 1);
      // this.setState({pageNo1: pageNo});

      this.setState({ current_page: (this.state.current_page - 1) }, () => {
        this.getUnassignList()
      })
    }
  }

  onNextPress = () => {
    if (this.props.unassingTicketData.data.next_page) {
      // let pageNo = (this.state.pageNo1 - 1);
      // this.setState({pageNo1: pageNo});

      this.setState({ current_page: (this.state.current_page + 1) }, () => {
        this.getUnassignList()
      })
    }
  }


  onUserChange(a) {
    this.setState({ assign_to_user_id: a.id }, () => {
      //this.getUnassignList();
    })
  }


  onBucketChange = (e) => {
    this.setState({ bucket_id: e.target.value }, () => {
      this.getUnassignList();
    })
  }

  onSLAChange = (e) => {
    this.setState({ sla_breached: e.target.value }, () => {
      this.getUnassignList();
    })
  }


  async componentDidMount() {
    this.getUserList();
    let data = {}
    await this.props.getComplaintBuckets(data);
    this.setState({
      statusList: this.props.bucketData.data.filter((bd) => Number(bd.id) !== 0)
    })
    this.getUnassignList();
    //this.getComplaintList();
  }


  getUnassignList = async () => {
    let data = {
      //"complaint_id": 2,     //optional
      //"unassigned": false,     //optional :- when unassigned tickets are required
      "bucket_id": Number(this.state.bucket_id),     //optional    
      "sla_breached": this.state.sla_breached,   //optional :- when need to look sla_breached tickets,
      "assignmentBoard": true,  //optional :- either user is in assignment page,
      //"prev_days": 7,   //optional :- no of days before today to get data,   
      "limit": this.state.pageLimit,     //optional by default limit is 10
      "offset": 0,
      "page_no": this.state.current_page,
      "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
      "endDate": moment(this.state.endDate).format('YYYY-MM-DD')
    }

    if (this.state.SLAId && this.state.SLAId !== "0")
      data["sla_breached"] = this.state.SLAId

    this.setState({ isLoading: true, unassignList: [] })
    //await this.props.getUserData(data);
    await this.props.getUnassignTicket(data);
    let unassignList = this.props.unassingTicketData;
    if (unassignList.success !== false) {
      this.setState({
        isLoading: true,
        userUnassign: unassignList.data.complaint_data,
        totalPage: unassignList.data.no_page,
        totalCount: unassignList.data.total,
        limit: unassignList.data.limit,
        nextPage: unassignList.data.next_page,
        prevPage: unassignList.data.prev_page,
      })
    } else {
      this.setState({
        isLoading: true,
        userUnassign: '',
        totalPage: '',
        totalCount: '',
        limit: '',
        nextPage: '',
        prevPage: '',
      })

    }
  }

  getUserList = async () => {
    let data = {
      "filterText": '',
      "noPagination": true
    }
    this.setState({ isLoading: true, userList: [] })
    //await this.props.getUserData(data);
    await this.props.getUserData(data);
    let usersData = this.props.userData;
    this.setState({
      isLoading: false,
      userList: usersData.data ? usersData.data.users : [],
    })
  }

  selectAll = () => {
    let link = document.querySelectorAll('.container3 .bx input[type="checkbox"]');
    if (document.querySelector("#content_assign .counts .c input[type='checkbox']").checked === true) {
      for (let i = 0; i < link.length; i++) {
        // link[i].click();
        link[i].checked = true;

      }
    }
    else {
      for (let i = 0; i < link.length; i++) {
        // link[i].click();
        link[i].checked = false;

      }
    }


  }

  onCheckboxChange = (e) => {
    let checked = false;
    if (e.target.checked === true) {
      //this.setState({checked:true});
      checked = true;
    }
    else {
      // this.setState({checked:false});
      checked = false;
      if (checked === false) {
        let main = document.querySelector("#content_assign .counts .c input[type='checkbox']");
        if (main.checked === true) {
          main.checked = false;
        }
        else {
        }
      }
    }
  }

  handleChangeChk(e) {
    const { name, checked } = e.target
    let ticket_id = []

    if (name === "allSelect") {
      let tempTickets = this.state.userUnassign.map((td) => {
        return { ...td, isChecked: checked }
      })
      for (let i = 0; i < tempTickets.length; i++) {
        if (tempTickets[i].isChecked) {
          ticket_id.push(tempTickets[i].id)
        }
      }
      this.setState({
        userUnassign: tempTickets,
        ticketId: ticket_id
      })
    } else {
      let tempTickets = this.state.userUnassign.map((td) => td.id === name ? { ...td, isChecked: checked } : td)
      for (let i = 0; i < tempTickets.length; i++) {
        if (tempTickets[i].isChecked) {
          ticket_id.push(tempTickets[i].id)
        }
      }
      this.setState({
        userUnassign: tempTickets,
        ticketId: ticket_id
      })

    }
    // disable transfer buton
  }

  dateSelect = async (value) => {
    this.setState({
      startDate: new Date(value[0]),
      endDate: new Date(value[1])
    }, async () => {
      this.getUnassignList();
      // await this.props.showComplaintDetailsAction(false)
    })
  }

  render() {
    let paginationCount = this.props.unassingTicketData.data;
    let pageNo = 0
    if (paginationCount) {
      pageNo = ((paginationCount.no_page - (paginationCount.no_page - this.state.current_page)) - 1);
    }
    else {
      pageNo = 0;
    }


    const { userList } = this.state

    let optionUnassign = []
    userList.map((obj, index) => (
      optionUnassign.push(
        {
          label: obj.first_name + " " + obj.last_name,
          value: obj.last_name,
          id: obj.id
        }
      )
    ))
    let userRoleId = localStorage.getItem('userRoleId');
    if (userRoleId === '4' || userRoleId === '8' || userRoleId === '9' || userRoleId === '10') {
      return (<AccessRestricted />)
    }
    else if (userRoleId === '6'){
      return (<ComplaintRegistration />)
  }
    else {
      return (
        <div>
         { window.innerWidth > 600 &&   <SidebarComponent sideProp={'MU'} />}
          {/* <div className="modal fade" id="exampleModals">
          <div className="modal-dialog" role="document">
            <div className="mo">
              <div className="header">
              <ViewProfile />
              </div>
              <div className="log" onClick={this.handleLogout}>
                <i className="fas fa-power-off"></i><p>Log Out</p>
              </div>

            </div>

          </div>
        </div>
        <div className="nav dashboard">
          <span>
            <i className="fas fa-user-circle" data-toggle="modal" data-target="#exampleModals"></i> </span>
        </div> */}

          <ViewProfile />

          <div className="nav dashboard">
            <span>
              {/* <i className="fa fa-bell"></i> */}
              <i className="fas fa-user-circle" data-toggle="modal" data-target="#exampleModals"></i> </span>
          </div>
          <div className="content assign-user-component">
            <span className="complaint-head">Manage Users</span>
            <ul id="menu">
              <li className="actice2"><a href="/assign-ticket">Assign Ticket</a></li>
              <li><a href="/manage-user" className="noactive">Users</a></li>
            </ul>
            <div className="assign_dropdown">
              <div className="fbox" style={{ width: '16%' }}>
                <span>Select Bucket</span>
                <div className="dropdown ">
                  <select className="dropbtn" onChange={(e) => this.onBucketChange(e)}>
                    <i className="fa fa-angle-down" />
                    {/* <option value={0}> Select Bucket</option> */}
                    {this.state.statusList.map((obj, index) => (
                      <option value={obj.id} selected={Number(obj.id) === Number(this.state.bucket_id)} key={index}>{obj.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="sbox" style={{ width: '16%' }}>
                <span>SLA Breached</span>
                <div className="dropdown">
                  <select className="dropbtn" onChange={(e) => this.onSLAChange(e)}>
                    <i className="fa fa-angle-down" />
                    <option value={0}>SLA Breached</option>
                    {this.state.SLAList.map((obj) => (
                      <option value={obj.id}>{obj.value}</option>
                    ))}
                  </select>

                  {/* <select>
                  <option>SLA Breached</option>
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select> */}
                </div>
              </div>

              <div className="rbox1" style={{ width: '20%' }}>
                <span>Date - (DD/MM/YYYY)</span>
                {/* <div className="dropdown">
                <select>
                  <option>All Time</option>
                </select>
              </div> */}
             
                <DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(new Date(new Date().setDate(new Date().getDate() - 120))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.dateSelect(value) }} value={[new Date(this.state.startDate), new Date(this.state.endDate)]} />
              </div>
              <div className="pbox">
                {this.state.userUnassign.length > 0 ?
                  <button data-toggle="modal" data-target="#exampleModalsAssign" className="assign-manage-btn buttonShow">Assign To</button>
                  : ''}
              </div>
            </div>

            <div className={this.state.value} id="content_assign">

              <div className="counts">
                {this.state.totalCount > 0 ?
                  <div className="c" style={{ display: 'block' }}>
                    {/* <input type="checkbox" onClick={this.selectAll}></input> <p>Select all ({this.state.totalCount ? this.state.totalCount : '0'})</p> */}
                    <input type="checkbox"
                      name="allSelect"
                      checked={this.state.userUnassign && this.state.userUnassign.length > 0 ? this.state.userUnassign.filter(td => td.isChecked !== true).length < 1 : false}
                      onChange={this.handleChangeChk}></input><p>Select all</p>
                  </div>
                  :
                  <div className="c">
                  </div>
                }
                <div className="c2">
                
                  <p>{this.state.totalPage} of {this.state.totalCount} results <i className="fas fa-chevron-left" onClick={this.onPrevPress}></i><button>1{this.state.pageNo}</button><i className="fas fa-chevron-right" onClick={this.onNextPress}></i>
                  </p>
                </div>
              </div>
              <div className="wrapper">
                {this.state.userUnassign.length > 0 ?
                  this.state.userUnassign.map((item) => {
                    return (
                      <div className="container3">
                        <div className="bx">
                          <input type="checkbox" value={item.id} name={item.id} onClick={this.handleChangeChk} checked={item.isChecked || false}></input> <h6>{item.ticket_id} <button className="active">{item.status === '1' ? 'Actice' : 'Inactive'}</button></h6>
                          <span>{item.product_name} ({item.color_name})</span>
                          <p>{item.created_at} || {item.channel_name}</p></div>
                        <div className="box1">
                          <h6>{item.problem}</h6>
                          <span>{item.problem_description}</span>
                        </div>
                      </div>
                    )
                  })
                  :
                  <div className="no-result-text">
                    <p>No result found</p>
                  </div>
                }
              </div>

            </div>
            <Footer pageNo={pageNo} onPrevPress={this.onPrevPress} onNextPress={this.onNextPress} current_page={this.state.current_page} paginationCount={paginationCount} cc={"jj"}  totalcount={this.state.totalCount}/>
            <div className="modal fade" id="exampleModalsAssign" tabindex="-1" role="dialog" aria-labelledby="modallongtitle" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modf1">
                  <div className="modal-header">
                    <div className="Et">
                      <span>Assign To</span>
                    </div> <div data-dismiss="modal" aria-label="Close" className="close_assign" id="assignUser_close">
                      <span aria-hidden="true"><i className="fas fa-times"></i></span>
                    </div>
                  </div>
                  <div className="more4">
                    <div className="dropdown-wrap-problem">
                      <h6>Select user</h6>
                      {/* <select className="dropbtn" id="user_select" onChange={(e) => this.onUserChange(e)}>
                      <i className="fa fa-angle-down" />
                      <option value={0}>Select user</option>
                      {this.state.userList.map((item) => (
                        <option value={item.id}>{item.first_name} {item.last_name}</option>
                      ))}
                    </select> */}
                    <Select
                        onChange={this.onUserChange}
                        options={optionUnassign}
                        isSearchable={true}
                        className="select-product-css selectProductDropdown"
                        //values={this.state.productId}
                        defaultValue={this.state.selectedProduct}
                        maxMenuHeight={200}
                        placeholder="Select User..."
                      // closeMenuOnSelect={true}
                        styles= {{
                          singleValue: provided => ({
                            ...provided,
                            color: '#fff !important'
                          }),
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: '1px solid #595959',
                            boxShadow: '0 !important',
                            '&:hover': {
                              border: '1px solid #595959 !important'
                            },
                            color: '#fff !important',
                            borderRadius: '20px !important',
                            backgroundColor: '#000 !important',
                            padding: '0 5px',
                            fontSize: '14px !important',
                            minHeight: '32px !important',
                            height: '32px !important'
                          }),
                          option: (baseStyles, state) => ({
                            ...baseStyles,
                            background: state.isFocused ? '#402500d7 !important':'#000 !important',
                            color: state.isFocused? '#FA9F22 !important' : '#fff !important',
                            width: '100%',
                            fontSize: '14px !important'
                          }),
                          Input: (baseStyles, state) => ({
                            ...baseStyles,
                            color: state.isFocused ? '#fff !important' : '#000'
                          }),
                          placeholder: (baseStyles) => ({
                            ...baseStyles,
                            color: '#fff !important'
                          }),
                          indicatorSeparator: () => null,
                          valueContainer: (baseStyles) => ({
                            ...baseStyles,
                            minHeight: 'auto !important',
                            height: 'auto !important',
                            color: '#fff !important',
                            // marginTop:  !important'
                          }),
                          DropdownIndicator: (baseStyles) => ({
                            ...baseStyles,
                            height: '25px !important',
                            // marginBottom:'-13px !important',
                          }),
                          menuList: (baseStyles) => ({
                            ...baseStyles,
                            background: '#000 !important'
                          })
                        }}
                      />
                      {/* <Select
                        onChange={this.onUserChange}
                        options={optionUnassign}
                        isSearchable={true}
                         className="select-user-dropdown"
                        placeholder="Select user"
                    
                      /> */}

                    </div>
                  </div>
                  <div className="more8">
                    {/* <button data-dismiss="modal" onClick={this.handleSubmit}>Assign Tickets</button> */}
                    <button onClick={this.handleSubmit} >Assign Tickets</button>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>

      )
    }
  }
}

const mapStateToProps = state => ({
  bucketData: state.user.bucketData,
  userData: state.user.userData,
  unassingTicketData: state.user.unassingTicketData,
  ticketAssigned: state.user.ticketAssigned
})

export default connect(mapStateToProps, { getComplaintBuckets, getUserData, getUnassignTicket, assignTicket })(AssignTicket);

