import React, { useState, useEffect, useRef } from 'react';
import './ComplaintDetailsComponent.css';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { changeComplaintStatus } from '../../actions/serviceCenterComplaintAction';
import AccessRestricted from '../AccessRestricted';
import Loader from 'react-loader-spinner';
import moment from 'moment';

function ComplaintDetailsComponent() {
    const complaintDetails = useSelector((state) => state.serviceCenterComplaint.complaintDetails.data);
    const showToubleshoot = useSelector((state) => state.serviceCenter.showToubleshoot);

    const [showLoader, setShowLoader] = useState(true);
    const history = useHistory();
    const dispatch = useDispatch();
    let role = JSON.parse(localStorage.getItem('userRoleId'));
    let btnRef = useRef();

    useEffect(() => {
        document.getElementById('root').style.background = '#F5FBFB';
        document.querySelector('body').style.background = '#F5FBFB';

        return () => {
            document.getElementById('root').style.background = '#121212';
            document.querySelector('body').style.background = '#121212';
        };
    }, []);

    useEffect(() => {
        setShowLoader(true);
        let interval = setTimeout(() => {
            if (complaintDetails === undefined) {
                role === 8 ? history.push('/main-service-center') : history.push('/sub-service-center');
            }
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [complaintDetails]);

    let response = useSelector((state) => state.serviceCenterComplaint.complaintStatus);
    if (response !== undefined) {
        if (response.success === true) {
            role === 8 ? history.push('/main-service-center') : history.push('/sub-service-center');
        }

        if (response.success === false) {
            btnRef.current.innerText = 'Save';
            btnRef.current.style.width = '130px';
            btnRef.current.removeAttribute("disabled");
        }
    }

    const initialValues = {
        issueSolved: 0,
        remarks: ''
    };

    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});

    const handleIssueResolved = (e) => {
        setValues({
            ...values,
            issueSolved: e.target.value,
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleBackBtn = () => {

        if (role === 8)
            history.push('/main-service-center');

        if (role === 9)
            history.push('/sub-service-center');

        if (role === 10)
            history.push('/sub-service-center');
    };

    const handleValidation = (notes) => {
        let errors = {};
        let validity = true;
        if (notes === undefined || notes === '') {
            errors['notes'] = 'Remarks cannot be empty';
            validity = false;
        }

        setErrors(errors);
        return validity;
    };


    const handleSubmit = () => {
        let data = {
            "issue_resolve": values.issueSolved,
            "complaint_id": complaintDetails[0].id,
            "notes": values.remarks
        };
        let validity = handleValidation(data.notes);

        if (validity === true) {
            if (btnRef.current) {
                btnRef.current.setAttribute("disabled", "disabled");
                btnRef.current.innerText = 'Please Wait...';
                btnRef.current.style.width = '150px';
            }
            dispatch(changeComplaintStatus(data));
        }
    };

    if (![8, 9, 10].includes(role)) {
        const style = {
            fontSize: "24px",
            color: "#000",
            textAlign: "center",
            fontWeight: "500",
            paddingTop: "5%"
        };
        return (<AccessRestricted style={style} />);
    }
    else {
        return (
            <React.Fragment>
                <div className='header pd headerNew'>
                    <div className="backHome" onClick={handleBackBtn}>
                        <i className='fas fa-arrow-left'></i> Back
                    </div>
                </div>
                {
                    !complaintDetails && showLoader ?
                        <div className='loader'>
                            <Loader
                                type="Oval"
                                color="Grey"
                                height={200}
                                width={200}
                                className='loaderNew'
                            />
                        </div>
                        :
                        <div className='main-container main-container-new'>
                            <div className='complaintDetailContainer'>
                                <h1 className='complaintNumber'>Complaint No. {complaintDetails && complaintDetails[0] && complaintDetails[0].ticket_id}</h1>
                                <span className='date'>Date : {moment(complaintDetails && complaintDetails[0] && complaintDetails[0].created_at.split(" ")[0]).format('DD/MM/YYYY')}</span>
                                <div className='horizontal'></div>
                                <div className='details-container'>
                                    <div className='productDetailContainer'>
                                        <h3 className='form-subheading'>Product Details</h3>
                                        <table className='reviewDetailsTable'>
                                            <tbody>
                                                <tr>
                                                    <th className='primary-heading'>Category</th>
                                                    <th className='primary-heading'>Product Name</th>
                                                    <th className='primary-heading'>Problem</th>
                                                </tr>
                                                <tr>
                                                    <td>{complaintDetails && complaintDetails[0] && complaintDetails[0].product.group_name}</td>
                                                    <td>{complaintDetails && complaintDetails[0] && complaintDetails[0].product.product_name}</td>
                                                    <td>{complaintDetails && complaintDetails[0] && complaintDetails[0].product.problem ? complaintDetails[0].product.problem : "Issue not listed"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <h4 className='primary-heading' style={{ marginTop: '20px' }}>{complaintDetails && complaintDetails[0] && complaintDetails[0].product.sub_problem ? "Sub problem": "Description"}</h4>
                                        <p style={{ wordBreak: "break-word", width: "85%" }}>{complaintDetails && complaintDetails[0] && complaintDetails[0].product.sub_problem ? complaintDetails[0].product.sub_problem : complaintDetails[0].problem_description}</p>
                                        <h4 className='primary-heading'>Screening status</h4>
                                        <p>{complaintDetails && complaintDetails[0] && complaintDetails[0].screening_status}</p>
                                        <h4 className='primary-heading'>Purchase Date</h4>
                                        <p>{complaintDetails && complaintDetails[0] && moment(complaintDetails[0].purchase_date).format('DD/MM/YYYY')}</p>
                                    </div>
                                    <div className='customerDetailContainer'>
                                        <h3 className='form-subheading'>Customer Details</h3>
                                        <h4 className='primary-heading'>{complaintDetails && complaintDetails[0] && complaintDetails[0].customer.name}</h4>
                                        <p style={{ marginBottom: '0' }}>{complaintDetails && complaintDetails[0] && complaintDetails[0].customer.address}</p>
                                        <p style={{ marginBottom: '0' }}>{complaintDetails && complaintDetails[0] && complaintDetails[0].customer.city}</p>
                                        <p style={{ marginBottom: '20px' }}>{complaintDetails && complaintDetails[0] && complaintDetails[0].customer.state_name} - {complaintDetails && complaintDetails[0] && complaintDetails[0].customer.pincode}</p>

                                        <h4 className='primary-heading'>Contact Number</h4>
                                        <p>{complaintDetails && complaintDetails[0] && complaintDetails[0].customer.mobile}</p>

                                        <h4 className='primary-heading'>Email ID</h4>
                                        <p>{complaintDetails && complaintDetails[0] && complaintDetails[0].customer.email}</p>
                                    </div>
                                </div>
                                <div>
                                    <h3 className='form-subheading'>Troubleshoot Details</h3>
                                    <h4 className='primary-heading'>Issue resolve?</h4>

                                    {complaintDetails && complaintDetails[0].status_name == "complaint_registered" ?
                                        <div>
                                            <select id='issueResolveDropdown' name='issue' value={values.issueSolved || ''} onChange={handleIssueResolved}>
                                                <option value='0'>No</option>
                                                <option value='1'>Yes</option>
                                            </select>
                                        </div> :
                                        <h4 className='primary-heading1' >{complaintDetails[0].troubleshoot_status.toUpperCase()}</h4>
                                    }
                                    <h4 className='primary-heading' style={{ marginTop: '20px' }}>Engineer's Remark</h4>

                                    {complaintDetails && complaintDetails[0].status_name == "complaint_registered" ?
                                        <textarea className='remarks' name='remarks' value={values.remarks || ''} onChange={handleInputChange} >
                                        </textarea> :
                                        <h4 className='primary-heading1' >{complaintDetails[0].eng_remark}</h4>}
                                    <div className='error_add'>
                                        <span className='errorShow'>{errors.notes}</span>
                                    </div>
                                    {showToubleshoot == false ? <div className='buttons'>
                                        <button className='navBtn' id='prevBtn' onClick={handleBackBtn}>Close</button>
                                        <button className='navBtn' id='saveBtn' onClick={handleSubmit} ref={btnRef}>Save</button>
                                    </div> : null}</div>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    }
}

export default ComplaintDetailsComponent;