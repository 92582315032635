import React, { Component } from 'react'
import './ReportComponent.css';
import { connect } from "react-redux";
import history from '../../history';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import { fetchLeaderboardAll, fetchLeaderboardAllChange } from '../../actions/leaderboardAction';
import { getGroupData, fetchAllProductsByCategoryId } from '../../actions/productActions';
import { downloadManifestReport, fetchProductList, fetchChannels, fetchDataFields, onCustomReportDownload, checkCustomReportAccess, fetchDownloadLimit } from '../../actions/reportActions'
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';
import Select from 'react-select';
import ViewProfile from '../ViewProfile'
import { getUserDetail } from '../../actions/userActions'
// import { CSVLink } from "react-csv";
import ReactModal from 'react-modal';
import AccessRestricted from '../AccessRestricted'
import ComplaintRegistration from '../ComplaintRegistration';

let ischecked = false;
const customStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    inset: '15% 0 auto',
    margin: '0 auto',
    transform: 'translate(0)',
    background: '#121212',
    padding: '2rem',
    border: 'none',
    width: '40%',
    position: 'absolute',
    borderRadius: '30px'
  },
};
const {
  allowedRange,
  allowedMaxDays,
  before,
  combine
} = DateRangePicker;

class LeaderboardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leaderBoard: {},
      lederBoardDropDown: [],
      summaryBlock: [],
      summaryBlockTable: [],
      summaryBlockData: {},
      tableHeading: [],
      teams: [],
      grandTotal: 0,
      month: 4,
      selectValue: 'All',
      startDate: new Date().setDate(new Date().getDate() - 15),
      endDate: new Date().setDate(new Date().getDate() - 1),
      reportStartDate: new Date().setDate(new Date().getDate() - 30),
      reportEndDate: new Date().setDate(new Date().getDate() - 1),
      isLoading: false,
      downloadReport: false,
      leaderboard: true,
      errors: [],
      checked: [],
      isSelectAll: false,
      isSelectAllProduct: false,
      isSelectAllData: false,
      selectedChannels: [],
      selectedChannelIds: [],
      productList: [],
      productId: [],
      selectedProducts: 0,
      dataCols: [],
      showPopUp: false,
      dataFields: [],
      productName: '',
      editDateRange: false,
      allChannelIds: [],
      selectedProduct: [],
      productGroupId: [],
      productGroup: [],
      productNameArray: [],
      isSelectSLA: false,
      isPriority: false,
      selectedCategory: []
    }
    this.handleChangeLeader = this.handleChangeLeader.bind(this);
    this.handleLogout = this.handleLogout.bind(this)
    this.leaderboard = this.leaderboard.bind(this)
    this.downloadReport = this.downloadReport.bind(this)
    this.handleDownloadReport = this.handleDownloadReport.bind(this)
    this.csvManifestReport = React.createRef();
    this.customReport = this.customReport.bind(this)
    this.handleSelectAll = this.handleSelectAll.bind(this)
    this.handleDownloadCustomReport = this.handleDownloadCustomReport.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.assignValue = this.assignValue.bind(this);
    this.handleChannelList = this.handleChannelList.bind(this);
    this.handleDataFields = this.handleDataFields.bind(this);
    this.closeDownloadPopup = this.closeDownloadPopup.bind(this);
    this.editDate = this.editDate.bind(this);
    this.downloadCustomReport = this.downloadCustomReport.bind(this);
    this.handleSelectAllData = this.handleSelectAllData.bind(this);
    this.getProductName = this.getProductName.bind(this);
    this.handleSelectAllCategory = this.handleSelectAllCategory.bind(this);
    this.productDisable = this.productDisable.bind(this);
    this.slaBreached = this.slaBreached.bind(this);
    this.priority = this.priority.bind(this);
  }

  handleDownloadReport = async () => {
    let errors = {};
    let diff = [moment(this.state.endDate) - moment(this.state.startDate)];
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if (days > 31) {
      errors["dateRange"] = "You can download report for 1 month only. Maximum date range: 31 days)";
    } else {
      let newDate = {
        "fromDate": moment(this.state.startDate).format('YYYY-MM-DD'),
        "toDate": moment(this.state.endDate).format('YYYY-MM-DD')
      }
      await this.props.downloadManifestReport(newDate);
      // if (this.props.manifestReportData.length > 0) {
      //   errors["dateRange"] = "";
      //   setTimeout(() => {
      //     this.csvManifestReport.current.link.click();
      //   }, 200)
      // } else {
      //   alert("No data found")
      // }
    }
    this.setState({ errors: errors });
  }

  downloadReport() {
    this.setState({ downloadReport: true, leaderboard: false, customReport: false })
  }

  leaderboard() {
    this.setState({ downloadReport: false, leaderboard: true, customReport: false })
  }

  customReport = async () => {
    await this.props.fetchDataFields();
    await this.props.fetchChannels();
    await this.props.getGroupData();
    // await this.props.fetchProductList();
    this.setState({ downloadReport: false, leaderboard: false, customReport: true, productGroup: this.props.groupData.data })
    if (this.state.dataCols =='') {
      this.setState({ dataCols: this.props.fetchDataFieldsList })
    } else {
      if (this.state.isSelectAllData) {
        let tempResult = this.state.dataCols.map((dc) => dc.isChecked === false ? { ...dc, isChecked: true } : dc);
        this.setState({
          dataCols: tempResult
        })
      }
    }
    if (this.state.productGroupId.length === 1 && this.state.selectedProducts >= 0) {
      await this.getProductListByCategoruy(this.state.productGroupId);
      this.productEnable();
    } else {
      this.productDisable();
    }
  }

  handleLogout = () => {
    localStorage.removeItem('token')
    document.querySelector(".modal-backdrop").style.display = "none";
    history.push('/')
  }

  async componentDidMount() {
    let userId = localStorage.getItem('userId');
    let data = {
      "user_id": userId,
    }
    await this.props.getUserDetail(data);
    // await this.props.checkCustomReportAccess();
    let editRes = this.props.userDetail.data;
    if (editRes && editRes.user_role.length > 0 && editRes.user_role[0] && [1, 2, 5].includes(editRes.user_role[0].id)) {
      await this.props.fetchDownloadLimit()
    }
    this.setState({
      isLoading: true,
      newDate: [new Date(this.state.startDate), new Date(this.state.endDate)],
      reportDate: [new Date(this.state.startDate), new Date(this.state.endDate)],
      newCustomReportDate: [new Date(this.state.reportStartDate), new Date(this.state.reportEndDate)]
    })
    let lederData = {
      "team": this.state.selectValue.toUpperCase(),
      "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
      "endDate": moment(this.state.endDate).format('YYYY-MM-DD')
    }
    await this.props.fetchLeaderboardAllChange(lederData);
    this.setState({
      isLoading: false,
      leaderBoard: this.props.leaderboardDataChange,
      lederBoardDropDown: this.props.leaderboardDataChange.dropDownMenu,
      summaryBlock: this.props.leaderboardDataChange.summaryBlock,
      summaryBlockTable: this.props.leaderboardDataChange.summaryBlock ? this.props.leaderboardDataChange.summaryBlock.month : [],
      summaryBlockData: this.props.leaderboardDataChange.summaryBlock,
      tableHeading: this.props.leaderboardDataChange.tableHeading,
      teams: this.props.leaderboardDataChange.teams,
      grandTotal: this.props.leaderboardDataChange.grandTotal,
      selectValue: this.props.leaderboardDataChange.dropDownMenu ? this.props.leaderboardDataChange.dropDownMenu[0] : []
    })
  }

  handleChangeLeader = async (selectValue) => {
    this.setState({ isLoading: true, selectValue: selectValue.value });
    let dataLeader = {
      "team": selectValue.value.toUpperCase(),
      "startDate": moment(this.state.startDate).format('YYYY-MM-DD'),
      "endDate": moment(this.state.endDate).format('YYYY-MM-DD')
    }
    await this.props.fetchLeaderboardAllChange(dataLeader);
    this.setState({
      isLoading: false,
      leaderBoard: this.props.leaderboardDataChange,
      lederBoardDropDown: this.props.leaderboardDataChange.dropDownMenu,
      summaryBlock: this.props.leaderboardDataChange.summaryBlock,
      summaryBlockTable: this.props.leaderboardDataChange.summaryBlock.month,
      summaryBlockData: this.props.leaderboardDataChange.summaryBlock,
      tableHeading: this.props.leaderboardDataChange.tableHeading,
      teams: this.props.leaderboardDataChange.teams,
      grandTotal: this.props.leaderboardDataChange.grandTotal,
      selectValue: selectValue.value
    })
  };

  newDateRange = (value) => {
    this.setState({
      startDate: new Date(value[0]),
      endDate: new Date(value[1]),
      newDate: value
    })
  }

  customReportDate = (value) => {
    this.setState({
      reportStartDate: new Date(value[0]),
      reportEndDate: new Date(value[1]),
      newCustomReportDate: value,
      editDateRange: false
    })
  }

  dateSelect = async (value) => {
    this.setState({
      isLoading: true,
      startDate: new Date(value[0]),
      endDate: new Date(value[1]),
      reportDate: value
    })
    let dataLeader = {
      "team": this.state.selectValue.toUpperCase(),
      "startDate": moment(new Date(value[0])).format('YYYY-MM-DD'),
      "endDate": moment(new Date(value[1])).format('YYYY-MM-DD')
    }
    await this.props.fetchLeaderboardAllChange(dataLeader);
    this.setState({
      isLoading: false,
      leaderBoard: this.props.leaderboardDataChange,
      lederBoardDropDown: this.props.leaderboardDataChange.dropDownMenu,
      summaryBlock: this.props.leaderboardDataChange.summaryBlock,
      summaryBlockTable: this.props.leaderboardDataChange.summaryBlock.month,
      summaryBlockData: this.props.leaderboardDataChange.summaryBlock,
      tableHeading: this.props.leaderboardDataChange.tableHeading,
      teams: this.props.leaderboardDataChange.teams,
      grandTotal: this.props.leaderboardDataChange.grandTotal,
      selectValue: this.state.selectValue
    })
  }

  handleSelectAllData() {
    ischecked = !ischecked;
    this.setState({ isSelectAllData: !this.state.isSelectAllData });
    if (ischecked) {
      let tempResult = this.state.dataCols.map((dc) => dc.isChecked === false ? { ...dc, isChecked: true } : dc)
      this.setState({
        dataCols: tempResult
      })
    } else {
      let tempResult = this.state.dataCols.map((dc) => dc.isChecked === true ? { ...dc, isChecked: false } : dc)
      this.setState({
        dataCols: tempResult
      })
    }
  }

  handleSelectAll() {
    this.setState({ isSelectAll: !this.state.isSelectAll });
    this.assignValue();
  }

  onProductChange = () => {
    this.setState({ isSelectAllProduct: !this.state.isSelectAllProduct });
    this.assignProductValue();
  }

  assignProductValue = () => {
    let selectAllProducts = [];
    if (!this.state.isSelectAllProduct) {
      selectAllProducts = this.state.productNameArray;
      let selectedAllProductIds = [];
      selectAllProducts.map((item) => selectedAllProductIds.push(item.id));
      this.setState({ selectedProduct: selectAllProducts, selectedProducts: selectedAllProductIds.length, productId: selectedAllProductIds }, () => {
      });
    } else {
      selectAllProducts = '';
      this.setState({ selectedProduct: [], selectedAllProductIds: '', selectedProducts: 0, productId: [] }, () => {
      });
    }
  }

  assignValue() {
    let selectAllChannels = [];
    if (!this.state.isSelectAll) {
      selectAllChannels = this.props.fetchChannelList;
      let selectedAllChannelIds = [];
      selectAllChannels.map((item) => selectedAllChannelIds.push(item.id));
      this.setState({ selectedChannels: this.props.fetchChannelList, selectedChannelIds: selectedAllChannelIds }, () => {
      });
    } else {
      selectAllChannels = '';
      this.setState({ selectedChannels: [], selectedChannelIds: [] }, () => {
      });
    }
  }

  handleChannelList = (selectedChannels) => {
    if (this.state.isSelectAll) //if select all is selected
    {
      this.setState({ isSelectAll: false });
      // document.querySelector(".channel-list .css-tlfecz-indicatorContainer").setAttribute('id', 'hideDiv');
      // document.querySelector(".channel-list .css-1okebmr-indicatorSeparator").classList.add("hideDiv");
    }
    let selectedIds = [];
    selectedChannels.map((item) => (selectedIds.push(item.value)))
    this.setState({ selectedChannelIds: selectedIds, selectedChannels }, () => {
      // this.handleValidation();
    });
  }

  handleValidation = () => {
    let errors = {};
    let flag = false;
    const newData = this.state.dataCols.filter((data) => {
      return data.isChecked;
    });
    this.setState({ dataFields: newData });
    if (newData.length < 1) {
      errors["dataFields"] = "Please select atleast 1 field";
      flag = true;
    }
    if (this.state.selectedChannelIds.length < 1) {
      errors["channels"] = "Please select atleast 1 channel";
      flag = true;
    }
    if (this.state.productGroupId === '') {
      errors["category"] = "Please select atleast 1 category";
      flag = true;
    }
    if (this.state.productGroupId) {
      if (this.state.productGroupId.length === 1) {
        if (this.state.selectedProducts === 0) {
          errors["products"] = "Please select product";
          flag = true;
        }
      }
    }
    this.setState({ errors: errors });
    return flag;
  }

  handleDownloadCustomReport = async () => {
    if (this.handleValidation() !== true) {
      this.setState({ showPopUp: true })
    }
  }

  downloadCustomReport = async () => {
    //this.setState({ isLoading: true })
    const newData = this.state.dataCols.filter((data) => {
      return data.isChecked;
    })
    let priority = "0"
    if (this.state.isPriority) {
      priority = "1"
    }
    let data = {
      catIds: this.state.productGroupId,
      productIds: this.state.productGroupId.length > 1 ? '' : this.state.productId,
      channelIds: this.state.selectedChannelIds,
      attributes: newData,
      sla_breach: this.state.isSelectSLA,
      is_priority: priority,
      fromDate: moment(this.state.reportStartDate).format('YYYY-MM-DD'),
      toDate: moment(this.state.reportEndDate).format('YYYY-MM-DD'),
    }
    await this.props.onCustomReportDownload(data);
    this.setState({ isLoading: false });
    // let isSuccess = this.props.isAuthorized;
    // if (isSuccess.message !== null) {
    // if (isSuccess.success) {
    ischecked = false;
    this.setState({
      productGroupId: [],
      showPopUp: false,
      productId: [],
      selectedChannelIds: '',
      selectedChannels: '',
      selectedCategory: '',
      productOptions: '',
      selectedProduct: [],
      channelList: '',
      isSelectAll: false,
      isSelectAllCategories: false,
      isSelectAllData: false,
      reportStartDate: new Date().setDate(new Date().getDate() - 30),
      reportEndDate: new Date(),
      dataCols: this.props.fetchDataFieldsList,
      newCustomReportDate: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
      isSelectAllProduct: false,
      sla_breach: false,
      catIds: [],
      is_priority: false
    })
    // }
    // alert(isSuccess && isSuccess.message ? isSuccess.message : "Something went wrong!");
    // } else {
    //   alert("Something went wrong");
    // }
  }

  closePopUp = () => {
    let isExecuted = window.confirm("All data filled will be lost. Do you want to continue?");
    if (isExecuted) {
      this.setState({ isLoading: true });
      setTimeout(() => {
        ischecked = false;
        this.setState({
          isLoading: false,
          showPopUp: false,
          productId: 0,
          selectedChannelIds: '',
          selectedChannels: '',
          selectedCategory: '',
          productOptions: '',
          selectedProduct: [],
          channelList: '',
          isSelectAll: false,
          isSelectAllData: false,
          reportStartDate: new Date().setDate(new Date().getDate() - 30),
          reportEndDate: new Date(),
          dataCols: this.props.fetchDataFieldsList,
          newCustomReportDate: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
          isSelectAllProduct: false
        })
      }, 200)
    } else {
      return;
    }
  }

  handleProductSelection = (a) => {
    this.setState({ selectedProducts: a.length, productName: a.label, selectedProduct: a }, () => {
    });
    if (this.state.isSelectAllProduct) //if select all is selected
    {
      this.setState({ isSelectAllProduct: false });
    }
    if (this.state.isSelectAllCategories) {
      this.setState({ productId: '' });
    } else {
      let productId = [];
      a.map((item) => (productId.push(item.value)))
      this.setState({ productId });
    }
  }

  handleDataFields(e) {
    if (this.state.isSelectAllData) {
      this.setState({ isSelectAllData: false })
      ischecked = false;
    }
    const { name, checked } = e.target;
    let tempResult = this.state.dataCols.map((dc) => dc.attributes === name ? { ...dc, isChecked: checked } : dc)
    this.setState({
      dataCols: tempResult
    }, () => {
      // this.handleValidation();
    });
  }

  closeDownloadPopup() {
    this.setState({
      showPopUp: false
    })
  }

  editDate() {
    this.setState({ editDateRange: true })
  }

  getProductName = async (selectedCategory) => {
    let selectedCatIds = [];
    let selectedCategories = selectedCategory;
    this.setState({ selectedCategory: selectedCategories });
    if (selectedCategories.length === 1) {
      selectedCatIds = [selectedCategories[0].value];
      this.setState({ productGroupId: selectedCatIds, productId: '', selectedProducts: 0, selectedProduct: [], isSelectAllCategories: false });
      await this.getProductListByCategoruy(selectedCatIds);
      this.productEnable();
    } else if (selectedCategories.length > 1) {
      selectedCategories.map((item) => (selectedCatIds.push(item.value)))
      this.setState({ productGroupId: selectedCatIds, productId: 0, selectedProducts: 0, selectedProduct: [], isSelectAllCategories: false, productNameArray: '' });
      this.productDisable();
      if (this.state.isSelectAllProduct) {
        this.setState({ isSelectAllProduct: false });
      }
    } else {
      this.productDisable();
      this.setState({ productGroupId: '', isSelectAllProduct: false, productId: 0, selectedProducts: 0, selectedProduct: [], isSelectAllCategories: false, productNameArray: '' })
    }
  }

  handleSelectAllCategory() {
    this.setState({ isSelectAllProduct: false, productNameArray: '' });
    this.productDisable();
    this.setState({ isSelectAllCategories: !this.state.isSelectAllCategories }, () => {
      if (this.state.isSelectAllCategories) {
        let allCatIds = [];
        this.state.productGroup.map((item) => (allCatIds.push(item.id)))
        this.setState({ productGroupId: allCatIds });
      } else {
        this.setState({ productGroupId: '', productId: 0, selectedProducts: 0, selectedProduct: [], selectedCategory: '' });
        let classExist2 = document.querySelector(".category .css-14el2xx-placeholder");
        if (classExist2) {
          classExist2.innerHTML = "Search Category.."
        }
      }
    });
  }

  productDisable() {
    this.setState({ selectedProduct: '' })
    let classExist = document.querySelector(".product-name-wrapper .select-product-css .css-14el2xx-placeholder");
    let classExist2 = document.querySelector(".product-name-wrapper .css-1pahdxg-control");
    if (classExist) {
      classExist.innerText = "Disabled..."
    }
    if (classExist2) {
      classExist2.innerText = "Disabled..."
    }
    let classDisabled = document.querySelector(".product-name-wrapper .select-product-css .css-319lph-ValueContainer input");
    let classDisabled3 = document.querySelector(".product-name-wrapper .select-product-css .css-ackcql input");
    if (classDisabled) {
      classDisabled.setAttribute("disabled", "true");
    }
    if (classDisabled3) {
      classDisabled3.setAttribute("disabled", "true");
    }
    let classHide = document.querySelector(".product-name-wrapper .css-1hb7zxy-IndicatorsContainer");
    if (classHide) {
      classHide.style.display = 'none';
    }
    let classHide2 = document.querySelector(".product-name-wrapper .css-1wy0on6");
    if (classHide2) {
      classHide2.style.display = 'none';
    }
    let classDisabled2 = document.querySelector(".product-name-wrapper #selectAllProducts");
    if (classDisabled2) {
      classDisabled2.setAttribute("disabled", "true");
    }
  }

  productEnable() {
    let classExist = document.querySelector(".product-name-wrapper .select-product-css .css-14el2xx-placeholder");
    let classExist2 = document.querySelector(".product-name-wrapper .css-1pahdxg-control");
    if (classExist) {
      classExist.innerText = "Search Product..."
    }
    if (classExist2) {
      classExist2.innerText = "Search Product..."
    }
    let classDisabled = document.querySelector(".product-name-wrapper .select-product-css .css-319lph-ValueContainer input");
    let classDisabled3 = document.querySelector(".product-name-wrapper .select-product-css .css-ackcql input");
    if (classDisabled) {
      classDisabled.removeAttribute("disabled");
    }
    if (classDisabled3) {
      classDisabled3.removeAttribute("disabled");
    }
    let classHide = document.querySelector(".product-name-wrapper .css-1hb7zxy-IndicatorsContainer");
    if (classHide) {
      classHide.style.display = 'block';
    }
    let classHide2 = document.querySelector(".product-name-wrapper .css-1wy0on6");
    if (classHide2) {
      classHide2.style.display = 'block';
    }
    let classDisabled2 = document.querySelector(".product-name-wrapper #selectAllProducts");
    if (classDisabled2) {
      classDisabled2.removeAttribute("disabled");
    }
  }

  getProductListByCategoruy = async (productGroupId) => {
    let data = {
      "category_id": productGroupId
    }
    await this.props.fetchAllProductsByCategoryId(data);
    let productList = this.props.productListByCat;
    this.setState({ productNameArray: productList, productId: 0 });
  }

  slaBreached() {
    this.setState({ isSelectSLA: !this.state.isSelectSLA })
  }

  priority() {
    this.setState({ isPriority: !this.state.isPriority })
  }

  render() {
    const { selectedChannels, selectedCategory } = this.state;
    let productOptions = []
    if (this.state.productNameArray !== '') {
      this.state.productNameArray.map((obj) => (
        productOptions.push(
          {
            value: obj.id,
            label: obj.product_name,
          }
        )
      ))
    }
    let channelList = [];
    {
      this.props.fetchChannelList.map((item) => (
        channelList.push(
          {
            value: item.id,
            label: item.name,
          }
        )
      ))
    }
    let productGroupOptions = [];
    {
      this.state.productGroup.map((item) => (
        productGroupOptions.push(
          {
            value: item.id,
            label: item.group_name,
          }
        )
      ))
    }
    // let fileName = "Manifest_Report_" + moment().format('DD/MM/YYYY HH:mm:ss') + ".csv"
    const { selectValue, lederBoardDropDown } = this.state;
    let optionDropCourier = []
    lederBoardDropDown.map((obj, index) => (
      optionDropCourier.push(
        {
          label: obj,
          value: obj,
          id: [index + 1]
        }
      )
    ))
    let userRoleId = localStorage.getItem('userRoleId');
    if (userRoleId === '4' || userRoleId === '8' || userRoleId === '9' || userRoleId === '10') {
      return (<AccessRestricted />)
    } 
    else if (userRoleId === '6'){
      return (<ComplaintRegistration />)
  }
    else {
      return (
        <div>
          <div className="leaderboard">
            <div className="sidebar sidebar-leaderboard">
              <img src="/img/nlogo.png" alt="" />
              <div className="sidebar-container">
                <a href="/dashboard"><i className="fa fa-th-large"></i><span>Dashboard</span></a>
                <a href="/complaints-data"><i className="fa fa-exclamation-circle"></i><span>Complaints</span></a>
                <a href="/rtl-data"><i className="fa fa-exclamation-circle "></i><span >RTL Complaints</span></a>
                <a href="/Sc-complaints"><i className="fa fa-exclamation-circle "></i><span >SC Complaitns</span></a>
                <a href="/refund"><i className="fa fa-rupee "></i><span >Refund</span></a>
                <a href="/manage-user"><i className="fas fa-users"></i><span>Manage Users</span></a>
                <a href="/leaderboard" className="nav-highligh"><i className="fas fa-signal menucolor2"></i><span className="menucolor">Reports</span></a>
                <a href="/manage-product"><i className="far fa-building"></i><span>Product</span></a>
                <a href="bulk_movement"><i className="fas fa-dumpster"></i><span>Bulk Movement</span></a>
                <a href="/warranty-data"><i className='fas fa-user-shield'></i><span>Customer</span></a>
                <a href="/settings"><i className="fa fa-cog"></i><span>Settings</span></a>
              </div>
            </div>
            <div className="modal fade" id="notification">
              <div className="modal-dialog" role="document">
                <p>Notifications(4)</p>
                <ul id="menu"><li><a href="#" className="noactive">All</a></li><li className="actice2"><a href="/">Unread</a></li></ul>
                <div data-dismiss="modal" aria-label="Close" className="closeModal">
                  <span aria-hidden="true"><i className="fas fa-times"></i></span>
                </div>
              </div>
            </div>
            <div className="nav dashboard">
              <span>
                <i className         ="fas fa-user-circle" data-toggle="modal" data-target="#exampleModals"></i> </span>
            </div>
            <ViewProfile />
          </div>
          <div className="content-leaderboard">
            <div className="content-title">
              <h3>Reports</h3>
            </div>
            <ul id="menu">
              {/* <li><a href="/reports" className="noactive">Report</a></li> */}
              <li className={this.state.leaderboard && 'actice2-border'}><a onClick={this.leaderboard} className={this.state.leaderboard && 'actice2'}>Leaderboard</a></li>
              {this.props.manifestConfig.type && <li className={this.state.downloadReport && 'actice2-border'}><a onClick={this.downloadReport} className={this.state.downloadReport && 'actice2'}>Manifest Report</a></li>}
              {this.props.isAuthorized.type && <li className={this.state.customReport && 'actice2-border'}><a onClick={this.customReport} className={this.state.customReport && 'actice2'}>Customised report</a></li>}
              {/* <li><a href="#" className="noactive">Courier</a></li> */}
            </ul>
            {this.state.leaderboard &&
              <div>
                <div className="dropdown-wrap leaderBoard">
                  <div className="lbtn" style={{marginRight:"0px"}}>
                    <div className="dropdown-bar">
                      <h6>Leaderboard</h6>
                      <Select
                        onChange={this.handleChangeLeader}
                        options={optionDropCourier}
                        isSearchable={true}
                        value={selectValue}
                        maxMenuHeight={200}
                        className="problem-product-courier"
                        placeholder={selectValue}
                        closeMenuOnSelect={true}
                        styles= {{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: '1px solid #595959',
                            boxShadow: '0 !important',
                            '&:hover': {
                              border: '1px solid #595959 !important'
                            },
                            borderRadius: '20px !important',
                            backgroundColor: '#000 !important',
                            padding: '0 5px',
                            fontSize: '14px !important',
                            minHeight: 'auto !important',
                            height: 'auto !important',
                            width: "193px ",
                          }),
                          option: (baseStyles, state) => ({
                            ...baseStyles,
                            background: state.isFocused ? '#402500d7 !important':'#000 !important',
                            color: state.isFocused? '#FA9F22': '#fff !important',
                            width: '100%'
                          }),
                          placeholder: (baseStyles) => ({
                            ...baseStyles,
                            color: '#fff !important'
                          }),
                          indicatorSeparator: () => null,
                          valueContainer: (baseStyles) => ({
                            ...baseStyles,
                            minHeight: '32px !important',
                            height: '32px !important',
                            color: '#fff !important'
                          }),
                          menuList: (baseStyles) => ({
                            ...baseStyles,
                            background: '#000 !important'
                          }),
                          Input: (baseStyles) => ({
                            ...baseStyles,
                           
                            color: '#fff !important'
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className="dbtn">
                    <div className="dropdown-bar">
                      <h6>Date - (DD/MM/YYYY)</h6>
                      {/* <select id="date-dropdown" className="dropbtn"> <i className="fa fa-angle-down" ></i>
                        <option key={1} value="4">Last 4 Months</option>
                        <option key={2} value="2">Last 2 Months</option>
                        <option key={3} value="1">Last 1 Month</option>
                        </select> */}
                      {/* <DateRangePicker showTwoCalendar format="yyyy-MM-dd" disabledDate={allowedRange(moment(new Date(new Date().setDate(new Date().getDate() - 90))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => {this.dateSelect(value)}} value={[new Date(this.state.startDate),new Date(this.state.endDate)]} /> */}
                      <DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(new Date(new Date().setDate(new Date().getDate() - 90))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.dateSelect(value) }} value={this.state.reportDate} className="date-leaderboard" />
                    </div>
                  </div>
                  <div className="summary-wrap">

<div className="total">
  <p>{this.state.summaryBlockData.name}</p>
  <h3>{this.state.summaryBlockData.count}</h3>
</div>
<div className="table-wrap">
  <table>
    <thead>
      <tr>
        {this.state.summaryBlockTable.map((obj, index) => (
          <th key={index + 1} >{obj.month.substring(0, 3)}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      <tr>
        {this.state.summaryBlockTable.map((obj, index) => (
          <td key={index + 1}>{obj.count}</td>
        ))}
      </tr>
    </tbody>
  </table>
</div>
</div>
                </div>
                {this.state.isLoading ?
                  <div className='loader'>
                    <Loader
                      type="Oval"
                      color="Grey"
                      height={200}
                      width={200}
                    />
                  </div>
                  : <div>
                    <div className="summary-block">
                     
                    </div>
                    <div className="main-table">
                      <table>
                        <thead>
                          <tr>
                            <th className="col-sm-3">{this.state.selectValue === 'ALL' ? 'Pending Form' : 'Buckets'}</th>
                            {this.state.tableHeading.map((obj, index) => (
                              <th key={index + 1}>{obj.month.substring(0, 3)}</th>
                            ))}
                            <th className="col-sm-5">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.teams.map((obj, index) => {
                            return (
                              <tr key={index + 1}>
                                <td className='col-sm-3'>{obj.name}</td>
                                {obj.data.map((data, index) => (
                                  <td key={index + 1}>{data.count}</td>
                                ))}
                                <td className="progress-wrap">
                                  <p>{obj.total}</p>
                                  <div className="progress">
                                    <div className="progress_inner" style={this.state.grandTotal > 0 ? { width: obj.total / this.state.grandTotal * 100 + '%' } : { width: '0px' }}></div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
              </div>
            }
            {/*Mainfest Report*/}
            {this.state.downloadReport &&
              <div>
                {this.props.manifestConfig.type && this.props.userDetail.data && this.props.userDetail.data.user_role.length > 0 && [1, 2, 5].includes(this.props.userDetail.data.user_role[0].id) ?
                  <div className='downloadReport col-sm-6'><div>
                    <DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={allowedRange(moment(new Date(new Date().setDate(new Date().getDate() - 90))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))} onOk={(value) => { this.newDateRange(value) }} value={this.state.newDate} className="date-manifest" />
                    <p className="errorShow">{this.state.errors["dateRange"]}</p>
                  </div>
                    <br></br>
                    <button className='edit-user-btn' onClick={this.handleDownloadReport} disabled={!this.props.manifestConfig.canDownload}>Submit</button>
                  </div> : null
                }
              </div>
            }
            {this.state.customReport &&
              <div>
                {this.state.isLoading ?
                  <div className='loader'>
                    <Loader
                      type="Oval"
                      color="Grey"
                      height={200}
                      width={200}
                    />
                  </div>
                  :
                  <div>
                    <div className="customReportWrapper">
                      <div className="dbtn">
                        <div className="dropdown-bar">
                          <h6>Date - (DD/MM/YYYY)</h6>
                          <DateRangePicker showTwoCalendar format="dd/MM/yyyy" disabledDate={combine(allowedMaxDays(180), before("2010-01-01"))} onOk={(value) => { this.customReportDate(value) }} value={this.state.newCustomReportDate} className="date-leaderboard cr-datepicker" />
                          {/* <p className="errorShow">{this.state.errors["dateRange"]}</p> */}
                        </div>
                      </div>
                      {this.state.productGroup.length > 0 &&
                        <div className="category">
                          <div className="label">
                            <p>Select category</p>
                          </div>
                          {/* <select value={this.state.productGroupId} className="category" id="product-group" name="product group" placeholder="Select type of product" onChange={(e) => this.getProductName(e)}>
                            {this.state.productGroup.map((option) => (
                            <option value={option.id} >{option.group_name}</option>
                            ))}
                            </select> */}
                          <Select
                            onChange={this.getProductName}
                            options={productGroupOptions}
                            value={this.state.isSelectAllCategories ? productGroupOptions : selectedCategory}
                            isSearchable={true}
                            className="select-channel"
                            id="product-group"
                            name="product group"
                            maxMenuHeight={200}
                            placeholder="Search Category.."
                            isMulti
                            styles= {{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                border: '1px solid #595959',
                                boxShadow: '0 !important',
                                '&:hover': {
                                  border: '1px solid #595959 !important'
                                },
                                borderRadius: '20px !important',
                                backgroundColor: '#000 !important',
                                padding: '0 5px',
                                fontSize: '14px !important',
                                minHeight: 'auto !important',
                                height: 'auto !important'
                              }),
                              option: (baseStyles, state) => ({
                                ...baseStyles,
                                background: state.isFocused ? '#402500d7 !important':'#000 !important',
                                color: state.isFocused? '#FA9F22': '#fff !important',
                                width: '100%'
                              }),
                              placeholder: (baseStyles) => ({
                                ...baseStyles,
                                color: '#fff !important'
                              }),
                              indicatorSeparator: () => null,
                              valueContainer: (baseStyles) => ({
                                ...baseStyles,
                                minHeight: 'auto !important',
                                height: 'auto !important',
                                color: '#fff !important',
                                width: 'auto !important'
                              }),
                              menuList: (baseStyles) => ({
                                ...baseStyles,
                                background: '#000 !important'
                              })
                            }}
                          />
                          <div className="select-all-wrapper">
                            <input type="checkbox" name="selectAllCat" id="selectAllCat" onChange={this.handleSelectAllCategory} checked={this.state.isSelectAllCategories} />
                            <label htmlFor="selectAllCat">Select all categories</label>
                          </div>
                          <p className="error_add">
                            <span className="errorShow">{this.state.errors["category"]}</span>
                          </p>
                        </div>
                      }
                      <div className="channel-list">
                        <h6>Select Channel</h6>
                        <Select
                          onChange={this.handleChannelList}
                          options={channelList}
                          value={this.state.isSelectAll ? channelList : selectedChannels}
                          isSearchable={true}
                          className="select-channel"
                          maxMenuHeight={200}
                          placeholder="Search Channel.."
                          isMulti
                          styles= {{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              border: '1px solid #595959',
                              boxShadow: '0 !important',
                              '&:hover': {
                                border: '1px solid #595959 !important'
                              },
                              borderRadius: '20px !important',
                              backgroundColor: '#000 !important',
                              padding: '0 5px',
                              fontSize: '14px !important',
                              minHeight: 'auto !important',
                              height: 'auto !important'
                            }),
                            option: (baseStyles, state) => ({
                              ...baseStyles,
                              background: state.isFocused ? '#402500d7 !important':'#000 !important',
                              color: state.isFocused? '#FA9F22': '#fff !important',
                              width: '100%'
                            }),
                            placeholder: (baseStyles) => ({
                              ...baseStyles,
                              color: '#fff !important'
                            }),
                            indicatorSeparator: () => null,
                            valueContainer: (baseStyles) => ({
                              ...baseStyles,
                              minHeight: 'auto !important',
                              height: 'auto !important',
                              color: '#fff !important'
                            }),
                            menuList: (baseStyles) => ({
                              ...baseStyles,
                              background: '#000 !important'
                            })
                          }}
                        />
                        <div className="select-all-wrapper">
                          <input type="checkbox" name="selectAll" id="selectAll" onChange={this.handleSelectAll} checked={this.state.isSelectAll} />
                          <label htmlFor="selectAll">Select all channels</label>
                        </div>
                        <div className="error_add">
                          <span className="errorShow">{this.state.errors["channels"]}</span>
                        </div>
                      </div>
                    </div>
                    <div className="customReportWrapper1">
                      <div className="product-name-wrapper">
                        <h6>Select Product</h6>
                        <Select
                          onChange={this.handleProductSelection}
                          value={this.state.isSelectAllProduct ? productOptions : this.state.selectedProduct}
                          options={productOptions}
                          isSearchable={true}
                          className="select-product-css"
                          maxMenuHeight={200}
                          placeholder="Search Product.."
                          isMulti
                          styles= {{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              border: '1px solid #595959',
                              boxShadow: '0 !important',
                              '&:hover': {
                                border: '1px solid #595959 !important'
                              },
                              
                              borderRadius: '20px !important',
                              backgroundColor: '#000 !important',
                              padding: '0 5px',
                              fontSize: '14px !important',
                              minHeight: 'auto !important',
                              height: 'auto !important'
                            }),
                            option: (baseStyles, state) => ({
                              ...baseStyles,
                              background: state.isFocused ? '#402500d7 !important':'#000 !important',
                              color: state.isFocused? '#FA9F22': '#fff !important',
                              width: '100%'
                            }),
                            placeholder: (baseStyles) => ({
                              ...baseStyles,
                              color: '#fff !important'
                            }),
                            indicatorSeparator: () => null,
                            valueContainer: (baseStyles) => ({
                              ...baseStyles,
                              minHeight: 'auto!important',
                              height: 'auto !important',
                              color: '#fff !important'
                            }),
                            menuList: (baseStyles) => ({
                              ...baseStyles,
                              background: '#000 !important'
                            }),
                            Input: (baseStyles) => ({
                              ...baseStyles,
                             
                              color: '#fff !important'
                            }),
                          }}
                        />
                        <div className="select-all-wrapper">
                          <input type="checkbox" name="selectAllProducts" id="selectAllProducts" onChange={this.onProductChange} checked={this.state.isSelectAllProduct} />
                          <label htmlFor="selectAllProducts">Select all products</label>
                        </div>
                        <div className="error_add">
                          <span className="errorShow">{this.state.errors["products"]}</span>
                        </div>
                      </div>
                      <div className="sla_breached">
                        <input type="checkbox" name="selectSLA" id="selectSLA" onChange={this.slaBreached} checked={this.state.isSelectSLA} />
                        <label htmlFor="selectSLA">SLA Breached</label>
                      </div>
                      <div className="priority">
                        <input type="checkbox" name="selectPriority" id="selectPriority" onChange={this.priority} checked={this.state.isPriority} />
                        <label htmlFor="selectPriority">Priority Tickets</label>
                      </div>
                    </div>
                    <div className='data-fields'>
                      <h5>Data Fields</h5>
                      {/* <p>Select data fields you want in your report.</p> */}
                      <div className='select-all-wrapper'>
                        <input className="select-all-data" type="checkbox" name="selectAllDF" id="selectAllDF" onChange={this.handleSelectAllData} checked={this.state.isSelectAllData} />
                        <label htmlFor="selectAllDF">Select all data fields</label>
                      </div>
                      <div className="data-fields-wrapper">
                        {this.state.dataCols.map((field, index) => (
                          <div key={index} className='data-col'>
                            <input className="field-checkbox" type="checkbox" name={field.attributes} id={field.attributes} onChange={this.handleDataFields} checked={field.isChecked || false} />
                            <label htmlFor={field.attributes}>{field.display_name}</label>
                          </div>
                        ))
                        }
                      </div>
                      <div className="error_add" >
                        <span className="errorShow">{this.state.errors["dataFields"]}</span>
                      </div>
                    </div>
                    <div className="Submit submitCustomReport">
                      <button type="button" onClick={this.handleDownloadCustomReport} className="transferButton downloadReport">Submit</button>
                    </div>
                    <ReactModal
                      isOpen={this.state.showPopUp}
                      contentLabel="Minimal Modal Example"
                      style={customStyles}
                      ariaHideApp={false}
                      className="custom_report_popup"
                    >
                      <div className="closeCustomPopup">
                        <i className="fas fa-times" onClick={this.closePopUp}></i>
                      </div>
                      <h2 id="rModal_head">Download Report</h2>
                      {/* <h6>Product name</h6>
                        <p>{this.state.productName}</p> */}
                      <div className="date-wrapper">
                        <div className="dateRange">
                          <h6>Date range</h6>
                          <p>{moment(this.state.reportStartDate).format('DD/MM/YYYY')} - {moment(this.state.reportEndDate).format('DD/MM/YYYY')}</p>
                        </div>
                        {/* <div className='edit_date' onClick={this.editDate}>
                          <i className="fas fa-pencil-alt" title="Edit"></i> Edit Date Range
                          </div> */}
                      </div>
                      <h6>Data fields</h6>
                      <p>{this.state.dataFields.map((item) => <span>{item.display_name} {this.state.dataFields.length > 1 ? ',' : null} </span>)}</p>
                      <div className="download-report">
                        <button type="button" onClick={this.downloadCustomReport} className="download_report transferButton">Download Report</button>
                        <button id="close_rModal" onClick={this.closeDownloadPopup}>Edit</button>
                      </div>
                      {this.state.editDateRange &&
                        <DateRangePicker showTwoCalendar open={true} format="dd/MM/yyyy" disabledDate={combine(allowedMaxDays(180), before("2010-01-01"))} onOk={(value) => { this.customReportDate(value) }} value={this.state.newCustomReportDate} className="date-leaderboard edit-date-popup" />
                      }
                    </ReactModal>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
  leaderboardData: state.report.leaderboardData,
  leaderboardDataChange: state.report.leaderboardDataChange,
  userDetail: state.user.userDetail,
  dailyReportData: state.report.dailyReportData,
  manifestReportData: state.report.manifestReportData,
  fetchAllProducts: state.report.fetchAllProducts,
  fetchChannelList: state.report.fetchChannelList,
  fetchDataFieldsList: state.report.fetchDataFieldsList,
  downloadCustomReport: state.report.downloadCustomReport,
  isAuthorized: state.report.isAuthorized,
  groupData: state.report.groupData,
  detailData: state.report.detailData,
  productListByCat: state.product.productListByCat,
  manifestConfig: state.report.manifestReportConfig,
})

export default connect(mapStateToProps, { fetchLeaderboardAll, fetchLeaderboardAllChange, getUserDetail, downloadManifestReport, fetchProductList, fetchChannels, fetchDataFields, onCustomReportDownload, checkCustomReportAccess, getGroupData, fetchAllProductsByCategoryId, fetchDownloadLimit })(LeaderboardComponent);