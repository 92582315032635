import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import './RTLComplaintComponent.css';
// import ReactModal from 'react-modal';
import {
  getDistributorComplaintDetail, showComplaintDetailsAction, getDistributorData
} from '../../actions/distributorAction';
import 'react-medium-image-zoom/dist/styles.css';
// import history from '../../history';
import Loader from 'react-loader-spinner';
import CutomerDetails from './Retail/CustomerDetails';
import TicketReceived from './ticketReceived';
import History from './Retail/History';
import Conversations from './Retail/Conversations';
import Comments from './Retail/Comments';

class ComplaintDetailsNew extends Component {
  constructor() {
    super();
    this.state = {
      ticketReceived: true,
      approvedTicket: true,
      subMenu: 1,
      click: 0,
      showLoader: false,
      data: "",
      activeProduct: '',
      complaintProductList: []
    };
    // this.handleRefreshList = this.props.handleRefreshList.bind(this)
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
  }

  onSubMenuClick = (e) => {
    this.setState({ subMenu: e.target.value }, () => {
      // this.getComplaintList();
    });
  };

  onItemPress = async (e, item) => {
    this.setState({ showLoader: true, complaintProductList: [] });
    await this.props.getDistributorComplaintDetail(item.id);
    this.setState({
      activeProduct: this.props.complaintProductList[0].product_color_id,
      complaintProductList: this.props.complaintProductList
    });
    this.setState({ showLoader: false, subMenu: 1 });
    const element = document.querySelector(".activeColor");
    if (element) {
      element.scrollIntoView({ block: "center" });
    }
  };

  onCloseDetail = (e) => {
    e.preventDefault();
    this.props.showComplaintDetailsAction(false);
  };

  onDownloanInvoice = (url) => {
  };

  zoomIn() {
    this.setState({ click: this.state.click + 1 });
    if (this.state.click > 1) {
      this.setState({ click: 2 });
    }
  }

  zoomOut() {
    this.setState({ click: this.state.click - 1 });
    if (this.state.click < 1) {
      this.setState({ click: 0 });
    }
  }

  onUpdateClick = (e) => {
    e.preventDefault();
    let data = {
      "complaint_id": this.state.complaintId,
      "problem_description": this.state.problemDesc,
      "purchase_date": this.state.purchaseDate,
      "address1": this.state.address1,
      "address2": this.state.address2,
      "pincode": this.state.pinCode,
      "state": this.state.state_id,
      "city": this.state.city_id,
      // "comment": this.state.comment,
      "customer_id": this.state.customer_id,
      "email": this.state.email,
      "mobile": this.state.mobile,
    };
    if (this.handleValidation() !== false) {
      this.callEditApi(data);
    }
  };

  async componentDidMount() {
    if (this.props.complaintProductList.length > 0) {
      this.setState({
        activeProduct: this.props.complaintProductList[0].product_color_id,
        complaintProductList: this.props.complaintProductList
      });
    }
  }

  handleChangeProduct = (e) => {

    this.setState({
      activeProduct: e.target.value
    });
    let tempProductList = [];
    for (let i = 0; i < this.props.distributorComplainDetails.product.length; i++) {
      let temp = this.props.distributorComplainDetails.product[i];
      if (temp.product_color_id == e.target.value) {
        if (tempProductList.length > 0) {
          let status = false;
          for (let j = 0; j < tempProductList.length; j++) {
            if (tempProductList[j].product_problem_id === temp.product_problem_id ||
              tempProductList[j].purchase_date === temp.purchase_date ||
              tempProductList[j].problem_description === temp.problem_description) {
              tempProductList[j].quantity += 1;
              status = true;
              break;
            }
          }
          if (!status) {
            tempProductList.push({ ...temp, quantity: 1 });
          }
        } else {
          tempProductList.push({ ...temp, quantity: 1 });
        }
      }
    }
    this.setState({
      complaintProductList: tempProductList
    });
  };

  handleLoader() {
    this.setState({ showLoader: !this.state.showLoader });
  }

  render() {
    return (
      <div className="main-container">
        {this.state.showLoader ?
          <div className='loader'>
            <Loader
              type="Oval"
              color="Grey"
              height={200}
              width={200}
            />
          </div>
          :
          <div>
            <div className="left-container">
              {this.props.distributorData.length > 0 ?
                this.props.distributorData.map((item, index) => {
                  return (
                    <div onClick={(e) => this.onItemPress(e, item)} key={index} >
                      {/* {item.id == this.props.location.state.item.id ? */}
                      {this.props.distributorComplainDetails ?
                        <div className={this.props.distributorComplainDetails.id === item.id ? "container7 activeColor" : "container7"} >
                          <div className="box">
                            <h6>{item.ticket_id}
                              <button className="active ">Active</button>
                            </h6>
                            <span style={{ cursor: 'pointer', class: 'name' }}>Total Products : </span>
                            <span style={{ cursor: 'pointer', color: "#d2691e" }}>{item.productCount}</span>
                            {item.upgradeProduct &&
                              <span className='upg'>
                                UPG - {item.upgradeProduct.product_name} + {item.upgradeProduct.color_name}
                              </span>
                            }
                            <p style={{ cursor: 'pointer' }}>{moment(item.created_at).format('MMMM DD, YYYY || hh:mm:ss A')}</p>
                          </div>
                        </div>
                        :
                        <div></div>
                        // <div className={this.props.complaintsDetail == item.ticket_id ? "box" : null}>
                        //   <h6>{item.ticket_id}
                        //     {item.is_warranty == 1 ?
                        //       <button className="active">Active</button>
                        //       :
                        //       <button className="active">Expire</button>
                        //     }
                        //   </h6>
                        //   <span style={{ cursor: 'pointer' }}>{item.product_name}({item.color_name})</span>
                        //   <p style={{ cursor: 'pointer' }}>{moment(item.created_at).format('MMMM DD, YYYY || hh:mm:ss A')} || {item.channel_name}</p>
                        // </div>
                      }
                    </div>
                  );
                })
                :
                <div className="no-result-text">
                  <p>No result found</p>
                </div>
              }
            </div>
            {this.props.distributorData.length > 0 ?
              <div>
                {this.props.distributorComplainDetails ?
                  <div className="right-container">
                    <div className="box">
                      <h6 style={{ "marginLeft": "10px" }}>{this.props.distributorComplainDetails.ticket_id}
                        <button className="active">Active</button>
                      </h6>
                      <div className="ppt setwidth">
                        <p>{
                          this.props.distributorComplainDetails.retailer ? this.props.distributorComplainDetails.retailer.first_name : null} {this.props.distributorComplainDetails.retailer ? this.props.distributorComplainDetails.retailer.last_name : null} || {moment(this.props.distributorComplainDetails.created_at).format('MMMM DD, YYYY')} || {this.props.distributorComplainDetails.status_name}</p>
                      </div> <div className="ppti abc">  <i className="fas fa-times" onClick={(e) => this.onCloseDetail(e)}></i></div><br />
                      <div className="name" style={{ "marginLeft": "10px" }}>
                        <span>{this.props.distributorComplainDetails.retailer ? this.props.distributorComplainDetails.retailer.first_name : null} {this.props.distributorComplainDetails.retailer ? this.props.distributorComplainDetails.retailer.last_name : null}</span>
                        <div className="col">
                          {this.props.distributorComplainDetails.complaint_status_id === 13 ?
                            null
                            :
                            this.props.distributorComplainDetails.complaint_status_id === 59 ?
                              null
                              :
                              <button onClick={this.actions} className="bbts" id="dropdownMenuButton" data-toggle="dropdown">
                                <i className="fas fa-ellipsis-v"></i>Actions
                              </button>

                          }

                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {this.state.ticketReceived &&
                              <TicketReceived handleRefreshList={this.props.handleRefreshList} handleLoader={this.handleLoader.bind(this)} />
                            }
                          </div>
                        </div>
                        <p>{this.props.distributorComplainDetails.retailer ? this.props.distributorComplainDetails.retailer.mobile : null} || {this.props.distributorComplainDetails.retailer ? this.props.distributorComplainDetails.retailer.email : null}  </p>
                      </div>
                      {/* <div className="buton">   <button className="bbts"><i className="fas fa-ellipsis-v"></i>Actions</button></div> */}
                      <div className="mitem">
                        <ul id="menu2" style={{ "marginLeft": "-40px", "marginTop": '80px' }}>
                          <li value="1" className={this.state.subMenu === 1 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>Product Details </li>
                          <li value="2" className={this.state.subMenu === 2 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>Retailer Details</li>
                          <li value="3" className={this.state.subMenu === 3 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>Conversations</li>
                          <li value="4" className={this.state.subMenu === 4 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>Comments</li>
                          <li value="5" className={this.state.subMenu === 5 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>History</li>
                        </ul>
                      </div>
                    </div>
                    {
                      this.state.subMenu === 1 ?
                        <div className='product_class'>
                          <div className='product_details2'>
                            {this.props.distributorComplainDetails.product &&
                              <select onChange={this.handleChangeProduct} className="dropdown" value={this.state.activeProduct}>
                                {this.props.individualProductList.map((item, index) => (
                                  <option key={item.product_color_id} value={item.product_color_id}>{item.product_name + " (" + item.color_name + ")"}</option>
                                ))
                                }
                              </select>
                            }
                          </div>
                          <div className="product_details" style={{ heigth: "330px" }}>
                            {
                              this.props.distributorComplainDetails.product ? this.state.complaintProductList.map((item, index) => {
                                {
                                  return (
                                    <ul key={item.productComplaintId}>
                                      <div className="wrapper">
                                        <div className="first">
                                          <div className="firstbox">
                                            <span style={{ "fontSize": "40px" }}>Product</span>
                                            <p className="product_name" style={{ color: "white" }}>{item.product_name} ({item.color_name})</p>
                                          </div>
                                          <div className="secondbox">
                                            <span>Problem</span>
                                            <p className="product_name" style={{ color: "white" }} >{item.problem}</p>
                                          </div>
                                          {item.problem != "Issue not listed"
                                            &&
                                            <div className="thirdbox">
                                              <span>Sub Problem</span>
                                              <p className="product_name" style={{ color: "white" }}>{item.sub_problem_name}</p>
                                            </div>}
                                          {item.problem == "Issue not listed" &&
                                            <div className="prblmdescription" style={{ width: "100%" }}>
                                              <span>Problem Description</span>
                                              <p style={{ color: "white", wordBreak: 'break-word' }} >{item.problem_description}</p>
                                            </div>
                                          }
                                        </div>
                                        <div className='second'>
                                          <div className="firstbox">
                                            <span style={{ "fontSize": "40px" }}>Product SKU</span>
                                            <p className="product_name" style={{ color: "white" }}>{item.sku_code}</p>
                                          </div>
                                          <div className="secondbox">
                                            <span>Purchase Date</span>
                                            <p className="product_name" style={{ color: "white" }} >{moment(item.purchase_date).format('MMMM DD, YYYY')}</p>
                                          </div>

                                        </div>
                                        <div className='third'>
                                          <div className="firstbox">
                                            <span style={{ "fontSize": "40px" }}>Product Cost</span>
                                            <p className="product_name" style={{ color: "white" }} >{item.product_price}</p>
                                          </div>

                                          <div className="thirdbox">
                                            <span>Quantity</span>
                                            <p className="product_name" style={{ color: "white" }}>{item.quantity}</p>
                                          </div>


                                        </div>

                                      </div>
                                    </ul>
                                  );
                                }
                              }) : ""
                            }
                          </div>
                          <div className="product_details1" style={{ height: "170px" }}>
                            <div className='first'>
                              <div className='firstbox'>
                                <span >Waybill Number</span>
                                <p className="product_name" style={{ color: "white" }}>
                                  {this.props.distributorComplainDetails.courier ? (this.props.distributorComplainDetails.courier.reverse_waybill_number ? this.props.distributorComplainDetails.courier.reverse_waybill_number : 'N/A') : 'N/A'}</p>
                              </div>
                            </div>
                            <div className='first1'>
                              <div className='firstbox'>
                                <span >Reverse Pickup Cost</span>
                                <p className="product_name" style={{ color: "white", "font": " normal normal normal 12px Roboto", "letter-spacing": "0px", "opacity": 1 }}>
                                  {this.props.distributorComplainDetails.courier ? (this.props.distributorComplainDetails.courier.reverse_courier_cost ? this.props.distributorComplainDetails.courier.reverse_courier_cost : "N/A") : 'N/A'}</p>
                              </div>
                            </div>
                            <div className='first'>
                              <div className='firstbox'>
                                <span >Forward Waybill Number</span>
                                <p className="product_name" style={{ color: "white", }}>
                                  {this.props.distributorComplainDetails.courier ? (this.props.distributorComplainDetails.courier.forward_waybill_number ? this.props.distributorComplainDetails.courier.forward_waybill_number : 'N/A') : 'N/A'}</p>
                              </div>
                            </div>

                            <div className='first'>
                              <div className='firstbox'>
                                <span >Forward Pickup Cost</span>
                                <p className="product_name" style={{ color: "white" }}>
                                  {this.props.distributorComplainDetails.courier ? (this.props.distributorComplainDetails.courier.forward_courier_cost ? this.props.distributorComplainDetails.courier.forward_courier_cost : 'N/A') : 'N/A'}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        : this.state.subMenu === 2 ?
                          <CutomerDetails
                            item={this.props.distributorComplainDetails}
                          />
                          : this.state.subMenu === 5 ?
                            <History
                            /> :
                            this.state.subMenu === 3 ?
                              <Conversations
                              />
                              :
                              this.state.subMenu === 4 ?
                                <Comments
                                />
                                : null
                    }
                  </div>
                  : <h3 style={{ color: "#fff" }}>Complaint Details not found</h3>
                }
              </div>
              : null
            }
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  distributorData: state.distributor.distributorData,
  distributorComplainDetails: state.distributor.distributorComplainDetails,
  individualProductList: state.distributor.individualProductList,
  showComplaintDetails: state.complaints.showComplaintDetails,
  commentsData: state.distributor.commentsData,
  conversationData: state.distributor.conversationData,
  complaintProductList: state.distributor.complaintProductList
});

export default connect(mapStateToProps, {
  getDistributorComplaintDetail, showComplaintDetailsAction, getDistributorData
})(ComplaintDetailsNew);
