import {
  FETCH_COMPLAINTS_DATA, ADD_COMPLAINT_SELECTION, REMOVE_COMPLAINT_SELECTION, CLEAR_ALL_COMPLAINT_DATA,
  GET_PRODUCT_LIST, GET_PRODUCTS, GET_PRODUCT_PROBLEM_LIST, GET_COMPLAINT_BUCKET, GET_COMPLAINT_STATUS,
  EDIT_COMPLAINTS_DATA, GET_COMPLAINTS_DETAIL, CALL_CUSTOMER, GET_RPU_COURIER, ADD_STATUS_ID,
  SHOW_COMPLAINT_DETAILS, GET_CITY_LIST, REFRESH_COMPLAINT_LIST, GET_STATE_LIST, FETCH_COMPLAINTS_COUNT,
  ADD_COMMENT, FETCH_COMMENTS, FETCH_CONVERSATIONS, FETCH_PAGINATION_INFO, COURIER_ID, TRACK_STATUS_DATA,
  SCREENING_RESPONSE, PRINT_LABEL_DATA, TRANSFER_TO_DD, RESEND_MAIL, FETCH_CANNED_REPONSES,
  RESCHEDULE_IVR, FETCH_REFUND_EDIT, FETCH_GENERATE_LINK, GET_CHANNEL_LIST, FETCH__GC_AAPPROVED_LINK,
  FETCH_REUND_CASE, FETCH_ADMIN_LIST, FETCH_GC_CASE, FETCH_GCAPPROVAL_CASE, FETCH_REFUNDAPPROVAL_CASE, FETCH_GC_APPROVAL_CASE, GET_SUB_PROBLEM_LIST, FETCH_CONVERSATIONS_DATA,
  FETCH_DOWNLOAD_REPORT, FETCH_REFUND_RESEND, COSMOS_RES, FETCH_CONVERSATIONS_LOAD, FETCH_COMMENT_DATA,
  FETCH_COMMENT_LOAD, SET_BUCKET_ID_LIST, SET_ACTION_TRUE, SAVE_MAC_ID, GET_NON_NFA
} from '../actions/types';

const initialState = {
  complaintsData: [],
  complaintSelection: "",
  downloadData: [],
  bucketData: [],
  statusData: [],
  productData: [],
  problemData: [],
  statusId: 1,
  editData: undefined,
  newComment: "",
  callRes: undefined,
  complaintsDetail: undefined,
  courierData: [],
  showComplaintDetails: false,
  cityData: [],
  stateData: [],
  complaintsDataCount: [],
  refreshComplaintList: false,
  commentsData: [],
  conversationData: [],
  paginationCount: [],
  courierId: 0,
  trackStatusData: {},
  screenRes: null,
  printLabel: null,
  trackDDStatus: [],
  resendMail: null,
  commentsFail: {},
  fetchCannedResponse: {},
  rescheduleIvr: '',
  editRefund: [],
  linkGenerte: '',
  channelDatalist: [],
  getGCApproved: '',
  resendRefund: '',
  geRefundCase: '',
  adminUserlist: [],
  gcCase: '',
  gcApproval: '',
  refundApproval: '',
  downlaodReport: '',
  gcApprovalBy: '',
  cosmosRes: {},
  sideProp: '',
  conversationDetails: [],
  conversationpage: [],
  commentDetails: [],
  commentpage: [],
  BucketIDlist: "1",
  showAction: true,
  macSaved: {},
  nonNfa: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPLAINTS_DATA:
      return {
        ...state,
        complaintsData: action.payload,
      };
    case GET_COMPLAINT_BUCKET:
      return {
        ...state,
        bucketData: action.payload,
      };
    case GET_COMPLAINT_STATUS:
      return {
        ...state,
        statusData: action.payload,
      };
    case GET_PRODUCT_LIST:
      return {
        ...state,
        productData: action.payload,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        productData: action.payload,
      };
    case GET_PRODUCT_PROBLEM_LIST:
      return {
        ...state,
        problemData: action.payload,
      };
    case GET_SUB_PROBLEM_LIST:
      return {
        ...state,
        subproblemData: action.payload,
      };
    case GET_RPU_COURIER:
      return {
        ...state,
        courierData: action.payload,
      };
    case EDIT_COMPLAINTS_DATA:
      return {
        ...state,
        editData: action.payload,
      };
    case ADD_COMMENT:
      return {
        ...state,
        newComment: action.payload,
      };
    case GET_COMPLAINTS_DETAIL:
      return {
        ...state,
        complaintsDetail: action.payload,
      };
    case CALL_CUSTOMER:
      return {
        ...state,
        callRes: action.payload,
      };
    case ADD_COMPLAINT_SELECTION:
      return {
        ...state,
        complaintSelection: action.payload
      };
    case REMOVE_COMPLAINT_SELECTION:
      return {
        ...state,
        complaintSelection: ''
      };
    case SHOW_COMPLAINT_DETAILS:
      return {
        ...state,
        showComplaintDetails: action.payload
      };
    case REFRESH_COMPLAINT_LIST:
      return {
        ...state,
        refreshComplaintList: action.payload
      };
    case ADD_STATUS_ID:
      return {
        ...state,
        statusId: action.payload
      };
    case CLEAR_ALL_COMPLAINT_DATA:
      return {
        ...state,
        complaintsData: [],
        downloadData: []
      };
    case GET_STATE_LIST:
      return {
        ...state,
        stateData: action.payload
      };
    case FETCH_COMMENTS:
      return {
        ...state,
        commentsData: action.payload
      };
    case FETCH_CONVERSATIONS:
      return {
        ...state,
        conversationData: action.payload
      };
    case GET_CITY_LIST:
      return {
        ...state,
        cityData: action.payload
      };
    case FETCH_COMPLAINTS_COUNT:
      return {
        ...state,
        complaintsDataCount: action.payload,
      };
    case FETCH_PAGINATION_INFO:
      return {
        ...state,
        paginationCount: action.payload,
      };
    case COURIER_ID:
      return {
        ...state,
        courierId: action.payload,
      };
    case TRACK_STATUS_DATA:
      return {
        ...state,
        trackStatusData: action.payload,
      };
    case TRANSFER_TO_DD:
      return {
        ...state,
        trackDDStatus: action.payload,
      };
    case SCREENING_RESPONSE:
      return {
        ...state,
        screenRes: action.payload,
      };
    case PRINT_LABEL_DATA:
      return {
        ...state,
        printLabel: action.payload,
      };
    case RESEND_MAIL:
      return {
        ...state,
        resendMail: action.payload
      };
    case FETCH_CANNED_REPONSES:
      return {
        ...state,
        fetchCannedResponse: action.payload
      };
    case RESCHEDULE_IVR:
      return {
        ...state,
        rescheduleIvr: action.payload
      };
    case FETCH_REFUND_EDIT:
      return {
        ...state,
        editRefund: action.payload
      };
    case FETCH_GENERATE_LINK:
      return {
        ...state,
        linkGenerte: action.payload
      };
    case GET_CHANNEL_LIST:
      return {
        ...state,
        channelDatalist: action.payload,
      };
    case FETCH__GC_AAPPROVED_LINK:
      return {
        ...state,
        getGCApproved: action.payload
      };
    case FETCH_REFUND_RESEND:
      return {
        ...state,
        resendRefund: action.payload
      };
    case FETCH_REUND_CASE:
      return {
        ...state,
        geRefundCase: action.payload
      };
    case FETCH_ADMIN_LIST:
      return {
        ...state,
        adminUserlist: action.payload
      };
    case FETCH_GC_CASE:
      return {
        ...state,
        gcCase: action.payload
      };
    case FETCH_GCAPPROVAL_CASE:
      return {
        ...state,
        gcApproval: action.payload
      };
    case FETCH_REFUNDAPPROVAL_CASE:
      return {
        ...state,
        refundApproval: action.payload
      };
    case FETCH_GC_APPROVAL_CASE:
      return {
        ...state,
        gcApprovalBy: action.payload
      };

    case FETCH_DOWNLOAD_REPORT:
      return {
        ...state,
        downlaodReport: action.payload
      };
    case COSMOS_RES:
      return {
        ...state,
        cosmosRes: action.payload
      };
    case FETCH_CONVERSATIONS_DATA:
      return {
        ...state,
        conversationDetails: action.payload
      };
    case FETCH_CONVERSATIONS_LOAD:
      return {
        ...state,
        conversationpage: action.payload
      };
    case FETCH_COMMENT_DATA:
      return {
        ...state,
        commentDetails: action.payload
      };
    case FETCH_COMMENT_LOAD:
      return {
        ...state,
        commentpage: action.payload
      };
    case SET_BUCKET_ID_LIST:
      return {
        ...state,
        BucketIDlist: action.payload
      };
    case SET_ACTION_TRUE:
      return {
        ...state,
        showAction: action.payload
      };
    case SAVE_MAC_ID:
      return {
        ...state,
        macSaved: action.payload
      };
      case GET_NON_NFA:
        return {
          ...state,
          nonNfa: action.payload
        };
    default:
      return state;
  }
}