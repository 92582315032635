import React, { Component } from 'react';
import './UserComponent.css';
import { connect } from "react-redux";
import history from '../../history';
// import moment from 'moment'
import Loader from 'react-loader-spinner';
import { getUserData, getUserDetail, createUser, editUserData, getAccessLevelData, getReportinglData, removeUserData } from '../../actions/userActions';
import ViewProfile from '../ViewProfile';
import AccessRestricted from '../AccessRestricted';
import ComplaintRegistration from '../ComplaintRegistration';
import Footer from '../FooterComponent/footer';
import SidebarComponent from '../SidebarComponent/SidebarComponent';

class UserComponent extends Component {
  constructor() {
    super();
    this.state = {
      firstName: '',
      logout: false,
      //showMenu: false,
      pageNo: 0,
      pageLimit: 10,
      searchText: '',
      //searchDate: '',
      userList: [],
      userDetailList: [],
      //prevPage: false,
      //nextPage: false,
      // userId: '',
      // firstName: '',
      lastName: '',
      userName: '',
      email: '',
      mobile: '',
      reportingTo: '',
      isReporter: '',
      first_name: '',
      last_name: '',
      team: '',
      fields: {},
      errors: [],
      accessList: [],
      reportList: [],
      reportingList: [],
      accessId: 0,
      reportingId: 0,
      is_reporter: 0,
      reporting_to: 0,
      access_lavel: '',
      // reporting_to:'',
      user_role: '',
      user_reporting: '',
      loader: false,
      limit: 10,
      totalcount: '',
      prevPage: '',
      nextPage: '',
      fname: '',
      lname: '',
      newEmail: '',
      newMobile: '',
      access_level: '',
      req_link: "no",
      userId: ''
    };
    this.showMenu = this.showMenu.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  handleKeyUp = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleLogout = () => {
    localStorage.removeItem('token');
    history.push('/');
  };

  handleIconDetails() {
    this.setState({
      logout: !this.state.logout
    });
  }

  // onClearForm = (e) => {
  //   this.setState({
  //     firstName: '',
  //     lastName: '',
  //     userName: '',
  //     email: '',
  //     mobile: '',
  //     accessLavel: '',
  //     reportingTo: '',
  //     isReporter: '',
  //     first_name: '',
  //     last_name: '',
  //   })
  // }

  componentDidMount() {
    this.getUserList();
    this.getAccessList();
    this.getReportingList();
  }

  onPrevPress = () => {
    if (this.state.prevPage) {
      this.setState({ pageNo: (this.state.pageNo - 1) }, () => {
        this.getUserList();
      });
    }
  };

  onNextPress = () => {
    if (this.state.nextPage) {
      this.setState({ pageNo: (this.state.pageNo + 1) }, () => {
        this.getUserList();
      });
    }
  };

  getUserList = async () => {
    let data = {
      "filterText": this.state.searchText,
      "limit": Number(this.state.pageLimit),
      "offset": Number(this.state.pageNo)
    };
    this.setState({ isLoading: true, userList: [] });
    //await this.props.getUserData(data);
    await this.props.getUserData(data);
    let usersData = this.props.userData;
    this.setState({
      isLoading: false,
      prevPage: usersData.data ? usersData.data.offset : 0,
      nextPage: usersData.data ? usersData.data.next_page : 0,
      totalcount: usersData.data ? usersData.data.count : 0,
      limit: this.state.limit,
      userList: usersData.data ? usersData.data.users : [],
    });
  };

  onEditClick = (e, item) => {
    // this.setState({
    //   userId: item.id,
    //   firstName: item.first_name,
    //   lastName: item.last_name,
    //   userName: item.username,
    //   email: item.email,
    //   mobile: item.mobile,
    //   accessLavel: item.is_reporter,
    //   reportingTo: item.reporter_username,
    //   isReporter:''
    // })
    this.setState({
      req_link: "no"
    });
    let data = {
      "user_id": item.id,
    };
    this.setState({ errors: [] });
    this.getUserDetailsApi(data);
  };


  getUserDetailsApi = async (param) => {
    await this.props.getUserDetail(param);
    let editRes = this.props.userDetail;
    let firstName = editRes.data.first_name;
    document.getElementById("validationCustom01").value = firstName;
    let lastName = editRes.data.last_name;
    document.getElementById("last_name_id").value = lastName;
    let email = editRes.data.email;
    document.getElementById("editEmail").value = email;
    let mobile = editRes.data.mobile;
    document.getElementById("contact-number").value = mobile;
    let accessLevel = editRes.data.user_role[0].id;
    document.getElementById("access_edit").value = accessLevel;
    let userId = editRes.data.id;
    document.getElementById("viewModal").style.display = "block";
    //document.getElementById("viewModal").classList.add("show");
    console.log(editRes.data)
    let user_role = editRes.data.user_role ? editRes.data.user_role[0]?.id : '';
    let user_reporting = editRes.data.reporting ? editRes.data.reporting[0]?.id : '';
    let reportingList = this.state.reportingList;
    // let reportingID = reportingList.map((obj) => obj.id === user_reporting ? obj.id : 0);
    let reportingID = reportingList.filter(obj => {
      return obj.id === user_reporting;
    });
    document.getElementById("reportingToEdit").value = reportingID[0] ? reportingID[0].role_id : 0;
    let team = editRes.data.team;
    document.getElementById("teamEdit").value = team;
    
    console.log(lastName,firstName,firstName)
    this.setState({
      userDetailList: editRes.data,
      accessId: user_role,
      reportingId: reportingID[0] ? reportingID[0].role_id : 0,
      lname: lastName,
      fname: firstName,
      mobile: mobile,
      email: email,
      userId: userId,
      team: team,
    });
  };





























  
  showMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true });
  }

  handleDate = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.getUserList();
    });
  };

  //this.setState({ [e.target.name]: e.target.value })



  handleChangeEvent = (e) => {
    if (e.target.name === 'searchByusername') {
      this.setState({ searchText: e.target.value });
      if (e.target.value === '') {
        this.getUserList();
      }
    } else {
      // this.setState({ [e.target.name]: e.target.value }, () => {
      // })
    }
  };

  handleChangeButton = (e) => {
    e.preventDefault();
    this.getUserList();
  };

  handleValidation() {
    let errors = {};
    let formIsValid = true;
    var patternName = new RegExp(/^[ a-zA-Z ]+$/);
    if (this.state.first_name === '') {
      formIsValid = false;
      errors["first_name"] = "Cannot be empty";
    }
    if (this.state.first_name !== '') {
      if (!patternName.test(this.state.first_name)) {
        formIsValid = false;
        errors["first_name"] = "Allow only characters";
      }
    }
    if (this.state.last_name === '') {
      formIsValid = false;
      errors["last_name"] = "Cannot be empty";
    }
    if (this.state.last_name !== '') {
      if (!patternName.test(this.state.last_name)) {
        formIsValid = false;
        errors["last_name"] = "Allow only characters";
      }
    }
    if (this.state.newEmail === '') {
      formIsValid = false;
      errors["email"] = "Email cannot be empty";
    }
    if (this.state.newEmail !== '') {
      var pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (!pattern.test(this.state.newEmail)) {
        formIsValid = false;
        errors["email"] = "Email format is not correct";
      }
    }
    if (this.state.newMobile === '') {
      formIsValid = false;
      errors["mobile"] = "Enter your phone number.";
    }
    if (this.state.newMobile !== '') {
      var pattern = new RegExp(/^[6-9]\d{9}$/);
      if (!pattern.test(document.getElementById("userPhone").value) || (document.getElementById("userPhone").value.length !== 10)) {
        formIsValid = false;
        errors["mobile"] = "Enter valid phone number.";
      }
    }
    if (this.state.access_level === "" || this.state.access_level === 0) {
      formIsValid = false;
      errors["access_lavel"] = "Cannot be empty";
    }
    // else {
    //   var pattern = new RegExp(/^[0-9\b]+$/);
    //   if (!pattern.test(this.state.accessId)) {
    //     formIsValid = false;
    //     errors["access_lavel"] = "Enter only number.";
    //   }
    // }
    if (this.state.reportingTo === "" || this.state.reportingTo === 0) {
      formIsValid = false;
      errors["reporting_to"] = "Cannot be empty";
    }
    // else {
    //   var pattern = new RegExp(/^[0-9\b]+$/);
    //   if (!pattern.test(this.state.reportingId)) {
    //     formIsValid = false;
    //     errors["reporting_to"] = "Enter only number.";
    //   }
    // }
    // if (typeof this.state.reportingId !== "0") {  
    //   var pattern = new RegExp(/^[0-9\b]+$/);
    //   if (!pattern.test(this.state.reportingId)) {
    //     formIsValid = false;
    //     errors["reporting_to"] = "Enter only number.";
    //   }
    // }
    let userRoleId = localStorage.getItem('userRoleId');
    if (userRoleId !== '1') {
      if ((this.state.access_level === '1') || (this.state.access_level === '2')) {
        errors["access_lavel"] = "Only super-admin can make others admin or super-admin";
        formIsValid = false;
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleResendVerificationLink = (e) => {
    //   document.querySelector(".error-report").style.display = "none";
    this.setState({ req_link: e.target.value }, () => {
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    let first_name = this.state.first_name;
    let last_name = this.state.last_name;
    let email = this.state.newEmail;
    let mobile = this.state.newMobile;
    let reporting_to = this.state.reportingTo;
    let access_level = this.state.access_level;
    let team = this.state.team;
    let data = {
      'first_name': first_name,
      'last_name': last_name,
      'email': email,
      'username': first_name + last_name,
      'mobile': mobile,
      'reporting_to': reporting_to,
      "is_reporter": '0',
      "role_id": access_level,
      "team": team,
    };
    if (this.handleValidation() !== false) {
      this.callAddApi(data);
    }
  }

  onAccessChange = (e) => {
    this.setState({ accessId: e.target.value }, () => {
    });
    //  document.querySelector(".error-access").style.display = "none";
  };

  onTeamChange = (e) => {
    this.setState({ team: e.target.value }, () => {
    });
    //  document.querySelector(".error-access").style.display = "none";
  };

  onReportingChange = (e) => {
    //   document.querySelector(".error-report").style.display = "none";
    this.setState({ reportingId: e.target.value }, () => {
    });
  };

  callAddApi = async (param) => {
    await this.props.createUser(param);
    let addresponce = this.props.lotResponse;
    this.setState({ loader: true });
    if (addresponce.success) {
      this.setState({ errors: [], first_name: "", last_name: "", newEmail: "", newMobile: "", reportingTo: '', access_level: '', team: 'CS' });
      document.getElementById("exampleModal").style.display = "none";
      this.getUserList();
      //  document.getElementById("add_form").reset();
    }
    this.setState({ loader: false });
  };

  handleEditValidation(e) {
    let errors = {};
    let flag = true;
    let fname = this.state.fname;
    let lname = this.state.lname;
    let phone = this.state.mobile;
    let email = this.state.email;
    let reporting_to = this.state.reportingId;
    let access_to = this.state.accessId;
    let userRoleId = localStorage.getItem('userRoleId');
    var patternName = new RegExp(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/);
    if (fname !== '') {
      if (!patternName.test(fname)) {
        flag = false;
        errors["fname"] = "Incorrect format for name";
      }
    }
    if (lname !== '') {
      if (!patternName.test(lname)) {
        flag = false;
        errors["lname"] = "Incorrect format for name";
      }
    }
    if (reporting_to === 0) {
      flag = false;
      errors["reporting_to"] = "This field can't be empty";
    }
    if (access_to === 0 || access_to === '0') {
      flag = false;
      errors["access_to"] = "This field can't be empty";
    }
    // if(access_to != userRoleId){
    //   flag = false;
    //   this.handleLogout();
    // }
    if (fname === '') {
      errors["fname"] = "Name can't be empty";
      flag = false;
    }
    if (lname === '') {
      errors["lname"] = "Last name can't be empty";
      flag = false;
    }
    if (phone === '' || phone.length < 10) {
      errors["mobile"] = "Phone number is mandatory!";
      flag = false;
    }
    if (email === '') {
      errors["email"] = "Email ID is mandatory!";
      flag = false;
    }
    if (email !== '') {
      var pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (!pattern.test(email)) {
        flag = false;
        errors["email"] = "Email format is not correct";
      }
    }
    if (userRoleId !== '1') {
      if ((access_to === '1') || (access_to === '2')) {
        errors["access_to"] = "Only super-admin can make others admin or super-admin";
        flag = false;
      }
      //       let userId = localStorage.getItem('userId');
      //       if(this.state.userDetailList.id == userId){
      // this.handleLogout();
      //       }
    }
    this.setState({ errors });
    return flag;
  }

  onUpdateClick = (e) => {
    e.preventDefault();
    let fname = this.state.fname;
    let lname = this.state.lname;
    let phone = this.state.mobile;
    let reporting_to = this.state.reportingId;
    let access_to = this.state.accessId;
    let data = {
      "user_id": this.state.userDetailList.id,
      "first_name": fname,
      "last_name": lname,
      "email": this.state.email,
      "mobile": phone,
      "username": this.state.userDetailList.username,
      "reporting_to": this.state.reporting_to,
      "is_reporter": '0',
      "role_id": access_to,
      "req_link": this.state.req_link === "yes" ? 1 : 0,
      "team": this.state.team,
    };
    if (this.handleEditValidation() !== false) {
      this.setState({ isLoading: true });
      this.callEditApi(data);
    }
  };

  onDeleteClick = (e, item) => {
    e.preventDefault();
    let data = {
      "user_id": item.id,
    };
    this.callDeleteApi(data);
  };

  callDeleteApi = async (param) => {
    await this.props.removeUserData(param);
    let editRes = this.props.deleteData;
    this.setState({ loader: true });
    if (editRes) {
      this.setState({ loader: false });
      alert(editRes.message);
      this.getUserList();
    }

  };

  callEditApi = async (param) => {
    await this.props.editUserData(param);
    let editRes = this.props.editData;
    // alert(editRes.message);
    if (editRes.success) {
      alert(editRes.message);
      document.getElementById("viewModal").style.display = "none";
      document.getElementById("add_form").reset();
      document.getElementById("viewModal").classList.remove("show");
      this.getUserList();
    }
    this.setState({ isLoading: false });
    let userInitialId = localStorage.getItem('userId');
    if (userInitialId === this.state.userId) {
      let userRoleId = localStorage.getItem('userRoleId');
      if (this.state.accessId !== userRoleId) {
        alert("User access level changed. Please login again!");
        this.handleLogout();
      }
    }
  };

  getAccessList = async () => {
    this.setState({ isLoading: true, accessList: [] });
    await this.props.getAccessLevelData();
    let accessData = this.props.accessData;
    this.setState({
      accessList: accessData.data || [],
    });
  };

  getReportingList = async () => {
    let data = {
      "isReporter": true
    };
    this.setState({ isLoading: true, reportingList: [] });
    await this.props.getReportinglData(data);
    let reportList = this.props.reportData;





















    this.setState({
      reportingList: reportList.data ? reportList.data.users : [],
    });
  };








  closeEdit = () => {
    document.getElementById("viewModal").style.display = "none";
    document.getElementById("viewModal").classList.remove("show");
  };

  show = () => {
    document.getElementById("exampleModal").style.display = "block";
  };

  hide = () => {
    document.getElementById("exampleModal").style.display = "none";
    this.setState({ errors: [], first_name: "", last_name: "", newEmail: "", newMobile: "", reportingTo: '', access_level: '', team: 'CS' });
  };

  render() {

    let userRoleId = localStorage.getItem('userRoleId');
    if (userRoleId === '4' || userRoleId === '8' || userRoleId === '9' || userRoleId === '10') {
      return (<AccessRestricted />);
    }
    else if (userRoleId === '6') {
      return (<ComplaintRegistration />);
    }
    else {
      return (
        <div>
          <div>
            { window.innerWidth > 600 && <SidebarComponent sideProp={'MU'} />}
            <div className="modal fade" id="exm" tabIndex="-1" role="dialog" aria-labelledby="modallongtitle" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="mofd">
                  <div className="Et">
                    <span>Notifications(40)</span>
                    <ul id="lim">
                      <li><a href="#">All</a></li>
                      <li><a href="#">Unread</a></li>
                    </ul>
                  </div>
                  <div className="cont">
                    <h6>Today</h6>
                    <div className="b1">
                      <p>New User added by Peter Parker</p>
                      <p className="p">User:Daniel Hook</p>
                      <h1>6:05pm</h1>
                    </div>
                    <div className="b2">
                      <p>New User added by Peter Parker</p>
                      <p className="p">User:Daniel Hook</p>
                      <h1>6:05pm</h1>
                    </div>
                    <div className="cont1">
                      <h6>October 24,2021</h6>
                      <div className="b3">
                        <p>New User added by Peter Parker</p>
                        <p className="p">User:Daniel Hook</p>
                        <h1>6:05pm</h1>
                      </div>
                    </div></div>
                </div>
              </div>
            </div>
            {/* <div className="modal fade" id="exampleModals">
            <div className="modal-dialog" role="document">
              <div className="mo">
                <div className="header">
                <ViewProfile />
                </div>
                <div className="log" onClick={this.handleLogout}>
                  <i className="fas fa-power-off"></i><p>Log Out</p>
                </div>

              </div>

            </div>
          </div>
          <div className="nav dashboard">
            <span>
              <i className="fas fa-user-circle" data-toggle="modal" data-target="#exampleModals"></i> </span>
          </div> */}
            <ViewProfile />
          </div>
          <div className="content manage-user-main">
            <span className="complaint-head">Manage Users</span>
            <ul id="menu">
              <li><a href="/assign-ticket" className="noactive" onClick={this.handleRoute}>Assign Ticket</a></li>
              <li className="actice2"><a href="/manage-user">Users</a></li>
            </ul>
            <div className='search' style={{ float: 'left' }}>

              <input type="username" style={{ width: '230px' }} name="searchByusername" placeholder=" Search by user, user name or contact info" onChange={this.handleChangeEvent} />
              <button type="button" className="add-user-btn-input" onClick={this.handleChangeButton}><i className="fa fa-search user_search_icon"></i></button>
            </div>

            {/* <div className="list-btn-wrap">
                
                <input id="user_search" type="username" name="searchByusername" placeholder=" Search by user, user name or contact info" onChange={this.handleChangeEvent} />
                <button type="button" className="add-user-btn-input" onClick={this.handleChangeButton}><i className="fa fa-search user_search_icon"></i></button>
              </div> */}
            <div className="list">

              {(userRoleId == 1 || userRoleId == 2) &&
                <button type="button" onClick={this.show} className="add-user-btn">
                  Add User
                </button>
              }
              <div className="modal fade" id="exampleModal" >
                <div className="modal-dialog" role="document">
                  <div className="modc">
                    <form autoComplete="off" id="add_form">
                      <div className="modal-header">
                        <div className="list2">
                          <span className="complaint-head">Add User</span>
                        </div>
                        <div data-dismiss="modal" aria-label="Close" id="close_add" onClick={this.hide}>
                          <span aria-hidden="true"><i className="fas fa-times"></i></span>
                        </div></div>
                      <div className="form-row mg-left add_user">
                        <div className="col-md-6 mb-4">
                          <label className="lableClass" htmlFor="validationCustom01">First name</label>
                          <input id="addName" type="text" className="form-control" name="first_name" onChange={this.handleKeyUp} value={this.state.first_name} placeholder="First Name" required autoComplete="off" />
                          <div className="errorpd">
                            <span className="errorShow">{this.state.errors["first_name"]}</span>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label className="lableClass" htmlFor="validationCustom02">Last name</label>
                          <input id="addLName" type="text" className="form-control" name="last_name" value={this.state.last_name} onChange={this.handleKeyUp} placeholder="Last Name" required />
                          <div className="errorpd">
                            <span className="errorShow">{this.state.errors["last_name"]}</span>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label className="lableClass" htmlFor="validationCustom02">Email</label>
                          <input type="text" id="user_email" className="form-control" name="newEmail" value={this.state.newEmail} onChange={this.handleKeyUp} placeholder="Enter user email address" required />
                          <div className="errorpd">
                            <span className="errorShow">{this.state.errors["email"]}</span>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label className="lableClass" name="access_lavel" htmlFor="validationCustom02">Access Level</label>
                          <select className="dropbtn1111" name="access_level" value={this.state.access_level} onChange={this.handleKeyUp}>
                            {/* <i className="fas fa-chevron-down"></i> */}
                            <option value={0}> Select access Level</option>
                            {this.state.accessList.map((obj) => (
                              <option key={obj.id} value={obj.id}>{obj.name}</option>
                            ))}
                          </select>
                          {/* <input type="text" className="form-control" id="validationCustom02" placeholder="Last name" value="Otto" required/> */}
                          <div className="errorpd accessLevel">
                            <span className="errorShow">{this.state.errors["access_lavel"]}</span>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label className="lableClass" htmlFor="validationCustom02">Contact Number</label>
                          <input id="userPhone" type="text" className="form-control" name="newMobile" value={this.state.newMobile} maxLength="10" onChange={this.handleKeyUp} placeholder="99XXXXXXX9" required />
                          <div className="errorpd">
                            <span className="errorShow">{this.state.errors["mobile"]}</span>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label className="lableClass" name="reporting_to" htmlFor="validationCustom02">Reporting To</label>
                          <select className="dropbtn1111" name="reportingTo" value={this.state.reportingTo} onChange={this.handleKeyUp}>
                            {/* <i className="fas fa-chevron-down"></i> */}
                            <option value={0}> Reporting to</option>
                            {this.state.reportingList.map((obj) => (
                              <option key={obj.id} value={obj.id}>{obj.first_name} {obj.last_name}</option>
                            ))}
                          </select>
                          <div className="errorpd">
                            <span className="errorShow">{this.state.errors["reporting_to"]}</span>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label className="lableClass" name="teamLabel" htmlFor="validationCustom02">Team</label>
                          <select className="dropbtn1111" name="team" value={this.state.team} onChange={this.handleKeyUp}>
                            {/* <i className="fas fa-chevron-down"></i> */}
                            <option value={'CS'}>CS</option>
                            <option value={'WH'}>WH</option>
                          </select>
                          <div className="errorpd">
                            <span className="errorShow">{this.state.errors["team"]}</span>
                          </div>
                        </div>
                      </div>
                      <div className="addbtn">
                        <button onClick={this.handleSubmit}>Add User</button></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="viewModal" >
              <div className="modal-dialog" role="document">
                <div className="modc">
                  <form action="" >
                    <div className="modal-header edit_user">
                      <div className="list2">
                        <span className="complaint-head">Edit User</span>
                      </div>
                      <div data-dismiss="modal" aria-label="Close" className="close_edit" onClick={this.closeEdit}>
                        <span aria-hidden="true"><i className="fas fa-times"></i></span>
                      </div>
                    </div>
                    <div className="form-row mg-left">
                      <div className="col-md-6 mb-4">
                        <label className="lableClass" htmlFor="validationCustom01">First name</label>
                        <input id="validationCustom01" type="text" className="form-control" name="fname" onChange={this.handleKeyUp} required />
                        <div className="errorpd">
                          <span className="errorShow">{this.state.errors["fname"]}</span>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="lableClass" htmlFor="validationCustom02">Last name</label>
                        <input id="last_name_id" type="text" className="form-control" name="lname" onChange={this.handleKeyUp} required />
                        <div className="errorpd">
                          <span className="errorShow">{this.state.errors["lname"]}</span>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="lableClass" htmlFor="validationCustom02">Email</label>
                        <input id="editEmail" type="text" className="form-control" name="email" onChange={this.handleKeyUp} required />
                        <div className="errorpd">
                          <span className="errorShow">{this.state.errors["email"]}</span>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="lableClass" name="access_lavel" htmlFor="validationCustom02">Access Level</label>
                        <select id="access_edit" className="dropbtn1111" onChange={(e) => this.onAccessChange(e)}>
                          {/* <i className="fas fa-chevron-down"></i> */}
                          <option value={0}> Select access Level </option>
                          {this.state.accessList.map((obj) => (
                            <option key={obj.id} value={obj.id} defaultValue={obj.id === this.state.accessId}>{obj.name}</option>
                          ))}
                        </select>
                        <div className="errorpd accessLevel">
                          <span className="errorShow">{this.state.errors["access_to"]}</span>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="lableClass" htmlFor="validationCustom02">Contact Number</label>
                        <input id="contact-number" type="text" className="form-control" name="mobile" maxLength="10" onChange={this.handleKeyUp} />
                        <div className="errorpd">
                          <span className="errorShow">{this.state.errors["mobile"]}</span>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="lableClass" htmlFor="validationCustom02">Reporting To</label>

                        <select id="reportingToEdit" className="dropbtn1111" onChange={(e) => this.onReportingChange(e)}>
                          {/* <i className="fas fa-chevron-down"></i> */}
                          <option value={0}> Reporting to</option>
                          {this.state.reportingList.map((obj) => (
                            <option key={obj.id} value={obj.role_id} defaultValue={obj.id == this.state.reportingId}>{obj.first_name} {obj.last_name}</option>
                          ))}
                        </select>
                        <div className="errorpd">
                          <span className="errorShow">{this.state.errors["reporting_to"]}</span>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="lableClass" name="access_lavel" htmlFor="validationCustom02">Resend Verification Link</label>
                        <select id="access_edit" className="dropbtn1111" onChange={(e) => this.handleResendVerificationLink(e)}>
                          {/* <i className="fas fa-chevron-down"></i> */}
                          {/* <option value={0}> Resend Verification Link </option> */}
                          <option value={"yes"}> Yes </option>
                          <option value={"no"} defaultValue={this.state.req_link === "no"}> No </option>
                        </select>
                        <div className="error error-access">
                          <span className="errorShow">Please fill out Access Level</span>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="lableClass" name="teamLabel" htmlFor="validationCustom02">Team</label>
                        <select id="teamEdit" className="dropbtn1111" onChange={(e) => this.onTeamChange(e)}>
                          {/* <i className="fas fa-chevron-down"></i> */}
                          <option value={'CS'} defaultValue={this.state.team === "CS"}>CS</option>
                          <option value={'WH'} defaultValue={this.state.team === "WH"}>WH</option>
                        </select>
                        <div className="errorpd">
                          <span className="errorShow">{this.state.errors["team"]}</span>
                        </div>
                      </div>
                    </div>
                    <div className="addbtn">
                      <button onClick={(e) => this.onUpdateClick(e)}>Update User</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="colrow user">
              <div className="row customrow">
                <div className="col-sm" style={{ color: '#fff' }}>User</div>
                <div className="col-sm" style={{ color: '#fff' }}>Reporting To</div>
                <div className="col-sm" style={{ color: '#fff' }}>Contact info</div>
                <div className="col-sm" style={{ color: '#fff' }}>Tickets Assigned</div>
              </div>
              {this.state.userList.length > 0 ?
                this.state.userList.map((item) => {
                  return (
                    <div key={item.id} className="row customrow">
                      <div className="col">
                        <p>{item.first_name} {item.last_name}</p>
                        <p>{item.team} </p>
                      </div>
                      <div className="col">
                        <span> {item.reporter_fname ? item.reporter_fname : "NA"} {item.reporter_lname ? item.reporter_lname : ''} </span>
                      </div>
                      <div className="col">
                        <p>{item.email}</p><p>{item.mobile}</p>
                      </div>
                      <div className="col">
                        <span> {item.complaint.ticketCount ? item.complaint.ticketCount : 'NA'}</span>
                      </div>
                      {(userRoleId === "1" || userRoleId === "2") &&
                        <div className="col">
                          <div className="dropdown user_dropdown">
                            <button className="btn btn-link " type="button" id="dropdownMenuButton" data-toggle="dropdown" ria-expanded="false">
                              <i className="fas fa-ellipsis-h"></i>
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton"  >
                              <a className="dropdown-item" href="#" data-toggle="modal" onClick={(e) => this.onEditClick(e, item)}> <i className="fas fa-pencil-alt"></i>Edit</a>
                              <a className="dropdown-item" href="#" onClick={(e) => this.onDeleteClick(e, item)} ><i className="fas fa-exclamation-circle"></i>Remove</a>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  );
                })
                :
                <div className="no-result-text">
                  <p>No result found</p>
                </div>
              }
            </div>
            <Footer onPrevPress={this.onPrevPress} onNextPress={this.onNextPress} pageNo={this.state.pageNo} cc={"jj"} totalcount={this.state.totalcount} />
            {/* <div className="footer user_footer">
              <div className="pages">
              </div>
              <div className="pages1">
                {this.state.totalcount > 0 ?
                  <p>{this.state.pageLimit * (this.state.pageNo) > 1 ? this.state.pageLimit * (this.state.pageNo) : '1'} - {this.state.pageLimit * (this.state.pageNo + 1)} of {this.state.totalcount} results
                    <i className="fas fa-chevron-left" onClick={this.onPrevPress} />
                    <span>{Number.parseInt(this.state.pageNo) + 1}</span>
                    <i className="fa fa-chevron-right" onClick={this.onNextPress} />
                  </p>
                  : ''}
              </div>
            </div> */}
          </div>
          {this.state.loader &&
            <div className='loader user-loader'>
              <Loader type="Oval" color="Grey" height={200} width={200} />
            </div>
          }
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  userData: state.user.userData,
  userDetail: state.user.userDetail,
  editData: state.user.editData,
  lotResponse: state.user.lotResponse,
  accessData: state.user.accessData,
  reportData: state.user.reportData,
  deleteData: state.user.deleteData
});

export default connect(mapStateToProps, { getUserData, getUserDetail, createUser, editUserData, getAccessLevelData, getReportinglData, removeUserData })(UserComponent);