import React, { Component } from 'react'
import './ProductComponent.css';
import { connect } from "react-redux";
import history from '../../history';
import HtmlParser from 'react-html-parser';
import { getMessageData, getMessageDetail, editMessageData, removeMessageData } from '../../actions/productActions'
import ViewProfile from '../ViewProfile'
import AccessRestricted from '../AccessRestricted'
import ComplaintRegistration from '../ComplaintRegistration';
import Footer from'../FooterComponent/footer';
import SidebarComponent from '../SidebarComponent/SidebarComponent';

class MessageComponent extends Component {
  constructor() {
    super();

    this.state = {
      logout: false,
      pageNo: 0,
      pageLimit: 10,
      searchText: '',
      //searchDate: '',
      messageList: [],
      messageData: [],
      message_id: '',
      message: '',
      subject: '',
      type: '',
      body: '',
      status: '',
      messageId: '',
      messageType: '',
      errors: '',
      message_type: '',
      count: ''

    }

    this.showMenu = this.showMenu.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleLogout() {
    localStorage.removeItem('token')
    document.querySelector(".modal-backdrop").style.display = "none";
    history.push('/')
  }

  handleIconDetails() {
    this.setState({
      logout: !this.state.logout
    })
  }

  onClearForm = (e) => {
    this.setState({
      message: '',
      subject: '',
      messageType: '',
    })
  }


  componentDidMount() {
    this.getMessageList();
  }


  onPageLimitChange = (e) => {
    this.setState({ pageLimit: e.target.value }, () => {
      this.getMessageList();
    })
  }



  onPrevPress = () => {
    if (this.state.prevPage) {
      this.setState({ pageNo: (this.state.pageNo - 1) }, () => {
        this.getMessageList()
      })
    }
  }

  onNextPress = () => {
    if (this.state.nextPage) {
      this.setState({ pageNo: (this.state.pageNo + 1) }, () => {
        this.getMessageList()
      })
    }
  }

  onMessageType = (e) => {
    this.setState({ messageType: e.target.value }, () => {
    })
  }


  getMessageList = async () => {
    let data = {
      //"page_no": this.state.pageNo,
      "limit": this.state.pageLimit,
      "offset": this.state.pageNo,
      "filterText": this.state.searchText
    }
    this.setState({ isLoading: true, messageData: [] })
    //await this.props.getMessageData(data);
    await this.props.getMessageData(data);
    let messageData = this.props.messageResponse;
    if (messageData.success) {
      this.setState({
        isLoading: false,
        prevPage: messageData.data.offset,
        nextPage: messageData.data.next_page,
        totalcount: messageData.data.count,
        // limit: this.messageData.limit,
        messageData: messageData.data.messageData,
      })
    }
  }

  handleEdit = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }


  onEditClick = (e, item) => {
    this.setState({
      messageId: item.id,
      type: item.message_type,
      subject: item.subject,
      body: item.body
    })
  }

  showMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true });
  }

  handleDate = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.getMessageList();
    })
  }

  //this.setState({ [e.target.name]: e.target.value })

  handleChange = (e) => {
    if (e.target.name === 'searchByusername') {
      this.setState({ searchText: e.target.value }, () => {
        this.getMessageList();
      })
    } else {
      this.setState({ [e.target.name]: e.target.value }, () => {
      })
    }
  }



  handleValidationadd() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.body) {
      formIsValid = false;
      errors["error_body"] = "Cannot be empty";
    }

    if (!this.state.subject) {
      formIsValid = false;
      errors["error_subject"] = "Cannot be empty";
    }

    if (!this.state.messageType) {
      formIsValid = false;
      errors["error_message_type"] = "Cannot be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }


  handleValidation() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.body) {
      formIsValid = false;
      errors["error_body"] = "Cannot be empty";
    }

    if (!this.state.subject) {
      formIsValid = false;
      errors["error_subject"] = "Cannot be empty";
    }


    if (!this.state.type || this.state.type === '0') {
      formIsValid = false;
      errors["error_message_type"] = "Cannot be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(e) {
    e.preventDefault();
    let data = {
      "message": this.state.body,
      "subject": this.state.subject,
      "type": this.state.messageType
    }
    //if validate than un comment this line
    if (this.handleValidationadd() !== false) {
      this.callEditApi(data)
    }

  }



  onUpdateClick = (e) => {
    e.preventDefault()
    let data = {
      "message_id": this.state.messageId,
      "message": this.state.body,
      "subject": this.state.subject,
      "type": this.state.type

    }

    if (this.handleValidation() !== false) {
      this.callEditApi(data)
    }
  }



  onDeleteClick = (e, item) => {
    e.preventDefault()
    let data = {
      "message_id": item.id,
    }

    this.deleteMessageData(data)
  }



  deleteMessageData = async (param) => {

    await this.props.removeMessageData(param)
    //let editRes = this.props.editMessageData
    this.onClearForm();
    this.getMessageList();

  }

  callEditApi = async (param) => {
    await this.props.editMessageData(param)
    // let editRes = this.props.editMessageData
    document.getElementById("add_form").reset();

    let link = document.getElementById('editMessage_close');
    link.click();
    let link1 = document.getElementById('add_msg_close');
    link1.click();

    // var element = document.querySelector(".modal-backdrop");
    // element.classList.remove("show");
    // document.getElementById("exampleModals").style.display = "none";
    // document.querySelector(".modal-backdrop").style.display = "none";

    this.getMessageList();
    history.push('/manage-message')

  }

  render() {
    let userRoleId = localStorage.getItem('userRoleId');
    if (userRoleId === '4' || userRoleId === '8' || userRoleId === '9' || userRoleId === '10') {
      return (<AccessRestricted />)
    }
    else if (userRoleId === '6'){
      return (<ComplaintRegistration />)
  }
    else {
      return (
        <div className="manage-message message-component-main">
          {window.innerWidth > 600 &&  <SidebarComponent sideProp={'MP'} />}
           <ViewProfile />


          <div className="content">
          <div className='search_header_product'>
            <span>Product</span>
            <div className='search' style={{marginRight:'unset'}}>
              <i className="fa fa-search"></i> 
              <input type="username"  style={{width:'230px'}} name="searchByusername" placeholder=" Search By Subject" onChange={this.handleChange} />
              </div>
              </div>
            <ul id="menu">
              <li><a href="/manage-product" className="noactive">Product Info</a></li>
              <li><a href="/manage-group" className="noactive">Product Group</a></li>
              <li className="actice2"><a href="/manage-message">Message</a></li>
              <li><a href="/channels" className="noactive">Channels</a></li>

            </ul>
              
             
           <div className="list"> 
           {/* <input type="username" name="searchByusername" placeholder=" Search By Subject" onChange={this.handleChange} /> */}
              <button type="button" data-toggle="modal" data-target="#exampleModal" className="add-message-btn">
                Add Message
              </button>

              <div className="modal fade" id="exampleModal">
                <div className="modal-dialog" role="document">
                  <div className="modc">
                    <form action="" id="add_form" onSubmit={this.handleSubmit}>
                      <div className="modal-header">

                        <div className="list2">
                          <span className="titleshow">Add message</span>
                        </div>
                        <div data-dismiss="modal" aria-label="Close" id="add_msg_close" className="add_msg_close">
                          <span aria-hidden="true" style={{ color: 'white' }}><i className="fas fa-times"></i></span>
                        </div></div>

                      <div className="form-row mg-left2">
                        <div className="col-md-6 mb-6">
                          <label>Message Type *</label>

                          <select className="dropbtn1111" name="message_type" onChange={(e) => this.onMessageType(e)}>
                            {/* <i className="fas fa-chevron-down"></i> */}
                            <option value={0}> Message Type</option>
                            <option value={'email'}> Email</option>
                            <option value={'message'}> Message</option>

                          </select>

                          <div className="errorpd">
                            <span className="errorShow">{this.state.errors["error_message_type"]}</span>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mg-left2">
                        {/* <div className="col-md-12 mb-12"> */}
                        <label>Subject</label>
                        <input type="text" className="form-control inputclass" name="subject" onChange={this.handleChange} placeholder="Subject" required />
                        <div className="errorpd">
                          <span className="errorShow">{this.state.errors["error_subject"]}</span>
                        </div>
                        {/* </div> */}
                      </div>


                      <div className="form-group mg-left2">
                        {/* <div className="col-md-12 mb-12"> */}
                        <label>Body</label>
                        <input type="text" className="form-control inputclass" name="body" onChange={this.handleChange} placeholder="Body" required />
                        <div className="errorpd">
                          <span className="errorShow">{this.state.errors["error_body"]}</span>
                        </div>
                      </div>

                      {/* <div className="sign">
    <i className="fas fa-plus-circle"></i> Add Another</div> */}
                      <div className="addbtn">
                        <button onClick={this.handleSubmit}>Add Message</button></div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

          </div>

          <div className="modal fade" id="viewModal" >
            <div className="modal-dialog" role="document">
              <div className="modc">
                <div className="modal-header">

                  <div className="list2 edit_msg">
                    <span>Edit message</span>
                  </div>
                  <div data-dismiss="modal" id="editMessage_close" aria-label="Close" className="editMessage_close">
                    <span aria-hidden="true"><i className="fas fa-times"></i></span>
                  </div>
                </div>
                <form id="add_form">
                  <div className="form-row mg-left2">
                    <div className="col-md-6 mb-6">
                      <label>Message Type *</label>

                      <select className="dropbtn1111" name="message_type" onChange={(e) => this.onMessageType(e)}>
                        {/* <i className="fas fa-chevron-down"></i> */}
                        <option value={0}> Message Type</option>
                        <option value={'email'} defaultValue={'email' === this.state.type}> Email</option>
                        <option value={'message'} defaultValue={'message' === this.state.type}> Message</option>

                      </select>

                      <div className="errorpd">
                        <span className="errorShow">{this.state.errors["error_message_type"]}</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mg-left2">
                    {/* <div className="col-md-12 mb-12"> */}
                    <label>Subject</label>
                    <input type="text" className="form-control inputclass" name="subject" value={this.state.subject} onChange={this.handleEdit} placeholder="Subject" required />
                    <div className="errorpd">
                      <span className="errorShow">{this.state.errors["error_subject"]}</span>
                    </div>
                    {/* </div> */}
                  </div>


                  <div className="form-group mg-left2">
                    {/* <div className="col-md-12 mb-12"> */}
                    <label>Body</label>
                    <input type="text" className="form-control inputclass" name="body" value={(HtmlParser(this.state.body))} onChange={this.handleEdit} placeholder="Body" required />
                    <div className="errorpd">
                      <span className="errorShow">{this.state.errors["error_body"]}</span>
                    </div>
                  </div>

                  <div className="addbtn">
                    <button onClick={(e) => this.onUpdateClick(e)}>Update Changes</button></div>
                </form>
              </div>

            </div>
          </div>


          <div className="colrow message_wrapper">
            <div className="row customrow" style={{marginTop:"0px"}}>
              <div className="col-sm "  style={{color:"#eb951f"}}>Message type</div>
              <div className="col-sm"  style={{color:"#eb951f"}}>Subject</div>
               </div>{this.state.messageData.length > 0 ?
              this.state.messageData.map((item, index) => {
                return (
                  <div className="row customrow" key={index}>
                    <div className="col">
                      <p>{item.message_type}</p>
                    </div>
                    <div className="col">{item.subject}
                    </div>



                    <div className="col">
                      <span> {" "}</span>
                      <div className="dropdown">
                        <button className="btn btn-link " type="button" id="dropdownMenuButton" data-toggle="dropdown" ria-expanded="false">
                          <i className="fas fa-ellipsis-h" style={{ paddingTop: '23px' }} ></i>
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" onClick={(e) => this.onEditClick(e, item)}>
                          <a className="dropdown-item" href="" data-toggle="modal" data-target="#viewModal"> <i className="fas fa-pencil-alt"></i>Edit</a>
                          <a className="dropdown-item" href="" onClick={(e) => this.onDeleteClick(e, item)} ><i className="fas fa-exclamation-circle"></i>Remove</a>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              :
              <div className="no-result-text">
                <p>No result found</p>
              </div>
            }
          </div>
          <Footer onPrevPress={this.onPrevPress} onNextPress={this.onNextPress} pageNo={this.state.pageNo} cc={"jj"} totalcount={this.state.totalcount} />
          {/* <div className="footer">
            <div className="pages">
            </div>
            <div className="pages1">
              <p>{this.state.pageLimit * (this.state.pageNo) > 1 ? this.state.pageLimit * (this.state.pageNo) : '1'} - {this.state.pageLimit * (this.state.pageNo + 1)} of {this.state.totalcount} results
                <i className="fas fa-chevron-left" onClick={this.onPrevPress} />
                <span>{Number(this.state.pageNo) + 1}</span>
                <i className="fa fa-chevron-right" onClick={this.onNextPress} />
              </p>
            </div></div> */}
        </div>

      )
    }
  }
}
const mapStateToProps = state => ({
  messageResponse: state.product.messageResponse,
  //userDetail :state.product.userDetail,
  editMessageData: state.product.editMessageData,
  //lotResponse: state.product.lotResponse
})

export default connect(mapStateToProps, { getMessageData, getMessageDetail, editMessageData, removeMessageData })(MessageComponent);
