export const FETCH_CRM_DATA = "FETCH_CRM_DATA";

export const GET_SUB_PROBLEM_LIST = "GET_SUB_PROBLEM_LIST";

export const FETCH_COMPLAINTS_DATA = "FETCH_COMPLAINTS_DATA";

export const FETCH_REFUND_RESEND = "FETCH_REFUND_RESEND";

export const FETCH_ORDER_DATA = "FETCH_ORDER_DATA";

export const FETCH_WARRANTY_DATA = "FETCH_WARRANTY_DATA";

export const GET_WARRANTY_DATA = "GET_WARRANTY_DATA";

export const FETCH_USER_DATA = "FETCH_USER_DATA";

export const FETCH_DASHBOARD_DATA = "FETCH_DASHBOARD_DATA";

export const FETCH_FILTER_DATA = "FETCH_FILTER_DATA";

export const ADD_SELECTION = "ADD_SELECTION";

export const REMOVE_SELECTION = "REMOVE_SELECTION";

export const REMOVE_ALL_SELECTION = "REMOVE_ALL_SELECTION";

export const ADD_COMPLAINT_SELECTION = "ADD_COMPLAINT_SELECTION";

export const REMOVE_COMPLAINT_SELECTION = "REMOVE_COMPLAINT_SELECTION";

export const ADD_WARRANTY_SELECTION = "ADD_WARRANTY_SELECTION";

export const REMOVE_WARRANTY_SELECTION = "REMOVE_WARRANTY_SELECTION";

export const ADD_CHANNEL_TYPE_SELECTION = "ADD_CHANNEL_TYPE_SELECTION";

export const REMOVE_CHANNEL_TYPE_SELECTION = "REMOVE_CHANNEL_TYPE_SELECTION";

export const REMOVE_ALL_CHANNEL_TYPE_SELECTION = "REMOVE_ALL_CHANNEL_TYPE_SELECTION";

export const ADD_ORDER_TYPE_SELECTION = "ADD_ORDER_TYPE_SELECTION";

export const REMOVE_ORDER_TYPE_SELECTION = "REMOVE_ORDER_TYPE_SELECTION";

export const REMOVE_ALL_ORDER_TYPE_SELECTION = "REMOVE_ALL_ORDER_TYPE_SELECTION";

export const CLEAR_ALL_DASHBOARD_DATA = "CLEAR_ALL_DASHBOARD_DATA";

export const SEARCH_INPUT_TEXT = "SEARCH_INPUT_TEXT";

export const CLEAR_ALL_ORDER_DATA = "CLEAR_ALL_ORDER_DATA";

export const CLEAR_ALL_COMPLAINT_DATA = "CLEAR_ALL_COMPLAINT_DATA";

export const CLEAR_ALL_WARRANTY_DATA = "CLEAR_ALL_WARRANTY_DATA";

export const CLEAR_ALL_LEADS_DATA = "CLEAR_ALL_LEADS_DATA";

export const START_DATE = "START_DATE";

export const END_DATE = "END_DATE";

export const LISTING_BY = "LISTING_BY";

export const GET_PRODUCT_CATEGORY = "GET_PRODUCT_CATEGORY";

export const GET_PRODUCT_NAME = "GET_PRODUCT_NAME";

export const SET_INVENTORY_DATA = "SET_INVENTORY_DATA";

export const GET_INVENTORY_DATA = "GET_INVENTORY_DATA";

export const CLEAR_ALL_INVENTORY_DATA = "CLEAR_ALL_INVENTORY_DATA";

export const REMOVE_INVENTORY_DATA = "REMOVE_INVENTORY_DATA";

export const SET_CATEGORY_DATA = "SET_CATEGORY_DATA";

export const GET_CATEGORY_DATA = "GET_CATEGORY_DATA";

export const UPDATE_CATEGORY_DATA = "UPDATE_CATEGORY_DATA";

export const REMOVE_CATEGORY_DATA = "REMOVE_CATEGORY_DATA";

export const CLEAR_ALL_CATEGORY_DATA = "CLEAR_ALL_CATEGORY_DATA";

export const SET_SCM_DATA = "SET_SCM_DATA";

export const GET_SCM_DATA = "GET_SCM_DATA";

export const CLEAR_ALL_SCM_DATA = "CLEAR_ALL_SCM_DATA";

export const REMOVE_SCM_DATA = "REMOVE_SCM_DATA";

export const GET_ORDER_HISTORY = "GET_ORDER_HISTORY";

export const SET_LOT_DATA = "SET_LOT_DATA";

export const GET_LOT_DATA = "GET_LOT_DATA";

export const CLEAR_ALL_LOT_DATA = "CLEAR_ALL_LOT_DATA";

export const UPDATE_LOT_DATA = "UPDATE_LOT_DATA";

export const PICK_LOT_DATA = "PICK_LOT_DATA";

export const GET_ORDER_DETAIL = "GET_ORDER_DETAIL";

export const APPROVE_LOT_REQUEST = "APPROVE_LOT_REQUEST";

export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT";

export const GET_ORDER_NOS = "GET_ORDER_NOS";

//export const ADD_USER_SELECTION = 'ADD_USER_SELECTION';

//export const REMOVE_USER_SELECTION = 'REMOVE_USER_SELECTION';

//export const CLEAR_ALL_USER_DATA = 'CLEAR_ALL_USER_DATA';

export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";

export const GET_PRODUCTS = "GET_PRODUCTS";

export const GET_PRODUCT_PROBLEM_LIST = "GET_PRODUCT_PROBLEM_LIST";

export const GET_COMPLAINT_BUCKET = "GET_COMPLAINT_BUCKET";

export const GET_COMPLAINT_STATUS = "GET_COMPLAINT_STATUS";

export const EDIT_COMPLAINTS_DATA = "EDIT_COMPLAINTS_DATA";

export const ADD_COMMENT = "ADD_COMMENT";

export const GET_COMPLAINTS_DETAIL = "GET_COMPLAINTS_DETAIL";

export const CALL_CUSTOMER = "CALL_CUSTOMER";

export const GET_RPU_COURIER = "GET_RPU_COURIER";

export const GET_USER_DETAIL = "GET_USER_DETAIL";

export const SET_USER_DATA = "SET_USER_DATA";

export const EDIT_USER_DATA = "EDIT_USER_DATA";

// product
export const FETCH_PRODUCT_DATA = "FETCH_PRODUCT_DATA";

export const FETCH_GROUP_DATA = "FETCH_GROUP_DATA";

export const FETCH_ACCESSLAVEL_DATA = "FETCH_ACCESSLAVEL_DATA";

export const FETCH_REPORTING_DATA = "FETCH_REPORTING_DATA";

export const FETCH_OOS_REPORT = "FETCH_OOS_REPORT";

export const FETCH_OOS_GIFT_REPORT = "FETCH_OOS_GIFT_REPORT";

export const FETCH_RTL_REPORT_CONFIG = "FETCH_RTL_REPORT_CONFIG";

export const SET_PRODUCT_DATA = "SET_PRODUCT_DATA";

export const EDIT_PRODUCT_DATA = "EDIT_PRODUCT_DATA";

export const GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL";

export const SET_PRODUCTGROUP_DATA = "SET_PRODUCTGROUP_DATA";

export const FETCH_MESSAGE_DATA = "FETCH_MESSAGE_DATA";

export const EDIT_MESSAGE_DATA = "EDIT_MESSAGE_DATA";

export const FETCH_TICKET_DATA = "FETCH_TICKET_DATA";

export const USER_DELETE = "USER_DELETE";

export const FETCH_COLOR_DATA = "FETCH_COLOR_DATA";

export const FETCH_PRODUCT_NAME_DATA = "FETCH_PRODUCT_NAME_DATA";

export const SET_ASSIGN_DATA = "SET_ASSIGN_DATA";

// BULK MOVEMENT
export const FETCH_BUCKETS = "FETCH_BUCKETS";

export const FETCH_TICKET_LIST = "FETCH_TICKET_LIST";

export const FETCH_STATUS_LIST = "FETCH_STATUS_LIST";

export const SUCCESS_LIST = "SUCCESS_LIST";

export const SUCCESS_LIST_DI = "SUCCESS_LIST_DI";

export const FAILURE_LIST = "FAILURE_LIST";

export const FAILURE_LIST_DI = "FAILURE_LIST_DI";

export const MAC_FAILURE_LIST = "MAC_FAILURE_LIST";

export const FAILURE_WAY_BILL_LIST = "FAILURE_WAY_BILL_LIST";

export const SUCCESS_WAY_BILL_LIST = "SUCCESS_WAY_BILL_LIST";

export const NEXT_BUTTON_DISABLE = "NEXT_BUTTON_DISABLE";

export const FETCH_TICKET_COUNT = "FETCH_TICKET_COUNT";

export const FETCH_BULK_CONSTANT = "FETCH_BULK_CONSTANT";

export const CLEAR_SUCCESS_LIST_BRPU = "CLEAR_SUCCESS_LIST_BRPU";

export const CLEAR_FAILURE_LIST_BRPU = "CLEAR_FAILURE_LIST_BRPU";

export const CLEAR_SUCCESS_LIST = "CLEAR_SUCCESS_LIST";

export const CLEAR_SUCCESS_LIST_DI = "CLEAR_SUCCESS_LIST_DI";

export const CLEAR_FAILURE_LIST = "CLEAR_FAILURE_LIST";

export const CLEAR_FAILURE_LIST_DI = "CLEAR_FAILURE_LIST_DI";

// Dashboard
export const FETCH_DASHBOARD_ALL = "FETCH_DASHBOARD_ALL";

export const FETCH_DASHBOARD_COURIER = "FETCH_DASHBOARD_COURIER";

export const FETCH_DASHBOARD_PROBLEM = "FETCH_DASHBOARD_PROBLEM";

//Report
export const FETCH_LEADERBOARD_ALL = "FETCH_LEADERBOARD_ALL";

export const FETCH_LEADERBOARD_ALL_CHANGE = "FETCH_LEADERBOARD_ALL_CHANGE";

export const FETCH_DAILY_REPORT_DATA = "FETCH_DAILY_REPORT_DATA";

export const FETCH_USER_ACTION_REPORT_DATA = "FETCH_USER_ACTION_REPORT_DATA";

export const FETCH_REPORT_CONFIG = "FETCH_REPORT_CONFIG";

export const FETCH_ACTION_REPORT_CONFIG = "FETCH_ACTION_REPORT_CONFIG";

export const FETCH_OPEN_CLOSE_REPORT_DATA = "FETCH_OPEN_CLOSE_REPORT_DATA";

export const FETCH_INVOICE_REPORT_CONFIG = "FETCH_INVOICE_REPORT_CONFIG";

export const FETCH_IVR_REPORT_CONFIG = "FETCH_IVR_REPORT_CONFIG";

export const FETCH_REFUND_GC_CONFIG = "FETCH_REFUND_GC_CONFIG";

export const FETCH_MANIFEST_REPORT_CONFIG = "FETCH_MANIFEST_REPORT_CONFIG";

export const FETCH_OPEN_CLOSE_REPORT_CONFIG = "FETCH_OPEN_CLOSE_REPORT_CONFIG";

export const DOWNLOAD_MANIFEST_REPORT = "DOWNLOAD_MANIFEST_REPORT";

export const FETCH_ALL_PRODUCTS = "FETCH_ALL_PRODUCTS";

export const FETCH_CHANNELS = "FETCH_CHANNELS";

export const FETCH_DATA_FIELDS = "FETCH_DATA_FIELDS";

export const DOWNLOAD_CUSTOM_REPORT = "DOWNLOAD_CUSTOM_REPORT";

export const FETCH_AUTHORIZATION = "FETCH_AUTHORIZATION";

export const FETCH_MOVE_REPORT_CONFIG = "FETCH_MOVE_REPORT_CONFIG";

export const FETCH_SC_REPORT_CONFIG = "FETCH_SC_REPORT_CONFIG";

//COMPLAINT
export const ADD_STATUS_ID = "ADD_STATUS_ID";

export const SHOW_COMPLAINT_DETAILS = "SHOW_COMPLAINT_DETAILS";

export const FETCH_COMPLAINTS_COUNT = "FETCH_COMPLAINTS_COUNT";

export const REFRESH_COMPLAINT_LIST = "REFRESH_COMPLAINT_LIST";

export const FETCH_COMMENTS = "FETCH_COMMENTS";

export const SEND_MAIL = "SEND_MAIL";

export const RESEND_MAIL = "RESEND_MAIL";

export const FETCH_CANNED_REPONSES = "FETCH_CANNED_REPONSES";

export const GET_CHANNEL_LIST = "GET_CHANNEL_LIST";

export const FETCH_REUND_CASE = "FETCH_REUND_CASE";

export const FETCH_ADMIN_LIST = "FETCH_ADMIN_LIST";

export const FETCH_GC_CASE = "FETCH_GC_CASE";

export const FETCH_GCAPPROVAL_CASE = "FETCH_GCAPPROVAL_CASE";

export const FETCH_REFUNDAPPROVAL_CASE = "FETCH_REFUNDAPPROVAL_CASE";

export const FETCH_DOWNLOAD_REPORT = "FETCH_DOWNLOAD_REPORT";

export const FETCH_GC_APPROVAL_CASE = "FETCH_GC_APPROVAL_CASE";

// city
export const GET_CITY_LIST = "GET_CITY_LIST";

export const GET_STATE_LIST = "GET_STATE_LIST";

export const FETCH_LOGIN_USER_DATA = "FETCH_LOGIN_USER_DATA";

export const FETCH_LOGIN_RESPONSE = "FETCH_LOGIN_RESPONSE";

export const FETCH_CONVERSATIONS = "FETCH_CONVERSATIONS";

export const FETCH_PAGINATION_INFO = "FETCH_PAGINATION_INFO";

export const COURIER_ID = "COURIER_ID";

export const UNASSIGNED_COMPLAINT_LIST = "UNASSIGNED_COMPLAINT_LIST";

export const TRACK_STATUS_DATA = "TRACK_STATUS_DATA";

export const TRANSFER_TO_DD = "TRANSFER_TO_DD";

export const SUCCESS_LIST_BRPU = "SUCCESS_LIST_BRPU";

export const FAILURE_LIST_BRPU = "FAILURE_LIST_BRPU";

export const SCREENING_RESPONSE = "SCREENING_RESPONSE";

export const PRINT_LABEL_DATA = "PRINT_LABEL_DATA";

//service center
export const FETCH_SERVICE_DATA = "FETCH_SERVICE_DATA";

export const UPDATE_SERVICE_DATA = "UPDATE_SERVICE_DATA";

export const CENTER_DELETE = "CENTER_DELETE";

export const FETCH_SERVICE_REPORT_CONFIG = "FETCH_SERVICE_REPORT_CONFIG";

export const FETCH_PRODUCT_BY_CATEGORY = "FETCH_PRODUCT_BY_CATEGORY";

export const FETCH_SERVICE_COMPLAINT_CONFIG = "FETCH_SERVICE_COMPLAINT_CONFIG";

export const ADD_SERVICE_CENTER = "ADD_SERVICE_CENTER";

export const ADD_NEW_SERVICE_CENTER = "ADD_NEW_SERVICE_CENTER";

export const EDIT_SERVICE_CENTER = "EDIT_SERVICE_CENTER";

export const CLEAR_EDIT_SERVICE_CENTER_DATA = "CLEAR_EDIT_SERVICE_CENTER_DATA";

export const CLEAR_MAIN_SERVICE_CENTER_DATA = "CLEAR_MAIN_SERVICE_CENTER_DATA";

export const CLEAR_SUB_SERVICE_CENTER_EMP_DATA = "CLEAR_SUB_SERVICE_CENTER_EMP_DATA";

export const ADD_SUB_SERVICE_CENTER_EMPLOYEE = "ADD_SUB_SERVICE_CENTER_EMPLOYEE";

export const EDIT_SUB_SERVICE_CENTER_EMPLOYEE = "EDIT_SUB_SERVICE_CENTER_EMPLOYEE";

export const CLEAR_EDIT_EMPLOYEE_DATA = "CLEAR_EDIT_EMPLOYEE_DATA";

export const GET_EMPLOYEE_DETAILS = "GET_EMPLOYEE_DETAILS";

export const DEACTIVATE_SERVICE_CENTER = "DEACTIVATE_SERVICE_CENTER";

export const GET_EMPLOYEE_DATA = "GET_EMPLOYEE_DATA";

export const SET_ACTIVE_FILTERS = "SET_ACTIVE_FILTERS";

//User
export const TICKET_ASSIGNED = "TICKET_ASSIGNED";

export const REMOVE_SERVICE_CENTER = "REMOVE_SERVICE_CENTER";

export const CALL_SERVICE_CENTER = "CALL_SERVICE_CENTER";

//Reschedule IVR
export const RESCHEDULE_IVR = "RESCHEDULE_IVR";

//distributor
export const FETCH_DISTRIBUTOR_DATA = "FETCH_DISTRIBUTOR_DATA";

export const SHOW_DISTRIBUTOR_COMPLAIN_DETAILS = "SHOW_DISTRIBUTOR_COMPLAIN_DETAILS";

export const ADD_DISTRIBUTOR_STATUS_ID = "ADD_DISTRIBUTOR_STATUS_ID";

export const GET_DISTRIBUTOR_COMPLAINTS_DETAIL = "GET_DISTRIBUTOR_COMPLAINTS_DETAIL";

export const DISTRIBUTOR_COMPLAINT_STATUS = "DISTRIBUTOR_COMPLAINT_STATUS";

export const ADD_COMPLAINT_DISTRIBUTOR = "ADD_COMPLAINT_DISTRIBUTOR";

export const FETCH_SAVE_TICKET = "FETCH_SAVE_TICKET";

export const FETCH_DISTRIBUTOR_CONVERSATIONS = "FETCH_DISTRIBUTOR_CONVERSATIONS";

export const DISTRIBUTOR_SCREENING_RESPONSE = "DISTRIBUTOR_SCREENING_RESPONSE";

export const FETCH_INDIVIDUAL_PRODUCTS = "FETCH_INDIVIDUAL_PRODUCTS";

export const COMPLAINT_PRODUCT_LIST = "COMPLAINT_PRODUCT_LIST";

export const EDIT_COMPLAINT = "EDIT_COMPLAINT";

export const FETCH_ALL_PRODUCTS_CLUBBED = "FETCH_ALL_PRODUCTS_CLUBBED";

//REFUND GC
export const FETCH_REFUND_EDIT = "FETCH_REFUND_EDIT";

export const FETCH_GENERATE_LINK = "FETCH_GENERATE_LINK";

export const FETCH__GC_AAPPROVED_LINK = "FETCH__GC_AAPPROVED_LINK";

//notify
export const GET_NOTIFY_DATA = "GET_NOTIFY_DATA";

export const FETCH_NOTIFY_REPORT_CONFIG = "FETCH_NOTIFY_REPORT_CONFIG";

export const FETCH_LUNA_NOTIFY_REPORT_CONFIG = "FETCH_LUNA_NOTIFY_REPORT_CONFIG";

export const FETCH_B2B_REPORT_CONFIG = "FETCH_B2B_REPORT_CONFIG";

//Service Center Complaint Registration

export const SAVE_CUSTOMER_DATA = "SAVE_CUSTOMER_DATA";

export const SAVE_PRODUCT_DATA = "SAVE_PRODUCT_DATA";

export const SAVE_COMPLAINT_DATA = "SAVE_COMPLAINT_DATA";

export const CLEAR_COMPLAINT_DATA = "CLEAR_COMPLAINT_DATA";

export const CLEAR_SEARCH_DATA = "CLEAR_SEARCH_DATA";

export const CHANGE_COMPLAINT_STATUS = "CHANGE_COMPLAINT_STATUS";

export const CLEAR_EDITED_COMPLAINT_RES = "CLEAR_EDITED_COMPLAINT_RES";

export const FETCH_COMPLAINT_DETAILS = "FETCH_COMPLAINT_DETAILS";

export const FETCH_SERVICE_CENTERS = "FETCH_SERVICE_CENTERS";

export const FETCH_SERVICE_CENTER_REPORT = "FETCH_SERVICE_CENTER_REPORT";

export const FETCH_SERVICE_CONVERSATIONS = "FETCH_SERVICE_CONVERSATIONS";

export const TRACK_SERVICE_STATUS_DATA = "TRACK_SERVICE_STATUS_DATA";

export const FETCH_SERVICE_CENTER_LIST = "FETCH_SERVICE_CENTER_LIST";

export const CLEAR_SUCCESS_LIST_SC = "CLEAR_SUCCESS_LIST_SC";

export const CLEAR_FAILURE_LIST_SC = "CLEAR_FAILURE_LIST_SC";

export const FAILURE_LIST_SC = "FAILURE_LIST_SC";

export const SUCCESS_LIST_SC = "SUCCESS_LIST_SC";

export const SUCCESS_LIST_BRPU_SC = "SUCCESS_LIST_BRPU_SC";

export const FAILURE_LIST_BRPU_SC = "FAILURE_LIST_BRPU_SC";

export const CLEAR_SUCCESS_LIST_BRPU_SC = "CLEAR_SUCCESS_LIST_BRPU_SC";

export const CLEAR_FAILURE_LIST_BRPU_SC = "CLEAR_FAILURE_LIST_BRPU_SC";

//Search

export const FETCH_SEARCH_RESULTS = "FETCH_SEARCH_RESULTS";

export const GET_SC_RPU_COURIER = "GET_SC_RPU_COURIER";

export const PRINT_SC_LABEL_DATA = "PRINT_SC_LABEL_DATA";

export const COSMOS_RES = "COSMOS_RES";

export const FETCH_CHALLAN_DATA = "FETCH_CHALLAN_DATA";

export const SET_COMPLAINT_ARRAY = "SET_COMPLAINT_ARRAY";

export const SEND_CHALLAN_ACTION = "SEND_CHALLAN_ACTION";

export const SEND_WAREHOUSE_ACTION = "SEND_WAREHOUSE_ACTION";

export const FAILED_TO_RECIEVE = "FAILED_TO_RECIEVE";

export const SHOW_TROUBLESHOOT_ACTION = "SHOW_TROUBLESHOOT_ACTION";

export const FETCH_SUB_PROBLEM_ID = "FETCH_SUB_PROBLEM_ID";

export const FETCH_CONVERSATIONS_DATA = "FETCH_CONVERSATIONS_DATA";

export const FETCH_CONVERSATIONS_LOAD = "FETCH_CONVERSATIONS_LOAD";

export const FETCH_COMMENT_LOAD = "FETCH_COMMENT_LOAD";

export const FETCH_COMMENT_DATA = "FETCH_COMMENT_DATA";

export const SET_BUCKET_ID_LIST = "SET_BUCKET_ID_LIST";

export const SET_ACTION_TRUE = "SET_ACTION_TRUE";

export const SAVE_MAC_ID = "SAVE_MAC_ID";

export const GET_NON_NFA = "GET_NON_NFA";

export const SUCCESS_TESTING_INWARD_LIST = "SUCCESS_TESTING_INWARD_LIST";

export const FAILURE_TESTING_INWARD_LIST = "FAILURE_TESTING_INWARD_LIST";

export const FAILURE_MAC_FAILURE_LIST = "FAILURE_MAC_FAILURE_LIST";

export const SUCCESS_TESTING_INWARD_LIST_CLEAR = "SUCCESS_TESTING_INWARD_LIST_CLEAR";

export const FAILURE_TESTING_INWARD_LIST_CLEAR = "FAILURE_TESTING_INWARD_LIST_CLEAR";

export const FAILURE_MAC_FAILURE_LIST_CLEAR = "FAILURE_MAC_FAILURE_LIST_CLEAR";

export const FETCH_REPLACEMENT_REPORT_CONFIG = "FETCH_REPLACEMENT_REPORT_CONFIG";

export const SET_EDIT_PRODUCT_DATA = "SET_EDIT_PRODUCT_DATA";

export const FETCH_PAGINATION_INFO_INTERNATIONAL="FETCH_PAGINATION_INFO_INTERNATIONAL";

export const GET_WARRANTY_DATA_INTERNATIONAL="GET_WARRANTY_DATA_INTERNATIONAL";

export const GET_FAULTS_LIST = "GET_FAULT_LIST";

