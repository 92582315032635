// Sub Service Center (Role ID 9 and 10)
import React, {useState, useEffect} from 'react'
import './SubServiceCenter.css';
import ActiveComplaints from './ActiveComplaints'
import ReplacementComplaints from './ReplacementComplaints';
import ClosedComplaints from './ClosedComplaints';
import ManageUsers from './ManageUsers'
import AccessRestricted from '../AccessRestricted';
import SearchResult from './SearchResult';

import {clearSubServiceCenterEmpData, clearEditEmpData, setActiveInFilters} from '../../actions/serviceCenterActions'
import {clearComplaintData, clearEditedComplaintResponse} from '../../actions/serviceCenterComplaintAction'
import { useDispatch } from 'react-redux';

import ServiceCenterHeader from '../ServiceCenterHeader/ServiceCenterHeader';
import SubServiceCenterSideBar from '../ServiceCenterSidebarComponent/SubServiceCenterSidebar';
import SubServiceCenterSideBarTwo from '../ServiceCenterSidebarComponent/SubServiceCenterSideBarTwo';


function SubServiceCenter() {

  const dispatch = useDispatch();
  const [active, setActive] = useState(1);
  const [searchtext, setSearchText] = useState(false);
  const [searchData, setSearchData] = useState(null);
  let roleId = JSON.parse(localStorage.getItem('userRoleId'));

  const changeActive = active => {
    setActive(active)
    dispatch(setActiveInFilters(active))
  }

  const isSearch = isSearch => {
    setSearchText(isSearch);
  }

  const searchServiceCenterData = searchData => {
    setSearchData(searchData);
  }

  useEffect(() => {
    document.getElementById('root').style.background = '#F5FBFB';
    document.querySelector('body').style.background = '#F5FBFB';
    dispatch(clearSubServiceCenterEmpData());
    dispatch(clearEditEmpData());
    dispatch(clearComplaintData());
    dispatch(clearEditedComplaintResponse())

    return () => {
      document.getElementById('root').style.background = '#121212';
      document.querySelector('body').style.background = '#121212';
    }
  }, [])
  let userRoleId = localStorage.getItem('userRoleId');
  if (!['9', '10'].includes(userRoleId)){
    const style = {
      fontSize:"24px",
      color:"#000",
      textAlign:"center",
      fontWeight:"500",
      paddingTop:"5%"
    }
    return( <AccessRestricted style={style}/>)
  }
  else{
    return (
      <div className='serviceCenter clearfix'>
          <ServiceCenterHeader searchServiceCenterData={searchServiceCenterData} isSearch={isSearch}/>
          <div className='sc__main-content'>
             {roleId === 9 ? <SubServiceCenterSideBar changeActive={changeActive} searchtext={searchtext} setSearchText={setSearchText}/> : <SubServiceCenterSideBarTwo changeActive={changeActive} searchtext={searchtext} setSearchText={setSearchText}/> }
             {
              searchtext == false ?
              <div className="mainContentFiles">
                {
                  active === 1 ?  <ActiveComplaints /> : active === 2 ? <ReplacementComplaints />
                  : active === 3 ? <ClosedComplaints /> : <ManageUsers />
                }
              </div>
              :
              <div>
                <SearchResult searchData={searchData}/>
              </div>
             }
          </div>
      </div>
    )
  }
}

export default SubServiceCenter