// Main Service Center (User ID 8)
import React, { useEffect, useState } from 'react';
import './ServiceCenter.css';
// import Loader from 'react-loader-spinner';
import AccessRestricted from '../AccessRestricted';
import ServiceCenterHeader from '../ServiceCenterHeader/ServiceCenterHeader';
import ServiceCenterSidebar from '../ServiceCenterSidebarComponent/ServiceCenterSidebar';
import AllComplaints from './AllComplaints';
import ActiveComplaints from './ActiveComplaints';
import PausedComplaints from './PausedComplaints';
import ClosedComplaints from './ClosedComplaints'
import ManageUsers from './ManageUsers';
import SearchResult from './SearchResult';
import { useDispatch, } from 'react-redux';
import { clearMainServiceCenterData, clearEditServiceCenterData, setActiveInFilters } from '../../actions/serviceCenterActions'
import {clearEditedComplaintResponse, clearComplaintData} from '../../actions/serviceCenterComplaintAction'

function ServiceCenter() {
  // const initialValues={
  //   searchtext: true
  // }

  const dispatch = useDispatch();
  const [active, setActive] = useState(1);
  const [searchtext, setSearchText] = useState(false);
  const [searchData, setSearchData] = useState(null);

  let roleId = JSON.parse(localStorage.getItem('userRoleId'));

  const changeActive = active => {
    setActive(active);
    dispatch(setActiveInFilters(active))
  }
  // const changeSearchText = searchtext => {
  //   setSearchText(searchtext);
  // }

  const isSearch = isSearch => {
    setSearchText(isSearch);
  }

  const searchServiceCenterData = searchData => {
    setSearchData(searchData);
  }

  useEffect(() => {
    document.getElementById('root').style.background = '#F5FBFB';
    document.querySelector('body').style.background = '#F5FBFB';
    dispatch(clearMainServiceCenterData())
    dispatch(clearEditServiceCenterData())
    dispatch(clearComplaintData());
    dispatch(clearEditedComplaintResponse())
    return () => {
      document.getElementById('root').style.background = '#121212';
      document.querySelector('body').style.background = '#121212';
    }
  }, [])

  if (roleId !== 8) {
    const style = {
      fontSize: "24px",
      color: "#000",
      textAlign: "center",
      fontWeight: "500",
      paddingTop: "5%"
    }
    return <AccessRestricted style={style} />
  }

  return (
    <div className='serviceCenter clearfix'>
      <ServiceCenterHeader searchServiceCenterData={searchServiceCenterData} isSearch={isSearch} />
      <div className='sc__main-content'>
        <ServiceCenterSidebar changeActive={changeActive} searchtext={searchtext} setSearchText={setSearchText} />
        {
          searchtext === false ?
            <div className="mainContentFiles">
              {
                active === 1 ? <AllComplaints /> : active === 2 ? <ActiveComplaints /> : active === 3 ? <PausedComplaints />
                  : active === 4 ? <ClosedComplaints /> : <ManageUsers setActive={setActive} />
              }
            </div>
            :
            <div>
              <SearchResult searchData={searchData} />
            </div>
        }
      </div>
    </div>
  )
}

export default ServiceCenter;