import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './styles.css';
import CustomerDetails from './CustomerDetails';
import ProductDetails from './ProductDetails';
import ReviewDetails from './ReviewDetails';
import AccessRestricted from '../AccessRestricted';
import { setCustomerDetails } from '../../actions/serviceCenterComplaintAction';

import { useHistory } from 'react-router-dom';


function ComplaintRegistration() {
    const dispatch = useDispatch();
    const [step, setStep] = useState(1); //step should always be initialised with 1

    useEffect(() => {
        document.getElementById('root').style.background = '#F5FBFB';
        document.querySelector('body').style.background = '#F5FBFB';

        return () => {
            document.getElementById('root').style.background = '#121212';
            document.querySelector('body').style.background = '#121212';
        };
    }, []);


    const history = useHistory();
    const role = localStorage.getItem('userRoleId');
    function handleBackButton() {
        if (role == 8)
            history.push('/main-service-center');

        if (role == 9)
            history.push('/sub-service-center');

        if (role == 10)
            history.push('/sub-service-center');
        dispatch(setCustomerDetails({}));

    }

    const nextStep = step => {
        setStep(step + 1);
    };

    const prevStep = step => {
        setStep(step - 1);
    };

    let userRoleId = localStorage.getItem('userRoleId');
    if (!['8', '9', '10'].includes(userRoleId)) {
        return (<AccessRestricted />);
    }
    else {
        return (
            <React.Fragment>
                <div className='header pd headerNew'>
                    <div className="backHome" onClick={handleBackButton}>
                        <i className='fas fa-arrow-left'></i> Back to complaints
                    </div>
                    <div className="navigationWrapper">
                        <div className="navigation">
                            <div className="navigationName" id={step !== 2 ? "done" : ''}>
                                {step === 2 || step === 3 ?
                                    <span className={"done"}><i className="fa fa-check" aria-hidden="true"></i></span>
                                    :
                                    <span style={step === 1 ? { "color": "white", "background": "#307FE2" } : { "color": "307FE2" }}>1</span>
                                } Customer Details
                            </div>
                            <div className='nextArrow'><i className='fas fa-angle-right'></i></div>
                            <div className="navigationName" id={step === 2 || step === 3 ? "done" : ''}>
                                {step === 3 ?
                                    <span className="done"><i className="fa fa-check" aria-hidden="true"></i></span>
                                    :
                                    <span style={step === 2 ? { "color": "white", "background": "#307FE2" } : { "color": "#307FE2" }}>2</span>
                                }
                                Product Details
                            </div>
                            <div className='nextArrow'><i className='fas fa-angle-right'></i></div>
                            <div className="navigationName" id={step === 3 ? "done" : ''}>
                                {step === 3 ?
                                    <span className="done"><i className="fa fa-check" aria-hidden="true"></i></span>
                                    :
                                    <span>3</span>
                                }  Review
                            </div>
                        </div>
                    </div>
                </div>
                <div className='main-container main-container-new'>
                    <div className='complaintForm'>
                        {step === 1 ? <CustomerDetails nextStep={nextStep} /> : step === 2 ? <ProductDetails nextStep={nextStep} prevStep={prevStep} /> : <ReviewDetails prevStep={prevStep} />}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ComplaintRegistration;