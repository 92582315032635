import {
  SAVE_CUSTOMER_DATA, SAVE_PRODUCT_DATA, SAVE_COMPLAINT_DATA, CLEAR_COMPLAINT_DATA, CHANGE_COMPLAINT_STATUS,
  FETCH_COMPLAINT_DETAILS, CLEAR_EDITED_COMPLAINT_RES, SHOW_TROUBLESHOOT_ACTION, FETCH_SUB_PROBLEM_ID,
} from './types';
import axios from 'axios';
import { baseUrl } from '../config';
import history from '../history';

export const setCustomerDetails = (data) => async dispatch => {
  dispatch({
    type: SAVE_CUSTOMER_DATA,
    payload: data
  });
};

export const setProductDetails = (data) => async dispatch => {
  dispatch({
    type: SAVE_PRODUCT_DATA,
    payload: data
  });
};

export const setComplaintData = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/sc-complaint/add', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      dispatch({
        type: SAVE_COMPLAINT_DATA,
        payload: res.data
      });
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401)
        history.push('/');
    });
};

export const clearComplaintData = (data) => async dispatch => {
  dispatch({
    type: CLEAR_COMPLAINT_DATA,
    payload: data
  });
};

export const changeComplaintStatus = data => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/sc-complaint/edit', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      dispatch({
        type: CHANGE_COMPLAINT_STATUS,
        payload: res.data
      });
      alert(res.data.message);
    })
    .catch((e) => {
      if (e.response.status === 401)
        history.push('/');
    });
};

export const clearEditedComplaintResponse = () => async dispatch => {
  dispatch({
    type: CLEAR_EDITED_COMPLAINT_RES
  });
};

export const fetchComplaintDetails = (id) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.get(baseUrl + '/sc-complaint/get/' + id, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: FETCH_COMPLAINT_DETAILS,
          payload: res.data,
        });
      } else {
        alert(res.data.message);
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/');
      }
    });
};

export const TroubleShootShow = (flag) => async dispatch => {
  dispatch({
    type: SHOW_TROUBLESHOOT_ACTION,
    payload: flag
  });
};

export const setSubProblemID = (ID) => async dispatch => {
  dispatch({
    type: FETCH_SUB_PROBLEM_ID,
    payload: ID
  });
};