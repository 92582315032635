import React, { Component } from "react";
import "./ProductComponent.css";
import { connect } from "react-redux";
import history from "../../history";
// import moment from 'moment'
// import Loader from 'react-loader-spinner'
import {
  getGroupData,
  createProductGroup,
  removeGroupData,
  addChannels,
  updateChannels
} from "../../actions/productActions";
import ViewProfile from "../ViewProfile";
import ComplaintRegistration from "../ComplaintRegistration";
import AccessRestricted from "../AccessRestricted";
import Footer from "../FooterComponent/footer";
import SidebarComponent from "../SidebarComponent/SidebarComponent";
import { getChannelList } from "../../actions/complaintsDataAction";
import ReactModal from "react-modal";
const customStylesDO = {
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    inset: "8% 0 0 auto",
    marginRight: "0",
    transform: "translate(0)",
    background: "#000",
    padding: "2rem",
    border: "none",
    width: "35%",
    position: "absolute",
  },
};

class ChannelsComponent extends Component {
  constructor() {
    super();
    this.state = {
      channelsList: [],
      showchannelModal: false,
      channelName: "",
      errors: "",
      loading: false,
      channel_id: "",
      isEdit: false,
    };
  }

  componentDidMount() {
    this.getchannelsList();
  }

  getchannelsList = async () => {
    let data = {
      limit: Number(this.state.pageLimit),
      filterText: this.state.searchText,
      offset: Number(this.state.pageNo),
    };
    this.setState({ isLoading: true, channelsList: [],isEdit:false });
    await this.props.getGroupData(data);
    let response = await this.props.getChannelList();
    this.setState({ channelsList: response });
  };

  capitalizeFirstLetters(str) {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  handleAddChannel = async () => {
    if (this.state.channelName) {
      let data = {
        channel_name: this.capitalizeFirstLetters(this.state.channelName),
      };
      this.setState({ loading: true });
      let response;
      if (this.state.isEdit) {
        data.channel_id = this.state.channel_id
        response = await this.props.updateChannels(data);
      } else {
        response = await this.props.addChannels(data);
      }
      if (response?.success) {
        alert(response.message);
        this.getchannelsList();
        if (response.message != "Channel is already present") {
          this.setState({ channelName: "", showchannelModal: false,isEdit:false });
        }
      } else {
        alert("Something went wrong");
      }
    } else {
      this.setState({ errors: "Please enter channel name" });
    }
    this.setState({ loading: false });
  };

  render() {
    let userRoleId = localStorage.getItem("userRoleId");
    if (
      userRoleId === "4" ||
      userRoleId === "8" ||
      userRoleId === "9" ||
      userRoleId === "10"
    ) {
      return <AccessRestricted />;
    } else if (userRoleId === "6") {
      return <ComplaintRegistration />;
    } else {
      return (
        <div className="product-group group-component-main">
          <ViewProfile />
        { window.innerWidth > 600 &&  <SidebarComponent sideProp={"MP"} />}
          <div className="content">
            <div className="search_header_product">
              <span>Product</span>
              <div className="search" style={{ marginRight: "unset" }}>
                <i className="fa fa-search"></i>
                <input
                  type="username"
                  style={{ width: "230px" }}
                  name="searchByusername"
                  placeholder=" Search By Product Group"
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <ul id="menu">
              <li>
                <a href="/manage-product" className="noactive">
                  Product Info
                </a>
              </li>
              <li>
                <a href="/manage-group">Product Group</a>
              </li>
              <li>
                <a href="/manage-message" className="noactive">
                  Message
                </a>
              </li>
              <li className="actice2">
                <a href="/channels" className="noactive">
                  Channels
                </a>
              </li>
            </ul>
          </div>
          <div className="colrow grp">
            <div className="button-channels">
              <button
                onClick={() => this.setState({ showchannelModal: true })}
                type="button"
                data-toggle="modal"
                data-target="#exampleModal"
                className="btn-add-grp">
                Add Channels
              </button>
            </div>
            <div className="row customrow" style={{ marginTop: "0px" }}>
              <div className="col-sm" style={{ color: "#eb951f" }}>
                Channels
              </div>
            </div>

            {this.state.channelsList?.length > 0 ? (
              this.state.channelsList.map((item) => {
                return (
                  <div key={item.id} className="row customrow">
                    <div className="col channel-edit">
                      <p>{item.name ? item.name : "NA"}</p>
                      <i
                        onClick={() =>
                          this.setState({
                            showchannelModal: true,
                            channelName: item.name,
                            channel_id: item.id,
                            isEdit: true,
                          })
                        }
                        style={{ marginRight: "10px" }}
                        className="fas fa-pencil-alt"></i>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="no-result-text">
                <p>No result found</p>
              </div>
            )}
          </div>
          <ReactModal
            isOpen={this.state.showchannelModal}
            contentLabel="Minimal Modal Example"
            style={customStylesDO}
            ariaHideApp={false}>
            <div className="modal-head-product">
              <h2 id="rModal_head">Add channel name</h2>
              <i
                className="fa fa-times"
                aria-hidden="true"
                onClick={() => this.setState({ showchannelModal: false })}></i>
            </div>

            <div className="add-product-color" style={{ marginTop: "8%" }}>
              <div className="form-group add-color">
                <label>Channel Name</label>
                <input
                  type="text"
                  value={this.state.channelName}
                  className="form-control inputclass"
                  name="subject"
                  disabled={this.state.loading}
                  onChange={(e) =>
                    this.setState({ channelName: e.target.value })
                  }
                  placeholder="Please enter channel name"
                  required
                />
                <div className="errorpd">
                  <span className="errorShow">{this.state.errors}</span>
                </div>
              </div>
              <div className="addbtn">
                <button
                  disabled={this.state.loading}
                  style={{ marginLeft: "1%" }}
                  onClick={this.handleAddChannel}>
                  {this.state.loading ? "Please wait" : "Submit"}
                </button>
              </div>
            </div>
          </ReactModal>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  groupData: state.product.groupData,
  groupResponse: state.product.groupData,
});

export default connect(mapStateToProps, {
  getGroupData,
  createProductGroup,
  removeGroupData,
  getChannelList,
  addChannels,
  updateChannels,
})(ChannelsComponent);
