import React, { Component } from 'react';
import './ComplaintRegistration.css';
import { connect } from "react-redux";
import { getGroupData, getProductNameData } from "../actions/productActions";
import { getProductProblemList, getStateList, getCityByState, getSubProblemList } from "../actions/complaintsDataAction";
import { addDistributorComplaint } from "../actions/distributorAction";
import Select from 'react-select';
import ReactModal from 'react-modal';
import moment from 'moment';

const styleEdit = {
    overlay: {
        background: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
        inset: '0 0 0 auto',
        marginRight: '0',
        transform: 'translate(0)',
        background: '#fff',
        padding: '2% 3%',
        border: 'none',
        height: '100%',
        overflow: 'hidden',
        overflowY: 'scroll',
        position: 'fixed',
        width: '45%',
        float: 'right',
        productName: '',
        isLoadingGif: false,
        distributorComplainDetails: '',
    },
};

class RTLProductDetails extends Component {
    constructor() {
        super();
        this.state = {
            categoryList: [],
            editcategoryList: [],
            selectedCategory: [],
            newSelectedCategory: [],
            selectedCategoryId: 0,
            productList: [],
            selectedProduct: [],
            newSelectedProduct: [],
            errors: [],
            today: '',
            purchaseDateRtl: '',
            problemList: [],
            problemListData: [],
            productListData: [],
            problemId: '',
            formValues: [{ product_id: "", product_problem_id: "", quantity: "", purchase_date: "", problem_description: "", product_name: "", category_name: "", sub_product_problem_id: "", sub_issue_name: '' }],
            selectedProductId: '',
            quantity: [],
            purchaseDate: [],
            selectedIssue: [],
            addIndex: 0,
            showProductDetails: true,
            showShippingForm: false,
            phaseOneComplete: false,
            address: '',
            landmark: '',
            city_id: '',
            state_id: '',
            pincode: '',
            isDefaultAddress: false,
            stateList: [],
            cityList: [],
            phaseTwoComplete: false,
            productId: [],
            description: [],
            newDescription: '',
            shippingData: {},
            stateName: '',
            cityName: '',
            minDate: '',
            showThankyou: false,
            invalidDate: false,
            showEdit: false,
            newCategoryName: [],
            categoryName: [],
            newSelectedProductLabel: [],
            selectedProductLabel: [],
            newSelectedProductId: [],
            newquantity: '',
            test: false,
            tempIndex: 0,
            newSelectedIssue: [],
            newIssueId: [],
            newIssueName: [],
            newPurchaseDate: '',
            mobile: '',
            email: '',
            showDescription: false,
            subProblemList: [],
            subProblemListData: [],
            selectedSubIssue: [],
            newSelectedSubIssue: [],
        };
        this.submitProductDetails = this.submitProductDetails.bind(this);
    }

    componentDidMount = async () => {
        this.disable(this.state.addIndex);
        await this.props.getGroupData();
        let categories = this.props.groupData.data;
        this.setState({ categoryList: categories, editcategoryList: categories });
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        today = dd + '-' + mm + '-' + yyyy;
        this.setState({ today: today });
        var minDate = new Date();
        minDate.setDate(minDate.getDate() - 90);
        var mindd = minDate.getDate();
        var minmm = minDate.getMonth() + 1;
        var minyy = minDate.getFullYear();
        if (mindd < 10) {
            mindd = '0' + mindd;
        }
        if (minmm < 10) {
            minmm = '0' + minmm;
        }
        minDate = mindd + '-' + minmm + '-' + minyy;
        this.setState({ minDate: minDate });
        let address = localStorage.getItem('address');
        if (address && address !== '') {
            address = JSON.parse(address);
            this.setState({
                address: address.address,
                landmark: address.address_2,
                city_id: address.city_id,
                state_id: address.state_id,
                pincode: address.pincode,
                stateName: address.state_name,
                cityName: address.city,
                mobile: address.mobile,
                email: address.email,
            });
            this.getCityListApi();
        }
    };

    componentWillUnmount() {
        document.getElementById("root").style.background = "#121212";
        document.querySelector("body").style.background = "#121212";
    }

    disable = (index) => {
        let lineItem = ".line" + index;
        let classProduct = document.querySelector(lineItem + " .select-product-rtl input");
        if (classProduct) {
            classProduct.setAttribute("disabled", "true");
        }
        let classProductDropdown = document.querySelector(lineItem + " .select-product-rtl .css-1hb7zxy-IndicatorsContainer");
        if (classProductDropdown) {
            classProductDropdown.style.display = "none";
        }
        let classIssue = document.querySelector(lineItem + " .select-issue-rtl input");
        if (classIssue) {
            classIssue.setAttribute("disabled", "true");
        }
        let classIssueDropdown = document.querySelector(lineItem + " .select-issue-rtl .css-1hb7zxy-IndicatorsContainer");
        if (classIssueDropdown) {
            classIssueDropdown.style.display = "none";
        }

        let classSubIssue = document.querySelector(lineItem + " .select-sub-issue-rtl input");
        if (classSubIssue) {
            classSubIssue.setAttribute("disabled", "true");
        }
        let classSubIssueDropdown = document.querySelector(lineItem + " .select-sub-issue-rtl .css-1hb7zxy-IndicatorsContainer");
        if (classSubIssueDropdown) {
            classSubIssueDropdown.style.display = "none";
        }

    };

    enable = (index) => {
        let lineItem = ".line" + index;
        let classProduct = document.querySelector(lineItem + " .select-product-rtl input");
        if (classProduct) {
            classProduct.removeAttribute("disabled");
        }
        let classProductDropdown = document.querySelector(lineItem + " .select-product-rtl .css-1hb7zxy-IndicatorsContainer");
        if (classProductDropdown) {
            classProductDropdown.style.display = "block";
        }
        let classIssue = document.querySelector(lineItem + " .select-issue-rtl input");
        if (classIssue) {
            classIssue.removeAttribute("disabled");
        }
        let classIssueDropdown = document.querySelector(lineItem + " .select-issue-rtl .css-1hb7zxy-IndicatorsContainer");
        if (classIssueDropdown) {
            classIssueDropdown.style.display = "block";
        }

        let classSubIssue = document.querySelector(lineItem + " .select-sub-issue-rtl input");
        if (classSubIssue) {
            classSubIssue.removeAttribute("disabled");
        }
        let classSubIssueDropdown = document.querySelector(lineItem + " .select-sub-issue-rtl .css-1hb7zxy-IndicatorsContainer");
        if (classSubIssueDropdown) {
            classSubIssueDropdown.style.display = "block";
        }
    };

    handleOldFieldValidation() {
        let errors = {};
        let flag = true;
        // let index = this.state.addIndex;
        if (this.state.formValues.length > 0) {
            for (let i = 0; i < this.state.formValues.length; i++) {
                if (this.state.formValues[i].category_name === '') {
                    flag = false;
                    errors["category_name" + i] = "Category can't be empty!";
                }
                if (this.state.formValues[i].product_id === '') {
                    flag = false;
                    errors["product_name" + i] = "Product can't be empty!";
                }

                if (this.state.formValues[i].product_problem_id === '') {
                    flag = false;
                    errors["issue" + i] = "Issue can't be empty!";
                    if (this.state.formValues[i].sub_product_problem_id === '') {
                        flag = false;
                        errors["subIssue" + i] = " Sub issue can't be empty!";
                    }
                }
                else {
                    if (this.state.formValues[i].issue_name !== "Issue not listed") {
                        if (this.state.formValues[i].sub_product_problem_id === '') {
                            flag = false;
                            errors["subIssue" + i] = " Sub issue can't be empty!";
                        }
                    }
                }

                if (this.state.formValues[i].problem_description === '') {
                    flag = false;
                    errors["problemDescription" + i] = "Description can't be empty!";
                }


                if (this.state.formValues[i].quantity === '') {
                    flag = false;
                    errors["quantity" + i] = "Quantity can't be empty!";
                }
                let quantity = this.state.quantity;
                if (this.state.formValues[i].quantity !== '') {
                    if (this.state.formValues[i].quantity === 0) {
                        flag = false;
                        errors["quantity" + i] = "Quantity can't be 0!";
                    }
                    var pattern = new RegExp(/[^0-9]/);
                    if (pattern.test(this.state.formValues[i].quantity)) {
                        errors["quantity" + i] = "Quantity can only be number";
                        quantity[i] = "";
                        flag = false;
                        this.setState({ quantity });
                    }
                }
                if (this.state.formValues[i].purchase_date === '') {
                    flag = false;
                    errors["purchaseDate" + i] = "Date can't be empty!";
                }
                if (this.state.formValues[i].purchase_date !== '') {
                    let minDate = new Date();
                    minDate.setDate(minDate.getDate() - 90);
                    let input = this.state.formValues[i].purchase_date;
                    let formatinput = new Date(input);
                    let diff = (formatinput - minDate);
                    diff = Math.floor(diff / (1000 * 60 * 60 * 24));
                    let currentDate = new Date();
                    let diff2 = (currentDate - formatinput);
                    if (diff2 <= 0) {//future date	
                        flag = false;
                        errors["purchaseDate" + i] = "Purchase date cannot be future date.";
                    }
                    if (diff <= 0) {//check from min date 3 months	
                        flag = false;
                        errors["purchaseDate" + i] = "Purchase date cannot be before 3 months from current date.";
                    }
                }
            }
        }
        this.setState({ errors });
        return flag;
    }

    handleChangeDate(i, e) {
        let formValues = this.state.formValues;
        let purchaseDate = this.state.purchaseDate;
        formValues[i]["purchase_date"] = e.target.value;
        purchaseDate[i] = e.target.value;
        this.setState({ purchaseDate, formValues });

    }

    addFormFields() {
        if (this.handleOldFieldValidation() !== false) {
            let index = this.state.addIndex + 1;
            this.setState({ addIndex: index }, () => {
                this.disable(this.state.addIndex);
            });
            this.setState({
                formValues: [...this.state.formValues, { product_id: "", product_problem_id: "", quantity: "", purchase_date: "", problem_description: "", product_name: "", category_name: "", sub_product_problem_id: '', sub_issue_name: '' }]
            });
        }
    }

    removeFormFields(i) {
        let formValues = this.state.formValues;
        formValues.splice(i, 1);
        let selectedProduct = this.state.selectedProduct;
        selectedProduct.splice(i, 1);
        let selectedCategory = this.state.selectedCategory;
        selectedCategory.splice(i, 1);
        let selectedIssue = this.state.selectedIssue;
        selectedIssue.splice(i, 1);
        let selectedSubIssue = this.state.selectedSubIssue;
        selectedSubIssue.splice(i, 1);
        let quantity = this.state.quantity;
        quantity[i] = "";
        let description = this.state.description;
        description[i] = "";
        let purchaseDate = this.state.purchaseDate;
        purchaseDate[i] = "";
        this.setState({ formValues, addIndex: (this.state.addIndex - 1), description, selectedProduct, selectedCategory, selectedIssue, quantity, purchaseDate });
    }

    handleCategorySelection = async (i, a) => {
        this.enable(i);
        let selectedCategory = this.state.selectedCategory;
        selectedCategory[i] = a;
        let formValues = this.state.formValues;
        formValues[i]["category_name"] = a.label;
        this.setState({ selectedCategory, selectedCategoryId: a.value, categoryName: a.label, formValues });
        // if (a.value != '') {
        let selectedProduct = this.state.selectedProduct;
        selectedProduct[i] = '';
        let selectedIssue = this.state.selectedIssue;
        selectedIssue[i] = "";
        let selectedSubIssue = this.state.selectedSubIssue;
        selectedSubIssue[i] = "";
        formValues[i]["product_id"] = '';
        formValues[i]["product_name"] = '';
        this.setState({ selectedProduct, selectedIssue, formValues, selectedSubIssue });
        // }
        let data = {
            "product_group_id": a.value,
            "isReport": true,
        };
        await this.props.getProductNameData(data);
        if (this.props.productNameList) {
            let productNameList = this.props.productNameList.data.products;
            let newproductListData = [];
            let productListData = this.state.productListData;

            if (productNameList) {
                productNameList.map((obj) => (
                    newproductListData.push({

                        value: obj.product_color_id,
                        label: obj.id == 239 ? obj.product_name + "(" + "Size " + obj.size + "|" + obj.color_name + ")" : obj.product_name + "(" + obj.color_name + ")",

                    })
                ));
            };
            (productListData[i] != undefined) && productListData.splice(i, 1);
            productListData.splice(i, 0, newproductListData);
            this.setState({ productList: productNameList, productListData: productListData });
        }
        await this.props.getProductProblemList({ "category_id": a.value });
        let problemList = this.props.problemData.problems;
        this.setState({ problemList: problemList, });

    };

    handleProductSelection = (i, a) => {
        let formValues = this.state.formValues;
        formValues[i]["product_id"] = a.value;
        formValues[i]["product_name"] = a.label;
        this.setState({ formValues });
        if (this.state.selectedCategory === '') {
            alert("Please select category first!");
        } else {
            let selectedProduct = this.state.selectedProduct;
            selectedProduct[i] = a;
            this.setState({ selectedProduct, selectedProductId: a.value, selectedProductLabel: a.label });
        }
    };

    disableSubIssue = (index) => {
        let idName = "#subIssue" + index;
        let id = document.querySelector(idName + " input");
        if (id) {
            id.setAttribute("disabled", "true");
        }
        let dropDown = document.querySelector(idName + " .css-1xc3v61-indicatorContainer");
        if (dropDown) {
            dropDown.style.display = "none";
        }
    };

    enableSubIssue = (index) => {
        let idName = "#subIssue" + index;
        let id = document.querySelector(idName + " input");
        if (id) {
            id.removeAttribute("disabled");
        }
        let dropDown = document.querySelector(idName + " .css-1xc3v61-indicatorContainer");
        if (dropDown) {
            dropDown.style.display = "block";
        }
    };


    handleProductIssue = async (i, a) => {
        const { formValues } = this.state;
        const updatedformValues = [...formValues];

        if (a.label == "Issue not listed") {
            this.disableSubIssue(i);
            this.setState({ formValues: updatedformValues, subProblemList: [] });
        } else {
            this.enableSubIssue(i);
            this.setState({ formValues: updatedformValues });
            let data = {
                "problem_id": [a.value]
            };
            await this.props.getSubProblemList(data);
            let subProblemList = this.props.subproblemData.sub_problems;
            let newSubPRobelmListData = [];
            let subProblemListData = this.state.subProblemListData;

            if (subProblemList) {
                subProblemList.map((obj) => (
                    newSubPRobelmListData.push({
                        value: obj.id,
                        label: obj.sub_problem,
                    })
                ));
            };
            (subProblemListData[i] != undefined) && subProblemListData.splice(i, 1);
            subProblemListData.splice(i, 0, newSubPRobelmListData);
            this.setState({ subProblemListData: subProblemListData });
            if (subProblemList) {
                subProblemList = subProblemList.sub_problems;
                this.setState({ subProblemList });
            }
            else {
                this.setState({ subProblemList: [] });
            }
        }
        formValues[i]["product_problem_id"] = a.value;
        formValues[i]["issue_name"] = a.label;
        let selectedIssue = this.state.selectedIssue;
        selectedIssue[i] = a;
        let selectedSubIssue = this.state.selectedSubIssue;
        selectedSubIssue[i] = "";
        this.setState({ formValues, selectedIssue, selectedSubIssue });
    };

    handleSubIssuse = (i, a) => {
        let formValues = this.state.formValues;
        formValues[i]["sub_product_problem_id"] = a.value;
        formValues[i]["sub_issue_name"] = a.label;
        let selectedSubIssue = this.state.selectedSubIssue;
        selectedSubIssue[i] = a;
        this.setState({ formValues, selectedSubIssue });

    };

    handleChangeQuantity(i, e) {
        let quantity = this.state.quantity;
        let formValues = this.state.formValues;
        quantity[i] = e.target.value;
        formValues[i]["quantity"] = e.target.value;
        this.setState({ quantity, formValues });
    }

    handleChangeDesc(i, e) {
        // let description = this.state.description	
        // description[i] = e.target.value;	
        let formValues = this.state.formValues;
        formValues[i]["problem_description"] = e.target.value;
        this.setState({ formValues, description: e.target.value });
    }

    editFormFields = async (i) => {
        let tempIndex = i;
        this.setState({ test: true, showEdit: true, tempIndex });
        let newDescription = this.state.formValues[i].problem_description;
        let newPurchaseDate = this.state.formValues[i].purchase_date;
        let newquantity = this.state.formValues[i].quantity;
        let newSelectedCategory = [];
        newSelectedCategory = this.state.selectedCategory[i];
        let newCategoryName = [];
        newCategoryName[i] = this.state.formValues[i].category_name;
        let newSelectedProduct = [];
        newSelectedProduct = this.state.selectedProduct[i];
        let newSelectedProductLabel = [];
        newSelectedProductLabel[i] = this.state.formValues[i].product_name;
        let newSelectedProductId = [];
        newSelectedProductId[i] = this.state.formValues[i].product_id;
        let newSelectedIssue = [];
        newSelectedIssue[i] = this.state.selectedIssue[i];
        // if (newSelectedIssue.label == "Issue not listed") {
        //     this.setState({ showDescription: true });
        // } else {
        //     this.setState({ showDescription: false });
        // }
        let newSelectedSubIssue = [];
        newSelectedSubIssue[i] = this.state.selectedSubIssue[i];
        let data = {
            "product_group_id": newSelectedCategory.value,
            "isReport": true,
        };

        await this.props.getProductNameData(data);
        if (this.props.productNameList) {
            let productNameList = this.props.productNameList.data.products;

            this.setState({ productList: productNameList });
        }
        let body = {
            category_id: newSelectedCategory.value
        };
        await this.props.getProductProblemList(body);
        let problemList = this.props.problemData.problems;
        let Subdata = {
            "problem_id": newSelectedIssue[i].value
        };
        await this.props.getSubProblemList(Subdata);
        let subProblemList = this.props.subproblemData;
        if (subProblemList) {
            subProblemList = subProblemList.sub_problems;
        }
        else {
            subProblemList = [];
        }

        this.setState({ problemList: problemList, subProblemList });
        this.setState({ newDescription, newSelectedCategory, newSelectedProduct, newSelectedProductLabel, newSelectedProductId, newCategoryName, newquantity, newSelectedIssue, newSelectedSubIssue, newPurchaseDate });
    };

    handleEditCategory = async (i, a) => {
        this.setState({ newSelectedCategory: a });
        // if (a.value != '') {
        // let newSelectedProduct = this.state.newSelectedProduct;
        // newSelectedProduct[i] = ''
        // let newSelectedIssue = this.state.newSelectedIssue;
        // newSelectedIssue[i] = "";
        this.setState({ newSelectedProduct: '', newSelectedIssue: '' });
        // }
        let data = {
            "product_group_id": a.value,
            "isReport": true,
        };
        await this.props.getProductNameData(data);
        if (this.props.productNameList) {
            let productNameList = this.props.productNameList.data.products;
            this.setState({ productList: productNameList });
        }
        await this.props.getProductProblemList(data);
        let problemList = this.props.problemData.problems;
        this.setState({ problemList: problemList });
    };

    handleEditProduct = (i, a) => {
        let errors = {};
        if (this.state.newSelectedCategory === '') {
            errors["productError"] = "Please select category first!";
            this.setState({ errors: errors });
        }
        this.setState({ newSelectedProduct: a });
        // let newSelectedProduct = this.state.selectedProduct;
        // newSelectedProduct[i] = a
        // let newProductLabel = a.label
        // this.setState({ newSelectedProduct, newSelectedProductId: a.value, newProductLabel }, () => {
        // })
    };

    handleEditIssue = async (i, a) => {
        if (a.label !== "Issue not listed") {
            let data = {
                "problem_id": [a.value]
            };
            await this.props.getSubProblemList(data);
            let subProblemList = this.props.subproblemData;
            if (subProblemList) {
                subProblemList = subProblemList.sub_problems;
            }
            this.setState({ subProblemList });
        }
        else {
            this.setState({ subProblemList: [] });
        }
        let errors = {};
        if (this.state.newSelectedIssue === '') {
            errors["issueError"] = "Please select issue!";
            this.setState({ errors: errors });
        }
        let newSelectedIssue = [];
        newSelectedIssue[i] = a;
        this.setState({ newSelectedIssue, newSelectedSubIssue: '' });

    };



    handleEditSubIssue = async (i, a) => {
        let errors = {};
        if (this.state.newSelectedSubIssue === '') {
            errors["subissueError"] = "Please select Sub issue!";
            this.setState({ errors: errors });
        }
        let newSelectedSubIssue = a;
        this.setState({ newSelectedSubIssue });
    };



    handleEditDate(i, e) {
        let newPurchaseDate = e.target.value;
        this.setState({ newPurchaseDate });
    }

    handleEditDesc = (i, e) => {
        let newDescription = e.target.value;
        this.setState({ newDescription });
    };

    handleEditQuantity(i, e) {
        let newquantity = e.target.value;
        this.setState({ newquantity });
    }

    handleEditValidation() {
        let errors = {};
        let flag = false;
        if (this.state.newSelectedCategory === '') {
            flag = true;
            errors["edit_category"] = "Please select category";
        }
        if (this.state.newSelectedProduct === '') {
            flag = true;
            errors["edit_product"] = "Please select product";
        }
        if (this.state.newquantity === '') {
            flag = true;
            errors["edit_quantity"] = "Can't be empty";
        }
        if (this.state.newquantity === '0') {
            flag = true;
            errors["edit_quantity"] = "Can't be 0";
        }
        if (this.state.newquantity !== '' && this.state.newquantity !== '0') {
            var pattern = new RegExp(/[^0-9]/);
            if (pattern.test(this.state.newquantity)) {
                flag = true;
                errors["edit_quantity"] = "Invalid format!";
            }
        }
        if (this.state.newSelectedIssue === '') {
            flag = true;
            errors["edit_issue"] = "Can't be empty";
        }
        if (this.state.newSelectedSubIssue && this.state.newSelectedSubIssue === '') {
            flag = true;
            errors["edit_sub_issue"] = "Can't be empty";
        }
        if (this.state.newPurchaseDate !== '') {
            let minDate = new Date();
            minDate.setDate(minDate.getDate() - 90);
            let input = this.state.newPurchaseDate;
            let formatinput = new Date(input);
            let diff = (formatinput - minDate);
            diff = Math.floor(diff / (1000 * 60 * 60 * 24));
            let currentDate = new Date();
            let diff2 = (currentDate - formatinput);
            if (diff2 <= 0) {
                flag = true;
                errors["edit_date"] = "Purchase date can't be future date.";
            }
            if (diff <= 0) {
                flag = true;
                errors["edit_date"] = "Can't select before 3 months";
            }
        }
        this.setState({ errors });
        return flag;
    }

    saveEditForm = (i, a) => {
        if (this.handleEditValidation() !== true) {
            let formValues = this.state.formValues;
            formValues[i]["category_name"] = this.state.newSelectedCategory.label;
            formValues[i]["product_id"] = this.state.newSelectedProduct.value;
            formValues[i]["product_name"] = this.state.newSelectedProduct.label;
            formValues[i]["quantity"] = this.state.newquantity;
            formValues[i]["purchase_date"] = this.state.newPurchaseDate;
            formValues[i]["product_problem_id"] = this.state.newSelectedIssue.value || this.state.formValues[0].product_problem_id;
            formValues[i]["sub_product_problem_id"] = this.state.newSelectedSubIssue.value || this.state.formValues[0].sub_product_problem_id;
            formValues[i]["issue_name"] = this.state.newSelectedIssue.label || this.state.formValues[0].issue_name;
            formValues[i]["sub_issue_name"] = this.state.newSelectedSubIssue.label || this.state.formValues[0].sub_issue_name;
            if (this.state.newDescription !== '') {
                formValues[i]["problem_description"] = this.state.newDescription;
            }
            let selectedCategory = this.state.selectedCategory;
            selectedCategory[i] = this.state.newSelectedCategory;
            let selectedProduct = this.state.selectedProduct;
            selectedProduct[i] = this.state.newSelectedProduct;
            let selectedIssue = this.state.selectedIssue;
            selectedIssue[i] = this.state.newSelectedIssue;
            let selectedSubIssue = this.state.selectedSubIssue;
            selectedSubIssue[i] = this.state.newSelectedSubIssue;
            this.setState({ formValues, showEdit: false, selectedCategory, selectedProduct, selectedIssue, selectedSubIssue });
        }
    };

    handleShippingInfo = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleCheckbox = () => {
        this.setState({ isDefaultAddress: !this.state.isDefaultAddress });
    };

    handleProductValidation = () => {
        let flag = true;
        let formValues = this.state.formValues;
        var prodId = formValues.filter((obj) => obj.product_id === '' || obj.product_problem_id === '' || obj.quantity === '' || obj.purchase_date === '');
        if (prodId.length > 0 || formValues.length < 1) {
            flag = false;
            alert("Some fields are incorrectly filled!");
        }
        if (this.state.invalidDate) {
            flag = false;
            alert("Invalid date!");
        }
        return flag;
    };

    submitProductDetails = () => {
        // if (this.handleProductValidation() == true) {
        if (this.handleOldFieldValidation() !== false) {
            this.setState({ showShippingForm: true, showProductDetails: false, phaseOneComplete: true }, async () => {
                await this.props.getStateList();
                let stateDataList = this.props.stateDataList;
                this.setState({
                    stateList: stateDataList.data,
                });
            });
        }
    };

    backToProductDetails = () => {
        this.setState({ showShippingForm: false, showProductDetails: true, phaseOneComplete: false, phaseTwoComplete: false, reviewForm: false });
    };

    backToShipping = () => {
        this.setState({ showShippingForm: true, showProductDetails: false, phaseOneComplete: true, phaseTwoComplete: false, reviewForm: false });
    };

    onStateChange = (e) => {
        this.setState({ stateName: e.target.options[e.target.value].text });
        // this.state.stateList.filter(state => state.id === e.target.value).map(stateName => (
        //     this.setState({ stateName: stateName.state_name })
        // ));
        this.setState({ country_id: 1, city_id: 0, state_id: Number(e.target.value) }, () => {
            this.getCityListApi();
        });
    };

    onCityChange = (e) => {
        // let filterValue = this.state.cityList.filter(city => city.id === e.target.value)=>
        // this.setState({ cityName: cityName.city })
        // return cityName.city
        // );
        this.setState({ city_id: e.target.value, cityName: e.target.selectedOptions[0].text }, () => {
            this.getCityListApi();
        });
    };

    getCityListApi = async () => {
        let data = {
            "state_id": this.state.state_id
        };
        await this.props.getCityByState(data);
        let cityDataList = this.props.cityDataList;
        this.setState({
            cityList: cityDataList.data,
        });
    };

    handleShippingValidation = () => {
        let errors = {};
        let flag = true;
        if (this.state.address === '') {
            flag = false;
            errors["address"] = "Please enter address";
        }
        if (this.state.landmark === '') {
            flag = false;
            errors["landmark"] = "Please enter landmark";
        }
        if (this.state.pincode === '') {
            flag = false;
            errors["pincode"] = "Please enter pincode";
        }
        if (this.state.pincode !== '') {
            var pattern = new RegExp(
                /^[1-9]\d{5}$/
            );
            if (!pattern.test(this.state.pincode)) {
                flag = false;
                errors["pincode"] = "Please valid format of pincode";
            }
        }
        if (this.state.state_id == 0) {
            flag = false;
            errors["state"] = "Please enter state";
        }
        if (this.state.city_id == 0) {
            flag = false;
            errors["city"] = "Please enter city";
        }
        if (this.state.mobile === '') {
            flag = false;
            errors["mobile"] = "Please Enter Mobile";
        }
        if (this.state.mobile !== '') {
            var pattern = new RegExp(/^[6-9]\d{9}$/);
            if (!pattern.test(this.state.mobile)) {
                flag = false;
                errors["mobile"] = "Please Enter Valid Number";
            }
        }
        if (this.state.email === '') {
            flag = false;
            errors["email"] = "Please Enter E-Mail";
        }
        if (this.state.email !== '') {
            var pattern = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
            if (!pattern.test(this.state.email)) {
                flag = false;
                errors["email"] = "Please Enter Valid Email";
            }
        }
        this.setState({ errors });
        return flag;
    };
    submitShippingDetails = () => {
        if (this.handleShippingValidation() === true) {
            let data = {
                "address": this.state.address,
                "address2": this.state.landmark,
                "pincode": this.state.pincode,
                "city_id": this.state.city_id,
                "state_id": this.state.state_id,
                "email": this.state.email,
                "mobile": this.state.mobile,
                "is_default_addr": this.state.isDefaultAddress === true ? 1 : 0
            };
            this.setState({ shippingData: data, showShippingForm: false, showProductDetails: false, phaseOneComplete: true, phaseTwoComplete: true, reviewForm: true });
        }
    };

    submitComplaint = async () => {
        if (this.state.formValues.length < 1) {
            alert("Please insert atleast one product for complaint");
        }
        else {
            let data = {
                "product": this.state.formValues,
                "address": this.state.address,
                "address2": this.state.landmark,
                "pincode": this.state.pincode,
                "city_id": this.state.city_id,
                "state_id": this.state.state_id,
                "mobile": this.state.mobile,
                "email": this.state.email,
                "is_default_addr": this.state.isDefaultAddress === true ? 1 : 0
            };
            await this.props.addDistributorComplaint(data);
            if (this.props.distributorTicketId) {
                alert("Thank you for registering your complaint!");
                if (this.state.isDefaultAddress === true) {
                    localStorage.setItem('address', JSON.stringify({
                        "address": this.state.address,
                        "address_2": this.state.landmark,
                        "state_id": this.state.state_id,
                        "city_id": this.state.city_id,
                        "pincode": this.state.pincode,
                        "state_name": this.state.stateName,
                        "city": this.state.cityName,
                        "mobile": this.state.mobile,
                        'email': this.state.email,
                    }));
                }
                this.setState({
                    showThankyou: true
                });
                this.showTrackingStatus();
            }
        }
    };

    showProductDetails = () => {
        this.setState({ showShippingForm: false, showProductDetails: true, phaseOneComplete: false });
    };

    showTrackingStatus = () => {
        window.location.reload();
    };

    closeEditModal = () => {
        this.setState({ showEdit: false, errors: '' });
    };



    render = () => {
        let categoryOptions = [];
        if (this.state.categoryList !== '') {
            this.state.categoryList.map((obj) => (
                categoryOptions.push(
                    {
                        value: obj.id,
                        label: obj.group_name,
                    }
                )
            ));
        }
        let editcategoryOptions = [];
        if (this.state.editcategoryList !== '') {
            this.state.editcategoryList.map((obj) => (
                editcategoryOptions.push(
                    {
                        value: obj.id,
                        label: obj.group_name,
                    }
                )
            ));
        }
        let productOptions = [];
        if (this.state.productList !== '') {
            this.state.productList.map((obj) => (
                productOptions.push(
                    {
                        value: obj.product_color_id,
                        label: obj.id == 239 ? obj.product_name + "(" + "Size " + obj.size + " | " + obj.color_name + ")" : obj.product_name + "(" + obj.color_name + ")",
                    }
                )
            ));
        }
        let problems = [];
        if (this.state.problemList !== '') {
            this.state.problemList.map((obj) => (
                problems.push(
                    {
                        value: obj.id,
                        label: obj.problem,
                    }
                )
            ));
        }
        let Subproblems = [];
        if (this.state.subProblemList) {
            this.state.subProblemList.map((obj) => (
                Subproblems.push(
                    {
                        value: obj.id,
                        label: obj.sub_problem,
                    }
                )
            ));

        }
        else {

        }

        return (
            <div className="complaintRegistration">
                <div className='header pd'>
                    <div className="backHome" onClick={this.showTrackingStatus}>
                        <i className='fas fa-arrow-left'></i> Back to home
                    </div>
                    <div className="navigationWrapper">
                        <div className="navigation">
                            <div className="navigationName" id={this.state.phaseOneComplete && "done"} onClick={this.backToProductDetails}>
                                {this.state.phaseOneComplete ?
                                    <span className="done"><i className="fa fa-check" aria-hidden="true"></i></span>
                                    :
                                    <span>1</span>
                                } Product Details
                            </div>
                            <div className='nextArrow'><i className='fas fa-angle-right'></i></div>
                            <div className="navigationName" id={this.state.phaseTwoComplete && "done"} onClick={this.state.reviewForm && this.backToShipping}>
                                {this.state.phaseTwoComplete ?
                                    <span className="done"><i className="fa fa-check" aria-hidden="true"></i></span>
                                    :
                                    <span>2</span>
                                }
                                Shipping Details
                            </div>
                            <div className='nextArrow'><i className='fas fa-angle-right'></i></div>
                            <div className="navigationName">
                                <span>3</span> Review
                            </div>
                        </div>
                    </div>
                    <div className="continueWrapper">
                        {this.state.showProductDetails &&
                            <button type="submit" className="continueShippingDetails" onClick={this.submitProductDetails}>CONTINUE</button>
                        }
                        {this.state.showShippingForm &&
                            <div>
                                <button type="submit" className="backToProductDetails" onClick={this.backToProductDetails}>BACK</button>
                                <button type="submit" className="confirmShipping" onClick={this.submitShippingDetails}>CONTINUE</button>
                            </div>
                        }
                        {this.state.reviewForm &&
                            <div>
                                <button type="submit" className="backToShipping" onClick={this.backToShipping}>BACK</button>
                                <button type="submit" className="confirmReview" onClick={this.submitComplaint}>SUBMIT</button>
                            </div>
                        }
                    </div>
                </div>
                {
                    this.state.showProductDetails &&
                    <div className="productDetailsWrapper">
                        <h2>New Complaint</h2>
                        <div className="productDetailsWrapperInside">
                            <h3>Product details</h3>
                            <p>{this.state.addIndex + 1} items are added</p>
                            {this.state.formValues.map((element, index) => (
                                <div className={"lineItemWrapper line" + index} key={index}>
                                    <div className='wrapper'>
                                        <label>Category</label>
                                        <Select
                                            name='category'
                                            onChange={e => this.handleCategorySelection(index, e)}
                                            value={this.state.selectedCategory[index]}
                                            options={categoryOptions}
                                            isSearchable={true}
                                            className="select-category-rtl"
                                            maxMenuHeight={200}
                                            placeholder="Search Category.."
                                        />
                                        <div className="error_add">
                                            <span className="errorShow">{this.state.errors["category_name" + index]}</span>
                                        </div>
                                    </div>
                                    <div className='wrapper'>
                                        <label className={"sdfdx" + index}>Product</label>
                                        <Select
                                            name="product_id"
                                            onChange={e => this.handleProductSelection(index, e)}
                                            value={this.state.selectedProduct[index]}
                                            options={this.state.productListData[index]}
                                            isSearchable={true}
                                            className="select-product-rtl"
                                            maxMenuHeight={200}
                                            placeholder="Search Product.."
                                        />
                                        <div className="error_add">
                                            <span className="errorShow">{this.state.errors["product_name" + index]}</span>
                                        </div>
                                    </div>
                                    <div className='wrapper'>
                                        <label>Quantity</label>
                                        <input type="number" name="quantity" className="itemQuantity"
                                            placeholder="Quantity.."
                                            value={element.quantity}
                                            onChange={e => this.handleChangeQuantity(index, e)} />
                                        <div className="error_add">
                                            <span className="errorShow">{this.state.errors["quantity" + index]}</span>
                                        </div>
                                    </div>
                                    <div className="wrapper">
                                        <label>Purchase date</label>
                                        <input name="date" type="date" className="date-complaint-rtl" placeholder={this.state.today} max={this.state.today} onChange={e => this.handleChangeDate(index, e)} value={element.purchase_date}></input>
                                        <div className="error_add">
                                            <span className="errorShow">{this.state.errors["purchaseDate" + index]}</span>
                                        </div>
                                    </div>
                                    <div className="wrapper">
                                        <label>Issue</label>
                                        <Select
                                            name="issue"
                                            onChange={e => this.handleProductIssue(index, e)}
                                            value={this.state.selectedIssue[index]}
                                            options={problems}
                                            isSearchable={true}
                                            className="select-issue-rtl"
                                            maxMenuHeight={200}
                                            placeholder="Search Issue.."
                                        />
                                        <div className="error_add">
                                            <span className="errorShow">{this.state.errors["issue" + index]}</span>
                                        </div>
                                    </div>

                                    <div className='wrapper'>
                                        <label className={"subIssue" + index}>Sub Issue</label>
                                        <Select
                                            name="product_id"
                                            id={"subIssue" + index}
                                            onChange={e => this.handleSubIssuse(index, e)}
                                            value={this.state.selectedSubIssue[index]}
                                            options={this.state.subProblemListData[index]}
                                            isSearchable={true}
                                            className="select-sub-issue-rtl"
                                            maxMenuHeight={200}
                                            placeholder="Search sub problem.."
                                        />
                                        <div className="error_add">
                                            <span className="errorShow">{this.state.errors["subIssue" + index]}</span>
                                        </div>
                                    </div>

                                    <div className="wrapper">
                                        <label>Problem Description</label>
                                        <textarea name="description" type="text" placeholder="Problem Description" className="problemDescription" onChange={e => this.handleChangeDesc(index, e)} value={element.problem_description}></textarea>
                                        <div className="error_add">
                                            <span className="errorShow">{this.state.errors["problemDescription" + index]}</span>
                                        </div>
                                    </div>

                                    <i className="fa fa-times removeLineItem" aria-hidden="true" onClick={() => this.removeFormFields(index)}></i>
                                </div>
                            ))
                            }
                            <div className="rtl-button-section">
                                <button className="button add" type="button" onClick={() => this.addFormFields()}> <i className="fa fa-plus"></i> NEW PRODUCT</button>
                            </div>
                        </div>
                    </div>
                }
                {this.state.showShippingForm &&
                    <div className="productDetailsWrapper shippingInfo">
                        <h2>New Complaint</h2>
                        <div className="productDetailsWrapperInside">
                            <h3>Shipping details</h3>
                            <div className="shippingWrapper">
                                <div className="field">
                                    <label>Phone</label>
                                    <input type="text" name="mobile" maxLength={10} value={this.state.mobile} onChange={(e) => this.handleShippingInfo(e)}></input>
                                    <div className="error_add">
                                        <span className="errorShow">{this.state.errors["mobile"]}</span>
                                    </div>
                                </div>
                                <div className="field">
                                    <label>E-Mail</label>
                                    <input type="text" name="email" value={this.state.email} onChange={(e) => this.handleShippingInfo(e)}></input>
                                    <div className="error_add">
                                        <span className="errorShow">{this.state.errors["email"]}</span>
                                    </div>
                                </div>
                                <div className="field">
                                    <label>Address line 1</label>
                                    <input type="text" name="address" value={this.state.address} onChange={(e) => this.handleShippingInfo(e)}></input>
                                    <div className="error_add">
                                        <span className="errorShow">{this.state.errors["address"]}</span>
                                    </div>
                                </div>
                                <div className="field">
                                    <label>Landmark</label>
                                    <input type="text" name="landmark" value={this.state.landmark} onChange={(e) => this.handleShippingInfo(e)}></input>
                                    <div className="error_add">
                                        <span className="errorShow">{this.state.errors["landmark"]}</span>
                                    </div>
                                </div>
                                <div className="field">
                                    <label>Pincode</label>
                                    <input type="text" name="pincode" maxLength={6} value={this.state.pincode} onChange={(e) => this.handleShippingInfo(e)}></input>
                                    <div className="error_add">
                                        <span className="errorShow">{this.state.errors["pincode"]}</span>
                                    </div>
                                </div>
                                <div className="field">
                                    <label>State</label>
                                    <select placeholder="Select State" className="dropdown" value={this.state.state_id} onChange={(e) => this.onStateChange(e)} name="state">
                                        <option value={0}> Select state</option>
                                        {this.state.stateList.map((obj) => (
                                            <option value={obj.id} selected={obj.id === this.state.state_id ? 'selected' : ''} data={obj.state_name}>{obj.state_name}</option>
                                        ))}
                                    </select>
                                    <div className="error_add">
                                        <span className="errorShow">{this.state.errors["state"]}</span>
                                    </div>
                                </div>
                                <div className="field">
                                    <label>City</label>
                                    {/* <input type="text" name="city" value={this.state.city} onChange={(e) => this.handleShippingInfo(e)}></input> */}
                                    <select className="dropdown" id="select_city" value={this.state.city_id} onChange={(e) => this.onCityChange(e)}>
                                        <option value={0}> Select city</option>
                                        {this.state.cityList?.map((obj) => (
                                            <option value={obj.id} selected={obj.id === this.state.city_id ? 'selected' : ''}>{obj.city}</option>
                                        ))}
                                    </select>
                                    <div className="error_add">
                                        <span className="errorShow">{this.state.errors["city"]}</span>
                                    </div>
                                </div>
                                <div className="defaultAddCheck">
                                    <input type="checkbox" name="isDefaultAddress" checked={this.state.isDefaultAddress} onChange={this.handleCheckbox}></input>
                                    <label>Set as default address</label>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.reviewForm &&
                    <div className="productDetailsWrapper reviewInfo">
                        <h2>New Complaint</h2>
                        <div className="productDetailsWrapperInside reviewTicketWrapper">
                            <h3>Product details</h3>
                            <p>{this.state.addIndex + 1} items are added</p>
                            {this.state.formValues.map((element, index) => (
                                <div className="reviewOuterWrapper">
                                    <div className="reviewWrapper">
                                        <div className="reviewLineItem">
                                            <h5>{element.category_name} </h5>
                                            <span>{element.product_name} </span>
                                        </div>
                                        <div className="reviewLineItem">
                                            <span>{element.quantity} </span>
                                        </div>
                                        <div className="reviewLineItem"><span>{moment(element.purchase_date).format('DD-MM-YYYY')} </span></div>
                                        <div className="reviewLineItem"><span className="issue_name">{element.issue_name}</span><br></br><span>{element.issue_name !== "Issue not listed" && element.sub_issue_name} <br></br> {element.problem_description}</span></div>
                                    </div>

                                    <div className="reviewRemove" onClick={() => this.removeFormFields(index)}>
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </div>
                                    <div className="editLineItem" onClick={() => this.editFormFields(index)}>
                                        <i className="fas fa-pencil-alt" title="Edit"></i>
                                    </div>
                                </div>
                            ))
                            }
                            <ReactModal
                                isOpen={this.state.showEdit}
                                contentLabel="Minimal Modal Example"
                                style={styleEdit}
                                ariaHideApp={false}
                                className="edit-complaint"
                            >
                                <h1>Edit product</h1>
                                <div className="closeEditProduct" onClick={this.closeEditModal}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </div>
                                <div className="editFormWrapper">
                                    <div className="row">
                                        <label>Category</label>
                                        <Select
                                            name='category'
                                            onChange={e => this.handleEditCategory(this.state.tempIndex, e)}
                                            value={this.state.newSelectedCategory}
                                            options={editcategoryOptions}
                                            isSearchable={true}
                                            className="select-category-rtl"
                                            maxMenuHeight={200}
                                            placeholder="Search Category.."
                                        />
                                        <div className="error_add">
                                            <span className="errorShow">{this.state.errors["edit_category"]}</span>
                                        </div>

                                    </div>

                                    <div className="edit_complaint_review">
                                        <p>Product Details</p>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-5">
                                            <label>Product</label>
                                            <Select
                                                name="product_id"
                                                onChange={e => this.handleEditProduct(this.state.tempIndex, e)}
                                                value={this.state.newSelectedProduct}
                                                options={productOptions}
                                                isSearchable={true}
                                                className="select-product-rtl"
                                                maxMenuHeight={200}
                                                placeholder="Search Product.."
                                            />
                                            <div className="error_add">
                                                <span className="errorShow">{this.state.errors["edit_product"]}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <label>Quantity</label>
                                            <input type="number" name="quantity" className="itemQuantity" value={this.state.newquantity} onChange={e => this.handleEditQuantity(this.state.tempIndex, e)} />
                                            <div className="error_add">
                                                <span className="errorShow">{this.state.errors["edit_quantity"]}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-5 purchase_date_wrapper">
                                            <label>Purchase date</label>
                                            <input name="date" type="date" readonly className="date-complaint-rtl" placeholder={this.state.today} max={this.state.today} onChange={e => this.handleEditDate(this.state.tempIndex, e)} value={this.state.newPurchaseDate}></input>
                                            <div className="error_add">
                                                <span className="errorShow">{this.state.errors["edit_date"]}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label>Issues</label>
                                        <Select
                                            name="issue"
                                            onChange={e => this.handleEditIssue(this.state.tempIndex, e)}
                                            value={this.state.newSelectedIssue[this.state.tempIndex]}
                                            options={problems}
                                            isSearchable={true}
                                            className="select-issue-rtl"
                                            maxMenuHeight={200}
                                            placeholder="Search Issue.."
                                        />
                                        <div className="error_add">
                                            <span className="errorShow">{this.state.errors["edit_issue"]}</span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <label>Selected sub issue</label>
                                        <Select
                                            name="issue"
                                            onChange={e => this.handleEditSubIssue(this.state.tempIndex, e)}
                                            value={this.state.newSelectedSubIssue[this.state.tempIndex]}
                                            options={Subproblems}
                                            isSearchable={true}
                                            className="select-issue-rtl"
                                            maxMenuHeight={200}
                                            placeholder="Search Sub Issue.."
                                        />
                                        <div className="error_add">
                                            <span className="errorShow">{this.state.errors["edit_sub_issue"]}</span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <label>Description (optional)</label>
                                        <textarea name="description" type="text" className="problemDescription" onChange={(el) => this.handleEditDesc(this.state.tempIndex, el)} value={this.state.newDescription == (true || false) ? "" : this.state.newDescription}></textarea>
                                    </div>


                                </div>
                                <div className="save_wrapper">
                                    <button className="saveLineItem" onClick={e => this.saveEditForm(this.state.tempIndex, e)}>SAVE</button>
                                </div>

                            </ReactModal>
                        </div>
                        <div className="shippingAddWrapper">
                            <h5>Shipping details</h5>
                            <div>
                                <span>{this.state.mobile}<br></br>{this.state.email}<br></br>{this.state.address}<br></br>{this.state.landmark}<br></br>{this.state.cityName}, {this.state.stateName}
                                    <br></br>{this.state.pincode}
                                </span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    };
}

const mapStateToProps = state => ({
    groupData: state.product.groupData,
    productNameList: state.product.productNameList,
    problemData: state.complaints.problemData,
    stateDataList: state.complaints.stateData,
    cityDataList: state.complaints.cityData,
    distributorTicketId: state.distributor.distributorTicketId,
    subproblemData: state.complaints.subproblemData,
});

export default connect(mapStateToProps, {
    getGroupData, getProductNameData, getProductProblemList, getStateList, getCityByState, getSubProblemList,
    addDistributorComplaint
})(RTLProductDetails);