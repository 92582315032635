import React, { Component } from 'react';
import { connect } from "react-redux";
import { Editor } from 'react-draft-wysiwyg';
//import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { sendMail, cannedResponse, getConversationData } from '../../../actions/complaintsDataAction';
import moment from 'moment';
import HtmlParser from 'react-html-parser';
import { addComments } from '../../../actions/complaintsDataAction';
// import { isExtraneousPopstateEvent } from 'history/DOMUtils';

class ConversationExpand extends Component {

  constructor() {
    super();
    this.state = {
      editorHtml: '', theme: 'snow', file: {}, expandConversation: false, disabled: false, typeValue: 1,
      commentsValue: '', commentDisabled: false, fetchCannedResponse: [], isfetchCannedResponse: false, cannedSearchString: '',
      conversationLIST: [], pageno: 1,
      loadState: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAttachment = this.handleAttachment.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openCannedResponse = this.openCannedResponse.bind(this);
    this.toggleOpenIcon = this.toggleOpenIcon.bind(this);
    this.addCannedResponse = this.addCannedResponse.bind(this);
    this.cannedSearch = this.cannedSearch.bind(this);
    this.onInputCannedSearch = this.onInputCannedSearch.bind(this);
    this.handleCannedClose = this.handleCannedClose.bind(this);
    this.closeCannedSearch = this.closeCannedSearch.bind(this);
  }


  handleAttachment(e) {
    document.getElementById("files").classList.remove("removeValue");
    this.setState({
      file: e.target.files[0],
      disabled: false
    });
  }

  handleChange(html) {
    const div = document.querySelector('.ql-editor');
    if (div.classList.contains('ql-blank')) {
      this.setState({ editorHtml: '' });
    }
    else {
      let br = document.querySelector('.ql-editor p').innerHTML;
      if (br === "<br>") {
        this.setState({ editorHtml: '' });
      }
      else {
        this.setState({ editorHtml: html });
      }
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    // let t = this.props.complaintsDetail.id;
    if (this.state.editorHtml && !this.state.editorHtml.match(/data:image\/[a-z]+;base64,/)) {
      this.setState({ disabled: true });
      let data = new FormData();
      data.append('complaint_id', this.props.complaintsDetail.id);
      data.append('msg', this.state.editorHtml);
      data.append('attachment', this.state.file);
      let response = await this.props.sendMail(data);
      let div = document.createElement("div");
      div.innerHTML = this.state.editorHtml;
      let text = div.textContent || div.innerText || "";
      if (response.success) {
        let data = [{
          body_text: text,
          created_at: response.data.date
        }];
        this.setState({ conversationLIST: [...this.state.conversationLIST, ...data], loadState: false });
      }
      document.querySelector(".ql-editor").innerHTML = "";
      document.getElementById("files").value = '';
      this.setState({ editorHtml: '', disabled: false, file: {} });
      var myDiv = document.getElementById("expand_conversation_head");
      myDiv.scrollTop = myDiv.scrollHeight - myDiv.clientHeight;
      // myDiv.scrollTop = 0;
    }
    else {
      alert("Please enter proper text!");
    }
  }

  onTypeChange(e) {
    this.setState({ typeValue: e.target.value }, () => {
      if (this.state.typeValue == 2) {
        if (this.state.isfetchCannedResponse) {
          this.setState({ isfetchCannedResponse: false });
          document.getElementById("cannedWrapper").style.display = "none";
          document.getElementById("conversationWrapper").classList.remove('half');
        }
      }
    }
    );
  }


  async handleCommentSubmit(e) {
    // e.preventDefault();
    if (this.state.commentsValue !== "") {
      let currentdate = new Date();
      let datetime = "Last Sync: " + currentdate.getDate() + "/"
        + (currentdate.getMonth() + 1) + "/"
        + currentdate.getFullYear() + " @ "
        + currentdate.getHours() + ":"
        + currentdate.getMinutes() + ":"
        + currentdate.getSeconds();
      this.setState({ current_date: datetime, commentDisabled: true });

      let data = new FormData();
      data.append('complaint_id', this.props.complaintsDetail.id);
      data.append("comment", this.state.commentsValue,);
      if (this.state.file && this.state.file.size) {
        if (this.state.file.size / 1000 > 25 && this.state.file.size / 1000 < 1024) {
          data.append('commentImg', this.state.file);
          //  data = {
          //   "complaint_id": this.props.complaintsDetail.id,
          //   "comment": this.state.commentsValue,
          //   "userMap": [
          //   ]
          // };
        } else {
          alert("File size will be more than 25kb and and less than 1mb");
          document.getElementById("files").value = '';
          this.setState({ commentDisabled: false, file: {} });
          return false;
        }
      }

      await this.callToCommentApi(data);
      document.getElementById("files").value = '';
      this.setState({ commentsValue: '', commentDisabled: false, file: {} });
      var myDiv = document.getElementById("expand_conversation_head");
      myDiv.scrollTop = myDiv.scrollHeight - myDiv.clientHeight;
    } else {
      alert("Please enter comment");
    }
  }

  callToCommentApi = async (param) => {
    await this.props.addComments(param);
    let commentsRes = this.props.conversationData;
    if (commentsRes) {
      this.setState({ commentsValue: '', commentDisabled: false });
      var myDiv = document.getElementById("expand_conversation_head");
      myDiv.scrollTop = myDiv.scrollHeight - myDiv.clientHeight;
    }
  };

  componentDidMount() {
    document.querySelector(".ql-editor").innerHTML = "";
    this.setState({ editorHtml: '' });
    var myDiv = document.getElementById("expand_conversation_head");
    myDiv.scrollTop = myDiv.scrollHeight - myDiv.clientHeight;
    this.setState({ conversationLIST: this.props.conversationData, pageno: this.props.pageno });
  }

  addCannedResponse = (body) => {
    this.setState({ editorHtml: body });
  };

  toggleOpenIcon(data) {
    let id = "toggle" + data;
    let targetId = document.getElementById(id);
    let rotateClass = targetId.querySelector(".fa-angle-down");

    rotateClass.classList.toggle("rotateIcon");

    // let isOpen = e.target.getAttribute('aria-expanded');
    // let icon = e.target.querySelector(".fa-angle-down");
    // if (isOpen) {
    //   icon.classList.add("rotateIcon");
    // }
    // else {
    //   icon.classList.remove("rotateIcon");
    // }
  }
  onInputCannedSearch = async (e) => {
    if (e.target.value !== '') {
      this.setState({ cannedSearchString: e.target.value });
    }
    else {
      this.setState({ cannedSearchString: '' });
      let data = {
        "complaint_id": this.props.complaintId
      };
      await this.props.cannedResponse(data);
      this.setState({ fetchCannedResponse: this.props.fetchCannedResponse });
    }
  };

  closeCannedSearch = async (e) => {
    let data = {
      "complaint_id": this.props.complaintId
    };
    await this.props.cannedResponse(data);
    this.setState({ fetchCannedResponse: this.props.fetchCannedResponse, cannedSearchString: '' });
  };

  cannedSearch = async () => {
    let data = {
      "complaint_id": this.props.complaintId,
      "search_by": this.state.cannedSearchString
    };
    await this.props.cannedResponse(data);
    this.setState({ fetchCannedResponse: this.props.fetchCannedResponse });
  };

  handleCannedClose = () => {
    this.setState({ isfetchCannedResponse: false, fetchCannedResponse: [] });
    document.getElementById("cannedWrapper").style.display = "none";
    document.getElementById("conversationWrapper").classList.remove('half');
    document.querySelector(".canned-reposnse").classList.remove('shift');
  };

  openCannedResponse = async () => {
    let data = {
      "complaint_id": this.props.complaintId
    };
    await this.props.cannedResponse(data);
    if (this.props.fetchCannedResponse) {
      this.setState({ isfetchCannedResponse: true });
      document.getElementById("cannedWrapper").style.display = "block";
      document.getElementById("conversationWrapper").classList.add('half');
      document.querySelector(".canned-reposnse").classList.add('shift');
      this.setState({ fetchCannedResponse: this.props.fetchCannedResponse });
    }
    else {
      this.setState({ isfetchCannedResponse: false });
    }
  };

  closeModal() {
    this.props.showExpandedConversation(false);
  }

  loadMore = async () => {
    let response = await this.props.getConversationData(this.props.complaintsDetail.id, this.state.pageno + 1);
    this.setState({ conversationLIST: [...this.state.conversationLIST, ...response] });
  };

  render() {
    let userRoleId = localStorage.getItem('userRoleId');
    let userEmail = localStorage.getItem('userEmail');

    return (
      <div>
        <div id="conversationWrapper">
          {userRoleId !== '4' &&
            <div className="shrink_wrapper" onClick={this.closeModal}>
              <i className="fa fa-compress" aria-hidden="true"></i>
            </div>
          }
          <div className="head conversation_head expanded" id="expand_conversation_head">
            {
              this.state.conversationLIST.map((b, index) => (
                <div style={{ width: "100%", float: "left" }}>
                  {!b.user &&
                    <div>
                      {b.from_email != null
                        ?

                        <div id="coversation_body" className={b.from_email !== "NOISE <help@nexxbase.com>" && 'incoming'} >

                          <div>
                            {b.from_email !== "NOISE <help@nexxbase.com>" &&
                              <h3>Customer Reply</h3>
                            }
                            <p>{b.body_text}</p>
                            <p id="created_at">{moment(b.created_at, ["MMMM DD, YYYY || HH.mm"]).format('MMMM DD, YYYY || hh:mm A')}</p>
                          </div>

                        </div>
                        :
                        <div id="coversation_body" className={b.to_emails === '' && "yellowBG"} >

                          <div>

                            <p>{b.body_text}</p>
                            <p id="created_at">{moment(b.created_at, ["MMMM DD, YYYY || HH.mm"]).format('MMMM DD, YYYY || hh:mm A')}</p>
                          </div>

                        </div>
                      }
                    </div>}
                </div>
              ))
            }
            {(this.props.conversationpage.next_page == true && this.state.loadState) &&
              <div style={{ display: "flex", justifyContent: "center" }} className="expand_wrapper" onClick={this.loadMore}>
                <button className='loadMoreBUtton'>Load more..</button>
              </div>}
          </div>
          <div className="foot conversation_foot expand_conversation_footer" id="collapseFooter">

            <div className="f1">
              <div className='reply_wrapper'>
                <p className="conversation_reply"><i className="fas fa-share"></i>Public reply</p>
                <p className="to_customer">To <span>{this.props.complaintsDetail.customer.email}</span></p>
              </div>
              <div>
                <form action="" id="commconv">
                  <ReactQuill
                    theme='snow'
                    onChange={this.handleChange}
                    value={this.state.editorHtml}
                    modules={Editor.modules}
                    formats={Editor.formats}
                    placeholder="Enter text.."
                  />
                  <p className="canned-reposnse" onClick={this.openCannedResponse} title="Canned responses"><img src="/img/canned-icon.png" alt='' /></p>
                </form>
              </div>
              <form>
                <div>
                  <input id="files" type="file" onChange={this.handleAttachment} title='Add Attachment' />
                  <button type="button" disabled={this.state.disabled} id="mailReply" className="btn btn-info" onClick={this.handleSubmit}>Reply</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {this.state.isfetchCannedResponse &&
          <div id="cannedWrapper">
            <button id="close_canned" onClick={this.handleCannedClose}><i className="fas fa-times"></i></button>
            <h3>Canned Responses</h3>
            <div className="cannedSearch">
              <input type="cannedSearch" name="searchCanned" id="search_canned" placeholder="Search..." value={this.state.cannedSearchString} onChange={this.onInputCannedSearch} />
              <button type="button" className="add-user-btn-input-canned" onClick={this.cannedSearch}>
                <i className="fa fa-search user_search_canned"></i>
              </button>
              {this.state.cannedSearchString.length > 0 &&
                <button type="button" className="add-user-btn-input-canned" onClick={this.closeCannedSearch}>
                  <i className="fas fa-times"></i>
                </button>
              }
            </div>
            <div className="responseWrapper">
              <h4>RECENT REPOSNSES</h4>
              <div className="reponses">
                {
                  this.state.fetchCannedResponse.map((canned, index) => (
                    <div className="canned_responses">
                      <a onClick={() => this.toggleOpenIcon(canned.id)} id={"toggle" + canned.id} className="cannedSubject" data-toggle="collapse" href={"#collapse" + canned.id} aria-expanded="false" aria-controls={"#collapse" + canned.id}>
                        <i className={"fas fa-angle-down angle" + canned.id}></i> {canned.subject}
                      </a>
                      <p className="cannedType"><i className="fa fa-folder" aria-hidden="true"></i> {canned.variant}</p>
                      <p className="mail collapse" id={"collapse" + canned.id} aria-labelledby={"collapse" + canned.id}>
                        <div className="iconWrapper" title="Insert">
                          <i className="fas fa-plus-circle" onClick={() => this.addCannedResponse(canned.body)}></i>
                          <img title="Replace" src="/img/exchange1.png" alt='' onClick={() => this.addCannedResponse(canned.body)} />
                        </div>
                        {(HtmlParser(canned.body))}
                      </p>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        }

      </div>
    );
  }
}
/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: [
    [{ 'font': [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline'],
    ['link']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
};
/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  'font', 'size',
  'bold', 'italic', 'underline',
  'link'
];

const mapStateToProps = state => ({
  conversationDetails: state.complaints.conversationDetails,
  complaintsDetail: state.complaints.complaintsDetail,
  commentsData: state.complaints.complaintsDetail,
  fetchCannedResponse: state.complaints.fetchCannedResponse,
  conversationpage: state.complaints.conversationpage
});

export default connect(mapStateToProps, { sendMail, addComments, cannedResponse, getConversationData })(ConversationExpand);