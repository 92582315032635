import {
  FETCH_SERVICE_DATA, UPDATE_SERVICE_DATA, REMOVE_SERVICE_CENTER, CALL_SERVICE_CENTER, FETCH_SERVICE_COMPLAINT_CONFIG,
  FETCH_PAGINATION_INFO, ADD_SERVICE_CENTER, GET_EMPLOYEE_DETAILS, ADD_NEW_SERVICE_CENTER,
  ADD_SUB_SERVICE_CENTER_EMPLOYEE, CLEAR_MAIN_SERVICE_CENTER_DATA, EDIT_SUB_SERVICE_CENTER_EMPLOYEE,
  EDIT_SERVICE_CENTER, CLEAR_SUB_SERVICE_CENTER_EMP_DATA, CLEAR_SEARCH_DATA, CLEAR_EDIT_SERVICE_CENTER_DATA,
  CLEAR_EDIT_EMPLOYEE_DATA, DEACTIVATE_SERVICE_CENTER, FETCH_SEARCH_RESULTS, GET_COMPLAINTS_DETAIL, FETCH_COMMENTS,
  FETCH_CONVERSATIONS, FETCH_COMPLAINTS_DATA, FETCH_COMPLAINTS_COUNT, GET_SC_RPU_COURIER, PRINT_SC_LABEL_DATA, FETCH_SERVICE_CENTER_REPORT,
  GET_EMPLOYEE_DATA, FETCH_SERVICE_CENTERS, FETCH_SERVICE_CONVERSATIONS,
  SET_ACTIVE_FILTERS, TRACK_SERVICE_STATUS_DATA, ADD_COMMENT, GET_PRODUCT_PROBLEM_LIST,
  FETCH_SERVICE_CENTER_LIST, CLEAR_SUCCESS_LIST_SC, CLEAR_FAILURE_LIST_SC, FAILURE_LIST_SC, SUCCESS_LIST_SC, FAILURE_WAY_BILL_LIST,
  SUCCESS_WAY_BILL_LIST, SUCCESS_LIST_BRPU_SC, FAILURE_LIST_BRPU_SC, CLEAR_SUCCESS_LIST_BRPU_SC, CLEAR_FAILURE_LIST_BRPU_SC, FETCH_CHALLAN_DATA, SET_COMPLAINT_ARRAY,
  SHOW_TROUBLESHOOT_ACTION, FETCH_CONVERSATIONS_DATA, FETCH_CONVERSATIONS_LOAD, FETCH_COMMENT_DATA, FETCH_COMMENT_LOAD
} from '../actions/types';
const initialState = {
  successListDI: [],
  courierSCData: [],
  serviceData: [],
  serviceCenter: {},
  newServiceData: [],
  serviceListRemoved: [],
  callServiceCenter: [],
  newServiceCenter: [],
  employeeData: [],
  serviceNewData: [],
  paginationCount: [],
  newServiceCenter2: [],
  subServiceCenterEmp: [],
  clearSearchData: [],
  editServiceCenter: [],
  editSubServiceCenterEmp: [],
  subServiceCenter: [],
  searchResults: [],
  complaintsDetail: undefined,
  sccomplaintsData: [],
  commentsData: [],
  sccomplaintsDataCount: [],
  printSCLabel: null,
  employeeDetails: [],
  serviceCentersList: [],
  serviceCentersReport: '',
  conversationData: [],
  activeFilter: 1,
  trackServiceStatusData: {},
  newComment: "",
  // commentsData: [],
  commentsFail: {},
  problemData: [],
  scList: [],
  failureListDI: [],
  successList: [],
  failureList: [],
  successWayBillList: [],
  failureWayBillList: [],
  FailureListBRPU: [],
  SucccessListBRPU: [],
  challanData: [],
  complaintsArray: [],
  showToubleshoot: '',
  SCconversationDetails: [],
  SCconversationpage: [],
  SCcommentDetails: [],
  SCcommentpage: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SERVICE_DATA:
      return {
        ...state,
        serviceData: action.payload,
      };
    case UPDATE_SERVICE_DATA:
      return {
        ...state,
        newServiceData: action.payload,
      };
    case REMOVE_SERVICE_CENTER:
      return {
        ...state,
        serviceListRemoved: action.payload,
      };
    case CALL_SERVICE_CENTER:
      return {
        ...state,
        callServiceCenter: action.payload,
      };
    case FETCH_SERVICE_COMPLAINT_CONFIG:
      return {
        ...state,
        serviceNewData: action.payload
      };
    case ADD_SERVICE_CENTER:
      return {
        ...state,
        newServiceCenter: action.payload,
      };
    case EDIT_SERVICE_CENTER:
      return {
        ...state,
        editServiceCenter: action.payload,
      };
    case GET_EMPLOYEE_DETAILS:
      return {
        ...state,
        employeeData: action.payload,
      };
    case GET_EMPLOYEE_DATA:
      return {
        ...state,
        employeeDetails: action.payload
      };
    case FETCH_PAGINATION_INFO:
      return {
        ...state,
        paginationCount: action.payload,
      };
    case ADD_NEW_SERVICE_CENTER:
      return {
        ...state,
        newServiceCenter2: action.payload
      };
    case ADD_SUB_SERVICE_CENTER_EMPLOYEE:
      return {
        ...state,
        subServiceCenterEmp: action.payload
      };
    case CLEAR_MAIN_SERVICE_CENTER_DATA:
      return {
        ...state,
        newServiceCenter2: []
      };
    case CLEAR_SUB_SERVICE_CENTER_EMP_DATA:
      return {
        ...state,
        subServiceCenterEmp: []
      };
    case CLEAR_SEARCH_DATA:
      return {
        ...state,
        serviceNewData: []
      };
    case EDIT_SUB_SERVICE_CENTER_EMPLOYEE:
      return {
        ...state,
        editSubServiceCenterEmp: action.payload
      };
    case CLEAR_EDIT_EMPLOYEE_DATA:
      return {
        ...state,
        editSubServiceCenterEmp: []
      };
    case CLEAR_EDIT_SERVICE_CENTER_DATA:
      return {
        ...state,
        editServiceCenter: []
      };
    case DEACTIVATE_SERVICE_CENTER:
      return {
        ...state,
        subServiceCenter: action.payload
      };
    case FETCH_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload
      };
    case GET_COMPLAINTS_DETAIL:
      return {
        ...state,
        complaintsDetail: action.payload,
      };
    case FETCH_COMMENTS:
      return {
        ...state,
        commentsData: action.payload
      };
    case FETCH_CONVERSATIONS:
      return {
        ...state,
        conversationData: action.payload
      };
    case FETCH_COMPLAINTS_DATA:
      return {
        ...state,
        sccomplaintsData: action.payload,
      };
    case FETCH_COMPLAINTS_COUNT:
      return {
        ...state,
        sccomplaintsDataCount: action.payload,
      };
    case GET_SC_RPU_COURIER:
      return {
        ...state,
        courierSCData: action.payload,
      };
    case PRINT_SC_LABEL_DATA:
      return {
        ...state,
        printSCLabel: action.payload,
      };
    case FETCH_SERVICE_CENTERS:
      return {
        ...state,
        serviceCentersList: action.payload,
      };
    case FETCH_SERVICE_CENTER_REPORT:
      return {
        ...state,
        serviceCentersReport: action.payload,
      };
    case FETCH_SERVICE_CONVERSATIONS:
      return {
        ...state,
        conversationData: action.payload,
      };
    case SET_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilter: action.payload
      };
    case TRACK_SERVICE_STATUS_DATA:
      return {
        ...state,
        trackServiceStatusData: action.payload,
      };
    case ADD_COMMENT:
      return {
        ...state,
        newComment: action.payload,
      };
    // case FETCH_COMMENTS:
    //   return {
    //     ...state,
    //     commentsData: action.payload
    //   };
    case GET_PRODUCT_PROBLEM_LIST:
      return {
        ...state,
        problemData: action.payload,
      };
    case FETCH_SERVICE_CENTER_LIST:
      return {
        ...state,
        scList: action.payload
      };
    case CLEAR_SUCCESS_LIST_SC:
      return {
        ...state,
        successList: []
      };
    case CLEAR_FAILURE_LIST_SC:
      return {
        ...state,
        failureList: []
      };
    case FAILURE_LIST_SC:
      // let failureListResult = []  
      // if(action.payload.length > 0){
      //   failureListResult = [...state.failureList, ...action.payload]
      // }
      return {
        ...state,
        failureList: [...state.failureList, ...action.payload],
      };
    case SUCCESS_LIST_SC:
      // let successListResult = []  
      // if(action.payload.length > 0){
      //   successListResult = [...state.successList, ...action.payload]
      // }
      return {
        ...state,
        successList: [...state.successList, ...action.payload],
      };
    case FAILURE_WAY_BILL_LIST:
      return {
        ...state,
        failureWayBillList: action.payload,
      };
    case SUCCESS_WAY_BILL_LIST:
      return {
        ...state,
        successWayBillList: action.payload,
      };
    case CLEAR_SUCCESS_LIST_BRPU_SC:
      return {
        ...state,
        SucccessListBRPU: []
      };
    case CLEAR_FAILURE_LIST_BRPU_SC:
      return {
        ...state,
        FailureListBRPU: []
      };
    case SUCCESS_LIST_BRPU_SC:
      return {
        ...state,
        SucccessListBRPU: [...state.SucccessListBRPU, ...action.payload]
      };
    case FAILURE_LIST_BRPU_SC:
      return {
        ...state,
        FailureListBRPU: [...state.FailureListBRPU, ...action.payload]
      };
    case FETCH_CHALLAN_DATA:
      return {
        ...state,
        challanData: action.payload,
      };
    case SET_COMPLAINT_ARRAY:
      return {
        ...state,
        complaintsArray: action.payload,
      };
    case SHOW_TROUBLESHOOT_ACTION:
      return {
        ...state,
        showToubleshoot: action.payload,
      }; case FETCH_CONVERSATIONS_DATA:
      return {
        ...state,
        SCconversationDetails: action.payload
      };
    case FETCH_CONVERSATIONS_LOAD:
      return {
        ...state,
        SCconversationpage: action.payload
      };
    case FETCH_COMMENT_DATA:
      return {
        ...state,
        SCcommentDetails: action.payload
      };
    case FETCH_COMMENT_LOAD:
      return {
        ...state,
        SCcommentpage: action.payload
      };


    default:
      return state;
  }
}
