import * as React from "react";
import { connect } from "react-redux";
import './ComponentToRPUPrint.css';
import moment from 'moment';
import AccessRestricted from '../AccessRestricted';
import ComplaintRegistration from '../ComplaintRegistration';
import ReactToPrint from "react-to-print";
var Barcode = require('react-barcode');

class ComponentToFPUPrint extends React.PureComponent {
    constructor(props) {
        super(props);
        this.componentRef = null; // Use null instead of createRef
        this.handlePrint = null; // Store print function reference
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress);
    }

    handleKeyPress = (e) => {
        if (e.ctrlKey && e.key === "p") {
            e.preventDefault(); // Prevent default browser print
            if (this.handlePrint) {
                this.handlePrint(); // Trigger custom print
            }
        }
    };

    render() {
        let userRoleId = localStorage.getItem('userRoleId');
        if (['4', '8', '9', '10'].includes(userRoleId)) {
            return <AccessRestricted />;
        } else if (userRoleId === '6') {
            return <ComplaintRegistration />;
        } else {
            return (
                <div>
                    {this.props.printLabel && this.props.printLabel.tickets[0] ? (
                        <div>
                            {/* Attach ref correctly */}
                            <div className='rpu-print-cont' ref={(el) => (this.componentRef = el)}>
                                {/* Print Styles */}
                                <style type="text/css" media="print">
                                    {`
                                        .flex-table { padding-left: 1rem !important; }
                                        .rpu-print-cont td, .rpu-print-cont p { font-size: 2.5rem !important; font-weight: 800 !important; }
                                        .description, .from, .deliver_to { flex: 5 !important; }
                                        .flex-table svg { transform: scale(1.5) translate(44px, 20px) !important; margin-bottom: 6%!important; }
                                    `}
                                </style>

                                <table className="flex-table">
                                    <tbody>
                                        <tr className='svg'>
                                            <td data-label="Number">
                                                <Barcode 
                                                    value={this.props.printLabel?.tickets[0]?.forward_waybill_number || ""}
                                                    displayValue={false}
                                                />
                                            </td>
                                        </tr>
                                        <tr className="tr1">
                                            <td>
                                                <p>
                                                    {this.props.printLabel?.tickets[0]?.forward_waybill_number} - 
                                                    {this.props.printLabel?.tickets[0]?.courier?.courier_name}
                                                </p>
                                            </td>
                                            <td data-label="Name">
                                                <div>Act wt - {this.props.printLabel?.tickets[0]?.courier?.actual_weight}</div>
                                            </td>
                                            <td data-label="Name">
                                                <div>Dim (cm) - </div>
                                            </td>
                                        </tr>

                                        {/* Destination Code Section */}
                                        {this.props.printLabel?.destinationCode !== 0 ? (
                                            <tr>
                                                <td>
                                                    <div><b>Destination Code:</b> {this.props.printLabel.destinationCode}</div>
                                                </td>
                                            </tr>
                                        ) : null}

                                        <tr>
                                            <td className="deliver_to" data-label="Number">
                                                <b>Deliver To:</b>
                                                <br />
                                                {this.props.printLabel?.toAddress}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td data-label="Number">Order No: {this.props.printLabel?.tickets[0]?.order_id}</td>
                                        </tr>
                                        <tr>
                                            <td data-label="Number">Date : {moment(this.props.printLabel?.tickets[0]?.forward_pickup_date).format("DD/MM/YYYY HH:mm:ss")}</td>
                                        </tr>
                                        <tr>
                                            <td className="from" data-label="Number">From : {this.props.printLabel?.fromAddress}</td>
                                        </tr>
                                        <tr>
                                            <td className="description" data-label="Number">
                                                Description : {this.props.printLabel?.tickets[0]?.product?.product_name} ({this.props.printLabel?.tickets[0]?.product?.color_name})
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/* Print Button */}
                            <ReactToPrint
                                content={() => this.componentRef}  // ✅ Use ref correctly
                                removeAfterPrint
                                trigger={() => (
                                    <div className='rpu_print_cta'>
                                        <button type="button" id="send_rModal">Print</button>
                                    </div>
                                )}
                                ref={(el) => (this.handlePrint = el ? el.handlePrint : null)}
                            />
                        </div>
                    ) : (
                        <h2 id="rModal_head" style={{ color: "#fff" }}>No forward pick up found</h2>
                    )}
                </div>
            );
        }
    }
}

const mapStateToProps = state => ({
    printLabel: state.complaints.printLabel
});

export default connect(mapStateToProps, {})(ComponentToFPUPrint);
