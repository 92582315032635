import axios from "axios";
import {
    FETCH_PRODUCT_DATA,
    FETCH_GROUP_DATA,
    GET_PRODUCT_DETAIL,
    GET_USER_DETAIL,
    SET_PRODUCT_DATA,
    EDIT_PRODUCT_DATA,
    FETCH_LEADERBOARD_ALL,
    FETCH_REPORT_CONFIG,
    FETCH_ACTION_REPORT_CONFIG,
    FETCH_OPEN_CLOSE_REPORT_CONFIG,
    FETCH_ALL_PRODUCTS,
    FETCH_CHANNELS,
    FETCH_DATA_FIELDS,
    FETCH_AUTHORIZATION,
    FETCH_INVOICE_REPORT_CONFIG,
    FETCH_MANIFEST_REPORT_CONFIG,
    FETCH_SERVICE_REPORT_CONFIG,
    FETCH_IVR_REPORT_CONFIG,
    FETCH_LUNA_NOTIFY_REPORT_CONFIG,
    FETCH_MOVE_REPORT_CONFIG,
    FETCH_NOTIFY_REPORT_CONFIG,
    FETCH_REFUND_GC_CONFIG,
    FETCH_SC_REPORT_CONFIG,
    FETCH_RTL_REPORT_CONFIG,
    FETCH_OOS_REPORT,
    FETCH_OOS_GIFT_REPORT,
    FETCH_B2B_REPORT_CONFIG,
    FETCH_REPLACEMENT_REPORT_CONFIG,
} from "./types";
import { baseUrl, oldUrl, webBaseUrl } from "../config";
import history from "../history";
// import moment from 'moment'

export const fetchOpenCloseReportLimit = userId => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .get(baseUrl + "/record/check-open-close-report-limit/" + userId, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_OPEN_CLOSE_REPORT_CONFIG,
                    payload: res.data.checkDownloadLimit,
                });
            } else {
                alert("Something went wrong");
            }
        })
        .catch(e => {
            // history.push('/manage-user')
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const fetchOpenCloseReportDownloadData = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/open-close-report/", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                // dispatch({
                //   type: FETCH_OPEN_CLOSE_REPORT_DATA,
                //   payload: res.data.data
                // })
                dispatch({
                    type: FETCH_OPEN_CLOSE_REPORT_CONFIG,
                    payload: res.data.checkDownloadLimit[0],
                });
            } else {
                alert("Something went wrong");
            }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const fetchProductList = () => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .get(baseUrl + "/product/fetch-all-products", { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_ALL_PRODUCTS,
                    payload: res.data.data,
                });
            } else {
                alert("NO DATA FOUND");
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const downloadManifestReport = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/manifest-report/", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                dispatch({
                    type: FETCH_MANIFEST_REPORT_CONFIG,
                    payload: res.data.checkDownloadLimit[0],
                });
            }
            // else {
            //   alert("NO DATA FOUND")
            // }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const fetchUserActionReportData = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/user-action-report/", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                // dispatch({
                //   type: FETCH_USER_ACTION_REPORT_DATA,
                //   payload: res.data.data
                // })
                dispatch({
                    type: FETCH_ACTION_REPORT_CONFIG,
                    payload: res.data.checkDownloadLimit[0],
                });
            } else {
                alert("Something went wrong");
            }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const fetchActionReportLimit = () => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .get(baseUrl + "/record/check-action-report-limit/", { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_ACTION_REPORT_CONFIG,
                    payload: res.data.checkDownloadLimit,
                });
            } else {
                alert("Something went wrong");
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const fetchDownloadLimit = () => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .get(baseUrl + "/record/check-limit/", { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                for (let i = 0; i < res.data.checkDownloadLimit.length; i++) {
                    if (res.data.checkDownloadLimit[i].type === "customized_report") {
                        dispatch({
                            type: FETCH_AUTHORIZATION,
                            payload: res.data.checkDownloadLimit[i],
                        });
                    }
                    if (res.data.checkDownloadLimit[i].type === "action_report") {
                        dispatch({
                            type: FETCH_ACTION_REPORT_CONFIG,
                            payload: res.data.checkDownloadLimit[i],
                        });
                    }
                    if (res.data.checkDownloadLimit[i].type === "open_close_report") {
                        dispatch({
                            type: FETCH_OPEN_CLOSE_REPORT_CONFIG,
                            payload: res.data.checkDownloadLimit[i],
                        });
                    }
                    if (res.data.checkDownloadLimit[i].type === "service_center") {
                        dispatch({
                            type: FETCH_SERVICE_REPORT_CONFIG,
                            payload: res.data.checkDownloadLimit[i],
                        });
                    }
                    if (res.data.checkDownloadLimit[i].type === "invoice") {
                        dispatch({
                            type: FETCH_INVOICE_REPORT_CONFIG,
                            payload: res.data.checkDownloadLimit[i],
                        });
                    }
                    if (res.data.checkDownloadLimit[i].type === "daily_report") {
                        dispatch({
                            type: FETCH_REPORT_CONFIG,
                            payload: res.data.checkDownloadLimit[i],
                        });
                    }
                    if (res.data.checkDownloadLimit[i].type === "manifest") {
                        dispatch({
                            type: FETCH_MANIFEST_REPORT_CONFIG,
                            payload: res.data.checkDownloadLimit[i],
                        });
                    }
                    if (res.data.checkDownloadLimit[i].type === "ivr_close") {
                        dispatch({
                            type: FETCH_IVR_REPORT_CONFIG,
                            payload: res.data.checkDownloadLimit[i],
                        });
                    }
                    if (res.data.checkDownloadLimit[i].type === "movement") {
                        dispatch({
                            type: FETCH_MOVE_REPORT_CONFIG,
                            payload: res.data.checkDownloadLimit[i],
                        });
                    }
                    if (res.data.checkDownloadLimit[i].type === "refund-gc") {
                        dispatch({
                            type: FETCH_REFUND_GC_CONFIG,
                            payload: res.data.checkDownloadLimit[i],
                        });
                    }
                    if (res.data.checkDownloadLimit[i].type == "sc_complaint_wh") {
                        dispatch({
                            type: FETCH_SC_REPORT_CONFIG,
                            payload: res.data.checkDownloadLimit[i],
                        });
                    }
                    if (res.data.checkDownloadLimit[i].type == "rtl_complaint") {
                        dispatch({
                            type: FETCH_RTL_REPORT_CONFIG,
                            payload: res.data.checkDownloadLimit[i],
                        });
                    }
                    if (res.data.checkDownloadLimit[i].type == "notify") {
                        dispatch({
                            type: FETCH_NOTIFY_REPORT_CONFIG,
                            payload: res.data.checkDownloadLimit[i],
                        });
                    }
                    if (res.data.checkDownloadLimit[i].type == "notify_luna") {
                        dispatch({
                            type: FETCH_LUNA_NOTIFY_REPORT_CONFIG,
                            payload: res.data.checkDownloadLimit[i],
                        });
                    }
                    if (res.data.checkDownloadLimit[i].type == "oos_dump") {
                        dispatch({
                            type: FETCH_OOS_REPORT,
                            payload: res.data.checkDownloadLimit[i],
                        });
                    }
                    if (res.data.checkDownloadLimit[i].type == "oos_gift_card") {
                        dispatch({
                            type: FETCH_OOS_GIFT_REPORT,
                            payload: res.data.checkDownloadLimit[i],
                        });
                    }
                    if (res.data.checkDownloadLimit[i].type == "b2b") {
                        dispatch({
                            type: FETCH_B2B_REPORT_CONFIG,
                            payload: res.data.checkDownloadLimit[i],
                        });
                    }
                    if (res.data.checkDownloadLimit[i].type == "replacement_order") {
                        dispatch({
                            type: FETCH_REPLACEMENT_REPORT_CONFIG,
                            payload: res.data.checkDownloadLimit[i],
                        });
                    }
                }
            } else {
                alert("Something went wrong");
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const fetchReportData = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/daily-report/", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                // dispatch({
                //   type: FETCH_DAILY_REPORT_DATA,
                //   payload: res.data.data
                // })
                dispatch({
                    type: FETCH_REPORT_CONFIG,
                    payload: res.data.checkDownloadLimit[0],
                });
            } else {
                // if (res.data.code == 1001 || res.data.code == 1002 || res.data.code == 1003  ){
                //   localStorage.removeItem('token')
                //   history.push('/')
                // }
            }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const inVoiceReportData = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/invoice-report/", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                // dispatch({
                //   type: FETCH_DAILY_REPORT_DATA,
                //   payload: res.data.data
                // })
                dispatch({
                    type: FETCH_INVOICE_REPORT_CONFIG,
                    payload: res.data.checkDownloadLimit[0],
                });
            } else {
                // if (res.data.code == 1001 || res.data.code == 1002 || res.data.code == 1003  ){
                //   localStorage.removeItem('token')
                //   history.push('/')
                // }
            }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const b2bReportAction = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/download-b2b", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                // dispatch({
                //   type: FETCH_DAILY_REPORT_DATA,
                //   payload: res.data.data
                // })
                dispatch({
                    type: FETCH_B2B_REPORT_CONFIG,
                    payload: res.data.checkDownloadLimit[0],
                });
            } else {
                // if (res.data.code == 1001 || res.data.code == 1002 || res.data.code == 1003  ){
                //   localStorage.removeItem('token')
                //   history.push('/')
                // }
            }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const notifyReport = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/notify-report/", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                dispatch({
                    type: FETCH_NOTIFY_REPORT_CONFIG,
                    payload: res.data.checkDownloadLimit[0],
                });
            }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const lunaReport = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/download-luna-website/", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                dispatch({
                    type: FETCH_LUNA_NOTIFY_REPORT_CONFIG,
                    payload: res.data.checkDownloadLimit[0],
                });
            }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const oosReport = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/oos-dump", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                dispatch({
                    type: FETCH_OOS_REPORT,
                    payload: res.data.checkDownloadLimit[0],
                });
            }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const oosGiftReport = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/oos-gift-card", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                dispatch({
                    type: FETCH_OOS_GIFT_REPORT,
                    payload: res.data.checkDownloadLimit[0],
                });
            }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const ivrReportData = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/ivr-close-report/", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                // dispatch({
                //   type: FETCH_DAILY_REPORT_DATA,
                //   payload: res.data.data
                // })
                dispatch({
                    type: FETCH_IVR_REPORT_CONFIG,
                    payload: res.data.checkDownloadLimit[0],
                });
            } else {
                // if (res.data.code == 1001 || res.data.code == 1002 || res.data.code == 1003  ){
                //   localStorage.removeItem('token')
                //   history.push('/')
                // }
            }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const rtlReportData = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/rtl-complaint-report/", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                dispatch({
                    type: FETCH_RTL_REPORT_CONFIG,
                    payload: res.data.checkDownloadLimit[0],
                });
            } else {
            }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const bulkMovementReport = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/movement-report/", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                // dispatch({
                //   type: FETCH_DAILY_REPORT_DATA,
                //   payload: res.data.data
                // })
                dispatch({
                    type: FETCH_MOVE_REPORT_CONFIG,
                    payload: res.data.checkDownloadLimit[0],
                });
            } else {
                // if (res.data.code == 1001 || res.data.code == 1002 || res.data.code == 1003) {
                //   localStorage.removeItem('token')
                //   history.push('/')
                // }
            }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const refundGCReport = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/refund-gc-report", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);

                dispatch({
                    type: FETCH_REFUND_GC_CONFIG,
                    payload: res.data.checkDownloadLimit[0],
                });
            } else {
            }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const scReport = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/sc-complaint-wh-report", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                dispatch({
                    type: FETCH_SC_REPORT_CONFIG,
                    payload: res.data.checkDownloadLimit[0],
                });
            }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getProductData = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/product/get", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_PRODUCT_DATA,
                    payload: res.data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const assignUserTicket = data => async dispatch => {
    return await axios
        .post(baseUrl + "/user/list-a", data, { headers: {} })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_PRODUCT_DATA,
                    payload: res.data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getProductDetail = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/product/get", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: GET_PRODUCT_DETAIL,
                    payload: res.data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const createUser = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/product/upsert", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: SET_PRODUCT_DATA,
                    payload: res.data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const createProduct = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/product/upsert", data, { headers: { "x-access-token": token } })
        .then(res => {
            alert(res.data.message);
            if (res.data.success) {
                dispatch({
                    type: SET_PRODUCT_DATA,
                    payload: res.data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const editProductData = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/user/edit", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: EDIT_PRODUCT_DATA,
                    payload: res.data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getGroupData = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/product/getproductCategories", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_GROUP_DATA,
                    payload: res.data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getMessageData = data => async dispatch => {
    return await axios
        .post(baseUrl + "/user/list", data, { headers: {} })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_PRODUCT_DATA,
                    payload: res.data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getMessageDetail = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/user/get/2", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: GET_USER_DETAIL,
                    payload: res.data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const getGroupDetail = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/user/get/2", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: GET_USER_DETAIL,
                    payload: res.data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const editMessageData = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/user/edit", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: EDIT_PRODUCT_DATA,
                    payload: res.data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const fetchLeaderboard = () => async dispatch => {
    let token = localStorage.getItem("token");
    // let token = "$2b$08$t8dmSsTqX6SaD5AH.M3wHOXWjR5/1uzAz6MhHB30jyA6p6MyIlq5i"
    return await axios
        .post(baseUrl + "/record/leaderboard", {}, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_LEADERBOARD_ALL,
                    payload: res.data.data,
                });
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const fetchChannels = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/complaint/channels", data, { headers: { "x-access-token": token,"api_key":"d8f021fd65e25982003ddce3cac2225e" }})
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_CHANNELS,
                    payload: res.data.data,
                });
            } else {
                alert("NO DATA FOUND");
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const fetchDataFields = () => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .get(baseUrl + "/record/custom-fetch-columns", { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_DATA_FIELDS,
                    payload: res.data.data,
                });
            } else {
                alert("NO DATA FOUND");
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const onCustomReportDownload = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/custom-report", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                // dispatch({
                //   type: FETCH_DAILY_REPORT_DATA,
                //   payload: res.data.data
                // })
                dispatch({
                    type: FETCH_AUTHORIZATION,
                    payload: res.data.checkDownloadLimit[0],
                });
            } else {
                // if (res.data.code == 1001 || res.data.code == 1002 || res.data.code == 1003  ){
                //   localStorage.removeItem('token')
                //   history.push('/')
                // }
            }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const checkCustomReportAccess = () => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .get(baseUrl + "/record/check-access", { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                dispatch({
                    type: FETCH_AUTHORIZATION,
                    payload: res.data.isAuthorized,
                });
            } else {
                alert("Something went wrong");
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const CronTeleforceAction = () => async dispatch => {
    // let token = localStorage.getItem('token');
    return await axios
        .get(baseUrl + "/user/teleforce-agents", { headers: { api_key: "d8f021fd65e25982003ddce3cac2225e", "Content-Type": "application/json" } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
            }
        })
        .catch(e => {
            if (e.response.status === 401) {
                history.push("/");
            }
        });
};

export const fetchReplacementReport = data => async dispatch => {
    let token = localStorage.getItem("token");
    return await axios
        .post(baseUrl + "/record/replacement-order", data, { headers: { "x-access-token": token } })
        .then(res => {
            if (res.data.success) {
                alert(res.data.message);
                dispatch({
                    type: FETCH_REPLACEMENT_REPORT_CONFIG,
                    payload: res.data.checkDownloadLimit[0],
                });
            }
        })
        .catch(e => {
            if (e.response.status === 400) {
                alert(e.response.data.message);
            } else if (e.response.status === 401) {
                history.push("/");
            }
        });
};
