import React, { Component } from 'react';
import { connect } from "react-redux";
import { Editor } from 'react-draft-wysiwyg';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { sendMail, getRTLConversationData } from '../../../actions/distributorAction';
import moment from 'moment';

class ConversationExpand extends Component {

  constructor() {
    super();
    this.state = {
      editorHtml: '',
      theme: 'snow',
      file: {},
      expandConversation: false,
      disabled: false, typeValue: 1,
      commentsValue: '',
      commentDisabled: false,
      fetchCannedResponse: [],
      isfetchCannedResponse: false,
      cannedSearchString: '',
      pageno: 1,
      conversationLIST: [],
      loadState: true,

    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAttachment = this.handleAttachment.bind(this);
    this.handleExpand = this.handleExpand.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleExpand(e) {
    let classOpen = document.getElementById("openCloseEditor");
    this.setState({
      expandConversation: !this.state.expandConversation,
    }, () => {
      if (!this.state.expandConversation) {
        classOpen.innerHTML = "Open Reply &nbsp;<i className='fa fa-expand' aria-hidden='true'></i>";
      }
      else {
        classOpen.innerHTML = "Close Reply &nbsp;<i className='fa fa-compress' aria-hidden='true'></i>";
      }

    });
  }

  handleAttachment(e) {
    document.getElementById("files").classList.remove("removeValue");
    this.setState({
      file: e.target.files[0],
    });
  }

  handleChange(html) {
    const div = document.querySelector('.ql-editor');
    if (div.classList.contains('ql-blank')) {
      this.setState({ editorHtml: '' });
    }
    else {
      let br = document.querySelector('.ql-editor p').innerHTML;
      if (br === "<br>") {
        this.setState({ editorHtml: '' });
      }
      else {
        this.setState({ editorHtml: html });
      }
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    // let t = this.props.complaintsDetail.id;
    if (this.state.editorHtml && !this.state.editorHtml.match(/data:image\/[a-z]+;base64,/)) {
      this.setState({ disabled: true });
      let data = new FormData();
      data.append('complaint_id', this.props.distributorComplainDetails.id);
      data.append('msg', this.state.editorHtml);
      data.append('attachment', this.state.file);
      let response = await this.props.sendMail(data);
      let div = document.createElement("div");
      div.innerHTML = this.state.editorHtml;
      let text = div.textContent || div.innerText || "";
      if (response.success) {
        let data = [{
          body_text: text,
          created_at: response.data.date,
          from_email: "NOISE <help@nexxbase.com>",
        }];
        // this.state.conversationLIST.unshift(data);
        this.setState({ conversationLIST: [...this.state.conversationLIST, ...data], loadState: false });
      }
      document.querySelector(".ql-editor").innerHTML = "";
      document.getElementById("files").value = '';
      this.setState({ editorHtml: '', disabled: false, file: {} });
      var myDiv = document.getElementById("expand_conversation_head");
      myDiv.scrollTop = myDiv.scrollHeight - myDiv.clientHeight;
    }
    else {
      alert("Please enter proper text!");
    }
  }

  onTypeChange(e) {
    this.setState({ typeValue: e.target.value }, () => {
      if (this.state.typeValue == 2) {
        if (this.state.isfetchCannedResponse) {
          this.setState({ isfetchCannedResponse: false });
          document.getElementById("cannedWrapper").style.display = "none";
          document.getElementById("conversationWrapper").classList.remove('half');
        }
      }
    }
    );
  }
  componentDidMount() {
    document.querySelector(".ql-editor").innerHTML = "";
    this.setState({ editorHtml: '' });
    var myDiv = document.getElementById("expand_conversation_head");
    myDiv.scrollTop = myDiv.scrollHeight - myDiv.clientHeight;
    this.setState({ conversationLIST: this.props.conversationData, pageno: this.props.pageno });

  }


  closeModal() {
    this.props.showExpandedConversation(false);
  }

  loadMore = async () => {
    let response = await this.props.getRTLConversationData(this.props.distributorComplainDetails.id, this.state.pageno + 1);
    this.setState({ conversationLIST: [...this.state.conversationLIST, ...response], pageno: this.state.pageno + 1 });
  };


  render() {
    let userRoleId = localStorage.getItem('userRoleId');
    let userEmail = localStorage.getItem('userEmail');

    return (
      <div>
        <div id="conversationWrapper">
          {userRoleId !== '4' &&
            <div className="shrink_wrapper" onClick={this.closeModal}>
              <i className="fa fa-compress" aria-hidden="true"></i>
            </div>
          }
          <div className="head conversation_head expanded" id="expand_conversation_head">
            {
              this.state.conversationLIST.map((b, index) => (
                <div style={{ width: "100%", float: "left" }}>
                  <div>
                    {b.from_email !== null
                      ?

                      <div id="coversation_body" className={b.from_email !== "NOISE <help@nexxbase.com>" && 'incoming'} >

                        <div>
                          {b.from_email !== "NOISE <help@nexxbase.com>" &&
                            <h3>Customer Reply</h3>
                          }
                          <p>{b.body_text}</p>
                          <p id="created_at">{moment(b.created_at, ["MMMM DD, YYYY || HH.mm"]).format('MMMM DD, YYYY || hh:mm A')}</p>
                        </div>

                      </div>
                      :
                      <div id="coversation_body" className={b.to_emails === '' && "yellowBG"} >
                        <div>
                          <p>{b.body_text}</p>
                          <p id="created_at">{moment(b.created_at, ["MMMM DD, YYYY || HH.mm"]).format('MMMM DD, YYYY || hh:mm A')}</p>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              ))
            }
            {(this.props.commentSCpage.next_page == true && this.state.loadState) &&
              <div style={{ display: "flex", justifyContent: "center" }} className="expand_wrapper" onClick={this.loadMore}>
                <button className='loadMoreBUtton'>Load more..</button>
              </div>}
          </div>
          <div className="foot conversation_foot expand_conversation_footer" id="collapseFooter">
            <div className="f1">
              <div className='reply_wrapper'>
                <p className="conversation_reply"><i className="fas fa-share"></i>Public reply</p>
                <p className="to_customer">To <span>{this.props.distributorComplainDetails.retailer ? this.props.distributorComplainDetails.retailer.email : null}</span></p>
              </div>
              <div>
                <form action="" id="commconv">
                  <ReactQuill
                    theme='snow'
                    onChange={this.handleChange}
                    value={this.state.editorHtml}
                    modules={Editor.modules}
                    formats={Editor.formats}
                    placeholder="Enter text.."
                  />
                  <p className="canned-reposnse" onClick={this.openCannedResponse} title="Canned responses"><img src="/img/canned-icon.png" alt='' /></p>
                </form>
              </div>
              <form>
                <div>
                  <input id="files" type="file" onChange={this.handleAttachment} title='Add Attachment' />
                  <button type="button" disabled={this.state.disabled} id="mailReply" className="btn btn-info" onClick={this.handleSubmit}>Reply</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Editor.modules = {
  toolbar: [
    [{ 'font': [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline'],
    ['link']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
};
/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  'font', 'size',
  'bold', 'italic', 'underline',
  'link'
];

const mapStateToProps = state => ({
  distributorConversationData: state.distributor.distributorConversationData,
  complaintsDetail: state.complaints.complaintsDetail,
  distributorComplainDetails: state.distributor.distributorComplainDetails,
  commentSCDetails: state.distributor.commentSCDetails,
  commentSCpage: state.distributor.commentSCpage,

});

export default connect(mapStateToProps, { sendMail, getRTLConversationData })(ConversationExpand);