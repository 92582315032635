import axios from "axios";
import { baseUrl } from "../config";
import history from "../history";
import { EDIT_COMPLAINTS_DATA } from "./types";
export const sendBoseSms = (data) => async (dispatch) => {
    console.log("DATA: ", data);
    let token = localStorage.getItem("token");
    ;
    console.log(baseUrl);
    // https://stage-app.gonoise.com/connect

    return await axios
    //TO DO: baseUrl+"-message/v1/bose/tsUnsuccessful" (revamped -> message-service-backend)
      .post(baseUrl + "/message/connect/ts-unsuccess" , data, {
        headers: { "x-access-token": token },
      })
      .then((res) => {
        if (res.data.success) {
          alert("SMS sent successfully");
        } else {
          alert("Something went wrong while sending SMS");
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          alert("Unauthorized access. Please contact the administrator for assistance.");
        } else {
          alert("An error occurred while sending SMS");
          console.error(e);
        }
      });
  };
  
  export const sendBoseSms2 = (data) => async (dispatch) => {
    console.log("DATA: ", data);
    let token = localStorage.getItem("token");
    console.log(baseUrl);
    ;
    return await axios
      .post(baseUrl + "-message/v1/bose/addcomplaintMsg" , data, {        
        headers: { "x-access-token": token },
      })
      .then((res) => {
        if (res.data.success) {
          alert("SMS sent successfully");
        } else {
          alert("Something went wrong while sending SMS");
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          alert("Unauthorized access. Please contact the administrator for assistance.");
        } else {
          alert("An error occurred while sending SMS");
          console.error(e);
        }
      });
  };

  export const tsUnsuccess = (data) => async (dispatch) => {
    ;
    let token = localStorage.getItem("token");
    return await axios
    .get(baseUrl+"-complaint/v1/complaint/ts-unsuccess/"+data.complaint_id,{
        headers: { "x-access-token": token },
      })
      .then((res) => {
       alert("SMS sent successfully!")
        // dispatch(refreshComplaintList(true))
      })
      .catch((e) => {
        if (e.response.status === 401) {
          history.push("/");
        }
      });
  };

  export const callCustomerPhone = (data) => async (dispatch) => {
    ;
    let token = localStorage.getItem("token");
    return await axios
      //.post(baseUrl + "/complaint/edit", data, {
      .get(baseUrl+"-complaint/v1/complaint/call-not-picked/"+data.complaint_id, {
        headers: { "x-access-token": token },
      })
      .then((res) => {
        alert("SMS and mail sent successfully!")
      })
      .catch((e) => {
        if (e.response.status === 401) {
          history.push("/");
        }
      });
  };