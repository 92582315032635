import React, { Component } from "react";
import "./ProductComponent.css";
import "./AddColorComponent.css";
import { connect } from "react-redux";
import Select from "react-select";
import {
  setNewProduct,
  addNewColor,
  addnewVariant,
  updateVariant,
} from "../../actions/productActions";
import { getProductColorData } from "../../actions/productActions";
import { getGroupData } from "../../actions/productActions";
import Loader from "react-loader-spinner";
import ReactModal from "react-modal";

const customStylesDO = {
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    inset: "8% 0 0 auto",
    marginRight: "0",
    transform: "translate(0)",
    background: "#000",
    padding: "2rem",
    border: "none",
    width: "35%",
    position: "absolute",
  },
};

class AddColorComponent extends Component {
  constructor() {
    super();

    this.state = {
      logout: false,
      pageNo: 0,
      pageLimit: 10,
      launch_date: "",
      buffer_date: "",
      extended_warranty: 0,
      searchText: "",
      //searchDate: '',
      messageList: [],
      messageData: [],
      message_id: "",
      message: "",
      subject: "",
      type: "",
      body: "",
      status: "",
      messageId: "",
      messageType: "",
      errors: "",
      message_type: "",
      count: "",
      colorName: "",
      colorDesctiption: "",
      productName: "",
      size: "",
      hsn: "",
      sku: "",
      imageUrl: "",
      troubleShootUrl: "",
      warranty: "",
      productName: "",
      group: "",
      colorId: "",
      productColorList: [],
      groupData: [],
      variantArray: [],
      color_name: "",
      price: "",
      selling_price: "",
      showcolorModal: false,
      selectedColor: [],
      group_name: "",
    };
  }

  componentDidMount() {
    this.getProductColor();
    if (this.props.isAdd) {
      this.setState({
        productName: this.props.editProduct?.product_name,
        product_id: this.props.editProduct?.product_id,
        group: this.props.editProduct?.product_group_id,
        warranty: this.props.editProduct?.warranty_in_months || 12,
        group_name: this.props.editProduct?.group_name,
      });
    } else
      if (this.props.isEdit) {                
        this.setState({
          productName: this.props.editProduct?.product_name,
          product_id: this.props.editProduct?.product_id,
          group: this.props.editProduct?.product_group_id,
          warranty: this.props.editProduct?.warranty_in_months || 12,
          group_name: this.props.editProduct?.group_name,
          selectedColor: {
            value: this.props.editProduct?.color_id,
            label: this.props.editProduct?.color_name,
          },
          selling: this.props.editProduct?.selling_price
            ? this.props.editProduct?.selling_price
            : "",
          price: this.props.editProduct?.product_price
            ? this.props.editProduct?.product_price
            : "",
          sku: this.props.editProduct?.sku_code
            ? this.props.editProduct?.sku_code
            : "",
          troubleShootUrl: this.props.editProduct?.troubleshoot_url
            ? this.props.editProduct?.troubleshoot_url
            : "",
          hsn: this.props.editProduct?.hsn_code
            ? this.props.editProduct?.hsn_code
            : "",
          sac: this.props.editProduct?.sac_code
            ? this.props.editProduct?.sac_code
            : "",
          imageUrl: this.props.editProduct?.product_image_url
            ? this.props.editProduct?.product_image_url
            : "",
          size: this.props.editProduct?.size_id
            ? this.props.editProduct?.size_id
            : "",
          colorId: this.props.editProduct?.color_id,
          launch_date: this.props.editProduct?.launch_date == null ? '' : this.props.editProduct?.launch_date,
          buffer_date: this.props.editProduct?.buffer_date == null ? '' : this.props.editProduct?.buffer_date,
          extended_warranty: this.props.editProduct?.extended_warranty == null ? '' : this.props.editProduct?.extended_warranty,
        });
      }
  }

  getProductColor = async () => {
    this.setState({ isLoading: true, productColorList: [] });
    let data = {
      offset: 0,
      limit: 1000,
    };
    await this.props.getGroupData();
    await this.props.getProductColorData(data);
    let groupData = this.props.groupData;
    let colorData = this.props.clolorList;
    this.setState({
      productColorList: colorData.data ? colorData.data.colors : "",
      groupData: groupData.data ? groupData.data : [],
      isLoading: false,
    });
  };

  handleValidationadd() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.colorName) {
      formIsValid = false;
      errors["error_color"] = "Color can't be empty";
    }

    if (!this.state.colorDesctiption) {
      formIsValid = false;
      errors["error_description"] = " Description Can't be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleValidation() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.body) {
      formIsValid = false;
      errors["error_color"] = "Cannot be empty";
    }

    if (!this.state.subject) {
      formIsValid = false;
      errors["error_description"] = "Cannot be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleVariantValidation() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.productName) {
      formIsValid = false;
      alert("Product name can't be empty");
    } else if (!this.state.warranty) {
      formIsValid = false;
      alert("Please enter warranty");
    } else if (!this.state.group === "" || this.state.group === "0") {
      formIsValid = false;
      alert("Select product Category");
    } else if (!this.state.colorId || this.state.colorId === "0") {
      formIsValid = false;
      alert("Select color ID");
    } else if (!this.state.price) {
      formIsValid = false;
      alert("Enter Product price");
    } else if (!this.state.selling) {
      formIsValid = false;
      alert("Enter selling price");
    } else if (!this.state.sku) {
      formIsValid = false;
      alert("Enter SKU");
    }
    // else if (!this.state.sac) {
    //   formIsValid = false;
    //   alert("Enter SAC Code");
    // } else if (!this.state.hsn) {
    //   formIsValid = false;
    //   alert("Enter HSN Code");
    // } else if (!this.state.troubleShootUrl) {
    //   formIsValid = false;
    //   alert("Enter troubleshoot Code");
    // }
    else if (!this.state.imageUrl) {
      formIsValid = false;
      alert("Enter imageUrl");
    } else if (!this.state.size && this.state.group == 13) {
      formIsValid = false;
      errors["size"] = "Enter Size";
      alert("Enter Size");
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleVariantsubmit = () => {
    let findVariant = this.state.variantArray?.find((item, index) => {
      if (item.color_id == this.state.colorId) {
        return item;
      }
    });
    if (findVariant) {
      alert("Product color is already present");
    } else {
      let validation = this.handleVariantValidation();
      if (validation) {
        let array = this.state.variantArray;
        let data = {
          color_id: this.state.colorId,
          size_id: this.state.size === '' ? null : this.state.size,
          product_image_url: this.state.imageUrl,
          product_price: this.state.price,
          selling_price: this.state.selling,
          hsn_code: this.state.hsn,
          sku_code: this.state.sku,
          color_name: this.state.color_name,
          troubleshoot_url: this.state.troubleShootUrl,
          sac_code: this.state.sac,
          launch_date: this.state.launch_date === '' ? null : this.state.launch_date,
          buffer_date: this.state.buffer_date === '' ? null : this.state.buffer_date,
          extended_warranty: this.state.extended_warranty == 0 ? null : this.state.extended_warranty,
        };
        this.setState({
          variantArray: [...array, data],
          colorId: "",
          size: "",
          imageUrl: "",
          price: "",
          selling: "",
          hsn: "",
          sku: "",
          color_name: "",
          sac: "",
          troubleShootUrl: "",
          selectedColor: {
            value: 0,
            label: "Select Product",
          },
        });
      }
    }
  };

  handleRemoveColor = (index) => {
    let oldArray = this.state.variantArray;
    oldArray.splice(index, 1);
    this.setState({ variantArray: oldArray });
  };

  productSubmit = async () => {
    let data = {
      product_name: this.state.productName,
      group_id: this.state.group,
      warranty_in_months: this.state.warranty,
      variants: this.state.variantArray,
    };
    this.setState({ isLoading: true });
    let response;
    if (this.props.isAdd) {
      let payload = {
        product_id: this.props.editProduct?.product_id,
        variants: this.state.variantArray,
      };
      response = await this.props.addnewVariant(payload);
    }
    else {
      response = await this.props.setNewProduct(data);
    }

    this.setState({ isLoading: false });
    if (response?.success) {
      alert(response.message);
      this.setState({
        variantArray: [],
        colorId: "",
        size: "",
        imageUrl: "",
        price: "",
        selling: "",
        hsn: "",
        sku: "",
        color_name: "",
        sac: "",
        troubleShootUrl: "",
        productName: "",
        warranty: "",
        group: "",
        group_name: "",
        selectedColor: {
          value: 0,
          label: "Select Product",
        },
        launch_date: "",
        buffer_date: "",
        extended_warranty: 0,
      });
    } else {
      alert(response.errors.message);
    }
  };

  handleColorAdd = async (a) => {
    let validation = this.handleValidationadd();
    if (validation) {
      let data = {
        color_name: this.state.colorName,
        description: this.state.colorDesctiption,
      };
      this.setState({ isLoading: true, showcolorModal: false });
      let response = await this.props.addNewColor(data);

      if (response?.success) {
        this.setState({ isLoading: false });
        alert(response.message);
        window.location.reload();
      } else {
        alert("Something went wrong");
      }
    }
  };

  setColors = (a) => {
    this.setState({ colorId: a.value, color_name: a.label, selectedColor: a });
  };

  handleUpdateVariant = async () => {
    let validation = this.handleVariantValidation();
    if (validation) {
      let data = {
        product_id: this.props.editProduct?.product_id,
        variants: {
          color_id: this.state.colorId,
          size_id: this.state.size === '' ? null : this.state.size,
          product_image_url: this.state.imageUrl,
          product_price: this.state.price,
          selling_price: this.state.selling,
          hsn_code: this.state.hsn,
          sku_code: this.state.sku,
          color_name: this.state.color_name,
          troubleshoot_url: this.state.troubleShootUrl,
          sac_code: this.state.sac,
          warranty_in_months: this.state.warranty || 12,
          launch_date: this.state.launch_date === '' ? null : this.state.launch_date,
          buffer_date: this.state.buffer_date === '' ? null : this.state.buffer_date,
          extended_warranty: this.state.extended_warranty == 0 ? null : this.state.extended_warranty,
        },
      };
      let response = await this.props.updateVariant(data);
      if (response.success) {
        alert("updated Successfully");
        this.props.cancleAddProduct();
      }
    }
  };

  render() {
    let optionBucketDrop = [
      {
        value: 0,
        label: "Select color",
      },
    ];
    {
      let data =
        this.props.clolorList?.data?.colors?.length > 0 &&
        this.props.clolorList?.data?.colors.map((obj) =>
          optionBucketDrop.push({
            // label: obj.name,
            value: obj.id,
            label: obj.name,
            // id: obj.id
          })
        );
    }
    return (
      <>
        <button
          className="back-button-product"
          onClick={() => this.props.cancleAddProduct()}>
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <div className="add-new-pruduct-container">
          <div className="product-name-continer">
            <input
              value={this.state.productName}
              onChange={(e) => {
                const value = e.target.value;
                if (value.startsWith(" ")) return; 
                this.setState({ productName: e.target.value })
                }}
              disabled={this.props.isEdit || this.props.isAdd}
              placeholder="Enter Product Name"></input>
            <input
              value={this.state.warranty}
              type="number"
              onChange={(e) => {
                const value = e.target.value;
                if (value == 0) {
                  this.setState({ warranty: "" });
                  return;
                }
                if (String(value).startsWith("0") || Number(value) < 1 || Number(value) > 36) {
                  return
                };
                this.setState({ warranty: Number(value) });
              }}
              onKeyDown={(e) => {
                if (e.key == "0" && e.target.value.length == 0 ) e.preventDefault(); 
                if (e.key == "-" || e.key == "e") e.preventDefault(); 
              }}
              disabled={this.props.isEdit || this.props.isAdd}
              placeholder="Enter Warranty in months"></input>
            <select
              value={this.state.group}
              disabled={this.props.isEdit || this.props.isAdd}
              onChange={(e) => this.setState({ group: e.target.value })}>
              <option>{`${(this.props.isEdit || this.props.isAdd) ? this.state.group_name : "Select Category"
                }`}</option>
              {this.state.groupData.map((item, index) => (
                <option value={item.id}>{item.group_name}</option>
              ))}
            </select>
          </div>
          <div className="product-variant-continer">
            <div id="nd-box-product" className="select-prod-comp">
              <div className="dropdown-wrap-problem">
                <Select
                  onChange={this.setColors}
                  options={optionBucketDrop}
                  isSearchable={true}
                  isDisabled={this.props.isEdit}
                  className="select-product-css selectProductDropdown"
                  value={this.state.selectedColor}
                  defaultValue={this.state.selectedColor}
                  maxMenuHeight={200}
                  placeholder="Search color.."
                  // closeMenuOnSelect={true}
                  styles={{
                    singleValue: (provided) => ({
                      ...provided,
                      color: "#fff !important",
                    }),
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: "1px solid #595959",
                      boxShadow: "0 !important",
                      "&:hover": {
                        border: "1px solid #595959 !important",
                      },
                      color: "#fff !important",
                      borderRadius: "20px !important",
                      backgroundColor: "#000 !important",
                      padding: "0 5px",
                      fontSize: "14px !important",
                      minHeight: "32px !important",
                      height: "32px !important",
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      background: state.isFocused
                        ? "#402500d7 !important"
                        : "#000 !important",
                      color: state.isFocused
                        ? "#FA9F22 !important"
                        : "#fff !important",
                      width: "100%",
                      fontSize: "14px !important",
                    }),
                    Input: (baseStyles, state) => ({
                      ...baseStyles,
                      color: state.isFocused ? "#fff !important" : "#000",
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      color: "#fff !important",
                    }),
                    indicatorSeparator: () => null,
                    valueContainer: (baseStyles) => ({
                      ...baseStyles,
                      minHeight: "auto !important",
                      height: "auto !important",
                      color: "#fff !important",
                      marginTop: "-13px !important",
                    }),
                    DropdownIndicator: (baseStyles) => ({
                      ...baseStyles,
                      height: "25px !important",
                      marginBottom: "-13px !important",
                    }),
                    menuList: (baseStyles) => ({
                      ...baseStyles,
                      background: "#000 !important",
                    }),
                  }}
                />
              </div>
            </div>
            <input
              value={this.state.selling}
              type="number"
              onChange={(e) => {
                const value = e.target.value;
                if (value == 0) {
                  this.setState({ selling: "" });
                  return;}
                if (String(value).startsWith("0") || Number(value) < 1) {
                  return};
                this.setState({ selling: Number(value) });
              }}
              onKeyDown={(e) => {
                if (e.key == "0" && e.target.value.length == 0) e.preventDefault(); 
                if (e.key == "-" || e.key == "e") e.preventDefault(); 
              }}

              placeholder="Enter selling Price"></input>
            <input
              value={this.state.price}
              type="number"
              onChange={(e) => {
                const value = e.target.value;
                if (value == 0) {
                  this.setState({ price: "" });
                  return;
                }
                if (String(value).startsWith("0") || Number(value) < 1) {
                  return
                };
                this.setState({ price: Number(value) });
              }}
              onKeyDown={(e) => {
                if (e.key == "0" && e.target.value.length == 0) e.preventDefault(); 
                if (e.key == "-" || e.key == "e") e.preventDefault(); 
              }}
              placeholder="Enter product Price"></input>
            <input
              value={this.state.sku}
              onChange={(e) => this.setState({ sku: e.target.value })}
              placeholder="Enter SKU"></input>
            <input
              value={this.state.sac}
              type="number"
              onChange={(e) => 
              {
                const value = e.target.value;
                if (Number(value) < 0) {
                  return; 
                }
                this.setState({ sac: value });
              }}
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "e") e.preventDefault(); 
              }}
              placeholder="Enter SAC Code"></input>
            <input
              value={this.state.hsn}
              type="number"
              onChange={(e) => 
                // this.setState({ hsn: e.target.value })}
              {
                const value = e.target.value;
                if (Number(value) < 0) {
                  return;
                }
                this.setState({ hsn: value });
              }}
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "e") e.preventDefault();
              }}
              placeholder="Enter HSN Code"></input>
            <input
              value={this.state.troubleShootUrl}
              onChange={(e) =>
                this.setState({ troubleShootUrl: e.target.value })
              }
              placeholder="Enter Torubleshoot Url"></input>
            <input
              value={this.state.imageUrl}
              onChange={(e) => this.setState({ imageUrl: e.target.value })}
              placeholder="Enter Image URL"></input>
            {this.state.group == 13 && (
              <input
                value={this.state.size}
                onChange={(e) => this.setState({ size: e.target.value })}
                placeholder="Enter Size"></input>
            )}
            <div
              style={{
                color: '#fff',
                fontSize: '25px',
                marginBottom: '10px',
                textAlign: 'left',
                display: 'block',
                width: '100%'
              }}
            >
              For Essential Products
            </div>
            <div className="date-fields-container">
              <div className="input-group">
                <label style={{ color: '#fff', fontSize: '14px' }}>Launch Date</label>
                <input
                  type="date"
                  value={this.state.launch_date}
                  onChange={(e) => this.setState({ launch_date: e.target.value })}
                />
              </div>

              <div className="input-group">
                <label style={{ color: '#fff', fontSize: '14px' }}>Buffer Date</label>
                <input
                  type="date"
                  value={this.state.buffer_date}
                  onChange={(e) => this.setState({ buffer_date: e.target.value })}
                />
              </div>

              <div className="extended-warranty-container">
                <label>Extended Warranty (months):</label>
                <input
                  type="number"
                  value={this.state.extended_warranty}
                  onChange={(e) => {
                     const value = e.target.value;
                    if (value == 0) {
                      this.setState({ extended_warranty: "" });
                      return;
                    }
                    if (String(value).startsWith("0") || Number(value) < 1 || Number(value) > 36) {
                      return
                    };
                    this.setState({ extended_warranty: Number(value) });
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "0" && e.target.value.length == 0) e.preventDefault(); 
                    if (e.key == "-" || e.key == "e") e.preventDefault(); 
                  }}  
                  placeholder="Extended Warranty"
                />
              </div>
            </div>
            <div className="add-product-button">
              <button onClick={() => this.props.cancleAddProduct()}>
                Cancel
              </button>
              {this.props.isEdit ? (
                <button onClick={() => this.handleUpdateVariant()}>
                  Update
                </button>
              ) : (
                <button onClick={() => this.handleVariantsubmit()}>Add</button>
              )}
            </div>
          </div>
          {this.state.variantArray?.length > 0 && (
            <>
              <div>
                <table className="product-body">
                  <tbody>
                    <tr>
                      <td>Color</td>
                      <td>Price</td>
                      <td>selling</td>
                      <td>SKU</td>
                      <td>SAC</td>
                      <td>HSN</td>
                      <td>Troubleshoot</td>
                      <td>Image</td>
                      {this.state.group == 13 && <td>Size</td>}
                      <td>Action</td>
                    </tr>
                  </tbody>
                </table>
                <table className="product-body">
                  <tbody>
                    {this.state.variantArray.map((item, index) => (
                      <tr>
                        <td>
                          <span className="text-wrap">
                            {item.color_name || "---"}
                          </span>
                        </td>
                        <td>
                          <span className="text-wrap">
                            {item.product_price || "---"}
                          </span>
                        </td>
                        <td>
                          <span className="text-wrap">
                            {item.selling_price || "---"}
                          </span>
                        </td>
                        <td>
                          <span className="text-wrap">
                            {item.sku_code || "---"}
                          </span>
                        </td>
                        <td>
                          <span className="text-wrap">
                            {item.sac_code || "---"}
                          </span>
                        </td>
                        <td>
                          <span className="text-wrap">
                            {item.hsn_code || "---"}
                          </span>
                        </td>
                        <td>
                          <span className="text-wrap">
                            {item.troubleshoot_url || "---"}
                          </span>
                        </td>
                        <td>
                          <span className="text-wrap">
                            {item.product_image_url || "---"}
                          </span>
                        </td>
                        {this.state.group == 13 && (
                          <td>
                            <span className="text-wrap">
                              {item.size_id || "---"}
                            </span>
                          </td>
                        )}
                        <td className="remove-product-button">
                          {
                            <button
                              onClick={() => this.handleRemoveColor(index)}>
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </button>
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="product-submit-add">
                <button onClick={() => this.productSubmit()}>Submit</button>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  clolorList: state.product.clolorList,
  groupData: state.product.groupData,
  editProductID: state.product.editProductID,
});

export default connect(mapStateToProps, {
  getProductColorData,
  getGroupData,
  setNewProduct,
  addnewVariant,
  addNewColor,
  updateVariant,
})(AddColorComponent);
