import axios from 'axios';
import {
  FETCH_BUCKETS,
  FETCH_TICKET_LIST,
  FETCH_STATUS_LIST,
  FAILURE_LIST,
  SUCCESS_LIST,
  SUCCESS_LIST_DI,
  FAILURE_LIST_DI,
  MAC_FAILURE_LIST,
  SUCCESS_LIST_BRPU,
  FAILURE_LIST_BRPU,
  SUCCESS_WAY_BILL_LIST,
  FAILURE_WAY_BILL_LIST,
  NEXT_BUTTON_DISABLE,
  FETCH_TICKET_COUNT,
  FETCH_BULK_CONSTANT,
  CLEAR_SUCCESS_LIST_BRPU,
  CLEAR_FAILURE_LIST_BRPU,
  CLEAR_SUCCESS_LIST,
  CLEAR_SUCCESS_LIST_DI,
  CLEAR_FAILURE_LIST,
  CLEAR_FAILURE_LIST_DI,
  SUCCESS_TESTING_INWARD_LIST,
  FAILURE_TESTING_INWARD_LIST,
  FAILURE_MAC_FAILURE_LIST,
  SUCCESS_TESTING_INWARD_LIST_CLEAR,
  FAILURE_MAC_FAILURE_LIST_CLEAR,
  FAILURE_TESTING_INWARD_LIST_CLEAR
} from "./types";
import { baseUrl } from '../config'
import history from '../history';

export const fetchBulkConstant = () => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.get(baseUrl + '/master/get_constant/4', { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: FETCH_BULK_CONSTANT,
          payload: res.data.data
        })
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/')
      }
    })
}

export const fetchAllBuckets = () => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/complaint/buckets', {}, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        let array = res.data.data
        array = array.filter((a) => Number(a.id) !== 0)
        dispatch({
          type: FETCH_BUCKETS,
          payload: array
        })
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/')
      }
    })
}

export const fetchTicketList = (body) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/complaint/get-complaint', body, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: FETCH_TICKET_LIST,
          payload: res.data.data.complaint_data
        })

        dispatch({
          type: FETCH_TICKET_COUNT,
          payload: res.data.data
        })

        dispatch({
          type: NEXT_BUTTON_DISABLE,
          payload: res.data.data.next_page
        })
      } else {
        dispatch({
          type: FETCH_TICKET_LIST,
          payload: []
        })
      }

    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/')
      }
    })
}


export const submitTransfer = (body) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/complaint/bulk-movement', body, { 'headers': { 'x-access-token': token } })
    .then(res => {
      dispatch({
        type: SUCCESS_LIST,
        payload: res.data.data.success
      })
      dispatch({
        type: FAILURE_LIST,
        payload: res.data.data.failure
      })
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/')
      }
    })
}

export const clearDIDataResults = () => async dispatch => {
  dispatch({
    type: CLEAR_SUCCESS_LIST_DI,
    payload: []
  })
  dispatch({
    type: CLEAR_FAILURE_LIST_DI,
    payload: []
  })
}

export const submitBulkRPU = (body) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/complaint/bulk-courier-gen', body, { 'headers': { 'x-access-token': token } })
    .then(res => {
      dispatch({
        type: SUCCESS_LIST_BRPU,
        payload: res.data.data.success
      })
      dispatch({
        type: FAILURE_LIST_BRPU,
        payload: res.data.data.failure
      })
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/')
      }
    })
}

export const clearBulkRpuDataResults = () => async dispatch => {
  dispatch({
    type: CLEAR_SUCCESS_LIST_BRPU,
    payload: []
  })
  dispatch({
    type: CLEAR_FAILURE_LIST_BRPU,
    payload: []
  })
}

export const clearBulkTransferDataResults = () => async dispatch => {
  dispatch({
    type: CLEAR_SUCCESS_LIST,
    payload: []
  })
  dispatch({
    type: CLEAR_FAILURE_LIST,
    payload: []
  })
}

export const submitDispatchInward = (body) => async dispatch => {
  let token = localStorage.getItem('token');
  // let token = "$2b$08$t8dmSsTqX6SaD5AH.M3wHOXWjR5/1uzAz6MhHB30jyA6p6MyIlq5i"

  return await axios.post(baseUrl + '/complaint/bulk-dispatch-inward', body, { 'headers': { 'x-access-token': token } })
    .then(res => {
      // dispatch({
      //   type: SUCCESS_LIST,
      //   payload: res.data.data.success
      // })

      dispatch({
        type: SUCCESS_LIST_DI,
        payload: res.data.data.success
      })
      dispatch({
        type: FAILURE_LIST_DI,
        payload: res.data.data.failure
      })
      dispatch({
        type: MAC_FAILURE_LIST,
        payload: res.data.data.macFailure
      })
      return res.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/')
      }
    })
}

export const submitDispatchInwardRetailer = (body) => async dispatch => {
  let token = localStorage.getItem('token');
  // let token = "$2b$08$t8dmSsTqX6SaD5AH.M3wHOXWjR5/1uzAz6MhHB30jyA6p6MyIlq5i"

  return await axios.post(baseUrl + '/distributor/bulk-dispatch-inward', body, { 'headers': { 'x-access-token': token } })
    .then(res => {
      // dispatch({
      //   type: SUCCESS_LIST,
      //   payload: res.data.data.success
      // })
      dispatch({
        type: SUCCESS_LIST_DI,
        payload: res.data.data.success
      })
      dispatch({
        type: FAILURE_LIST_DI,
        payload: res.data.data.failure
      })
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/')
      }
    })
}

export const selectedSubmitTransfer = (body) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/complaint/bulk-movement', body, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        alert(res.data.message)
      } else {
        alert("Bucket Movement Failed")
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/')
      }
    })
}


export const submitWayBillNumbers = (body) => async dispatch => {
  let token = localStorage.getItem('token');
  // let token = "$2b$08$t8dmSsTqX6SaD5AH.M3wHOXWjR5/1uzAz6MhHB30jyA6p6MyIlq5i"
  return await axios.post(baseUrl + '/complaint/handover', body, { 'headers': { 'x-access-token': token } })
    .then(res => {
      dispatch({
        type: SUCCESS_WAY_BILL_LIST,
        payload: res.data.data.success
      })
      dispatch({
        type: FAILURE_WAY_BILL_LIST,
        payload: res.data.data.failure
      })
      // if (res.data.success) {
      //   alert("Succesfully Moved")
      //   window.location.reload()
      // }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/')
      }
    })
}


export const submitWayBillNumbersToWarehouse = (body) => async dispatch => {
  let token = localStorage.getItem('token');
  // let token = "$2b$08$t8dmSsTqX6SaD5AH.M3wHOXWjR5/1uzAz6MhHB30jyA6p6MyIlq5i"
  return await axios.post(baseUrl + '/complaint/reverse-pickup-received', body, { 'headers': { 'x-access-token': token } })
    .then(res => {
      dispatch({
        type: SUCCESS_WAY_BILL_LIST,
        payload: res.data.data.success
      })
      dispatch({
        type: FAILURE_WAY_BILL_LIST,
        payload: res.data.data.failure
      })
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/')
      }
    })
}

export const fetchBucketStatus = (id) => async dispatch => {
  let token = localStorage.getItem('token');
  // let token = "$2b$08$t8dmSsTqX6SaD5AH.M3wHOXWjR5/1uzAz6MhHB30jyA6p6MyIlq5i"
  let body = {
    bucket_id: id
  }
  return await axios.post(baseUrl + '/complaint/status', body, { 'headers': { 'x-access-token': token } })
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: FETCH_STATUS_LIST,
          payload: res.data.data
        })
      }
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/')
      }
    })
}

export const qcFailBuckets = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/complaint/bulk-qc-fail', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      dispatch({
        type: SUCCESS_TESTING_INWARD_LIST,
        payload:res.data.data.success
      })
      dispatch({
        type: FAILURE_TESTING_INWARD_LIST,
        payload: res.data.data.failure
      })
      dispatch({
        type: FAILURE_MAC_FAILURE_LIST,
        payload: res.data.data.macFailure
      })
      return res.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/')
      }
    })
}

export const qcPassBuckets = (data) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/complaint/bulk-qc-pass', data, { 'headers': { 'x-access-token': token } })
    .then(res => {
      dispatch({
        type: SUCCESS_TESTING_INWARD_LIST,
        payload: res.data.data.success
      })
      dispatch({
        type: FAILURE_TESTING_INWARD_LIST,
        payload: res.data.data.failure
      })
      dispatch({
        type: FAILURE_MAC_FAILURE_LIST,
        payload: res.data.data.macFailure
      })
      return res.data
    })
    .catch((e) => {

      if (e.response.status === 401) {
        history.push('/')
      }
    })
}

export const  clearTEstingBulk = () => async dispatch => {
  dispatch({
    type: SUCCESS_TESTING_INWARD_LIST_CLEAR,
    payload: []
  })
  dispatch({
    type: FAILURE_TESTING_INWARD_LIST_CLEAR,
    payload: []
  })
  dispatch({
    type: FAILURE_MAC_FAILURE_LIST_CLEAR,
    payload: []
  })
}

export const complaintResend = (body) => async dispatch => {
  let token = localStorage.getItem('token');
  // let token = "$2b$08$t8dmSsTqX6SaD5AH.M3wHOXWjR5/1uzAz6MhHB30jyA6p6MyIlq5i"
  return await axios.post(baseUrl + '/customer/send-complaint-registration-link', body, { 'headers': { 'x-access-token': token } })
    .then(res => {
      return res.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/')
      }
    })
}

export const fetchoosIQC = (body) => async dispatch => {
  let token = localStorage.getItem('token');
  // let token = "$2b$08$t8dmSsTqX6SaD5AH.M3wHOXWjR5/1uzAz6MhHB30jyA6p6MyIlq5i"
  return await axios.post(baseUrl + '/complaint/oos-to-qc-fail', body, { 'headers': { 'x-access-token': token } })
    .then(res => {
      return res.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/')
      }
    })
}

export const iqxToTestingAction = (body) => async dispatch => {
  let token = localStorage.getItem('token');
  return await axios.post(baseUrl + '/complaint/qc-fail-to-testing-invard', body, { 'headers': { 'x-access-token': token } })
    .then(res => {
      return res.data
    })
    .catch((e) => {
      if (e.response.status === 401) {
        history.push('/')
      }
    })
}