import { FETCH_LOGIN_RESPONSE,FETCH_LOGIN_USER_DATA,FETCH_USER_DATA,GET_USER_DETAIL,SET_USER_DATA,EDIT_USER_DATA,FETCH_ACCESSLAVEL_DATA,FETCH_REPORTING_DATA,GET_COMPLAINT_BUCKET,FETCH_TICKET_DATA,USER_DELETE, SET_ASSIGN_DATA,
  UNASSIGNED_COMPLAINT_LIST, TICKET_ASSIGNED,GET_STATE_LIST,GET_CITY_LIST
} from '../actions/types';

const initialState = {
  userData: [],
  userDetail: [],
  lotResponse:[],
  editData:[],
  accessData: [],
  reportData:[],
  bucketData: [],
  unassingTicketData:[],
  deleteData:[],
  assignData:[],
  user : {},
  userLogin : {},
  unAssignedComplaintList : [],
  loginRes : {},
  ticketAssigned: {},
  stateData:[],
  cityData:[],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_DATA:
      return {
        ...state,
        userData: action.payload
      };
      case GET_USER_DETAIL:
      return {
        ...state,
        userDetail: action.payload,
      };
      case SET_USER_DATA:
        return {
          ...state,
            lotResponse: action.payload,
          };
      case EDIT_USER_DATA:
        return {
          ...state,
            editData: action.payload,
        };
        case FETCH_ACCESSLAVEL_DATA:
        return {
          ...state,
          accessData: action.payload,
        };
        case FETCH_REPORTING_DATA:
        return {
          ...state,
          reportData: action.payload,
        };
        
        case GET_COMPLAINT_BUCKET:
        return {
          ...state,
          bucketData: action.payload,
        };

        case FETCH_TICKET_DATA:
        return {
          ...state,
          unassingTicketData: action.payload,
        };

        case USER_DELETE:
        return {
          ...state,
          deleteData: action.payload,
        };
        case SET_ASSIGN_DATA:
          return {
            ...state,
            assignData: action.payload,
          };
          case FETCH_LOGIN_USER_DATA:
            return {
              ...state,
              userLogin: action.payload,
            };  
            case FETCH_LOGIN_RESPONSE:
              return {
                ...state,
                loginRes: action.payload,
              };  
            case UNASSIGNED_COMPLAINT_LIST:
              return {
                ...state,
                unAssignedComplaintList: action.payload,
              };  
              case TICKET_ASSIGNED:
                return {
                  ...state,
                  ticketAssigned: action.payload,
                }; 
                case GET_STATE_LIST:
                  return {
                    ...state,
                    stateData: action.payload
                  }; 
                  case GET_CITY_LIST:
                    return {
                      ...state,
                      cityData: action.payload
                  };
    default:
      return state;
  }
}
