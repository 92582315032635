import React, { Component } from "react";
import history from "../history";
import ReactModal from "react-modal";
import SidebarComponent from "./SidebarComponent/SidebarComponent";

const customStylesDO = {
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    inset: "0% 0 0 auto",
    marginRight: "0",
    transform: "translate(0)",
    background: "#000",
    padding: "2rem",
    border: "none",
    width: "100%",
    position: "absolute",
  },
};

class ViewProfile extends Component {
  constructor() {
    super();
    this.state = {
      isburger: false,
    };
  }

  handleLogout = () => {
    setTimeout(() => {
      this.logOut();
    }, 500);
  };

  logOut = () => {
    localStorage.removeItem("token");
    history.push("/");
  };

  render() {
    let userFName = localStorage.getItem("userFirstName");
    let userLName = localStorage.getItem("userLastName");
    let userName = userFName + " " + userLName;

    return (
      <>
        <div>
          <div className="noiselogo">
            <img src="/img/nlogo.png" alt="" />
            <div className="logophone">
              <div className="dashboard">
                <span>
                  <i
                    className="fas fa-user-circle"
                    data-toggle="modal"
                    data-target="#exampleModals"
                  ></i>{" "}
                </span>
              </div>

              <div>
                <span>
                  <i
                    onClick={() => this.setState({ isburger: true })}
                    className="fas fa-bars"
                  ></i>{" "}
                </span>
              </div>
            </div>
          </div>

          <div className="modal fade" id="exampleModals">
            <div className="modal-dialog" role="document">
              <div className="mo">
                <div className="header">
                  <div className="list2">
                    <span>
                      <i className="fas fa-user-circle"></i>{" "}
                      <p>
                        {userName}
                        <br />
                        <a href="/settings" className="v">
                          View profile <i className="fas fa-chevron-right"></i>
                        </a>
                      </p>
                    </span>
                  </div>
                </div>

                <button
                  className="log"
                  onClick={this.handleLogout}
                  data-dismiss="modal"
                  style={{ background: "none" }}
                >
                  <i className="fas fa-power-off"></i>
                  <p>Log Out</p>
                </button>
              </div>
            </div>
          </div>

          <div className="nav dashboard-webview">
            <span>
              <i
                className="fas fa-user-circle"
                data-toggle="modal"
                data-target="#exampleModals"
              ></i>{" "}
            </span>
          </div>
        </div>

        <ReactModal
          isOpen={this.state.isburger}
          contentLabel="Minimal Modal Example"
          style={customStylesDO}
          ariaHideApp={false}
        >
          <button id="close_rModal">
            <i
              className="fas fa-times"
              onClick={() => this.setState({ isburger: false })}
            ></i>
          </button>
          <SidebarComponent sideBarChange={this.sideBarChange} />
        </ReactModal>
      </>
    );
  }
}

export default ViewProfile;
