import { combineReducers } from 'redux';
import complaints from './complaints';
import user from './user';
import dashboard from './dashboard';
import warranty from './warranty';
import product from './product';
import report from './report';
import bulkmovement from './bulkmovement';
import serviceCenter from './serviceCenter';
import distributor from './distributor';
import serviceCenterComplaint from './serviceCenterComplaint';

export default combineReducers({
  complaints : complaints,
  user: user,
  dashboard :  dashboard,
  warranty : warranty,
  product : product,
  report : report,
  bulkmovement : bulkmovement,
  serviceCenter:serviceCenter,
  distributor:distributor,
  serviceCenterComplaint: serviceCenterComplaint
  // user : userReducer,
});
