import React, { Component } from 'react'
import './Settings.css';
import { connect } from "react-redux";
import history from '../../history';
import Loader from 'react-loader-spinner'
import { getServiceCenters, updateCenterInfo, removeServiceCenter, callServiceCenter, downlaodCenterReportData } from '../../actions/serviceCenterActions'
import { fetchDownloadLimit } from '../../actions/reportActions'
import { getCityByState, getStateList } from '../../actions/complaintsDataAction';
import { getUserDetail } from '../../actions/userActions'
import ReactModal from 'react-modal';
import ViewProfile from '../ViewProfile'
import AccessRestricted from '../AccessRestricted'
import ComplaintRegistration from '../ComplaintRegistration';
import Footer from '../FooterComponent/footer';
import SidebarComponent from '../SidebarComponent/SidebarComponent';

const styleEdit = {
    overlay: {
        background: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
        inset: '8% 0 0 auto',
        marginRight: '0',
        transform: 'translate(0)',
        background: '#000',
        padding: '0',
        border: 'none',
        height: '100%',
        overflow: 'hidden',
        overflowY: 'scroll',
        position: 'fixed',
        width: '40%',
        float: 'right',
        display: 'inline-block'
    },
};

class ServiceCenter extends Component {
    constructor() {
        super();
        this.state = {
            centerName: '',
            pinCode: '',
            serviceCenters: '',
            reporting: '',
            accessLevel: '',
            logout: false,
            //showMenu: false,
            pageNo: 0,
            pageLimit: 10,
            searchText: '',
            //searchDate: '',
            userList: [],
            userDetailList: [],
            userId: '',
            firstName: '',
            lastName: '',
            userName: '',
            mobile: '',
            accessLavel: '',
            reportingTo: '',
            isReporter: '',
            first_name: '',
            last_name: '',
            username: '',
            fields: {},
            errors: [],
            accessList: [],
            reportList: [],
            reportingList: [],
            accessId: 0,
            reportingId: 0,
            is_reporter: 0,
            reporting_to: 0,
            access_lavel: '',
            user_role: '',
            user_reporting: '',
            loader: false,
            limit: 10,
            totalcount: '',
            prevPage: false,
            nextPage: false,
            showChangePassword: false,
            isPasswordShown1: false,
            isPasswordShown2: false,
            dropDownOpen: false,
            openEditService: false,
            state: '',
            city: '',
            address: '',
            current_page: 1,
            isSearch: false,
            addNewServiceCenter: false,
            count: 0,
            inputList: [],
            setInputList: [],
            fname: '',
            lname: '',
            phone: '',
            email: '',
            city_id: 0,
            state_id: 0,
            cityList: [],
            stateList: [],
            serviceCenterId: '',
            serviceCenterSpocId: '',
            openCallOption: false,
            showNum: false
        }
        //  this.showMenu = this.showMenu.bind(this);
        //  this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLogout = this.handleLogout.bind(this)
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleClosePassword = this.handleClosePassword.bind(this);
        this.openDropDown = this.openDropDown.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.handleChangeSpocPerson = this.handleChangeSpocPerson.bind(this);
        // this.callOption = this.callOption.bind(this);
    }

    handleChangeSpocPerson(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    openDropDown() {
        //  alert("hit")
        this.setState({
            dropDownOpen: true
        })
    }

    handleClosePassword() {
        this.setState({
            showChangePassword: false
        })
    }

    handleKeyUp = (e) => {
        this.value = e.target.value;
    }

    handleLogout = () => {
        localStorage.removeItem('token')
        document.querySelector(".modal-backdrop").style.display = "none";
        history.push('/')
    }

    handleIconDetails() {
        this.setState({
            logout: !this.state.logout
        })
    }

    onClearForm = (e) => {
        this.setState({
            firstName: '',
            lastName: '',
            userName: '',
            email: '',
            mobile: '',
            accessLavel: '',
            reportingTo: '',
            isReporter: '',
            first_name: '',
            last_name: '',
            username: ''
        })
    }

    componentDidMount() {
        this.getUserInfo();
        this.getServiceCenterList();
        this.getStateListApi();
        this.getCityListApi(this.state.state_id);
    }

    getServiceCenterList = async () => {
        let offset = (this.state.current_page - 1);
        if (this.state.searchText.length > 0) {
            let data = {
                "limit": 10,
                "offset": offset,
                "search_by": this.state.searchText
            }
            this.setState({ isLoading: true })
            await this.props.getServiceCenters(data);
            // let serviceData = this.props.serviceData;
            if (this.props.serviceData) {
                let insideServiceData = this.props.serviceData.data;
                let serviceCenters = this.props.serviceData.data.serviceCenters;
                this.setState({
                    serviceCenters: serviceCenters,
                    isLoading: false,
                    totalCount: insideServiceData.total,
                    totalPage: insideServiceData ? insideServiceData.no_page : 0,
                    prevPage: insideServiceData ? insideServiceData.prev_page : 0,
                    nextPage: insideServiceData ? insideServiceData.next_page : 1,
                    isSearch: true
                })
            }
        } else {
            let data = {
                "limit": 10,
                "offset": offset,
            }
            //   let serviceCenters = [];
            await this.props.getServiceCenters(data);
            // let serviceData = this.props.serviceData;
            let insideServiceData = this.props.serviceData?.data;
            let serviceCenters = this.props.serviceData?.data?.serviceCenters;
            this.setState({
                serviceCenters: serviceCenters && serviceCenters,
                isLoading: false,
                totalCount: insideServiceData?.total,
                totalPage: insideServiceData ? insideServiceData?.no_page : 0,
                prevPage: insideServiceData ? insideServiceData?.prev_page : 0,
                nextPage: insideServiceData ? insideServiceData?.next_page : 1,
                // complaintList: complaintsData.data.complaint_data,
            })
        }
        //let serviceCenterList = this.props.serviceData.serviceCenters;
    }

    getUserInfo = async () => {
        let userId = localStorage.getItem('userId');
        let data = {
            "user_id": userId,
        }
        await this.props.getUserDetail(data)
        let editRes = this.props.userDetail.data;
        let access_level = editRes.user_role[0] ? editRes.user_role[0].name : 'No Role assigned';
        this.setState({ accessLevel: access_level })
        if (editRes && editRes.user_role.length > 0 && editRes.user_role[0] && [1, 2, 5].includes(editRes.user_role[0].id)) {
            await this.props.fetchDownloadLimit()
        }
    }

    openEditModal = (e, item) => {
        this.setState({
            openEditService: true, centerName: item.asp_name, pinCode: item.pincode, state: item.state_name, city: item.city, address: item.address, state_id: item.state_id, city_id: item.city_id, serviceCenterId: item.id, fname: item.spoc[0].contact_person, serviceCenterSpocId: item.spoc[0].service_center_id,
            email: item.spoc[0].email, mobile: item.spoc[0].mobile_number, phone: item.spoc[0].phone_number
        });
        let errors = {};
        errors["phone"] = '';
        errors["centerName"] = '';
        errors["pinCode"] = '';
        errors["state"] = '';
        errors["city"] = '';
        errors["address"] = '';
        errors["email"] = '';
        errors["mobile"] = '';
        errors["phone"] = '';
        errors["fname"] = '';
        this.setState({ errors: errors });
    }

    handleCloseEditModal() {
        this.setState({ openEditService: false })
    }

    handleEdit = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleValidation() {
        let errors = {};
        let formValidity = true;
        if (this.state.centerName === '') {
            formValidity = false;
            errors["centerName"] = "Service Center name cannot be empty";
        }
        if (this.state.centerName !== '') {
            var specialChars = `\`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`;
            let centerName = this.state.centerName;
            const result = specialChars.split('').some(specialChar => {
                if (centerName.includes(specialChar)) {
                    return true;
                }
                return false;
            });
            if (result) {
                formValidity = false;
                errors["centerName"] = "Service Center format not correct";
            }
        }
        if (this.state.fname === '') {
            formValidity = false;
            errors["fname"] = "Contact person's name cannot be empty";
        }
        if (this.state.fname !== '') {
            var specialChars = `\`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`;
            let fname = this.state.fname;
            const result = specialChars.split('').some(specialChar => {
                if (fname.includes(specialChar)) {
                    return true;
                }
                return false;
            });
            if (result) {
                formValidity = false;
                errors["fname"] = "Please enter only alphabets";
            }
        }
        if (this.state.pinCode === '') {
            formValidity = false;
            errors["pinCode"] = "Pin code cannot be empty";
        }
        if (this.state.pinCode !== '') {
            var pattern = new RegExp(/^[0-9]/);
            let pinCode = this.state.pinCode;
            if (!pattern.test(pinCode) || (pinCode.length < 6)) {
                formValidity = false;
                errors["pinCode"] = "Incorrect pin code format";
            }
        }
        if (this.state.state_id === 0) {
            formValidity = false;
            errors["state"] = "State cannot be empty";
        }
        if (this.state.city_id === 0) {
            formValidity = false;
            errors["city"] = "City cannot be empty";
        }
        if (this.state.address === '') {
            formValidity = false;
            errors["address"] = "Address cannot be empty";
        }
        if (this.state.email !== '') {
            const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i
            let validEmail = expression.test(String(this.state.email).toLowerCase());
            if (!validEmail) {
                formValidity = false;
                errors["email"] = "Email format is not correct";
            }
        }
        if (this.state.mobile === '') {
            formValidity = false;
            errors["mobile"] = "Enter mobile number.";
        }
        if (this.state.mobile !== '') {
            var pattern = new RegExp(/^[0-9]{6,12}$/);
            let mobile = this.state.mobile;
            if (!pattern.test(mobile)) {
                formValidity = false;
                errors["mobile"] = "Enter valid mobile number.";
            }
        }
        if (this.state.phone !== '') {
            var pattern = new RegExp(/^[0-9]{6,12}$/);
            let phone = this.state.phone;
            if (!pattern.test(phone)) {
                formValidity = false;
                errors["phone"] = "Enter valid phone number.";
            }
        }
        this.setState({ errors: errors });
        return formValidity;
    }

    editCenterInfo = async () => {
        if (this.handleValidation() !== false) {
            let spocId = this.state.serviceCenterSpocId;
            let ServiceCenterId = this.state.serviceCenterId;
            let data = {
                "asp_name": this.state.centerName,
                "pincode": this.state.pinCode,
                "address": this.state.address,
                "status": "1",
                "country_id": 1,
                "city_id": this.state.city_id,
                "state_id": this.state.state_id,
                "service_center_id": ServiceCenterId ? ServiceCenterId : null,
                "service_spocs": [
                    {
                        "contact_person": this.state.fname,
                        "mobile_number": this.state.mobile,
                        "phone_number": this.state.phone,
                        "email": this.state.email,
                        "status": "1",
                        "service_spoc_id": spocId ? spocId : null
                    }
                ]
            }
            await this.props.updateCenterInfo(data);
            let res = this.props.newServiceData;
            alert(res.message);
            if (res.success) {
                this.setState({
                    addNewServiceCenter: false,
                    openEditService: false,
                    centerName: '',
                    pinCode: '',
                    address: '',
                    mobile: '',
                    phone: '',
                    fname: '',
                    lname: '',
                    email: '',
                    city_id: 0
                })
                this.getServiceCenterList();
                this.getStateListApi();
                this.getCityListApi();
            }
        }
    }

    handleRemove = async (e, item) => {
        let data = {
            "id": item.id
        }
        await this.props.removeServiceCenter(data);
        let serviceListRemoved = this.props.serviceListRemoved;
        if (serviceListRemoved.success) {
            this.getServiceCenterList();
            this.getStateListApi();
            this.getCityListApi();
        }
    }

    onPrevPress = () => {
        if (this.props.paginationCount.prevPage) {
            // let new_page = (this.state.new_page1 - 1);
            // this.setState({new_page1: new_page});
            this.setState({ current_page: (this.state.current_page - 1) }, () => {
                this.getServiceCenterList()
            })
        }
    }

    onNextPress = () => {
        if (this.props.paginationCount.next_page) {
            // let new_page = (this.state.new_page1 - 1);
            // this.setState({new_page1: new_page});
            this.setState({ current_page: (this.state.current_page + 1) }, () => {
                this.getServiceCenterList()
            })
        }
        // if (this.state.nextPage) {
        //   this.setState({ pageNo: (this.state.pageNo + 1) }, () => {
        //     this.getComplaintList()
        //   })
        // }
    }

    handleChangeEvent = async (e) => {
        this.setState({ searchText: e.target.value.replace(/^\s+|\s+$/gm, ''), current_page: 1 })
        if (e.target.value.length === 0) {
            this.setState({ isSearch: false, searchText: '', }, () => {
                this.getServiceCenterList()
            })
        }
    }

    _handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.handleChangeButton(e)
        }
    }

    handleClose = async (e) => {
        document.getElementById("search_input").value = '';
        this.setState({ current_page: (this.state.current_page + 1), searchText: '', current_page: 1, isSearch: false }, () => {
            this.getServiceCenterList()
        })
        // await this.props.showComplaintDetailsAction(false);
        // this.getComplaintList();
    }

    handleChangeButton = async (e) => {
        e.preventDefault();
        // this.handleRefreshList();
        let val = document.getElementById("search_input").value.replace(/^\s+|\s+$/gm, '');
        if (val.length > 0) {
            this.getServiceCenterList();
            this.setState({
                isLoading: false
            })
        }
        // await this.props.showComplaintDetailsAction(false);
        // this.setState({ searchText: val, current_page: 1, assignedStatus: false }, () => {
        //   this.getComplaintList();
        // })
    }

    openAddServiceCenter = () => {
        this.setState({
            addNewServiceCenter: true,
            serviceCenterSpocId: '',
            centerName: '',
            pinCode: '',
            address: '',
            mobile: '',
            phone: '',
            fname: '',
            email: '',
            serviceCenterId: '', state_id: 0, city_id: 0
        })
    }

    handleCloseAddServiceCenter = () => {
        this.setState({ addNewServiceCenter: false })
    }

    addNew = () => {
        this.setState({ count: this.state.count + 1 })
    }

    onStateChange = (e) => {
        this.setState({ city_id: 0, state_id: Number(e.target.value) }, () => {
            this.getCityListApi(this.state.state_id);
        })
    }

    getStateListApi = async (param) => {
        await this.props.getStateList(param)
        this.setState({ stateList: [] })
        let stateDataList = this.props.stateDataList
        this.setState({
            stateList: stateDataList.data,
        })
    }

    onCityChange = (e) => {
        this.setState({ city_id: e.target.value }, () => {
            this.getCityListApi(this.state.state_id);
        })
    }

    getCityListApi = async (param) => {
        let data = {
            "state_id": this.state.state_id
        }
        await this.props.getCityByState(data)
        this.setState({ cityList: [] })
        let cityDataList = this.props.cityDataList
        this.setState({
            cityList: cityDataList.data,
        })
    }

    // callOption = (e, id) => {
    //     let showNum = document.getElementById(id)
    //         showNum.classList.add("show");
    // }

    handleCallServiceCenter = async (e, id) => {
        if (id !== '') {
            await this.props.callServiceCenter(id);
            // let res = this.props.callServiceCenter;
        } else {
            alert("No phone number found!")
        }
    }

    downloadCenterReport = async () => {
        await this.props.downlaodCenterReportData()
    };

    render() {
        let serviceData = this.state.serviceCenters;
        //   let paginationCount = this.props.serviceData.data;
        let new_page = ((this.state.totalPage - (this.state.totalPage - this.state.current_page)) - 1);
        let userRoleId = localStorage.getItem('userRoleId');
        if (userRoleId === '4' || userRoleId === '8' || userRoleId === '9' || userRoleId === '10') {
            return (<AccessRestricted />)
        }
        else if (userRoleId === '6') {
            return (<ComplaintRegistration />)
        }
        else {
            return (
                <div>
                    <div>
                        { window.innerWidth > 600 && <SidebarComponent sideProp={'SS'} />}
                        <div className="modal fade" id="notification">
                            <div className="modal-dialog" role="document">
                                <p>Notifications(4)</p>
                                <ul id="menu"><li><a href="#" className="noactive">All</a></li><li className="actice2"><a href="/">Unread</a></li></ul>
                                <div data-dismiss="modal" aria-label="Close" className="closeModal">
                                    <span aria-hidden="true"><i className="fas fa-times"></i></span>
                                </div>
                            </div>
                        </div>
                        <div className="nav dashboard">
                            <span>
                                <i className="fas fa-user-circle" data-toggle="modal" data-target="#exampleModals"></i> </span>
                        </div>
                        <ViewProfile />
                    </div>
                    <div className="content manage-user-main">
                    <div className='search_header_product'>
                        <span className="complaint-head">Settings</span>
                        <div className='search' style={{marginRight:'unset'}}>
                            <input type="username" name="searchText" id="search_input" placeholder="Search by city, mobile..." onChange={this.handleChangeEvent} onKeyDown={this._handleKeyDown} />
                            {this.state.searchText &&
                                <button type="button" className="close-search" onClick={this.handleClose}><i className="fa fa-times" aria-hidden="true"></i></button>
                            }
                            <button type="button" className="add-user-btn-input" onClick={this.handleChangeButton}><i className="fa fa-search user_search_icon"></i></button>
                        </div>
                        </div>
                        <ul id="menu">
                            <li><a href="/settings">Profile</a></li>
                            <li className="actice2"><a href="/service-center">Service Center</a></li>



                            {/* <li><a href="/manage-user">Waybill</a></li>
                                <li><a href="/manage-user">Courier</a></li> */}
                        </ul>
                       
                        {
                            this.props.userDetail.data && this.props.userDetail.data.user_role?.length > 0 && [1, 2, 5].includes(this.props.userDetail.data.user_role[0].id) ?
                                <div className="addServiceCenter" >
                                    <button type="button" style={{ fontSize: "unset" }} className="bbts" onClick={this.openAddServiceCenter}>Add Service Center</button>
                                </div>
                                : null
                        }
                        {
                            this.props.serviceReportConfig.type && this.props.userDetail.data && this.props.userDetail.data.user_role.length > 0 && [1, 2, 5].includes(this.props.userDetail.data.user_role[0].id) && this.props.userDetail.data.team === "WH" ?
                                <div className="downloadservicecenter" >
                                    <button type="button" style={{ height: "50px", fontSize: "unset" }} className="dsc" onClick={this.downloadCenterReport} disabled={!this.props.serviceReportConfig.canDownload}>Export</button>
                                </div>
                                : null
                        }
                        {this.state.isLoading ?
                            <div className='loader'>
                                <Loader
                                    type="Oval"
                                    color="Grey"
                                    height={200}
                                    width={200}
                                />
                            </div>
                            :
                            <div className='service-center-wrapper'>
                                {this.state.isSearch &&
                                    <div className='search_title'>Search Results</div>
                                }
                                {this.props.serviceData ?
                                    <div>
                                        <div className='colrow service-center'>
                                            <div className='row header-row'>
                                                <div className='col-sm'>
                                                    Name
                                                </div>
                                                <div className='col-sm'>
                                                    Contact Person
                                                </div>
                                                <div className='col-sm'>
                                                    Address
                                                </div>
                                                <div className='col-sm'>
                                                    State
                                                </div>
                                                <div className='col-sm'>
                                                    City
                                                </div>
                                                <div className='col-sm'>
                                                </div>
                                            </div>
                                            {serviceData
                                                ?
                                                serviceData.map((item, index) => (
                                                    <div className='row list-row' key={item.id}>
                                                        <div className='col-sm'>
                                                            {item.asp_name}
                                                        </div>
                                                        <div className='col-sm'>
                                                            {item.spoc[0] &&
                                                                <div>
                                                                    {item.spoc[0].contact_person}
                                                                    <p className="serviceEmail">{item.spoc[0].email}</p>
                                                                    <p className="serviceMobile">{item.spoc[0].mobile_number}</p>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className='col-sm'>
                                                            {item.address}
                                                        </div>
                                                        <div className='col-sm'>
                                                            {item.state_name}
                                                        </div>
                                                        <div className='col-sm'>
                                                            {item.city}
                                                        </div>
                                                        <div style={{ display: "flex" }}>
                                                            <div className='col-sm icon_wrapper icon_wrapper2'>
                                                                <button className="btn btn-link " type="button" id="dropdownMenuButton1" onClick={(e) => this.handleCallServiceCenter(e, item.id)}>
                                                                    <i className="fas fa-phone" title="Call option"></i>
                                                                </button>
                                                                {/* <div className="dropdown-menu menuServiceCenter" aria-labelledby="dropdownMenuButton1" >
                                                            {item.spoc[0].mobile_number == '' && item.spoc[0].phone_number == '' ?
                                                                        <a>No record</a>
                                                                        :
                                                                        <div>
                                                                            <a className="dropdown-item" >{item.spoc[0].mobile_number}</a>
                                                                            <a className="dropdown-item" >{item.spoc[0].phone_number}</a>
                                                                        </div>
                                                                    }
                                                            </div> */}
                                                            </div>
                                                            {
                                                                this.props.userDetail.data && this.props.userDetail.data.user_role.length > 0 && [1, 2, 5].includes(this.props.userDetail.data.user_role[0].id) ?
                                                                    <div className='col-sm icon_wrapper'>
                                                                        {/* <a className="btn btn-link " onClick={(e) => this.callOption(e, item.id)}>
                                                                <i className="fas fa-phone" title="Call option"></i>
                                                            </a>                                                         
                                                                <div id={item.id}  className="dropdownCall">
                                                                    {item.spoc[0].mobile_number == '' && item.spoc[0].phone_number == '' ?
                                                                        <a>No record</a>
                                                                        :
                                                                        <div>
                                                                            <a className="dropdown-item" >{item.spoc[0].mobile_number}</a>
                                                                            <a className="dropdown-item" >{item.spoc[0].phone_number}</a>
                                                                        </div>
                                                                    }
                                                                </div> */}

                                                                        <button className="btn btn-link " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                                                                            <i className="fas fa-ellipsis-h"></i>
                                                                        </button>
                                                                        <div className="dropdown-menu menuServiceCenter" aria-labelledby="dropdownMenuButton" >
                                                                            <a className="dropdown-item" id="editServiceC" onClick={(e) => this.openEditModal(e, item)}><i className="fas fa-pencil-alt"></i>Edit</a>
                                                                            <a className="dropdown-item" id="removeServiceC" onClick={(e) => this.handleRemove(e, item)}><i className="fas fa-exclamation-circle"></i>Remove</a>
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>

                                                    </div>
                                                ))
                                                :
                                                <p>Error</p>
                                            }


                                        </div>
                                        <Footer new_page={new_page} onPrevPress={this.onPrevPress} onNextPress={this.onNextPress} current_page={this.state.current_page} cc={"kk"} />
                                    </div>
                                    :
                                    <div style={{ color: "#fff" }}>No result found</div>
                                }
                            </div>
                        }
                    </div>
                    <ReactModal
                        isOpen={this.state.addNewServiceCenter}
                        style={styleEdit}
                        ariaHideApp={false}
                        className="modal-header"
                    >
                        <div className="Et service_center">
                            <span>Add Service Center</span>
                            <button id="close_rModal" onClick={this.handleCloseAddServiceCenter}><i className="fas fa-times"></i></button>
                        </div>
                        <div className="info_wrapper service_center">
                            <div className="wrapper">
                                <div className="lbl">
                                    <input type="text" id="centerName" placeholder="Service Center Name" name="centerName" value={this.state.centerName} onChange={this.handleEdit} />
                                    <div className="error_add">
                                        <span className="errorShow">{this.state.errors["centerName"]}</span>
                                    </div>
                                </div>
                                <div className="lbl">
                                    <input type="text" id="centerName" placeholder="Pincode" name="pinCode" value={this.state.pinCode} onChange={this.handleEdit} maxLength={6} />

                                    <div className="error_add">
                                        <span className="errorShow">{this.state.errors["pinCode"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="wrapper">
                                <div className="lbl">
                                    <select className="dropbtn1111 service_center" onChange={(e) => this.onStateChange(e)} name="state" placeholder="Enter State">
                                        <option value={'0'}> Select State</option>
                                        {this.state.stateList.map((obj) => (
                                            <option key={obj.id} value={obj.id} selected={Number(obj.id) === Number(this.state.state_id)}>{obj.state_name}</option>
                                        ))}
                                    </select>
                                    {/* <input type="text" placeholder="State" name="state" value={this.state.state} onChange={this.handleEdit} /> */}
                                    <div className="error_add">
                                        <span className="errorShow">{this.state.errors["state"]}</span>
                                    </div>
                                </div>
                                <div className="lbl">
                                    {/* <input type="text" placeholder="City" name="city" value={this.state.city} onChange={this.handleEdit} /> */}
                                    <select className="dropbtn1111 service_center" onChange={(e) => this.onCityChange(e)}>
                                        <option value={'0'}> Select City</option>
                                        {this.state.cityList?.map((obj) => (
                                            <option key={obj.id} value={obj.id} selected={Number(obj.id) === Number(this.state.city_id)}>{obj.city}</option>
                                        ))}
                                    </select>
                                    <div className="error_add">
                                        <span className="errorShow">{this.state.errors["city"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="lbl adress">
                                <input type="text" id="centerName" placeholder="Address" name="address" value={this.state.address} onChange={this.handleEdit} />
                                <div className="error_add">
                                    <span className="errorShow">{this.state.errors["address"]}</span>
                                </div>
                            </div>
                            <div className="addSpoc">
                                <div className="wrapper spoc">
                                    <p style={{ color: "#fff" }}>Contact Person</p>
                                    <div className="row row1">
                                        <div className="input-wrapper" id="fname-wrapper">
                                            <input id="fname" type="text" placeholder="Full Name..." name="fname" value={this.state.fname} onChange={this.handleChangeSpocPerson} />
                                        </div>
                                        <div className="error_add">
                                            <span className="errorShow">{this.state.errors["fname"]}</span>
                                        </div>
                                        {/* <div className="input-wrapper">
                                                                    <input id="lname" type="text" placeholder="Last Name" name="lname" value={this.state.lname} onChange={this.handleChangeSpocPerson} />
                                                                </div> */}
                                    </div>
                                    <div className="row row2">
                                        <div className="input-wrapper" id="mobile-wrapper">
                                            <input id="mobile" type="text" maxLength={12} placeholder="Mobile Number..." name="mobile" value={this.state.mobile} onChange={this.handleChangeSpocPerson} />
                                            <div className="error_add">
                                                <span className="errorShow">{this.state.errors["mobile"]}</span>
                                            </div>
                                        </div>
                                        <div className="input-wrapper">
                                            <input id="phone" type="text" maxLength={12} placeholder="Phone Number..." name="phone" value={this.state.phone} onChange={this.handleChangeSpocPerson} />
                                            <div className="error_add">
                                                <span className="errorShow">{this.state.errors["phone"]}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row row3'>
                                        <div className="input-wrapper">
                                            <input id="email" type="text" placeholder="Email..." name="email" value={this.state.email} onChange={this.handleChangeSpocPerson} />
                                            <div className="error_add">
                                                <span className="errorShow">{this.state.errors["email"]}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <AddSpoc spocInfo={this.spocInfo} /> */}
                        <button id="updateServiceCenter" onClick={this.editCenterInfo}>Add Service Center</button>
                    </ReactModal>
                    <ReactModal
                        isOpen={this.state.openEditService}
                        contentLabel="Minimal Modal Example"
                        style={styleEdit}
                        ariaHideApp={false}
                        className="modal-header"
                    >
                        <div className="Et service_center">
                            <span>Edit Service Center</span>
                            <button id="close_rModal" onClick={this.handleCloseEditModal}><i className="fas fa-times"></i></button>
                        </div>
                        <div className="info_wrapper">
                            <div className="wrapper">
                                <div className="lbl">
                                    <p>Service Center Name<sup>*</sup></p>
                                    <input type="text" placeholder="Enter Service Center Name" name="centerName" value={this.state.centerName} onChange={this.handleEdit} />
                                    <div className="error_add">
                                        <span className="errorShow">{this.state.errors["centerName"]}</span>
                                    </div>
                                </div>
                                <div className="lbl">
                                    <p>Pincode<sup>*</sup></p>
                                    <input type="text" placeholder="Enter Pincode" name="pinCode" value={this.state.pinCode} onChange={this.handleEdit} maxLength={6} />
                                    <div className="error_add">
                                        <span className="errorShow">{this.state.errors["pinCode"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="wrapper">
                                <div className="lbl">
                                    <p>State<sup>*</sup></p>
                                    <select className="dropbtn1111" onChange={(e) => this.onStateChange(e)} name="state" placeholder="Enter State">
                                        <option value={'0'}> Select State</option>
                                        {this.state.stateList.map((obj) => (
                                            <option key={obj.id} value={obj.id} selected={Number(obj.id) === Number(this.state.state_id)}>{obj.state_name}</option>
                                        ))}
                                    </select>
                                    {/* <input type="text" placeholder="Enter State" name="state" value={this.state.state} onChange={this.handleEdit} /> */}
                                    <div className="error_add">
                                        <span className="errorShow">{this.state.errors["state"]}</span>
                                    </div>
                                </div>
                                <div className="lbl">
                                    <p>City<sup>*</sup></p>
                                    {/* <input type="text" placeholder="Enter City" name="city" value={this.state.city} onChange={this.handleEdit} /> */}
                                    <select className="dropbtn1111 service_center" id="select_city" onChange={(e) => this.onCityChange(e)}>
                                        <option value={'0'}> Select City</option>
                                        {this.state.cityList?.map((obj) => (
                                            <option key={obj.id} value={obj.id} selected={Number(obj.id) === Number(this.state.city_id)}>{obj.city}</option>
                                        ))}
                                    </select>
                                    <div className="error_add">
                                        <span className="errorShow">{this.state.errors["city"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="lbl adress">
                                <p>Address<sup>*</sup></p>
                                <input type="text" placeholder="Enter Address" name="address" value={this.state.address} onChange={this.handleEdit} />
                                <div className="error_add">
                                    <span className="errorShow">{this.state.errors["address"]}</span>
                                </div>
                            </div>
                            <div className="addSpoc">
                                <div className="wrapper spoc">
                                    <p style={{ color: "#fff" }}>Contact person</p>
                                    <div className="row row1">
                                        <div className="input-wrapper" id="fname-wrapper">
                                            <input id="fname" type="text" placeholder="Full Name" name="fname" value={this.state.fname} onChange={this.handleChangeSpocPerson} />
                                        </div>
                                        <div className="error_add">
                                            <span className="errorShow">{this.state.errors["fname"]}</span>
                                        </div>
                                        {/* <div className="input-wrapper">
                                                                    <input id="lname" type="text" placeholder="Last Name" name="lname" value={this.state.lname} onChange={this.handleChangeSpocPerson} />
                                                                </div> */}
                                    </div>
                                    <div className="row row2">
                                        <div className="input-wrapper" id="mobile-wrapper">
                                            <input id="mobile" type="text" maxLength={12} placeholder="Mobile Number" name="mobile" value={this.state.mobile} onChange={this.handleChangeSpocPerson} />
                                            <div className="error_add">
                                                <span className="errorShow">{this.state.errors["mobile"]}</span>
                                            </div>
                                        </div>
                                        <div className="input-wrapper">
                                            <input id="phone" type="text" maxLength={12} placeholder="Phone Number...." name="phone" value={this.state.phone} onChange={this.handleChangeSpocPerson} />
                                            <div className="error_add">
                                                <span className="errorShow">{this.state.errors["phone"]}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row row3'>
                                        <div className="input-wrapper">
                                            <input id="email" type="text" placeholder="Email..." name="email" value={this.state.email} onChange={this.handleChangeSpocPerson} />
                                            <div className="error_add">
                                                <span className="errorShow">{this.state.errors["email"]}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button id="updateServiceCenter" onClick={this.editCenterInfo}>Update Service Center</button>
                    </ReactModal>
                </div>
            )
        }
    }
}

const mapStateToProps = state => ({
    userDetail: state.user.userDetail,
    userLogin: state.user.userLogin,
    serviceData: state.serviceCenter.serviceData,
    newServiceData: state.serviceCenter.newServiceData,
    stateDataList: state.complaints.stateData,
    cityDataList: state.complaints.cityData,
    serviceListRemoved: state.serviceCenter.serviceListRemoved,
    callServiceCenter: state.serviceCenter.callServiceCenter,
    serviceReportConfig: state.report.serviceReportConfig,
    paginationCount: state.complaints.paginationCount,
})

export default connect(mapStateToProps, { getServiceCenters, updateCenterInfo, removeServiceCenter, getCityByState, getStateList, callServiceCenter, getUserDetail, downlaodCenterReportData, fetchDownloadLimit })(ServiceCenter);