import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import CutomerDetails from './Retail/CustomerDetails';
import ProductDetails from './Retail/ProductDetails';
import History from './Retail/History';
import Conversations from './Retail/Conversations';
import Comments from './Retail/Comments';
// import Comments from './Retail/Comments'
// import TicketReceived from './ticketReceived';
import { getComplaintDetail, showComplaintDetailsAction, generateLink, editComplaintData, unmarkedTicketRefund } from '../../actions/complaintsDataAction';
// import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css';
// import history from '../../history';
import Loader from 'react-loader-spinner';

class ComplaintDetailsNew extends Component {

  constructor() {
    super();
    this.state = {
      ticketReceived: true,
      approvedTicket: true,
      subMenu: 1,
      click: 0,
      showLoader: false,
      isChecked: []
    };
    // this.handleRefreshList = this.props.handleRefreshList.bind(this)
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
  }

  onSubMenuClick = (e) => {
    this.setState({ subMenu: e.target.value }, () => {
      // this.getComplaintList();
    });
  };

  onItemPress = async (e, item) => {
    this.setState({ showLoader: true });
    await this.props.getComplaintDetail(item.id);
    this.setState({ showLoader: false, subMenu: 1 });
    const element = document.querySelector(".activeColor");
    if (element) {
      element.scrollIntoView({ block: "center" });
    }
  };

  onCloseDetail = (e) => {
    e.preventDefault();
    this.props.showComplaintDetailsAction(false);
  };

  onDownloanInvoice = (url) => { };

  // componentDidMount(){ }

  zoomIn() {
    this.setState({ click: this.state.click + 1 });
    if (this.state.click > 1) {
      this.setState({ click: 2 });
    }
  }

  zoomOut() {
    this.setState({ click: this.state.click - 1 });
    if (this.state.click < 1) {
      this.setState({ click: 0 });
    }
  }

  onGenerateLink = async (e, item) => {
    e.preventDefault();
    let data = {
      'complaint_id': item
    };
    let confirm = window.confirm("Are you sure you want to continue..?");
    if (confirm) {
      await this.props.generateLink(data);
      this.props.handleRefreshList();
    }
  };

  onUnmarkedTicketRefund = async (e, item) => {
    let data = {
      "complaint_id": item,
    };
    await this.props.unmarkedTicketRefund(data);
    this.props.handleRefreshList();
  };

  callEditApi = async (param) => {
    await this.props.editComplaintData(param);
    let editRes = this.props.editData;
    alert(editRes.message);
    this.props.handleRefreshList();
  };

  onMoveToComplaintClosedClick = (e, item) => {
    e.preventDefault();
    let data = {
      "complaint_id": item,
      "complaint_status_id": 13, // complaint_closed
      "sla_breached": '0',
      "update_comment": 'Complaint close normally',
      "flag": 'normal_close'

    };
    this.callEditApi(data);
  };

  onRevokeTicket = (e, item) => {
    e.preventDefault();
    let data = {
      "complaint_id": item,
      "complaint_status_id": 101,
      "sla_breached": '0',
      "update_comment": 'Revoke to Refund Approve',
      "flag": 'revoke_approve'
    };
    this.callEditApi(data);
  };


  onMoveToIQCClick = (e, item) => {
    e.preventDefault();
    let data = {
      "complaint_id": item,
      "complaint_status_id": 81, // screening_inbound
      "sla_breached": '0',
      "update_comment": 'Move to ICQ',
      "flag": 'move_icq'
    };
    this.callEditApi(data);
  };

  render() {
    return (
      <div className="main-container">
        {this.state.showLoader ?
          <div className='loader'>
            <Loader
              type="Oval"
              color="Grey"
              height={200}
              width={200}
            />
          </div>
          :
          <div>
            <div className="left-container">
              {this.props.complaintsData.length > 0 ?
                this.props.complaintsData.map((item, index) => {
                  return (
                    <div onClick={(e) => this.onItemPress(e, item)} key={index} >
                      {this.props.complaintsDetail ?
                        <div className={this.props.complaintsDetail.id === item.id ? "container7 activeColor" : "container7"} >
                          <div className="box">
                            <h6>{item.ticket_id}
                              {Number(item.is_warranty) === 1 ?
                                <button className="active ">Active</button>
                                :
                                <button className="active">Expire</button>
                              }
                            </h6>
                            <span style={{ cursor: 'pointer' }}>{item.product_name} ({item.size && item.size + " | "}{item.color_name})</span>
                            {item.upgradeProduct &&
                              <span className='upg'>
                                UPG - {item.upgradeProduct.product_name} + {item.size && item.size + " | "} {item.upgradeProduct.color_name}
                              </span>
                            }
                            <p style={{ cursor: 'pointer' }}>{moment(item.created_at, ["YYYY-MM-DD || HH.mm"]).format('MMMM DD, YYYY || hh:mm A')} || {item.channel_name}</p>
                          </div>
                        </div>
                        :
                        <div></div>
                      }
                    </div>
                  );
                })
                :
                <div className="no-result-text">
                  <p>No result found</p>
                </div>
              }
            </div>
            {this.props.complaintsData.length > 0 ?
              <div>
                {this.props.complaintsDetail ?
                  <div className="right-container">
                    <div className="box">
                      <h6>{this.props.complaintsDetail.ticket_id}
                        {Number(this.props.complaintsDetail.is_warranty) === 1 ?
                          <button className="active">Active</button>
                          :
                          <button className="active">Expire</button>
                        }
                      </h6>
                      <div className="ppt setwidth">
                        <p>{this.props.complaintsDetail.customer ? this.props.complaintsDetail.customer.name : null} || {moment(this.props.complaintsDetail.updated_at).format('MMMM DD, YYYY')} || {this.props.complaintsDetail.status_name}</p>
                      </div> <div className="ppti abc">  <i className="fas fa-times" onClick={(e) => this.onCloseDetail(e)}></i></div>
                      <div className="details">  <h6>{this.props.complaintsDetail.product.problem}</h6>
                      </div>
                      <div className="col">
                        {[101, 111, 104, 109, 110].includes(this.props.complaintsDetail.complaint_status_id) ?
                          <button onClick={this.actions} className="bbts" id="dropdownMenuButton" data-toggle="dropdown">
                            <i className="fas fa-ellipsis-v"></i>Actions
                          </button> : null}
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          {[101].includes(this.props.complaintsDetail.complaint_status_id) ?
                            <a className="dropdown-item" onClick={(e) => this.onUnmarkedTicketRefund(e, this.props.complaintsDetail.id)}><img src="../../img/Revoke-Ticket.svg" alt="" />Unmarked Refund </a>
                            : null}
                          {[111].includes(this.props.complaintsDetail.complaint_status_id) ?
                            <a className="dropdown-item" onClick={(e) => this.onUnmarkedTicketRefund(e, this.props.complaintsDetail.id)}><img src="../../img/Revoke-Ticket.svg" alt="" />Unmarked Refund </a>
                            : null}
                          {[109, 110].includes(this.props.complaintsDetail.complaint_status_id) ?
                            <a className="dropdown-item"  onClick={(e) => this.onGenerateLink(e, this.props.complaintsDetail.id)}><img src="../../img/Freshdesk.svg" alt="" />Re-GenerateLink</a>
                            : null}
                          {[104].includes(this.props.complaintsDetail.complaint_status_id) ?
                            <a className="dropdown-item"  onClick={(e) => this.onMoveToComplaintClosedClick(e, this.props.complaintsDetail.id)}><img src="../../img/Complaint-Closed.svg" alt="" />Close Complaint</a>
                            : null}
                          {[104].includes(this.props.complaintsDetail.complaint_status_id) ?
                            <a className="dropdown-item"  onClick={(e) => this.onMoveToIQCClick(e, this.props.complaintsDetail.id)}><img src="../../img/IQC.svg" alt="" />IQC</a>
                            : null}
                          {[104].includes(this.props.complaintsDetail.complaint_status_id) ?
                            <a className="dropdown-item"  onClick={(e) => this.onRevokeTicket(e, this.props.complaintsDetail.id)}><img src="../../img/Revoke-Ticket.svg" alt="" /> Revoke Ticket </a>
                            : null}
                          {[104].includes(this.props.complaintsDetail.complaint_status_id) ?
                            <a className="dropdown-item"  onClick={(e) => this.onRevokeTicket(e, this.props.complaintsDetail.id)}><img src="../../img/Revoke-Ticket.svg" alt="" /> Revoke Ticket </a>
                            : null}
                        </div>
                      </div>
                      <div className="mitem">
                        <ul id="menu2">
                          <li value="1" className={this.state.subMenu === 1 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>Attachments</li>
                          <li value="2" className={this.state.subMenu === 2 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>Product details</li>
                          <li value="3" className={this.state.subMenu === 3 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>Customer details</li>
                          <li value="4" className={this.state.subMenu === 4 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>Conversations</li>
                          <li value="5" className={this.state.subMenu === 5 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>Comments</li>
                          <li value="6" className={this.state.subMenu === 6 ? "selectedMenu" : null} onClick={(e) => this.onSubMenuClick(e)}>History</li>
                        </ul>
                      </div></div>
                    {this.state.subMenu === 1 ?
                      <div className="detail_wrapper">
                        <div className="div5 p_image">
                          <h6>Product image</h6>
                          <img className="imageWidthHight" alt="No image available" src={this.props.complaintsDetail.image_url ? this.props.complaintsDetail.image_url : ''} data-toggle="modal" data-target="#immos" />
                          {/* <img src={this.props.complaintsDetail.image_url} /> */}
                          {/* <img src={"https://picsum.photos/200/300"} /> */}
                        </div>
                        <div className="modal fade" id="immos">
                          <div className="zoom-icons">
                            {this.state.click < 3 &&
                              <div>
                                <i className="fas fa-search-plus" onClick={(this.state.click < 3) && this.zoomIn}></i>
                                <i className="fas fa-search-minus" onClick={(this.state.click < 3) && this.zoomOut}></i>
                              </div>
                            }
                          </div>
                          <div className="modal-dialog" role="document">
                            <div className="immo">
                              <div className="imgs" data-dismiss="modal">
                                {this.state.click === 0 &&
                                  <img style={{ transform: "scale(1)" }} alt="No image available" src={this.props.complaintsDetail.image_url ? this.props.complaintsDetail.image_url : ''} />
                                }
                                {this.state.click === 1 &&
                                  <img style={{ transform: "scale(1.5)", marginTop: "15%" }} alt="No image available" src={this.props.complaintsDetail.image_url ? this.props.complaintsDetail.image_url : ''} />
                                }
                                {this.state.click === 2 &&
                                  <img style={{ transform: "scale(2.5)", marginTop: "75%" }} alt="No image available" src={this.props.complaintsDetail.image_url ? this.props.complaintsDetail.image_url : ''} />
                                }
                                {/* <img alt="" src={this.props.complaintsDetail.image_url ? this.props.complaintsDetail.image_url : '/img/ss2.jpg'} /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="div6 invoice">
                          <h6>Invoice</h6>
                          <span onClick={() => this.onDownloanInvoice(this.props.complaintsDetail.invoice_url)}>
                            <a className="download_icon" href={this.props.complaintsDetail.invoice_url ? this.props.complaintsDetail.invoice_url : ''} target='_blank'> <i className="fas fa-download" />
                              Download Invoice</a></span>
                          <img alt="No preview available for PDF. Please Download from Above" className="imageWidthHight render-class" src={this.props.complaintsDetail.invoice_url ? this.props.complaintsDetail.invoice_url : ''} width="750px" height="900px" data-toggle="modal" data-target="#immos1" />
                          {/* <img src={this.props.complaintsDetail.invoice_url} /> */}
                        </div>
                        <div className="modal fade" id="immos1">
                          <div className="zoom-icons">
                            {this.state.click < 3 &&
                              <div>
                                <i className="fas fa-search-plus" onClick={(this.state.click < 3) && this.zoomIn}></i>
                                <i className="fas fa-search-minus" onClick={(this.state.click < 3) && this.zoomOut}></i>
                              </div>
                            }
                          </div>
                          <div className="modal-dialog" role="document">
                            <div className="immo" >
                              <div className="imgs" data-dismiss="modal">
                                {this.state.click === 0 &&
                                  <img style={{ transform: "scale(1)" }} alt="No invoice available" src={this.props.complaintsDetail.invoice_url ? this.props.complaintsDetail.invoice_url : ''} />
                                }
                                {this.state.click === 1 &&
                                  <img style={{ transform: "scale(1.5)", marginTop: "15%" }} alt="No invoice available" src={this.props.complaintsDetail.invoice_url ? this.props.complaintsDetail.invoice_url : ''} />
                                }
                                {this.state.click === 2 &&
                                  <img style={{ transform: "scale(2.5)", marginTop: "75%" }} alt="No invoice available" src={this.props.complaintsDetail.invoice_url ? this.props.complaintsDetail.invoice_url : ''} />
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      this.state.subMenu === 2 ?
                        <ProductDetails
                        />
                        :
                        this.state.subMenu === 3 ?
                          <CutomerDetails
                            item={this.props.complaintsDetail}
                          />
                          :
                          this.state.subMenu === 4 ?
                            <Conversations
                            // item={this.props.complaintsDetail}
                            />
                            :
                            this.state.subMenu === 5 ?
                              <Comments
                              // item={this.props.complaintsDetail}
                              />
                              :
                              this.state.subMenu === 6 ?
                                <History
                                // item={this.props.complaintsDetail}
                                />
                                : null
                    }
                  </div>
                  : <h3 style={{ color: "#fff" }}>Complaint Details not found</h3>
                }
              </div>
              : null
            }
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  complaintsData: state.complaints.complaintsData,
  complaintsDetail: state.complaints.complaintsDetail,
  showComplaintDetails: state.complaints.showComplaintDetails,
  linkGenerteL: state.complaints.linkGenerte,
  editData: state.complaints.editData,
});

export default connect(mapStateToProps, {
  getComplaintDetail, showComplaintDetailsAction, generateLink, editComplaintData, unmarkedTicketRefund
})(ComplaintDetailsNew);