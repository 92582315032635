import { FETCH_PRODUCT_DATA,FETCH_GROUP_DATA,SET_PRODUCT_DATA,EDIT_PRODUCT_DATA,GET_PRODUCT_DETAIL,SET_PRODUCTGROUP_DATA,FETCH_MESSAGE_DATA,EDIT_MESSAGE_DATA,FETCH_COLOR_DATA,FETCH_PRODUCT_NAME_DATA, FETCH_PRODUCT_BY_CATEGORY,SET_EDIT_PRODUCT_DATA} from '../actions/types';

const initialState = {
  productData: [],
  groupData: [],
  productResponse:[],
  editData:[],
  detailData:[],
  groupResponse:[],
  messageResponse:[],
  editMessageData:[],
  clolorList:[],
  user : {},
  productListByCat: [],
  editProductID:"",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCT_DATA:
      return {
        ...state,
        productData: action.payload
      };
      case FETCH_GROUP_DATA:
      return {
        ...state,
        groupData: action.payload,
      };
      case SET_PRODUCT_DATA:
        return {
          ...state,
          productResponse: action.payload,
          };
      case EDIT_PRODUCT_DATA:
        return {
          ...state,
            editData: action.payload,
        };
        case GET_PRODUCT_DETAIL:
        return {
          ...state,
            detailData: action.payload,
        };
        case SET_PRODUCTGROUP_DATA:
        return {
          ...state,
          groupResponse: action.payload,
        };

        case FETCH_MESSAGE_DATA:
        return {
          ...state,
          messageResponse: action.payload,
        };
        
        case EDIT_MESSAGE_DATA:
        return {
          ...state,
            editMessageData: action.payload,
        };

        case FETCH_COLOR_DATA:
        return {
          ...state,
            clolorList: action.payload,
        };

        case FETCH_PRODUCT_NAME_DATA:
        return {
          ...state,
            productNameList: action.payload,
        };
        
        case FETCH_PRODUCT_BY_CATEGORY:
          return {
            ...state,
              productListByCat: action.payload,
          };
          case SET_EDIT_PRODUCT_DATA:
            return {
              ...state,
                editProductID: action.payload,
            };
        
    default:
      return state;
  }
}
